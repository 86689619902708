import React, { useState, useEffect } from 'react'

import { Table, TableHead, TableBody, TableRow, TableCell, TextField, Typography } from '@material-ui/core'

export const DurationTable = ({ path, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { STRING } = validation
  const { LENGTH } = STRING
  const [source, setSource] = useState([])

  const getProperty = (path) => {
    const keys = path.split(/\./)
    const lastIndex = keys.length - 1
    const lastKey = keys[lastIndex]
    let property = PREBID.VENDOR

    keys.forEach((key, index) => {
      if (!property[key]) { property[key] = {} }
      if (index < lastIndex) { property = property[key] }
    })

    return property[lastKey]
  }

  useEffect(() => {
    setSource(getProperty(path))
  }, [getProperty(path)])

  return (
    <>
      <Table size='small' aria-label='video-duration-table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell style={{ textAlign: 'center' }}>Desktop</TableCell>
            <TableCell style={{ textAlign: 'center' }}>Mobile</TableCell>
            <TableCell style={{ textAlign: 'center' }}>Desktop</TableCell>
            <TableCell style={{ textAlign: 'center' }}>Mobile</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Min Duration</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={source.DESKTOP?.MIN?.DOM || 1}
                error={errors.PREBID_DVMD}
                helperText={errors.PREBID_DVMD && LENGTH.message}
                onChange={(event) => {
                  // let targetSource = Object.assign({ DURATION: { DESKTOP: { MIN: { DOM: 6, INTL: 6 }, MAX: { DOM: 30, INTL: 30 } }, MOBILE: { MIN: { DOM: 6, INTL: 6 }, MAX: { DOM: 30, INTL: 30 } } } }, source)
                  console.log('--- SOURCE --- ', source)
                  const value = parseInt(event.target.value, 10)
                  // PREBID.VENDOR.IX.VIDEO.DESKTOP.MIN.DOM = value
                  // targetSource.DESKTOP.MIN.DOM = value
                  source.DESKTOP.MIN.DOM = value
                  // const newOptions = { ...options}
                  setErrors({ ...errors, PREBID_IX_DVMD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options })
                  setAllOptions({ ...allOptions })
                }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1000
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={source.MOBILE?.MIN?.DOM || 1}
                error={errors.PREBID_MVMD}
                helperText={errors.PREBID_MVMD && LENGTH.message}
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10)
                  source.MOBILE.MIN.DOM = value
                  setErrors({ ...errors, PREBID_IX_MVMD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options })
                  setAllOptions({ ...allOptions })
                }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1000
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={source.DESKTOP?.MIN?.INTL || 1}
                error={errors.PREBID_DVMI}
                helperText={errors.PREBID_DVMI && LENGTH.message}
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10)
                  source.DESKTOP.MIN.INTL = value
                  setErrors({ ...errors, PREBID_IX_DVMI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options })
                  setAllOptions({ ...allOptions })
                }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1000
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={source.MOBILE?.MIN?.INTL || 1}
                error={errors.PREBID_MVMI}
                helperText={errors.PREBID_MVMI && LENGTH.message}
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10)
                  source.MOBILE.MIN.INTL = value
                  setErrors({ ...errors, PREBID_IX_MVMI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options })
                  setAllOptions({ ...allOptions })
                }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1000
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Max Duration</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={source.DESKTOP?.MAX?.DOM || 60}
                error={errors.PREBID_DVXD}
                helperText={errors.PREBID_DVXD && LENGTH.message}
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10)
                  source.DESKTOP.MAX.DOM = value
                  setErrors({ ...errors, PREBID_IX_DVXD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options })
                  setAllOptions({ ...allOptions })
                }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1000
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={source.MOBILE?.MAX?.DOM || 60}
                error={errors.PREBID_MVXD}
                helperText={errors.PREBID_MVXD && LENGTH.message}
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10)
                  source.MOBILE.MAX.DOM = value
                  setErrors({ ...errors, PREBID_IX_MVXD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options })
                  setAllOptions({ ...allOptions })
                }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1000
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={source.DESKTOP?.MAX?.INTL || 60}
                error={errors.PREBID_IX_DVXI}
                helperText={errors.PREBID_IX_DVXI && LENGTH.message}
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10)
                  source.DESKTOP.MAX.INTL = value
                  setErrors({ ...errors, PREBID_IX_DVXI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options })
                  setAllOptions({ ...allOptions })
                }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1000
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={source.MOBILE?.MAX?.INTL || 60}
                error={errors.PREBID_MVXI}
                helperText={errors.PREBID_MVXI && LENGTH.message}
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10)
                  source.MOBILE.MAX.INTL = value
                  setErrors({ ...errors, PREBID_IX_MVXI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options })
                  setAllOptions({ ...allOptions })
                }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1000
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}
