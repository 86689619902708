import React, { useState, useEffect } from 'react'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Typography, Tooltip, IconButton, List, ListItem, TextField, Grid } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

import { commonStyle } from '../../../styles'

const swal = withReactContent(Swal)

export const RuleList = ({ path, options, allOptions, setOptions, validation, placeholder = '' }) => {

  const getRuleValue = (path) => {
    let property = ADFUEL.MONETIZATION
    return property[path]
  }

  const commonClasses = commonStyle()
  const { ADFUEL } = options
  const { STRING } = validation
  const { LENGTH } = STRING
  const ruleValue = getRuleValue(path)
  const [list, setList] = useState(new Set(ruleValue))
  const [errors, setErrors] = useState({})
  const [entry, setEntry] = useState()


  const addRuleValue = (path, value) => {
    if (value && value !== '') {
      let ruleValues = new Set(ADFUEL.MONETIZATION[path])
      console.log('List: ', Array.from(ruleValues))
      console.log('Adding Value To List: ', value)    
      ruleValues.add(value)
      setEntry('')
      setList(ruleValues)
      ADFUEL.MONETIZATION[path] = Array.from(ruleValues)
      setOptions(ADFUEL.MONETIZATION[path])
    }
  }

  const removeRuleValue = (path, value) => {
    let ruleValues = new Set(ADFUEL.MONETIZATION[path])
    console.log('List: ', Array.from(ruleValues))
    console.log('Removing Value From List: ', value)    
    ruleValues.delete(value)
    setList(ruleValues)
    ADFUEL.MONETIZATION[path] = Array.from(ruleValues)
    setOptions(ADFUEL.MONETIZATION[path])
  }

  useEffect(() => {
    console.log('Setting List: ', getRuleValue(path))
    setList(new Set(getRuleValue(path)))
  }, [getRuleValue(path)])

  return (
    <List dense disablePadding>
      {Array.from(list).map((item, index) => {
        return (
          <ListItem dense disableGutters key={item.key}>
            <Grid container spacinsg={1}>
              <Grid item style={{width: '200px', padding: '2px', borderBottom: 'solid 1px black'}}>
                <Typography variant="p" style={{ paddingRight: '5px', fontSize: '1.15em'}}>{item}</Typography>
              </Grid>
              <Grid item style={{padding: '2px', borderBottom: 'solid 1px black'}}>
                <Tooltip title='Delete'>
                  <IconButton
                    className={commonClasses.btnDanger}
                    size='small'
                    onClick={() => {
                      swal.fire({
                        title: 'Are you sure?',
                        icon: 'warning',
                        showCancelButton: true
                        // buttons: true,
                        // dangerMode: true
                      }).then(ok => {
                        if (ok.isConfirmed) {
                          removeRuleValue(path, item)
                        }
                      })
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </ListItem>
        )
      })}
      <ListItem dense disableGutters>
        <Grid container spacing={1}>
          <Grid item style={{width: '200px', padding: '2px'}}>
            <TextField
              style={{ width: '100%' }}
              placeholder={placeholder}
              value={entry}
              error={errors[path + 'RULE_' + path + '_0']}
              helperText={errors[path + 'RULE_' + path + '_0'] && LENGTH.message}
              onChange={(event) => {
                const value = event.target.value
                setErrors({ ...errors, [path + 'RULE_' + path + '_0']: value.length < LENGTH.min || value.length > LENGTH.max })
                setEntry(value)
              }}
            />
          </Grid>
          <Grid item style={{padding: '2px', paddingLeft: '6px'}}>
            <Tooltip title='Add'>
              <IconButton
                className={commonClasses.btnSuccess}
                size='small'
                onClick={() => {
                  if (!list.has(entry)){
                    addRuleValue(path, entry)
                  }
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  )
}
