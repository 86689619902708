import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'

const swal = withReactContent(Swal)

const initialState = {
  loading: false,
  permissions: [],
  error: null
}

const permissionSlice = createSlice({
  name: 'permissionSlice',
  initialState,
  reducers: {
    getPermissions: (state, action) => {
      state.loading = false
      state.permissions = action.payload
    },
    createPermission: (state, action) => {
      state.loading = false
      swal.fire({
        title: `The permission "${action.payload.name}" has been created!`,
        icon: 'success'
      })
    },
    updatePermission: (state, action) => {
      state.loading = false
      const permissions = state.permissions.slice()
      const updatedItemIndex = permissions.findIndex(
        permission => permission._id === action.payload._id
      )
      permissions[updatedItemIndex] = action.payload
      state.permissions = permissions
      swal.fire({
        title: `The permission "${action.payload.name}" has been updated!`,
        icon: 'success'
      })
    },
    deletePermission: (state, action) => {
      state.loading = false
      swal.fire({
        title: `The permission "${action.payload.name}" has been deleted!`,
        icon: 'success'
      })
    },
    loadingStatus: state => {
      state.loading = true
    },
    errorStatus: (state, action) => {
      state.loading = false
      state.error = action.payload
      handleError(action.payload, showError)
    }
  }
})

export default permissionSlice.reducer
export const {
  errorStatus,
  loadingStatus,
  getPermissions,
  updatePermission,
  createPermission,
  deletePermission
} = permissionSlice.actions

export const fetchRolePermissions = () => {
  return dispatch => {
    dispatch(loadingStatus())
    return request('/lookups/permissions')
      .then(res => dispatch(getPermissions(res.data)))
      .catch(err => dispatch(errorStatus(err)))
  }
}

export const updateRolePermission = permission => {
  return dispatch => {
    return request(`/lookups/permissions/${permission._id}`, 'POST', permission)
      .then(res => {
        dispatch(updatePermission(res.data))
      })
      .catch(err => dispatch(errorStatus(err)))
  }
}

export const createRolePermission = permission => {
  return dispatch => {
    return request('/lookups/permissions', 'POST', permission)
      .then(res => dispatch(createPermission(res.data)))
      .then(dispatch(fetchRolePermissions()))
      .catch(err => dispatch(errorStatus(err)))
  }
}

export const deleteRolePermission = permissionId => {
  return dispatch => {
    return (
      request(`/lookups/permissions/${permissionId}`, 'DELETE')
        .then(res => dispatch(deletePermission(res.data)))
        .catch(err => dispatch(errorStatus(err)))
    )
  }
}
