import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request } from '../utilities/request'
import config from '../config'
let env = document.location.hostname.startsWith('rocketeer-ui-dev.') || document.location.hostname.startsWith('dev.') || document.location.hostname.startsWith('localhost') || document.location.hostname.startsWith('ui.')
  ? 'development'
  : (
      document.location.hostname.startsWith('qa.')
        ? 'qa'
        : 'production'
    )

if (document.location.hostname.startsWith('localhost')) env = 'local'

// console.log('configSlice Env: ', env)

const swal = withReactContent(Swal)

const initialState = {
  pathConfig: null,
  publisher: null,
  isChanged: false,
  error: null,
  loading: false,
  next: () => {}
}

const configSlice = createSlice({
  name: 'configSlice',
  initialState,
  reducers: {
    GetPath: (state, action) => {
      state.loading = false
      state.pathConfig = action.payload[0]
      state.publisher = action.payload[0].publisher
    },
    AddPath: (state, action) => {
      state.pathConfig = action.payload.data
      state.publisher = action.payload.data.publisher
      state.loading = false

      if (action.payload) {
        swal.fire({
          title: `Path ${action.payload.name} was added successfully`,
          icon: 'success'
        })
      }
    },
    UpdatePath: (state, action) => {
      state.pathConfig = action.payload.data
      state.publisher = action.payload.data.publisher
      state.loading = false
      if (action.payload) {
        swal.fire({
          title: `Path ${action.payload.name} was updated successfully`,
          icon: 'success'
        })
      }
    },
    DeletePath: (state, action) => {
      state.pathConfig = action.payload.data
      state.publisher = action.payload.data.publisher
      state.loading = false
      if (action.payload) {
        swal.fire({
          title: `Path ${action.payload.name} was deleted successfully`,
          icon: 'success'
        })
      }
    },
    LoadingStatus: state => {
      state.loading = true
    },
    ErrorStatus: (state, action) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export default configSlice.reducer

export const {
  GetPath,
  AddPath,
  UpdatePath,
  DeletePath,
  LoadingStatus,
  ErrorStatus
} = configSlice.actions

export const getPath = () => {
  return dispatch => {
    dispatch(LoadingStatus())
    return request('/path')
      .then(res => {
        dispatch(GetPath(res.data))
      })
      .catch(err => {
        dispatch(ErrorStatus(err.message))
        dispatch(GetPath([config[env]]))
      })
  }
}

export const updatePath = (PathConfig, name, action) => {
  return async dispatch => {
    dispatch(LoadingStatus())
    return request('/path', 'POST', PathConfig)
      .then(res => {
        switch (action) {
          case 'Add':
            dispatch(AddPath({ data: res.data, name }))
            break
          case 'del':
            dispatch(DeletePath({ data: res.data, name }))
            break
          default:
            dispatch(UpdatePath({ data: res.data, name }))
            break
        }
      })
      .catch(err => dispatch(ErrorStatus(err.response)))
  }
}
