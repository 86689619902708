import config from '../config'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swal = withReactContent(Swal)

// const isLocalhost = Boolean(
//   window.location.hostname === 'localhost' ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === '[::1]' ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// )
let env = document.location.hostname.startsWith('rocketeer-ui-dev.') || document.location.hostname.startsWith('dev.') || document.location.hostname.startsWith('localhost') || document.location.hostname.startsWith('ui.')
  ? 'development'
  : (
      document.location.hostname.startsWith('qa.')
        ? 'qa'
        : 'production'
    )
if (document.location.hostname.startsWith('localhost')) env = 'local'

export const request = (path, method = 'GET', body = null, params = null) => {
  const url = config[env].apiBaseUrl + decodeURIComponent(path)

  const options = {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    withCredentials: true
  }

  if (body !== null) {
    options.data = JSON.stringify(body)
  }

  if (params !== null && typeof params === 'object') {
    options.params = params
  }

  // console.log('[CALLING AXIOS] ----- ', { path, method, body, params, url, options })
  return axios(url, options)
}

export const requestUpload = (path, method = 'GET', formData) => {
  const url = config[env].apiBaseUrl + decodeURIComponent(path)

  const options = {
    method,
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'application/json; charset=utf-8',
    },
    withCredentials: true,
    data: formData
  }
  return axios(url, options)
}

export const handleError = (err, next) => {
  console.log('Handling Error: ', err)
  let text = 'Sorry, '
  let showErr = true
  if (err.response) {
    // Request made and server responded
    if (!err.response.status) {
      // network error
      text += 'The request was made but no response was received.'
    } else if (err.response.status === 400) {
      // Bad request
      text += 'Bad Request: Error 400'
    } else if (err.response.status === 401) {
      // 'Unauthorized'
      // text += 'Stop right here! Authorization required'
      showErr = false
    } else if (err.response.status === 404) {
      text += "Looks like what you're looking for isn't here."
    } else if (err.response.status === 405) {
      text += 'API Route Method Not Allowed.'
      // } else if (err.response.status === 422) { // Validation Message
      //   text += 'API Route Method Not Allowed'
    } else if (err.response.status >= 500) {
      // Server Error Message
      text += 'The application has encountered an unknown error.'
    } else {
      // Something happened in setting up the request that triggered an Error
      text += 'Something went wrong.'
    }
  } else if (typeof err === 'string') {
    showErr = true
    text = err + ' Please refresh the page and try again.'
  } else if (typeof err.response === 'undefined' || err.request) {
    // network error
    console.log('err: ', err)
    text += 'A request was made but no response was received'.
    showErr = false
  } else {
    text += 'The application has encountered an unknown error.'
  }
  // text += ', Please contact your administrator.'
  text += ' Help us improve your experience by submitting an error report to the Digital Ads development team.'
  next(text, showErr)
}

export const showError = (text, showErr) => {
  console.warn('SWAL Error Wrapper:', { text, showErr })
  if (showErr) {
    swal.fire({
      title: '¯\\_(ツ)_/¯',
      text,
      icon: 'error'
    })
  }
}
