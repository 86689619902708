import React from 'react'
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, TextField, Button, makeStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { addClasses, commonStyle } from '../../styles'
const useStyle = makeStyles(theme => ({
  led: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.25)",
    // boxShadow: "#000 0 -1px 6px 1px",
    marginRight: "10px",
    display: 'inline-block',
    // border: 'solid 1px white'
  },
  ledRed: {
    backgroundColor: "#F00",
    // boxShadow: "#000 0 -1px 6px 1px, inset #600 0 -1px 8px, #F00 0 3px 11px"
  },
  ledOrange: {
    backgroundColor: "#FF7000",
    // boxShadow: "#000 0 -1px 6px 1px, inset #630 0 -1px 8px, #FF7000 0 3px 11px"
  },
  ledYellow: {
    backgroundColor: "#FF0",
    // boxShadow: "#000 0 -1px 6px 1px, inset #660 0 -1px 8px, #FF0 0 3px 11px"
  },
  ledGreen: {
    backgroundColor: "#80FF00",
    // boxShadow: "#000 0 -1px 6px 1px, inset #460 0 -1px 8px, #80FF00 0 3px 11px"
  },
  ledBlue: {
    backgroundColor: "#06F",
    // boxShadow: "#000 0 -1px 6px 1px, inset #006 0 -1px 8px, #06F 0 3px 11px"
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
}))

const RegistrySiteDetails = ({ site, ivr, rtai }) => {
  const commonClasses = commonStyle()
  const classes = useStyle()
  console.log('[RegistrySiteDetails] ========== Site: ', site)
  console.log('[RegistrySiteDetails] ========== IVR: ', ivr)
  console.log('[RegistrySiteDetails] ========== RTAI: ', rtai)
  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrSiteHeader)} titleTypographyProps={{ variant: 'h6' }} title={`${site.name} Site Properties`}/>
      <CardContent className={addClasses(commonClasses.pl_3, commonClasses.pr_3)}>
        <Grid container spacing={0}>
          <Grid item xl={12} lg={2} md={4} sm={6} xs={6} style={{paddingLeft: '5px'}}>
            {site.isResponsive ? (<><div className={addClasses(classes.led, classes.ledGreen)}></div>&nbsp;Responsive Viewports<br /></>) : (<><div className={addClasses(classes.led, classes.ledRed)}></div>&nbsp;Responsive Viewports<br/></>)}
          </Grid>
          <Grid item xl={12} lg={2} md={4} sm={6} xs={6} style={{paddingLeft: '5px'}}>
            {site.useWOPR ? (<><div className={addClasses(classes.led, classes.ledGreen)}></div>&nbsp;WOPR Registry Naming<br /></>) : (<><div className={addClasses(classes.led, classes.ledRed)}></div>&nbsp;WOPR Registry Naming<br/></>)}
          </Grid>
          <Grid item xl={12} lg={2} md={4} sm={6} xs={6} style={{paddingLeft: '5px'}}>
            {site.tagForChildDirectedTreatment ? (<><div className={addClasses(classes.led, classes.ledGreen)}></div>&nbsp;Child-Directed Treatment<br /></>) : (<><div className={addClasses(classes.led, classes.ledRed)}></div>&nbsp;Child-Directed Treatment<br/></>)}
          </Grid>
          <Grid item xl={12} lg={2} md={4} sm={6} xs={6} style={{paddingLeft: '5px'}}>
            {site.safeFrameConfig ? (<><div className={addClasses(classes.led, classes.ledGreen)}></div>&nbsp;Safeframe Enabled<br /></>) : (<><div className={addClasses(classes.led, classes.ledRed)}></div>&nbsp;Safeframe Enabled<br/></>)}
          </Grid>
          <Grid item xl={12} lg={2} md={4} sm={6} xs={6} style={{paddingLeft: '5px'}}>
            {ivr ? (<><div className={addClasses(classes.led, classes.ledGreen)}></div>&nbsp;In-View Refresh<br /></>) : (<><div className={addClasses(classes.led, classes.ledRed)}></div>&nbsp;In-View Refresh<br/></>)}
          </Grid>
          <Grid item xl={12} lg={2} md={4} sm={6} xs={6} style={{paddingLeft: '5px'}}>
            {rtai ? (<><div className={addClasses(classes.led, classes.ledGreen)}></div>&nbsp;Real-Time Ad Insertion<br /></>) : (<><div className={addClasses(classes.led, classes.ledRed)}></div>&nbsp;Real-Time Ad Insertion<br/></>)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default RegistrySiteDetails