import React from 'react'
import { Typography, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  textfield: {
    margin: theme.spacing(1)
  },
  inline: {
    display: 'inline-block'
  }
}))

export default function Input (props) {
  const { data } = props
  const { style, label, variant, value, classes, disabled, onChangeHandler } = data
  const _classes = useStyles()
  const inputLabel = React.useRef(null)
  return (
    <div className={!classes ? '' : classes}>
      {label.outer && <Typography ref={inputLabel} style={!label.style ? '' : label.style} className={!label.classes ? '' : label.classes}>{label.outer}</Typography>}
      <TextField
        disabled={disabled}
        className={_classes.textfield}
        label={label.inner}
        variant={variant}
        value={value}
        onChange={onChangeHandler}
        margin='dense'
        style={!style ? '' : style}
      />
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.shape({
    outer: PropTypes.string,
    inner: PropTypes.string,
    classes: PropTypes.string
  }),
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  classes: PropTypes.string,
  onChangeHandler: PropTypes.func
}
