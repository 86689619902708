import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { array as yuparray, string as yupstring, object as yupobject, boolean as yupbool, number as yupnum } from 'yup'
import { convertObjectToArray, siteNameFormatter } from '../../../utilities/helperFunctions.js'
import { Container, Card, CardHeader, CardContent, CardActions, Button, Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
// import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
// import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
// import BuildIcon from '@material-ui/icons/Build'
import { saveAdFuelModuleConfiguration, saveAdFuelOptionFile, updateAdFuelVersion } from '../../../modules/lookupsSlice'
import { ToggleSwitch } from '../../ui/ToggleSwitch'
import { commonStyle } from '../../../styles'
import { RuleList } from './RuleList.js'

const swal = withReactContent(Swal)

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%'
//   },
//   hidden: {
//     display: 'none',
//     visibility: 'collapse'
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: '33.33%',
//     flexShrink: 0
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(13),
//     color: theme.palette.text.secondary
//   },
//   spacer: { margin: theme.spacing(1) },
//   saveButton: { color: '#2D2' },
//   deleteButton: { color: '#D22' },
//   plainText: {
//     fontSize: theme.typography.pxToRem(18)
//   },
//   buttonGroup: {
//     marginTop: theme.spacing(1),
//     position: 'relative',
//     top: 5
//   }
// }))


const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

const mergeDeep = (target, ...sources) => {
  if (!sources.length) {
    // console.log('No more sources. Returning target: ', target)
    return target
  }
  const source = sources.shift()
  // console.log('Merging in source: ', source)
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          })
        }
        // console.log('Merging key: ', key)
        mergeDeep(target[key], source[key])
      } else {
        // console.log('Setting key: ', key)
        Object.assign(target, {
          [key]: source[key]
        })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

const validJSON = (object) => {
  try {
    JSON.parse(JSON.stringify(object))
    return true
  } catch (error) {
    Swal.fire({
      title: 'JSON Validation Error',
      text: error,
      icon: 'error'
    })
    return false
  }
}

export const AdFuelConfigurationModal = ({ allOptions, data, site, setOptions, setAllOptions, setOpenDialog, errorObject, setErrors }) => {
  const [isChanged, setIsChanged] = React.useState(false)

  const commonClasses = commonStyle()
  const validation = {
    STRING: {
      LENGTH: { min: 0, max: 255, message: 'character limit exceeded' }
    },
    NUMBER: {
      TIMEOUT: { min: 500, max: 3000, message: 'please enter a number between 500-3000' },
      REFRESH: { min: 35, max: 1000, message: 'please enter a number between 35-1000' },
      PERCENTAGE: { min: 0, max: 100, message: 'please enter a number between 0-100' }
    }
  }

  const initialState = {
    ACTIVE_MODULES: [],
    AUTO_DISPATCH: true,
    DEFAULT_TIMEOUT: 800,
    DEFAULT_DESKTOP_TIMEOUT: 1200,
    DEFAULT_MOBILE_TIMEOUT: 800,
    IFRAME_TITLE: 'advertisement',
    NETWORK_ID: '8663477',
    REFRESH_ON_FOCUS_ONLY: false,
    LAZY_LOAD_FETCH_PERCENT: 500,
    LAZY_LOAD_RENDER_PERCENT: 200,
    LAZY_LOAD_MOBILE_SCALING: 2,
    COLLAPSE_EMPTY_DIVS: true,
    DEBUG: false,
    SITE_OBJECT: '',
    MONETIZATION: {
      INVALID_MAPPINGS: [],
      INVALID_SEGMENTS: ['_ns_', '_nfs_'],
      INVALID_ADUNITS : [
        'CNN/health',
        'CNN/health/healthgrades',
        'CNN/health/leaf',
        'CNN/health/list',
        'CNN/health/photos',
        'CNN/health/specials',
        'CNN/health/video',
        'CNN/student-news'
      ],
      MONETIZED_SIZES : [
        '160x600',
        '300x250',
        '300x600',
        '320x50',
        '728x90',
        '970x90',
        '970x250'
      ]
    },
    clean: true
  }

  const validationSchema = yupobject().shape({
    AUTO_DISPATCH: yupbool().required('Required'),
    DEFAULT_TIMEOUT: yupnum().min(250, 'Must be at least 250ms').integer('Must be an integer value.').required('Required'),
    DEFAULT_DESKTOP_TIMEOUT: yupnum().min(250, 'Should be at least 250ms').max(2500, 'Should not be over 2500ms').integer('Must be an integer value.').required('Required'),
    DEFAULT_MOBILE_TIMEOUT: yupnum().min(250, 'Should be at least 250ms').max(2500, 'Should not be over 2500ms').integer('Must be an integer value.').required('Required'),
    IFRAME_TITLE: yupstring().required('Required'),
    NETWORK_ID: yupstring().min(6).max(16).required('Required'),
    REFRESH_ON_FOCUS_ONLY: yupbool().required('Required'),
    LAZY_LOAD_FETCH_PERCENT: yupnum().integer('Must be an integer value.').required('Required'),
    LAZY_LOAD_RENDER_PERCENT: yupnum().integer('Must be an integer value.').required('Required'),
    LAZY_LOAD_MOBILE_SCALING: yupnum().integer('Must be an integer value.').required('Required'),
    COLLAPSE_EMPTY_DIVS: yupbool().required('Required'),
    SITE_OBJECT: yupstring().required('Required'),
    MONETIZATION: yupobject().shape({
      INVALID_MAPPINGS: yuparray().ensure().of(yupstring()).compact(),
      INVALID_SEGMENTS: yuparray().ensure().of(yupstring()).compact(),
      INVALID_ADUNITS: yuparray().ensure().of(yupstring()).compact(),
      MONETIZED_SIZES: yuparray().ensure().of(yupstring()).compact().min(1),
    }).required('Required'),
    DEBUG: yupbool().required('Required')
  })

  let ADFUEL = mergeDeep(initialState, data)
  ADFUEL.SITE_OBJECT = ADFUEL.SITE_OBJECT || site.name.toUpperCase().replace(/\s+/g, '')
  ADFUEL.MONETIZATION = ADFUEL.MONETIZATION || {
    INVALID_MAPPINGS: [],
    INVALID_SEGMENTS: [],
    INVALID_ADUNITS: [],
    MONETIZED_SIZES: [],
  }
  const { NUMBER } = validation
  const { TIMEOUT } = NUMBER
  const { register, control, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  });

  // let { register, errors, setValue } = useForm({
  //   resolver: yupResolver(validationSchema),
  //   submitFocusError: false
  // })

  if (typeof errors === 'undefined') errors = errorObject

  const handleTextFieldChange = async (name, e) => {
    console.log('Changing: ', name)
    setIsChanged(true)
    setValue(name, e.target.value, { shouldValidate: true })
  }

  const handleBoolFieldChange = async (name, e) => {
    console.log('Changing: ', name)
    setIsChanged(true)
    setValue(name, e.target.checked, { shouldValidate: true })
  }

  const handleMonetizationChange = async (name, value) => {
    console.log('Data: ', data)
    console.log(`Changing: ${name}`, value, typeof value)
    setIsChanged(true)
    ADFUEL.MONETIZATION[name] = value
    setOptions({ ADFUEL })
    setAllOptions({...allOptionsptions, ADFUEL})
  }

  //   const handleSelectChange = async e => {
  //     return e
  //   }

  //   const select = (handleSelectFieldChange) => {
  //     return (
  //       <Select
  //         id='moduleAdFuelVersion'
  //         value={'default'}
  //         className={classes.selectEmpty}
  //         name='selectVersion'
  //         variant='outlined'
  //         onChange={e => handleSelectFieldChange(e)}
  //       >
  //         <MenuItem key={'default'} value={'default'}>
  //           {'Select One'}
  //         </MenuItem>
  //       </Select>
  //     )
  //   }

  const boolField = (
    boolFieldName,
    boolFieldValue,
    handleBoolFieldChange,
    error,
    errorMessage
  ) => {
    return (
      <ToggleSwitch
        label={boolFieldName}
        offLabel='False'
        onLabel='True'
        error={!!error}
        helperText={error ? errorMessage : ''}
        checked={boolFieldValue}
        disabled
        // {...register(boolFieldName, { checked: boolFieldValue, value: boolFieldValue, onChange: (e) => { console.log('A Changing...', e); return handleBoolFieldChange(boolFieldName, e); } })}
        inputRef={register}
        onChange={(e) => handleBoolFieldChange(boolFieldName, e)}
      />
    )
  }

  const textField = (
    textFieldName,
    textFieldDefaultValue,
    handleTextFieldChange,
    error,
    errorMessage
  ) => {
    return (
      <TextField
        margin='dense'
        name={textFieldName}
        defaultValue={textFieldDefaultValue}
        variant='outlined'
        error={!!error}
        helperText={error ? errorMessage : ''}
        {...register(textFieldName)}
        onChange={e => handleTextFieldChange(textFieldName, e)}
      />
    )
  }

  const displayAdFuelMonetizationRulesTable = () => {

    return (
      <Table size='small' aria-label='adfuel-monetization-rules'>
        <TableHead>
          <TableRow>
            <TableCell style={{ paddingTop: '25px' }} colspan={4}><Typography variant='h5'>Monetization Rules</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '25%', verticalAlign: 'top' }}>
              <Typography variant='p' style={{fontWeight: 'bold'}}>Invalid Mappings</Typography>
              <RuleList
                path='INVALID_MAPPINGS'
                value={ADFUEL.MONETIZATION.INVALID_MAPPINGS}
                placeholder={'ex: bnr_atf_01'}
                options={{ ADFUEL }}
                allOptions={allOptions}
                setOptions={(options) => { handleMonetizationChange('INVALID_MAPPINGS', options)}}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='INVALID_MAPPINGS'
              />
            </TableCell>
            <TableCell style={{ width: '25%', verticalAlign: 'top' }}>
              <Typography variant='p' style={{fontWeight: 'bold'}}>Invalid Segments</Typography>
              <RuleList
                path='INVALID_SEGMENTS'
                placeholder={'ex: _atf_'}
                value={ADFUEL.MONETIZATION.INVALID_SEGMENTS}
                options={{ ADFUEL }}
                allOptions={allOptions}
                setOptions={(options) => { handleMonetizationChange('INVALID_SEGMENTS', options)}}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='INVALID_SEGMENTS'
              />
            </TableCell>
            <TableCell style={{ width: '25%', verticalAlign: 'top' }}>
              <Typography variant='p' style={{fontWeight: 'bold'}}>Invalid AdUnits</Typography>
              <RuleList
                path='INVALID_ADUNITS'
                placeholder={'ex: CNN/health/leaf'}
                value={ADFUEL.MONETIZATION.INVALID_ADUNITS}
                options={{ ADFUEL }}
                allOptions={allOptions}
                setOptions={(options) => { handleMonetizationChange('INVALID_ADUNITS', options)}}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='INVALID_ADUNITS'
              />
            </TableCell>
            <TableCell style={{ width: '25%', verticalAlign: 'top' }}>
              <Typography variant='p' style={{fontWeight: 'bold'}}>Monetized Sizes</Typography>
              <RuleList
                path='MONETIZED_SIZES'
                placeholder={'ex: 300x250'}
                value={ADFUEL.MONETIZATION.MONETIZED_SIZES}
                options={{ ADFUEL }}
                allOptions={allOptions}
                setOptions={(options) => { handleMonetizationChange('MONETIZED_SIZES', options)}}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='MONETIZED_SIZES'
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }


  const displayPropertyTable = () => {
    return (
      <Card>
        <CardHeader title='AdFuel Configuration' />
        <Divider />
        <CardContent className={commonClasses.cardBkClr} style={{ width: '100%' }}>
          <Container maxWidth='xl'>
            <Card>
              <CardContent>
                <Table aria-label='default-config-table'>
                  <TableBody>
                    <TableRow>
                      <TableCell size='small' style={{ width: '150px', border: 'none' }}>
                        <Typography>IFRAME_TITLE:</Typography>
                      </TableCell>
                      <TableCell size='small' style={{ width: '200px', border: 'none' }}>
                        {textField('IFRAME_TITLE', ADFUEL.IFRAME_TITLE, handleTextFieldChange, errors.IFRAME_TITLE, errors.IFRAME_TITLE ? errors.IFRAME_TITLE.message : '')}
                      </TableCell>
                      <TableCell size='small' rowSpan={9} style={{ border: 'none', verticalAlign: 'top' }}>
                        <Table aria-label='default-config-table'>
                          <TableBody>
                            <TableRow>
                              <TableCell size='small' style={{ border: 'none' }}>
                                {boolField('AUTO_DISPATCH', ADFUEL.AUTO_DISPATCH, handleBoolFieldChange, errors.AUTO_DISPATCH, errors.AUTO_DISPATCH ? errors.AUTO_DISPATCH.message : '')}
                                {boolField('COLLAPSE_EMPTY_DIVS', ADFUEL.COLLAPSE_EMPTY_DIVS, handleBoolFieldChange, errors.COLLAPSE_EMPTY_DIVS, errors.COLLAPSE_EMPTY_DIVS ? errors.COLLAPSE_EMPTY_DIVS.message : '')}
                                {boolField('REFRESH_ON_FOCUS_ONLY', ADFUEL.REFRESH_ON_FOCUS_ONLY, handleBoolFieldChange, errors.REFRESH_ON_FOCUS_ONLY, errors.REFRESH_ON_FOCUS_ONLY ? errors.REFRESH_ON_FOCUS_ONLY.message : '')}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ border: 'none' }}>
                      <TableCell size='small' style={{ width: '150px', border: 'none' }}>
                        <Typography>NETWORK_ID:</Typography>
                      </TableCell>
                      <TableCell size='small' colSpan={2} style={{ width: '200px', border: 'none' }}>
                        {textField('NETWORK_ID', ADFUEL.NETWORK_ID, handleTextFieldChange, errors.NETWORK_ID, errors.NETWORK_ID ? errors.NETWORK_ID.message : '')}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ border: 'none' }}>
                      <TableCell size='small' style={{ width: '150px', border: 'none' }}>
                        <Typography>SITE_OBJECT:</Typography>
                      </TableCell>
                      <TableCell size='small' colSpan={2} style={{ width: '200px', border: 'none' }}>
                        {textField('SITE_OBJECT', ADFUEL.SITE_OBJECT, handleTextFieldChange, errors.SITE_OBJECT, errors.SITE_OBJECT ? errors.SITE_OBJECT.message : '')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Divider />
                <Table size='small' aria-label='default-timeouts-table'>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ paddingTop: '25px' }} colspan={3}><Typography variant='h5'>Default Timeouts</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ width: '33%' }}>
                        <TextField
                          fullWidth
                          margin='none'
                          label='General'
                          inputProps={{
                            type: 'number',
                            min: TIMEOUT.min,
                            max: TIMEOUT.max
                          }}
                          value={ADFUEL.DEFAULT_TIMEOUT}
                          error={errors.DEFAULT_TIMEOUT}
                          helperText={errors.DEFAULT_TIMEOUT && TIMEOUT.message}
                          onChange={(event) => {
                            const value = parseInt(event.target.value)
                            ADFUEL.DEFAULT_TIMEOUT = value
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ width: '33%' }}>
                        <TextField
                          fullWidth
                          margin='none'
                          label='Desktop'
                          inputProps={{
                            type: 'number',
                            min: TIMEOUT.min,
                            max: TIMEOUT.max
                          }}
                          value={ADFUEL.DEFAULT_DESKTOP_TIMEOUT}
                          error={errors.DEFAULT_DESKTOP_TIMEOUT}
                          helperText={errors.DEFAULT_DESKTOP_TIMEOUT && TIMEOUT.message}
                          onChange={(event) => {
                            const value = parseInt(event.target.value)
                            ADFUEL.DEFAULT_DESKTOP_TIMEOUT = value
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ width: '33%' }}>
                        <TextField
                          fullWidth
                          margin='none'
                          label='Mobile'
                          inputProps={{
                            type: 'number',
                            min: TIMEOUT.min,
                            max: TIMEOUT.max
                          }}
                          value={ADFUEL.DEFAULT_MOBILE_TIMEOUT}
                          error={errors.DEFAULT_MOBILE_TIMEOUT}
                          helperText={errors.DEFAULT_MOBILE_TIMEOUT && TIMEOUT.message}
                          onChange={(event) => {
                            const value = parseInt(event.target.value)
                            ADFUEL.DEFAULT_MOBILE_TIMEOUT = value
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {displayAdFuelMonetizationRulesTable()}
              </CardContent>
            </Card>
          </Container>
        </CardContent>
        <Divider />
        <CardActions className={commonClasses.actionArea}>
          <Button
            className={isChanged ? commonClasses.btnSuccess : ''}
            variant='contained'
            disabled={!isChanged}
            onClick={() => {
                let adFuelModuleId = site.adFuelVersionId
                let adFuelModuleName = 'ADFUEL'
                let UPDATED_ADFUEL = mergeDeep(data, getValues())
                UPDATED_ADFUEL = mergeDeep(UPDATED_ADFUEL, ADFUEL)
                console.log("UPDATED ADFUEL", UPDATED_ADFUEL)
                let options = { ADFUEL: UPDATED_ADFUEL }
                console.log('Saving AdFuel Options: ', options)
                const formErrors = convertObjectToArray(errors)
                if (formErrors.length > 0) {
                  Swal.fire({
                    title: 'Invalid Form',
                    text: `${formErrors.length} error(s) found.`,
                    html: `<h3>${formErrors.length} error(s) found.</h3><pre>${JSON.stringify(formErrors, null, 2)}</pre>`,
                    icon: 'error'
                  })
                  return
                }
                if (!validJSON(options) || !validJSON(allOptions)) { 
                  return
                }
                allOptions.VERSION = '1.3'
                allOptions.NAME = site.name
                allOptions.TIMESTAMP = new Date().toLocaleString('en-US', { timeZone: 'America/New_York'})
                const theRest = async () => {
                  delete allOptions.PREBID.BIDDERS.ALLOWED
                  delete allOptions.PREBID.MODULES
                  const finalOptions = mergeDeep(allOptions, options)
                  const cdnPayload = {
                    name: siteNameFormatter(site.name),
                    options: finalOptions
                  }
                  const cdnRes = await saveAdFuelOptionFile(cdnPayload)
                  const dbPayload = {
                    site: site._id,
                    module: adFuelModuleId,
                    config: options[adFuelModuleName],
                    key: adFuelModuleName
                  }
                  const dbRes = await saveAdFuelModuleConfiguration(dbPayload)
                  if (cdnRes.status === 200 && dbRes.status === 200) {
                    Swal.fire({
                      title: 'Success',
                      text: 'Database updated and configuration file saved successfully.  Please click \'Save and Build\' under AIS Configuration to update your AIS file with the new AdFuel configuration.',
                      icon: 'success'
                    }).then(res => {
                      console.log('Success - SWAL Response: ', res)
                    })
                  } else if (cdnRes.status !== 200 && dbRes.status !== 200) {
                    // console.log('error response:', cdnRes, dbRes)
                    Swal.fire({
                      title: 'Dual Errors',
                      text: `An error occurred while updating database and saving configuration file: ${cdnRes.message}, ${dbRes.message}`,
                      icon: 'error'
                    }).then(res => {
                      console.log('Dual Errors - SWAL Response: ', res)
                    })
                  } else if (cdnRes.status !== 200) {
                    // console.log('error response:', cdnRes)
                    Swal.fire({
                      title: 'File Save Error',
                      text: `An error occurred while saving configuration file: ${cdnRes.message}`,
                      icon: 'error'
                    }).then(res => {
                      console.log('File Save Error - SWAL Response: ', res)
                    })
                    // console.log(res)
                  } else if (dbRes.status !== 200) {
                    // console.log('error response:', dbRes)
                    Swal.fire({
                      title: 'DB Update Error',
                      text: `An error occurred while updating the database: ${dbRes.message}`,
                      icon: 'error'
                    }).then(res => {
                      console.log('DB Error - SWAL Response: ', res)
                    })
                  }
                  setOpenDialog(false)
                }
                return theRest()
            }}
          >
            Save
          </Button>
          <Button variant='text' onClick={() => setOpenDialog(false)}>Close</Button>
        </CardActions>
      </Card>
    )
  }

  return (
    displayPropertyTable()
  )
}
