import React from 'react'

import { Card, Grid, CardContent, Table, TableBody, TableRow, TableCell, TextField, Typography } from '@material-ui/core'

export const MalvertisingDetection = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { MALVERTISING } = options
  const { SCRIPT_SRC } = MALVERTISING
  const { STRING } = validation
  const { LENGTH } = STRING

  const displayPropertyTable = () => {
    return (
      <Grid container>
        <Grid item xs={2}><Typography variant='h5'>Script Source</Typography></Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            margin='none'
            placeholder='Script Source'
            value={SCRIPT_SRC}
            error={errors.MALVERTISING_SS}
            helperText={errors.MALVERTISING_SS && LENGTH.message}
            onChange={(event) => {
              const value = event.target.value
              MALVERTISING.SCRIPT_SRC = value
              setErrors({ ...errors, MALVERTISING_SS: value.length < LENGTH.min || value.length > LENGTH.max })
              setOptions({ ...options, MALVERTISING })
              setAllOptions({ ...allOptions, MALVERTISING })
            }}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          {displayPropertyTable()}
        </Grid>
      </CardContent>
    </Card>
  )
}
