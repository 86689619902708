import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAdFuelOptions,
  getAdFuelOptionFile,
  saveAdFuelOptionFile
} from '../../modules/lookupsSlice'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import MaterialTable from 'material-table'
import { TableIcons } from '../ui/TableIcons'
import {
  Link,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Container,
  List,
  ListItem,
  Divider,
  Button
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import LaunchIcon from '@material-ui/icons/Launch'
import GetAppIcon from '@material-ui/icons/GetApp'
import SettingsIcon from '@material-ui/icons/Settings'
import { commonStyle } from '../../styles'

import { CustomDialog, LoadingProgress } from '../ui'
import { clone } from '../../utilities/helperFunctions.js'

const swal = withReactContent(Swal)

const download = async name => {
  try {
    const jsonFile = await getAdFuelOptionFile(name)
    const file = new window.Blob([jsonFile], { type: 'application/json' })
    const link = document.createElement('a')
    link.download = `${name}-options.json`
    link.href = URL.createObjectURL(file)
    link.click()
  } catch (err) {
    // console.log('err', err)
  }
}

const operators = {
  ADD_EDIT: 'Add/Edit',
  REMOVE: 'Remove'
}

const ModifyOptionsDialog = ({ data, setOpenDialog }) => {
  const commonClasses = commonStyle()
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [errors, setErrors] = useState({})
  const [operator, setOperator] = useState(operators.ADD_EDIT)

  const isValidInput = () => {
    const formErrors = {}
    if (key.trim() === '') {
      formErrors.key = { message: 'Key is required', type: 'required' }
    }
    if (operator === operators.ADD_EDIT) {
      if (value.trim() === '') {
        formErrors.value = { message: 'Value is required', type: 'required' }
      }
      try {
        JSON.parse(value.trim())
      } catch (err) {
        formErrors.value = {
          message: 'Value is not valid JSON',
          type: 'invalidJSON'
        }
      }
    }
    setErrors(formErrors)
    return !(formErrors.key || formErrors.value)
  }

  const applyChanges = async () => {
    const results = await Promise.all(
      data.map(async item => {
        const options = await getAdFuelOptionFile(item.name)
        const keys = key.trim().split(/\./)
        const lastIndex = keys.length - 1
        const lastKey = keys[lastIndex]
        let property = options
        keys.forEach((key, index) => {
          if (!property[key]) {
            property[key] = {}
          }
          if (index < lastIndex) {
            property = property[key]
          }
        })
        switch (operator) {
          case operators.ADD_EDIT:
            property[lastKey] = JSON.parse(value.trim())
            break
          case operators.REMOVE:
            delete property[lastKey]
            break
        }
        const payload = { name: item.name, options }
        return await saveAdFuelOptionFile(payload)
      })
    )
    const positiveResults = results.filter(result => result.status === 200)
    swal.fire({
      title: `${positiveResults.length}/${results.length} file(s) have been successfully modified.`,
      icon: 'success'
    })
    setOpenDialog(false)
  }

  return (
    <Card>
      <CardHeader title='Modify' />
      <Divider />
      <CardContent>
        <FormControl>
          <InputLabel id='operator-label'>Operator</InputLabel>
          <Select
            labelId='operator-label'
            style={{ minWidth: '200px' }}
            value={operator}
            onChange={event => {
              setOperator(event.target.value)
            }}
          >
            <MenuItem value={operators.ADD_EDIT}>{operators.ADD_EDIT}</MenuItem>
            <MenuItem value={operators.REMOVE}>{operators.REMOVE}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label='Key'
          value={key}
          error={!!errors.key}
          helperText={errors.key && errors.key.message}
          onChange={event => {
            setKey(event.target.value)
            if (errors.key) {
              setErrors({})
            }
          }}
        />
        <TextField
          fullWidth
          label='Value'
          multiline
          value={value}
          error={!!errors.value}
          helperText={errors.value && errors.value.message}
          onChange={event => {
            setValue(event.target.value)
            if (errors.value) {
              setErrors({})
            }
          }}
        />
        <Box mt={3}>
          <MuiAlert variant='filled' severity='info'>
            <List subheader='Note' dense>
              <ListItem dense>
                Key should start with the module name, ex. PREBID, SSAI, IVR
              </ListItem>
              <ListItem dense>
                Value needs to be in JSON format, don't forget the double
                quotes.
              </ListItem>
              <ListItem dense>Add/Edit WILL replace existing values.</ListItem>
              <ListItem dense>
                Data structural changes WILL affect AIS configurations and the
                modules that use them. Be sure to update those as well.
              </ListItem>
            </List>
          </MuiAlert>
        </Box>
      </CardContent>
      <Divider />
      <CardActions className={commonClasses.actionArea}>
        <Button variant='text' onClick={() => setOpenDialog(false)}>
          Cancel
        </Button>
        <Button
          className={commonClasses.btnSuccess}
          variant='contained'
          onClick={() => {
            if (!isValidInput()) {
              return
            }
            swal.fire({
              title: 'Are you sure?',
              text: `${data.length} file(s) will be affected.`,
              icon: 'warning',
              showCancelButton: true
              // buttons: true,
              // dangerMode: true
            }).then(ok => {
              if (ok.isConfirmed) {
                applyChanges()
              }
            })
          }}
        >
          Apply
        </Button>
      </CardActions>
    </Card>
  )
}

export const AdFuelOptions = () => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { adFuelOptions } = useSelector(state => state.lookups)
  const [optionsList, setOptionsList] = useState(clone(adFuelOptions))
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [isLoaded, setIsLoaded] = useState(true)

  const openViewDialog = (jsonFile, filename) => {
    setDialog({
      title: filename,
      content: (
        <CardContent>
          <pre>{JSON.stringify(jsonFile, null, 2)}</pre>
        </CardContent>
      )
    })
    setOpenDialog(true)
  }

  const modify = data => {
    setDialog({
      size: 'sm',
      content: (
        <ModifyOptionsDialog data={data} setOpenDialog={setOpenDialog} />
      ),
      displayDialogAction: false
    })
    setOpenDialog(true)
  }

  useEffect(() => {
    if (adFuelOptions.length === 0) {
      dispatch(getAdFuelOptions())
    } else {
      setOptionsList(clone(adFuelOptions))
    }
  }, [adFuelOptions])

  useEffect(() => {
    setIsLoaded(false)
  }, [])

  return (
    <Container maxWidth='xl'>
      <Card>
        <CardHeader title='AdFuel Options' />
        <CardContent className={commonClasses.cardBkClr}>
          <Card>
            <CardContent>
              {isLoaded
                ? (
                  <LoadingProgress label='Loading' />
                  )
                : (
                  <MaterialTable
                    title={'Files'}
                    icons={TableIcons}
                    data={optionsList}
                    options={{
                      emptyRowsWhenPaging: false,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 50, 100],
                      selection: true,
                      headerStyle: {
                        fontWeight: 'bold'
                      }
                    }}
                    columns={[
                      { title: 'Name', field: 'name' },
                      {
                        title: 'Url',
                        field: 'url',
                        render: rowData => (
                          <Link
                            className={commonClasses.linkColor}
                            href={rowData.url}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {!rowData.url ? '' : rowData.url.replace(/.*options\//, '')}
                          </Link>
                        )
                      }
                    ]}
                    actions={[
                      {
                        icon: SettingsIcon,
                        tooltip: 'Modify',
                        onClick: (event, data) => {
                          Promise.all(
                            data.map(async rowData => {
                              await modify(data)
                            })
                          )
                        }
                      },
                      {
                        icon: LaunchIcon,
                        tooltip: 'View',
                        onClick: async (event, data) => {
                          const rowData = data[0]
                          try {
                            const jsonFile = await getAdFuelOptionFile(rowData.name)
                            openViewDialog(jsonFile, rowData.name)
                          } catch (err) {
                          // console.log('err', err)
                          }
                        }
                      },
                      {
                        icon: GetAppIcon,
                        tooltip: 'Download',
                        onClick: (event, data) => {
                          Promise.all(
                            data.map(async rowData => {
                              await download(rowData.name)
                            })
                          )
                        }
                      }
                    ]}
                  />
                  )}

              <CustomDialog
                open={openDialog}
                setOpenDialog={setOpenDialog}
                title={dialog.title}
                subtitle={dialog.subtitle}
                content={dialog.content}
                displayDialogAction={dialog.displayDialogAction}
                customAction={dialog.customAction}
                size={dialog.size}
              />
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </Container>
  )
}
