import React, { useState } from 'react'
import {
  Box,
  Tabs,
  Tab,
  IconButton
} from '@material-ui/core'
import {
  CloseRounded
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const verticalStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

const horizontalStyles = makeStyles(theme => ({
  root: {},
  tabs: {}
}))

export default function TabPanels (props) {
  const { tabs, orientation } = props
  const [value, setValue] = useState(0)
  const classes = !orientation ? horizontalStyles() : verticalStyles()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  if (tabs.length === 0) { return (<div />) }
  return (
    <div className={classes.root}>
      <Tabs value={value} orientation={!orientation ? 'horizontal' : 'vertical'} onChange={handleChange} variant='scrollable' scrollButtons='auto' aria-label='tabs'>
        {tabs.map(tab =>
          <Tab
            className={classes.tabs}
            key={'tab-header' + tab.id} component='div' label={
              <div>
                {tab.header}
                {tab.removable &&
                  <IconButton size='small'>
                    <CloseRounded onClick={() => tab.removeTab(tab.id)} />
                  </IconButton>}
              </div>
            } id={'tab' + tab.id} aria-controls={'tabpanel-' + tab.id}
          />
        )}
      </Tabs>
      {tabs.map(tab =>
        <Box
          className={tab.bodyClassName ? tab.bodyClassName : ''}
          component='div'
          role='tabpanel'
          hidden={value !== tab.id}
          id={'tab-' + tab.id}
          aria-labelledby={'tab-' + tab.id}
          key={'tab-body' + tab.id}
          p={0}
        >
          {tab.body}
        </Box>
      )}
    </div>
  )
}

TabPanels.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.element,
    bodyClassName: PropTypes.string
  }))
}
