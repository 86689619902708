import React from 'react'
import {
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  List,
  ListItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  labelText: {
    fontSize: '1.10em'
  }
}))

export const LoadingProgress = ({ circular = true, label = 'Loading' }) => {
  const _classes = useStyles()
  return (
    <Card>
      <CardContent>
        {circular ? <CircularProgress /> : <LinearProgress />}
        <List>
          <ListItem className={_classes.labelText}>{label}...</ListItem>
        </List>
      </CardContent>
    </Card>
  )
}
