import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store/redux'
import { runWithAdal } from 'react-adal'
import { authContext } from './utilities/adal-config/azure-auth'
import { CssBaseline } from '@material-ui/core'
import './index.css'
import { App } from './App'

const DO_NOT_LOGIN = true

runWithAdal(authContext, () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CssBaseline />
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )
}, DO_NOT_LOGIN)
