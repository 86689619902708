import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  resMessage: null,
  frequencies: ['hourly', 'daily', 'weekly'],
  adFuelModules: [],
  orgAdFuelModules: [],
  adFuelModule: {},
  loading: false,
  error: null
}

const adFuelModulesSlice = createSlice({
  name: 'adFuelModules',
  initialState,
  reducers: {
    FetchAdFuelModules: (state, action) => {
      state.loading = false
      state.adFuelModules = action.payload
      state.orgAdFuelModules = action.payload
    },

    UpdateAdFuelModule: (state, action) => {
      const adFuelModules = action.payload.adFuelModules
      state.loading = false
      state.adFuelModules = adFuelModules
      state.orgAdFuelModules = adFuelModules
      swal.fire({
        title: 'AdFuel Modules Updated!',
        text: `AdFuel Module [${action.payload.adFuelModule.name}] Updated.`,
        icon: 'success'
      })
    },
    RemoveAdFuelModule: (state, action) => {
      const adFuelModules = action.payload.adFuelModules
      state.loading = false
      state.adFuelModules = adFuelModules
      state.orgAdFuelModules = adFuelModules
      swal.fire({
        title: 'AdFuel Modules Updated!',
        text: `AdFuel Module [${action.payload.adFuelModule.name}] Removed.`,
        icon: 'success'
      })
    },
    FilterAdFuelModule: (state, action) => {
      const results = filterByValue(
        state.orgAdFuelModules,
        action.payload.searchTerm
      )
      state.loading = false
      state.adFuelModules = results
    },

    AdFuelModuleLoadingStatus: state => {
      state.loading = true
    },
    AdFuelModuleError: (state, action) => {
      state.loading = false
      state.error = action.payload.error
      handleError(action.payload, showError)
    }
  }
})

export default adFuelModulesSlice.reducer
export const {
  FetchAdFuelModules,
  UpdateAdFuelModule,
  RemoveAdFuelModule,
  FilterAdFuelModule,
  SendAdFuelModule,
  AdFuelModuleLoadingStatus,
  AdFuelModuleError
} = adFuelModulesSlice.actions

const filterByValue = (array, string) => {
  return array.filter(o =>
    Object.keys(o).some(k =>
      o[k]
        .toString()
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  )
}

export const fetchAdFuelModules = () => {
  return async dispatch => {
    dispatch(AdFuelModuleLoadingStatus())
    return request('/lookups/adFuelModules')
      .then(res => {
        dispatch(FetchAdFuelModules(res.data))
      })
      .catch(err => dispatch(AdFuelModuleError(err)))
  }
}

export const isModuleInUse = (module) => {
  // return request('/lookups/')
}

export const removeAdFuelModule = (adFuelModules, adFuelModule) => {
  return async dispatch => {
    request(`/lookups/adFuelModules/${adFuelModule._id}`, 'DELETE', adFuelModule)
      .then(res => {
        dispatch(
          RemoveAdFuelModule({
            adFuelModules,
            adFuelModule
          })
        )
      }).catch(err => {
        dispatch(AdFuelModuleError(err))
      })
  }
}

export const updateAdFuelModules = (adFuelModules, adFuelModule) => {
  return async dispatch => {
    request(`/lookups/adFuelModules/${adFuelModule._id}`, 'POST', adFuelModule)
      .then(res => {
        dispatch(
          UpdateAdFuelModule({
            adFuelModules,
            adFuelModule
          })
        )
      })
      .catch(err => dispatch(AdFuelModuleError(err)))
  }
}

export const createAdFuelModule = (adFuelModules, adFuelModule) => {
  return async dispatch => {
    request('/lookups/adFuelModules', 'POST', adFuelModule)
      .then(res => {
        dispatch(
          UpdateAdFuelModule({
            adFuelModules,
            adFuelModule
          })
        )
      })
      .catch(err => dispatch(AdFuelModuleError(err)))
  }
}

export const filterAdFuelModule = (adFuelModules, searchTerm) => {
  return async dispatch => {
    dispatch(AdFuelModuleLoadingStatus())
    dispatch(
      FilterAdFuelModule({
        adFuelModules,
        searchTerm
      })
    )
  }
}
