import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'
// import fetch from 'cross-fetch'

// const GET_SLOT_TYPES = 'GET_SLOT_TYPES'
// const GET_SLOT_TYPES_SUCCESS = 'GET_SLOT_TYPES_SUCCESS'
// const GET_SLOT_TYPES_FAILURE = 'GET_SLOT_TYPES_FAILURE'

// const GET_SLOT_POSITIONS = 'GET_SLOT_POSITIONS'
// const GET_SLOT_POSITIONS_SUCCESS = 'GET_SLOT_POSITIONS_SUCCESS'
// const GET_SLOT_POSITIONS_FAILURE = 'GET_SLOT_POSITIONS_FAILURE'

// const GET_ADFUEL_VERSIONS = 'GET_ADFUEL_VERSIONS'
// const GET_ADFUEL_VERSIONS_SUCCESS = 'GET_ADFUEL_VERSIONS_SUCCESS'
// const GET_ADFUEL_VERSIONS_FAILURE = 'GET_ADFUEL_VERSIONS_FAILURE'

// const GET_ADFUEL_MODULES = 'GET_ADFUEL_MODULES'
// const GET_ADFUEL_MODULES_SUCCESS = 'GET_ADFUEL_MODULES_SUCCESS'
// const GET_ADFUEL_MODULES_FAILURE = 'GET_ADFUEL_MODULES_FAILURE'

const swal = withReactContent(Swal)

const initialState = {
  versions: [],
  orgVersions: [],
  modules: [],
  orgModules: [],
  adFuelOptions: [],
  file: null,
  slotTypes: [],
  slotPositions: [],
  categories: [],
  loading: false,
  error: null
}

const lookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {
    LookupsLoadingStatus: state => {
      state.loading = true
    },
    FetchSlotPositions: (state, action) => {
      state.loading = false
      state.slotPositions = action.payload
    },
    FetchSlotTypes: (state, action) => {
      state.loading = false
      state.slotTypes = action.payload
    },

    FetchAdFuelModules: (state, action) => {
      state.loading = false
      state.modules = action.payload
      state.orgModules = action.payload
    },
    CreateAdFuelModule: (state, action) => {
      const adFuelModules = state.modules.slice()
      adFuelModules.unshift(action.payload)
      state.loading = false
      state.modules = adFuelModules
      state.orgModules = adFuelModules
      swal.fire({
        title: 'AdFuelModules Saved!',
        text: 'AdFuelModules saved successfully.',
        icon: 'success'
      })
    },
    UpdateAdFuelModule: (state, action) => {
      const adFuelModules = state.modules.slice()
      const updatedItemIndex = adFuelModules.findIndex(
        adFuelModule => adFuelModule._id === action.payload._id
      )
      adFuelModules[updatedItemIndex] = action.payload

      state.loading = false
      state.modules = adFuelModules
      state.orgModules = adFuelModules
      swal.fire({
        title: 'AdFuelModule Updated!',
        text: 'AdFuelModule Updated.',
        icon: 'success'
      })
    },
    FilterAdFuelModule: (state, action) => {
      const results = filterByValue(state.orgModules, action.payload)
      state.loading = false
      state.modules = results
    },

    FetchAdFuelVersions: (state, action) => {
      state.loading = false
      state.versions = action.payload
      state.orgVersions = action.payload
    },
    CreateAdFuelVersion: (state, action) => {
      const adFuelVersions = state.versions.slice()
      adFuelVersions.unshift(action.payload)
      state.loading = false
      state.versions = adFuelVersions
      state.orgVersions = adFuelVersions
      swal.fire({
        title: 'AdFuelVersions Saved!',
        icon: 'success'
      })
    },
    UpdateAdFuelVersion: (state, action) => {
      const adFuelVersions = state.versions.slice()
      const index = adFuelVersions.findIndex(
        adFuelVersion => adFuelVersion._id === action.payload._id
      )
      adFuelVersions[index] = action.payload
      state.loading = false
      state.versions = adFuelVersions
      state.orgVersions = adFuelVersions
      swal.fire({
        title: `The adFuelVersion "${action.payload.name}" has been Updated!`,
        icon: 'success'
      })
    },
    DeleteAdFuelVersion: (state, action) => {
      const adFuelVersions = state.versions
        .slice()
        .filter(adFuelVersion => adFuelVersion._id !== action.payload._id)
      state.loading = false
      state.versions = adFuelVersions
      state.orgVersions = adFuelVersions
      swal.fire({
        title: `The adFuelVersion "${action.payload.name}" has been deleted!`,
        icon: 'success'
      })
    },
    FilterAdFuelVersion: (state, action) => {
      const results = filterByValue(state.orgVersions, action.payload)
      state.loading = false
      state.versions = results
    },
    setAdFuelOptions: (state, action) => {
      state.adFuelOptions = action.payload
    },
    setFile: (state, action) => {
      state.file = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    LookupsError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    }
  }
})

export default lookupsSlice.reducer
export const {
  LookupsLoadingStatus,
  FetchSlotPositions,
  FetchSlotTypes,
  FetchAdFuelModules,
  CreateAdFuelModule,
  UpdateAdFuelModule,
  FilterAdFuelModule,
  FetchAdFuelVersions,
  CreateAdFuelVersion,
  UpdateAdFuelVersion,
  DeleteAdFuelVersion,
  FilterAdFuelVersion,
  setFile,
  setAdFuelOptions,
  setCategories,
  LookupsError
} = lookupsSlice.actions

const filterByValue = (array, string) => {
  return array.filter(arrObject =>
    Object.keys(arrObject).some(element =>
      // Some() allows you to test if an array has at least one element that meets a condition.
      arrObject[element]
        .toString()
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  )
}

export const fetchSlotPositions = () => {
  return async dispatch => {
    dispatch(LookupsLoadingStatus())
    return request('/slotPositions')
      .then(res => dispatch(FetchSlotPositions(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }
  // return async dispatch => {
  //   dispatch(lookupsSlice.actions[GET_SLOT_POSITIONS]())
  //   return fetch('http://localhost:3030/api/slotPositions')
  //     .then(
  //       response => response.json(),
  //       error =>
  //         dispatch(
  //           lookupsSlice.actions[GET_SLOT_POSITIONS_FAILURE]({
  //             error: error.toString()
  //           })
  //         )
  //     )
  //     .then(json =>
  //       dispatch(
  //         lookupsSlice.actions[GET_SLOT_POSITIONS_SUCCESS]({
  //           slotPositions: json
  //         })
  //       )
  //     )
  //     .catch(err =>
  //       dispatch(
  //         lookupsSlice.actions[GET_SLOT_POSITIONS_FAILURE]({
  //           error: err.toString()
  //         })
  //       )
  //     )
  // }
}

export const fetchSlotTypes = () => {
  return async dispatch => {
    dispatch(LookupsLoadingStatus())
    return request('/slotTypes')
      .then(res => dispatch(FetchSlotTypes(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }

  // return async dispatch => {
  //   dispatch(lookupsSlice.actions[GET_SLOT_TYPES]())
  //   return fetch('http://localhost:3030/api/slotTypes')
  //     .then(
  //       response => response.json(),
  //       error =>
  //         dispatch(
  //           lookupsSlice.actions[GET_SLOT_TYPES_FAILURE]({
  //             error: error.toString()
  //           })
  //         )
  //     )
  //     .then(json =>
  //       dispatch(
  //         lookupsSlice.actions[GET_SLOT_TYPES_SUCCESS]({ slotTypes: json })
  //       )
  //     )
  //     .catch(err =>
  //       dispatch(
  //         lookupsSlice.actions[GET_SLOT_TYPES_FAILURE]({
  //           error: err.toString()
  //         })
  //       )
  //     )
  // }
}

// AdFuelModule
export const fetchAdFuelModules = () => {
  return async dispatch => {
    dispatch(LookupsLoadingStatus())
    return request('/lookups/adFuelModules')
      .then(res => dispatch(FetchAdFuelModules(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }
  // return async dispatch => {
  //   dispatch(lookupsSlice.actions[GET_ADFUEL_MODULES]())
  //   return fetch(
  //     'https://api.rocketeer.turnerapps.com/api/lookups/adFuelModules'
  //   )
  //     .then(
  //       response => response.json(),
  //       error =>
  //         dispatch(
  //           lookupsSlice.actions[GET_ADFUEL_MODULES_FAILURE]({
  //             error: JSON.stringify(error.message)
  //           })
  //         )
  //     )
  //     .then(json =>
  //       dispatch(
  //         lookupsSlice.actions[GET_ADFUEL_MODULES_SUCCESS]({ modules: json })
  //       )
  //     )
  //     .catch(err =>
  //       dispatch(
  //         lookupsSlice.actions[GET_ADFUEL_MODULES_FAILURE]({
  //           error: JSON.stringify(err.message)
  //         })
  //       )
  //     )
  // }
}

export const createAdFuelModule = adFuelModule => {
  return async dispatch => {
    dispatch(LookupsLoadingStatus())
    return request('/adFuelModules', 'POST', adFuelModule)
      .then(res => dispatch(CreateAdFuelModule(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }
}

// AdFuelVersions
export const fetchAdFuelVersions = () => {
  return async dispatch => {
    // // console.log('fetchAdFuelVersions')
    dispatch(LookupsLoadingStatus())
    return request('/lookups/adFuelVersions')
      .then(res => dispatch(FetchAdFuelVersions(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }
}

export const createAdFuelVersion = adFuelVersion => {
  return async dispatch => {
    dispatch(LookupsLoadingStatus())
    return request('/lookups/adFuelVersions', 'POST', adFuelVersion)
      .then(res => dispatch(CreateAdFuelVersion(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }
}

export const updateAdFuelVersion = adFuelVersion => {
  return async dispatch => {
    dispatch(LookupsLoadingStatus())
    return request(
      `/lookups/adFuelVersions/${adFuelVersion._id}`,
      'POST',
      adFuelVersion,
      true
    )
      .then(res => dispatch(UpdateAdFuelVersion(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }
}

export const deleteAdFuelVersion = adFuelVersion => {
  return async dispatch => {
    dispatch(LookupsLoadingStatus())
    return request(
      `/lookups/adFuelVersions/${adFuelVersion._id}`,
      'DELETE',
      adFuelVersion
    )
      .then(res => dispatch(DeleteAdFuelVersion(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }
}

export const filterAdFuelVersion = searchTerm => {
  return async dispatch => {
    dispatch(LookupsLoadingStatus())
    dispatch(FilterAdFuelVersion(searchTerm))
  }
}

export const getAdFuelOptions = () => {
  return async dispatch => {
    return request('/lookups/adFuelOptions')
      .then(res => dispatch(setAdFuelOptions(res.data)))
      .catch(err => dispatch(LookupsError(err)))
  }
}

export const getAdFuelOptionFile = (name) => {
  return request(`/lookups/getAdFuelOptionFile?name=${name}`)
    .then(res => res.data)
    .catch(err => err)
}

export const getFileContents = (url) => {
  return request(`/lookups/getFileContents?url=${url}`)
    .then(res => res.data)
    .catch(err => err)
}

export const buildPrebidLibrary = ({ name, components }) => {
  return request(`/lookups/buildPrebidLibrary?name=${name}`, 'POST', components)
    .then(res => res)
    .catch(err => err)
}

export const saveAdFuelOptionFile = ({ name, options }) => {
  return request(`/lookups/saveAdFuelOptionFile?name=${name}`, 'POST', options)
    .then(res => res)
    .catch(err => err)
}

export const getAdFuelModuleConfigurations = site => {
  return request(`/lookups/getAdFuelModuleConfigurations?site=${site}`)
    .then(res => res)
    .catch(err => err)
}

export const getAdFuelModuleConfiguration = (site, module) => {
  return request(`/lookups/getAdFuelModuleConfiguration?site=${site}&module=${module}`)
    .then(res => res)
    .catch(err => err)
}

export const getAdFuelModuleCoreConfiguration = () => {
  return request('/lookups/getAdFuelModuleCoreConfiguration')
    .then(res => res.data)
    .catch(err => err)
}

export const saveAdFuelModuleConfiguration = (options) => {
  // console.log('Saving AdFuel Module Configuration: ', options)
  return request('/lookups/saveAdFuelModuleConfiguration', 'POST', options)
    .then(res => {
      // console.log({ res: res })
      return res
    })
    .catch(err => {
      return { status: 500, message: err.message }
    })
}

export const getCategories = () => {
  return async dispatch => {
    return request('/lookups/categories')
      .then(res => { dispatch(setCategories(res.data)) })
      .catch(err => err)
  }
}
