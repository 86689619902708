/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css'

const swal = withReactContent(Swal)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  hidden: {
    display: 'none',
    visibility: 'collapse'
  },
  selected: {
    backgroundColor: '#333',
    color: '#FFF'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  formLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bolder'
  },
  spacer: { margin: theme.spacing(1) },
  saveButton: { color: '#2D2' },
  notesSaveButton: { backgroundColor: '#2A2', color: '#FFF' },
  argDeleteButton: { backgroundColor: '#D22', color: '#FFF' },
  dialogCloseButton: { backgroundColor: '#222', color: '#FFF' },
  deleteButton: { color: '#D22' },
  plainText: { fontSize: theme.typography.pxToRem(18) },
  bolder: { fontWeight: 'bolder' },
  buttonGroup: {
    marginTop: theme.spacing(1),
    position: 'relative',
    top: 5
  }
}))

const ReleaseNotesDialog = (props) => {
  // // console.log('Release Notes Dialog Props: ', props)
  const classes = useStyles()

  const mdParser = new MarkdownIt(/* Markdown-it options */)
  // Finish!

  const [releaseNotes, setReleaseNotes] = useState(props?.version?.releaseNotes || '# NO RELEASE NOTES')
  const [open, setOpen] = useState(props.open)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    setOpen(props.open || false)
    // // console.log('A Setting Release Notes: ', props?.version?.releaseNotes || '# NO RELEASE NOTES')
    setReleaseNotes(props?.version?.releaseNotes || '# NO RELEASE NOTES')
  }, [props.open, props.version])

  const handleEditorChange = ({ html, text }) => {
    setIsDirty(text !== (props?.version?.releaseNotes || '# NO RELEASE NOTES'))
    setReleaseNotes(text)
  }

  const handleClose = e => {
    if (isDirty) {
      swal.fire({
        title: 'Are you sure?',
        text: 'You have unsaved changes to the selected argument.',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(willClose => {
        if (willClose.isConfirmed) {
          setOpen(false)
          if (props.handleClose) {
            // console.log('deferring to parent component: ', `${props.module.name}`)
            props.handleClose(props.module)
          }
        }
      })
    } else {
      setOpen(false)
      if (props.handleClose) {
        // console.log('deferring to parent component: ', `${props.module.name}`)
        props.handleClose(props.module)
      }
    }
  }

  const handleSave = e => {
    if (isDirty) {
      swal.fire({
        title: 'Are you sure you want to save the release notes?',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(willSave => {
        if (willSave.isConfirmed) {
          setIsDirty(false)
          if (props.handleSave) {
            // console.log('release notes: ', releaseNotes)
            props.handleSave(props.module, props.version, releaseNotes)
          }
        }
      })
    } else {
      swal.fire({
        text: 'There are no unsaved changes.'
      })
    }
  }

  const mainForm = (
    <form autoComplete='off'>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <MdEditor
            value={releaseNotes}
            style={{ height: '500px', width: '800px' }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={handleEditorChange}
          />
        </Grid>
      </Grid>
    </form>
  )

  return (
    <Dialog maxWidth='xl' open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{`${props.module.name} v${props.version.name} Release Notes`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mainForm}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.dialogCloseButton}>
          Close
        </Button>
        <Button
          variant='contained'
          onClick={handleSave}
          className={isDirty ? classes.notesSaveButton : classes.hidden}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ReleaseNotesDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  module: PropTypes.object,
  version: PropTypes.object
}

export default ReleaseNotesDialog
