import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  makeStyles,
  FormLabel,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Container,
  FormGroup,
  Typography
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {
  updateBannerMessage,
  fetchBannerMessage
} from '../../modules/bannerMessageSlice'
import { commonStyle } from '../../styles'
import { LoadingProgress } from '../ui'

const useStyles = makeStyles(theme => ({
  flexAlignItemsEnd: {
    alignItems: 'flex-end'
  },
  justify: {
    justifyContent: 'space-between'
  }
}))

const BannerMessage = () => {
  const classes = useStyles()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { banner } = useSelector(state => state.bannerMessageSlice)
  const [bannerMessage, setBannerMessage] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [isLoaded, setIsLoaded] = useState(true)

  const bannerMessageChange = event => {
    setBannerMessage({ ...bannerMessage, message: event.target.value })
  }

  const saveBannerMessage = () => {
    dispatch(updateBannerMessage(bannerMessage))
  }

  const updateSeverity = event => {
    const severity = event.target.value
    setSeverity(severity)
    setBannerMessage({ ...bannerMessage, severity })
  }

  useEffect(() => {
    if (banner) {
      setBannerMessage(banner)
      setSeverity(banner.severity)
    } else dispatch(fetchBannerMessage())
  }, [banner])

  useEffect(() => {
    setIsLoaded(false)
  }, [])

  return (
    <Container maxWidth='xl'>
      {isLoaded
        ? (
          <LoadingProgress label='Loading' />
          )
        : (
          <form autoComplete='off' noValidate>
            <Card>
              <CardHeader title='Banner Message' />
              <Divider />
              <CardContent className={commonClasses.cardBkClr}>
                <Card>
                  <CardContent>
                    <FormGroup row className={classes.justify}>
                      <FormControl component='fieldset'>
                        <FormLabel component='legend'>Severity</FormLabel>
                        <RadioGroup
                          style={{ flexDirection: 'row' }}
                          aria-label='severity'
                          name='severity'
                          value={severity}
                          onChange={updateSeverity}
                        >
                          <FormControlLabel
                            value='error'
                            control={<Radio />}
                            label='Error'
                          />
                          <FormControlLabel
                            value='warning'
                            control={<Radio />}
                            label='Warning'
                          />
                          <FormControlLabel
                            value='success'
                            control={<Radio />}
                            label='Success'
                          />
                          <FormControlLabel
                            value='info'
                            control={<Radio />}
                            label='Info'
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormGroup>
                    <FormGroup className={classes.flexAlignItemsEnd}>
                      <TextField
                        fullWidth
                        label='Message'
                        margin='normal'
                        name='txtMessage'
                        value={bannerMessage.message || ''}
                        variant='outlined'
                        multiline
                        rows={5}
                        onChange={bannerMessageChange}
                      />
                      <Button
                        className={[classes.button, commonClasses.btnSuccess].join(
                          ' '
                        )}
                        variant='contained'
                        onClick={saveBannerMessage}
                        startIcon={<CheckCircleIcon />}
                      >
                        Save
                      </Button>
                    </FormGroup>
                  </CardContent>
                </Card>
                <br/>
                <Card>
                  <CardContent>
                    <Typography>
                      Adding a hyperlink to the banner message can be accomplished by
                      manually adding the following code to the banner message text:
                    </Typography>
                    <TextField
                      margin='normal'
                      disabled
                      fullWidth
                      variant='outlined'
                      defaultValue='<a href="INSERT URL HERE" target="_blank">INSERT DESCRIPTION HERE</a>'
                    />
                    <Typography>Example: </Typography>
                    <TextField
                      margin='normal'
                      disabled
                      fullWidth
                      variant='outlined'
                      defaultValue='<a href="https://jiraprod.turner.com/projects/CSD/versions/24788" target="_blank">here</a>'
                    />
                  </CardContent>
                </Card>
              </CardContent>
            </Card>
          </form>
          )}
    </Container>
  )
}

export default BannerMessage
