import React, { useState, useEffect } from 'react'
import { getSlotsDataFile, updateSlots } from '../../../modules/registrySlice'

import parseCsv from 'parse-csv'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Grid, CardContent, CardActions, Card, CardHeader, Divider, List, ListItem, Typography, Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import PublishIcon from '@material-ui/icons/Publish'

import { commonStyle } from '../../../styles'

const swal = withReactContent(Swal)

const slotInformation = {
  title: 'Update Slot Level Properties',
  instructions: [
    'Select the data Level on the left. It is set to Slot by default',
    'Click Download above to download most recent slot info.',
    'Update slot info by modifying the CSV file in Excel or a preferred text editor.',
    'Click Choose File below to select the modified CSV file to upload.',
    'Click Upload below to upload selected file.'
  ]
}

const viewportInformation = {
  title: 'Update Viewport Level Properties',
  instructions: [
    'Select the data Level on the left. It is set to Slot by default',
    'Click Download above to download most recent viewport info.',
    'Update viewport info by modifying the CSV file in Excel or a preferred text editor.',
    'Click Choose File below to select the modified CSV file to upload.',
    'Click Upload below to upload selected file.'
  ]
}

const displayInstructionSet = ({ title, instructions }) => {
  return (
    <List subheader={title} dense>
      {instructions.map((item, index) => {
        const number = index + 1
        return (<ListItem dense key={index}>{number + '. ' + item}</ListItem>)
      })}
    </List>
  )
}

const convertCSVToArray = (data) => {
  const date = new Date()
  const results = []
  data = data.replace(/ufeff/g, '')
  const jsonObject = parseCsv.toJSON(data, { headers: { included: true } })
  jsonObject.forEach(function (object) {
    delete object['']
    object.date = date
    results.push(JSON.parse(JSON.stringify(object)))
  })
  return results
}

const convertObjectToArray = (object) => {
  return Object.entries(object).map(entry => entry[1])
}

// converts csv into json objects
// then converts the array of objects into key value pairs
// this makes fetching data faster and puts the slots into
// the parent registry
const formatSlotData = (data) => {
  const records = convertCSVToArray(data)
  let result = {}
  records.forEach(record => {
    if (!result[record.registry_id]) {
      result[record.registry_id] = {
        registry_id: record.registry_id,
        registry_name: record.registry_name,
        slotsData: []
      }
    }
    result[record.registry_id].slotsData.push(record)
  })
  result = convertObjectToArray(result)
  return result
}

// converts csv into json objects
// then converts the array of objects into key value pairs
// this makes fetching data faster and puts the slots into
// the parent registry and viewports into the parent slot
const formatViewportData = (data) => {
  const records = convertCSVToArray(data)
  let result = {}
  records.forEach(record => {
    if (!result[record.registry_id]) {
      result[record.registry_id] = {
        registry_id: record.registry_id,
        registry_name: record.registry_name,
        slotsData: {}
      }
    }
    if (!result[record.registry_id].slotsData[record.slot_id]) {
      result[record.registry_id].slotsData[record.slot_id] = {
        registry_id: record.registry_id,
        registry_name: record.registry_name,
        slot_id: record.slot_id,
        slot_name: record.slot_name,
        viewportsData: []
      }
    }
    result[record.registry_id].slotsData[record.slot_id].viewportsData.push(record)
  })
  result = convertObjectToArray(result)
  result.forEach((registryData, index) => {
    const { slotsData } = registryData
    result[index].slotsData = convertObjectToArray(slotsData)
  })
  return result
}

const LEVEL_TYPE = {
  SLOT: 'Slot',
  VIEWPORT: 'Viewport'
}

export const ManageSlotsDialog = ({ site, setOpenDialog }) => {
  const commonClasses = commonStyle()
  const [info, setInfo] = useState(slotInformation)
  const [levelType, setLevelType] = useState(LEVEL_TYPE.SLOT)
  const [downloading, setDownloading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [file, setFile] = useState(null)
  let fileInputElement

  useEffect(() => {
    switch (levelType) {
      case LEVEL_TYPE.SLOT:
        setInfo(slotInformation)
        break
      case LEVEL_TYPE.VIEWPORT:
        setInfo(viewportInformation)
        break
      default:
    }
  }, [levelType])

  return (
    <Card>
      <CardHeader
        title='Slot Manager'
        action={(
          <Button
            disabled={downloading}
            className={!downloading ? commonClasses.btnInfo : ''}
            variant='contained'
            onClick={async () => {
              if (!site._id) { return }
              setDownloading(true)
              const res = await getSlotsDataFile(site._id, levelType)
              if (res.status === 200) {
                const data = new window.Blob([res.data], { type: 'text/csv' })
                const link = document.createElement('a')
                link.download = `${site.name}_${levelType}_Data_${Date.now()}.csv`
                link.href = URL.createObjectURL(data)
                link.click()
              } else {
                swal.fire({ title: `Error processing file. ${res.status}: ${res.data}`, icon: 'error' })
              }
              setDownloading(false)
            }}
            startIcon={<GetAppIcon />}
          >
            {downloading ? 'Downloading...' : 'Download'}
          </Button>
        )}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <FormControl component='fieldset'>
              <FormLabel focused component='legend'>Level</FormLabel>
              <RadioGroup aria-label='levelType' name='levelType' value={levelType} onChange={(event) => setLevelType(event.target.value)}>
                <FormControlLabel value={LEVEL_TYPE.SLOT} control={<Radio color='primary' />} label={LEVEL_TYPE.SLOT} />
                <FormControlLabel value={LEVEL_TYPE.VIEWPORT} control={<Radio color='primary' />} label={LEVEL_TYPE.VIEWPORT} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            {displayInstructionSet(info)}
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={commonClasses.actionArea}>
        {file ? <Typography>{file.name}</Typography> : null}
        <Button
          className={commonClasses.btnInfo}
          variant='contained'
          onClick={() => fileInputElement.click()}
          startIcon={<SearchIcon />}
        >
          <input
            style={{ display: 'none' }}
            type='file'
            accept='.csv'
            required
            ref={element => { fileInputElement = element }}
            onChange={(event) => setFile(event.target.files[0])}
          />
          Choose File
        </Button>
        <Button
          disabled={!file || uploading}
          className={(!uploading && file) ? commonClasses.btnSuccess : ''}
          variant='contained'
          onClick={() => {
            if (!file) {
              swal.fire({ title: 'Please choose a file.', icon: 'info' })
              return
            }
            setUploading(true)
            const reader = new window.FileReader()
            reader.onload = async (event) => {
              try {
                let data = event.target.result
                switch (levelType) {
                  case LEVEL_TYPE.SLOT:
                    data = formatSlotData(data)
                    break
                  case LEVEL_TYPE.VIEWPORT:
                    data = formatViewportData(data)
                    break
                  default:
                }
                const res = await updateSlots({ type: levelType, data })
                // console.log('res', res)
                if (res.status === 200) {
                  swal.fire({ title: 'File processed successfully.', icon: 'success' })
                } else {
                  swal.fire({ title: `Error processing file. ${res.status}: ${res.data}`, icon: 'error' })
                }
              } catch (error) {
                swal.fire({
                  title: 'CSV File Processing Error',
                  text: error.message,
                  icon: 'error'
                })
              } finally {
                setUploading(false)
              }
            }
            reader.readAsText(file)
          }}
          startIcon={<PublishIcon />}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </Button>
        <Button variant='text' onClick={() => setOpenDialog(false)}>Close</Button>
      </CardActions>
    </Card>
  )
}
