import React, { useState, useEffect } from 'react'

import { TextField, Table, TableHead, TableBody, TableRow, TableCell, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const template = {
  AD_CODE: {
    DOM: '',
    INTL: ''
  },
  SUPPLY_CODE: {
    DOM: '',
    INTL: ''
  }
}

export const Telaria = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const TELARIA = Object.assign(template, VENDOR.TELARIA)
  VENDOR.TELARIA = TELARIA
  const { STRING } = validation
  const { LENGTH } = STRING

  const { AD_CODE = { DOM: '', INTL: '' }, SUPPLY_CODE = { DOM: '', INTL: '' } } = TELARIA

  const { DOM: DOM_AC, INTL: INTL_AC } = AD_CODE
  const { DOM: DOM_SC, INTL: INTL_SC } = SUPPLY_CODE

  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '180px' }} />
          <TableCell colSpan={2} style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
          <TableCell colSpan={2} style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const displayCodeTable = () => {
    return (
      <Table size='small' aria-label='telaria-ad-code-table'>
        {TableHeader()}
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Ad Code</Typography></TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DOM_AC}
                error={errors.PREBID_TELARIA_ACD}
                helperText={errors.PREBID_TELARIA_ACD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TELARIA.AD_CODE.DOM = value
                  setErrors({ ...errors, PREBID_TELARIA_ACD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={INTL_AC}
                error={errors.PREBID_TELARIA_ACI}
                helperText={errors.PREBID_TELARIA_ACI && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TELARIA.AD_CODE.INTL = value
                  setErrors({ ...errors, PREBID_TELARIA_ACI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Supply Code</Typography></TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DOM_SC}
                error={errors.PREBID_TELARIA_SCD}
                helperText={errors.PREBID_TELARIA_SCD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TELARIA.SUPPLY_CODE.DOM = value
                  setErrors({ ...errors, PREBID_TELARIA_SCD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={INTL_SC}
                error={errors.PREBID_TELARIA_SCI}
                helperText={errors.PREBID_TELARIA_SCI && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TELARIA.SUPPLY_CODE.INTL = value
                  setErrors({ ...errors, PREBID_TELARIA_SCI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='telaria-panel-content'
        id='telaria-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Telaria</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>{displayTableHeader()}</Grid> */}
          <Grid item xs={12}>{displayCodeTable()}</Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
