import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearRegistries,
  deploySiteRegistries,
  CloseToast
} from '../../modules/registrySlice'
import {
  fetchRocketeerSites,
  fetchSiteByName,
  updateGeneralInformation, ToggleIsAdFuelVersionSelected
} from '../../modules/sitesSlice'
import { fetchAllAdUnits } from '../../modules/DFPAdUnitSlice'
import { fetchAdFuelModules } from '../../modules/adFuelModuleSlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { fetchAdFuelVersions } from '../../modules/adFuelSlice'
import { faker } from '@faker-js/faker'
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  List,
  ListItem,
  CircularProgress,
  Breadcrumbs,
  Grid,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import {
  SiteConfiguration,
  SiteOverview,
  Notes,
  ResponsivenessBeta,
  AdFuelConfigurationBeta,
  ModuleConfiguration,
} from './index'

import { clone, siteNameFormatter } from '../../utilities/helperFunctions'
import { addClasses, commonStyle } from '../../styles'
import { CustomDialog, Toast } from '../ui'

const swal = withReactContent(Swal)

const SiteBeta = () => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { site, sites, sitePending, validationAlert, next, isAdFuelVersionSelected } = useSelector(
    state => state.sitesSlice
  )
  const { registries, toast } = useSelector(state => state.registrySlice)
  const { adFuelVersions } = useSelector(state => state.adFuelSlice)
  const siteLoading = useSelector(state => state.sitesSlice.loading)
  const adRootLoading = useSelector(state => state.dfp.loading)
  const adRoots = useSelector(state => state.dfp.adRoots)
  const [rocketeerSite, setRocketeerSite] = useState(clone(site))
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const { canEditSite } = useSelector(state => state.authReducer)
  const { adFuelModules: allAdFuelModules } = useSelector(state => state.adFuelModulesSlice)
  const [loadingPage, setLoadingPage] = useState(true)
  const [siteNotes, setSiteNotes] = useState(
    site.history 
      ? site.history
        .map(item => item.area === 'site - added comment' ? item : null)
        .filter(item => item !== null) 
      : []
  )
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(CloseToast())
  }

  useEffect(() => {
    if (adFuelVersions.length === 0) {
      dispatch(fetchAdFuelVersions())
    } else {
      const adFuelVersion = adFuelVersions.find(adfuel => adfuel._id === site.adFuelVersionId)
      dispatch(ToggleIsAdFuelVersionSelected(adFuelVersion !== undefined))
    }
  }, [adFuelVersions])

  useEffect(() => {
    if (validationAlert.validationWarnings) {
      setDialog({
        size: 'sm',
        content: displayValidationWarnings(validationAlert, next),
        displayDialogAction: false
      })
      setOpenDialog(true)
    }
  }, [validationAlert])

  useEffect(() => {
    if (sites && sites.length <= 0) {
      dispatch(fetchRocketeerSites())
    }
    if (adRoots.length <= 0) {
      dispatch(fetchAllAdUnits())
    }
    if (allAdFuelModules.length <= 0) {
      dispatch(fetchAdFuelModules())
    }
    setLoadingPage(false)
    console.log('[SiteBeta] ---------- useEffect[]::site', site)
  }, [])


  useEffect(() => {
    if (Object.keys(site).length !== 0) {
      console.log('[SiteBeta] ---------- useEffect[site]::site', site)
      const _sites = clone(site)
      const { adFuelModules } = site
      if (adFuelModules && adFuelModules.length > 0) {
        console.log('[SiteBeta] ---------- useEffect[site]::filtering modules...')
        const adFuelModuleList = adFuelModules?.filter(
          adFuelModule => 
            allAdFuelModules?.find(
              ({ _id }) => adFuelModule.adFuelModuleId === _id
            )
        )
        console.log('[SiteBeta] ---------- useEffect[site}::adFuelModuleList', adFuelModuleList)
        _sites.adFuelModules = adFuelModuleList
      }else{
        console.warn('[SiteBeta] ---------- useEffect[site]::NO MODULES!', adFuelModules)
      }
      _sites.usePackage = false
      setRocketeerSite(clone(_sites))
    } else {
      console.log('[SiteBeta] ---------- useEffect[site]::site', site)
    }
  }, [site])

  const displayValidationWarnings = (validationAlert, next) => {
    const { registriesAffected, validationWarnings } = validationAlert
    return (
      <Card>
        <CardHeader title='Confirm saving site?' />
        <Divider />
        <CardContent>
          {sitePending.name} has changes affecting registries, requiring
          redeployment:
          <ul>
            {validationWarnings.map((item, index) => {
              return (
                <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
              )
            })}
          </ul>
          Are you sure you want to save your changes?
        </CardContent>
        <Divider />
        <CardActions className={commonClasses.actionArea}>
          <Button
            variant='text'
            onClick={() => {
              setOpenDialog(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className={commonClasses.btnSuccess}
            variant='contained'
            onClick={() => {
              const newSite = clone(sitePending)
              newSite.hasConfirmedValidationWarnings = true
              dispatch(next(newSite))
              if (registriesAffected && registriesAffected.length > 0) {
                // dispatch(fetchRegistriesForSite(site._id))
                swal.fire({
                  text: registriesAffected.length + ' registries updated'
                })
              }
              const adFuelVersion = adFuelVersions.find(adfuel => adfuel._id === newSite.adFuelVersionId)
              dispatch(ToggleIsAdFuelVersionSelected(adFuelVersion !== undefined))
              setOpenDialog(false)
            }}
          >
            Ok
          </Button>
        </CardActions>
      </Card>
    )
  }

  const fetchByName = () => {
    const urlFragments = window.location.pathname.split('/')
    let siteName = urlFragments[urlFragments.length-1]
    if (siteName) {
      console.log('[SiteBeta] ---------- fetchByName::Fetching Site: ', siteName)
      fetchSiteByName(siteName)
    } else {
      console.log('[SiteBeta] ---------- fetchByName::No Sitename: ', urlFragments)
    }
  }

  useEffect(() => {
    let newSiteNotes = site.history 
      ? site.history
        .map(item => item.area === 'site - added comment' ? item : null)
        .filter(item => item !== null) 
      : []

    // let urgentCount = 0
    // let importantCount = 0
    // let bannedDigits = ['3','4','5','6','7','8','9']
    // let urgentThreshold = 1
    // let importantThreshold = 2
    // for (let x = 0; x < 25; x++) {
    //   let severity = x % 3 === 0 ? parseInt(faker.random.numeric(1, { bannedDigits }), 10) : 0
    //   if (severity > 1) {
    //     urgentCount = urgentCount + 1
    //     if (urgentCount > urgentThreshold) severity = 0
    //   }
    //   if (severity === 1) {
    //     importantCount = importantCount + 1
    //     if (importantCount > importantThreshold) severity = 0
    //   }
      
    //   siteNotes.push({
    //     text: faker.hacker.phrase(),
    //     createdBy: faker.name.fullName(),
    //     createdOn: new Date(faker.date.past(3)),
    //     severity: severity
    //   })
    // }
    // siteNotes.sort((itemA, itemB) => {
    //   if (itemA.severity === 2) return -1
    //   if (itemB.severity === 2) return 1   
    //   if (itemA.createdOn > itemB.createdOn) return -1
    //   if (itemB.createdOn > itemA.createdOn) return -1
    // })
    setSiteNotes(newSiteNotes)  
  }, [site])

  console.log('[SiteBeta] ---------- render::loadingPage', loadingPage)
  return (
    <>
      {site._id && (
        <Breadcrumbs
          className={addClasses(commonClasses.pl_3, commonClasses.pb_1, loadingPage ? (commonClasses.disabledlink, commonClasses.opacity50) : null)}
          aria-label='breadcrumb'
        >
          {/* <NavLink
            activeStyle={{
              fontWeight: 'bold',
              textDecoration: 'underline'
            }}
            to={`/sites/${siteNameFormatter(site.name)}`}
          >
            {rocketeerSite.name || 'Site'}
          </NavLink>
          <NavLink
            to={{
              pathname: `/sites/${siteNameFormatter(site.name)}/registries`,
              state: { rocketeerSite }
            }}
          >
            Registries
          </NavLink> */}
          {loadingPage
            ? <NavLink to={`/sites/${siteNameFormatter(site.name)}`} onClick={(event) => event.preventDefault()}>{site.name ? site.name : 'Site'} </NavLink>
            : <NavLink to={`/sites/${siteNameFormatter(site.name)}`}>{site.name ? site.name : 'Site'}</NavLink>}
          {loadingPage
            ? <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}/registries`} onClick={(event) => event.preventDefault()}>Registries</NavLink>
            : <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}/registries`}>Registries</NavLink>}
        </Breadcrumbs>
      )}
      <Container maxWidth='xl'>
        {site._id && !adRootLoading && !siteLoading
          ? (
            <Grid container spacing={2}>
              <Grid item md={12} lg={6}>
                <Box mb={2}>
                  <SiteOverview
                    data={rocketeerSite}
                    canEditSite={canEditSite}
                    registries={registries}
                  />
                </Box>
                {
                  (site && (site.useMCP === false || typeof site.useMCP === 'undefined'))
                    ? ( <Box mb={2}>
                      <SiteConfiguration
                        data={rocketeerSite}
                        canEditSite={canEditSite}
                      />
                      </Box>
                    ) 
                    : ''
                }
                <Box mb={2}>
                  <Notes notes={siteNotes} data={rocketeerSite} />
                </Box>
              </Grid>
              <Grid item md={12} lg={6}>
                {
                  (site && site.useMCP && site.useMCP === true) 
                    ? (
                      <Box mb={2}>
                      <SiteConfiguration
                        data={rocketeerSite}
                        canEditSite={canEditSite}
                      />
                    </Box>
                  )
                  : (
                    <Box mb={2}>
                      <AdFuelConfigurationBeta
                        data={rocketeerSite}
                        canEditSite={canEditSite}
                      />
                    </Box>
                  )
                }
              </Grid>
              <Grid item md={12}>
              </Grid>
            </Grid>
          )
          : (
            <Card>
              <CardContent>
                <CircularProgress />
                <List>
                  {!site._id || siteLoading && (<ListItem>Loading Site Information...</ListItem>)}
                  {adRootLoading && <ListItem>Loading Ad Roots...</ListItem>}                  
                </List>
              </CardContent>
            </Card>
          )
        }
      </Container>
      <CustomDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        title={dialog.title}
        subtitle={dialog.subtitle}
        content={dialog.content}
        displayDialogAction={dialog.displayDialogAction}
        customAction={dialog.customAction}
        size={dialog.size}
      />
      <Toast
        isOpen={toast.isOpen}
        variant={toast.variant}
        message={toast.message}
        onClose={handleCloseMessage}
      />
    </>
  )
}
export default SiteBeta