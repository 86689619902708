import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAdLoaderUploads, getCdnUploadsCount } from '../../modules/cdnService'

import MaterialTable from 'material-table'
import { Card, CardHeader, CardContent, Container, Divider, Link, LinearProgress, Breadcrumbs } from '@material-ui/core'
import { TableIcons } from '../ui/TableIcons'
import { NavLink } from 'react-router-dom'
import { addClasses, commonStyle } from '../../styles'

const secureLink = url => {
  return url.includes('https') ? url : url.replace('http', 'https')
}

export const AdLoaderHistoryBeta = () => {
  const dispatch = useDispatch()
  const commonClasses = commonStyle()
  const { uploads, count, loading } = useSelector((state) => state.cdnService)
  const [query, setQuery] = useState('')
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const { canUseAdLoader } = useSelector(state => state.authReducer)
  const columns = [
    { title: 'Date', field: 'createdOn', type: 'datetime' },
    { title: 'Username', field: 'createdBy' },
    { title: 'Folder', field: 'cdnFolder' },
    {
      title: 'Asset',
      field: 'url',
      render: (rowdata) => (
        <Link
          className={commonClasses.linkColor}
          href={secureLink(rowdata.url)}
          target='_blank'
          rel='noopener noreferrer'
        >
          {rowdata.filename}
        </Link>
      )
    },
    { title: 'Description', field: 'description' }
  ]

  useEffect(() => {
    if (uploads && uploads.length > 0) {
      setItems(uploads.map((uploads) => ({ ...uploads })))
    }
  }, [uploads])

  useEffect(() => {
    if (count === 0) { dispatch(getCdnUploadsCount()) }
  }, [count])

  useEffect(() => {
    const pageParam = { page, pageSize, searchQuery: query }
    dispatch(fetchAdLoaderUploads(pageParam))
  }, [page, pageSize])

  useEffect(() => {
    const pageParam = { page: 1, pageSize, searchQuery: query }
    const delaySerchFn = setTimeout(() => {
      dispatch(fetchAdLoaderUploads(pageParam))
      dispatch(getCdnUploadsCount(pageParam))
    }, 500)
    return () => clearTimeout(delaySerchFn)
  }, [query])

  return (
    <>
      {canUseAdLoader && (
        <Breadcrumbs className={addClasses(commonClasses.pl_3, commonClasses.pb_1)} aria-label='breadcrumb'>
          <NavLink to='/adLoader'>
            AdLoader
          </NavLink>
          <NavLink
            to='/adLoader/history'
            activeStyle={{
              fontWeight: 'bold',
              textDecoration: 'underline'
            }}
          >
            History
          </NavLink>
        </Breadcrumbs>
      )}
      <Container maxWidth='xl'>
        <Card elevation={2} className={commonClasses.roundedCard}>
          <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} titleTypographyProps={{ variant: 'h6' }} title='Upload History' />
          <Divider />
          {/* <CardContent> */}
            {items.length > 0
              ? (
                <MaterialTable
                  title=''
                  icons={TableIcons}
                  columns={columns}
                  data={items}
                  onChangePage={(pageNumber, pageSize) => {
                    setPage(pageNumber + 1)
                    setPageSize(pageSize)
                  }}
                  onChangeRowsPerPage={(pageSize) => {
                    setPageSize(pageSize)
                  }}
                  onSearchChange={
                    (string) => {
                      setPage(1)
                      setQuery(string)
                    }
                  }
                  totalCount={count}
                  page={page - 1}
                  isLoading={loading}
                  options={{
                    emptyRowsWhenPaging: false,
                    pageSize,
                    pageSizeOptions: [10, 25, 50, 100],
                    headerStyle: {
                      fontWeight: 'bold'
                    }
                  }}
                />
                )
              : (
                <LinearProgress />
                )
            }
          {/* </CardContent> */}
        </Card>
      </Container>
    </>
  )
}
