import React, { useState, useEffect } from 'react'

import { Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { DurationTable } from '../index'

import { commonStyle } from '../../../../../styles'

const videoZoneMapping = {
  DESKTOP: {
    HP: {},
    ROS: {}
  },
  MOBILE: {
    HP: {},
    ROS: {}
  }
}

const videoSiteIds = {
  DESKTOP: 0,
  MOBILE: 0
}

let template = {
  ACCOUNT_ID: {
    DOM: 0,
    INTL: 0
  },
  SITE_ID: {
    DOM: {
      DESKTOP: 0,
      MOBILE: 0,
      VIDEO: {
        DESKTOP: 0,
        MOBILE: 0
      }
    },
    INTL: {
      DESKTOP: 0,
      MOBILE: 0,
      VIDEO: {
        DESKTOP: 0,
        MOBILE: 0
      }
    }
  },
  ZONE_MAPPING: {
    DOM: {
      DESKTOP: {
        HP: {},
        ROS: {}
      },
      MOBILE: {
        HP: {},
        ROS: {}
      },
      VIDEO: {
        DESKTOP: {
          HP: {},
          ROS: {}
        },
        MOBILE: {
          HP: {},
          ROS: {}
        }
      }
    },
    INTL: {
      DESKTOP: {
        HP: {},
        ROS: {}
      },
      MOBILE: {
        HP: {},
        ROS: {}
      },
      VIDEO: {
        DESKTOP: {
          HP: {},
          ROS: {}
        },
        MOBILE: {
          HP: {},
          ROS: {}
        }
      }
    }
  },
  LANGUAGE: { DOM: 'en', INTL: 'en' },
  VIDEO: {
    DURATION: {
      DESKTOP: {
        MIN: {
          DOM: 5,
          INTL: 5
        },
        MAX: {
          DOM: 15,
          INTL: 15
        }
      },
      MOBILE: {
        MIN: {
          DOM: 6,
          INTL: 6
        },
        MAX: {
          DOM: 30,
          INTL: 30
        }

      }
    }
  }
}

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

const mergeDeep = (target, ...sources) => {
  if (!sources.length) {
    // console.log('No more sources. Returning target: ', target)
    return target
  }
  const source = sources.shift()
  // console.log('Merging in source: ', source)
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          })
        }
        // console.log('Merging key: ', key)
        mergeDeep(target[key], source[key])
      } else {
        // console.log('Setting key: ', key)
        Object.assign(target, {
          [key]: source[key]
        })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const Rubicon = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const commonClasses = commonStyle()
  const { PREBID } = options
  const { VENDOR } = PREBID
  let RUBICON = mergeDeep(template, VENDOR.RUBICON)
  let {
    ACCOUNT_ID,
    SITE_ID,
    ZONE_MAPPING,
    LANGUAGE
  } = RUBICON
  VENDOR.RUBICON = RUBICON
  if (typeof SITE_ID.DOM.VIDEO === 'string') {
    SITE_ID.DOM.VIDEO = videoSiteIds
  }
  if (typeof SITE_ID.INTL.VIDEO === 'string') {
    SITE_ID.INTL.VIDEO = videoSiteIds
  }
  if (typeof ZONE_MAPPING.DOM.VIDEO === 'string') {
    ZONE_MAPPING.DOM.VIDEO = videoZoneMapping
  }
  if (typeof ZONE_MAPPING.INTL.VIDEO === 'string') {
    ZONE_MAPPING.INTL.VIDEO = videoZoneMapping
  }
  

  const { STRING } = validation
  const { LENGTH } = STRING
  // const { LANGUAGE } = VIDEO
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  
  useEffect(() => {
    RUBICON = mergeDeep(template, VENDOR.RUBICON)
    
    ACCOUNT_ID = RUBICON.ACCOUNT_ID
    SITE_ID = RUBICON.SITE_ID
    ZONE_MAPPING = RUBICON.ZONE_MAPPING
    LANGUAGE = RUBICON.LANGUAGE

    VENDOR.RUBICON = RUBICON
  }, [VENDOR])

  const displayAccountIDandVideoLanguageTable = () => {
    return (
      <Table size='small' aria-label='rubicon-account-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }} />
            <TableCell style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Account IDs</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={ACCOUNT_ID.DOM}
                error={errors.PREBID_RUBICON_AID_D}
                helperText={errors.PREBID_RUBICON_AID_D && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.RUBICON.ACCOUNT_ID.DOM = value
                  setErrors({ ...errors, PREBID_RUBICON_AID_D: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={ACCOUNT_ID.INTL}
                error={errors.PREBID_RUBICON_AID_I}
                helperText={errors.PREBID_RUBICON_AID_I && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.RUBICON.ACCOUNT_ID.INTL = value
                  setErrors({ ...errors, PREBID_RUBICON_AID_I: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Video Language</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={LANGUAGE.DOM}
                error={errors.PREBID_RUBICON_VL_D}
                helperText={errors.PREBID_RUBICON_VL_D && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.RUBICON.LANGUAGE.DOM = value
                  setErrors({ ...errors, PREBID_RUBICON_VL_D: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={LANGUAGE.INTL}
                error={errors.PREBID_RUBICON_VL_I}
                helperText={errors.PREBID_RUBICON_VL_I && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.RUBICON.LANGUAGE.INTL = value
                  setErrors({ ...errors, PREBID_RUBICON_VL_I: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displaySiteIDTable = () => {
    return (
      <Table size='small' aria-label='rubicon-site-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Site IDs</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Display Desktop
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SITE_ID.DOM.DESKTOP}
                error={errors.PREBID_RUBICON_SID_DD}
                helperText={errors.PREBID_RUBICON_SID_DD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.RUBICON.SITE_ID.DOM.DESKTOP = value
                  setErrors({ ...errors, PREBID_RUBICON_SID_DD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SITE_ID.INTL.DESKTOP}
                error={errors.PREBID_RUBICON_SID_ID}
                helperText={errors.PREBID_RUBICON_SID_ID && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.RUBICON.SITE_ID.INTL.DESKTOP = value
                  setErrors({ ...errors, PREBID_RUBICON_SID_ID: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Display Mobile
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SITE_ID.DOM.MOBILE}
                error={errors.PREBID_RUBICON_SID_DM}
                helperText={errors.PREBID_RUBICON_SID_DM && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.RUBICON.SITE_ID.DOM.MOBILE = value
                  setErrors({ ...errors, PREBID_RUBICON_SID_DM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SITE_ID.INTL.MOBILE}
                error={errors.PREBID_RUBICON_SID_IM}
                helperText={errors.PREBID_RUBICON_SID_IM && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.RUBICON.SITE_ID.INTL.MOBILE = value
                  setErrors({ ...errors, PREBID_RUBICON_SID_IM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Video Desktop
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SITE_ID.DOM.VIDEO.DESKTOP}
                error={errors.PREBID_RUBICON_SID_DVD}
                helperText={errors.PREBID_RUBICON_SID_DVD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  console.log('Setting DOM Video Desktop Site ID: ', value)
                  PREBID.VENDOR.RUBICON.SITE_ID.DOM.VIDEO.DESKTOP = value
                  setErrors({ ...errors, PREBID_RUBICON_SID_DVD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SITE_ID.INTL.VIDEO.DESKTOP}
                error={errors.PREBID_RUBICON_SID_IVD}
                helperText={errors.PREBID_RUBICON_SID_IVD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  console.log('Setting INTL Video Desktop Site ID: ', value)
                  PREBID.VENDOR.RUBICON.SITE_ID.INTL.VIDEO.DESKTOP = value
                  setErrors({ ...errors, PREBID_RUBICON_SID_IVD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Video Mobile
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SITE_ID.DOM.VIDEO.MOBILE}
                error={errors.PREBID_RUBICON_SID_DVM}
                helperText={errors.PREBID_RUBICON_SID_DVM && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  console.log('Setting DOM Video Mobile Site ID: ', value)
                  PREBID.VENDOR.RUBICON.SITE_ID.DOM.VIDEO.MOBILE = value
                  setErrors({ ...errors, PREBID_RUBICON_SID_DVM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SITE_ID.INTL.VIDEO.MOBILE}
                error={errors.PREBID_RUBICON_SID_IVM}
                helperText={errors.PREBID_RUBICON_SID_IVM && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  console.log('Setting INTL Video Mobile Site ID: ', value)
                  PREBID.VENDOR.RUBICON.SITE_ID.INTL.VIDEO.MOBILE = value
                  setErrors({ ...errors, PREBID_RUBICON_SID_IVM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayZoneMappingTable = () => {
    return (
      <Table size='small' aria-label='rubicon-zone-mapping-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Zone Mappings</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Display Desktop</TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP atf'
                    value={ZONE_MAPPING.DOM.DESKTOP.HP.atf}
                    error={errors.PREBID_RUBICON_ZM_DD_HP_A}
                    helperText={errors.PREBID_RUBICON_ZM_DD_HP_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.DESKTOP.HP.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DD_HP_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP btf'
                    value={ZONE_MAPPING.DOM.DESKTOP.HP.btf}
                    error={errors.PREBID_RUBICON_ZM_DD_HP_B}
                    helperText={errors.PREBID_RUBICON_ZM_DD_HP_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.DESKTOP.HP.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DD_HP_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS atf'
                    value={ZONE_MAPPING.DOM.DESKTOP.ROS.atf}
                    error={errors.PREBID_RUBICON_ZM_DD_ROS_A}
                    helperText={errors.PREBID_RUBICON_ZM_DD_ROS_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.DESKTOP.ROS.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DD_ROS_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS btf'
                    value={ZONE_MAPPING.DOM.DESKTOP.ROS.btf}
                    error={errors.PREBID_RUBICON_ZM_DD_ROS_B}
                    helperText={errors.PREBID_RUBICON_ZM_DD_ROS_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.DESKTOP.ROS.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DD_ROS_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP atf'
                    value={ZONE_MAPPING.INTL.DESKTOP.HP.atf}
                    error={errors.PREBID_RUBICON_ZM_ID_HP_A}
                    helperText={errors.PREBID_RUBICON_ZM_ID_HP_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.DESKTOP.HP.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_ID_HP_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP btf'
                    value={ZONE_MAPPING.INTL.DESKTOP.HP.btf}
                    error={errors.PREBID_RUBICON_ZM_ID_HP_B}
                    helperText={errors.PREBID_RUBICON_ZM_ID_HP_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.DESKTOP.HP.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_ID_HP_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS atf'
                    value={ZONE_MAPPING.INTL.DESKTOP.ROS.atf}
                    error={errors.PREBID_RUBICON_ZM_ID_ROS_A}
                    helperText={errors.PREBID_RUBICON_ZM_ID_ROS_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.DESKTOP.ROS.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_ID_ROS_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS btf'
                    value={ZONE_MAPPING.INTL.DESKTOP.ROS.btf}
                    error={errors.PREBID_RUBICON_ZM_ID_ROS_B}
                    helperText={errors.PREBID_RUBICON_ZM_ID_ROS_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.DESKTOP.ROS.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_ID_ROS_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Display Mobile</TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP atf'
                    value={ZONE_MAPPING.DOM.MOBILE.HP.atf}
                    error={errors.PREBID_RUBICON_ZM_DM_HP_A}
                    helperText={errors.PREBID_RUBICON_ZM_DM_HP_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.MOBILE.HP.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DM_HP_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP btf'
                    value={ZONE_MAPPING.DOM.MOBILE.HP.btf}
                    error={errors.PREBID_RUBICON_ZM_DM_HP_B}
                    helperText={errors.PREBID_RUBICON_ZM_DM_HP_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.MOBILE.HP.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DM_HP_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS atf'
                    value={ZONE_MAPPING.DOM.MOBILE.ROS.atf}
                    error={errors.PREBID_RUBICON_ZM_DM_ROS_A}
                    helperText={errors.PREBID_RUBICON_ZM_DM_ROS_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.MOBILE.ROS.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DM_ROS_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS btf'
                    value={ZONE_MAPPING.DOM.MOBILE.ROS.btf}
                    error={errors.PREBID_RUBICON_ZM_DM_ROS_B}
                    helperText={errors.PREBID_RUBICON_ZM_DM_ROS_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.MOBILE.ROS.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DM_ROS_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP atf'
                    value={ZONE_MAPPING.INTL.MOBILE.HP.atf}
                    error={errors.PREBID_RUBICON_ZM_IM_HP_A}
                    helperText={errors.PREBID_RUBICON_ZM_IM_HP_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.MOBILE.HP.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IM_HP_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP btf'
                    value={ZONE_MAPPING.INTL.MOBILE.HP.btf}
                    error={errors.PREBID_RUBICON_ZM_IM_HP_B}
                    helperText={errors.PREBID_RUBICON_ZM_IM_HP_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.MOBILE.HP.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IM_HP_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS atf'
                    value={ZONE_MAPPING.INTL.MOBILE.ROS.atf}
                    error={errors.PREBID_RUBICON_ZM_IM_ROS_A}
                    helperText={errors.PREBID_RUBICON_ZM_IM_ROS_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.MOBILE.ROS.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IM_ROS_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS btf'
                    value={ZONE_MAPPING.INTL.MOBILE.ROS.btf}
                    error={errors.PREBID_RUBICON_ZM_IM_ROS_B}
                    helperText={errors.PREBID_RUBICON_ZM_IM_ROS_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.MOBILE.ROS.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IM_ROS_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Video Desktop</TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP atf'
                    value={ZONE_MAPPING.DOM.VIDEO.DESKTOP.HP.atf}
                    error={errors.PREBID_RUBICON_ZM_DVD_HP_A}
                    helperText={errors.PREBID_RUBICON_ZM_DVD_HP_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting DOM Video Desktop HP ATF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.VIDEO.DESKTOP.HP.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DVD_HP_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP btf'
                    value={ZONE_MAPPING.DOM.VIDEO.DESKTOP.HP.btf}
                    error={errors.PREBID_RUBICON_ZM_DVD_HP_B}
                    helperText={errors.PREBID_RUBICON_ZM_DVD_HP_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting DOM Video Desktop HP BTF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.VIDEO.DESKTOP.HP.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DVD_HP_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS atf'
                    value={ZONE_MAPPING.DOM.VIDEO.DESKTOP.ROS.atf}
                    error={errors.PREBID_RUBICON_ZM_DVD_ROS_A}
                    helperText={errors.PREBID_RUBICON_ZM_DVD_ROS_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting DOM Video Desktop ROS ATF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.VIDEO.DESKTOP.ROS.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DVD_ROS_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS btf'
                    value={ZONE_MAPPING.DOM.VIDEO.DESKTOP.ROS.btf}
                    error={errors.PREBID_RUBICON_ZM_DVD_ROS_B}
                    helperText={errors.PREBID_RUBICON_ZM_DVD_ROS_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting DOM Video Desktop ROS BTF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.VIDEO.DESKTOP.ROS.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DVD_ROS_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP atf'
                    value={ZONE_MAPPING.INTL.VIDEO.DESKTOP.HP.atf}
                    error={errors.PREBID_RUBICON_ZM_IVD_HP_A}
                    helperText={errors.PREBID_RUBICON_ZM_IVD_HP_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting INTL Video Desktop HP ATF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.VIDEO.DESKTOP.HP.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IVD_HP_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP btf'
                    value={ZONE_MAPPING.INTL.VIDEO.DESKTOP.HP.btf}
                    error={errors.PREBID_RUBICON_ZM_IVD_HP_B}
                    helperText={errors.PREBID_RUBICON_ZM_IVD_HP_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting INTL Video Desktop HP BTF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.VIDEO.DESKTOP.HP.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IVD_HP_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS atf'
                    value={ZONE_MAPPING.INTL.VIDEO.DESKTOP.ROS.atf}
                    error={errors.PREBID_RUBICON_ZM_IVD_ROS_A}
                    helperText={errors.PREBID_RUBICON_ZM_IVD_ROS_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting INTL Video Desktop ROS ATF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.VIDEO.DESKTOP.ROS.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IVD_ROS_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS btf'
                    value={ZONE_MAPPING.INTL.VIDEO.DESKTOP.ROS.btf}
                    error={errors.PREBID_RUBICON_ZM_IVD_ROS_B}
                    helperText={errors.PREBID_RUBICON_ZM_IVD_ROS_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting INTL Video Desktop ROS BTF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.VIDEO.DESKTOP.ROS.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IVD_ROS_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Video Mobile</TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP atf'
                    value={ZONE_MAPPING.DOM.VIDEO.MOBILE.HP.atf}
                    error={errors.PREBID_RUBICON_ZM_DVM_HP_A}
                    helperText={errors.PREBID_RUBICON_ZM_DVM_HP_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting DOM Video Mobile HP ATF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.VIDEO.MOBILE.HP.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DVM_HP_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP btf'
                    value={ZONE_MAPPING.DOM.VIDEO.MOBILE.HP.btf}
                    error={errors.PREBID_RUBICON_ZM_DVM_HP_B}
                    helperText={errors.PREBID_RUBICON_ZM_DVM_HP_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting DOM Video Mobile HP BTF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.VIDEO.MOBILE.HP.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DVM_HP_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS atf'
                    value={ZONE_MAPPING.DOM.VIDEO.MOBILE.ROS.atf}
                    error={errors.PREBID_RUBICON_ZM_DVM_ROS_A}
                    helperText={errors.PREBID_RUBICON_ZM_DVM_ROS_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting DOM Video Mobile ROS ATF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.VIDEO.MOBILE.ROS.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DVM_ROS_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS btf'
                    value={ZONE_MAPPING.DOM.VIDEO.MOBILE.ROS.btf}
                    error={errors.PREBID_RUBICON_ZM_DVM_ROS_B}
                    helperText={errors.PREBID_RUBICON_ZM_DVM_ROS_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting DOM Video Mobile ROS BTF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.DOM.VIDEO.MOBILE.ROS.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_DVM_ROS_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP atf'
                    value={ZONE_MAPPING.INTL.VIDEO.MOBILE.HP.atf}
                    error={errors.PREBID_RUBICON_ZM_IVM_HP_A}
                    helperText={errors.PREBID_RUBICON_ZM_IVM_HP_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting INTL Video Mobile HP ATF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.VIDEO.MOBILE.HP.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IVM_HP_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='HP btf'
                    value={ZONE_MAPPING.INTL.VIDEO.MOBILE.HP.btf}
                    error={errors.PREBID_RUBICON_ZM_IVM_HP_B}
                    helperText={errors.PREBID_RUBICON_ZM_IVM_HP_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting INTL Video Mobile HP BTF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.VIDEO.MOBILE.HP.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IVM_HP_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS atf'
                    value={ZONE_MAPPING.INTL.VIDEO.MOBILE.ROS.atf}
                    error={errors.PREBID_RUBICON_ZM_IVM_ROS_A}
                    helperText={errors.PREBID_RUBICON_ZM_IVM_ROS_A && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting INTL Video Mobile ROS ATF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.VIDEO.MOBILE.ROS.atf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IVM_ROS_A: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    margin='none'
                    label='ROS btf'
                    value={ZONE_MAPPING.INTL.VIDEO.MOBILE.ROS.btf}
                    error={errors.PREBID_RUBICON_ZM_IVM_ROS_B}
                    helperText={errors.PREBID_RUBICON_ZM_IVM_ROS_B && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      console.log('Setting INTL Video Mobile ROS BTF ZONE ID: ', value)
                      PREBID.VENDOR.RUBICON.ZONE_MAPPING.INTL.VIDEO.MOBILE.ROS.btf = value
                      setErrors({ ...errors, PREBID_RUBICON_ZM_IVM_ROS_B: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, PREBID })
                      setAllOptions({ ...allOptions, PREBID })
                    }}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='Rubicon-panel-content'
        id='Rubicon-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Rubicon</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container>
          <Grid className={commonClasses.mb_3} item xs={12}>{displayAccountIDandVideoLanguageTable()}</Grid>
          <Grid className={commonClasses.mb_3} item xs={12}>{displaySiteIDTable()}</Grid>
          <Grid className={commonClasses.mb_3} item xs={12}>{displayZoneMappingTable()}</Grid>
          <Grid className={commonClasses.mb_3} item xs={12}>
            {/* {displayDurationTable(VIDEO, PREBID, errors, validation, options, allOptions, setErrors, setOptions, setAllOptions)} */}
            <DurationTable
                // path='IX.SITE_ID.DOM.DESKTOP.btf'
              path='RUBICON.VIDEO.DURATION'
                // object={SITE_ID?.DOM?.DESKTOP?.btf}
              object={RUBICON}
              options={options}
              allOptions={allOptions}
              setOptions={setOptions}
              setAllOptions={setAllOptions}
              errors={errors}
              setErrors={setErrors}
              validation={validation}
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
