import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { commonStyle } from '../../../styles/commonStyle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import {
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  FormControl,
  Link
} from '@material-ui/core'
// import EditIcon from '@material-ui/icons/Edit'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import SaveIcon from '@material-ui/icons/Save'
import { Alert } from '@material-ui/lab'
import { useSelector, useDispatch } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getAllCustomTargets } from '../../../modules/DFPAdUnitSlice'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}))

function getStyles (targeting, selectedTargetings, theme) {
  return {
    fontWeight:
      selectedTargetings.indexOf(targeting) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

const CustomTargetingDialog = props => {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { isUpdating } = useSelector(state => state.registrySlice)
  const { customTargets } = useSelector(state => state.dfp)
  const [customTargetings, setCustomTargetings] = useState([])
  const [targetingsWithKeys, setTargetingsWithKeys] = useState(false)

  const handleSave = () => {
    const bulkTargetings = customTargetings
      .filter(customTargeting => customTargeting.selectedValues.length > 0)
      .map(_customTargeting => {
        const container = {
          key: _customTargeting.name,
          values: _customTargeting.selectedValues
        }
        return container
      })
    props.handleSave(bulkTargetings)
  }

  const handleDialogClose = () => {
    props.handleDialogClose()
  }

  const handleChange = (event, customTargeting) => {
    // find the index of object from array that you want to update
    const selectedTargetingIndex = customTargetings.findIndex(
      _targeting => _targeting.id === customTargeting.id
    )

    // update Targeting status
    const updatedSelectedTargeting = customTargetings
      .filter(_targeting => _targeting.id === customTargeting.id)
      .map(targeting => ({
        ...targeting,
        selectedValues: event.target.value
      }))

    // make final new array of objects by combining updated object.
    const updatedTargeting = [
      ...customTargetings.slice(0, selectedTargetingIndex),
      updatedSelectedTargeting[0],
      ...customTargetings.slice(selectedTargetingIndex + 1)
    ]
    setCustomTargetings(updatedTargeting)
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  useEffect(() => {
    if (customTargets.length > 0) {
      const targets = customTargets.map(site => ({
        ...site,
        selectedValues: []
      }))
      if (props.targetings?.length > 0) {
        let updatedTargeting = customTargetings.slice()
        const tartetingGroup = props.targetings.reduce(function (r, a) {
          r[a.key] = r[a.key] || []
          r[a.key].push(a.value)
          return r
        }, {})
        const targetings = []
        for (const targetingName in tartetingGroup) {
          targetings.push({
            key: targetingName,
            values: tartetingGroup[targetingName]
          })
        }
        targetings.forEach(targeting => {
          const selectedTargetingIndex = customTargetings.findIndex(
            _targeting => _targeting.name === targeting.key
          )

          // update Targeting status
          const updatedSelectedTargeting = customTargetings
            .filter(_targeting => _targeting.name === targeting.key)
            .map(_targeting => ({
              ..._targeting,
              selectedValues: _targeting.values.filter(f =>
                targeting.values.includes(f.name)
              )
            }))

          updatedTargeting = [
            ...updatedTargeting.slice(0, selectedTargetingIndex),
            updatedSelectedTargeting[0],
            ...updatedTargeting.slice(selectedTargetingIndex + 1)
          ]
        })
        setCustomTargetings(updatedTargeting)
      } else {
        setCustomTargetings(targets)
      }
    } else {
      dispatch(getAllCustomTargets(true))
    }
  }, [customTargets, props.targetings])

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby='customized-dialog-title'
      open={props.isDialogOpen}
      maxWidth='md'
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle id='form-dialog-title'>{props.dialogTitle}</DialogTitle>
      <DialogContent dividers>
        <Alert severity='error'>
          NOTE: These values will be set at the page level on all selected
          registries!.
        </Alert>
        <Grid container className={commonClasses.m_1}>
          <Grid item xs={12} sm={12}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '50%', fontWeight: 'bold' }}>
                    Target Key
                  </TableCell>
                  <TableCell style={{ width: '50%', fontWeight: 'bold' }}>
                    Values
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customTargetings && customTargetings.length > 0
                  ? (
                      customTargetings.map(customTargeting => {
                        if (targetingsWithKeys) {
                          if (customTargeting.selectedValues.length > 0) {
                            return (
                              <TableRow
                                className={classes.tableRow}
                                hover
                                key={customTargeting.id}
                                style={{
                                  verticalAlign: 'top'
                                }}
                              >
                                <TableCell>
                                  <div className={classes.plainText}>
                                    [{customTargeting.name}] -{' '}
                                    {customTargeting.displayName}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth style={{ minHeight: 50 }}>
                                    <Select
                                      labelId='demo-mutiple-chip-label'
                                      id='demo-mutiple-chip'
                                      multiple
                                      value={customTargeting.selectedValues}
                                      onChange={e => {
                                        handleChange(e, customTargeting)
                                      }}
                                      input={<Input id={customTargeting.id} />}
                                      renderValue={selected => {
                                        return (
                                          <div className={classes.chips}>
                                            {selected.map(value => (
                                              <Chip
                                                key={value.id}
                                                label={value.name}
                                                className={classes.chip}
                                              />
                                            ))}
                                          </div>
                                        )
                                      }}
                                      MenuProps={MenuProps}
                                    >
                                      {customTargeting.values.map(value => (
                                        <MenuItem
                                          key={value.id}
                                          value={value}
                                          style={getStyles(
                                            value,
                                            customTargeting.selectedValues,
                                            theme
                                          )}
                                        >
                                          {value.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )
                          } else { return (<></>) }
                        } else {
                          return (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={customTargeting.id}
                              style={{
                                verticalAlign: 'top'
                              }}
                            >
                              <TableCell>
                                <div className={classes.plainText}>
                                  [{customTargeting.name}] -{' '}
                                  {customTargeting.displayName}
                                </div>
                              </TableCell>
                              <TableCell>
                                <FormControl fullWidth style={{ minHeight: 50 }}>
                                  <Select
                                    labelId='demo-mutiple-chip-label'
                                    id='demo-mutiple-chip'
                                    multiple
                                    value={customTargeting.selectedValues}
                                    onChange={e => handleChange(e, customTargeting)}
                                    input={<Input id={customTargeting.id} />}
                                    renderValue={selected => {
                                      return (
                                        <div className={classes.chips}>
                                          {selected?.map(value => (
                                            <Chip
                                              key={value.id}
                                              label={value.name}
                                              className={classes.chip}
                                            />
                                          ))}
                                        </div>
                                      )
                                    }}
                                    MenuProps={MenuProps}
                                  >
                                    {customTargeting?.values?.map(value => (
                                      <MenuItem
                                        key={value.id}
                                        value={value}
                                        style={getStyles(
                                          value,
                                          customTargeting.selectedValues,
                                          theme
                                        )}
                                      >
                                        {value.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          )
                        }
                      })
                    )
                  : (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                    )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.wrapper}>
        {targetingsWithKeys
          ? (
            <Link
              component='button'
              variant='body2'
              onClick={() => {
                console.info('Show all targetings')
                setTargetingsWithKeys(!targetingsWithKeys)
              }}
            >
              Show all targetings
            </Link>
            )
          : (
            <Link
              component='button'
              variant='body2'
              onClick={() => {
                console.info('Show only targetings with keys')
                setTargetingsWithKeys(!targetingsWithKeys)
              }}
            >
              Show only targetings with keys
            </Link>
            )}

        {isUpdating
          ? (
            <Button
              disabled
              variant='contained'
              title='Saving...'
              className={commonClasses.btnSuccess}
              startIcon={<CircularProgress size={24} />}
            >
              Saving
            </Button>
            )
          : (
            <Button
              autoFocus
              className={commonClasses.btnSuccess}
              onClick={() => handleSave()}
              variant='contained'
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
            )}
        {isUpdating
          ? (
            <Button
              disabled
              variant='contained'
              title='Saving...'
              className={commonClasses.btnSuccess}
            >
              Cancel
            </Button>
            )
          : (
            <Button
              className={commonClasses.ml_1}
              title='Cancel'
              variant='contained'
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            )}
      </DialogActions>
    </Dialog>
  )
}

CustomTargetingDialog.propTypes = {
  targetings: PropTypes.array,
  handleDialogClose: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  siteName: PropTypes.string,
  dialogTitle: PropTypes.string
}

export default CustomTargetingDialog
