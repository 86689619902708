import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getAllLineItems,
  getByCustomTarget,
  getAudienceSegmentsForCsv,
  getAllCustomTargets,
  fetchAPISchedule,
  fetchAPIVersion,
} from '../../modules/DFPAdUnitSlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  TextField,
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Container,
  Divider,
  Button,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MuiAlert from '@material-ui/lab/Alert'
import GetAppIcon from '@material-ui/icons/GetApp'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { addClasses, commonStyle } from '../../styles'
import { request } from '../../utilities/request.js'
import { LoadingProgress } from '../ui'

const swal = withReactContent(Swal)

const downloadFile = async (filename, data) => {
  try {
    const file = new window.Blob([data], { type: 'text/csv' })
    const link = document.createElement('a')
    link.download = filename
    link.href = URL.createObjectURL(file)
    link.click()
  } catch (err) {
    // console.log('err', err)
  }
}

const getTask = name => {
  return request(`/task/${name}`)
    .then(res => res)
    .catch(err => err)
}

const getDateForFilename = () => {
  const date = new Date()
  let year = date.getFullYear()
  let month = String(date.getMonth() + 1).padStart(2,'0')
  let day = String(date.getDate()).padStart(2,'0')

  let hr = String(date.getHours()).padStart(2,'0')
  let mn = String(date.getMinutes()).padStart(2,'0')
  let sc = String(date.getSeconds()).padStart(2,'0')

  return `${year}-${month}-${day}@${hr}.${mn}.${sc}`
}

export const GetAllLineItems = ({ task, setTask, expandAll }) => {
  const commonClasses = commonStyle()
  const [expanded, setExpanded] = useState(true)
  const { lastRunBy, lastRunOn, isActive } = task

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <Card elevation={0} style={{ width: '100%' }} raised={false}>
      <CardContent>
        <MuiAlert severity='info'>
          last run completed on: {lastRunOn} by user: {lastRunBy}
        </MuiAlert>
      </CardContent>
      <Divider />
      <CardActions className={commonClasses.actionArea}>
        <MuiAlert severity='warning'>
          *** WARNING *** Run time may exceed 30 minutes.
        </MuiAlert>
        <Button
          disabled={isActive}
          className={commonClasses.btnDanger}
          variant='contained'
          startIcon={<CheckCircleIcon />}
          onClick={() => {
            swal.fire({
              title: 'Are you sure?',
              text: 'Run time may exceed 30 minutes.',
              icon: 'warning',
              showCancelButton: true
              // buttons: true,
              // dangerMode: true
            }).then(async ok => {
              if (ok.isConfirmed) {
                const res = await getAllLineItems()
                if (res.reason) {
                  swal.fire({
                    title: 'An error has occurred with your request.',
                    text: res.reason,
                    icon: 'error'
                  })
                } else {
                  setTask(res.data)
                }
              }
            })
          }}
        >
          {isActive ? 'Running...' : 'Run'}
        </Button>
      </CardActions>
    </Card>
  )
}

export const GetLineItemsByCustomTarget = ({ expandAll, customTargets }) => {
  const commonClasses = commonStyle()
  const [expanded, setExpanded] = useState(true)
  const [customTargetList, setCustomTargetList] = useState([])
  const [customTargetKey, setCustomTargetKey] = useState('')
  const [errors, setErrors] = useState({})
  const [isRunning, setIsRunning] = useState(false)

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <Card elevation={0} style={{ width: '100%' }} raised={false}>
      <CardContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Autocomplete
              style={{ minWidth: 200 }}
              autoSelect
              autoComplete
              noOptionsText='Type to search key'
              forcePopupIcon={false}
              options={customTargetList}
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                setCustomTargetKey(!newValue ? null : newValue)
              }}
              onInputChange={(event, newValue, reason) =>
                setCustomTargetList(
                  reason === 'input' && newValue.length >= 1
                    ? customTargets.filter(item => item.name.match(RegExp(`^${newValue}`, 'i'))).sort()
                    : [])}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Custom Target'
                  margin='none'
                  error={!!errors.customTargetKey}
                  helperText={
                    errors.customTargetKey && errors.customTargetKey.message
                  }
                  onChange={() => {
                    if (errors.customTargetKey) {
                      delete errors.customTargetKey
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <MuiAlert severity='info'>
              To get the most up-to-date Line Items, click the Run button
              above.
            </MuiAlert>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={commonClasses.actionArea}>
        <Button
          disabled={isRunning}
          className={commonClasses.btnSuccess}
          variant='contained'
          startIcon={<GetAppIcon />}
          onClick={async () => {
            if (!customTargetKey) {
              setErrors({
                ...errors,
                customTargetKey: {
                  message: 'Custom Target is required'
                }
              })
              return
            }
            setIsRunning(true)
            const res = await getByCustomTarget(customTargetKey.name)
            if (res.reason) {
              swal.fire({
                title: 'An error has occurred with your request.',
                text: res.reason,
                icon: 'error'
              })
            } else {
              downloadFile(
                `${customTargetKey.name}_lintItems_${Date.now()}.csv`,
                res.data
              )
            }
            setIsRunning(false)
          }}
        >
          {isRunning ? 'Running...' : 'Run & Download'}
        </Button>
      </CardActions>
    </Card>
  )
}

export const GetAllActiveAudienceSegment = ({ expandAll }) => {
  const commonClasses = commonStyle()
  const [expanded, setExpanded] = useState(true)
  const [isAllRunning, setIsAllRunning] = useState(false)
  const [isActiveRunning, setIsActiveRunning] = useState(false)
  const [isInactiveRunning, setIsInactiveRunning] = useState(false)

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <Card elevation={0} style={{ width: '100%' }} raised={false}>
      <CardActions className={commonClasses.actionArea}>
        <Button
          disabled={isAllRunning}
          className={commonClasses.btnSuccess}
          variant='contained'
          startIcon={
            isAllRunning ? <CircularProgress size={24} /> : <GetAppIcon />
          }
          onClick={async () => {
            setIsAllRunning(true)
            const res = await getAudienceSegmentsForCsv('ALL')
            if (res.reason) {
              swal.fire({
                title: 'An error has occurred with your request.',
                text: res.reason,
                icon: 'error'
              })
            } else {
              const formattedDate = getDateForFilename()
              downloadFile(
                `all_audience_segments_${formattedDate}.csv`,
                res.data
              )
            }
            setIsAllRunning(false)
          }}
        >
          {isAllRunning ? 'Downloading...' : 'Download All'}
        </Button>
        <Button
          disabled={isActiveRunning}
          className={commonClasses.btnSuccess}
          variant='contained'
          startIcon={
            isActiveRunning ? <CircularProgress size={24} /> : <GetAppIcon />
          }
          onClick={async () => {
            setIsActiveRunning(true)
            const res = await getAudienceSegmentsForCsv('ACTIVE')
            if (res.reason) {
              swal.fire({
                title: 'An error has occurred with your request.',
                text: res.reason,
                icon: 'error'
              })
            } else {
              const formattedDate = getDateForFilename() // because Filename is lonely
              downloadFile(
                `active_audience_segments_${formattedDate}.csv`,
                res.data
              )
            }
            setIsActiveRunning(false)
          }}
        >
          {isActiveRunning ? 'Downloading...' : 'Download Active'}
        </Button>
        <Button
          disabled={isInactiveRunning}
          className={commonClasses.btnSuccess}
          variant='contained'
          startIcon={
            isInactiveRunning ? <CircularProgress size={24} /> : <GetAppIcon />
          }
          onClick={async () => {
            setIsInactiveRunning(true)
            const res = await getAudienceSegmentsForCsv('INACTIVE')
            if (res.reason) {
              swal.fire({
                title: 'An error has occurred with your request.',
                text: res.reason,
                icon: 'error'
              })
            } else {
              const formattedDate = getDateForFilename()
              downloadFile(
                `inactive_audience_segments_${formattedDate}.csv`,
                res.data
              )
            }
            setIsInactiveRunning(false)
          }}
        >
          {isInactiveRunning ? 'Downloading...' : 'Download Inactive'}
        </Button>
      </CardActions>
    </Card>
  )
}

export const GoogleAdManagerAPIBeta = () => {
  const dispatch = useDispatch()
  const [expandAll, setExpandAll] = useState(true)
  const [allLineItemsTask, setAllLineItemsTask] = useState('')
  let { customTargets, currentVersion, schedule } = useSelector(state => state.dfp)
  const commonClasses = commonStyle()
  let columnHeaders = []
  let depSchedule = [...schedule]
  if (schedule.length > 0) columnHeaders = depSchedule.shift().split(',')
  console.log('ColumnHeaders: ', columnHeaders)
  console.log('DepSchedule: ', depSchedule)

  useEffect(() => {
    ;(async () => {
      if (currentVersion === '') {
        console.log('Fetching API Version and Schedule...')
        dispatch(fetchAPIVersion())
        dispatch(fetchAPISchedule())
      }
      if (customTargets.length === 0) {
        dispatch(getAllCustomTargets(true))
      }
      const res = await getTask('getAllLineItems')
      if (res.reason) {
        swal.fire({
          title: 'An error has occurred with your request.',
          text: res.reason,
          icon: 'error'
        })
      } else {
        setAllLineItemsTask(res.data)
      }
    })()
  }, [])

  return (
    <Container maxWidth='lg'>
      {allLineItemsTask && customTargets.length > 0
        ? (
        <>
          <Card elevation={2} className={commonClasses.roundedCard}>
            <CardHeader 
              style={{padding: 5}} 
              className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} 
              titleTypographyProps={{ variant: 'h6' }} 
              title='Google Ad Manager API :: Version and Release Schedule'
            />
            <Divider />
            <CardContent>
              <MuiAlert severity='info'>
                Current API Version: {currentVersion}
              </MuiAlert>
              <Divider />
              <TableContainer component={Paper}>
                <Table size="small" style={{minWidth: 650}}>
                  <TableHead>
                    <TableRow>
                      {columnHeaders.map(header => (<TableCell style={{fontWeight: 'bolder'}}>{header.toUpperCase().replace('DATE', ' DATE')}</TableCell>))}                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {depSchedule.map(item => {
                      let arr = item.split(',')
                      console.log('schedule item: ', item)
                      return (
                        <TableRow>
                          {arr.map(value => (<TableCell>{value}</TableCell>))}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <br />
          <Card elevation={2} className={commonClasses.roundedCard}>
            <CardHeader 
              style={{padding: 5}} 
              className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} 
              titleTypographyProps={{ variant: 'h6' }} 
              title='Google Ad Manager API :: Get All Line Items'
            />
            <Divider />
            <CardContent>
              <GetAllLineItems
                expandAll={expandAll}
                task={allLineItemsTask}
                setTask={setAllLineItemsTask}
              />
            </CardContent>
          </Card>
          <br />
          <Card elevation={2} className={commonClasses.roundedCard}>
            <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} titleTypographyProps={{ variant: 'h6' }} data-testid='purgeToolHeading' title='Google Ad Manager API :: Get Line Items by Custom Target' />  
            <CardContent>
              <GetLineItemsByCustomTarget
                expandAll={expandAll}
                customTargets={customTargets}
              />
            </CardContent>
          </Card>
          <br />
          <Card elevation={2} className={commonClasses.roundedCard}>
            <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} titleTypographyProps={{ variant: 'h6' }} data-testid='purgeToolHeading' title='Google Ad Manager API :: Get Audience Segment Data' />  
            <CardContent>
              <GetAllActiveAudienceSegment expandAll={expandAll} />
            </CardContent>
          </Card>
        </>
      )
    : (
      <LoadingProgress />
    )}
    </Container>
  )
}
