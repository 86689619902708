import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  makeStyles,
  FormControl,
  TextField,
  Button,
  CardHeader,
  CardContent,
  Divider,
  Card,
  Container,
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  CardActions
} from '@material-ui/core'
import moment from 'moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SendIcon from '@material-ui/icons/Send'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupstring, object as yupobject } from 'yup'
import { sendMail } from '../../modules/emailUsersSlice'
import { getUsersByRole, fetchUsers } from '../../modules/userSlice'
import { commonStyle } from '../../styles'
import { LoadingProgress } from '../ui'
import { fetchUserRoles } from '../../modules/roleSlice'

const swal = withReactContent(Swal)

const useStyles = makeStyles(theme => ({
  flexAlignItemsEnd: {
    alignItems: 'flex-end'
  },
  justify: {
    justifyContent: 'space-between'
  },
  dropdown: {
    minWidth: '200px',
    maxWidth: '50%',
    width: '49%'
  }
}))

const validationSchema = yupobject().shape({
  toRoles: yupstring().required('Required'),
  subject: yupstring().required('Required'),
  message: yupstring().required('Required')
})

const EmailUsers = () => {
  const classes = useStyles()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { users, usersByRole } = useSelector(state => state.userSlice)
  const { roles } = useSelector(state => state.roleSlice)
  const { lastLoginDates, loading } = useSelector(
    state => state.emailUsersSlice
  )
  const [lastLoginDate, setLastLoginDate] = useState(30)
  const [emailUsers, setEmailUsers] = useState('')
  const [usersByRoles, setUsersByRoles] = useState('')
  const [userRoles, setUserRoles] = useState('')
  const [role, setRole] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [isLoaded, setIsLoaded] = useState(true)
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const handleSelectChange = e => {
    dispatch(getUsersByRole(e.target.value))
    setValue('toRoles', e.target.value, { shouldValidate: true })
    setRole(e.target.value)
  }

  const onSendMail = () => {
    let recipients = ''
    if (!lastLoginDate) {
      setLastLoginDate(lastLoginDates[6])
    }
    const minLoginDate = moment()
      .subtract(lastLoginDate, 'days')
      .toDate()

    if (userRoles === 'all users') {
      emailUsers.forEach(user => {
        const userLoginDate = user.lastLogin
          ? moment(user.lastLogin)
          : moment().subtract(366, 'days').toDate()
        if (user.email && minLoginDate < userLoginDate) {
          recipients += ', ' + user.email
        }
      })
    } else {
      usersByRoles.forEach(user => {
        const userLoginDate = user.lastLogin
          ? moment(user.lastLogin)
          : moment().subtract(366, 'days').toDate()
        if (user.email && minLoginDate < userLoginDate) {
          recipients += ', ' + user.email
        }
      })
    }
    recipients = recipients.substr(2)
    if (recipients && subject) {
      const objEmail = { to: recipients, subject, message }
      dispatch(sendMail(objEmail))
      clearForm()
    } else {
      swal.fire({
        title: 'Email Send!',
        text: 'please enter required fields.',
        icon: 'error'
      })
    }
  }

  const clearForm = () => {
    setMessage('')
  }

  useEffect(() => {
    if (users) {
      setEmailUsers(users.map(user => ({ ...user })))
    }
    if (usersByRole) {
      setUsersByRoles(usersByRole.map(usersByRole => ({ ...usersByRole })))
    }
    if (roles) {
      setUserRoles(roles.map(userRole => ({ ...userRole })))
    }
  }, [users, usersByRole, roles])

  useEffect(() => {
    setIsLoaded(false)
    if (users.length === 0) {
      dispatch(fetchUsers())
    }
    if (roles.length === 0) {
      dispatch(fetchUserRoles())
    }
  }, [])

  return (
    <Container maxWidth='xl'>
      {isLoaded
        ? (
          <LoadingProgress />
          )
        : (
          <Card>
            <CardHeader title='Email Users' />
            <Divider />
            <CardContent className={commonClasses.cardBkClr}>
              <Card>
                <CardContent>
                  <form autoComplete='off' noValidate>
                    <FormGroup row className={classes.justify}>
                      <FormControl
                        required
                        className={[
                          classes.dropdown,
                          commonClasses.mt_1,
                          commonClasses.mb_1,
                          commonClasses.mr_1
                        ].join(' ')}
                      >
                        <InputLabel htmlFor='toRoles'>To</InputLabel>
                        <Select
                          id='toRoles'
                          {...register('toRoles', {
                            validate: value => value === '' || 'Required'
                          })}
                          value={role}
                          error={!!errors.toRoles}
                          onChange={handleSelectChange}
                        >
                          {userRoles
                            ? (
                                userRoles.map((role, i) => (
                                  <MenuItem key={i} value={role.name}>
                                    {role.name}
                                  </MenuItem>
                                ))
                              )
                            : (
                              <MenuItem value={null}>No Roles Available</MenuItem>
                              )}
                        </Select>
                        {!!errors.toRoles && (
                          <FormHelperText error={!!errors.toRoles}>
                            Required
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        required
                        className={[
                          classes.dropdown,
                          commonClasses.mt_1,
                          commonClasses.mb_1
                        ].join(' ')}
                      >
                        <InputLabel id='lastLoginDate-label'>
                          Last Login In
                        </InputLabel>
                        <Select
                          required
                          labelId='lastLoginDate-label'
                          id='lastLoginDate'
                          name='lastLoginDate'
                          value={lastLoginDate}
                          onChange={e => setLastLoginDate(Number(e.target.value))}
                        >
                          {lastLoginDates.map((lastLoginDate, i) => (
                            <MenuItem key={i} value={lastLoginDate.days}>
                              {lastLoginDate.option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                    <FormGroup row>
                      <TextField
                        fullWidth
                        margin='dense'
                        label='Subject'
                        id='subject'
                        defaultValue={subject}
                        variant='outlined'
                        {...register('subject')}
                        onChange={e => {
                          setSubject(e.target.value)
                          setValue('subject', e.target.value, {
                            shouldValidate: true
                          })
                        }}
                        error={!!errors.subject}
                        helperText={errors.subject ? errors.subject.message : ''}
                      />
                    </FormGroup>
                    <FormGroup row>
                      <TextField
                        fullWidth
                        margin='dense'
                        label='Message'
                        id='message'
                        {...register('message')}
                        defaultValue={message}
                        variant='outlined'
                        multiline
                        rows={5}
                        onChange={e => {
                          setMessage(e.target.value)
                          setValue('message', e.target.value, {
                            shouldValidate: true
                          })
                        }}
                        error={!!errors.message}
                        helperText={errors.message ? errors.message.message : ''}
                      />
                    </FormGroup>
                    <FormGroup className={classes.flexAlignItemsEnd}>
                      <Button
                        variant='contained'
                        className={[
                          commonClasses.mt_1,
                          commonClasses.btnSuccess
                        ].join(' ')}
                        onClick={handleSubmit(onSendMail)}
                        disabled={loading}
                        startIcon={<SendIcon />}
                      >
                        Send
                      </Button>
                    </FormGroup>
                  </form>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
          )}
    </Container>
  )
}

export default EmailUsers
