import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  slotTypes: [],
  orgSlotType: [],
  newSlotType: false,
  loading: false
}

const slotTypeSlice = createSlice({
  name: 'slotType',
  initialState,
  reducers: {
    FetchSlotTypes: (state, action) => {
      state.loading = false
      state.slotTypes = action.payload
      state.orgSlotType = action.payload
    },

    UpdateSlotType: (state, action) => {
      const slotTypes = state.slotTypes.slice()
      const updatedItemIndex = slotTypes.findIndex(
        sType => sType._id === action.payload._id
      )
      slotTypes[updatedItemIndex] = action.payload

      state.loading = false
      state.slotTypes = slotTypes
      state.orgSlotType = slotTypes
      swal.fire({
        title: 'Slot Types Updated!',
        text: 'Slot Types Updated.',
        icon: 'success'
      })
    },

    CreateSlotType: (state, action) => {
      const orgSlotType = state.orgSlotType.slice()
      orgSlotType.unshift(action.payload)
      state.loading = false
      state.newSlotType = false
      state.slotTypes = orgSlotType
      state.orgSlotType = orgSlotType
      swal.fire({
        title: 'Slot Types Saved!',
        text: 'Slot Types saved successfully.',
        icon: 'success'
      })
    },

    DeleteSlotType: (state, action) => {
      const slotTypes = state.orgSlotType
        .slice()
        .filter(SlotType => SlotType._id !== action.payload._id)
      state.loading = false
      state.slotTypes = slotTypes
      state.orgSlotType = slotTypes
      swal.fire({
        title: `The slotTypes "${action.payload.name}" has been deleted!`,
        icon: 'success'
      })
    },

    FilterSlotType: (state, action) => {
      const results = filterByValue(state.orgSlotType, action.payload)
      state.loading = false
      state.slotTypes = results
    },

    ToggleIsNewSlotType: state => {
      state.newSlotType = !state.newSlotType
    },

    SlotTypeLoadingStatus: state => {
      state.loading = true
    },

    SlotTypeError: (state, action) => {
      state.loading = false
      console.log('Slot Types: ', state.slotTypes)
      console.log('orgSlot Type: ', state.orgSlotType)
      console.log('newSlot Type: ', state.newSlotType)
      console.log('Action Type: ', action.type)
      console.log('Action Payload:', action.payload)
      handleError(action.payload, showError)
    }
  }
})

export default slotTypeSlice.reducer
export const {
  FetchSlotTypes,
  UpdateSlotType,
  CreateSlotType,
  DeleteSlotType,
  FilterSlotType,
  ToggleIsNewSlotType,
  SlotTypeLoadingStatus,
  SlotTypeError
} = slotTypeSlice.actions

const filterByValue = (array, string) => {
  return array.filter(arrObject =>
    Object.keys(arrObject).some(element =>
      // Some() allows you to test if an array has at least one element that meets a condition.
      arrObject[element]
        .toString()
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  )
}

export const fetchSlotTypes = () => {
  return async dispatch => {
    dispatch(SlotTypeLoadingStatus())
    return request('/lookups/slotTypes')
      .then(res => dispatch(FetchSlotTypes(res.data)))
      .catch(err => {
        console.log('Error Fetching Slot Types...', err)
        dispatch(SlotTypeError(err))
      })
  }
}

export const updateSlotType = sType => {
  return async dispatch => {
    dispatch(SlotTypeLoadingStatus())
    return request(`/lookups/slotTypes/${sType._id}`, 'POST', sType)
      .then(res => {
        dispatch(UpdateSlotType(res.data))
      })
      .catch(err => {
        console.log('Error Updating Slot Type...', err)
        dispatch(SlotTypeError(err))
      })
  }
}

export const createSlotType = sType => {
  return async dispatch => {
    dispatch(SlotTypeLoadingStatus())
    return request('/lookups/slotTypes', 'POST', sType)
      .then(res => {
        dispatch(CreateSlotType(res.data))
      })
      .catch(err => {
        console.log('Error Creating Slot Type...', err)
        dispatch(SlotTypeError(err))
      })
  }
}

export const deleteSlotType = slotTypeId => {
  return async dispatch => {
    dispatch(SlotTypeLoadingStatus())
    return request(`/lookups/slotTypes/${slotTypeId}`, 'DELETE', slotTypeId)
      .then(res => dispatch(DeleteSlotType(res.data)))
      .catch(err => {
        console.log('Error Deleting Slot Type...', err)
        dispatch(SlotTypeError(err))
      })
  }
}

export const filterSlotType = searchTerm => {
  return async dispatch => {
    dispatch(SlotTypeLoadingStatus())
    dispatch(FilterSlotType(searchTerm))
  }
}
