import React from 'react'

import { FormLabel, Card, CardContent, Table, TableHead, TableBody, TableRow, TableCell, Grid, Typography, Checkbox, Divider } from '@material-ui/core'

import { convertObjectToArray } from '../../../utilities/helperFunctions.js'
import { commonStyle } from '../../../styles/index.js'

export const Audience = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  let DEFAULT_VENDORS = {
    "AppNexus":{"COOKIE_NAME":"zwmc","URL":"https://ib.adnxs.com/getuid?https://umto.{{domain}}/user-sync?zwmc=$UID&domain={{domain}}"},
    "Freewheel":{"COOKIE_NAME":"bea4","URL":"https://bea4.v.fwmrm.net/ad/u?mode=echo&cr=https://umto.{{domain}}/user-sync?bea4=#{user.id}&domain={{domain}}"},
    "LiveRamp":{"COOKIE_NAME":"orev","URL":"https://idsync.rlcdn.com/712348.gif?partner_uid=${fwuid}"},
    "ID5":{"COOKIE_NAME":"tbd","URL":"https://to.be.determined.com"},
    "PubMatic":{"COOKIE_NAME":"kfyn","URL":"https://ads.pubmatic.com/AdServer/js/userSync.js"},
    "Rubicon":{"COOKIE_NAME":"ifyr","URL":"https://pixel-us-east.rubiconproject.com/exchange/sync.php?p={{brand}}"},
    "Telaria":{"COOKIE_NAME":"goiz","URL":"https://eq97f.publishers.tremorhub.com/pubsync?redir=https://umto.{{domain}}/user-sync?goiz=%5Btvid%5D&domain={{domain}}"},
  }
  let { AUI } = options || { AUI: { SITE_VENDORS: { DOM: [], INTL: [] }, VENDORS: DEFAULT_VENDORS } }
  console.log('Vendors: ', AUI.VENDORS)
  const commonClasses = commonStyle()

  let { VENDORS, SITE_VENDORS } = AUI
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={8}><Typography variant={'h6'} style={{fontWeight: 'bolder'}}>Vendors</Typography></Grid>
          <Grid item xs={2} style={{textAlign: 'center', fontWeight: 'bolder'}}>DOM</Grid>
          <Grid item xs={2} style={{textAlign: 'center', fontWeight: 'bolder'}}>INTL</Grid>
          <Grid item xs={12} className={commonClasses.p_1}><Divider /></Grid>
          {convertObjectToArray(VENDORS).map((vendor, index) => {
            const domChecked = !!SITE_VENDORS.DOM.find(item => item === vendor.key)
            const intlChecked = !!SITE_VENDORS.INTL.find(item => item === vendor.key)
            return (
              <>
                <Grid className={commonClasses.m_0} item xs={8}>
                  <FormLabel>
                    <Typography>{vendor.key}</Typography>
                  </FormLabel>
                </Grid>
                <Grid className={commonClasses.m_0} style={{textAlign: 'center'}} item xs={2}>
                  <Checkbox
                    color='primary'
                    checked={domChecked}
                    onChange={(event) => {
                      const checked = event.target.checked
                      if (checked) {
                        AUI.SITE_VENDORS.DOM = [...AUI.SITE_VENDORS.DOM, vendor.key]
                      } else {
                        AUI.SITE_VENDORS.DOM = AUI.SITE_VENDORS.DOM.filter(item => item !== vendor.key)
                      }
                      AUI.VENDORS = VENDORS
                      AUI.SITE_VENDORS.DOM.sort()                              
                      setOptions({ ...options, AUI })
                      setAllOptions({ ...allOptions, AUI })
                    }}
                  />
                </Grid>
                <Grid className={commonClasses.m_0} style={{textAlign: 'center'}} item xs={2}>
                  <Checkbox
                    color='primary'
                    checked={intlChecked}
                    onChange={(event) => {
                      const checked = event.target.checked
                      if (checked) {
                        AUI.SITE_VENDORS.INTL = [...AUI.SITE_VENDORS.INTL, vendor.key]
                      } else {
                        AUI.SITE_VENDORS.INTL = AUI.SITE_VENDORS.INTL.filter(item => item !== vendor.key)
                      }
                      AUI.VENDORS = VENDORS
                      AUI.SITE_VENDORS.INTL.sort()                              
                      setOptions({ ...options, AUI })
                      setAllOptions({ ...allOptions, AUI })
                    }}
                  />
                </Grid>
              </>
            )
          })}
        </Grid>
      </CardContent>
    </Card>
  )
}
