import React, { useState, useEffect } from 'react'
import { TableIcons } from '../ui/TableIcons'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomTargetByName } from '../../modules/DFPAdUnitSlice'
import { Container, MenuItem, Typography, Card, CardHeader, CardContent, Divider, FormControl, FormGroup, InputLabel, Select, LinearProgress, makeStyles } from '@material-ui/core'
import MaterialTable from 'material-table'
import { addClasses, commonStyle } from '../../styles'

const useStyles = makeStyles((theme) => ({
  dropdown: {
    minWidth: '200px'
  }
}))

export const DFPCustomTargetsBeta = () => {
  const dispatch = useDispatch()
  const { customTargets, loading } = useSelector((state) => state.dfp)
  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Match Type', field: 'matchType' },
    {
      title: 'Status',
      field: 'status',
      render: (rowdata) => (
        <div>
          {' '}
          {rowdata.status === 'INACTIVE'
            ? (
              <Typography
                variant='body2'
                style={{
                  color: 'red'
                }}
              >
                {rowdata.status}
              </Typography>
              )
            : (
              <Typography variant='body2'>{rowdata.status}</Typography>
              )}
        </div>
      )
    }
  ]
  const [customTarget, setCustomTarget] = useState('')
  const [data, setData] = useState([])
  const classes = useStyles()
  const commonClasses = commonStyle()

  const onChange = (event) => {
    const customTarget = event.target.value
    setCustomTarget(customTarget)
    dispatch(getCustomTargetByName(customTarget))
  }

  const getCustomTargetFileName = (customTarget) => {
    let result = 'data'
    switch (customTarget) {
      case 'spec': result = 'Special Section'; break
      case 'c_type': result = 'Content Type'; break
      case 'native_adv': result = 'Native Advertisers'; break
      case 'mod': result = 'Content Module'; break
      default: break
    }
    return result
  }

  useEffect(() => {
    if (customTargets) {
      setData(customTargets.map((item) => ({ ...item })))
    }
  }, [customTargets])

  return (
    <Container maxWidth='lg'>
      <Card elevation={2} className={commonClasses.roundedCard}>
        <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} titleTypographyProps={{ variant: 'h6' }} data-testid='title' title='GAM Custom Targets' />
        <Divider />
        <CardContent>
          <FormGroup row className={commonClasses.mb_2}>
            <FormControl required className={[classes.dropdown, commonClasses.mr_1].join(' ')}>
              <InputLabel htmlFor='select-custom-target'>Custom Target</InputLabel>
              <Select
                value={customTarget}
                onChange={onChange}
                name='customTarget'
                inputProps={{
                  id: 'select-custom-target'
                }}
              >
                <MenuItem value='spec'>Special Section</MenuItem>
                <MenuItem value='c_type'>Content Type</MenuItem>
                <MenuItem value='native_adv'>Native Advertisers</MenuItem>
                <MenuItem value='mod'>Content Module</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
          <Typography data-testid='description' paragraph>Choose from the drop down to populate custom targets.</Typography>
        </CardContent>
      </Card>
      {loading
        ? (
          <LinearProgress className={commonClasses.mt_3}/>
          )
        : customTarget !== '' && data && data.length > 0
          ? (
            <Card elevation={2} className={addClasses(commonClasses.roundedCard, commonClasses.mt_3)}>
              <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} titleTypographyProps={{ variant: 'h6' }} data-testid='title' title='Results' />
              <Divider />
              <div data-testid='targetTable'>
                <MaterialTable
                  title=''
                  columns={columns}
                  data={data}
                  icons={TableIcons}
                  options={{
                    emptyRowsWhenPaging: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 100],
                    exportButton: true,
                    exportFileName: getCustomTargetFileName(customTarget),
                    toolbar: true
                  }}
                />
              </div>
            </Card>
          )
          : (
              ''
          )
      }
    </Container>
  )
}
