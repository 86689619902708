import React, { useState, useEffect } from 'react'
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Paper
} from '@material-ui/core'
import { Add, Edit, Clear, Check } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupstring, object as yupobject } from 'yup'
import {
  updateSiteCategory,
  createSiteCategory,
  filterSiteCategory,
  fetchSiteCategories
} from '../../modules/siteCategorySlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { commonStyle } from '../../styles'
import { LoadingProgress } from '../ui'

const swal = withReactContent(Swal)

const SiteCategories = props => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { siteCategories } = useSelector(state => state.siteCategorySlice)
  const [searchTerm, setSearchTerm] = useState('')
  const [siteCategoriesData, setSiteCategoriesData] = useState({})
  const [selectedSiteCategory, setSelectedSiteCategory] = useState({})
  const [addingNewSiteCategory, setAddingNewSiteCategory] = useState(false)
  const [isEditingSiteCategorie, setIsEditingSiteCategorie] = useState(false)

  const validationSchema = yupobject().shape({
    name: yupstring().required('Required'),
    code: yupstring().required('Required')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const onSearchChange = e => {
    setSearchTerm(e.target.value)
    dispatch(filterSiteCategory(e.target.value))
  }

  const onEditSiteCategorie = siteCategories => {
    setSelectedSiteCategory(siteCategories)
    setIsEditingSiteCategorie(true)
    setAddingNewSiteCategory(false)
    setValue('code', siteCategories.code, { shouldValidate: true })
    setValue('name', siteCategories.name, { shouldValidate: true })
  }

  const onAddSiteCategorie = () => {
    setSelectedSiteCategory({ name: '', code: '' })
    setAddingNewSiteCategory(true)
    setIsEditingSiteCategorie(false)
    setValue('code', '')
    setValue('name', '')
  }

  const handleSiteCategorieChange = e => {
    if (e.target.name === 'name') {
      setSelectedSiteCategory({
        ...selectedSiteCategory,
        name: e.target.value
      })
      setValue('name', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'code') {
      setSelectedSiteCategory({
        ...selectedSiteCategory,
        code: e.target.value
      })
      setValue('code', e.target.value, { shouldValidate: true })
    }
  }

  const UpdateSiteCategory = () => {
    dispatch(updateSiteCategory(selectedSiteCategory))
    setIsEditingSiteCategorie(false)
  }

  const CreateSiteCategory = () => {
    const itemIndex = siteCategoriesData.findIndex(
      siteCategories => siteCategories.name === selectedSiteCategory.name
    )
    if (itemIndex === -1) {
      dispatch(createSiteCategory(selectedSiteCategory))
      setAddingNewSiteCategory(false)
    } else {
      swal.fire({
        title: 'Duplicate record!',
        text: 'Duplicate record already exists.  Please modify it and resave.',
        icon: 'error'
      })
    }
  }

  useEffect(() => {
    if (siteCategories && siteCategories.length > 0) {
      setSiteCategoriesData(
        siteCategories.map(sCategorie => ({ ...sCategorie }))
      )
    } else {
      dispatch(fetchSiteCategories())
    }
  }, [siteCategories])

  const textField = (
    isEditingSiteCategorie,
    selectedSiteCategory,
    siteCategorie,
    siteCategorieFor,
    siteCategorieDefaultValue,
    handleSiteCategorieChange,
    error,
    errorMessage
  ) => {
    if (
      isEditingSiteCategorie &&
      selectedSiteCategory._id === siteCategorie._id
    ) {
      return (
        <TextField
          style={{ width: '100%' }}
          margin='dense'
          {...register(siteCategorieFor)}
          defaultValue={siteCategorieDefaultValue}
          variant='outlined'
          onChange={e => handleSiteCategorieChange(e)}
          error={!!error}
          helperText={error ? errorMessage : ''}
        />
      )
    } else {
      return (
        <div className={commonClasses.plainText}>{siteCategorieDefaultValue}</div>
      )
    }
  }

  return (
    <Container maxWidth={'lg'}>
      <Paper position='static'>
        <Card>
          <form autoComplete='off' id='SiteCategory' noValidate>
          <CardHeader title='Site Categories' />          
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card><CardContent>
            <Grid container style={{ marginBottom: '10pt' }}>
              <Grid item md={4} xs={12}>
                <TextField
                  style={{ width: '100%' }}
                  label='Find'
                  margin='dense'
                  name='itemFilter'
                  defaultValue={searchTerm}
                  variant='outlined'
                  onChange={onSearchChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  className={commonClasses.m_1}
                  startIcon={<Add />}
                  onClick={() => {
                    onAddSiteCategorie()
                  }}
                >
                  Add New Category
                </Button>
              </Grid>
              <Grid item md={2} />
            </Grid>
            <Divider />
            <Grid>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={commonClasses.fontWeightBold}
                      style={{ width: '55%' }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      className={commonClasses.fontWeightBold}
                      style={{ width: '30%' }}
                    >
                      GAM Code
                    </TableCell>
                    <TableCell
                      className={commonClasses.fontWeightBold}
                      style={{ width: '15%' }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    className={!addingNewSiteCategory ? commonClasses.hidden : ''}
                  >
                    <TableCell>
                      <TextField
                        {...register('name')}
                        margin='dense'
                        defaultValue={selectedSiteCategory.name}
                        style={{ width: '100%' }}
                        variant='outlined'
                        onChange={e => handleSiteCategorieChange(e)}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        {...register('code')}
                        margin='dense'
                        defaultValue={selectedSiteCategory.code}
                        variant='outlined'
                        style={{ width: '100%' }}
                        onChange={e => handleSiteCategorieChange(e)}
                        error={!!errors.code}
                        helperText={errors.code ? errors.code.message : ''}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Grid container>
                        <Grid className={commonClasses.displayFlex} item>
                          <IconButton
                            aria-label='Save'
                            variant='contained'
                            name='Save'
                            title='Save'
                            onClick={handleSubmit(CreateSiteCategory)}
                          >
                            <Check />
                          </IconButton>
                          <IconButton
                            aria-label='Cancel'
                            variant='contained'
                            title='Cancel'
                            name='cancel'
                            onClick={e => {
                              setAddingNewSiteCategory(false)
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  {siteCategoriesData.length > 0
                    ? (
                        siteCategoriesData.map(siteCategorie => (
                          <TableRow
                            hover
                            key={siteCategorie._id}
                            style={{
                              verticalAlign: 'top'
                            }}
                          >
                            <TableCell>
                              {textField(
                                isEditingSiteCategorie,
                                selectedSiteCategory,
                                siteCategorie,
                                'name',
                                siteCategorie.name,
                                handleSiteCategorieChange,
                                errors.name,
                                errors.name ? errors.name.message : ''
                              )}
                            </TableCell>
                            <TableCell>
                              {textField(
                                isEditingSiteCategorie,
                                selectedSiteCategory,
                                siteCategorie,
                                'code',
                                siteCategorie.code,
                                handleSiteCategorieChange,
                                errors.code,
                                errors.code ? errors.code.message : ''
                              )}
                            </TableCell>
                            <TableCell>
                              <Grid container>
                                <Grid className={commonClasses.displayFlex} item>
                                  <IconButton
                                    aria-label='Save'
                                    variant='contained'
                                    className={
                                isEditingSiteCategorie &&
                                selectedSiteCategory._id === siteCategorie._id
                                  ? ''
                                  : commonClasses.hidden
                              }
                                    name='Save'
                                    title='Save'
                                    onClick={handleSubmit(UpdateSiteCategory)}
                                  >
                                    <Check />
                                  </IconButton>
                                  <IconButton
                                    aria-label='Cancel'
                                    variant='contained'
                                    className={
                                isEditingSiteCategorie &&
                                selectedSiteCategory._id === siteCategorie._id
                                  ? ''
                                  : commonClasses.hidden
                              }
                                    title='Cancel'
                                    onClick={e => {
                                      e.stopPropagation()
                                      setIsEditingSiteCategorie(false)
                                    }}
                                  >
                                    <Clear />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid className={commonClasses.displayFlex} item>

                                  <IconButton
                                    aria-label='Edit'
                                    variant='contained'
                                    className={
                                isEditingSiteCategorie &&
                                selectedSiteCategory._id === siteCategorie._id
                                  ? commonClasses.hidden
                                  : commonClasses.editIconColor
                              }
                                    name='Edit'
                                    title='Edit'
                                    onClick={e => {
                                      onEditSiteCategorie(siteCategorie)
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))
                      )
                    : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <LoadingProgress />
                        </TableCell>
                      </TableRow>
                      )}
                </TableBody>
              </Table>
            </Grid>
            </CardContent></Card>
          </CardContent>
        </form>
        </Card>
      </Paper>
    </Container>
  )
}

export default SiteCategories
