import React, { useState, useEffect } from 'react'
import { TableIcons } from '../ui/TableIcons'
import { useDispatch, useSelector } from 'react-redux'
import { getPosTargets } from '../../modules/registrySlice'
import { Container, Card, CardHeader, CardContent, Divider, Grid, Button, LinearProgress, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import MaterialTable from 'material-table'
import { addClasses, commonStyle } from '../../styles'
import { useForm } from 'react-hook-form'
import SearchIcon from '@material-ui/icons/Search'

export const POSTargetingReportBeta = () => {
  const dispatch = useDispatch()
  const { posTargetings, loading } = useSelector((state) => state.registrySlice)
  const columns = [
    { title: 'Ad Unit', field: 'name' },
    { title: 'Registry', field: 'deployedLocationUrl' }
  ]
  const [data, setData] = useState([])
  const [count, setCount] = useState(0)
  const [searchPOSValue, SetSearchPOSValue] = useState('')
  const [searchUnitValue, SetSearchUnitValue] = useState('')
  const commonClasses = commonStyle()

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm()

  const getPOSTargetingReport = async () => {
    dispatch(getPosTargets(searchPOSValue, searchUnitValue))
    setCount(count + 1)
  }

  useEffect(() => {
    setData(posTargetings.map((item) => ({ ...item })))
  }, [posTargetings])

  return (
    <Container maxWidth='lg'>
      <Card elevation={2} className={commonClasses.roundedCard}>
        <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} titleTypographyProps={{ variant: 'h6' }} data-testid='title' title='POS Targeting Report' />
        <Divider />
        <CardContent>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={5} sm={5}>
              <TextField
                fullWidth
                key='searchPOSValue'
                label='POS value*'
                autoComplete='off'
                margin='dense'
                defaultValue={searchPOSValue}
                {...register('searchPOSValue', {
                  validate: value => value !== '' || 'POS value is Required'
                })}
                onChange={e => {
                  setValue('searchPOSValue', e.target.value, { shouldValidate: true })
                  SetSearchPOSValue(e.target.value)
                }}
                error={!!errors.searchPOSValue}
                helperText={
                        errors.searchPOSValue ? errors.searchPOSValue.message : 'Type the POS target you want to look for.'
                      }
              />
            </Grid>
            <Grid item xs={5} sm={5}>
              <TextField
                fullWidth
                key='searchUnitvalue'
                label='Unit value'
                name='searchUnitvalue'
                autoComplete='off'
                margin='dense'
                defaultValue={searchUnitValue}
                onChange={e => SetSearchUnitValue(e.target.value)}
                helperText='(OPTIONAL) Type the partial or whole unit string to look for.'
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <Button
                disabled={searchPOSValue === '' || loading}
                className={commonClasses.btnSuccess}
                variant='contained'
                startIcon={<SearchIcon />}
                onClick={handleSubmit(getPOSTargetingReport)}
              >
                {loading ? 'Searching...' : 'Search'}
              </Button>
            </Grid><Divider />
          </Grid>
        </CardContent>
      </Card>      
      <br />
      <Card elevation={2} className={commonClasses.roundedCard} style={loading || (data && data.length > 0) ? {} : {display: 'none'}}>
        <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrToolHeader)} titleTypographyProps={{ variant: 'h6' }} data-testid='subtitle' title='Results' />
        <CardContent>
          <Grid item xs={12} sm={12}>
            {loading
              ? (
                <LinearProgress />
                )
              : data && data.length > 0
                ? (
                  <div data-testid='targetTable'>
                    <MaterialTable
                      title='POS Targeting Report'
                      columns={columns}
                      data={data}
                      icons={TableIcons}
                      options={{
                        paging: false,
                        exportButton: true,
                        exportFileName: `${searchPOSValue}-PosTargets`,
                        toolbar: true
                      }}
                    />
                  </div>
                  )
                : (
                    count > 0
                      ? (
                        <Grid container spacing={2} alignItems='center'>
                          <Grid item xs={12} sm={12}>
                            <Typography variant='h5'>Nothing found.</Typography>
                          </Grid>
                        </Grid>
                        )
                      : ''
                  )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}
