/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Paper,
  LinearProgress
} from '@material-ui/core'
import {
  refreshReloadDfpAdUnits,
  getCacheTimeStamps
} from '../../modules/DFPAdUnitSlice'
import moment from 'moment'
import { addClasses, commonStyle } from '../../styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles(theme => ({
  formControl: { margin: theme.spacing(1), minWidth: 120 },
  selectEmpty: { marginTop: theme.spacing(3) },
  spacer: { margin: theme.spacing(1) },
  root: { padding: theme.spacing(8) },
  input: { display: 'none' },
  checkbox: { margin: theme.spacing(1) },
  extendedIcon: { marginRight: theme.spacing(1) },
  denger: { color: 'white', background: 'red' },
  hidden: {
    display: 'none',
    visibility: 'collapse'
  },
  saveButton: { color: '#2D2' },
  deleteButton: { color: '#D22' },
  plainText: {
    fontSize: theme.typography.pxToRem(18),
    overflowWrap: 'anywhere'
  },
  buttonGroup: {
    marginTop: theme.spacing(1),
    position: 'relative',
    top: 5
  }
}))

export const DFPCache = props => {
  const classes = useStyles()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const {
    dfpDataTypes,
    cacheTimestampResource,
    refreshingDfpAdUnits
  } = useSelector(state => state.dfp)
  const [dfpCacheDataTypes, setDfpCacheDataTypes] = useState(dfpDataTypes)

  const dfpRefreshUI = item => {
    switch (item.id?.toLowerCase()) {
      case 'adunit':
        dispatch(refreshReloadDfpAdUnits(true))
        break
      default:
        break
    }
  }

  const dfpReloadCache = item => {
    switch (item.id?.toLowerCase()) {
      case 'adunit':
        dispatch(refreshReloadDfpAdUnits(true, true))
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (dfpCacheDataTypes) {
      setDfpCacheDataTypes(
        dfpDataTypes.map(dfpDataType => ({ ...dfpDataType }))
      )
    }
    if (cacheTimestampResource && cacheTimestampResource.length === 0) {
      dispatch(getCacheTimeStamps())
    }
  }, [cacheTimestampResource])

  return (
    <Container>
      <Paper position='static'>
        <form autoComplete='off' noValidate>
          <CardHeader title='GAM Cache Admin' />
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card>
              <CardContent>
                <Grid>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '25%', fontWeight: 'bold' }} />
                        <TableCell style={{ width: '50%', fontWeight: 'bold' }} />
                        <TableCell style={{ width: '25%', fontWeight: 'bold' }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dfpCacheDataTypes.length > 0
                        ? (
                            dfpCacheDataTypes.map(dfpDataType => (
                              <TableRow
                                className={classes.tableRow}
                                hover
                                key={dfpDataType.name}
                                style={{
                                  verticalAlign: 'top'
                                }}
                              >
                                <TableCell>
                                  <div className={classes.plainText}>
                                    {dfpDataType.name}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div
                                    className={
                                  !dfpDataType.timestamp
                                    ? classes.hidden
                                    : classes.plainText
                                }
                                  >
                                    Updated:{' '}
                                    {moment(dfpDataType.timestamp).format('ll') +
                                  ' ' +
                                  moment(dfpDataType.timestamp).format('LTS')}
                                  </div>
                                  <div
                                    className={
                                  dfpDataType.timestamp ? classes.hidden : ''
                                }
                                  >
                                    Updated: no data loaded
                                  </div>
                                </TableCell>

                                <TableCell>
                                  {!refreshingDfpAdUnits
                                    ? (
                                      <div>
                                        <Button
                                          disabled={refreshingDfpAdUnits}
                                          variant='contained'
                                          color='secondary'
                                          className={addClasses(commonClasses.btnSuccess, classes.spacer)}
                                          startIcon={<CheckCircleIcon />}
                                          onClick={() => dfpRefreshUI(dfpDataType)}
                                        >
                                          Update Cache
                                        </Button>
                                        <Button
                                          disabled={refreshingDfpAdUnits}
                                          variant='contained'
                                          className={addClasses(
                                            commonClasses.btnDanger,
                                            classes.spacer
                                          )}
                                          startIcon={<CheckCircleIcon />}
                                          onClick={() => dfpReloadCache(dfpDataType)}
                                        >
                                          Clear Cache and Update
                                        </Button>
                                      </div>
                                      )
                                    : (
                                        'Querying GAM...'
                                      )}
                                </TableCell>
                              </TableRow>
                            ))
                          )
                        : (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <LinearProgress />
                            </TableCell>
                          </TableRow>
                          )}
                    </TableBody>
                  </Table>
                </Grid>
              </CardContent>
            </Card>
          </CardContent>
        </form>
      </Paper>
    </Container>
  )
}

export default DFPCache
