import React from 'react'
import {
  Typography,
  ButtonGroup,
  Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  inline: {
    display: 'inline-block',
    margin: '8px'
  },
  active: {
    backgroundColor: theme.palette.primary.main
  }
}))

export default function ButtonSwitch (props) {
  const { data } = props
  const { label, variant, color, active, onText, offText, handleClick } = data
  const classes = useStyles()

  return (
    <div className={classes.inline}>
      {label && <Typography className={label.classes ? label.classes : ''}>{label.text}</Typography>}
      <ButtonGroup variant={!variant ? 'contained' : variant} color={!color ? 'primary' : color} aria-label='button group' onClick={handleClick}>
        <Button className={classes.displayInlineBlock + (active ? ' ' + classes.active : '')}>{onText}</Button>
        <Button className={classes.displayInlineBlock + (!active ? ' ' + classes.active : '')}>{offText}</Button>
      </ButtonGroup>
    </div>
  )
}

ButtonSwitch.propTypes = {
  label: PropTypes.shape({
    classes: PropTypes.string,
    text: PropTypes.string
  }),
  variant: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
  onText: PropTypes.string,
  offText: PropTypes.string,
  handleClick: PropTypes.func
}
