import React from 'react'
import {
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  inline: {
    display: 'inline-block',
    margin: '8px'
  }
}))

export default function Checkboxes (props) {
  const { data } = props
  const { label, name, checkboxes, handleChange } = data
  const classes = useStyles()
  return (
    <div className={classes.inline} style={{ padding: '0', margin: '0' }}>
      {label && (
        <Typography
          className={label.classes ? label.classes : ''}
          style={{ marginBottom: '0' }}
        >
          {label.text}
        </Typography>
      )}
      <FormGroup
        className={classes.inline}
        aria-label={name}
        row
        name={name}
        style={{ marginTop: '0' }}
      >
        {checkboxes.map((checkbox, index) => {
          return (
            <FormControlLabel
              key={index}
              value={checkbox.value}
              control={<Checkbox color={checkbox.color ? checkbox.color : 'primary'} checked={checkbox.checked} onChange={handleChange(checkbox.value)} />}
              label={<Typography variant={checkbox.labelVariant ? checkbox.labelVariant : 'body1'}>{checkbox.label}</Typography>}
              labelPlacement={checkbox.labelPlacement ? checkbox.labelPlacement : 'end'}
            />
          )
        })}
      </FormGroup>
    </div>
  )
}

Checkboxes.propTypes = {
  label: PropTypes.shape({
    classes: PropTypes.string,
    text: PropTypes.string
  }),
  name: PropTypes.string,
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      checked: PropTypes.boolean,
      labelPlacement: PropTypes.string,
      color: PropTypes.string
    })
  ),
  handleChange: PropTypes.func
}
