import React from 'react'

import { TextField, Card, CardContent, FormHelperText, Input, InputAdornment, Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core'

const defaults = {
  MAX_NO_OF_ADS: 6, // max amount of Ads or Marketing Creatives
  BUFFER: 6, // initial paragraph buffer
  INITIAL_PLACEMENT: 4,
  REGISTRY: '//i.cdn.turner.com/ads/cnn/cnn_quantum_leaf.json',
  PARENT: '.article__content', // Wrapper element selector - defaults to Stellar content Wrapper
  CHILDREN: 'p', // Child element selector - defaults to paragraph
  OFFSET: 2, // how much to offset each adslot by
  lazyLoadOptions: {
    scrollThresholdDesktop: 25,
    scrollThresholdMobile: 100
  } // options to configure lazyloads for ads
}

export const RealTimeAdInsertion = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { RTAI } = options
  const { NUMBER, STRING } = validation
  const { MAXADS, BUFFER_OFFSET, INITIAL_INDEX } = NUMBER
  const { LENGTH } = STRING
  let { MAX_NO_OF_ADS, BUFFER, INITIAL_PLACEMENT, REGISTRY, PARENT, CHILDREN, OFFSET } = RTAI || defaults

  const displayPropertyTable = () => {
    return (
      <Table size='small' aria-label='RITA-property-table'>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '240px' }}><Typography variant='h5'>Maximum Number of Ads</Typography></TableCell>
            <TableCell>
              <Input
                style={{ minWidth: '80px' }}
                margin='none'
                inputProps={{
                  type: 'number',
                  min: MAXADS.min,
                  max: MAXADS.max
                }}
                value={MAX_NO_OF_ADS === '' ? '' : MAX_NO_OF_ADS}
                error={errors.RTAI_MAXADS}
                endAdornment={<InputAdornment position='end'> ads</InputAdornment>}
                onChange={(event) => {
                  const value = event.target.value
                  RTAI.MAX_NO_OF_ADS = value === '' ? 3 : typeof value === 'string' ? parseInt(value, 10) : value
                  setErrors({ ...errors, RTAI_MAXADS: (value < MAXADS.min) || (value > MAXADS.max) })
                  setOptions({ ...options, RTAI })
                  setAllOptions({ ...allOptions, RTAI })
                }}
              />
              <FormHelperText>{errors.RTAI_MAXADS && MAXADS.message}</FormHelperText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '240px' }}><Typography variant='h5'>Buffer</Typography></TableCell>
            <TableCell>
              <Input
                style={{ minWidth: '80px' }}
                margin='none'
                inputProps={{
                  type: 'number',
                  min: BUFFER_OFFSET.min,
                  max: BUFFER_OFFSET.max
                }}
                value={BUFFER === '' ? 6 : BUFFER}
                error={errors.RTAI_BUFFER}
                endAdornment={<InputAdornment position='end'> element{BUFFER > 1 ? 's':''}</InputAdornment>}
                onChange={(event) => {
                  const value = event.target.value
                  RTAI.BUFFER = value === '' ? '' : typeof value === 'string' ? parseInt(value, 10) : value
                  setErrors({ ...errors, RTAI_BUFFER: (value < BUFFER_OFFSET.min) || (value > BUFFER_OFFSET.max) })
                  setOptions({ ...options, RTAI })
                  setAllOptions({ ...allOptions, RTAI })
                }}
              />
              <FormHelperText>{errors.RTAI_MAXADS && BUFFER_OFFSET.message}</FormHelperText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '240px' }}><Typography variant='h5'>Offset</Typography></TableCell>
            <TableCell>
              <Input
                style={{ minWidth: '80px' }}
                margin='none'
                inputProps={{
                  type: 'number',
                  min: BUFFER_OFFSET.min,
                  max: BUFFER_OFFSET.max
                }}
                value={OFFSET === '' ? 2 : OFFSET}
                error={errors.RTAI_OFFSET}
                endAdornment={<InputAdornment position='end'> element{OFFSET > 1 ? 's':''}</InputAdornment>}
                onChange={(event) => {
                  const value = event.target.value
                  RTAI.OFFSET = value === '' ? '' : typeof value === 'string' ? parseInt(value, 10) : value
                  setErrors({ ...errors, RTAI_OFFSET: (value < BUFFER_OFFSET.min) || (value > BUFFER_OFFSET.max) })
                  setOptions({ ...options, RTAI })
                  setAllOptions({ ...allOptions, RTAI })
                }}
              />
              <FormHelperText>{errors.RTAI_MAXADS && BUFFER_OFFSET.message}</FormHelperText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '240px' }}><Typography variant='h5'>Initial Placement</Typography></TableCell>
            <TableCell>
              <Input
                style={{ minWidth: '80px' }}
                margin='none'
                inputProps={{
                  type: 'number',
                  min: INITIAL_INDEX.min,
                  max: INITIAL_INDEX.max
                }}
                value={INITIAL_PLACEMENT === '' ? 4 : INITIAL_PLACEMENT}
                error={errors.RTAI_IP}
                startAdornment={<InputAdornment position='start'>After the</InputAdornment>}
                endAdornment={<InputAdornment position='end'>{`${INITIAL_PLACEMENT > 9 && INITIAL_PLACEMENT < 20 ? 'th' : INITIAL_PLACEMENT % 10 === 1 ? 'st' : INITIAL_PLACEMENT % 10 === 2 ? 'nd' : INITIAL_PLACEMENT % 10 === 3 ? 'rd' : 'th'} element`}</InputAdornment>}
                onChange={(event) => {
                  const value = event.target.value
                  RTAI.INITIAL_PLACEMENT = value === '' ? '' : typeof value === 'string' ? parseInt(value, 10) : value
                  setErrors({ ...errors, RTAI_IP: (value < INITIAL_INDEX.min) || (value > INITIAL_INDEX.max) })
                  setOptions({ ...options, RTAI })
                  setAllOptions({ ...allOptions, RTAI })
                }}
              />
              <FormHelperText>{errors.RTAI_IP && INITIAL_INDEX.message}</FormHelperText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '240px' }}><Typography variant='h5'>Parent Selector</Typography></TableCell>
            <TableCell>
              <TextField
                margin='none'
                value={PARENT === '' ? defaults.PARENT : PARENT}
                error={errors.RTAI_PARENT}
                helperText={errors.RTAI_PARENT && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  RTAI.PARENT = value
                  setErrors({ ...errors, RTAI_PARENT: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, RTAI })
                  setAllOptions({ ...allOptions, RTAI })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <Card>
      <CardContent>
        {displayPropertyTable()}
      </CardContent>
    </Card>
  )
}
