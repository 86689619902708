import React from 'react'

import { Card, CardContent, Table, TableBody, TableRow, TableCell, TextField, Typography } from '@material-ui/core'

export const Beemray = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { BEEMRAY } = options
  const { API_KEY, CUSTOMER_ID } = BEEMRAY
  const { STRING } = validation
  const { LENGTH } = STRING

  const displayPropertyTable = () => {
    return (
      <Card>
        <CardContent>
          <Table size='small' aria-label='beemray-property-table'>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '180px' }}><Typography variant='h5'>API Key</Typography></TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    margin='none'
                    placeholder='API Key'
                    value={API_KEY}
                    error={errors.BEEMRAY_APIK}
                    helperText={errors.BEEMRAY_APIK && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      BEEMRAY.API_KEY = value
                      setErrors({ ...errors, BEEMRAY_APIK: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, BEEMRAY })
                      setAllOptions({ ...allOptions, BEEMRAY })
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: '180px' }}><Typography variant='h5'>Customer ID</Typography></TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    margin='none'
                    placeholder='Customer ID'
                    value={CUSTOMER_ID}
                    error={errors.BEEMRAY_CID}
                    helperText={errors.BEEMRAY_CID && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      BEEMRAY.CUSTOMER_ID = event.target.value
                      setErrors({ ...errors, BEEMRAY_CID: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, BEEMRAY })
                      setAllOptions({ ...allOptions, BEEMRAY })
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
  }

  return (
    displayPropertyTable()
  )
}
