const config = {
  local: {
    apiBaseUrl: 'http://localhost:3080/api',
    publisher: {
      rootAdFuelDirectory: '/www/ads/dev/adfuel',
      rootAdFuelAisDirectory: '/www/ads/dev/adfuel/ais',
      rootSiteDirectory: '/www/ads/dev/{siteName}',
      rootAdSpacesDirectory: '/www/ads/dev/adspaces',
      rootAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
      rootAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
      rootSSLAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
      rootSSLAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
      rootSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
      rootSSLSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
      rootAdSpacesUrl: 'https://i.cdn.turner.com/ads/dev/adspaces',
      rootAnalyticsSiteUrl: 'https://i.cdn.turner.com/analytics/{siteName}'
    },
    saml: {
      whoamiUrl: 'http://localhost:3080/whoami',
      loginUrl: 'http://localhost:3080/login',
      logoutUrl: 'http://localhost:3080/logout'
    }
  },
  development: {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.rocketeer.turnerapps.com/api',
    publisher: {
      rootAdFuelDirectory: '/www/ads/dev/adfuel',
      rootAdFuelAisDirectory: '/www/ads/dev/adfuel/ais',
      rootSiteDirectory: '/www/ads/dev/{siteName}',
      rootAdSpacesDirectory: '/www/ads/dev/adspaces',
      rootAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
      rootAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
      rootSSLAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
      rootSSLAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
      rootSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
      rootSSLSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
      rootAdSpacesUrl: 'https://i.cdn.turner.com/ads/dev/adspaces',
      rootAnalyticsSiteUrl: 'https://i.cdn.turner.com/analytics/{siteName}'
    },
    saml: {
      whoamiUrl: 'https://api.rocketeer.turnerapps.com/whoami',
      loginUrl: 'https://api.rocketeer.turnerapps.com/login',
      logoutUrl: 'https://api.rocketeer.turnerapps.com/logout',
    }
  },
  qa: {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://qa.api.rocketeer.turnerapps.com/api',
    publisher: {
      rootAdFuelDirectory: '/www/ads/qa/adfuel',
      rootAdFuelAisDirectory: '/www/ads/qa/adfuel/ais',
      rootSiteDirectory: '/www/ads/qa/{siteName}',
      rootAdSpacesDirectory: '/www/ads/qa/adspaces',
      rootAdFuelUrl: 'https://i.cdn.turner.com/ads/qa/adfuel',
      rootAdFuelAisUrl: 'https://i.cdn.turner.com/ads/qa/adfuel/ais',
      rootSSLAdFuelUrl: 'https://i.cdn.turner.com/ads/qa/adfuel',
      rootSSLAdFuelAisUrl: 'https://i.cdn.turner.com/ads/qa/adfuel/ais',
      rootSiteUrl: 'https://i.cdn.turner.com/ads/qa/{siteName}',
      rootSSLSiteUrl: 'https://i.cdn.turner.com/ads/qa/{siteName}',
      rootAdSpacesUrl: 'https://i.cdn.turner.com/ads/qa/adspaces',
      rootAnalyticsSiteUrl: 'https://i.cdn.turner.com/analytics/{siteName}'
    },
    saml: {
      whoamiUrl: 'https://qa.api.rocketeer.turnerapps.com/whoami',
      loginUrl: 'https://qa.api.rocketeer.turnerapps.com/login',
      logoutUrl: 'https://qa.api.rocketeer.turnerapps.com/logout',
    }
  },
  production: {
    // creating just for testing something
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://prod.api.rocketeer.turnerapps.com/api',
    publisher: {
      rootAdFuelDirectory: '/www/ads/adfuel',
      rootAdFuelAisDirectory: '/www/ads/adfuel/ais',
      rootSiteDirectory: '/www/ads/{siteName}',
      rootAdSpacesDirectory: '/www/ads/adspaces',
      rootAdFuelUrl: 'https://i.cdn.turner.com/ads/adfuel',
      rootAdFuelAisUrl: 'https://i.cdn.turner.com/ads/adfuel/ais',
      rootSSLAdFuelUrl: 'https://i.cdn.turner.com/ads/adfuel',
      rootSSLAdFuelAisUrl: 'https://i.cdn.turner.com/ads/adfuel/ais',
      rootSiteUrl: 'https://i.cdn.turner.com/ads/{siteName}',
      rootSSLSiteUrl: 'https://i.cdn.turner.com/ads/{siteName}',
      rootAdSpacesUrl: 'https://i.cdn.turner.com/ads/adspaces',
      rootAnalyticsSiteUrl: 'https://i.cdn.turner.com/analytics/{siteName}'
    },
    saml: {
      whoamiUrl: 'https://prod.api.rocketeer.turnerapps.com/whoami',
      loginUrl: 'https://prod.api.rocketeer.turnerapps.com/login',
      logoutUrl: 'https://prod.api.rocketeer.turnerapps.com/logout',
    }
  }
}

export default config
