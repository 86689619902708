import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { commonStyle, addClasses } from '../../styles'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FormHelperText from '@material-ui/core/FormHelperText'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  string as yupstring,
  number as yupnumber,
  object as yupobject
} from 'yup'
import { ToggleSwitch } from '../ui'
import { SlotsBeta } from '../slots'
import { AddSlotToNewRegistry } from '../../modules/slotSlice'
import {
  ToggleNewRegistryStatus,
  ToggleInViewRefresh,
  ToggleRealTimeAdInsertion,
} from '../../modules/registrySlice'
import { fetchSiteIVRConfiguration, fetchSiteRTAIConfiguration } from '../../modules/sitesSlice'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  // registryNameFormatter2,
  isValidRegistryName
} from '../../utilities/helperFunctions'

const swal = withReactContent(Swal)
const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300
  },
  ConfigurationItemContainer: {
    paddingTop: '4px'
  },
  ConfigurationItems: {
    margin: '2px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '95%',
    border: 'none',
    boxShadow: 'none'
  },  
  Targetings: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
  link: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  smallFont: {
    fontSize: '.8rem',
    opacity: 0.8
  },
  maxWidth: {
    width: '100%'
  },
  divider: {
    height: 28,
    margin: 4
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}))

const NewRegistryDialogBeta = props => {
  const theme = useTheme()
  const commonClasses = commonStyle()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const validationSchema = yupobject().shape({
    registryName: yupstring().required('Required'),
    registryDescription: yupstring().required('Required'),
    noOfSlots: yupnumber().min(1)
  })

  const [betaTest] = useState(
    window.localStorage.getItem('beta') === 'true'
  )
  
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })
  const { loading, registry, inViewRefresh, realTimeAdInsertion, registries } = useSelector(
    state => state.registrySlice
  )
  const { site, siteIVR } = useSelector(state => state.sitesSlice)
  const [open, setOpen] = useState(props.isNew)
  const [newRegistryData, setNewRegistryData] = useState([])
  const [isSafeframeConfigEnable, setIsSafeframeConfigEnable] = useState(false)
  // const [inViewRefresh, setInViewRefresh] = useState(false)

  const siteHasModule = moduleName => {
    let hasModule = false
    if (site && site.adFuelModules) {
      site.adFuelModules.forEach(afModule => {
        if (afModule.name.indexOf(moduleName) >= 0) hasModule = true
      })
    }
    return hasModule
  }

  const handleSaveRegistry = () => {
    if (!isValidRegistryName(newRegistryData.name)) {
      swal.fire({
        text: `${newRegistryData.name} is invalid. Only letters, numbers, underscores and dashes are allowed!`,
        icon: 'error'
      })
    } else {
      const foundRegistrie = registries.some(
        registry => registry.name === newRegistryData.name
      )
      if (foundRegistrie) {
        swal.fire({
          text: `${newRegistryData.name} is already in use.`,
          icon: 'error'
        })
      } else {
        props.handleSave(newRegistryData)
      }
    }
  }

  const handleRegistryChange = e => {
    console.log('===== NEW REGISTRY DIALOG ===== Handling Registry Change..', e.target)
    switch (e.target.name) {
      case 'registryName':
        // if (!isValidRegistryName(e.target.value)) {
        //   swal.fire({
        //     text: `${e.target.value} is invalid. Only letters, numbers, underscores and dashes are allowed!`,
        //     icon: 'error'
        //   })
        // }
        setNewRegistryData({
          ...newRegistryData,
          name: e.target.value
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          name: e.target.value
        }))
        setValue('registryName', e.target.value, { shouldValidate: true })
        break
      case 'registryDescription':
        setNewRegistryData({
          ...newRegistryData,
          description: e.target.value
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          description: e.target.value
        }))
        setValue('registryDescription', e.target.value, {
          shouldValidate: true
        })
        break
      case 'registrySingleton':
        setNewRegistryData({
          ...newRegistryData,
          isSingleton: e.target.checked
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          isSingleton: e.target.checked
        }))
        break
      case 'registryTagChildDirectedTreatment':
        setNewRegistryData({
          ...newRegistryData,
          tagForChildDirectedTreatment: e.target.checked
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          tagForChildDirectedTreatment: e.target.checked
        }))
        break
      case 'registryInViewRefresh':
        setNewRegistryData({
          ...newRegistryData,
          hasInViewRefresh: e.target.checked,
          inViewRefreshCount: 0,
          inViewRefreshInterval: 0
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          hasInViewRefresh: e.target.checked,
          inViewRefreshCount: 0,
          inViewRefreshInterval: 0
        }))
        toggleInViewRefresh(e.target.checked)
        break
      case 'registryRealTimeAdInsertion':
        setNewRegistryData({
          ...newRegistryData,
          hasRealTimeAdInsertion: e.target.checked
        })
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          hasRealTimeAdInsertion: e.target.checked
        }))
        toggleRealTimeAdInsertion(e.target.checked)
        break
      case 'registrySafeframeConfig':
        setNewRegistryData({
          ...newRegistryData,
          safeFrameConfig: e.target.checked
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          safeFrameConfig: e.target.checked
        }))
        // Dispatch new registry data to slots component
        setIsSafeframeConfigEnable(e.target.checked)
        break
      case 'registrySandbox':
        setNewRegistryData({
          ...newRegistryData,
          safeFrameSandboxMode: e.target.checked
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          safeFrameSandboxMode: e.target.checked
        }))
        break
      case 'registryAllowOverlayExpansion':
        setNewRegistryData({
          ...newRegistryData,
          safeFrameAllowOverlayExpansion: e.target.checked
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          safeFrameAllowOverlayExpansion: e.target.checked
        }))
        break
      case 'registryAllowPushExpansion':
        setNewRegistryData({
          ...newRegistryData,
          safeFrameAllowPushExpansion: e.target.checked
        })
        // Dispatch new registry data to slots component
        dispatch(AddSlotToNewRegistry({
          ...newRegistryData,
          safeFrameAllowPushExpansion: e.target.checked
        }))
        break
      default:
        break
    }
  }

  const handleDialogClose = () => {
    setOpen(false)
    props.handleDialogClose()
  }

  const toggleInViewRefresh = e => {
    console.log('===== NEW REGISTRY DIALOG ===== Toggling In-View Refresh...')
    dispatch(ToggleInViewRefresh(e))
  }

  const toggleRealTimeAdInsertion = e => {
    console.log('===== NEW REGISTRY DIALOG ===== Toggling Real-Time Ad Insertion...')
    dispatch(ToggleRealTimeAdInsertion(e))
  }

  useEffect(() => {
    setOpen(props.isNew || false)
  }, [props.isNew])

  useEffect(() => {
    if (registry) {
      setNewRegistryData(registry)
      setIsSafeframeConfigEnable(registry?.safeFrameConfig)
      // setIsViewRefresh(registry?.hasInViewRefresh)
      toggleInViewRefresh(registry?.hasInViewRefresh)
      toggleRealTimeAdInsertion(registry?.hasRealTimeAdInsertion)
      setValue(
        'noOfSlots',
        registry?.slots?.length ? registry?.slots?.length : 0,
        { shouldValidate: true }
      )
      if (props.useWOPR) {
        setValue('registryName', registry?.name ? registry?.name : '')
        setValue('registryDescription', registry?.description ? registry?.description : '')
      }
    }
  }, [registry])

  useEffect(() => {
    dispatch(fetchSiteIVRConfiguration(site?._id))
    dispatch(fetchSiteRTAIConfiguration(site?._id))
  }, [site])
  console.log('[NewRegistryDialogBeta] ---------- render::newRegistryData', newRegistryData)
  console.log('[NewRegsitryDialogBeta] ---------- render::disabled', { useWOPR: props.useWOPR, isClone: !!newRegistryData.isClone })
  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      maxWidth='lg'
      fullWidth
      fullScreen={fullScreen}
      className={commonClasses.roundedCard}
    >
      <DialogTitle style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrRegistryHeader)} titleTypographyProps={{ variant: 'h6' }} id='form-dialog-title'>Add New Registry</DialogTitle>
      <DialogContent dividers>
        <>
          <Card key={'registryGeneral'}>
            <CardContent>
              <Grid container key={'name'}>
                <Grid item md={12} xs={12} style={{ marginBottom: '10pt' }}>
                  <TextField
                    key='Name'
                    label='Name'
                    name='registryName'
                    autoComplete='off'
                    margin='dense'
                    fullWidth
                    disabled={(props.useWOPR && props.useWOPR === true)}
                    style={{ marginTop: 0 }}
                    id={newRegistryData.name}
                    // defaultValue={newRegistryData.name}
                    value={newRegistryData.name || ''}
                    {...register('registryName')}
                    onChange={e => handleRegistryChange(e)}
                    error={!!errors.registryName}
                    helperText={
                      errors.registryName ? errors.registryName.message : ''
                    }
                  />
                </Grid>
              </Grid>
              <Grid container key={'description'}>
                <Grid item md={12} xs={12}>
                    <TextField
                      label='Description'
                      name='registryDescription'
                      autoComplete='off'
                      fullWidth
                      margin='dense'
                      // defaultValue={newRegistryData.description}
                      value={newRegistryData.description || ''}
                      {...register('registryDescription')}
                      onChange={e => handleRegistryChange(e)}
                      error={!!errors.registryDescription}
                      helperText={
                        errors.registryDescription
                          ? errors.registryDescription.message
                          : ''
                      }
                    />
                </Grid>
              </Grid>
              <Grid container key={'tagForChildDirectedTreatment-singleton'} style={{borderBottom: 'solid 1px rgba(0,0,0,0.54)', borderCollapse: 'collapse' }}>
                <Grid item md={2} xs={2}>
                  <ToggleSwitch
                    label='Child-Directed Treatment'
                    name='registryTagChildDirectedTreatment'
                    offLabel='No'
                    onLabel='Yes'
                    checked={!!newRegistryData.tagForChildDirectedTreatment}
                    onChange={e => handleRegistryChange(e)}
                    value={newRegistryData.tagForChildDirectedTreatment}
                  />
                </Grid>
                <Grid item md={3} xs={3}>
                  <ToggleSwitch
                    label='Singleton'
                    name='registrySingleton'
                    offLabel='No'
                    onLabel='Yes'
                    checked={!!newRegistryData.isSingleton}
                    onChange={e => {
                      handleRegistryChange(e)
                    }}
                  />
                </Grid>                    
              </Grid>
              {siteHasModule('In-View Refresh') ? (
                <Grid container key={'inViewRefresh'} style={{borderBottom: 'solid 1px rgba(0,0,0,0.54)', borderCollapse: 'collapse' }}>
                  <Grid item md={2} xs={2}>
                    <ToggleSwitch
                      label='In-View Refresh'
                      name='registryInViewRefresh'
                      offLabel='Disabled'
                      onLabel='Enabled'
                      checked={!!newRegistryData.hasInViewRefresh}
                      onChange={e => {
                        handleRegistryChange(e)
                      }}
                      value={!!newRegistryData.hasInViewRefresh}
                    />
                  </Grid>
                  <Grid item md={10} xs={10}>
                    {inViewRefresh
                      ? (
                        <Grid container>
                          <Grid item md={2} xs={2} className={classes.ConfigurationItemContainer}>
                            {newRegistryData.hasInViewRefresh
                              ? (
                                <>
                                  <Paper className={classes.ConfigurationItems}>
                                    <TextField
                                      {...register('registryViewRefreshCount')}
                                      className={classes.input}
                                      label='Refresh Count'
                                      margin='dense'
                                      id='registryViewRefreshCount'
                                      variant='standard'
                                      onChange={e => {
                                        handleRegistryChange(e)
                                      }}
                                      value={
                                        newRegistryData.inViewRefreshCount === 0
                              ? null
                              : newRegistryData.inViewRefreshCount
                          }
                                      type='number'
                                      InputProps={{
                                        inputProps: { min: '5', step: '1' }
                                      }}
                                      error={!!errors.registryViewRefreshCount}
                                      helperText={
                            errors.registryViewRefreshCount
                              ? errors.registryViewRefreshCount.message
                              : ''
                          }
                                    />
                                    <Divider
                                      className={commonClasses.verticalDivider28}
                                      orientation='vertical'
                                    />
                                    <span className={classes.smallFont}>times</span>
                                  </Paper>
                                </>
                                )
                              : (
                                <></>
                                )}
                          </Grid>
                          <Grid item md={2} xs={2} className={classes.ConfigurationItemContainer}>
                            {newRegistryData.hasInViewRefresh
                              ? (
                                <>
                                  <Paper className={classes.ConfigurationItems}>
                                    <TextField
                                      {...register('registryViewRefreshInterval')}
                                      className={classes.input}
                                      label='Refresh Interval'
                                      margin='dense'
                                      id='registryViewRefreshInterval'
                                      variant='standard'
                                      type='number'
                                      InputProps={{
                                        inputProps: { min: '35', step: '1' }
                                      }}
                                      onChange={e => {
                                        handleRegistryChange(e)
                                      }}
                                      value={
                                        newRegistryData.inViewRefreshInterval === 0
                              ? null
                              : newRegistryData.inViewRefreshInterval
                          }
                                      error={!!errors.registryViewRefreshInterval}
                                      helperText={
                            errors.registryViewRefreshInterval
                              ? errors.registryViewRefreshInterval.message
                              : ''
                          }
                                    />
                                    <Divider
                                      className={commonClasses.verticalDivider28}
                                      orientation='vertical'
                                    />
                                    <span className={classes.smallFont}>seconds</span>
                                  </Paper>
                                </>
                                )
                              : (
                                <></>
                                )}
                          </Grid>
                        </Grid>
                        )
                      : (
                        <></>
                        )}
                  </Grid>
                </Grid>
              ): ''}
              {siteHasModule('Real-Time Ad Insertion') ? (
              <Grid container key={'realTimeAdInsertion'} style={{borderBottom: 'solid 1px rgba(0,0,0,0.54)', borderCollapse: 'collapse' }}>
                <Grid item md={2} xs={2}>
                  <ToggleSwitch
                    label='Real-Time Ad Insertion'
                    name='registryRealTimeAdInsertion'
                    offLabel='Disabled'
                    onLabel='Enabled'
                    checked={!!newRegistryData.hasRealTimeAdInsertion}
                    onChange={e => {
                      handleRegistryChange(e)
                    }}
                    value={!!newRegistryData.hasRealTimeAdInsertion}
                  />
                </Grid>
                <Grid item md={10} xs={10}>
                  {realTimeAdInsertion
                    ? (
                      <Grid container>
                        <Grid item md={2} xs={2} key={'rtaiMaxNoOfAdsItem'} className={classes.ConfigurationItemContainer}>
                          {newRegistryData.hasRealTimeAdInsertion
                            ? (
                              <Paper className={classes.ConfigurationItems}>
                                <TextField
                                  {...register('registryRTAIMaxNoOfAds')}
                                  className={classes.input}
                                  label='Max # of Ads'
                                  margin='dense'
                                  id='registryRTAIMaxNoOfAds'
                                  variant='standard'
                                  onChange={e => {
                                    handleRegistryChange(e)
                                  }}
                                  value={
                                    newRegistryData.rtaiMaxNoOfAds === 0
                                      ? 6
                                      : newRegistryData.rtaiMaxNoOfAds
                                  }
                                  type='number'
                                  InputProps={{
                                    inputProps: { min: '1', step: '1' }
                                  }}
                                  error={!!errors.registryRTAIMaxNoOfAds}
                                  helperText={
                                    errors.registryRTAIMaxNoOfAds
                                      ? errors.registryRTAIMaxNoOfAds.message
                                      : ''
                                  }
                                />
                              </Paper>
                              )
                            : (
                              <></>
                              )
                          }
                        </Grid>
                        <Grid item md={2} xs={2} key={'rtaiBuffer'} className={classes.ConfigurationItemContainer}>
                          {newRegistryData.hasRealTimeAdInsertion
                            ? (
                              <Paper className={classes.ConfigurationItems}>
                                <TextField
                                  {...register('registryRTAIBuffer')}
                                  className={classes.input}
                                  label='Buffer'
                                  margin='dense'
                                  id='registryRTAIBuffer'
                                  variant='standard'
                                  onChange={e => {
                                    handleRegistryChange(e)
                                  }}
                                  value={
                                    newRegistryData.rtaiBuffer === 0
                                      ? 4
                                      : newRegistryData.rtaiBuffer
                                  }
                                  type='number'
                                  InputProps={{
                                    inputProps: { min: '1', step: '1' }
                                  }}
                                  error={!!errors.registryRTAIBuffer}
                                  helperText={
                                    errors.registryRTAIBuffer
                                      ? errors.registryRTAIBuffer.message
                                      : ''
                                  }
                                />
                              </Paper>
                            )
                            : (
                              <></>
                            )
                          }
                        </Grid>
                        <Grid item md={2} xs={2} key={'rtaiOffset'} className={classes.ConfigurationItemContainer}>
                        {newRegistryData.hasRealTimeAdInsertion
                            ? (
                              <Paper className={classes.ConfigurationItems}>
                                <TextField
                                  {...register('registryRTAIOffset')}
                                  className={classes.input}
                                  label='Offset'
                                  margin='dense'
                                  id='registryRTAIOffset'
                                  variant='standard'
                                  onChange={e => {
                                    handleRegistryChange(e)
                                  }}
                                  value={
                                    newRegistryData.rtaiOffset === 0
                                      ? 2
                                      : newRegistryData.rtaiOffset
                                  }
                                  type='number'
                                  InputProps={{
                                    inputProps: { min: '1', step: '1' }
                                  }}
                                  error={!!errors.registryRTAIOffset}
                                  helperText={
                                    errors.registryRTAIOffset
                                      ? errors.registryRTAIOffset.message
                                      : ''
                                  }
                                />
                              </Paper>
                            )
                            : (
                              <></>
                            )
                          }
                        </Grid>
                        <Grid item md={2} xs={2} key={'rtaiInitialPlacement'} className={classes.ConfigurationItemContainer}>
                          {newRegistryData.hasRealTimeAdInsertion
                            ? (
                              <Paper className={classes.ConfigurationItems}>
                                <TextField
                                  {...register('registryRTAIInitialPlacement')}
                                  className={classes.input}
                                  label='Initial Placement'
                                  margin='dense'
                                  id='registryRTAIInitialPlacement'
                                  variant='standard'
                                  onChange={e => {
                                    handleRegistryChange(e)
                                  }}
                                  value={
                                    newRegistryData.rtaiInitialPlacement === 0
                                      ? 4
                                      : newRegistryData.rtaiInitialPlacement
                                  }
                                  type='number'
                                  InputProps={{
                                    inputProps: { min: '1', step: '1' }
                                  }}
                                  error={!!errors.registryRTAIInitialPlacement}
                                  helperText={
                                    errors.registryRTAIInitialPlacement
                                      ? errors.registryRTAIInitialPlacement.message
                                      : ''
                                  }
                                />
                              </Paper>
                            )
                            : (
                              <></>
                              )
                          }
                        </Grid>
                        <Grid item md={4} xs={4} key={'rtaiParentSelector'} className={classes.ConfigurationItemContainer}>
                          {newRegistryData.hasRealTimeAdInsertion
                            ? (
                              <Paper className={classes.ConfigurationItems}>
                                <TextField
                                  fullWidth
                                  label='Parent Selector'
                                  margin='dense'
                                  id='registryRTAIParentSelector'
                                  variant='standard'
                                  value={newRegistryData.rtaiParentSelector}
                                  {...register('registryRTAIParentSelector')}
                                  onChange={e => handleRegistryChange(e)}
                                  error={!!errors.registryRTAIParentSelector}
                                  helperText={
                                    errors.registryRTAIParentSelector
                                      ? errors.registryRTAIParentSelector.message
                                      : ''
                                  }
                                />
                              </Paper>
                            )
                            : (
                              <></>
                            )
                          }
                        </Grid>
                      </Grid>
                      )
                    : (
                      <></>
                      )}
                </Grid>
              </Grid>
              ): ''}
              <Grid container key={'safeframeConfig'}>
                <Grid item md={2} xs={2}>
                  <ToggleSwitch
                    label='Safeframe Config'
                    name='registrySafeframeConfig'
                    offLabel='Disabled'
                    onLabel='Enabled'
                    checked={!!newRegistryData.safeFrameConfig}
                    onChange={e => handleRegistryChange(e)}
                    value={newRegistryData.safeFrameConfig}
                  />
                </Grid>
                <Grid item md={10} xs={10}>
                  {isSafeframeConfigEnable 
                    ? (
                      <Grid container>
                        <Grid item md={3} xs={3} className={classes.ConfigurationItemContainer}>
                        <Paper className={classes.ConfigurationItems}>
                          <ToggleSwitch
                            label='Sandbox'
                            name='registrySandbox'
                            offLabel='No'
                            onLabel='Yes'
                            checked={!!newRegistryData.safeFrameSandboxMode}
                            onChange={e => handleRegistryChange(e)}
                            value={newRegistryData.safeFrameSandboxMode}
                            smallLabel
                          />
                        </Paper>
                        </Grid>
                        <Grid item md={3} xs={3} className={classes.ConfigurationItemContainer}>
                        <Paper className={classes.ConfigurationItems}>
                          <ToggleSwitch
                            label='Allow Overlay Expansion'
                            name='registryAllowOverlayExpansion'
                            offLabel='No'
                            onLabel='Yes'
                            checked={!!newRegistryData.safeFrameAllowOverlayExpansion}
                            onChange={e => handleRegistryChange(e)}
                            value={newRegistryData.safeFrameAllowOverlayExpansion}
                            smallLabel
                          />
                        </Paper>
                        </Grid>
                        <Grid item md={3} xs={3} className={classes.ConfigurationItemContainer}>
                        <Paper className={classes.ConfigurationItems}>
                          <ToggleSwitch
                            label='Allow Push Expansion'
                            name='registryAllowPushExpansion'
                            offLabel='No'
                            onLabel='Yes'
                            checked={
                              !!newRegistryData.safeFrameAllowPushExpansion
                            }
                            onChange={e => handleRegistryChange(e)}
                            value={newRegistryData.safeFrameAllowPushExpansion}
                            smallLabel
                          />
                        </Paper>
                        </Grid>
                      </Grid>
                    )
                    : (
                      <></>
                    )
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Divider />
        </>
        <Box
          border={errors.noOfSlots ? 1 : 0}
          borderColor={errors.noOfSlots ? 'error.main' : ''}
        >
          <TextField
            name='noOfSlots'
            style={{ display: 'none' }}
            defaultValue={newRegistryData?.slots?.length}
            {...register('noOfSlots')}
          />
          {!!errors.noOfSlots && (
            <FormHelperText
              className={commonClasses.ml_1}
              error={!!errors.noOfSlots}
            >
              Slot(s) is required.
            </FormHelperText>
          )}
          <SlotsBeta isSingleton={newRegistryData.isSingleton} />
        </Box>
      </DialogContent>
      <DialogActions className={classes.wrapper}>
        {loading
          ? (
            <Button
              disabled
              variant='contained'
              title='Saving...'
              className={commonClasses.btnSuccess}
              startIcon={<CircularProgress size={24} />}
            >
              Saving
            </Button>
            )
          : (
            <Button
              autoFocus
              className={commonClasses.btnSuccess}
              onClick={handleSubmit(handleSaveRegistry)}
              variant='contained'
              startIcon={<CheckCircleIcon />}
            >
              Save
            </Button>
            )}
        <Button
          className={commonClasses.ml_1}
          title='Cancel'
          variant='contained'
          onClick={() => dispatch(ToggleNewRegistryStatus())}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewRegistryDialogBeta.propTypes = {
  isNew: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  onCreateRegistry: PropTypes.func,
  useWOPR: PropTypes.bool
}

export default NewRegistryDialogBeta
