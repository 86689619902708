import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, useMediaQuery } from '@material-ui/core'
import { commonStyle } from '../../styles'
import { useTheme } from '@material-ui/core/styles'

export const CustomDialog = ({ open, setOpenDialog, title, subtitle, content, displayDialogAction = true, customAction, size, handleOnClose }) => {
  const classes = commonStyle()
  const theme = useTheme()
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const fullScreen = true
  return (
    <Dialog
      fullWidth={fullScreen}
      maxWidth={'lg'}
      open={open}
      onClose={() => handleOnClose ? handleOnClose() : setOpenDialog(false)}
      aria-labelledby='dialog-title'
    >
      {title ? (<DialogTitle id='dialog-title'>{title}</DialogTitle>) : null}
      <DialogContent
        className={classes.p_0}
        dividers={!!title}
      >
        {subtitle ? (<DialogContentText>{subtitle}</DialogContentText>) : null}
        {content}
      </DialogContent>
      {displayDialogAction
        ? !customAction
            ? (
              <>
                <Divider />
                <DialogActions>
                  <Button
                    className={classes.btnInfo}
                    variant='contained'
                    onClick={() => setOpenDialog(false)}
                  >
                    Ok
                  </Button>
                </DialogActions>
              </>
              )
            : (
              <>
                <DialogActions>
                  {customAction}
                </DialogActions>
              </>)
        : null}
    </Dialog>
  )
}
