import React, { useState, useEffect } from 'react'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Tooltip, IconButton, List, ListItem, TextField, Grid } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

import { commonStyle } from '../../../../styles'
import { convertObjectToArray, convertArrayToObject } from '../../../../utilities/helperFunctions.js'

const swal = withReactContent(Swal)

export const SlotList = ({ path, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation, keyName = 'Slot Size', valueName = 'Slot ID' }) => {
  const commonClasses = commonStyle()
  const { PREBID } = options
  const { STRING } = validation
  const { LENGTH } = STRING
  const [list, setList] = useState([])
  const [entry, setEntry] = useState({ key: '', value: '' })

  const getProperty = (path) => {
    const keys = path.split(/\./)
    const lastIndex = keys.length - 1
    const lastKey = keys[lastIndex]
    let property = PREBID.VENDOR
    keys.forEach((key, index) => {
      if (!property[key]) { property[key] = {} }
      if (index < lastIndex) { property = property[key] }
    })
    return property[lastKey]
  }

  const assignProperty = (value) => {
    const keys = path.split(/\./)
    const lastIndex = keys.length - 1
    const lastKey = keys[lastIndex]
    let property = PREBID.VENDOR
    keys.forEach((key, index) => {
      if (!property[key]) { property[key] = {} }
      if (index < lastIndex) { property = property[key] }
    })
    property[lastKey] = value
  }

  useEffect(() => {
    setList(convertObjectToArray(getProperty(path)))
  }, [getProperty(path)])

  return (
    <List dense disablePadding>
      {list.map((item, index) => {
        return (
          <ListItem dense disableGutters key={item.key}>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  style={{ width: '100px' }}
                  placeholder={keyName}
                  value={item.key}
                  error={errors[path + 'SLOT_' + index]}
                  helperText={errors[path + 'SLOT_' + index] && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    list[index] = { ...list[index], key: value }
                    assignProperty({ ...convertArrayToObject([...list]) })
                    setErrors({ ...errors, [path + 'SLOT_' + index]: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, PREBID })
                    setAllOptions({ ...allOptions, PREBID })
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '100px' }}
                  placeholder={valueName}
                  value={item.value}
                  error={errors[path + 'SLOT_' + index]}
                  helperText={errors[path + 'SLOT_' + index] && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    list[index] = { ...list[index], value }
                    assignProperty({ ...convertArrayToObject([...list]) })
                    setErrors({ ...errors, [path + 'SLOT_' + index]: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, PREBID })
                    setAllOptions({ ...allOptions, PREBID })
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title='Delete'>
                  <IconButton
                    className={commonClasses.btnDanger}
                    size='small'
                    onClick={() => {
                      swal.fire({
                        title: 'Are you sure?',
                        icon: 'warning',
                        showCancelButton: true
                        // buttons: true,
                        // dangerMode: true
                      }).then(ok => {
                        if (ok.isConfirmed) {
                          assignProperty({ ...convertArrayToObject(list.filter(entry => entry.key !== item.key)) })
                          setOptions({ ...options, PREBID })
                          setAllOptions({ ...allOptions, PREBID })
                        }
                      })
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </ListItem>
        )
      })}
      <ListItem dense disableGutters>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              style={{ width: '100px' }}
              placeholder={keyName}
              value={entry.key}
              error={errors[path + 'SLOT_0']}
              helperText={errors[path + 'SLOT_0'] && LENGTH.message}
              onChange={(event) => {
                const value = event.target.value
                setErrors({ ...errors, [path + 'SLOT_0']: value.length < LENGTH.min || value.length > LENGTH.max })
                setEntry({ ...entry, key: value })
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '100px' }}
              placeholder={valueName}
              value={entry.value}
              error={errors[path + 'SLOT_0']}
              helperText={errors[path + 'SLOT_0'] && LENGTH.message}
              onChange={(event) => {
                const value = event.target.value
                setErrors({ ...errors, [path + 'SLOT_0']: value.length < LENGTH.min || value.length > LENGTH.max })
                setEntry({ ...entry, value })
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title='Add'>
              <IconButton
                className={commonClasses.btnSuccess}
                size='small'
                onClick={() => {
                  assignProperty({ ...convertArrayToObject([...list, entry]) })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                  setEntry({ key: '', value: '' })
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  )
}
