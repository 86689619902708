import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getAllLineItems,
  getByCustomTarget,
  getEnhancedLineItems,
  getAudienceSegmentsForCsv,
  getAllCustomTargets,
  deactivateSegments,
  fetchAPISchedule,
  fetchAPIVersion,
} from '../../modules/DFPAdUnitSlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Container,
  Divider,
  Button,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'
import PublishIcon from '@material-ui/icons/Publish'
import MuiAlert from '@material-ui/lab/Alert'
import GetAppIcon from '@material-ui/icons/GetApp'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { addClasses, commonStyle } from '../../styles'
import { request } from '../../utilities/request.js'
import { LoadingProgress } from '../ui'

const swal = withReactContent(Swal)
const environment = window.document.location.href.indexOf('dev.') >= 0 || window.document.location.href.indexOf('localhost') >= 0 ? 'dev/' : window.document.location.href.indexOf('qa.') >= 0 ? 'qa/' : ''

const downloadFile = async (filename, data) => {
  try {
    const file = new window.Blob([data], { type: 'text/csv' })
    const link = document.createElement('a')
    link.download = filename
    link.href = URL.createObjectURL(file)
    link.click()
  } catch (err) {
    // console.log('err', err)
  }
}

const getTask = name => {
  return request(`/task/${name}`)
    .then(res => res)
    .catch(err => err)
}

const getDateForFilename = () => {
  const date = new Date()
  let year = date.getFullYear()
  let month = String(date.getMonth() + 1).padStart(2,'0')
  let day = String(date.getDate()).padStart(2,'0')

  let hr = String(date.getHours()).padStart(2,'0')
  let mn = String(date.getMinutes()).padStart(2,'0')
  let sc = String(date.getSeconds()).padStart(2,'0')

  return `${year}-${month}-${day}@${hr}.${mn}.${sc}`
}

export const GetAllLineItems = ({ task, setTask, expandAll }) => {
  const commonClasses = commonStyle()
  const [expanded, setExpanded] = useState(true)
  const { lastRunBy, lastRunOn, isActive } = task

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='allLineItems-panel-content'
        id='allLineItems-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Get All Line Items</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={commonClasses.p_0}>
        <Card elevation={0} style={{ width: '100%' }} raised={false}>
          <CardContent>
            <div style={{float: 'right' }}>
            </div>
            <MuiAlert severity='info'>
              Last run completed on: {lastRunOn} by user: {lastRunBy}
            </MuiAlert>
          </CardContent>
          <Divider />
          <CardActions className={commonClasses.actionArea}>
            <MuiAlert severity='warning'>
              *** WARNING *** Run time may exceed 30 minutes.
            </MuiAlert>
            <Button
              disabled={isActive}
              className={commonClasses.btnDanger}
              variant='contained'
              startIcon={<CheckCircleIcon />}
              onClick={() => {
                swal.fire({
                  title: 'Are you sure?',
                  text: 'Run time may exceed 30 minutes.',
                  icon: 'warning',
                  showCancelButton: true
                  // buttons: true,
                  // dangerMode: true
                }).then(async ok => {
                  if (ok.isConfirmed) {
                    const res = await getAllLineItems()
                    if (res.reason) {
                      swal.fire({
                        title: 'An error has occurred with your request.',
                        text: res.reason,
                        icon: 'error'
                      })
                    } else {
                      setTask(res.data)
                    }
                  }
                })
              }}
            >
              {isActive ? 'Running...' : 'Run'}
            </Button>
            <Button disabled={isActive} variant="contained" className={commonClasses.btnSuccess} onClick={() => window.open(`https://i.cdn.turner.com/ads/${environment}rocketeer/files/lineItems.csv`, "_blank")}>VIEW REPORT</Button>
          </CardActions>
        </Card>
      </AccordionDetails>
    </Accordion>
  )
}

export const GetAllLineItemsWithAudienceSegmentData = ({ task, setTask, expandAll }) => {
  const commonClasses = commonStyle()
  const [expanded, setExpanded] = useState(true)
  const { lastRunBy, lastRunOn, isActive } = task

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='enhancedLineItems-panel-content'
        id='enhancedLineItems-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Get All Line Items Targeting Audience Segments</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={commonClasses.p_0}>
        <Card elevation={0} style={{ width: '100%' }} raised={false}>
          <CardContent>
            <MuiAlert severity='info'>
              Last run completed on: {lastRunOn} by user: {lastRunBy}
            </MuiAlert>
          </CardContent>
          <Divider />
          <CardActions className={commonClasses.actionArea}>
            <MuiAlert severity='warning'>
              *** WARNING *** Run time may exceed 45 minutes.
            </MuiAlert>
            <Button
              disabled={isActive}
              className={commonClasses.btnDanger}
              variant='contained'
              startIcon={<CheckCircleIcon />}
              onClick={() => {
                swal.fire({
                  title: 'Are you really sure?',
                  text: 'Run time may seriously exceed 45 minutes.  If the report was run recently, try using the existing report.',
                  icon: 'warning',
                  showCancelButton: true
                  // buttons: true,
                  // dangerMode: true
                }).then(async ok => {
                  if (ok.isConfirmed) {
                    const res = await getEnhancedLineItems()
                    if (res.reason) {
                      swal.fire({
                        title: 'An error has occurred with your request.',
                        text: res.reason,
                        icon: 'error'
                      })
                    } else {
                      setTask(res.data)
                    }
                  }
                })
              }}
            >
              {isActive ? 'Running...' : 'Run'}
            </Button>
            <Button disabled={isActive} variant="contained" className={commonClasses.btnSuccess} onClick={() => window.open(`https://i.cdn.turner.com/ads/${environment}rocketeer/files/enhancedLineItems.csv`, "_blank")}>VIEW REPORT</Button>
          </CardActions>
        </Card>
      </AccordionDetails>
    </Accordion>
  )
}

export const GetLineItemsByCustomTarget = ({ expandAll, customTargets }) => {
  const commonClasses = commonStyle()
  const [expanded, setExpanded] = useState(true)
  const [customTargetList, setCustomTargetList] = useState([])
  const [customTargetKey, setCustomTargetKey] = useState('')
  const [errors, setErrors] = useState({})
  const [isRunning, setIsRunning] = useState(false)

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='customTarget-panel-content'
        id='customTarget-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Get Line Items by Custom Target</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={commonClasses.p_0}>
        <Card elevation={0} style={{ width: '100%' }} raised={false}>
          <CardContent>
            <Grid container spacing={2} alignItems='center'>
              <Grid item>
                <Autocomplete
                  style={{ minWidth: 200 }}
                  autoSelect
                  autoComplete
                  noOptionsText='Type to search key'
                  forcePopupIcon={false}
                  options={customTargetList}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    setCustomTargetKey(!newValue ? null : newValue)
                  }}
                  onInputChange={(event, newValue, reason) =>
                    setCustomTargetList(
                      reason === 'input' && newValue.length >= 1
                        ? customTargets.filter(item => item.name.match(RegExp(`^${newValue}`, 'i'))).sort()
                        : [])}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Custom Target'
                      margin='none'
                      error={!!errors.customTargetKey}
                      helperText={
                        errors.customTargetKey && errors.customTargetKey.message
                      }
                      onChange={() => {
                        if (errors.customTargetKey) {
                          delete errors.customTargetKey
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <MuiAlert severity='info'>
                  To get the most up-to-date Line Items, click the Run button
                  above.
                </MuiAlert>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={commonClasses.actionArea}>
            <Button
              disabled={isRunning}
              className={commonClasses.btnSuccess}
              variant='contained'
              startIcon={<GetAppIcon />}
              onClick={async () => {
                if (!customTargetKey) {
                  setErrors({
                    ...errors,
                    customTargetKey: {
                      message: 'Custom Target is required'
                    }
                  })
                  return
                }
                setIsRunning(true)
                const res = await getByCustomTarget(customTargetKey.name)
                if (res.reason) {
                  swal.fire({
                    title: 'An error has occurred with your request.',
                    text: res.reason,
                    icon: 'error'
                  })
                } else {
                  downloadFile(
                    `${customTargetKey.name}_lintItems_${Date.now()}.csv`,
                    res.data
                  )
                }
                setIsRunning(false)
              }}
            >
              {isRunning ? 'Running...' : 'Run & Download'}
            </Button>
          </CardActions>
        </Card>
      </AccordionDetails>
    </Accordion>
  )
}

export const GetAllActiveAudienceSegment = ({ expandAll }) => {
  const commonClasses = commonStyle()
  const [expanded, setExpanded] = useState(true)
  const [isAllRunning, setIsAllRunning] = useState(false)
  const [isActiveRunning, setIsActiveRunning] = useState(false)
  const [isInactiveRunning, setIsInactiveRunning] = useState(false)

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='allLineItems-panel-content'
        id='allLineItems-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Get Audience Segment Data</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={commonClasses.p_0}>
        <Card elevation={0} style={{ width: '100%' }} raised={false}>
          <CardActions className={commonClasses.actionArea}>
            <Button
              disabled={isAllRunning}
              className={commonClasses.btnSuccess}
              variant='contained'
              startIcon={
                isAllRunning ? <CircularProgress size={24} /> : <GetAppIcon />
              }
              onClick={async () => {
                setIsAllRunning(true)
                const res = await getAudienceSegmentsForCsv('ALL')
                if (res.reason) {
                  swal.fire({
                    title: 'An error has occurred with your request.',
                    text: res.reason,
                    icon: 'error'
                  })
                } else {
                  const formattedDate = getDateForFilename()
                  downloadFile(
                    `all_audience_segments_${formattedDate}.csv`,
                    res.data
                  )
                }
                setIsAllRunning(false)
              }}
            >
              {isAllRunning ? 'Downloading...' : 'Download All'}
            </Button>
            <Button
              disabled={isActiveRunning}
              className={commonClasses.btnSuccess}
              variant='contained'
              startIcon={
                isActiveRunning ? <CircularProgress size={24} /> : <GetAppIcon />
              }
              onClick={async () => {
                setIsActiveRunning(true)
                const res = await getAudienceSegmentsForCsv('ACTIVE')
                if (res.reason) {
                  swal.fire({
                    title: 'An error has occurred with your request.',
                    text: res.reason,
                    icon: 'error'
                  })
                } else {
                  const formattedDate = getDateForFilename() // because Filename is lonely
                  downloadFile(
                    `active_audience_segments_${formattedDate}.csv`,
                    res.data
                  )
                }
                setIsActiveRunning(false)
              }}
            >
              {isActiveRunning ? 'Downloading...' : 'Download Active'}
            </Button>
            <Button
              disabled={isInactiveRunning}
              className={commonClasses.btnSuccess}
              variant='contained'
              startIcon={
                isInactiveRunning ? <CircularProgress size={24} /> : <GetAppIcon />
              }
              onClick={async () => {
                setIsInactiveRunning(true)
                const res = await getAudienceSegmentsForCsv('INACTIVE')
                if (res.reason) {
                  swal.fire({
                    title: 'An error has occurred with your request.',
                    text: res.reason,
                    icon: 'error'
                  })
                } else {
                  const formattedDate = getDateForFilename()
                  downloadFile(
                    `inactive_audience_segments_${formattedDate}.csv`,
                    res.data
                  )
                }
                setIsInactiveRunning(false)
              }}
            >
              {isInactiveRunning ? 'Downloading...' : 'Download Inactive'}
            </Button>
          </CardActions>
        </Card>
      </AccordionDetails>
    </Accordion>
  )
}

export const UpdateAudienceSegmentData = ({ expandAll }) => {
  const commonClasses = commonStyle()
  const [expanded, setExpanded] = useState(true)
  const [file, setFile] = useState(null)
  const [segments, setSegments] = useState([])
  const [errorMessage, setErrorMessage] = useState({})
  let fileInputElement = null

  const cleanCut = (string = '', method = ',') => {
    return string.split(method).map(item => item.trim())
  }
  
  const processCSVFile = (input) => {
    const itemsToPrepare = []
    const reader = new window.FileReader()

    reader.onload = (event) => {
      try {
        const data = event.target.result
        console.log('data', data)
        const rows = cleanCut(data, '\n')
        console.log('rows', rows)
        const fields = cleanCut(rows[0])
        console.log('Fields: ', fields)
        rows.forEach((row, index) => {
          let keyValues = {}
          const columns = cleanCut(row)
          console.log('columns', columns)
          if (index > 0) {
            itemsToPrepare.push(columns[0])
          }
        })
        console.log("Segment IDS to Deactivate:", itemsToPrepare)
        setSegments(itemsToPrepare)
      } catch (error) {
        swal.fire({
          title: 'CSV File Processing Error',
          text: error.message,
          icon: 'error'
        })
      }
    }

    reader.readAsText(input)
  }
  
  const checkSegments = (input) => {
    if (!input) {
      swal.fire({
        title: 'Warning',
        text: 'CSV File not attached',
        icon: 'warning'
      })
      return
    }
    try {
      processCSVFile(input)
    } catch (error) {
      swal.fire({
        title: 'Error',
        text: error,
        icon: 'error'
      })
    }
  }

  const updateSegments = async () => {
    console.log('Updating Segments...')
    const res = await deactivateSegments(segments)
    if (res.reason) {
      swal.fire({
        title: 'An error has occurred with your request.',
        text: res.reason,
        icon: 'error'
      })
    } else {
      swal.fire({
        title: 'Completed!',
        icon: 'success'
      })
    }
  }

  useEffect(() => {
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='updateAudienceSegmentData-panel-content'
        id='updateAudienceSegmentData-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Deactivate Audience Segments</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={commonClasses.p_0}>
        <Card elevation={0} style={{ width: '100%' }} raised={false}>
          <CardContent>
            <MuiAlert severity='info'>
              File should be CSV format with a header row.<br />
              Segment IDs should be in the first column.<br />
              Additional columns are ignored.
            </MuiAlert>
            <br />
            <b>Selected File:</b> { file ? file.name : 'NO FILE SELECTED'}<br />
            <b># of Segments:</b> { segments ? segments.length : 0}
          </CardContent>
          <CardActions className={commonClasses.actionArea}>
            <Button
              className={addClasses(commonClasses.btnInfo, commonClasses.ml_1)}
              variant='contained'
              onClick={() => fileInputElement.click()}
              startIcon={<SearchIcon />}
            >
              <input
                style={{ display: 'none' }}
                type='file'
                accept='.csv'
                required
                ref={element => { fileInputElement = element }}
                onChange={(event) => {
                  setFile(event.target.files[0])
                  checkSegments(event.target.files[0])
                }}
              />
              Choose File
            </Button>
            <Button
              disabled={!file || !segments}
              className={addClasses(commonClasses.btnSuccess, commonClasses.ml_1)}
              variant='contained'
              onClick={() => { 
                updateSegments()
              }}
              startIcon={<PublishIcon />}
            >
              Upload File
            </Button>
          </CardActions>
        </Card>
      </AccordionDetails>
    </Accordion>
  )
}

export const GoogleAdManagerAPI = () => {
  const dispatch = useDispatch()
  const [expandAll, setExpandAll] = useState(true)
  const [allLineItemsTask, setAllLineItemsTask] = useState('')
  const [enhancedLineItemsTask, setEnhancedLineItemsTask] = useState('')
  let { customTargets, currentVersion, schedule } = useSelector(state => state.dfp)
  const commonClasses = commonStyle()
  let columnHeaders = []
  let depSchedule = [...schedule]
  if (schedule.length > 0) columnHeaders = depSchedule.shift().split(',')
  console.log('ColumnHeaders: ', columnHeaders)
  console.log('DepSchedule: ', depSchedule)

  useEffect(() => {
    ;(async () => {
      if (currentVersion === '') {
        console.log('Fetching API Version and Schedule...')
        dispatch(fetchAPIVersion())
        dispatch(fetchAPISchedule())
      }
      if (customTargets.length === 0) {
        dispatch(getAllCustomTargets(true))
      }
      let res = await getTask('getAllLineItems')
      if (res.reason) {
        swal.fire({
          title: 'An error has occurred with your request.',
          text: res.reason,
          icon: 'error'
        })
      } else {
        setAllLineItemsTask(res.data)
      }
      res = await getTask('getEnhancedLineItems')
      if (res.reason) {
        swal.fire({
          title: 'An error has occurred with your request.',
          text: res.reason,
          icon: 'error'
        })
      } else {
        setEnhancedLineItemsTask(res.data)
      }
    })()
  }, [])

  return (
    <Container maxWidth='lg'>
      <Card>
        <CardHeader
          title='Google Ad Manager API'
          action={
            <Button
              color='primary'
              variant='text'
              onClick={() => setExpandAll(!expandAll)}
            >
              {expandAll ? 'Collapse All' : 'Expand All'}
            </Button>
          }
        />
        <Divider />
        {allLineItemsTask && customTargets.length > 0
          ? (
            <CardContent className={commonClasses.cardBkClr}>
              <Card>
                <CardContent>
                  <MuiAlert severity='info'>
                    Current API Version: {currentVersion}
                  </MuiAlert>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table size="small" style={{minWidth: 650}}>
                      <TableHead>
                        <TableRow>
                          {columnHeaders.map(header => (<TableCell style={{fontWeight: 'bolder'}}>{header.toUpperCase().replace('DATE', ' DATE')}</TableCell>))}                      
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {depSchedule.map(item => {
                          let arr = item.split(',')
                          console.log('schedule item: ', item)
                          return (
                            <TableRow>
                              {arr.map(value => (<TableCell>{value}</TableCell>))}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card><br />
              <Card>
                <CardContent>
                  <GetAllLineItems
                    expandAll={expandAll}
                    task={allLineItemsTask}
                    setTask={setAllLineItemsTask}
                  />
                </CardContent>
              </Card><br />
              <Card>
                <CardContent>
                  <GetAllLineItemsWithAudienceSegmentData
                    expandAll={expandAll}
                    task={enhancedLineItemsTask}
                    setTask={setEnhancedLineItemsTask}
                  />
                </CardContent>
              </Card><br />
              <Card>
                <CardContent>
                  <GetLineItemsByCustomTarget
                    expandAll={expandAll}
                    customTargets={customTargets}
                  />
                </CardContent>
              </Card><br />
              <Card>
                <CardContent>
                  <GetAllActiveAudienceSegment expandAll={expandAll} />
                </CardContent>
              </Card><br />
              <Card>
                <CardContent>
                  <UpdateAudienceSegmentData expandAll={expandAll} />
                </CardContent>
              </Card><br />
            </CardContent>
            )
          : (
            <LoadingProgress />
            )}
      </Card>
    </Container>
  )
}
