import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdLoaderFolders, uploadAsset } from '../../modules/cdnService'

import { CardActions, makeStyles, Container, Typography, Breadcrumbs, Card, CardHeader, Divider, CardContent, CardMedia, Button, Box, InputLabel, Link, FormHelperText, MenuItem, FormControl, FormGroup, Select, TextField, FormControlLabel, Checkbox, LinearProgress } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import PublishIcon from '@material-ui/icons/Publish'
import { NavLink } from 'react-router-dom'
import { addClasses, commonStyle } from '../../styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object as yupobject, mixed as yupmixed } from 'yup'

const useStyles = makeStyles((theme) => ({
  justifyContentEnd: {
    justifyContent: 'flex-end'
  },
  dropdown: {
    minWidth: '200px'
  },
  media: {
    minHeight: 'auto',
    width: '100%',
    backgroundColor: theme.palette.grey[300]
  },
  figure: {
    maxWidth: '500px',
    margin: '0 auto'
  }
}))

const secureLink = url => {
  return url.includes('https') ? url : url.replace('http', 'https')
}

const validationSchema = yupobject().shape({
  selFolder: yupobject().required('Folder is required').nullable(),
  openFileDialog: yupmixed()
    .test('required', 'File is required', (value) => {
      return value && value.length
    })
    // .test('fileSize', 'The file is too large', (value, context) => {
    //   return value && value[0] && value[0].size <= 200000
    // })
    .test('type', 'File format is not supported', function (value) {
      if (value) console.log('Type of Value: ', typeof value)
      if (value && value[0] && value[0].type) console.log('File Type of First Value: ', value[0].type)
      return value &&
        value[0] &&
        (
          value[0].type.includes('image') ||
          value[0].type === 'application/x-zip-compressed' ||
          value[0].type === 'application/x-rar-compressed' ||
          value[0].type === 'application/zip' ||
          value[0].tpye === 'application/rar' ||
          value[0].type === 'video/mp4'
        )
    })
})

export const AdLoader = () => {
  const dispatch = useDispatch()
  const { folders, loading, upload } = useSelector((state) => state.cdnService)
  const classes = useStyles()
  const commonClasses = commonStyle()
  const [folder, setFolder] = useState('')
  const [description, setDescription] = useState('')
  const [GAMMacro, setGAMMacro] = useState(false)
  const [adImage, setAdImage] = useState(null)
  const [file, setFile] = useState(null)
  const [assetUrls, setAssetUrls] = useState([])
  let fileInputRef

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const objectToFormData = (object) => {
    const formData = new FormData()
    Object.keys(object).forEach((key) => {
      console.log('Appending Value To FormData: ', { key, value: object[key] })
      formData.append(key, object[key])
    })
    return formData
  }

  const uploadFile = async () => {
    const fileInfo = {
      cdnFolder: folder.folder,
      description,
      addGAMTrackingMacro: GAMMacro,
      file
    }
    if (folder && adImage) {
      const data = objectToFormData(fileInfo)
      console.log('Uploading File: ', { fileInfo, data })
      dispatch(uploadAsset(data))
    }
  }

  useEffect(() => {
    if (upload && upload.uploadedAssets) {
      const _assetUrls = upload.uploadedAssets.map((asset) => asset.url)
      if (_assetUrls.length > 0) { setAssetUrls(_assetUrls) }
    }
  }, [upload])

  useEffect(() => {
    if (folders.length === 0) { dispatch(fetchAdLoaderFolders()) }
  }, [])

  const setGAMTrackingMacro = (value) => {
    console.log('Setting GAM Tracking Macro: ', value)
    setGAMMacro(value)
  }

  return (
    <>
      <Breadcrumbs className={addClasses(commonClasses.pl_3, commonClasses.pb_1)} aria-label='breadcrumb'>
        <NavLink
          to='/adLoader'
          activeStyle={{
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
        >
          AdLoader
        </NavLink>
        <NavLink to='/adLoader/history'>
          History
        </NavLink>
      </Breadcrumbs>
      <Container maxWidth='lg'>
        <Card elevation={2}>
          <CardHeader title='AdLoader' />
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card>
              <CardContent>
                <FormGroup row>
                  <FormControl required className={classes.dropdown}>
                    <InputLabel id='folder-label'>Folder</InputLabel>
                    <Select
                      inputProps={{ 'data-testid': 'folder' }}
                      {...register('selFolder')}
                      value={folder}
                      onChange={(e) => {
                        setValue('selFolder', e.target.value, { shouldValidate: true })
                        setFolder(e.target.value)
                      }}
                      error={!!errors.selFolder}
                    >
                      {folders
                        ? (
                            folders.map((folder, i) => (
                              <MenuItem key={i} value={folder}>
                                {folder.folder}
                              </MenuItem>
                            ))
                          )
                        : (
                          <MenuItem value={null}>No Folders Available</MenuItem>
                          )}
                    </Select>
                    {!!errors.selFolder && (
                      <FormHelperText error={!!errors.selFolder}>
                        {errors.selFolder.message}
                      </FormHelperText>
                    )}
                    {/* <FormHelperText>Required</FormHelperText> */}
                  </FormControl>
                  <FormControlLabel
                    className={commonClasses.ml_2}
                    control={
                      <Checkbox
                        data-testid='GAMCheckBox'
                        checked={GAMMacro}
                        onChange={(e) => setGAMTrackingMacro(e.target.checked)}
                        value='GAMMacro'
                        color='primary'
                      />
                    }
                    label='Add GAM Tracking Macro'
                  />
                </FormGroup>
                <FormGroup row>
                  <TextField
                    margin='normal'
                    fullWidth
                    inputProps={{ 'data-testid': 'description' }}
                    id='standard-basic'
                    label='Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControl required>
                    <Button
                      variant='contained'
                      className={commonClasses.btnInfo}
                      onClick={() => fileInputRef.click()}
                      startIcon={<SearchIcon />}
                    >
                      <input
                        id='openFileDialog'
                        type='file'
                        style={{ display: 'none' }}
                        data-testid='adImageSelect'
                        accept='image/*,.zip,.rar,.mp4'
                        {...register('openFileDialog')}
                        ref={ref => { fileInputRef = ref }}
                        onChange={(e) => {
                          setFile(e.target.files[0])
                          setAssetUrls([])
                          setAdImage(URL.createObjectURL(e.target.files[0]))
                          setValue('openFileDialog', e.target.files, {
                            shouldValidate: true
                          })
                        }}
                      />
                      Choose File
                    </Button>
                    {!!errors.openFileDialog && (
                      <FormHelperText error={!!errors.openFileDialog}>
                        {errors.openFileDialog.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </FormGroup>
              </CardContent>
            </Card>
            
            {file && assetUrls && assetUrls.length > 0
              ? (
                  assetUrls.map((assetUrl, i) => (
                    <Card><CardContent><FormGroup key={i} row>
                      <Box className={addClasses(commonClasses.mt_1, commonClasses.mb_1)}>
                        <Link
                          className={commonClasses.linkColor}
                          href={secureLink(assetUrl)}
                          target='_blank' rel='noopener noreferrer'
                        >{secureLink(assetUrl)}
                        </Link>
                      </Box>
                    </FormGroup>
                    </CardContent></Card>
                  ))
                )
              : null}
              <br />
              <Card style={adImage && file ? {} : {display: 'none'}}
              >
                <CardHeader className={commonClasses.pl_3} titleTypographyProps={{ variant: 'h6' }} title='Preview'/>
                <CardContent>
                  <Box className={classes.figure}>
                    {file ? (<div><Typography variant="h6" style={{display: 'inline-block', marginRight: '10px', fontWeight: 'bold'}}>Filename:</Typography><Typography style={{display: 'inline-block'}}>{file.name}</Typography></div>) : null}
                    {file ? (<div><Typography variant="h6" style={{display: 'inline-block', marginRight: '10px', fontWeight: 'bold'}}>MIME Type:</Typography><Typography style={{display: 'inline-block'}}>{file.type}</Typography></div>) : null}
                    {adImage && file && (file.type !== 'application/x-rar-compressed' &&
                    file.type !== 'application/x-zip-compressed' &&
                    file.type !== 'application/zip' &&
                    file.type !== 'video/mp4')
                      ? (
                        <CardMedia
                          component='img'
                          className={classes.media}
                          image={adImage}
                          title='Uploaded Image Thumbnail'
                          alt='Uploaded Image Thumbnail'
                          aria-label='Uploaded Image Thumbnail'
                        />
                        )
                      : <Typography variant="h4">No Preview Available</Typography>}
                  </Box>
                </CardContent>
              </Card>
          </CardContent>
          <Divider />
          <CardActions className={classes.justifyContentEnd}>

            <Button
              variant='contained'
              className={addClasses(commonClasses.btnSuccess, commonClasses.ml_1)}
              onClick={handleSubmit(uploadFile)}
              disabled={loading}
              data-testid='uploadFile'
              startIcon={<PublishIcon />}
            >
              Upload File
            </Button>
          </CardActions>
          {loading && <LinearProgress />}
        </Card>
      </Container>
    </>
  )
}
