import React, { useState, useEffect } from 'react'

import { Grid, Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const template = {
  ORG: {
    DOM: '',
    INTL: ''
  },
  FLOOR_PRICE: {
    DOM: 0,
    INTL: 0
  },
  PLACEMENT_ID: {
    DOM: {
      DESKTOP: {
        'atf': '',
        'btf': ''
      },
      MOBILE: {
        'atf': '',
        'btf': ''
      }
    },
    INTL: {
      DESKTOP: {
        'atf': '',
        'btf': ''
      },
      MOBILE: {
        'atf': '',
        'btf': ''
      }
    }
  }
}

export const OpenWeb = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const { OPENWEB = template } = VENDOR
  PREBID.VENDOR.OPENWEB = OPENWEB
  const { ORG, FLOOR_PRICE, PLACEMENT_ID  } = Object.assign(template, OPENWEB)
  const { STRING, NUMBER } = validation
  const { LENGTH } = STRING
  const { FLOOR } = NUMBER
  const [expanded, setExpanded] = useState(expandAllVendors)
  

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displayPlacementIdTable = () => {
    return (
      <Table size='small' aria-label='OpenWeb-placement-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}>
              <Typography variant='h6' style={{ fontWeight: 'bolder' }}>Placement IDs</Typography>
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>ABOVE THE FOLD</TableCell>
            <TableCell style={{ textAlign: 'center' }}>BELOW THE FOLD</TableCell>
            <TableCell style={{ textAlign: 'center' }}>ABOVE THE FOLD</TableCell>
            <TableCell style={{ textAlign: 'center' }}>BELOW THE FOLD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ textAlign: 'right' }}><Typography variant='h6'>Desktop</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PLACEMENT_ID.DOM.DESKTOP.atf}
                error={errors.PREBID_OPENWEB_PIDDDA}
                helperText={errors.PREBID_OPENWEB_PIDDDA && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.OPENWEB.PLACEMENT_ID.DOM.DESKTOP.atf = value
                  setErrors({ ...errors, PREBID_OPENWEB_PIDDDA: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PLACEMENT_ID.DOM.DESKTOP.btf}
                error={errors.PREBID_OPENWEB_PIDDDB}
                helperText={errors.PREBID_OPENWEB_PIDDDB && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.OPENWEB.PLACEMENT_ID.DOM.DESKTOP.btf = value
                  setErrors({ ...errors, PREBID_OPENWEB_PIDDDB: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PLACEMENT_ID.INTL.DESKTOP.atf}
                error={errors.PREBID_OPENWEB_PIDIDA}
                helperText={errors.PREBID_OPENWEB_PIDIDA && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.OPENWEB.PLACEMENT_ID.INTL.DESKTOP.atf = value
                  setErrors({ ...errors, PREBID_OPENWEB_PIDIDA: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PLACEMENT_ID.INTL.DESKTOP.btf}
                error={errors.PREBID_OPENWEB_PIDIDB}
                helperText={errors.PREBID_OPENWEB_PIDIDB && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.OPENWEB.PLACEMENT_ID.INTL.DESKTOP.btf = value
                  setErrors({ ...errors, PREBID_OPENWEB_PIDIDB: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ textAlign: 'right' }}><Typography variant='h6'>Mobile</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PLACEMENT_ID.DOM.MOBILE.atf}
                error={errors.PREBID_OPENWEB_PIDDMA}
                helperText={errors.PREBID_OPENWEB_PIDDMA && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.OPENWEB.PLACEMENT_ID.DOM.MOBILE.atf = value
                  setErrors({ ...errors, PREBID_OPENWEB_PIDDMA: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PLACEMENT_ID.DOM.MOBILE.btf}
                error={errors.PREBID_OPENWEB_PIDDMB}
                helperText={errors.PREBID_OPENWEB_PIDDMB && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.OPENWEB.PLACEMENT_ID.DOM.MOBILE.btf = value
                  setErrors({ ...errors, PREBID_OPENWEB_PIDDMB: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PLACEMENT_ID.INTL.MOBILE.atf}
                error={errors.PREBID_OPENWEB_PIDIMA}
                helperText={errors.PREBID_OPENWEB_PIDIMA && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.OPENWEB.PLACEMENT_ID.INTL.MOBILE.atf = value
                  setErrors({ ...errors, PREBID_OPENWEB_PIDIMA: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PLACEMENT_ID.INTL.MOBILE.btf}
                error={errors.PREBID_OPENWEB_PIDIMB}
                helperText={errors.PREBID_OPENWEB_PIDIMB && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.OPENWEB.PLACEMENT_ID.INTL.MOBILE.btf = value
                  setErrors({ ...errors, PREBID_OPENWEB_PIDIMB: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='OpenWeb-panel-content'
        id='OpenWeb-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>OpenWeb</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table size='small' aria-label='OpenWeb-organization-table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Organization</Typography></TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={ORG.DOM || ''}
                      error={errors.PREBID_OPENWEB_ORGD}
                      helperText={errors.PREBID_OPENWEB_ORGD && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        ORG.DOM = value
                        PREBID.VENDOR.OPENWEB = { ...template, ...PREBID.VENDOR.OPENWEB}
                        PREBID.VENDOR.OPENWEB.ORG = { DOM: '', INTL: '', ...PREBID.VENDOR.OPENWEB?.ORG}
                        PREBID.VENDOR.OPENWEB.ORG.DOM = value
                        setErrors({ ...errors, PREBID_OPENWEB_ORGD: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={ORG.INTL || ''}
                      error={errors.PREBID_OPENWEB_ORGI}
                      helperText={errors.PREBID_OPENWEB_ORGI && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        ORG.INTL = value
                        PREBID.VENDOR.OPENWEB = { ...template, ...PREBID.VENDOR.OPENWEB}
                        PREBID.VENDOR.OPENWEB.ORG = { DOM: '', INTL: '', ...PREBID.VENDOR.OPENWEB?.ORG}
                        PREBID.VENDOR.OPENWEB.ORG.INTL = value
                        setErrors({ ...errors, PREBID_OPENWEB_ORGI: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Table size='small' aria-label='OpenWeb-floor-price-table'>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Floor Price</Typography></TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={FLOOR_PRICE.DOM}
                      error={errors.PREBID_OPENWEB_FPD}
                      helperText={errors.PREBID_OPENWEB_FPD && LENGTH.message}
                      inputProps={{
                        type: 'number',
                        min: FLOOR.min,
                        max: FLOOR.max,
                        step: FLOOR.step
                      }}
                      onChange={(event) => {
                        const value = event.target.value
                        FLOOR_PRICE.DOM = value
                        PREBID.VENDOR.OPENWEB = { ...template, ...PREBID.VENDOR.OPENWEB}
                        // PREBID.VENDOR.OPENWEB?.FLOOR_PRICE = { DOM: '0.01', INTL: '0.01', ...PREBID.OPENWEB?.FLOOR_PRICE }
                        // console.log('Set PREBID.VENDOR.OPENWEB.FLOOR_PRICE:', PREBID.VENDOR.OPENWEB?.FLOOR_PRICE)
                        // PREBID.VENDOR.OPENWEB?.FLOOR_PRICE.DOM = value
                        // console.log('Set PREBID.VENDOR.OPENWEB.FLOOR_PRICE.DOM:', PREBID.VENDOR.OPENWEB?.FLOOR_PRICE.DOM)
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={FLOOR_PRICE.INTL}
                      error={errors.PREBID_OPENWEB_FPI}
                      helperText={errors.PREBID_OPENWEB_FPI && LENGTH.message}
                      inputProps={{
                        type: 'number',
                        min: FLOOR.min,
                        max: FLOOR.max,
                        step: FLOOR.step
                      }}
                      onChange={(event) => {
                        const value = event.target.value
                        FLOOR_PRICE.INTL = value
                        PREBID.VENDOR.OPENWEB = { FLOOR_PRICE: { DOM: '0.00', INTL: '0,00'}, ...template, ...PREBID.VENDOR.OPENWEB}
                        // PREBID.VENDOR.OPENWEB.FLOOR_PRICE = { DOM: 0.01, INTL: 0.01, ...PREBID.OPENWEB?.FLOOR_PRICE }
                        // console.log('Set PREBID.VENDOR.OPENWEB.FLOOR_PRICE:', PREBID.VENDOR.OPENWEB?.FLOOR_PRICE)
                        // PREBID.VENDOR.OPENWEB.FLOOR_PRICE.INTL = value
                        // console.log('Set PREBID.VENDOR.OPENWEB.FLOOR_PRICE.INTL:', PREBID.VENDOR.OPENWEB?.FLOOR_PRICE.INTL)
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </Grid>
          <Grid item xs={12}>
            {displayPlacementIdTable()}
            {/* <Table size='small' aria-label='OpenWeb-placement-id-table'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '180px' }}><Typography variant='h6'>Placement ID</Typography></TableCell>
                  <TableCell>Domestic</TableCell>
                  <TableCell>International</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <TextField
                      margin='none'
                      value={PLACEMENT_ID.DOM}
                      error={errors.PREBID_OPENWEB_PIDD}
                      helperText={errors.PREBID_OPENWEB_PIDD && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        PLACEMENT_ID.DOM = value
                        PREBID.VENDOR.OPENWEB = { ...template, ...PREBID.VENDOR.OPENWEB}
                        PREBID.VENDOR.OPENWEB.PLACEMENT_ID = { DOM: '', INTL: '', ...PREBID.VENDOR.OPENWEB?.PLACEMENT_ID}
                        PREBID.VENDOR.OPENWEB.PLACEMENT_ID.DOM = value
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      margin='none'
                      value={PLACEMENT_ID.INTL}
                      error={errors.PREBID_OPENWEB_PIDI}
                      helperText={errors.PREBID_OPENWEB_PIDI && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        PLACEMENT_ID.INTL = value
                        PREBID.VENDOR.OPENWEB = { ...template, ...PREBID.VENDOR.OPENWEB}
                        PREBID.VENDOR.OPENWEB.PLACEMENT_ID = { DOM: '', INTL: '', ...PREBID.VENDOR.OPENWEB?.PLACEMENT_ID}
                        PREBID.VENDOR.OPENWEB.PLACEMENT_ID.INTL = value
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table> */}
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
