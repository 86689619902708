export const filterByValue = (array, string) => {
  return array.filter(arrObject => {
    
    const result = Object.keys(arrObject).some(element => {
        // Some() allows you to test if an array has at least one element that meets a condition.
      const propValue = arrObject[element]
      if (propValue) {
        const lowercased = propValue.toString().toLowerCase()
        const includes = lowercased.includes(string.toLowerCase())
        return includes
      }
      return false
    })
    return result
  })
}

export const siteNameFormatter = name => {
  // replace spaces with underscore
  // keep alphanumeric, dash, period, underscore
  // ex: DG Site_#2.0-a -> dg_site_2.0-a
  return name ? name.toLowerCase().replace(/[ ]/g, '_').replace(/[^a-z0-9-._-]/g, '') : ''
}

export const registryNameFormatter = name => {
  return name ? name.toLowerCase().replace(/[^a-z0-9._-]/g, '') : ''
}

export const registryNameFormatter2 = name => {
  return name ? name.replace(/[^a-zA-Z0-9._-]/g, '') : ''
}

export const isValidRegistryName = name => {
  const format = /^[a-zA-Z0-9._-]*$/g
  return format.test(name)
}

export const isObjectEquivalent = (a, b) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i]

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true
}

export const cacheBuster = () => {
  let result = ''
  for (let i = 0; i < 6; i++) {
    result += Math.floor(Math.random() * 16).toString(16)
  }
  return result
}

export const hasRole = (requiredRoles, user) => !user ? false : !!requiredRoles.find(requiredRole => user.role2.includes(requiredRole))

export const clone = (object) => JSON.parse(JSON.stringify(object))

export const distinct = collection => [...new Set(clone(collection).map(item => JSON.stringify(item)))].map(item => JSON.parse(item))

export const convertObjectToArray = (object) => {
  if (!object) { return [] }
  return Object.entries(object).map(entry => {
    const key = entry[0]
    const value = entry[1]
    return { key, value }
  })
}

export const convertArrayToObject = (array) => {
  const result = {}
  array.forEach(object => {
    if (object.key) {
      result[object.key] = object.value
    }
  })
  return result
}
