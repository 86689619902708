import React from 'react'

import { Card, CardContent, Table, TableBody, TableRow, TableCell, TextField, Typography } from '@material-ui/core'

export const UserConsent = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { USER_CONSENT } = options
  const { COOKIE_DOMAIN, ONETRUST_GUID, ONETRUST_SRC } = USER_CONSENT
  const { STRING } = validation
  const { LENGTH } = STRING

  const displayPropertyTable = () => {
    return (
      <Table size='small' aria-label='user-consent-property-table'>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '200px' }}><Typography variant='h5'>Cookie Domain</Typography></TableCell>
            <TableCell>
              <TextField
                fullWidth
                margin='none'
                placeholder='Cookie Domain'
                value={COOKIE_DOMAIN}
                error={errors.USER_CONSENT_CD}
                helperText={errors.USER_CONSENT_CD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  USER_CONSENT.COOKIE_DOMAIN = value
                  setErrors({ ...errors, USER_CONSENT_CD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, USER_CONSENT })
                  setAllOptions({ ...allOptions, USER_CONSENT })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '200px' }}><Typography variant='h5'>One Trust GUID</Typography></TableCell>
            <TableCell>
              <TextField
                fullWidth
                margin='none'
                placeholder='One Trust GUID'
                value={ONETRUST_GUID}
                error={errors.USER_CONSENT_OTG}
                helperText={errors.USER_CONSENT_OTG && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  USER_CONSENT.ONETRUST_GUID = value
                  setErrors({ ...errors, USER_CONSENT_OTG: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, USER_CONSENT })
                  setAllOptions({ ...allOptions, USER_CONSENT })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '200px' }}><Typography variant='h5'>One Trust Source</Typography></TableCell>
            <TableCell>
              <TextField
                fullWidth
                margin='none'
                placeholder='One Trust Source'
                value={ONETRUST_SRC}
                error={errors.USER_CONSENT_OTS}
                helperText={errors.USER_CONSENT_OTS && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  USER_CONSENT.ONETRUST_SRC = value
                  setErrors({ ...errors, USER_CONSENT_OTS: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, USER_CONSENT })
                  setAllOptions({ ...allOptions, USER_CONSENT })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <Card>
      <CardContent>
        {displayPropertyTable()}
      </CardContent>
    </Card>
  )
}
