import React, { useState, useEffect } from 'react'

import { Grid, Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const template = {
  PAGE_ID: {
    DOM: 0,
    INTL: 0
  },
  PLACEMENT_ID: {
    DOM: 0,
    INTL: 0
  }
}

export const Teads = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  // console.log('Prebid: ', PREBID)
  const { VENDOR } = PREBID
  VENDOR.TEADS = { ...template, ...VENDOR.TEADS }
  const { TEADS } = VENDOR
  const { PAGE_ID, PLACEMENT_ID } = TEADS
  const { STRING } = validation
  const { LENGTH } = STRING
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displayPageIdTable = () => {
    return (
      <Table size='small' aria-label='teads-page-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Page IDs</Typography></TableCell>
            <TableCell>Domestic</TableCell>
            <TableCell>International</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell>
              <TextField
                margin='none'
                value={PAGE_ID.DOM}
                error={errors.PREBID_TEADS_PIDD}
                helperText={errors.PREBID_TEADS_PIDD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TEADS.PAGE_ID.DOM = value
                  setErrors({ ...errors, PREBID_TEADS_PIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                margin='none'
                value={PAGE_ID.INTL}
                error={errors.PREBID_TEADS_PIDI}
                helperText={errors.PREBID_TEADS_PIDI && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TEADS.PAGE_ID.INTL = value
                  setErrors({ ...errors, PREBID_TEADS_PIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayPlacementIdTable = () => {
    return (
      <Table size='small' aria-label='teads-placement-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Placement IDs</Typography></TableCell>
            <TableCell>Domestic</TableCell>
            <TableCell>International</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell>
              <TextField
                margin='none'
                value={PLACEMENT_ID.DOM}
                error={errors.PREBID_TEADS_PLIDD}
                helperText={errors.PREBID_TEADS_PLIDD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TEADS.PLACEMENT_ID.DOM = value
                  setErrors({ ...errors, PREBID_TEADS_PLIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                margin='none'
                value={PLACEMENT_ID.INTL}
                error={errors.PREBID_TEADS_PLIDI}
                helperText={errors.PREBID_TEADS_PLIDI && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TEADS.PLACEMENT_ID.INTL = value
                  setErrors({ ...errors, PREBID_TEADS_PLIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='teads-panel-content'
        id='teads-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Teads</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displayPageIdTable()}</Grid>
          <Grid item xs={12}>{displayPlacementIdTable()}</Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
