import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Divider, Button, makeStyles, TextField, IconButton, Card, CardHeader, CardContent, CardActions, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { ToggleSwitch } from '../../ui'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { addClasses, commonStyle } from '../../../styles'
import { clone } from '../../../utilities/helperFunctions.js'
import { generateId, updateSiteResponsiveness } from '../../../modules/sitesSlice'
import DeleteIcon from '@material-ui/icons/Delete'
import { useForm } from 'react-hook-form'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swal = withReactContent(Swal)

const useStyle = makeStyles(theme => ({
  footerActionArea: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fontWeightBold: { fontWeight: 'bold' },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
}))

const ResponsiveViewports = ({ data, canEditSite, setIsChanged, isChanged }) => {
  const classes = useStyle()
  const commonClasses = commonStyle()
  const [site, setSite] = useState(clone(data))
  const { updatingSiteResponsiveness } = useSelector(state => state.sitesSlice)
  const [newViewport, setNewViewport] = useState({name: '', minWidth: 0, minHeight: 0})
  const dispatch = useDispatch()

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({ submitFocusError: false })

  const handleNewSiteResponsivenessChange = (event, viewport) => {
    let name = event.target.name.replace('New','')
    let value = event.target.value
    console.log('[ResponsiveViewports] ---------- handleNewSiteResponsivenessChange::data: ', { name, value, viewport })
    viewport[name] = value
    setValue(name, value, { shouldValidate: true})
    setNewViewport(viewport)
  }

  const handleSiteResponsivenessChange = (event, rowData) => {
    const selectedViewportIndex = site?.viewports?.findIndex(
      viewport => viewport.siteViewportId === rowData.siteViewportId
    )
    const _viewPorts = site?.viewports?.slice()
    if (event.target.name === 'name' + rowData.siteViewportId) {
      _viewPorts[selectedViewportIndex].name = event.target.value
      setValue('name' + rowData.siteViewportId, event.target.value, { shouldValidate: true })
    } else if (event.target.name === 'minWidth' + rowData.siteViewportId) {
      _viewPorts[selectedViewportIndex].minWidth = event.target.value
      setValue('minWidth' + rowData.siteViewportId, event.target.value, { shouldValidate: true })
    } else if (event.target.name === 'minHeight' + rowData.siteViewportId) {
      _viewPorts[selectedViewportIndex].minHeight = event.target.value
      setValue('minHeight' + rowData.siteViewportId, event.target.value, { shouldValidate: true })
    }
    setSite({
      ...site,
      viewports: _viewPorts
    })

  }

  const deleteViewport = (item) => {
    const filterViewportList = site?.viewports?.filter(viewport => viewport.siteViewportId !== item.siteViewportId)
    setSite({
      ...site,
      viewports: filterViewportList
    })
  }

  const saveResponsiveness = () => {
    dispatch(updateSiteResponsiveness(site))
  }

  useEffect(() => {
    const _site = clone(data)
    _site?.viewports?.sort((a, b) => {
      if (a.minWidth > b.minWidth) { return -1 }
      if (b.minWidth > a.minWidth) { return 1 }
      if (a.minWidth === b.minWidth) {
        if (a.minHeight < b.minHeight) { return -1 }
        if (a.minHeight > b.minHeight) { return 1 }
      }
      return 0
    })
    setSite(_site)
  }, [data])

  useEffect(() => {
    if (site._id) {
      const oldData = [ 
        ...data.viewports.map(item => { 
          return {...item} 
        })
        .sort((a, b) => {
          return a.minWidth > b.minWidth ? -1 : (a.minWidth < b.minWidth ? 1 : 0) 
        })
      ]
      const newData = [ ...site.viewports.map(item => { return {
        ...item,
        minWidth: parseInt(item.minWidth, 10),
        minHeight: parseInt(item.minHeight, 10)
      } }) ]
      console.log('[ResponsiveViewports] ---------- useEffect[site]::comparison ', { oldData: oldData, newData: newData })
      if (!isChanged) {
        setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
      }
    }
  }, [site])

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell
            className={classes.fontWeightBold}
            style={{ width: '30%' }}
          >
            Name
          </TableCell>
          <TableCell
            className={classes.fontWeightBold}
            style={{ width: '30%' }}
          >
            Minimum Width
          </TableCell>
          <TableCell
            className={classes.fontWeightBold}
            style={{ width: '30%' }}
          >
            Minimum Height
          </TableCell>
          <TableCell
            className={classes.fontWeightBold}
            style={{ width: '10%' }}
          >
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {site?.viewports?.map(rowData => {
          return (
            <TableRow
              className={classes.tableRow}
              hover
              key={rowData.siteViewportId}
              style={{
                verticalAlign: 'top'
              }}
            >
              <TableCell>
                <TextField
                  {...register('name' + rowData.siteViewportId, {
                    setValueAs: v => String(v),
                    required: 'Name is required'
                  })}
                  margin='dense'
                  value={rowData.name ? rowData.name : ''}
                  style={{ width: '100%' }}
                  variant='outlined'
                  onChange={e => handleSiteResponsivenessChange(e, rowData)}
                  error={!!errors['name' + rowData.siteViewportId]}
                  helperText={errors['name' + rowData.siteViewportId] ? errors['name' + rowData.siteViewportId].message : ''}
                />
              </TableCell>
              <TableCell>
                <TextField
                  {...register('minWidth' + rowData.siteViewportId, {
                    setValueAs: v => parseInt(v),
                    required: 'Minimum Width is required',
                    min: {
                      value: 0,
                      message: 'Width should be atleast 0'
                    }
                  })}
                  margin='dense'
                  value={rowData.minWidth}
                  style={{ width: '100%' }}
                  variant='outlined'
                  type='number'
                  onChange={e => handleSiteResponsivenessChange(e, rowData)}
                  error={!!errors['minWidth' + rowData.siteViewportId]}
                  helperText={errors['minWidth' + rowData.siteViewportId] ? errors['minWidth' + rowData.siteViewportId].message : ''}
                />
              </TableCell>
              <TableCell>
                <TextField
                  {...register('minHeight' + rowData.siteViewportId, {
                    setValueAs: v => parseInt(v),
                    required: 'Minimum Height is Required',
                    min: {
                      value: 0,
                      message: 'Height should be atleast 0'
                    }
                  })}
                  margin='dense'
                  value={rowData.minHeight}
                  style={{ width: '100%' }}
                  variant='outlined'
                  type='number'
                  onChange={e => handleSiteResponsivenessChange(e, rowData)}
                  error={!!errors['minHeight' + rowData.siteViewportId]}
                  helperText={errors['minHeight' + rowData.siteViewportId] ? errors['minHeight' + rowData.siteViewportId].message : ''}
                />
              </TableCell>
              <TableCell>
                <Tooltip title='Delete'>
                  <IconButton
                    aria-label='Delete'
                    onClick={() => {
                      if (canEditSite) {
                        deleteViewport(rowData)
                      } else {
                        swal.fire({
                          title: 'UNAUTHORIZED',
                          text: 'You do not have permissions to do this.',
                          icon: 'error',
                          dangerMode: false
                        })
                      }
                    }}
                  ><DeleteIcon style={{ color: '#D22' }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell>
            <TextField
              {...register('nameNew', {
                setValueAs: v => String(v),
                required: 'Name is required'
              })}
              margin='dense'
              value={newViewport.name}
              style={{ width: '100%' }}
              variant='outlined'
              onChange={e => handleNewSiteResponsivenessChange(e, newViewport)}
              error={!!errors['nameNew']}
              helperText={errors['nameNew'] ? errors['nameNew'].message : ''}
            />
          </TableCell>
          <TableCell>
            <TextField
              {...register('minWidthNew', {
                setValueAs: v => parseInt(v),
                required: 'Minimum Width is required',
                min: {
                  value: 0,
                  message: 'Width should be atleast 0'
                }
              })}
              margin='dense'
              value={newViewport.minWidth}
              style={{ width: '100%' }}
              variant='outlined'
              type='number'
              onChange={e => handleNewSiteResponsivenessChange(e, newViewport)}
              error={!!errors['minWidthNew']}
              helperText={errors['minWidthNew'] ? errors['minWidthNew'].message : ''}
            />
          </TableCell>
          <TableCell>
            <TextField
              {...register('minHeightNew', {
                setValueAs: v => parseInt(v),
                required: 'Minimum Height is Required',
                min: {
                  value: 0,
                  message: 'Height should be atleast 0'
                }
              })}
              margin='dense'
              value={newViewport.minHeight}
              style={{ width: '100%' }}
              variant='outlined'
              type='number'
              onChange={e => handleNewSiteResponsivenessChange(e, newViewport)}
              error={!!errors['minHeightNew']}
              helperText={errors['minHeightNew'] ? errors['minHeightNew'].message : ''}
            />
          </TableCell>
          <TableCell>
            <Tooltip title='Add'>
              <IconButton
                aria-label='Add'
                onClick={() => {
                  if (canEditSite) {
                    addViewport(newViewport)
                  } else {
                    swal.fire({
                      title: 'UNAUTHORIZED',
                      text: 'You do not have permissions to do this.',
                      icon: 'error',
                      dangerMode: false
                    })
                  }
                }}
                
              ><AddBoxIcon color="action" />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ResponsiveViewports