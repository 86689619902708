import React, { useState } from 'react'
import { makeStyles, Container, CardHeader, Divider, TextField, Card, CardContent, CardActions, FormGroup, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { purgeCdn } from '../../modules/cdnService'
import { commonStyle } from '../../styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  button: {
    alignSelf: 'flex-end'
  }
}))

export const CDNPurgeTool = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const commonClasses = commonStyle()
  const [urls, setUrls] = useState([])

  let validUrl
  const stripURI = (url) => {
    const link = document.createElement('a')
    link.href = url
    return link.pathname
  }
  const validateUrls = () => {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    const regex = RegExp(reg)
    const uris = urls.split(',')
    validUrl = uris.map((url) => {
      if (regex.test(url) === false) {
        return false
      }
      const uri = stripURI(url)
      return {
        uri,
        valid: regex.test(url)
      }
    })
  }

  const purgeUrls = async () => {
    if (urls.length > 0) {
      validateUrls()
      const uris = validUrl
        .map((url) => {
          if (url.valid) return url.uri
          return null
        })
        .filter((url) => {
          return url !== null
        })
      if (uris.length > 0) {
        dispatch(purgeCdn(uris))
        setTimeout(() => setUrls([]), 600)
      }
    }
  }
  return (
    <Container maxWidth='lg'>
      <Card>
        <CardHeader data-testid='purgeToolHeading' title='CDNPurgeTool' />
        <Divider />
        <CardContent className={commonClasses.cardBkClr}>
          <Card><CardContent>
          <FormGroup row>
            <TextField
              className={classes.root}
              required
              inputProps={{ 'data-testid': 'purgeInputField' }}
              id='filled-multiline-static'
              label='URLs'
              type='url'
              multiline
              rows='10'
              onChange={(e) => setUrls(e.target.value)}
              value={urls}
              variant='outlined'
            />
          </FormGroup>
          </CardContent></Card>
        </CardContent>
        <CardActions>
          <FormGroup>
            <Button
              className={classes.button + ' ' + commonClasses.btnSuccess}
              variant='contained'
              data-testid='purgeButton'
              onClick={() => purgeUrls()}
              startIcon={<CheckCircleIcon />}
            >
              Purge
            </Button>
          </FormGroup>
        </CardActions>
      </Card>
    </Container>
  )
}
