import React from 'react'

import { Card, CardContent, Table, TableHead, TableBody, TableRow, TableCell, Box, FormControlLabel, Typography, Checkbox } from '@material-ui/core'

import { convertObjectToArray } from '../../../utilities/helperFunctions.js'

export const ServerSideAdInsertion = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  let { SSAI } = options
  let { SITE_VENDORS, VENDORS } = SSAI
  VENDORS = { 
    ...VENDORS, 
    ...{
      "AppNexus":{"COOKIE_NAME":"zwmc","URL":"https://ib.adnxs.com/getuid?https://umto.{{domain}}/user-sync?zwmc=$UID&domain={{domain}}"},
      "Freewheel":{"COOKIE_NAME":"bea4","URL":"https://bea4.v.fwmrm.net/ad/u?mode=echo&cr=https://umto.{{domain}}/user-sync?bea4=#{user.id}&domain={{domain}}"},
      "LiveRamp":{"COOKIE_NAME":"orev","URL":"https://idsync.rlcdn.com/712348.gif?partner_uid=${fwuid}"},
      "PubMatic":{"COOKIE_NAME":"kfyn","URL":"https://ads.pubmatic.com/AdServer/js/userSync.js"},
      "Rubicon":{"COOKIE_NAME":"ifyr","URL":"https://pixel-us-east.rubiconproject.com/exchange/sync.php?p={{brand}}"},
      "Telaria":{"COOKIE_NAME":"goiz","URL":"https://eq97f.publishers.tremorhub.com/pubsync?redir=https://umto.{{domain}}/user-sync?goiz=%5Btvid%5D&domain={{domain}}"},
    }
  }
  return (
    <Card>
      <CardContent>
        <Table size='small' aria-controls='SSAI-vendor-table'>
          <TableHead>
            <TableRow><TableCell style={{ width: '180px' }}><Typography variant='h5'>Vendors</Typography></TableCell></TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {convertObjectToArray(VENDORS).map((vendor, index) => {
                  const checked = !!SITE_VENDORS.find(item => item === vendor.key)
                  return (
                    <Box key={index}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            color='primary'
                            checked={checked}
                            onChange={(event) => {
                              const checked = event.target.checked
                              if (checked) {
                                SSAI.SITE_VENDORS = [...SSAI.SITE_VENDORS, vendor.key]
                              } else {
                                SSAI.SITE_VENDORS = SSAI.SITE_VENDORS.filter(item => item !== vendor.key)
                              }
                              SSAI.VENDORS = VENDORS
                              SSAI.SITE_VENDORS.sort()
                              setOptions({ ...options, SSAI })
                              setAllOptions({ ...allOptions, SSAI })
                            }}
                          />
                        )}
                        label={vendor.key}
                      />
                    </Box>
                  )
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
