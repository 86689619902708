import React from 'react'
import { Card, CardContent, Divider, Table, TableHead, TableBody, TableRow, TableCell, TextField, Typography } from '@material-ui/core'
import { ToggleSwitch } from '../../ui/ToggleSwitch'

export const AdFuelConfiguration = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { ADFUEL } = options
  const {
    AUTO_DISPATCH,
    DEFAULT_TIMEOUT,
    DEFAULT_DESKTOP_TIMEOUT,
    DEFAULT_MOBILE_TIMEOUT,
    IFRAME_TITLE,
    NETWORK_ID,
    REFRESH_ON_FOCUS_ONLY,
    // LAZY_LOAD_FETCH_PERCENT,
    // LAZY_LOAD_RENDER_PERCENT,
    // LAZY_LOAD_MOBILE_SCALING,
    COLLAPSE_EMPTY_DIVS
  } = ADFUEL
  const { STRING, NUMBER } = validation
  const { LENGTH } = STRING
  const { TIMEOUT } = NUMBER

  const displayPropertyTable = () => {
    return (
      <Card>
        <CardContent style={{ width: '100%' }}>
          <Table aria-label='default-config-table'>
            <TableBody>
              <TableRow>
                <TableCell size='small' style={{ width: '150px', border: 'none' }}>
                  <Typography>IFRAME_TITLE:</Typography>
                </TableCell>
                <TableCell size='small' style={{ width: '200px', border: 'none' }}>
                  {/* {textField('IFRAME_TITLE', 'Advertisement', handleTextFieldChange, errors.IFRAME_TITLE, errors.IFRAME_TITLE ? errors.IFRAME_TITLE.message : '')} */}
                  <TextField
                    margin='none'
                    placeholder='Iframe Title'
                    value={IFRAME_TITLE}
                    error={errors.ADFUEL_IFT}
                    helperText={errors.ADFUEL_IFT && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      ADFUEL.IFRAME_TITLE = event.target.value
                      setErrors({ ...errors, ADFUEL_IFT: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, ADFUEL })
                      setAllOptions({ ...allOptions, ADFUEL })
                    }}
                  />
                </TableCell>
                <TableCell size='small' rowSpan={9} style={{ border: 'none', verticalAlign: 'top' }}>
                  <Table aria-label='default-config-table'>
                    <TableBody>
                      <TableRow>
                        <TableCell size='small' style={{ border: 'none' }}>
                          {/* {boolField('AUTO_DISPATCH', true, handleBoolChange, errors.AUTO_DISPATCH, errors.AUTO_DISPATCH ? errors.AUTO_DISPATCH.message : '')} */}
                          <ToggleSwitch
                            label='AUTO_DISPATCH'
                            offLabel='False'
                            onLabel='True'
                            checked={AUTO_DISPATCH}
                            error={errors.ADFUEL_ADI}
                            helperText={errors.ADFUEL_ADI}
                            onChange={(event) => {
                              const checked = event.target.checked
                              ADFUEL.AUTO_DISPATCH = checked
                              setOptions({ ...options, ADFUEL })
                              setAllOptions({ ...allOptions, ADFUEL })
                            }}
                          />
                          {/* {boolField('COLLAPSE_EMPTY_DIVS', true, handleBoolChange, errors.COLLAPSE_EMPTY_DIVS, errors.COLLAPSE_EMPTY_DIVS ? errors.COLLAPSE_EMPTY_DIVS.message : '')} */}
                          <ToggleSwitch
                            label='COLLAPSE_EMPTY_DIVS'
                            offLabel='False'
                            onLabel='True'
                            checked={COLLAPSE_EMPTY_DIVS}
                            error={errors.ADFUEL_CED}
                            helperText={errors.ADFUEL_CED}
                            onChange={(event) => {
                              const checked = event.target.checked
                              ADFUEL.COLLAPSE_EMPTY_DIVS = checked
                              setOptions({ ...options, ADFUEL })
                              setAllOptions({ ...allOptions, ADFUEL })
                            }}
                          />
                          {/* {boolField('REFRESH_ON_FOCUS_ONLY', false, handleBoolChange, errors.REFRESH_ON_FOCUS_ONLY, errors.REFRESH_ON_FOCUS_ONLY ? errors.REFRESH_ON_FOCUS_ONLY.message : '')} */}
                          <ToggleSwitch
                            label='REFRESH_ON_FOCUS_ONLY'
                            offLabel='False'
                            onLabel='True'
                            checked={REFRESH_ON_FOCUS_ONLY}
                            error={errors.ADFUEL_RFO}
                            helperText={errors.ADFUEL_RFO}
                            onChange={(event) => {
                              const checked = event.target.checked
                              ADFUEL.REFRESH_ON_FOCUS_ONLY = checked
                              setOptions({ ...options, ADFUEL })
                              setAllOptions({ ...allOptions, ADFUEL })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
              <TableRow style={{ border: 'none' }}>
                <TableCell size='small' style={{ width: '150px', border: 'none' }}>
                  <Typography>NETWORK_ID:</Typography>
                </TableCell>
                <TableCell size='small' colSpan={2} style={{ width: '200px', border: 'none' }}>
                  <TextField
                    margin='none'
                    placeholder='Network ID'
                    value={NETWORK_ID}
                    error={errors.ADFUEL_NID}
                    helperText={errors.ADFUEL_NID && LENGTH.message}
                    onChange={(event) => {
                      const value = event.target.value
                      ADFUEL.NETWORK_ID = event.target.value
                      setErrors({ ...errors, ADFUEL_NID: value.length < LENGTH.min || value.length > LENGTH.max })
                      setOptions({ ...options, ADFUEL })
                      setAllOptions({ ...allOptions, ADFUEL })
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Divider />
          <Table size='small' aria-label='adfuel timeouts table'>
            <TableHead>
              <TableRow>
                <TableCell style={{ paddingTop: '25px' }} colspan={3}><Typography variant='h5'>Default Timeouts</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '33%' }}>
                  <TextField
                    fullWidth
                    margin='none'
                    label='General'
                    inputProps={{
                      type: 'number',
                      min: TIMEOUT.min,
                      max: TIMEOUT.max
                    }}
                    value={DEFAULT_TIMEOUT}
                    error={errors.ADFUEL_ODT}
                    helperText={errors.ADFUEL_ODT && TIMEOUT.message}
                    onChange={(event) => {
                      const value = parseInt(event.target.value)
                      ADFUEL.DEFAULT_TIMEOUT = value
                      setErrors({ ...errors, ADFUEL_ODT: value < TIMEOUT.min || value > TIMEOUT.max })
                      setOptions({ ...options, ADFUEL })
                      setAllOptions({ ...allOptions, ADFUEL })
                    }}
                  />
                </TableCell>
                <TableCell style={{ width: '33%' }}>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Desktop'
                    inputProps={{
                      type: 'number',
                      min: TIMEOUT.min,
                      max: TIMEOUT.max
                    }}
                    value={DEFAULT_DESKTOP_TIMEOUT}
                    error={errors.ADFUEL_DDT}
                    helperText={errors.ADFUEL_DDT && TIMEOUT.message}
                    onChange={(event) => {
                      const value = parseInt(event.target.value)
                      ADFUEL.DEFAULT_DESKTOP_TIMEOUT = value
                      setErrors({ ...errors, ADFUEL_DDT: value < TIMEOUT.min || value > TIMEOUT.max })
                      setOptions({ ...options, ADFUEL })
                      setAllOptions({ ...allOptions, ADFUEL })
                    }}
                  />
                </TableCell>
                <TableCell style={{ width: '33%' }}>
                  <TextField
                    fullWidth
                    margin='none'
                    label='Mobile'
                    inputProps={{
                      type: 'number',
                      min: TIMEOUT.min,
                      max: TIMEOUT.max
                    }}
                    value={DEFAULT_MOBILE_TIMEOUT}
                    error={errors.ADFUEL_DMT}
                    helperText={errors.ADFUEL_DMT && TIMEOUT.message}
                    onChange={(event) => {
                      const value = parseInt(event.target.value)
                      ADFUEL.DEFAULT_MOBILE_TIMEOUT = value
                      setErrors({ ...errors, ADFUEL_DMT: value < TIMEOUT.min || value > TIMEOUT.max })
                      setOptions({ ...options, ADFUEL })
                      setAllOptions({ ...allOptions, ADFUEL })
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
  }

  return (
    displayPropertyTable()
  )
}
