import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateAdFuel, ToggleIsAdFuelVersionSelected } from '../../../modules/sitesSlice'
import { fetchAdFuelVersions } from '../../../modules/adFuelSlice'
import { CustomDialog, ToggleSwitch } from '../../ui'

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Button,
  Link,
  Grid
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SettingsIcon from '@material-ui/icons/Settings'

import { AdFuelConfigurationModal } from '../Dialogs/AdFuelConfigurationModal'

import { addClasses, commonStyle } from '../../../styles'
import { clone } from '../../../utilities/helperFunctions'
// import config from '../../config'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  getAdFuelModuleConfiguration,
  getAdFuelModuleCoreConfiguration
} from '../../../modules/lookupsSlice'
import { getPath } from '../../../modules/configSlice'

// const rootUrl = config[config.env].publisher.rootAdFuelUrl

const swal = withReactContent(Swal)

const AdFuelConfiguration = ({ data, canEditSite }) => {
  const commonClasses = commonStyle()
  const { adFuelVersions } = useSelector(state => state.adFuelSlice)
  const { updatingSiteAdFuel } = useSelector(state => state.sitesSlice)
  const { pathConfig } = useSelector(state => state.configSlice)
  const [publishers, setPublishers] = useState()
  const [site, setSite] = useState(clone(data))
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [errors, setErrors] = useState({})
  const {
    isAdFuelMinified,
    isAdFuelLocationSiteSpecific,
    adFuelVersionId,
    tagForChildDirectedTreatment
  } = site || {
    isAdFuelMinified: true,
    isAdFuelLocationSpecific: false,
    adFuelVersionId: null,
    tagForChildDirectedTreatment: false
  }
  const [url, setUrl] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const [versionId, setVersionId] = useState(adFuelVersionId)
  const dispatch = useDispatch()
  // console.log('Using COPPA AdFuel:', tagForChildDirectedTreatment)
  useEffect(() => {
    setSite(clone(data))
    const adFuelVersion = adFuelVersions.find(adfuel => adfuel._id === data.adFuelVersionId)
    dispatch(ToggleIsAdFuelVersionSelected(adFuelVersion !== undefined))
  }, [data])

  useEffect(() => {
    if (adFuelVersions.length === 0) {
      dispatch(fetchAdFuelVersions())
    }
  }, [adFuelVersions])

  useEffect(() => {
    buildUrl('topEffect-AdFuelVersions', adFuelVersions?.length)
    setVersionId(site.adFuelVersionId)
  }, [adFuelVersions])

  useEffect(() => {
    buildUrl('topEffect-Publishers', publishers)
  }, [publishers])

  useEffect(() => {
    if (site._id) {
      const oldData = {
        isAdFuelMinified: data.isAdFuelMinified,
        isAdFuelLocationSiteSpecific: data.isAdFuelLocationSiteSpecific,
        adFuelVersionId: data.adFuelVersionId
      }
      const newData = {
        isAdFuelMinified: site.isAdFuelMinified,
        isAdFuelLocationSiteSpecific: site.isAdFuelLocationSiteSpecific,
        adFuelVersionId: site.adFuelVersionId
      }
      setVersionId(site.adFuelVersionId)
      setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
      buildUrl()
    }
  }, [site])

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      setPublishers(pathConfig.publisher)
    } else {
      dispatch(getPath())
    }
    buildUrl()
  }, [pathConfig])

  const buildUrl = (name, value) => {
    // console.log('Building URL: ', { name, value, publishers })
    if (!publishers) return
    const adFuelVersion =
      adFuelVersions.find(adfuel => adfuel._id === adFuelVersionId) || {}
    const { major, minor, revision, patch } = adFuelVersion
    const fileName =
      'adfuel-' +
      major +
      '.' +
      minor +
      (revision || revision === 0 ? '.' + revision : '') +
      (patch || patch === 0 ? '.' + patch : '') +
      (tagForChildDirectedTreatment ? '-cc' : '') +
      (isAdFuelMinified ? '.min' : '') +
      '.js'
    // const adFuelUrl = (isAdFuelLocationSiteSpecific ? rootUrl.replace('/adfuel', `/${site.name.toLowerCase()}`) : rootUrl) + '/' + fileName
    const adFuelUrl =
      (isAdFuelLocationSiteSpecific
        ? publishers?.rootAdFuelUrl?.replace('/adfuel', '/' + site.name.toLowerCase())
        : publishers?.rootAdFuelUrl) +
      '/' +
      fileName

    setUrl(adFuelUrl)
  }

  const openConfigurationDialog = async () => {
    const adFuelVersion =
      adFuelVersions.find(adfuel => adfuel._id === adFuelVersionId) || {}
    const onClose = () => {
      swal.fire({
        title: 'Are you sure?',
        text: 'Changes will not be saved.',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(ok => {
        if (ok.isConfirmed) {
          setOpenDialog(false)
        }
      })
    }
    const response = await getAdFuelModuleConfiguration(
      site._id,
      adFuelVersionId
    )
    // console.log('Response: ', response)
    const core = await getAdFuelModuleCoreConfiguration()
    const mergedData = { ...core, ...response.data }
    mergedData.ADFUEL = mergedData.ADFUEL || {}
    mergedData.ADFUEL.ACTIVE_MODULES = mergedData.ADFUEL?.ACTIVE_MODULES || []

    try {
      setDialog({
        content: (
          <AdFuelConfigurationModal
            allOptions={mergedData}
            data={mergedData.ADFUEL}
            site={site}
            setOpenDialog={setOpenDialog}
            errors
            setErrors
          />
        ),
        displayDialogAction: false,
        onClose
      })
      setOpenDialog(true)
    } catch (err) {
      swal.fire({
        title: 'Error',
        text: err,
        icon: 'error'
      })
    }
  }

  return (
    <Card>
      <CardHeader
        className={commonClasses.pl_3}
        titleTypographyProps={{ variant: 'h6' }}
        title='AdFuel'
      />
      <CardContent
        className={addClasses(commonClasses.pl_3, commonClasses.pr_3)}
      >
        <Grid container spacing={2}>
          {url && adFuelVersionId && (
            <Grid item xs={12}>
              <Link className={commonClasses.linkColor} target='_blank' rel='noopener noreferrer' href={url}>
                {url}
              </Link>
            </Grid>
          )}
          <Grid item xs={12} container spacing={2} alignItems='center'>
            <Grid item>
              <FormControl>
                <InputLabel id='adfuel-version-label'>Version</InputLabel>
                <Select
                  disabled={!canEditSite || updatingSiteAdFuel}
                  labelId='adfuel-version-label'
                  style={{ minWidth: '120px' }}
                  value={versionId || ''}
                  onChange={event => {
                    const value = event.target.value
                    setVersionId(value)
                    setSite({ ...site, adFuelVersionId: value })
                    buildUrl('version', value)
                  }}
                >
                  {adFuelVersions.map((item, index) => {
                    // console.log('Filter: ', { tag: tagForChildDirectedTreatment, coppa: item.hasCOPPA })
                    if ((tagForChildDirectedTreatment && item.hasCOPPA) || !tagForChildDirectedTreatment) {
                      return (
                        <MenuItem key={index} value={item._id}>
                          {item.name}
                        </MenuItem>
                      )
                    } else {
                      return ''
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            {isAdFuelLocationSiteSpecific !== undefined && (
              <Grid item>
                <ToggleSwitch
                  disabled={!canEditSite || updatingSiteAdFuel}
                  label='Location'
                  offLabel='Common'
                  onLabel='Site Specific'
                  checked={isAdFuelLocationSiteSpecific}
                  onChange={event => {
                    const value = event.target.checked
                    setSite({ ...site, isAdFuelLocationSiteSpecific: value })
                    buildUrl('location', value)
                  }}
                />
              </Grid>
            )}
            {isAdFuelMinified !== undefined && (
              <Grid item>
                <ToggleSwitch
                  disabled={!canEditSite || updatingSiteAdFuel}
                  label='Packaging'
                  offLabel='Normal'
                  onLabel='Minified'
                  checked={isAdFuelMinified}
                  onChange={event => {
                    const value = event.target.checked
                    setSite({ ...site, isAdFuelMinified: value })
                    buildUrl('packaging', value)
                  }}
                />
              </Grid>
            )}
            <Grid item>
              <Button
                // className={commonClasses.btnSuccess}
                variant='contained'
                startIcon={<SettingsIcon />}
                onClick={() => {
                  if (canEditSite) {
                    openConfigurationDialog()
                  } else {
                    swal.fire({
                      title: 'UNAUTHORIZED',
                      text: 'You do not have permissions to do this.',
                      icon: 'error',
                      dangerMode: false
                    })
                  }
                }}
              >
                Configure
              </Button>
              <CustomDialog
                open={openDialog}
                setOpenDialog={setOpenDialog}
                title={dialog.title}
                subtitle={dialog.subtitle}
                content={dialog.content}
                displayDialogAction={dialog.displayDialogAction}
                customAction={dialog.customAction}
                size={dialog.size}
                handleOnClose={dialog.onClose}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className={isChanged ? commonClasses.btnSuccess : ''}
          disabled={!isChanged || updatingSiteAdFuel}
          variant='contained'
          size="small"
          startIcon={<CheckCircleIcon />}
          onClick={() => {
            dispatch(updateAdFuel(site))
          }}
        >
          {updatingSiteAdFuel ? 'Saving...' : 'Save'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default AdFuelConfiguration