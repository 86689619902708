import React, { useState, useEffect } from 'react'

import { Grid, FormControlLabel, Checkbox, Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Divider, Select, MenuItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DurationTable } from '../index'

const template = {
  ACCOUNT_ID: {
    DOM: 0,
    INTL: 0
  },
  VIDEO_INVCODE: {
    DOM: 'cnn_homepage_video1',
    INTL: 'cnni_homepage_video1'
  },
  PLAYBACK_METHOD: { 
    DOM: { 
      DESKTOP: 'auto_play_sound_off', 
      MOBILE: 'click_to_play' 
    }, 
    INTL: { 
      DESKTOP: 'auto_play_sound_off', 
      MOBILE: 'click_to_play' 
    } 
  },
  VIDEO: {
    DURATION: {
      DESKTOP: {
        MIN: {
          DOM: 5,
          INTL: 5
        },
        MAX: {
          DOM: 15,
          INTL: 15
        }
      },
      MOBILE: {
        MIN: {
          DOM: 6,
          INTL: 6
        },
        MAX: {
          DOM: 30,
          INTL: 30
        }

      }
    }
  },
  RESERVE: { DOM: 0.9, INTL: 0.9 },
  SUPPLY_TYPE: { DOM: { WEB: true, MOBILE_WEB: true }, INTL: { WEB: true, MOBILE_WEB: true } },
  CONTEXT: { DOM: 'instream', INTL: 'instream' }
}

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

const mergeDeep = (target, ...sources) => {
  if (!sources.length) {
    // console.log('No more sources. Returning target: ', target)
    return target
  }
  const source = sources.shift()
  // console.log('Merging in source: ', source)
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          })
        }
        // console.log('Merging key: ', key)
        mergeDeep(target[key], source[key])
      } else {
        // console.log('Setting key: ', key)
        Object.assign(target, {
          [key]: source[key]
        })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const AppNexus = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const APPNEXUS = mergeDeep(template, VENDOR.APPNEXUS)
  let {
    ACCOUNT_ID,
    PLAYBACK_METHOD,
    VIDEO_INVCODE,
    RESERVE,
    SUPPLY_TYPE,
    CONTEXT
  } = APPNEXUS
  let { DOM: ACCOUNT_DOM, INTL: ACCOUNT_INTL } = ACCOUNT_ID
  let { DOM: INVCODE_DOM, INTL: INVCODE_INTL } = VIDEO_INVCODE
  let { DOM: RESERVE_DOM, INTL: RESERVE_INTL } = RESERVE
  let { DOM: CONTEXT_DOM, INTL: CONTEXT_INTL } = CONTEXT
  let { DOM: PLAYBACK_DOM, INTL: PLAYBACK_INTL } = PLAYBACK_METHOD
  if (typeof PLAYBACK_DOM === 'string') { PLAYBACK_DOM = { DESKTOP: PLAYBACK_DOM, MOBILE: PLAYBACK_DOM } }
  if (typeof PLAYBACK_INTL === 'string') { PLAYBACK_INTL = { DESKTOP: PLAYBACK_INTL, MOBILE: PLAYBACK_INTL } }
  PREBID.VENDOR.APPNEXUS.PLAYBACK_METHOD = { DOM: PLAYBACK_DOM, INTL: PLAYBACK_INTL }
  let { DESKTOP: PLAYBACK_DOM_DESKTOP, MOBILE: PLAYBACK_DOM_MOBILE } = PLAYBACK_DOM
  let { DESKTOP: PLAYBACK_INTL_DESKTOP, MOBILE: PLAYBACK_INTL_MOBILE } = PLAYBACK_INTL
  VENDOR.APPNEXUS = APPNEXUS
  const { STRING } = validation
  const { LENGTH } = STRING
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='AppNexus-panel-content'
        id='AppNexus-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>AppNexus</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table size='small' aria-label='appnexus-account-id-table'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '180px' }} />
                  <TableCell style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Account IDs</Typography></TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={ACCOUNT_DOM}
                      error={errors.PREBID_APPNEXUS_AIDD}
                      helperText={errors.PREBID_APPNEXUS_AIDD && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        PREBID.VENDOR.APPNEXUS.ACCOUNT_ID.DOM = value
                        setErrors({ ...errors, PREBID_APPNEXUS_AIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={ACCOUNT_INTL}
                      error={errors.PREBID_APPNEXUS_AIDI}
                      helperText={errors.PREBID_APPNEXUS_AIDI && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        PREBID.VENDOR.APPNEXUS.ACCOUNT_ID.INTL = value
                        setErrors({ ...errors, PREBID_APPNEXUS_AIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Table size='small' aria-label='appnexus-video-invcode-table'>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Video InvCode</Typography></TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={INVCODE_DOM}
                      error={errors.PREBID_APPNEXUS_VICD}
                      helperText={errors.PREBID_APPNEXUS_VICD && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        PREBID.VENDOR.APPNEXUS.VIDEO_INVCODE.DOM = value
                        setErrors({ ...errors, PREBID_APPNEXUS_VICD: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={INVCODE_INTL}
                      error={errors.PREBID_APPNEXUS_VICI}
                      helperText={errors.PREBID_APPNEXUS_VICI && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        PREBID.VENDOR.APPNEXUS.VIDEO_INVCODE.INTL = value
                        setErrors({ ...errors, PREBID_APPNEXUS_VICI: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Table size='small' aria-label='appnexus-reserve-table'>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Reserve</Typography></TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={RESERVE_DOM}
                      error={errors.PREBID_APPNEXUS_RESD}
                      helperText={errors.PREBID_APPNEXUS_RESD && LENGTH.message}
                      onChange={(event) => {
                        const value = parseFloat(event.target.value)
                        PREBID.VENDOR.APPNEXUS.RESERVE.DOM = value
                        setErrors({ ...errors, PREBID_APPNEXUS_RESD: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={RESERVE_INTL}
                      error={errors.PREBID_APPNEXUS_RESI}
                      helperText={errors.PREBID_APPNEXUS_RESI && LENGTH.message}
                      onChange={(event) => {
                        const value = parseFloat(event.target.value)
                        PREBID.VENDOR.APPNEXUS.RESERVE.INTL = value
                        setErrors({ ...errors, PREBID_APPNEXUS_RESI: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Table size='small' aria-label='appnexus-supplyType-table'>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Supply Type</Typography></TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color='primary'
                              checked={SUPPLY_TYPE.DOM.WEB}
                              onChange={(event) => {
                                SUPPLY_TYPE.DOM.WEB = event.target.checked
                                setOptions({ ...options, PREBID })
                              }}
                            />
                          )}
                          label='Web'
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color='primary'
                              checked={SUPPLY_TYPE.DOM.MOBILE_WEB}
                              onChange={(event) => {
                                SUPPLY_TYPE.DOM.MOBILE_WEB = event.target.checked
                                setOptions({ ...options, PREBID })
                              }}
                            />
                          )}
                          label='Mobile Web'
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color='primary'
                              checked={SUPPLY_TYPE.INTL.WEB}
                              onChange={(event) => {
                                SUPPLY_TYPE.INTL.WEB = event.target.checked
                                setOptions({ ...options, PREBID })
                              }}
                            />
                          )}
                          label='Web'
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              color='primary'
                              checked={SUPPLY_TYPE.INTL.MOBILE_WEB}
                              onChange={(event) => {
                                SUPPLY_TYPE.INTL.MOBILE_WEB = event.target.checked
                                setOptions({ ...options, PREBID })
                              }}
                            />
                          )}
                          label='Mobile Web'
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Table size='small' aria-label='appnexus-context-table'>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Video Context</Typography></TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={CONTEXT_DOM}
                      error={errors.PREBID_APPNEXUS_CTD}
                      helperText={errors.PREBID_APPNEXUS_CTD && LENGTH.message}
                      onChange={(event) => {
                        const value = event.target.value
                        PREBID.VENDOR.APPNEXUS.CONTEXT.DOM = value
                        setErrors({ ...errors, PREBID_APPNEXUS_CTD: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TextField
                      margin='none'
                      value={CONTEXT_INTL}
                      error={errors.PREBID_APPNEXUS_CTI}
                      helperText={errors.PREBID_APPNEXUS_CTI && LENGTH.message}
                      onChange={(event) => {
                        console.log('INTL Context Change: ', event)
                        const value = event.target.value
                        console.log('INTL Context Value: ', value)
                        console.log('Video Configuration Value: ', PREBID.VENDOR.APPNEXUS.VIDEO)
                        PREBID.VENDOR.APPNEXUS.CONTEXT.INTL = value
                        console.log('Context Configuration Value: ', PREBID.VENDOR.APPNEXUS.VIDEO.CONTEXT)
                        console.log('Errors: ', errors)
                        setErrors({ ...errors, PREBID_APPNEXUS_CTI: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Table size='small' aria-label='appnexus-playback-method-table'>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '180px' }}>
                    <Typography variant='h6' style={{ fontWeight: 'bolder' }}>Playback Method</Typography>
                    <Typography variant='p'>Desktop</Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Select
                      labelId='PREBID_APPNEXUS_PBMDD'
                      id='PREBID_APPNEXUS_PBMDD'
                      value={PLAYBACK_DOM_DESKTOP}
                      error={errors.PREBID_APPNEXUS_PBMDD}
                      helperText={errors.PREBID_APPNEXUS_PBMDD && LENGTH.message}
                      label='Domestic Desktop'
                      onChange={(event) => {
                        const value = event.target.value
                        console.log('Setting value: ', value)
                        console.log("Property: ", { existing: PREBID.VENDOR.APPNEXUS.PLAYBACK_METHOD.DOM })
                        PREBID.VENDOR.APPNEXUS.PLAYBACK_METHOD.DOM.DESKTOP = value
                        setErrors({ ...errors, PREBID_APPNEXUS_PBMDD: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    >
                      <MenuItem value='auto_play_sound_off'>auto_play_sound_off</MenuItem>
                      <MenuItem value='auto_play_sound_on'>auto_play_sound_on</MenuItem>
                      <MenuItem value='auto_play_sound_unknown'>auto_play_sound_unknown</MenuItem>
                      <MenuItem value='click_to_play'>click_to_play</MenuItem>
                      <MenuItem value='mouse_over'>mouse_over</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Select
                      labelId='PREBID_APPNEXUS_PBMID'
                      id='PREBID_APPNEXUS_PBMID'
                      value={PLAYBACK_INTL_DESKTOP}
                      error={errors.PREBID_APPNEXUS_PBMID}
                      helperText={errors.PREBID_APPNEXUS_PBMID && LENGTH.message}
                      label='International Desktop'
                      onChange={(event) => {
                        const value = event.target.value
                        PREBID.VENDOR.APPNEXUS.PLAYBACK_METHOD.INTL.DESKTOP = value
                        setErrors({ ...errors, PREBID_APPNEXUS_PBMID: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    >
                      <MenuItem value='auto_play_sound_off'>auto_play_sound_off</MenuItem>
                      <MenuItem value='auto_play_sound_on'>auto_play_sound_on</MenuItem>
                      <MenuItem value='auto_play_sound_unknown'>auto_play_sound_unknown</MenuItem>
                      <MenuItem value='click_to_play'>click_to_play</MenuItem>
                      <MenuItem value='mouse_over'>mouse_over</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: '180px' }}>
                    <Typography variant='h6' style={{ fontWeight: 'bolder' }}>Playback Method</Typography>
                    <Typography variant='p'>Mobile</Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Select
                      labelId='PREBID_APPNEXUS_PBMDM'
                      id='PREBID_APPNEXUS_PBMDM'
                      value={PLAYBACK_DOM_MOBILE}
                      error={errors.PREBID_APPNEXUS_PBMDM}
                      helperText={errors.PREBID_APPNEXUS_PBMDM && LENGTH.message}
                      label='Domestic Mobile'
                      onChange={(event) => {
                        const value = event.target.value
                        PREBID.VENDOR.APPNEXUS.PLAYBACK_METHOD.DOM.MOBILE = value
                        setErrors({ ...errors, PREBID_APPNEXUS_PBMDM: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    >
                      <MenuItem value='auto_play_sound_off'>auto_play_sound_off</MenuItem>
                      <MenuItem value='auto_play_sound_on'>auto_play_sound_on</MenuItem>
                      <MenuItem value='auto_play_sound_unknown'>auto_play_sound_unknown</MenuItem>
                      <MenuItem value='click_to_play'>click_to_play</MenuItem>
                      <MenuItem value='mouse_over'>mouse_over</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Select
                      labelId='PREBID_APPNEXUS_PBMIM'
                      id='PREBID_APPNEXUS_PBMIM'
                      value={PLAYBACK_INTL_MOBILE}
                      error={errors.PREBID_APPNEXUS_PBMIM}
                      helperText={errors.PREBID_APPNEXUS_PBMIM && LENGTH.message}
                      label='International Mobile'
                      onChange={(event) => {
                        const value = event.target.value
                        PREBID.VENDOR.APPNEXUS.PLAYBACK_METHOD.INTL.MOBILE = value
                        setErrors({ ...errors, PREBID_APPNEXUS_PBMIM: value.length < LENGTH.min || value.length > LENGTH.max })
                        setOptions({ ...options, PREBID })
                        setAllOptions({ ...allOptions, PREBID })
                      }}
                    >
                      <MenuItem value='auto_play_sound_off'>auto_play_sound_off</MenuItem>
                      <MenuItem value='auto_play_sound_on'>auto_play_sound_on</MenuItem>
                      <MenuItem value='auto_play_sound_unknown'>auto_play_sound_unknown</MenuItem>
                      <MenuItem value='click_to_play'>click_to_play</MenuItem>
                      <MenuItem value='mouse_over'>mouse_over</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            {/* {displayDurationTable(VIDEO, PREBID, errors, validation, options, allOptions, setErrors, setOptions, setAllOptions)} */}
            <DurationTable
                // path='IX.SITE_ID.DOM.DESKTOP.btf'
              path='APPNEXUS.VIDEO.DURATION'
                // object={SITE_ID?.DOM?.DESKTOP?.btf}
              object={APPNEXUS}
              options={options}
              allOptions={allOptions}
              setOptions={setOptions}
              setAllOptions={setAllOptions}
              errors={errors}
              setErrors={setErrors}
              validation={validation}
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
