import React from 'react'

import { Card, CardContent, Table, TableHead, TableBody, TableRow, TableCell, TextField, Typography } from '@material-ui/core'

export const IntegralAdScience = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { IAS } = options
  const { ACCOUNT_IDS } = IAS
  const { DOM, INTL } = ACCOUNT_IDS
  const { STRING } = validation
  const { LENGTH } = STRING

  return (
    <Card>
      <CardContent>
        <Table size='small' aria-label='IAS-account-id-table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '180px' }}><Typography variant='h5'>Account IDs</Typography></TableCell>
              <TableCell>Domestic</TableCell>
              <TableCell>International</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell>
                <TextField
                  margin='none'
                  value={DOM}
                  error={errors.IAS_DOM}
                  helperText={errors.IAS_DOM && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    IAS.ACCOUNT_IDS.DOM = value
                    setErrors({ ...errors, IAS_DOM: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, IAS })
                    setAllOptions({ ...allOptions, IAS })
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  margin='none'
                  value={INTL}
                  error={errors.IAS_INTL}
                  helperText={errors.IAS_INTL && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    IAS.ACCOUNT_IDS.INTL = value
                    setErrors({ ...errors, IAS_INTL: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, IAS })
                    setAllOptions({ ...allOptions, IAS })
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
