import React, { useState, useEffect, useCallback } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { TableIcons } from '../../ui/TableIcons'
import { CardHeader, Typography, Tooltip, Grid, Card, CardContent, CardActions, IconButton, Select, MenuItem, LinearProgress, Link, Box, Button, makeStyles } from '@material-ui/core'
import { CustomDialog, CustomTable } from '../../ui'
import { addClasses, commonStyle } from '../../../styles'
import { clone, siteNameFormatter, cacheBuster } from '../../../utilities/helperFunctions.js'
import { fetchAdFuelModules } from '../../../modules/adFuelModuleSlice'
import { updateSiteAdFuelModules } from '../../../modules/sitesSlice'
import { getAdFuelModuleConfiguration, getAdFuelModuleConfigurations, getAdFuelModuleCoreConfiguration } from '../../../modules/lookupsSlice'
import Launch from '@material-ui/icons/Launch'
import GetApp from '@material-ui/icons/GetApp'
import Delete from '@material-ui/icons/Delete'
import Settings from '@material-ui/icons/Settings'
import Info from '@material-ui/icons/Info'
import Build from '@material-ui/icons/Build'
import { AdFuelOptions } from '../index.js'
import semver from 'semver'
import MarkdownIt from 'markdown-it'

const swal = withReactContent(Swal)

const useStyle = makeStyles(theme => ({
  footerActionArea: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  iconButtons: {
    marginLeft: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  dflex: {
    display: 'flex'
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },  
}))

const cellStyle = {
  padding: '0 5px'
}

const centeredCellStyle = {
  padding: '0 5px',
  textAlign: 'center',
  justify: 'middle'
}

const initialAdFuelModule = {
  name: '',
  description: '',
  url: '',
  versions: [],
  arguments: []
}

const ModuleConfiguration = ({ site, canEditSite }) => {
  const classes = useStyle()
  const commonClasses = commonStyle()
  const { updatingSiteAdFuelModules } = useSelector(state => state.sitesSlice)
  const { adFuelModules: allAdFuelModules } = useSelector(state => state.adFuelModulesSlice)
  const [siteData, setSiteData] = useState(clone(site))
  const [siteAdFuelModules, setSiteAdFuelModules] = useState(site.adFuelModules)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [loading, setLoading] = useState(true)
  let { aisUrl, adFuelModules } = site
  const [aisUrlWithCacheBuster, setAisUrlWithCacheBuster] = useState(aisUrl)
  const [clickCount, setClickCount] = useState(0)
  const dispatch = useDispatch()
  
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => {
    updateState({})
  }, [])

  const configurableModules = ['USER_CONSENT', 'A9', 'PREBID', 'KRUX', 'IAS', 'SSAI', 'MALVERTISING', 'IVR', 'BEEMRAY', 'RTAI']
  const mdParser = new MarkdownIt(/* Markdown-it options */)

  const getModule = adFuelModuleId => {
    const module = allAdFuelModules.find(item => item._id === adFuelModuleId)
    return !module ? '' : module
  }

  const byPriorityAndName = (a, b) => {
    if (a.adFuelModuleId && b.adFuelModuleId) {
      const moduleA = getModule(a.adFuelModuleId)
      const moduleB = getModule(b.adFuelModuleId)
      if (moduleA && moduleB) {
        const nameA = moduleA.name.toUpperCase()
        const nameB = moduleB.name.toUpperCase()
        const prioA = moduleA.priority !== null ? parseInt(moduleA.priority || 0, 10) : 0
        const prioB = moduleB.priority !== null ? parseInt(moduleB.priority || 0, 10) : 0
        try {
          if (prioA > prioB) {
            return -1
          }
          if (prioA < prioB) {
            return 1
          }
          if (prioA === prioB) {
            if (nameA > nameB) {
              return 1
            }
            if (nameA < nameB) {
              return -1
            }
          }
          return 0
        } catch (err) {
          return 0
        }
      }
    }
  }

  const getModulesForAdFuelVersion = (modules, site) => {
    const result = []
    const siteAdFuelVersionId = site.adFuelVersionId
    const siteAdFuelVersion = adFuelVersions.filter(version => version._id === siteAdFuelVersionId)[0]
    if (siteAdFuelVersion) {
      setSiteAdFuelVersion(siteAdFuelVersion)
      let latest = false
      const { major, minor, revision } = siteAdFuelVersion
      if (revision === null || siteAdFuelVersion.name.toLowerCase().indexOf('latest') > 0) latest = true
      setLatest(latest)
      modules.forEach(module => {
        // console.groupCollapsed(module.name)
        const moduleAdFuelVersionId = module.adFuelVersionId
        if (moduleAdFuelVersionId) {
          const moduleAdFuelVersion = adFuelVersions.filter(version => version._id === moduleAdFuelVersionId)[0]
          let modLatest = false
          if (moduleAdFuelVersion) {
            const { major: modMajor, minor: modMinor, revision: modRevision } = moduleAdFuelVersion
            if (modRevision === null || moduleAdFuelVersion.name.toLowerCase().indexOf('latest') > 0) modLatest = true
            if (moduleAdFuelVersion && modMajor === major && modMinor <= minor) {
              // console.log('AdFuel Major and Module Major are equal and Module Minor is less than or equal to AdFuel Minor', { major, minor, revision, latest, modMajor, modMinor, modRevision, modLatest })
              if (!latest && !modLatest && modRevision <= revision) {
                // console.log('AdFuel and Module is not latest and modRevision is less than or equal to AdFuel revision... Pushing ', { major, minor, revision, latest, modMajor, modMinor, modRevision, modLatest })
                result.push(module)
              }
              if (latest || modLatest) {
                // console.log('AdFuel or Module are latest... Pushing', { major, minor, revision, latest, modMajor, modMinor, modRevision, modLatest })
                result.push(module)
              }
            } else {
              // console.log('AdFuel Major and Module Major are not equal or Module Minor is greater than AdFuel Minor... Not Pushing', { major, minor, revision, latest, modMajor, modMinor, modRevision, modLatest })
            }
          }
        } else {
          const newModule = { ...module }
          // console.log('No Module AdFuel Version... Pushing', { major, minor, revision, latest, module, moduleAdFuelVersionId })
          result.push(newModule)
        }
        // console.groupEnd()
      })
    }
    setSelectedAdFuelModuleList([...result])
  }

  useEffect(() => {
    if (allAdFuelModules.length === 0) {
      console.log('[AISConfigurationBeta] ---------- No modules... fetching AdFuel Modules...')
      dispatch(fetchAdFuelModules())
    }
    setAisUrlWithCacheBuster(aisUrl + `?cacheBuster=${cacheBuster()}`)
  }, [])

  useEffect(() => {
    if (allAdFuelModules && adFuelModules && siteAdFuelModules && allAdFuelModules.length > 0 && site.adFuelModules.length >= 0 && (siteAdFuelModules.length === 0 || !siteAdFuelModules[0].action)) {
      console.log('Site: ', site)
      console.log('AdFuel Modules: ', adFuelModules)
      try{
        adFuelModules = site.adFuelModules.map(rowData => {
          const { adFuelModuleId, adFuelModuleVersionId } = rowData
          const module = getModule(adFuelModuleId)
          let actions = (<span></span>)
          if (module) {
            const version = module?.versions.find(item => item._id === adFuelModuleVersionId)
            const url = version ? `https://i.cdn.turner.com${version.url}` : module.url
            const filename = url.split('/')[url.split('/').length - 1]
            const key = module.configurationKey
            const hasReleaseNotes = (version && version.releaseNotes && version.releaseNotes !== null) || false
            actions = (
              <Grid container>
                <Grid className={classes.dflex} item>
                  <IconButton
                    aria-label='Delete Module'
                    title='Delete Module'
                    size="small"
                    onClick={() => {
                      if (canEditSite) {
                        deleteModule(module._id)
                      } else {
                        swal.fire({
                          title: 'UNAUTHORIZED',
                          text: 'You do not have permissions to do this.',
                          icon: 'error',
                          dangerMode: false,
                          customClass: {
                            container: 'swalContainer'
                          }
                        })
                      }
                    }}
                  >
                    <Delete className={commonClasses.deleteIconColor} />
                  </IconButton>
                  {
                    configurableModules.indexOf(key) >= 0
                      ? (
                        <IconButton
                          aria-label='Configure Module'
                          title='Configure Module'
                          size="small"
                          onClick={() => {
                            if (canEditSite) {
                              openConfigurationDialog(module._id, key)
                            } else {
                              swal.fire({
                                title: 'UNAUTHORIZED',
                                text: 'You do not have permissions to do this.',
                                icon: 'error',
                                dangerMode: false,
                                customClass: {
                                  container: 'swalContainer'
                                }
                              })
                            }
                          }}
                        >
                          <Settings />
                        </IconButton>
                        )
                      : ''
                  }
                  {
                    hasReleaseNotes
                      ? (
                        <IconButton
                          aria-label='Release Notes'
                          title='Release Notes'
                          size="small"
                          onClick={e => {
                            e.stopPropagation()
                            openReleaseNotesDialog(version.releaseNotes)
                          }}
                        >
                          <Info />
                        </IconButton>
                        )
                      : ''
                  }
                </Grid>
              </Grid>
            )
            // console.log('Module: ', { url, filename })
            rowData.source = filename || 'N/A'
            rowData.actions = actions
            rowData.actionstring = ReactDOMServer.renderToString(actions)
          } else {
            rowData.source = 'N/A'
            rowData.actions = actions
            rowData.actionstring = ReactDOMServer.renderToString(actions)
          }
          return rowData
        })
        adFuelModules.sort(byPriorityAndName)
      } catch(err) {
        console.log('[AISConfigurationBeta] ---------- useEffect::Error generating rowData:', err)
      }
      console.log('[AISConfigurationBeta] ---------- useEffect::Setting siteAdFuelModules:', { allAdFuelModules: allAdFuelModules, adFuelModules: adFuelModules })    
      setSiteAdFuelModules(adFuelModules)
      setLoading(false)
    } else if (siteAdFuelModules && siteAdFuelModules.length > 0 && !siteAdFuelModules[0].action) {
      console.log('[AISConfigurationBeta] ---------- useEffect::Modules Changed But Did Not Meet Requirements:', { allAdFuelModules: allAdFuelModules.length, adFuelModules: adFuelModules.length, siteAdFuelModules: siteAdFuelModules.length })
    }
  }, [allAdFuelModules, adFuelModules])

  useEffect(() => {
    if (siteData.aisUrl && siteData.adFuelModules) {
      aisUrl = siteData.aisUrl
      adFuelModules = siteData.adFuelModules.map(rowData => {
        const { adFuelModuleId, adFuelModuleVersionId } = rowData
        const module = getModule(adFuelModuleId)
        let actions = (<span></span>)
        if (module) {
          const version = module?.versions.find(item => item._id === adFuelModuleVersionId)
          const url = version ? `https://i.cdn.turner.com${version.url}` : module.url
          const filename = url.split('/')[url.split('/').length - 1]
          const key = module.configurationKey
          const hasReleaseNotes = (version && version.releaseNotes && version.releaseNotes !== null) || false
          actions = (
            <Grid container>
              <Grid className={classes.dflex} item>
                <IconButton
                  aria-label='Delete Module'
                  title='Delete Module'
                  size="small"
                  onClick={() => {
                    if (canEditSite) {
                      deleteModule(module._id)
                    } else {
                      swal.fire({
                        title: 'UNAUTHORIZED',
                        text: 'You do not have permissions to do this.',
                        icon: 'error',
                        dangerMode: false,
                        customClass: {
                          container: 'swalContainer'
                        }
                      })
                    }
                  }}
                >
                  <Delete className={commonClasses.deleteIconColor} />
                </IconButton>
                {
                  configurableModules.indexOf(key) >= 0
                    ? (
                      <IconButton
                        aria-label='Configure Module'
                        title='Configure Module'
                        size="small"
                        onClick={() => {
                          if (canEditSite) {
                            openConfigurationDialog(module._id, key)
                          } else {
                            swal.fire({
                              title: 'UNAUTHORIZED',
                              text: 'You do not have permissions to do this.',
                              icon: 'error',
                              dangerMode: false,
                              customClass: {
                                container: 'swalContainer'
                              }
                            })
                          }
                        }}
                      >
                        <Settings />
                      </IconButton>
                      )
                    : ''
                }
                {
                  hasReleaseNotes
                    ? (
                      <IconButton
                        aria-label='Release Notes'
                        title='Release Notes'
                        size="small"
                        onClick={e => {
                          e.stopPropagation()
                          openReleaseNotesDialog(version.releaseNotes)
                        }}
                      >
                        <Info />
                      </IconButton>
                      )
                    : ''
                }
              </Grid>
            </Grid>
          )
          // console.log('Module: ', { url, filename })
          rowData.source = filename || 'N/A'
          rowData.actions = actions
          rowData.actionstring = ReactDOMServer.renderToString(actions)
        } else {
          rowData.source = 'N/A'
          rowData.actions = actions
          rowData.actionstring = ReactDOMServer.renderToString(actions)
        }
        return rowData
      })
      adFuelModules.sort(byPriorityAndName)
      setSiteAdFuelModules(adFuelModules)
    }
  }, [siteData])

  useEffect(() => {
    if (siteData._id) {
      setAisUrlWithCacheBuster(aisUrl + `?cacheBuster=${cacheBuster()}`)
    }
  }, [clickCount])

  const getAISFilename = () => `${siteNameFormatter(siteData.name)}-ais.js`

  const openReleaseNotesDialog = async (releaseNotes) => {
    const onClose = () => {
      setOpenDialog(false)
    }
    setDialog({
      content: (
        <Card>
          <CardContent dangerouslySetInnerHTML={{ __html: mdParser.render(releaseNotes) }} />
        </Card>
      ),
      displayDialogAction: false,
      onClose
    })
    setOpenDialog(true)
  }

  const openConfigurationDialog = async (moduleId, moduleName) => {
    const setVersion = (siteModuleId, versionId, versionName) => {
      const newSite = { ...siteData }
      newSite.adFuelModules = siteData.adFuelModules.map(module => {
        if (module.adFuelModuleId === siteModuleId) { 
          module.adFuelModuleVersionId = versionId
          module.version = versionName
        }
        return module
      })
      setSiteData(newSite)
    }

    const onClose = () => {
      if (configurableModules.indexOf(moduleName) >= 0) {
        swal.fire({
          title: 'Are you sure?',
          text: 'Changes will not be saved.',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No, Do Not Cancel.',
          confirmButtonText: 'Yes, Discard my changes.',
          customClass: {
            container: 'swal2-container'
          },
          style: { zIndex: 99999 }
          // buttons: true,
          // dangerMode: true
        }).then(ok => {
          // console.log('Cancel response: ', ok)
          if (ok.isConfirmed) { setOpenDialog(false) }
        })
      } else {
        setOpenDialog(false)
      }
    }
    try {
      const { data: options } = await getAdFuelModuleConfigurations(siteData._id)
      let response = { data: [] }
      if (configurableModules.indexOf(moduleName) >= 0) {
        response = await getAdFuelModuleConfiguration(siteData._id, moduleId)
        const core = await getAdFuelModuleCoreConfiguration()
        // console.log('Core Options: ', core)
        const mergedData = { ...core, ...options }
        if (moduleName) {
          // console.log('Configuration Data: ', response.data)
          setDialog({
            content: (<AdFuelOptions allData={mergedData} module={getModule(moduleId)} moduleId={moduleId} moduleName={moduleName} data={response.data} site={site} setOpenDialog={setOpenDialog} onClose={onClose} setVersion={setVersion} />),
            displayDialogAction: false,
            size: 'xl',
            onClose
          })
          setOpenDialog(true)
        } else {
          swal.fire({
            title: 'Error',
            text: `Options not found -- Site: ${siteData._id} -- Module: ${moduleId} --`,
            icon: 'error',
            customClass: {
              container: 'swalContainer'
            }
          })
        }
      }
    } catch (err) {
      swal.fire({
        title: 'Error',
        text: err,
        icon: 'error',
        customClass: {
          container: 'swalContainer'
        }
      })
    }
  }

  const deleteModule = async moduleId => {
    console.log('[ModuleConfigurationBeta] ---------- deleteModule::Deleting Module ID: ', moduleId)
    if (moduleId === undefined) {
      const index = adFuelModules.findIndex(x => x.adFuelModuleId === undefined)
      console.log('[ModuleConfigurationBeta] ---------- deleteModule::index', index)
      if (index !== -1) {
        adFuelModules.splice(index, 1)
        console.log('[ModuleConfigurationBeta] ---------- deleteModule::setting Site AdFuel Modules', adFuelModules.filter(module => module.adFuelModuleId !== moduleId))
        setSiteAdFuelModules(adFuelModules)
        console.log('[ModuleConfigurationBeta] ---------- deleteModule::setting Site Data', {
          ...siteData,
          adFuelModules: adFuelModules
        })
        setSiteData({
          ...siteData,
          adFuelModules: adFuelModules
        })
      }
    } else {
      console.log('[ModuleConfigurationBeta] ---------- deleteModule::setting Site AdFuel Modules', adFuelModules.filter(module => module.adFuelModuleId !== moduleId))
      setSiteAdFuelModules(adFuelModules.filter(
        module => module.adFuelModuleId !== moduleId
      ))
      console.log('[ModuleConfigurationBeta] ---------- deleteModule::setting Site Data!', {
        ...siteData,
        adFuelModules: adFuelModules.filter(
          module => module.adFuelModuleId !== moduleId
        )
      })
      setSiteData({
        ...siteData,
        adFuelModules: adFuelModules.filter(
          module => module.adFuelModuleId !== moduleId
        )
      })
    }
  }

  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3)} titleTypographyProps={{ variant: 'h6' }} title={`Module Configuration`}/>
      <CardContent>
        {!loading
          ? (
            <Grid container spacing={2}>
              <Grid item container xs={12} style={{border: 'solid 2px #999', borderRadius: '10px', padding: '10px'}}>
                <Grid item xs={6}>
                  <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Digital Ads Module Package File URL:</label>
                  <Typography className={classes.link}>
                    <Link
                      className={addClasses(commonClasses.linkColor, classes.links)}
                      href={aisUrlWithCacheBuster}
                      rel='noreferrer'
                      target='_blank'
                      onClick={() => {
                        setClickCount(clickCount + 1)
                      }}
                    >
                      {aisUrl.length > 60 
                        ? (aisUrl.substring(0,30) + '[...]' + aisUrl.substring(aisUrl.length-25)) 
                        : (aisUrl)
                      }
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Tooltip title='View'>
                    <IconButton
                      size='small'
                      color='primary'
                      style={{fontSize: '1em'}}
                      className={commonClasses.linkColor}
                      aria-label='view'
                      onClick={async () => {
                        try {
                          const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                          console.log('err', jsFile)
                          openViewDialog(jsFile)
                        } catch (err) {
                        // console.log('err', err)
                        }
                      }}
                    >
                      <Launch />&nbsp;&nbsp;<span>Open File</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs>
                  <Tooltip title='Download'>
                    <IconButton
                      size='small'
                      color='primary'
                      style={{fontSize: '1em'}}
                      className={commonClasses.linkColor}
                      aria-label='download'
                      onClick={async () => {
                        try {
                          const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                          const file = new window.Blob([jsFile], { type: 'text/javascript' })
                          const link = document.createElement('a')
                          link.download = getAISFilename()
                          link.href = URL.createObjectURL(file)
                          link.click()
                        } catch (err) {
                        // console.log('err', err)
                        }
                      }}
                    >
                      <GetApp />&nbsp;&nbsp;<span>Download File</span>
                    </IconButton>
                  </Tooltip>
                </Grid>                
                <Grid item xs>
                  <IconButton
                    size='small'
                    color='primary'
                    className={commonClasses.linkColor}
                    style={{fontSize: '1em'}}
                    aria-label='download'
                    onClick={async () => {
                      try {
                        const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                        const file = new window.Blob([jsFile], { type: 'text/javascript' })
                        const link = document.createElement('a')
                        link.download = getAISFilename()
                        link.href = URL.createObjectURL(file)
                        link.click()
                      } catch (err) {
                      // console.log('err', err)
                      }
                    }}
                  >
                    <TableIcons.Add />&nbsp;&nbsp;<span>Add Module</span>
                  </IconButton>                
                </Grid>
              </Grid>
              <Grid item xs={12}>
                { siteAdFuelModules 
                  ? (
                    <CustomTable data={siteAdFuelModules} paginate={false} onRowClick={(event, rowName) => {
                      console.log('[ModuleConfigurationBeta] ---------- event/rowName: ', { eventTarget: event.target, event: {...event}, rowName })
                      event.preventDefault();
                      event.stopPropagation();
                    }} />
                  )
                  : ('')
                }
              </Grid>
            </Grid>
            )
          : (<LinearProgress />)}
        <CustomDialog
          open={openDialog}
          setOpenDialog={setOpenDialog}
          title={dialog.title}
          subtitle={dialog.subtitle}
          content={dialog.content}
          displayDialogAction={dialog.displayDialogAction}
          customAction={dialog.customAction}
          size={dialog.size}
          handleOnClose={dialog.onClose}
        />
      </CardContent>
      <CardActions>
        <Grid container spacing={1} justifyContent='flex-end'>
          <Grid item>
            <Button
              className={commonClasses.btnSuccess}
              variant='contained'
              disabled={updatingSiteAdFuelModules || !canEditSite}
              startIcon={<Build />}
              onClick={() => {
                const saveAndBuild = () => {
                  const adFuelModules = [...siteData.adFuelModules].sort(byPriorityAndName)
                  siteData.adFuelModules.sort(byPriorityAndName)
                  setSiteData({ ...siteData })
                  dispatch(updateSiteAdFuelModules(siteData))
                }
                if (!isChanged) {
                  swal.fire({
                    title: 'No changes were made to the list of modules included in AIS.',
                    text: 'Do you still want to rebuild and publish AIS?',
                    icon: 'info',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCancelButton: true
                  }).then(ok => {
                    if (ok.isConfirmed) { saveAndBuild() }
                  })
                } else {
                  saveAndBuild()
                }
              }}
            >
              {updatingSiteAdFuelModules ? 'Saving...' : 'Save & Build'}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

export default ModuleConfiguration