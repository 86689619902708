import React from 'react'
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'inline-block'
  },
  textfield: {
    margin: theme.spacing(1),
    width: '95%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  inline: {
    display: 'inline-block'
  }
}))

export default function Dropdown (props) {
  const { data } = props
  const { style, label, items, value, itemKey, itemValKey, handleChange, variant } = data
  const classes = useStyles()
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  // console.table(props)
  // console.table(data)
  return (
    <>
      <FormControl variant={variant} className={classes.formControl}>
        <InputLabel ref={inputLabel} id={`select-${label}-label`}>
          {label}
        </InputLabel>
        <Select
          labelId={'select-' + label}
          value={value}
          onChange={handleChange}
          labelWidth={labelWidth}
          style={style}
        >
          {items.map((item, index) => {
            return (
              <MenuItem value={item[!itemValKey ? itemKey : itemValKey]} key={index}>
                {item[itemKey]}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

Dropdown.propTypes = {
  header: PropTypes.shape({
    classes: PropTypes.string,
    text: PropTypes.string
  }),
  items: PropTypes.array,
  value: PropTypes.string,
  itemKey: PropTypes.string,
  variant: PropTypes.string,
  handleChange: PropTypes.func
}
