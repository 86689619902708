import React from 'react'
import {
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    margin: '8px'
  }
}))

export default function LabeledRadioGroup (props) {
  const { data } = props
  const { label, value, name, radioButtons } = data
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>{label}</Typography>
      <RadioGroup defaultValue={value} aria-label={'radio-group-' + label} name={name}>
        {radioButtons.map((radioButton, index) => {
          return (
            <FormControlLabel
              key={index}
              value={radioButton.value}
              label={radioButton.label}
              labelPlacement={radioButton.labelPlacement ? radioButton.labelPlacement : 'end'}
              control={<Radio color={radioButton.color ? radioButton.color : 'primary'} />}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}

LabeledRadioGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  radioButtons: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    labelPlacement: PropTypes.string,
    color: PropTypes.string
  }))
}
