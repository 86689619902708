import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { useSelector, useDispatch } from 'react-redux'
import { faker } from '@faker-js/faker'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { TableIcons } from '../../ui/TableIcons'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, Tooltip, Grid, Card, CardContent, CardActions, IconButton, Select, MenuItem, LinearProgress, Link, Box, Button, makeStyles } from '@material-ui/core'
import { CustomDialog } from '../../ui'
import { addClasses, commonStyle } from '../../../styles'
import { clone, siteNameFormatter, cacheBuster } from '../../../utilities/helperFunctions.js'
import { fetchAdFuelModules } from '../../../modules/adFuelModuleSlice'
import { fetchSiteByName } from '../../../modules/sitesSlice'
import { getAdFuelModuleConfiguration, getAdFuelModuleConfigurations, getAdFuelModuleCoreConfiguration } from '../../../modules/lookupsSlice'
import Launch from '@material-ui/icons/Launch'
import GetApp from '@material-ui/icons/GetApp'
import Delete from '@material-ui/icons/Delete'
import Settings from '@material-ui/icons/Settings'
import Info from '@material-ui/icons/Info'
import Build from '@material-ui/icons/Build'
import { AdFuelOptions } from '../index.js'
import semver from 'semver'
import MarkdownIt from 'markdown-it'
import { getPath } from '../../../modules/configSlice'
import PropTypes from 'prop-types';
import AddBoxIcon from '@material-ui/icons/AddBox'

const swal = withReactContent(Swal)

const useStyles = makeStyles(theme => ({
  footerActionArea: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  iconButtons: {
    marginLeft: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  dflex: {
    display: 'flex'
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeaderCell: {
    background: '#777',
    color: 'white',
    fontWeight: 'bolder',
  },
}))

const cellStyle = {
  padding: '0 5px'
}

const centeredCellStyle = {
  padding: '0 5px',
  textAlign: 'center',
  justify: 'middle'
}

const buildFakeAdFuelModule = (version) => {

  const adFuelModuleId = faker.random.alphaNumeric(24)
  const adFuelModule2xVersionId = faker.random.alphaNumeric(24)
  const adFuelModule3xVersionId = faker.random.alphaNumeric(24)
  const adFuelModule2xVersion = faker.system.semver()
  const adFuelModule3xVersion = faker.system.semver()
  const adFuelModuleName = faker.random.numeric() % 2 ? faker.company.name() : faker.name.jobTitle()
  const adFuelModuleDescription = 'A Meaningless Module'
  const adFuelModuleFilename = faker.system.fileName()
  const adFuelModuleUrl = '/ads/' + adFuelModuleFilename + '.js'
  const adFuelModule2xVersionUrl = adFuelModuleFilename + '-' + adFuelModule2xVersion + '.js'
  const adFuelModule3xVersionUrl = adFuelModuleFilename + '-' + adFuelModule3xVersion + '.js'
  const adFuelModulePriority = faker.random.numeric(2)

  const adFuelVersions = {
    'two': '5e7127a2c5a4700014428c99',
    'three': '62e16afbe2ef22001ad74bf1'
  }

  const adFuelModule = {
    _id: adFuelModuleId,
    name: adFuelModuleName,
    description: adFuelModuleDescription,
    url: faker.internet.url() + adFuelModuleUrl,
    versions: [
      {
        _id: adFuelModule2xVersionId,
        name: adFuelModule2xVersion,
        url: adFuelModule2xVersionUrl,
        adFuelVersionId: adFuelVersions.two
      },
      {
        _id: adFuelModule3xVersionId,
        name: adFuelModule3xVersion,
        url: adFuelModule3xVersionUrl,
        adFuelVersionId: adFuelVersions.three
      }
    ],
    arguments: [],
    priority: adFuelModulePriority,
  }
  const siteAdFuelModule = {
    adFuelModuleId: adFuelModuleId,
    adFuelModuleVersionId: version ? version : adFuelVersions.two,
    name: adFuelModuleName,
    version: adFuelModule2xVersion,
    priority: adFuelModulePriority
  }

  return { system: adFuelModule, site: siteAdFuelModule }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  if (array) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return []
}

function CustomTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useStyles()
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Module Name',
      sortable: false,
    },
    {
      id: 'version',
      numeric: false,
      disablePadding: false,
      label: 'Version',
      sortable: false
    },
    {
      id: 'priority',
      numeric: false,
      disablePadding: false,
      label: 'Priority',
      sortable: false,
    },              
    {
      id: 'source',
      numeric: false,
      disablePadding: false,
      label: 'Source',      
      sortable: false,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
      sortable: false
    }
  ]
  return (
    <TableHead>
      <TableRow >
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeaderCell}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ): (<div>{headCell.label}</div>)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ModuleConfigurationLite = ({ site, canEditSite, isAdFuelMinified, adFuelVersionId, setHaveModulesChanged, hasAdFuelChanged, doUpdateSiteAdFuelModules }) => {
  const classes = useStyles()
  const commonClasses = commonStyle()
  const { updatingSiteAdFuelModules } = useSelector(state => state.sitesSlice)
  const { adFuelModules: allAdFuelModulesSlice } = useSelector(state => state.adFuelModulesSlice)
  const [allAdFuelModules, setAllAdFuelModules] = useState(allAdFuelModulesSlice)
  const [siteData, setSiteData] = useState(clone(site))
  const [siteAdFuelModules, setSiteAdFuelModules] = useState(site.adFuelModules)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [loading, setLoading] = useState(true)
  const [addingNewModule, setAddingNewModule] = useState(false)
  let { aisUrl, adFuelModules } = site
  const [aisUrlWithCacheBuster, setAisUrlWithCacheBuster] = useState(aisUrl)
  const [clickCount, setClickCount] = useState(-1)
  const dispatch = useDispatch()
  const [url, setUrl] = useState(site.aisUrl)
  const [publishers, setPublishers] = useState()
  const { pathConfig } = useSelector(state => state.configSlice)
  const [isLoading, setIsLoading] = useState(false)

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);

  const [chosenModule, setChosenModule] = useState()
  const [chosenVersion, setChosenVersion] = useState()

  const mdParser = new MarkdownIt(/* Markdown-it options */)

  const getModule = adFuelModuleId => {
    const module = allAdFuelModules.find(item => item._id === adFuelModuleId)
    return !module ? '' : module
  }
  const byPriorityAndName = (a, b) => {
    if (a.adFuelModuleId && b.adFuelModuleId) {
      const moduleA = getModule(a.adFuelModuleId)
      const moduleB = getModule(b.adFuelModuleId)
      if (moduleA && moduleB) {
        const nameA = moduleA.name.toUpperCase()
        const nameB = moduleB.name.toUpperCase()
        const prioA = moduleA.priority !== null ? parseInt(moduleA.priority || 0, 10) : 0
        const prioB = moduleB.priority !== null ? parseInt(moduleB.priority || 0, 10) : 0
        try {
          if (prioA > prioB) {
            return -1
          }
          if (prioA < prioB) {
            return 1
          }
          if (prioA === prioB) {
            if (nameA > nameB) {
              return 1
            }
            if (nameA < nameB) {
              return -1
            }
          }
          return 0
        } catch (err) {
          return 0
        }
      }
    }
  }

  const buildUrl = (siteName, publishers) => {
    // console.log('Building URL: ', { name, value, publishers })
    if (!publishers) return
    const fileName =
      siteName?.toLowerCase() || 'core' +
      '-ais' +
      (isAdFuelMinified ? '.min' : '') +
      '.js'
    // const adFuelUrl = (isAdFuelLocationSiteSpecific ? rootUrl.replace('/adfuel', `/${site.name.toLowerCase()}`) : rootUrl) + '/' + fileName
    const adFuelUrl = publishers?.rootAdFuelUrl?.replace('/adfuel', '/' + site?.name?.toLowerCase() || 'core') + '/' + fileName

    setUrl(adFuelUrl)
  }

  const initialAdFuelModule = {
    name: '',
    description: '',
    url: '',
    versions: [],
    arguments: []
  }

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      setPublishers(pathConfig.publisher)
      buildUrl(site.name, pathConfig.publisher)
    } else {
      dispatch(getPath())
    }
  }, [pathConfig])

  useEffect(() => {
    console.log('[ModuleConfigurationLite] ---------- useEffect::isAdFuelMinified', isAdFuelMinified)
    buildUrl(site.name, publishers)
  }, [isAdFuelMinified])

  useEffect(() => {
    if (allAdFuelModules.length === 0) {
      console.log('[AISConfigurationBeta] ---------- No modules... fetching AdFuel Modules...')
      dispatch(fetchAdFuelModules())
    }
    setAisUrlWithCacheBuster(url + `?cacheBuster=${cacheBuster()}`)
  }, [url])

  useEffect(() => {
    buildUrl(site.name, publishers)
  }, [publishers])

  useEffect(() => {
    if (allAdFuelModules && adFuelModules && siteAdFuelModules && allAdFuelModules.length > 0 && site.adFuelModules.length >= 0 && (siteAdFuelModules.length === 0 || !siteAdFuelModules[0].action)) {
      console.log('Site: ', site)
      console.log('AdFuel Modules: ', adFuelModules)
      try{
        adFuelModules = site.adFuelModules.map(rowData => {
          const { adFuelModuleId, adFuelModuleVersionId } = rowData
          const module = getModule(adFuelModuleId)
          let actions = (<span></span>)
          if (module) {
            const version = module?.versions.find(item => item._id === adFuelModuleVersionId)
            const url = version ? `https://i.cdn.turner.com${version.url}` : module.url
            const filename = url.split('/')[url.split('/').length - 1]
            const key = module.configurationKey
            const hasReleaseNotes = (version && version.releaseNotes && version.releaseNotes !== null) || false
            actions = (
              <Grid container>
                <Grid className={classes.dflex} item>
                  <IconButton
                    aria-label='Delete Module'
                    title='Delete Module'
                    size="small"
                    disabled={!canEditSite || hasAdFuelChanged}
                    onClick={(event) => {
                      console.log('hasAdFuelChanged: ', hasAdFuelChanged)
                      // if (!hasAdFuelChanged) removeModule(event, module)
                    }}
                  >
                    <Delete className={commonClasses.deleteIconColor} />
                  </IconButton>
                  {
                    module.isConfigurable
                      ? (
                        <IconButton
                          aria-label='Configure Module'
                          title='Configure Module'
                          size="small"
                          disabled={!canEditSite || hasAdFuelChanged}
                          onClick={() => {
                            if (canEditSite) {
                              openConfigurationDialog(module._id, key)
                            } else {
                              swal.fire({
                                title: 'UNAUTHORIZED',
                                text: 'You do not have permissions to do this.',
                                icon: 'error',
                                dangerMode: false,
                                customClass: {
                                  container: 'swalContainer'
                                }
                              })
                            }
                          }}
                        >
                          <Settings className={commonClasses.infoIconColor} />
                        </IconButton>
                        )
                      : ''
                  }
                  {
                    hasReleaseNotes
                      ? (
                        <IconButton
                          aria-label='Release Notes'
                          title='Release Notes'
                          size="small"
                          onClick={e => {
                            e.stopPropagation()
                            openReleaseNotesDialog(version.releaseNotes)
                          }}
                        >
                          <Info className={commonClasses.infoIconColor} />
                        </IconButton>
                        )
                      : ''
                  }
                </Grid>
              </Grid>
            )
            // console.log('Module: ', { url, filename })
            rowData.source = filename || 'N/A'
            rowData.actions = actions
          } else {
            rowData.source = 'N/A'
            rowData.actions = actions
          }
          return rowData
        })
        adFuelModules.sort(byPriorityAndName)
      } catch(err) {
        console.log('[AISConfigurationBeta] ---------- useEffect::Error generating rowData:', err)
      }
      console.log('[AISConfigurationBeta] ---------- useEffect::Setting siteAdFuelModules:', { allAdFuelModules: allAdFuelModules, adFuelModules: adFuelModules })    
      setSiteAdFuelModules(adFuelModules)
      setLoading(false)
    } else if (siteAdFuelModules && siteAdFuelModules.length > 0 && !siteAdFuelModules[0].action) {
      console.log('[AISConfigurationBeta] ---------- useEffect::Modules Changed But Did Not Meet Requirements:', { allAdFuelModules: allAdFuelModules.length, adFuelModules: adFuelModules.length, siteAdFuelModules: siteAdFuelModules.length })
    }
  }, [allAdFuelModulesSlice, adFuelModules])

  useEffect(() => {    
    // const oldAdFuelModules = siteAdFuelModules?.map(item => item.name) || []
    // const newAdFuelModules = siteData.adFuelModules?.map(item => item.name) || []
    // let hC = false
    // oldAdFuelModules.sort((a,b) => { 
    //   if (a > b) return 1
    //   if (b > a) return -1
    //   return 0
    // })
    // newAdFuelModules.sort((a,b) => { 
    //   if (a > b) return 1
    //   if (b > a) return -1
    //   return 0
    // })
    // oldAdFuelModules.forEach((item, index) => {
    //   if (item !== newAdFuelModules[index]) hC = true
    // })
    // setHaveModulesChanged(hC)    
  }, [siteData])

  useEffect(() => {
    if (siteData.aisUrl && siteData.adFuelModules) {
      aisUrl = siteData.aisUrl
      aisUrl = isAdFuelMinified ? aisUrl.replace('.min.js', '.js').replace('.js','.min.js') : aisUrl.replace('.min.js','.js')
      adFuelModules = siteData.adFuelModules.map(rowData => {
        const { adFuelModuleId, adFuelModuleVersionId } = rowData
        const module = getModule(adFuelModuleId)
        let actions = (<span></span>)
        if (module) {
          const version = module?.versions.find(item => item._id === adFuelModuleVersionId)
          const url = version ? `https://i.cdn.turner.com${version.url}` : module.url
          const filename = url.split('/')[url.split('/').length - 1]
          const key = module.configurationKey
          const hasReleaseNotes = (version && version.releaseNotes && version.releaseNotes !== null) || false
          actions = (
            <Grid container>
              <Grid className={classes.dflex} item>
                <IconButton
                  aria-label='Delete Module'
                  title='Delete Module'
                  size="small"
                  className={commonClasses.deleteIconColor}
                  disabled={!canEditSite || hasAdFuelChanged}                  
                  onClick={(e) => {
                    console.log('hasAdFuelChanged: ', hasAdFuelChanged)
                    if (!hasAdFuelChanged) {
                      removeModule(e, module)
                    }
                  }}
                >
                  <Delete  />
                </IconButton>
                {
                  module.isConfigurable
                    ? (
                      <IconButton
                        aria-label='Configure Module'
                        title='Configure Module'
                        size="small"
                        onClick={() => {
                          if (canEditSite) {
                            openConfigurationDialog(module._id, key)
                          } else {
                            swal.fire({
                              title: 'UNAUTHORIZED',
                              text: 'You do not have permissions to do this.',
                              icon: 'error',
                              dangerMode: false,
                              customClass: {
                                container: 'swalContainer'
                              }
                            })
                          }
                        }}
                      >
                        <Settings />
                      </IconButton>
                      )
                    : ''
                }
                {
                  hasReleaseNotes
                    ? (
                      <IconButton
                        aria-label='Release Notes'
                        title='Release Notes'
                        size="small"
                        onClick={e => {
                          e.stopPropagation()
                          openReleaseNotesDialog(version.releaseNotes)
                        }}
                      >
                        <Info />
                      </IconButton>
                      )
                    : ''
                }
              </Grid>
            </Grid>
          )
          // console.log('Module: ', { url, filename })
          rowData.source = filename || 'N/A'
          rowData.actions = actions
          rowData.actionstring = ReactDOMServer.renderToString(actions)
        } else {
          rowData.source = 'N/A'
          rowData.actions = actions
          rowData.actionstring = ReactDOMServer.renderToString(actions)
        }
        return rowData
      })
      adFuelModules.sort(byPriorityAndName)
      setSiteAdFuelModules(adFuelModules)
    }
  }, [siteData])

  useEffect(() => {
    if (siteData._id) {
      setAisUrlWithCacheBuster(aisUrl + `?cacheBuster=${cacheBuster()}`)
    }
  }, [clickCount])

  useEffect(() => {
    setClickCount(0)
    setSiteData(site)
    setLoading(false)
  },[site])

  const fetchByName = () => {
    const urlFragments = window.location.pathname.split('/')
    let siteName = urlFragments[urlFragments.length-1]
    if (siteName) {
      console.log('[ModuleConfigurationLite] ---------- fetchByName::Fetching Site: ', siteName)
      setIsLoading(true)
      fetchSiteByName(siteName)
      setTimeout(() => {
        console.log('[ModuleConfigurationLite] ---------- fetchByName::Fetching Site Fallback Timeout: ', siteAdFuelModules)
        setIsLoading(false)
      }, 750)      
    } else {
      console.log('[ModuleConfigurationLite] ---------- fetchByName::No Sitename: ', urlFragments)
    }
  }

  const getAISFilename = () => `${siteNameFormatter(siteData.name)}-ais.js`

  const openReleaseNotesDialog = async (releaseNotes) => {
    const onClose = () => {
      setOpenDialog(false)
    }
    setDialog({
      content: (
        <Card>
          <CardContent dangerouslySetInnerHTML={{ __html: mdParser.render(releaseNotes) }} />
        </Card>
      ),
      displayDialogAction: false,
      onClose
    })
    setOpenDialog(true)
  }

  const openConfigurationDialog = async (moduleId, moduleName) => {
    const setVersion = (siteModuleId, versionId, versionName) => {
      const newSite = { ...siteData }
      newSite.adFuelModules = siteData.adFuelModules.map(module => {
        if (module.adFuelModuleId === siteModuleId) { 
          module.adFuelModuleVersionId = versionId
          module.version = versionName
        }
        return module
      })
      setSiteData(newSite)
    }

    const onClose = () => {
      swal.fire({
        title: 'Are you sure?',
        text: 'Changes will not be saved.',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'No, Do Not Cancel.',
        confirmButtonText: 'Yes, Discard my changes.',
        customClass: {
          container: 'swal2-container'
        },
        style: { zIndex: 99999 }
        // buttons: true,
        // dangerMode: true
      }).then(ok => {
        // console.log('Cancel response: ', ok)
        if (ok.isConfirmed) { setOpenDialog(false) }
      })
    }
    try {
      const { data: options } = await getAdFuelModuleConfigurations(siteData._id)
      let response = { data: [] }
      response = await getAdFuelModuleConfiguration(siteData._id, moduleId)
      const core = await getAdFuelModuleCoreConfiguration()
      // console.log('Core Options: ', core)
      const mergedData = { ...core, ...options }
      if (moduleName) {
        // console.log('Configuration Data: ', response.data)
        setDialog({
          content: (<AdFuelOptions allData={mergedData} module={getModule(moduleId)} moduleId={moduleId} moduleName={moduleName} data={response.data} site={site} setOpenDialog={setOpenDialog} onClose={onClose} setVersion={setVersion} />),
          displayDialogAction: false,
          size: 'xl',
          onClose
        })
        setOpenDialog(true)
      } else {
        swal.fire({
          title: 'Error',
          text: `Options not found -- Site: ${siteData._id} -- Module: ${moduleId} --`,
          icon: 'error',
          customClass: {
            container: 'swalContainer'
          }
        })
      }
      
    } catch (err) {
      swal.fire({
        title: 'Error',
        text: err,
        icon: 'error',
        customClass: {
          container: 'swalContainer'
        }
      })
    }
  }

  const deleteModule = async moduleId => {
    console.log('[ModuleConfigurationBeta] ---------- deleteModule::Deleting Module ID: ', moduleId)
    if (moduleId === undefined) {
      const index = siteAdFuelModules.findIndex(x => x.adFuelModuleId === undefined)
      console.log('[ModuleConfigurationBeta] ---------- deleteModule::index', index)
      if (index !== -1) {
        let tempAdFuelModules = [...clone(siteAdFuelModules).splice(index, 1)]
        console.log('[ModuleConfigurationBeta] ---------- deleteModule::setting Site AdFuel Modules', adFuelModules.filter(module => module.adFuelModuleId !== moduleId))
        setSiteAdFuelModules(tempAdFuelModules)
        console.log('[ModuleConfigurationBeta] ---------- deleteModule::setting Site Data', {
          ...siteData,
          adFuelModules: tempAdFuelModules
        })
        // setSiteData({
        //   ...siteData,
        //   adFuelModules: tempAdFuelModules
        // })
      }
    } else {
      console.log('[ModuleConfigurationBeta] ---------- deleteModule::setting Site AdFuel Modules', adFuelModules.filter(module => module.adFuelModuleId !== moduleId))
      setSiteAdFuelModules(siteAdFuelModules.filter(
        module => module.adFuelModuleId !== moduleId
      ))
      console.log('[ModuleConfigurationBeta] ---------- deleteModule::setting Site Data!', {
        ...siteData,
        adFuelModules: siteAdFuelModules.filter(
          module => module.adFuelModuleId !== moduleId
        )
      })
      // setSiteData({
      //   ...siteData,
      //   adFuelModules: siteAdFuelModules.filter(
      //     module => module.adFuelModuleId !== moduleId
      //   )
      // })
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const ModuleNameDropdown = () => {
    let filteredSiteModules = siteAdFuelModules.map(item => { return { _id: item.adFuelModuleId, name: item.name } })
    let filteredAllModules = allAdFuelModules.filter(item => item.adFuelVersionId === null || typeof item.adFuelVersionId === 'undefined').map(item => { return { _id: item._id, name: item.name } })
    let availableModules = filteredAllModules.filter(item => {
      let moduleNames = filteredSiteModules.map(item => item.name)
      return moduleNames.indexOf(item.name) < 0
    })
    console.log('All: ', filteredAllModules)
    console.log('Site: ', filteredSiteModules)
    console.log('Available: ', availableModules)
    return (
      <>
        <Select
          fullWidth
          disabled={!canEditSite}
          value={chosenModule}
          onChange={event => {            
            setChosenModule(event.target.value)
          }}
        >
          <MenuItem key={'NONE'} value={'NONE'}></MenuItem>
          {availableModules.map(item => (
            <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
          ))}
        </Select>
      </>
    ) 
  }
  
  const ModuleVersionDropdown = (rowData, index) => {
    const { adFuelModuleId, adFuelModuleVersionId } = rowData
    const module = getModule(adFuelModuleId)
    // console.log('[ModuleConfigurationLite] ---------- ModuleVersionDropdown::adFuelModuleVersionId: ', adFuelModuleVersionId)
    // console.log('[ModuleConfigurationLite] ---------- ModuleVersionDropdown::module: ', module)
    let render = 'N/A'
    let updateAvailable = true
    let versionSet = false

    if (module) {
      const newModule = JSON.parse(JSON.stringify({ ...module }))
      if (newModule.versions) {
        newModule.versions = newModule.versions.map(version => {
          const moduleVersionAdFuelVersionId = version.adFuelVersionId
          if (moduleVersionAdFuelVersionId) {
            return version
          }
          return null
        }).filter(item => item !== null && typeof item !== 'undefined')
        newModule.versions.sort((a, b) => {
          if (semver.gt(a.name, b.name)) return -1
          if (semver.gt(b.name, a.name)) return 1
          return 0
        })
      }
      const latestVer = newModule.versions.length > 0 ? newModule.versions[0] : 'Unknown'
      let _adFuelModuleVersionId = adFuelModuleVersionId
      if (!adFuelModuleVersionId) { _adFuelModuleVersionId = latestVer._id }
      const version = newModule.versions.filter(version => version._id === _adFuelModuleVersionId)[0]?.name || 'No Version Set'
      versionSet = version !== 'No Version Set'
      if (_adFuelModuleVersionId === latestVer._id) updateAvailable = false
      const style = {
        textAlign: 'center'
      }
      if (!versionSet) style.color = 'red'
      if (newModule) {
      // const version = module.versions.find(item => item._id === adFuelModuleId)
        render = (
          <div style={style}>
            {version}
            {
              updateAvailable && versionSet 
                ? (
                  <div className={commonClasses.updateAvailable} style={{ fontSize: '.8em' }}>
                    Update Available!
                  </div>
                ) 
                : ''
            }
          </div>
        )
      }
      if (canEditSite) {
        render = (
          <>
            <Select
              fullWidth
              disabled={!canEditSite || hasAdFuelChanged}
              value={siteAdFuelModules[index]?.adFuelModuleVersionId}
              onChange={event => {
                const moduleVersion= clone(event.target.value) 
                console.log('[ModuleConfigurationLite] ---------- ModuleVersionDropdown::Select::onChange - Updated Module Version: ', moduleVersion)
                console.log('[ModuleConfigurationLite] ---------- ModuleVersionDropdown::Select::onChange - AdFuelModule at Index: ', siteAdFuelModules[index])
                let newSiteAdFuelModules = [ ...siteAdFuelModules ]
                newSiteAdFuelModules[index].adFuelModuleVersionId = moduleVersion
                newSiteAdFuelModules[index].version = newModule?.versions.map(item => {
                  console.log('[ModuleConfigurationLite] ---------- ModuleVersionDropdown::Select::onChange::newModule.versions.map.item.name: ', { item: item, module: moduleVersion })
                  return item._id === moduleVersion ? item.name : null
                }).filter(item => item !== null)[0]
                newSiteAdFuelModules[index].source = newModule?.versions.map(item => {
                  console.log('[ModuleConfigurationLite] ---------- ModuleVersionDropdown::Select::onChange::newModule.versions.map.item.source: ', { item: item, module: moduleVersion })
                  return item._id === moduleVersion ? item.url : null
                }).filter(item => item !== null)[0]
                // newSiteAdFuelModules.map(item => {
                //   delete item.actions
                //   return item
                // })
                console.log('[ModuleConfigurationLite] ---------- ModuleVersionDropdown::Select::onChange - Comparison: ', { old: siteAdFuelModules[index], new: newSiteAdFuelModules[index] })
                // dispatch(updateSiteAdFuelModules({ ...site, adFuelModules }))
                console.log('[ModuleConfigurationLite] ---------- ModuleVersionDropdown::Select::onChange - Setting Site AdFuel Modules: ', newSiteAdFuelModules)
                setSiteAdFuelModules(newSiteAdFuelModules)
                setHaveModulesChanged(true)
              }}
            >
              {/* <MenuItem key={'latest'} value={module.versions[0]._id}>Latest</MenuItem> */}
              {newModule?.versions.map((item, index) => {
                return (<MenuItem key={index} value={item._id}>{item.name}</MenuItem>)
              })}
            </Select>
            {updateAvailable && versionSet ? (<div className={commonClasses.updateAvailable} style={{ textAlign: 'center', fontSize: '.8em' }}>Update Available!</div>) : ''}
            {
              !versionSet 
                ? (
                  <div style={{ textAlign: 'center', fontSize: '.8em', color: 'red' }}>
                    No Version Set.
                  </div>
                ) 
                : ''
            }
          </>
        )
      }
    } 
    return render
  }

  const newModuleRow = () => {

    let actions = (
      <Grid container>
        <Grid className={classes.dflex} item>
          <IconButton
            aria-label='Delete Module'
            title='Delete Module'
            size="small"
            onClick={() => {
              setChosenModule(null)
              setChosenVersion(null)
              setAddingNewModule(false)
            }}
          >
            <Delete className={commonClasses.deleteIconColor} />
          </IconButton>
          <IconButton
            aria-label='Add Module'
            title='Add Module'
            size="small"
            onClick={e => {
              e.stopPropagation()
              addModule()
            }}
          >
            <AddBoxIcon className={commonClasses.successIconColor} />
          </IconButton>
        </Grid>
      </Grid>
    )

    let cancel = (
      <Grid container>
        <Grid className={classes.dflex} item>
          <IconButton
            aria-label='Delete Module'
            title='Delete Module'
            size="small"
            onClick={() => {
              setChosenModule(null)
              setChosenVersion(null)
              setAddingNewModule(false)
            }}
          >
            <Delete className={commonClasses.deleteIconColor} />
          </IconButton>
        </Grid>
      </Grid>
    )

    return (
      <TableRow
        hover
        tabIndex={-1}
        key={'new-module'}
        // selected={isItemSelected}
      >
        <TableCell
          component="th"
          id={'new-module'}
          scope="row"
          padding="none"
        >
          {ModuleNameDropdown()}
        </TableCell>
        <TableCell align="left">
          { chosenModule && chosenModule !== 'NONE' ? NewModuleVersionDropdown({adFuelModuleId: chosenModule, adFuelModuleVersionId: chosenVersion}, -1) : 'DISABLED' }
        </TableCell>
        <TableCell align="left">
          { chosenModule && chosenModule !== 'NONE' ? NewModulePriority() : '' }
        </TableCell>
        <TableCell align="left">
          { chosenModule && chosenModule !== 'NONE' ? NewModuleFilename() : '' }
        </TableCell>
        <TableCell align="right">{/* dangerouslySetInnerHTML={{__html: row.actions}}>*/}
          { chosenModule && chosenModule !== 'NONE' && chosenVersion ? actions : cancel }
        </TableCell>
      </TableRow>
    )
  }

  const NewModuleVersionDropdown = (rowData, index) => {
    const { adFuelModuleId } = rowData
    const module = getModule(adFuelModuleId)
    let render = 'N/A'

    if (module) {
      const newModule = JSON.parse(JSON.stringify({ ...module }))
      if (newModule.versions) {
        newModule.versions = newModule.versions.map(version => {
          const moduleVersionAdFuelVersionId = version.adFuelVersionId
          if (moduleVersionAdFuelVersionId) {
            return version
          }
          return null
        }).filter(item => item !== null && typeof item !== 'undefined')
        newModule.versions.sort((a, b) => {
          if (semver.gt(a.name, b.name)) return -1
          if (semver.gt(b.name, a.name)) return 1
          return 0
        })
      }
      render = (
        <>
          <Select
            fullWidth
            disabled={!canEditSite}
            value={siteAdFuelModules[index]?.adFuelModuleVersionId}
            onChange={event => {
              setChosenVersion(event.target.value)
            }}
          >
            {newModule?.versions.map((item, index) => {
              return (<MenuItem key={index} value={item._id}>{item.name}</MenuItem>)
            })}
          </Select>
        </>
      )
    } 
    return render
  }

  const NewModuleFilename = () => {
    let fullModule = allAdFuelModules.filter(item => item._id === chosenModule)[0]
    let urlArray = fullModule.url.split('/')
    let fullFilename = fullModule.url
    let filename = urlArray[urlArray.length-1]
    let fullVersion
    if (chosenVersion) {
      fullVersion = fullModule.versions.filter(item => item._id === chosenVersion)[0]
      if (fullVersion) {
        fullFilename = fullVersion.url
        urlArray = fullVersion.url.split('/')
        filename = urlArray[urlArray.length-1]
      }
    }
    return (
      <Link className={commonClasses.linkColor} href={publishers?.rootAdFuelUrl?.replace('/ads/dev/adfuel', '') + fullFilename} target='_blank' rel='noopener noreferrer'>{filename}</Link>
    )
  }

  const NewModulePriority = () => {
    let fullModule = allAdFuelModules.filter(item => item._id === chosenModule)[0]
    let priority = fullModule.priority
    return (
      <Typography>{priority}</Typography>
    )
  }

  const addModule = () => {
    let fullModule = allAdFuelModules.filter(item => item._id === chosenModule)[0]
    let priority = fullModule.priority
    let urlArray = fullModule.url.split('/')
    let fullFilename = fullModule.url
    let filename = urlArray[urlArray.length-1]
    let fullVersion
    if (chosenVersion) {
      fullVersion = fullModule.versions.filter(item => item._id === chosenVersion)[0]
      if (fullVersion) {
        fullFilename = fullVersion.url
        urlArray = fullVersion.url.split('/')
        filename = urlArray[urlArray.length-1]
      }
    }
    console.log('[Add Module] ----- chosenVersion::',chosenVersion)
    const newModule = {
      adFuelModuleId: chosenModule,
      adFuelModuleVersionId: chosenVersion,
      name: fullModule.name,
      priority,
      source: filename,
      version: fullVersion.name
    }
    console.log('[Add Module] ----- chosenVersion::',chosenVersion)
    let newModules = [...siteAdFuelModules, newModule]
    newModules.sort((a, b) => {
      if (a.priorty > b.priorty) return 1
      if (a.name > b.name) return 1
      if (b.priority > a.priority) return -1
      if (b.name > a.name) return -1
      return 0
    })
    let siteClone = {...siteData, adFuelModules: newModules}
    setSiteData(siteClone)
    setAddingNewModule(false)
    setHaveModulesChanged(true)
    doUpdateSiteAdFuelModules(newModules)
  }

  const removeModule = (e, module) => {
    if (canEditSite) {
      console.log('[ModuleConfigurationBeta] ---------- removeModule::Removing Module: ', module)
      let tempAdFuelModules = siteData.adFuelModules.filter(mod => mod.name !== module.name)
      console.log('[ModuleConfigurationBeta] ---------- removeModule::setting Site AdFuel Modules', tempAdFuelModules)
      let siteClone = {...siteData, adFuelModules: tempAdFuelModules}
      setSiteData(siteClone)
      setSiteAdFuelModules(tempAdFuelModules)
      setHaveModulesChanged(true)
      doUpdateSiteAdFuelModules(tempAdFuelModules)
    } else {
      swal.fire({
        title: 'UNAUTHORIZED',
        text: 'You do not have permissions to do this.',
        icon: 'error',
        dangerMode: false,
        customClass: {
          container: 'swalContainer'
        }
      })
    }    
  }

  return (
    <>
      {!loading
        ? (
          <Grid className={addClasses(commonClasses.pt_1)}container spacing={2} alignItems='flex-end' justifyContent='flex-start' direction='row'>
            <Grid item container xs={12} alignItems='flex-end'>
              <Grid item xs={9}>
                <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Digital Ads Module Package File URL:</label>
                <Typography className={classes.link}>
                  <Link
                    className={commonClasses.linkColor}
                    href={!aisUrlWithCacheBuster ? aisUrl : aisUrlWithCacheBuster}
                    target='siteAIS' rel='noopener noreferrer'
                    onClick={() => {
                      setClickCount(clickCount + 1)
                    }}
                  >
                    {aisUrl?.length > 60 
                      ? (aisUrl.substring(0,30) + '[...]' + aisUrl.substring(aisUrl?.length-25)) 
                      : (aisUrl)
                    }
                  </Link>                  

                </Typography>
              </Grid>
              <Grid item xs>
                <Tooltip title='View'>
                  <IconButton
                    size='small'
                    color='primary'
                    style={{fontSize: '1em'}}
                    className={commonClasses.linkColor}
                    aria-label='view'
                    onClick={async () => {
                      try {
                        const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                        console.log('err', jsFile)
                        openViewDialog(jsFile)
                      } catch (err) {
                      // console.log('err', err)
                      }
                    }}
                  >
                    <Launch />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Tooltip title='Download'>
                  <IconButton
                    size='small'
                    color='primary'
                    style={{fontSize: '1em'}}
                    className={commonClasses.linkColor}
                    aria-label='download'
                    onClick={async () => {
                      try {
                        const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                        const file = new window.Blob([jsFile], { type: 'text/javascript' })
                        const link = document.createElement('a')
                        link.download = getAISFilename()
                        link.href = URL.createObjectURL(file)
                        link.click()
                      } catch (err) {
                      // console.log('err', err)
                      }
                    }}
                  >
                    <GetApp />
                  </IconButton>
                </Tooltip>
              </Grid>                
              <Grid item xs>
                <Tooltip title="Add Module">
                  <IconButton
                    size='small'
                    color='primary'
                    disabled={hasAdFuelChanged}
                    className={commonClasses.linkColor}
                    style={{fontSize: '1em'}}
                    aria-label='download'
                    onClick={async () => {
                      setAddingNewModule(true)
                      // const newModule = buildFakeAdFuelModule(adFuelVersionId)
                      // if (adFuelVersionId) {
                      //   setAllAdFuelModules([ newModule.system, ...allAdFuelModules ])
                      //   let newSiteAdFuelModules = [ newModule.site, ...siteAdFuelModules ].map(module => {
                      //     if (!module.source || !module.actions) {
                      //       const { adFuelModuleId, adFuelModuleVersionId } = module
                      //       const afModule = newModule.system
                      //       let actions = (<span></span>)
                      //       if (afModule) {
                      //         const version = afModule?.versions.find(item => item._id === adFuelModuleVersionId)
                      //         const url = version ? `https://i.cdn.turner.com${version.url}` : afModule.url
                      //         const filename = url.split('/')[url.split('/').length - 1]
                      //         const key = afModule.configurationKey
                      //         const hasReleaseNotes = (version && version.releaseNotes && version.releaseNotes !== null) || false
                      //         actions = (
                      //           <Grid container>
                      //             <Grid className={classes.dflex} item>
                      //               <IconButton
                      //                 aria-label='Delete Module'
                      //                 title='Delete Module'
                      //                 size="small"
                      //                 onClick={() => {
                      //                   if (canEditSite) {
                      //                     deleteModule(afModule._id)
                      //                   } else {
                      //                     swal.fire({
                      //                       title: 'UNAUTHORIZED',
                      //                       text: 'You do not have permissions to do this.',
                      //                       icon: 'error',
                      //                       dangerMode: false,
                      //                       customClass: {
                      //                         container: 'swalContainer'
                      //                       }
                      //                     })
                      //                   }
                      //                 }}
                      //               >
                      //                 <Delete className={commonClasses.deleteIconColor} />
                      //               </IconButton>
                      //             </Grid>
                      //           </Grid>
                      //         )
                      //         // console.log('Module: ', { url, filename })
                      //         module.source = filename || 'N/A'
                      //         module.actions = actions
                      //       } else {
                      //         module.source = 'N/A'
                      //         module.actions = actions
                      //       }
                      //     }
                      //     return module
                      //   })
                      //   setSiteAdFuelModules(newSiteAdFuelModules)
                      // } else {
                      //   swal.fire({
                      //     title: 'AdFuel Not Selected',
                      //     icon: 'warning',
                      //     button: 'Ok',
                      //     text: 'You must select an AdFuel Version first.',
                      //     customClass: {
                      //       container: 'swalContainer'
                      //     }
                      //   })
                      // }
                    }}
                  >
                    <TableIcons.Add />
                  </IconButton>   
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              { siteAdFuelModules && siteAdFuelModules.length > 0 
                ? (
                  <Box sx={{ width: '100%' }}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                      >
                        <CustomTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={siteAdFuelModules?.length || 0}
                        />
                        {addingNewModule ? newModuleRow() : ''}
                        <TableBody>
                          {
                            stableSort(siteAdFuelModules, getComparator(order, orderBy))
                              .map((row, index) => {
                                // const isItemSelected = isSelected(row.name);
                                const labelId = `custom-table-checkbox-${index}`;
                                return (
                                  <TableRow
                                    hover
                                    // onClick={(event) => handleClick(event, row.name)}
                                    // role="checkbox"
                                    // aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.name}
                                    // selected={isItemSelected}
                                  >
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      padding="none"
                                    >
                                      {row.name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {ModuleVersionDropdown(row, index)}
                                    </TableCell>
                                    <TableCell align="left">{row.priority}</TableCell>
                                    <TableCell align="left">
                                      <Link className={commonClasses.linkColor} href={publishers?.rootAdFuelUrl?.replace('/ads/adfuel', '/rocketeer') + row.source} target='_blank' rel='noopener noreferrer'>{row.source.split('/')[row.source.split('/').length-1]}</Link>
                                    </TableCell>
                                    <TableCell align="right">{/* dangerouslySetInnerHTML={{__html: row.actions}}>*/}
                                      {row.actions && row.actions.props ? row.actions : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )
                : (
                  <Box sx={{ width: '100%' }}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                      >
                        <CustomTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={siteAdFuelModules?.length || 0}
                        />
                        <TableBody>
                          {addingNewModule ? newModuleRow() : (
                            <TableRow>
                              <TableCell colSpan="5" align="center">
                                <Button
                                  onClick={(e) => {
                                    fetchByName()
                                  }}
                                  variant="contained"
                                  disabled={isLoading}
                                >
                                  { isLoading ? 'Refreshing....' : 'No Modules! - Click to Refresh' }                                
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )
              }
            </Grid>
          </Grid>
          )
        : (<LinearProgress />)
      }
      <CustomDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        title={dialog.title}
        subtitle={dialog.subtitle}
        content={dialog.content}
        displayDialogAction={dialog.displayDialogAction}
        customAction={dialog.customAction}
        size={dialog.size}
        handleOnClose={dialog.onClose}
      />
    </>
  )
}

export default ModuleConfigurationLite