import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupstring, object as yupobject } from 'yup'
import {
  fetchNotifications,
  updaeNotifications,
  getActiveCreativesOnInactiveLineItems,
  sendNonDeliveringReadyLineItems,
  sendCNNLineItemsLackingGeoTargeting,
  sendCreativesContainingTrackingImpjPixel,
  sendCreativesContainingProofPointClickThru,
  sendDeliveringTestLineItems,
  sendHouseLineItemsLackingNgInventoryTarget,
  sendInactiveLineItems,
  sendInactiveLineItemsModified,
  sendNonDeliveringLineItems,
  sendUsingDeviceIdMacro,
  sendAdUnitHierarchy,
  sendLineItemsWithCreativeTargeting,
  sendInviewRefresh,
  sendActiveUsers,
  sendAdFuelVersions,
  sendAdFuelModulesBySite,
  sendDfpActiveUsers,
  sendCNNA9SlotNames,
  sendGamAudienceData,
  sendGamInactiveAudienceData,
  sendAllPrebidVendorsList,
  filterReportNotification
} from '../../modules/reportNotificationsSlice'
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Paper,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core'
import { Add, Edit, Clear, Check } from '@material-ui/icons'
import { LoadingProgress } from '../ui'
import { commonStyle } from '../../styles'

export const ReportNotifications = props => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { frequencies, rptNotifications, loading } = useSelector(
    state => state.reportNotificationsSlice
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedItem, setSelectedItem] = useState({})
  const [reportNotifications, setReportNotifications] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  const validationSchema = yupobject().shape({
    description: yupstring().required('Required'),
    toEmail: yupstring().required('Required'),
    subject: yupstring().required('Required')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const onSearchChange = e => {
    setSearchTerm(e.target.value)
    dispatch(filterReportNotification(e.target.value))
  }

  const onEditReportNotification = reportNotification => {
    setSelectedItem(reportNotification)
    setIsEditing(true)
    setValue('description', reportNotification.description, {
      shouldValidate: true
    })
    setValue('toEmail', reportNotification.toEmail, { shouldValidate: true })
    setValue('subject', reportNotification.subject, { shouldValidate: true })
  }

  const handleTextFieldChange = e => {
    if (e.target.name === 'description') {
      setSelectedItem({
        ...selectedItem,
        description: e.target.value
      })
      setValue('description', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'toEmail') {
      setSelectedItem({
        ...selectedItem,
        toEmail: e.target.value
      })
      setValue('toEmail', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'subject') {
      setSelectedItem({
        ...selectedItem,
        subject: e.target.value
      })
      setValue('subject', e.target.value, { shouldValidate: true })
    }
  }

  const handleSelectChange = e => {
    if (e.target.name === 'selectFrequency') {
      setSelectedItem({
        ...selectedItem,
        frequency: e.target.value
      })
    }
  }

  const handleCheckBoxChange = e => {
    if (e.target.name === 'chkboxActive') {
      setSelectedItem({
        ...selectedItem,
        isActive: e.target.checked
      })
    }
  }

  const UpdateReportNotification = () => {
    dispatch(updateNotifications(selectedItem))
    setIsEditing(false)
  }

  const runAdHocReport = async rowData => {
    console.log('AdHoc rowData.name', rowData.name)
    switch (rowData.name) {
      case 'activeCreativesOnInactiveLineItems':
        await dispatch(getActiveCreativesOnInactiveLineItems())
        break
      case 'dfpAdUnitsWithMoatWrapperLabelToAddress':
        await dispatch(sendNonDeliveringReadyLineItems())
        break
      case 'dfpCNNLineItemsLackingGeoTargetingCreatedWithinDays':
        dispatch(sendCNNLineItemsLackingGeoTargeting())
        break
      case 'dfpCreativesContainingTrackImpjPixel':
        dispatch(sendCreativesContainingTrackingImpjPixel())
        break
      case 'dfpCreativesContainingProofPointClickThru':
        dispatch(sendCreativesContainingProofPointClickThru())
        break
      case 'dfpDeliveringTestLineItemsToAddress':
        dispatch(sendDeliveringTestLineItems())
        break
      case 'dfpHouseLineItemsLackingNgInventoryTargetToAddress':
        dispatch(sendHouseLineItemsLackingNgInventoryTarget())
        break
      case 'dfpInactiveLineItems':
        dispatch(sendInactiveLineItems())
        break
      case 'dfpInactiveLineItemsModified':
        dispatch(sendInactiveLineItemsModified())
        break
      case 'dfpNonDeliveringLineItemsToAddress':
        dispatch(sendNonDeliveringLineItems())
        break
      case 'dfpNonDeliveringReadyLineItemsToAddress':
        dispatch(sendNonDeliveringReadyLineItems())
        break
      case 'dfpUsingDeviceIdMacro':
        dispatch(sendUsingDeviceIdMacro())
        break
      case 'dfpAdUnitHierarchy':
        dispatch(sendAdUnitHierarchy())
        break
      case 'dfpLineItemsWithCreativeTargeting':
        dispatch(sendLineItemsWithCreativeTargeting())
        break
      case 'reportInviewRefresh':
        dispatch(sendInviewRefresh())
        break
      case 'reportActiveUsers':
        dispatch(sendActiveUsers())
        break
      case 'reportAdFuelVersions':
        dispatch(sendAdFuelVersions())
        break
      case 'reportAdFuelModulesBySite':
        dispatch(sendAdFuelModulesBySite())
        break
      case 'dfpActiveUsers':
        dispatch(sendDfpActiveUsers())
        break
      case 'reportCNNA9SlotNames':
        dispatch(sendCNNA9SlotNames())
        break
      case 'gamAudienceData':
        dispatch(sendGamAudienceData())
        break
      case 'gamInactiveAudienceData':
        dispatch(sendGamInactiveAudienceData())
        break
      case 'allPrebidVendorsList':
        dispatch(sendAllPrebidVendorsList())
        break
      default:
        return false
    }
  }

  useEffect(() => {
    if (rptNotifications.length > 0) {
      setReportNotifications(
        rptNotifications.map(notification => ({ ...notification }))
      )
    } else {
      dispatch(fetchNotifications())
    }
  }, [rptNotifications])

  const textField = (
    isEditing,
    selectedItem,
    reportNotification,
    textFieldName,
    textFieldDefaultValue,
    handleTextFieldChange,
    error,
    errorMessage
  ) => {
    if (isEditing && selectedItem._id === reportNotification._id) {
      return (
        <TextField
          style={{ width: '100%' }}
          margin='dense'
          {...register(textFieldName)}
          defaultValue={textFieldDefaultValue}
          variant='outlined'
          onChange={e => handleTextFieldChange(e)}
          error={!!error}
          helperText={error ? errorMessage : ''}
        />
      )
    } else {
      return <div className={commonClasses.plainTextWrap}>{textFieldDefaultValue}</div>
    }
  }

  const chkbox = (isEditing, selectedItem, reportNotification) => {
    if (isEditing && selectedItem._id === reportNotification._id) {
      return (
        <Checkbox
          checked={selectedItem.isActive === true}
          color='primary'
          value='true'
          name='chkboxActive'
          disabled={!isEditing}
          onChange={e => handleCheckBoxChange(e)}
        />
      )
    } else {
      return (
        <Checkbox
          checked={reportNotification.isActive === true}
          color='primary'
          value='true'
          name='chkboxActive'
          disabled={!isEditing}
          onChange={e => handleCheckBoxChange(e)}
        />
      )
    }
  }

  const select = (isEditing, selectedItem, reportNotification) => {
    if (isEditing && selectedItem._id === reportNotification._id) {
      return (
        <Select
          id='folder-label'
          value={selectedItem.frequency}
          className={commonClasses.selectEmpty}
          name='selectFrequency'
          variant='outlined'
          disabled={!isEditing}
          onChange={e => handleSelectChange(e)}
        >
          {frequencies.map((frequency, i) => (
            <MenuItem key={i} value={frequency}>
              {frequency}
            </MenuItem>
          ))}
        </Select>
      )
    } else {
      return (
        <Select
          id='folder-label'
          value={reportNotification.frequency}
          className={commonClasses.selectEmpty}
          name='selectFrequency'
          variant='outlined'
          disabled={!isEditing}
          onChange={e => handleSelectChange(e)}
        >
          {frequencies.map((frequency, i) => (
            <MenuItem key={i} value={frequency}>
              {frequency}
            </MenuItem>
          ))}
        </Select>
      )
    }
  }

  return (
    <Container maxWidth='xl'>
      <Paper position='static'>
        <form autoComplete='off' noValidate>
          <CardHeader title='Reporting' />
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '99%' }}
                      label='Find'
                      margin='dense'
                      name='itemFilter'
                      defaultValue={searchTerm}
                      variant='outlined'
                      onChange={onSearchChange}
                    />
                  </Grid>
                  <Grid item md={8} xs={12} />
                </Grid>
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardContent>
                <Grid>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '20%', fontWeight: 'bold' }}>
                          Name
                        </TableCell>
                        <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                          Description
                        </TableCell>
                        <TableCell style={{ width: '20%', fontWeight: 'bold' }}>
                          To Email Address
                        </TableCell>
                        <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                          Subject
                        </TableCell>
                        <TableCell style={{ width: '10%', fontWeight: 'bold' }}>
                          Frequency
                        </TableCell>
                        <TableCell style={{ width: '10%', fontWeight: 'bold' }}>
                          Active
                        </TableCell>
                        <TableCell style={{ width: '10%', fontWeight: 'bold' }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportNotifications.length > 0
                        ? (
                            reportNotifications.map(reportNotification => (
                              <TableRow
                                hover
                                key={reportNotification._id}
                                style={{
                                  verticalAlign: 'top'
                                }}
                              >
                                <TableCell>
                                  <div className={commonClasses.plainTextWrap}>
                                    {reportNotification.name}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {textField(
                                    isEditing,
                                    selectedItem,
                                    reportNotification,
                                    'description',
                                    reportNotification.description,
                                    handleTextFieldChange,
                                    errors.description,
                                    errors.description
                                      ? errors.description.message
                                      : ''
                                  )}
                                </TableCell>
                                <TableCell>
                                  {textField(
                                    isEditing,
                                    selectedItem,
                                    reportNotification,
                                    'toEmail',
                                    reportNotification.toEmail,
                                    handleTextFieldChange,
                                    errors.toEmail,
                                    errors.toEmail ? errors.toEmail.message : ''
                                  )}
                                </TableCell>
                                <TableCell>
                                  {textField(
                                    isEditing,
                                    selectedItem,
                                    reportNotification,
                                    'subject',
                                    reportNotification.subject,
                                    handleTextFieldChange,
                                    errors.subject,
                                    errors.subject ? errors.subject.message : ''
                                  )}
                                </TableCell>
                                <TableCell>
                                  {select(isEditing, selectedItem, reportNotification)}
                                </TableCell>
                                <TableCell>
                                  {chkbox(isEditing, selectedItem, reportNotification)}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    className={commonClasses.spacer}
                                    startIcon={<Add />}
                                    onClick={() => runAdHocReport(reportNotification)}
                                  >
                                    Run
                                  </Button>
                                  <Grid container>
                                    <Grid className={commonClasses.displayFlex} item>
                                      <IconButton
                                        aria-label='Save'
                                        variant='contained'
                                        className={
                                    isEditing &&
                                    selectedItem._id === reportNotification._id
                                      ? ''
                                      : commonClasses.hidden
                                  }
                                        name='Save'
                                        title='Save'
                                        onClick={handleSubmit(UpdateReportNotification)}
                                      >
                                        <Check />
                                      </IconButton>
                                      <IconButton
                                        aria-label='Cancel'
                                        variant='contained'
                                        className={
                                    isEditing &&
                                    selectedItem._id === reportNotification._id
                                      ? ''
                                      : commonClasses.hidden
                                  }
                                        title='Cancel'
                                        onClick={e => {
                                          e.stopPropagation()
                                          setIsEditing(false)
                                        }}
                                      >
                                        <Clear />
                                      </IconButton>
                                    </Grid>
                                  </Grid>

                                  <Grid container>
                                    <Grid className={commonClasses.displayFlex} item>

                                      <IconButton
                                        aria-label='Edit'
                                        variant='contained'
                                        className={
                                    isEditing &&
                                    selectedItem._id === reportNotification._id
                                      ? commonClasses.hidden
                                      : commonClasses.editIconColor
                                  }
                                        name='Edit'
                                        title='Edit'
                                        onClick={e => {
                                          onEditReportNotification(reportNotification)
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ))
                          )
                        : (
                          <TableRow>
                            <TableCell colSpan={7}>
                              {reportNotifications.length === 0 && loading && (
                                <LoadingProgress label='Loading Registries' />
                              )}
                            </TableCell>
                          </TableRow>
                          )}
                    </TableBody>
                  </Table>
                </Grid>
              </CardContent>
            </Card>
          </CardContent>
        </form>
      </Paper>
    </Container>
  )
}

export default ReportNotifications
