import React, { useState, useEffect } from 'react'

import Swal from 'sweetalert2'

import { Grid, Card, CardHeader, CardContent, CardActions, Divider, Button } from '@material-ui/core'

import { commonStyle } from '../../styles'
import { clone, convertObjectToArray, siteNameFormatter } from '../../utilities/helperFunctions.js'
import { buildPrebidLibrary, getAdFuelModuleConfiguration, saveAdFuelOptionFile, saveAdFuelModuleConfiguration } from '../../modules/lookupsSlice'

import {
  A9,
  AdFuelConfiguration,
  Beemray,
  IntegralAdScience,
  InViewRefresh,
  Audience,
  Krux,
  MalvertisingDetection,
  Prebid,
  RealTimeAdInsertion,
  ServerSideAdInsertion,
  UserConsent
} from './ModuleConfigs'

const validation = {
  STRING: {
    LENGTH: { min: 0, max: 255, message: 'character limit exceeded' }
  },
  NUMBER: {
    TIMEOUT: { min: 500, max: 3000, message: 'please enter a number between 500 & 3000' },
    FLOOR: { min: 0.0, max: 100, step: 0.01, message: 'please enter a number between 0 & 100' },
    REFRESH: { min: 35, max: 1000, message: 'please enter a number between 35 & 1000' },
    REFRESH_COUNT: { min: 1, max: 100, message: 'please enter a number between 1 & 100' },
    PERCENTAGE: { min: 0, max: 100, message: 'please enter a number between 0 & 100' },
    MAXADS: { min: 3, max: 25, message: 'please enter a number between 3 & 25' },
    BUFFER_OFFSET: { min: 1, max: 10, message: 'please enter a number between 1 & 10'},
    INITIAL_INDEX: { min: 1, max: 100, message: 'please enter a number between 1 & 100'}
  }
}

const hasModule = (site, module) => {
  let regex = new RegExp(module)
  let pattern = 'A9'
  switch (module.toUpperCase()) {
    case 'A9':
      pattern = 'A9'
      break
    case 'PREBID':
      pattern = 'Prebid'
      break
    case 'RTAI':
      pattern = 'Real(\\s|-)Time\\sAd\\sInsertion'
      break
    case 'AUI':
      pattern = 'Audience\\sand\\sUser\\sIdentity'
      break
    case 'KRUX':
      pattern = 'Krux'
      break
    case 'BEEMRAY':
      pattern = 'Beemray'
      break
    case 'IAS':
      pattern = 'Integral\\sAd\\sScience'
      break
    case 'IVR':
      pattern = 'In(\\s|-)View\\sRefresh'
      break
    case 'MALVERTISING':
      pattern = 'Malvertising\\s?Detection'
      break
    case 'SSAI':
      pattern = 'SSAI|Server(\\s|-)Side\\sAd\\sInsertion'
      break
    case 'USER_CONSENT':
      pattern = 'User\\s?Consent'
      break
    default:
      break
  }
  regex = new RegExp(pattern)
  return site.adFuelModules.find(item => {
    // console.log('Item: ', item)
    return item.name.match(regex)
  })
}

const validJSON = (object) => {
  try {
    JSON.parse(JSON.stringify(object))
    return true
  } catch (error) {
    Swal.fire({
      title: 'JSON Validation Error',
      text: error,
      icon: 'error'
    })
    return false
  }
}

export const AdFuelOptions = ({ module, allData, moduleId, moduleName, data, site, setOpenDialog, onClose, setVersion }) => {
  const [options, setOptions] = useState(clone(data))
  const commonClasses = commonStyle()
  const [errors, setErrors] = useState({})
  const [allOptions, setAllOptions] = useState(clone(allData))
  const [adFuelModuleName, setAdFuelModuleName] = useState(clone(moduleName))
  const [adFuelModuleId, setAdFuelModuleId] = useState(clone(moduleId))
  const [biddersChanged, setBiddersChanged] = useState(false)
  const [modulesChanged, setModulesChanged] = useState(false)
  const [fledgeVendors, setFledgeVendors] = useState(new Set(data.PREBID?.VENDOR?.FLEDGE?.BIDDERS || []))
  const [originalBidders, setOriginalBidders] = useState()
  const [originalModules, setOriginalModules] = useState()
  const [components, setComponents] = useState([])

  function arraysEqual (a, b) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
    a.sort()
    b.sort()
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  function toggleFledgeVendor (vendor) {
    console.log('Toggling Fledge Vendor: ', vendor)
    let newFledgeVendors = new Set(fledgeVendors)
    if (newFledgeVendors.has(vendor)) {
      newFledgeVendors.delete(vendor)
    } else {
      newFledgeVendors.add(vendor)
    }
    console.log('Fledge Vendors: ', newFledgeVendors.values())
    setFledgeVendors(newFledgeVendors)
  }

  async function addComponent (component) {
    if (components.indexOf(component) < 0 && component !== '') {
      components.push(component)
      components.sort()
      await setComponents(components)
    }
  }

  async function removeComponent (component) {
    const interim = components.filter(item => {
      return item !== component
    })
    interim.sort()
    await setComponents(interim)
  }

  useEffect(() => {
    async function fetchData () {
      const optionBidders = options.PREBID?.LIBRARY?.VENDORS || []
      const originalData = await getAdFuelModuleConfiguration(site._id, moduleId)
      await setOriginalBidders(originalData.data.PREBID?.LIBRARY?.VENDORS || [])
      if (originalBidders) { originalBidders.sort() }
      if (optionBidders) optionBidders.sort()
      const didBiddersChange = originalBidders && optionBidders ? !arraysEqual(originalBidders, optionBidders) : false
      await setBiddersChanged(didBiddersChange)
      const optionModules = options.PREBID.LIBRARY.MODULES || []
      await setOriginalModules(originalData.data.PREBID.LIBRARY.MODULES || [])
      if (originalModules) { originalModules.sort() }
      if (optionModules) optionModules.sort()
      const didModulesChange = originalModules && optionModules ? !arraysEqual(originalBidders, optionBidders) : false
      await setModulesChanged(didModulesChange)
    }    
    data.ADFUEL = data.ADFUEL || {}
    data.ADFUEL.ACTIVE_MODULES = data.ADFUEL?.ACTIVE_MODULES || []
    data.AUI = data.AUI || { SITE_VENDORS: { DOM: [], INTL: [] }, VENDORS: {
      "AppNexus":{"COOKIE_NAME":"zwmc","URL":"https://ib.adnxs.com/getuid?https://umto.{{domain}}/user-sync?zwmc=$UID&domain={{domain}}"},
      "Freewheel":{"COOKIE_NAME":"bea4","URL":"https://bea4.v.fwmrm.net/ad/u?mode=echo&cr=https://umto.{{domain}}/user-sync?bea4=#{user.id}&domain={{domain}}"},
      "LiveRamp":{"COOKIE_NAME":"orev","URL":"https://idsync.rlcdn.com/712348.gif?partner_uid=${fwuid}"},
      // "ID5":{"COOKIE_NAME":"","URL":""},
      "PubMatic":{"COOKIE_NAME":"kfyn","URL":"https://ads.pubmatic.com/AdServer/js/userSync.js"},
      "Rubicon":{"COOKIE_NAME":"ifyr","URL":"https://pixel-us-east.rubiconproject.com/exchange/sync.php?p={{brand}}"},
      "SpotX":{"COOKIE_NAME":"hkgc","URL":"https://sync.search.spotxchange.com/audience_id"},
      "Telaria":{"COOKIE_NAME":"goiz","URL":"https://eq97f.publishers.tremorhub.com/pubsync?redir=https://umto.{{domain}}/user-sync?goiz=%5Btvid%5D&domain={{domain}}"},
    } }
    data.RTAI = data.RTAI || {
      MAX_NO_OF_ADS: 6, // max amount of Ads or Marketing Creatives
      BUFFER: 6, // initial paragraph buffer
      INITIAL_PLACEMENT: 4,
      REGISTRY: '//i.cdn.turner.com/ads/cnn/cnn_quantum_leaf.json',
      PARENT: '.article__content', // Wrapper element selector - defaults to Stellar content Wrapper
      CHILDREN: 'p', // Child element selector - defaults to paragraph
      OFFSET: 2, // how much to offset each adslot by
      lazyLoadOptions: {
        scrollThresholdDesktop: 25,
        scrollThresholdMsobile: 100
      } // options to configure lazyloads for ads
    }
    console.log('Setting Options: ', data)
    setOptions(clone(data))
    setAllOptions(allData)
    setAdFuelModuleName(moduleName)
    setAdFuelModuleId(moduleId)
    if (options.PREBID) { fetchData() }
  }, [data, allData, moduleId, moduleName, module, site])
  return (
    <Card>
      <CardHeader
        title={`${site.name} ${adFuelModuleName} Configuration`}
      />
      <Divider />
      <CardContent className={commonClasses.cardBkClr}>
        {options.ADFUEL && adFuelModuleName === 'ADFUEL' && (
          <AdFuelConfiguration
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.A9 && hasModule(site, adFuelModuleName) && adFuelModuleName === 'A9' && (
          <A9
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.BEEMRAY && hasModule(site, adFuelModuleName) && adFuelModuleName === 'BEEMRAY' && (
          <Beemray
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.IAS && hasModule(site, adFuelModuleName) && adFuelModuleName === 'IAS' && (
          <IntegralAdScience
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.IVR && hasModule(site, adFuelModuleName) && adFuelModuleName === 'IVR' && (
          <InViewRefresh
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.AUI && hasModule(site, adFuelModuleName) && adFuelModuleName === 'AUI' && (
          <Audience
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.KRUX && hasModule(site, adFuelModuleName) && adFuelModuleName === 'KRUX' && (
          <Krux
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.MALVERTISING && hasModule(site, adFuelModuleName) && adFuelModuleName === 'MALVERTISING' && (
          <MalvertisingDetection
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.PREBID && hasModule(site, adFuelModuleName) && adFuelModuleName === 'PREBID' && (
          <Prebid
            options={options}
            allOptions={allData}
            originalBidders={originalBidders}
            originalModules={originalModules}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            setBiddersChanged={setBiddersChanged}
            fledgeVendors={fledgeVendors}
            toggleFledgeVendor={toggleFledgeVendor}
            setModulesChanged={setModulesChanged}
            addComponent={addComponent}
            removeComponent={removeComponent}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.RTAI && hasModule(site, adFuelModuleName) && adFuelModuleName === 'RTAI' && (
          <RealTimeAdInsertion
            options={options}
            allOptions={allData}
            originalBidders={originalBidders}
            originalModules={originalModules}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            setBiddersChanged={setBiddersChanged}
            setModulesChanged={setModulesChanged}
            addComponent={addComponent}
            removeComponent={removeComponent}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.SSAI && hasModule(site, moduleName) && adFuelModuleName === 'SSAI' && (
          <ServerSideAdInsertion
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
        {options.USER_CONSENT && hasModule(site, moduleName) && adFuelModuleName === 'USER_CONSENT' && (
          <UserConsent
            options={options}
            allOptions={allData}
            setOptions={setOptions}
            setAllOptions={setAllOptions}
            errors={errors}
            setErrors={setErrors}
            validation={validation}
          />
        )}
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container spacing={1} justify='flex-end'>
          <Grid item>
            <Button
              variant='text'
              onClick={() => {
                console.log('Cancelling...')
                return onClose()
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={commonClasses.btnSuccess}
              variant='contained'
              onClick={async () => {
                // console.log('SAVING ADFUEL OPTIONS....', { options, adFuelModuleId, adFuelModuleName, site })
                const formErrors = convertObjectToArray(errors)
                // console.log('ERRORS....', formErrors)
                if (formErrors.find(item => item.value)) {
                  Swal.fire({
                    title: 'Invalid Form',
                    text: `${formErrors.length} error(s) found.`,
                    icon: 'error'
                  })
                  return
                }
                if (!validJSON(options) || !validJSON(allData)) { return }
                options.VERSION = '1.3'
                options.NAME = site.name
                options.TIMESTAMP = new Date().toLocaleString('en-US', { timeZone: 'America/New_York'})
                const theRest = async () => {                  
                  delete allOptions.PREBID.BIDDERS.ALLOWED
                  delete allOptions.PREBID.MODULES                  
                  const finalOptions = { ...allOptions, ...options }
                  if (finalOptions.ADFUEL) {
                    console.log('Enforcing ACTIVE_MODULES as []')
                    finalOptions.ADFUEL.ACTIVE_MODULES = [...finalOptions.ADFUEL.ACTIVE_MODULES].filter(item=>item!==null)
                  }
                  if (finalOptions.PREBID) {
                    const allowedBidders = finalOptions.PREBID.LIBRARY?.VENDORS || ['appnexus','criteo']
                    console.log('allowedBidders: ', allowedBidders)
                    if (finalOptions.PREBID.BIDDERS.CLIENT) finalOptions.PREBID.BIDDERS.CLIENT.DOM = finalOptions.PREBID.BIDDERS.CLIENT.DOM?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.CLIENT) finalOptions.PREBID.BIDDERS.CLIENT.INTL = finalOptions.PREBID.BIDDERS.CLIENT.INTL?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.NATIVE) finalOptions.PREBID.BIDDERS.NATIVE.DOM = finalOptions.PREBID.BIDDERS.NATIVE.DOM?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.NATIVE) finalOptions.PREBID.BIDDERS.NATIVE.INTL = finalOptions.PREBID.BIDDERS.NATIVE.INTL?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.OUTSTREAM) finalOptions.PREBID.BIDDERS.OUTSTREAM.DOM = finalOptions.PREBID.BIDDERS.OUTSTREAM.DOM?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.OUTSTREAM) finalOptions.PREBID.BIDDERS.OUTSTREAM.INTL = finalOptions.PREBID.BIDDERS.OUTSTREAM.INTL?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.S2S) finalOptions.PREBID.BIDDERS.S2S.DOM = finalOptions.PREBID.BIDDERS.S2S.DOM?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.S2S) finalOptions.PREBID.BIDDERS.S2S.INTL = finalOptions.PREBID.BIDDERS.S2S.INTL?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.VIDEO) finalOptions.PREBID.BIDDERS.VIDEO.DOM = finalOptions.PREBID.BIDDERS.VIDEO.DOM?.filter(item => allowedBidders.indexOf(item) >= 0)
                    if (finalOptions.PREBID.BIDDERS.VIDEO) finalOptions.PREBID.BIDDERS.VIDEO.INTL = finalOptions.PREBID.BIDDERS.VIDEO.INTL?.filter(item => allowedBidders.indexOf(item) >= 0)
                    finalOptions.PREBID.VENDOR.FLEDGE = finalOptions.PREBID.VENDOR.FLEDGE || { BIDDERS: []}
                    finalOptions.PREBID.VENDOR.FLEDGE.BIDDERS = Array.from(fledgeVendors)
                  }
                  console.log('Final Options: ', finalOptions)
                  const cdnPayload = {
                    name: siteNameFormatter(site.name),
                    options: finalOptions
                  }
                  // console.log('CDN Payload: ', cdnPayload)
                  const cdnRes = await saveAdFuelOptionFile(cdnPayload)
                  const dbPayload = {
                    site: site._id,
                    module: adFuelModuleId,
                    config: options[adFuelModuleName],
                    key: adFuelModuleName
                  }
                  // console.log('DB Payload: ', dbPayload)
                  const dbRes = await saveAdFuelModuleConfiguration(dbPayload)
                  if (cdnRes.status === 200 && dbRes.status === 200) {
                    Swal.fire({
                      title: 'Success',
                      text: 'Database updated and configuration file saved successfully.  Please \'Save and Build\' to update your AIS file with the new configuration.',
                      icon: 'success'
                    }).then(res => {
                      console.log('Success - SWAL Response: ', res)
                    })
                  } else if (cdnRes.status !== 200 && dbRes.status !== 200) {
                    // console.log('error response:', cdnRes, dbRes)
                    Swal.fire({
                      title: 'Dual Errors',
                      text: `An error occurred while updating database and saving configuration file: ${cdnRes.message}, ${dbRes.message}`,
                      icon: 'error'
                    }).then(res => {
                      console.log('Dual Errors - SWAL Response: ', res)
                    })
                  } else if (cdnRes.status !== 200) {
                    // console.log('error response:', cdnRes)
                    Swal.fire({
                      title: 'File Save Error',
                      text: `An error occurred while saving configuration file: ${cdnRes.message}`,
                      icon: 'error'
                    }).then(res => {
                      console.log('File Save Error - SWAL Response: ', res)
                    })
                    // console.log(res)
                  } else if (dbRes.status !== 200) {
                    // console.log('error response:', dbRes)
                    Swal.fire({
                      title: 'DB Update Error',
                      text: `An error occurred while updating the database: ${dbRes.message}`,
                      icon: 'error'
                    }).then(res => {
                      console.log('DB Error - SWAL Response: ', res)
                    })
                  }
                  setOpenDialog(false)
                }
                // if (biddersChanged || modulesChanged) {
                //   const buildMessage = Swal.mixin({
                //     didOpen: () => {
                //       console.log('Opened SWAL Modal...')
                //     },
                //     showCancelButton: true,
                //     confirmButtonText: 'Build Prebid Library',
                //     showLoaderOnConfirm: true,
                //     allowOutsideClick: () => !Swal.isLoading(),
                //     backdrop: true
                //   })
                //   await buildMessage.fire({
                //     title: 'Components Changed',
                //     text: 'The components for Prebid have changed and will require a rebuild of the Prebid library.  This could take a few minutes.',
                //     icon: 'info',
                //     showCloseButton: false,
                //     showConfirmButton: true,
                //     showCancelButton: true,
                //     preConfirm: () => {
                //       const prebidPayload = {
                //         name: siteNameFormatter(site.name),
                //         components
                //       }
                //       return buildPrebidLibrary(prebidPayload)
                //         .then(response => {
                //           // if (!response.ok) {
                //           //   throw new Error(response.statusText)
                //           // }
                //           // return response.json()
                //           if (response.status === 200) {
                //             return theRest()
                //           }
                //           return false
                //         })
                //         .catch(error => {
                //           Swal.showValidationMessage(
                //           `Request failed: ${error}`
                //           )
                //         })
                //     }
                //   }).then(async (res) => {
                //     console.log('response: ', res)
                //   }).catch(err => {
                //     console.log('Components Changed Error: ', err)
                //   })
                //   console.log('Returning...')
                // } else {
                  console.log('Not firing component SWAL...')
                  return await theRest()
                // }
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
