import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'
import { filterByValue } from '../utilities/helperFunctions'

const swal = withReactContent(Swal)

const initialState = {
  adCDNFolders: [],
  orgAdCDNFolders: [],
  loading: false,
  error: null
}

const adLoaderCDNFoldersSlice = createSlice({
  name: 'adLoaderCDNFoldersSlice',
  initialState,
  reducers: {
    LoadingStatus: state => {
      state.loading = true
    },
    FetchAdCDNFolders: (state, action) => {
      state.loading = false
      state.adCDNFolders = action.payload
      state.orgAdCDNFolders = action.payload
    },
    CreateAdCDNFolder: (state, action) => {
      const adCDNFolders = state.adCDNFolders.slice()
      adCDNFolders.unshift(action.payload)
      state.loading = false
      state.adCDNFolders = adCDNFolders
      state.orgAdCDNFolders = adCDNFolders
      swal.fire({
        title: 'CDNFolders Saved!',
        icon: 'success'
      })
    },
    UpdateAdCDNFolder: (state, action) => {
      const adCDNFolders = state.adCDNFolders.slice()
      const index = adCDNFolders.findIndex(
        adCDNFolder => adCDNFolder._id === action.payload._id
      )
      adCDNFolders[index] = action.payload
      state.loading = false
      state.adCDNFolders = adCDNFolders
      state.orgAdCDNFolders = adCDNFolders
      swal.fire({
        title: `The adCDNFolder "${action.payload.name}" has been Updated!`,
        icon: 'success'
      })
    },
    DeleteAdCDNFolder: (state, action) => {
      const adCDNFolders = state.adCDNFolders
        .slice()
        .filter(adCDNFolder => adCDNFolder._id !== action.payload._id)
      state.loading = false
      state.adCDNFolders = adCDNFolders
      state.orgAdCDNFolders = adCDNFolders
      swal.fire({
        title: `The CDNFolder "${action.payload.name}" has been deleted!`,
        icon: 'success'
      })
    },
    FilterAdCDNFolder: (state, action) => {
      const results = filterByValue(state.orgAdCDNFolders, action.payload)
      state.loading = false
      state.adCDNFolders = results
    },
    SliceError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    }
  }
})

export default adLoaderCDNFoldersSlice.reducer
export const {
  LoadingStatus,
  FetchAdCDNFolders,
  CreateAdCDNFolder,
  UpdateAdCDNFolder,
  DeleteAdCDNFolder,
  FilterAdCDNFolder,
  SliceError
} = adLoaderCDNFoldersSlice.actions

// AdLoaderCDNFolders
export const fetchAdCDNFolders = () => {
  return async dispatch => {
    dispatch(LoadingStatus())
    return request('/lookups/cdnFolders')
      .then(res => dispatch(FetchAdCDNFolders(res.data)))
      .catch(err => dispatch(SliceError(err)))
  }
}

export const createAdCDNFolder = adCDNFolder => {
  return async dispatch => {
    dispatch(LoadingStatus())
    return request('/lookups/cdnFolders', 'POST', adCDNFolder)
      .then(res => dispatch(CreateAdCDNFolder(res.data)))
      .catch(err => dispatch(SliceError(err)))
  }
}

export const updateAdCDNFolder = adCDNFolder => {
  return async dispatch => {
    dispatch(LoadingStatus())
    return request(
      `/lookups/cdnFolders/${adCDNFolder._id}`,
      'POST',
      adCDNFolder
    )
      .then(res => dispatch(UpdateAdCDNFolder(res.data)))
      .catch(err => dispatch(SliceError(err)))
  }
}

export const deleteAdCDNFolder = adCDNFolder => {
  return async dispatch => {
    dispatch(LoadingStatus())
    return request(
      `/lookups/cdnFolders/${adCDNFolder._id}`,
      'DELETE',
      adCDNFolder
    )
      .then(res => dispatch(DeleteAdCDNFolder(res.data)))
      .catch(err => dispatch(SliceError(err)))
  }
}

export const filterCDNFolder = searchTerm => {
  return async dispatch => {
    dispatch(LoadingStatus())
    dispatch(FilterAdCDNFolder(searchTerm))
  }
}
