import React from 'react'

import { Card, CardContent, Table, TableHead, TableBody, TableRow, TableCell, TextField, Typography } from '@material-ui/core'

export const Krux = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { KRUX } = options
  const { DOM_CONTROLTAG, INTL_CONTROLTAG } = KRUX
  const { STRING } = validation
  const { LENGTH } = STRING

  return (
    <Card>
      <CardContent>
        <Table size='small' aria-label='krux-control-tag-table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '180px' }}><Typography variant='h5'>Control Tags</Typography></TableCell>
              <TableCell>Domestic</TableCell>
              <TableCell>International</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell>
                <TextField
                  margin='none'
                  value={DOM_CONTROLTAG}
                  error={errors.KRUX_DCT}
                  helperText={errors.KRUX_DCT && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    KRUX.DOM_CONTROLTAG = value
                    setErrors({ ...errors, KRUX_DCT: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, KRUX })
                    setAllOptions({ ...allOptions, KRUX })
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  margin='none'
                  value={INTL_CONTROLTAG}
                  error={errors.KRUX_ICT}
                  helperText={errors.KRUX_ICT && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    KRUX.INTL_CONTROLTAG = value
                    setErrors({ ...errors, KRUX_ICT: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, KRUX })
                    setAllOptions({ ...allOptions, KRUX })
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
