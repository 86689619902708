import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Divider, Button, makeStyles, TextField, IconButton, Paper, CardHeader, CardContent, CardActions, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { ToggleSwitch } from '../../ui'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { commonStyle } from '../../../styles'
import { clone } from '../../../utilities/helperFunctions.js'
import { generateId, updateSiteResponsiveness } from '../../../modules/sitesSlice'
import DeleteIcon from '@material-ui/icons/Delete'
import { useForm } from 'react-hook-form'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swal = withReactContent(Swal)

const useStyle = makeStyles(theme => ({
  footerActionArea: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fontWeightBold: { fontWeight: 'bold' }
}))

const Responsiveness = ({ data, canEditSite }) => {
  const classes = useStyle()
  const commonClasses = commonStyle()
  const [site, setSite] = useState(clone(data))
  const { updatingSiteResponsiveness } = useSelector(state => state.sitesSlice)
  const { isResponsive, viewports } = site
  const [isChanged, setIsChanged] = useState(false)
  const dispatch = useDispatch()

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({ submitFocusError: false })

  const handleSiteResponsivenessChange = (event, rowData) => {
    const selectedViewportIndex = site?.viewports?.findIndex(
      viewport => viewport.siteViewportId === rowData.siteViewportId
    )
    const _viewPorts = site?.viewports?.slice()
    if (event.target.name === 'name' + rowData.siteViewportId) {
      _viewPorts[selectedViewportIndex].name = event.target.value
      setValue('name' + rowData.siteViewportId, event.target.value, { shouldValidate: true })
    } else if (event.target.name === 'minWidth' + rowData.siteViewportId) {
      _viewPorts[selectedViewportIndex].minWidth = event.target.value
      setValue('minWidth' + rowData.siteViewportId, event.target.value, { shouldValidate: true })
    } else if (event.target.name === 'minHeight' + rowData.siteViewportId) {
      _viewPorts[selectedViewportIndex].minHeight = event.target.value
      setValue('minHeight' + rowData.siteViewportId, event.target.value, { shouldValidate: true })
    }
    setSite({
      ...site,
      viewports: _viewPorts
    })
  }

  const deleteViewport = (item) => {
    const filterViewportList = site?.viewports?.filter(viewport => viewport.siteViewportId !== item.siteViewportId)
    setSite({
      ...site,
      viewports: filterViewportList
    })
  }

  const saveResponsiveness = () => {
    dispatch(updateSiteResponsiveness(site))
  }

  useEffect(() => {
    const _site = clone(data)
    _site?.viewports?.sort((a, b) => {
      if (a.minWidth > b.minWidth) { return -1 }
      if (b.minWidth > a.minWidth) { return 1 }
      if (a.minWidth === b.minWidth) {
        if (a.minHeight < b.minHeight) { return -1 }
        if (a.minHeight > b.minHeight) { return 1 }
      }
      return 0
    })
    setSite(_site)
  }, [data])

  useEffect(() => {
    if (site._id) {
      const oldData = { isResponsive: data.isResponsive, viewports: data.viewports }
      const newData = { isResponsive: site.isResponsive, viewports: site.viewports }
      setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
    }
  }, [site])

  return (
    <Paper position='static'>
      <CardHeader
        title='Responsiveness'
        action={canEditSite && (
          <>
            <ToggleSwitch
              disabled={!canEditSite || updatingSiteResponsiveness}
              onLabel='Responsive'
              offLabel='Not Responsive'
              checked={!!isResponsive}
              onChange={(event) => setSite({ ...site, isResponsive: event.target.checked })}
            />
            <Tooltip title='Add'>
              <IconButton
                aria-label='Add'
                disabled={!isResponsive}
                onClick={() => new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    const initialResponsiveness = {
                      name: '',
                      minWidth: 0,
                      minHeight: 0,
                      siteViewportId: await generateId()
                    }
                    setSite({ ...site, viewports: [...viewports, initialResponsiveness] })
                    setValue('name' + initialResponsiveness.siteViewportId, '', { shouldValidate: true })
                    setValue('minWidth' + initialResponsiveness.siteViewportId, 0)
                    setValue('minHeight' + initialResponsiveness.siteViewportId, 0)
                    resolve()
                  }, 1000)
                })}
              ><AddBoxIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      />
      <CardContent>
        <Grid style={{ width: '100%' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.fontWeightBold}
                  style={{ width: '30%' }}
                >
                  Name
                </TableCell>
                <TableCell
                  className={classes.fontWeightBold}
                  style={{ width: '30%' }}
                >
                  Minimum Width
                </TableCell>
                <TableCell
                  className={classes.fontWeightBold}
                  style={{ width: '30%' }}
                >
                  Minimum Height
                </TableCell>
                <TableCell
                  className={classes.fontWeightBold}
                  style={{ width: '10%' }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isResponsive
                ? (
                    site?.viewports?.map(rowData => {
                      return (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={rowData.siteViewportId}
                          style={{
                            verticalAlign: 'top'
                          }}
                        >
                          <TableCell>
                            <TextField
                              {...register('name' + rowData.siteViewportId, {
                                setValueAs: v => String(v),
                                required: 'Name is required'
                              })}
                              margin='dense'
                              value={rowData.name ? rowData.name : ''}
                              style={{ width: '100%' }}
                              variant='outlined'
                              onChange={e => handleSiteResponsivenessChange(e, rowData)}
                              error={!!errors['name' + rowData.siteViewportId]}
                              helperText={errors['name' + rowData.siteViewportId] ? errors['name' + rowData.siteViewportId].message : ''}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              {...register('minWidth' + rowData.siteViewportId, {
                                setValueAs: v => parseInt(v),
                                required: 'Minimum Width is required',
                                min: {
                                  value: 0,
                                  message: 'Width should be atleast 0'
                                }
                              })}
                              margin='dense'
                              value={rowData.minWidth}
                              style={{ width: '100%' }}
                              variant='outlined'
                              type='number'
                              onChange={e => handleSiteResponsivenessChange(e, rowData)}
                              error={!!errors['minWidth' + rowData.siteViewportId]}
                              helperText={errors['minWidth' + rowData.siteViewportId] ? errors['minWidth' + rowData.siteViewportId].message : ''}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              {...register('minHeight' + rowData.siteViewportId, {
                                setValueAs: v => parseInt(v),
                                required: 'Minimum Height is Required',
                                min: {
                                  value: 0,
                                  message: 'Height should be atleast 0'
                                }
                              })}
                              margin='dense'
                              value={rowData.minHeight}
                              style={{ width: '100%' }}
                              variant='outlined'
                              type='number'
                              onChange={e => handleSiteResponsivenessChange(e, rowData)}
                              error={!!errors['minHeight' + rowData.siteViewportId]}
                              helperText={errors['minHeight' + rowData.siteViewportId] ? errors['minHeight' + rowData.siteViewportId].message : ''}
                            />
                          </TableCell>
                          <TableCell>
                            <Tooltip title='Delete'>
                              <IconButton
                                aria-label='Delete'
                                onClick={() => {
                                  if (canEditSite) {
                                    deleteViewport(rowData)
                                  } else {
                                    swal.fire({
                                      title: 'UNAUTHORIZED',
                                      text: 'You do not have permissions to do this.',
                                      icon: 'error',
                                      dangerMode: false
                                    })
                                  }
                                }}
                              ><DeleteIcon style={{ color: '#D22' }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )
                : (
                  <TableRow>
                    <TableCell colSpan={4} />
                  </TableRow>
                  )}
            </TableBody>
          </Table>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className={isChanged ? commonClasses.btnSuccess : ''}
          disabled={!isChanged || updatingSiteResponsiveness}
          variant='contained'
          startIcon={<CheckCircleIcon />}
          onClick={
            handleSubmit(saveResponsiveness)
          }
        >
          {updatingSiteResponsiveness ? 'Saving...' : 'Save'}
        </Button>
      </CardActions>
    </Paper>
  )
}

export default Responsiveness