/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupstring, object as yupobject } from 'yup'
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Paper,
  IconButton,
  Checkbox
} from '@material-ui/core'
import { Add, Edit, Clear, Check } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  createAdFuelVersion,
  updateAdFuelVersion,
  deleteAdFuelVersion,
  filterAdFuelVersions,
  fetchAdFuelVersions
} from '../../modules/adFuelSlice'
import { commonStyle } from '../../styles'
import { LoadingProgress } from '../ui'
import { request } from '../../utilities/request'

const swal = withReactContent(Swal)

const StyledTableRow = withStyles(theme => ({
  root: {
    verticalAlign: 'top',
    border: 'none'
  }
}))(TableRow)

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  }
}))(TableCell)

const AdFuelVersions = props => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { adFuelVersions, loading } = useSelector(state => state.adFuelSlice)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [adFuelVersionsData, setAdFuelversionsData] = useState({})
  const [selectedItem, setSelectedItem] = useState({})
  const [addingNew, setAddingNew] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const validationSchema = yupobject().shape({
    name: yupstring().required('Required'),
    major: yupstring().required('Required'),
    minor: yupstring().required('Required')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const onSearchChange = e => {
    setSearchTerm(e.target.value)
    dispatch(filterAdFuelVersions(e.target.value))
  }

  // Create new AdFuel Version
  const onAddAdFuelVersion = () => {
    setSelectedItem({ name: '', major: '', minor: '' })
    setAddingNew(true)
    setIsEditing(true)
    setValue('name', '')
    setValue('major', '')
    setValue('minor', '')
  }

  const CreateSlotLocation = () => {
    dispatch(createAdFuelVersion(selectedItem))
    setAddingNew(false)
  }

  // Update AdFuel Version
  const onEditAdFuelVersion = adFuelVersions => {
    setSelectedItem(adFuelVersions)
    setIsEditing(true)
    setAddingNew(false)
    setValue('name', adFuelVersions.name, { shouldValidate: true })
    setValue('major', adFuelVersions.major, { shouldValidate: true })
    setValue('minor', adFuelVersions.minor, { shouldValidate: true })
  }

  const handleSlotLocationChange = e => {
    if (e.target.name === 'name') {
      setSelectedItem({
        ...selectedItem,
        name: e.target.value
      })
      setValue('name', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'major') {
      setSelectedItem({
        ...selectedItem,
        major: e.target.value
      })
      setValue('major', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'minor') {
      setSelectedItem({
        ...selectedItem,
        minor: e.target.value
      })
      setValue('minor', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'revision') {
      setSelectedItem({
        ...selectedItem,
        revision: e.target.value
      })
    } else if (e.target.name === 'patch') {
      setSelectedItem({
        ...selectedItem,
        patch: e.target.value
      })
    } else if (e.target.name === 'releaseNotes') {
      setSelectedItem({
        ...selectedItem,
        releaseNotes: e.target.value
      })
    }
  }

  const handleCheckBoxChange = e => {
    if (e.target.name === 'chkboxBeta') {
      setSelectedItem({
        ...selectedItem,
        isBeta: e.target.checked
      })
    }
    if (e.target.name === 'chkboxHasCOPPA') {
      setSelectedItem({
        ...selectedItem,
        hasCOPPA: e.target.checked
      })
    }
  }

  const UpdateSlotLocation = () => {
    dispatch(updateAdFuelVersion(selectedItem))
    setIsEditing(false)
  }

  // Delete AdFuel Version
  const onDeleteSlotLocation = adFuelVersions => {
    swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, will not be able to recover this record!',
      icon: 'warning',
      showCancelButton: true
      // buttons: true,
      // dangerMode: true
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        request(`/lookups/isInUseAdFuelVersion/${adFuelVersions._id}`, 'GET').then(response => {
          if (response.data && !response.data.isInUse) { 
            dispatch(deleteAdFuelVersion(adFuelVersions))
          } else if ( response.data && response.data.isInUse ) {
            let html = `
            <h3 style="margin: 0; padding: 0; margin-bottom: 5px;">The AdFuel version is in use on the following ${response.data.sitesUsing.length} sites:</h3>
            <ul style="list-style-type: none; margin: 0; padding: 0;">
            `
            response.data.sitesUsing.forEach(site => {
              html = html + '<li style="margin: 0; padding: 0;">'+site.name+'</li>'
            })
            html = html + '</ul>'
            swal.fire(
              {
                title: `UNABLE TO DELETE VERSION`,
                icon: 'error',
                html
              }
            )
          } else {
            swal.fire({
              title: 'Ummm... I dunno.',
              icon: 'question',
              text: JSON.stringify(response)
            })
          }
        })
      }
    })
  }

  const textField = (
    isEditing,
    adFuelVersion,
    textFieldName,
    textFieldDefaultValue,
    handleTextFieldChange,
    error,
    errorMessage,
    type
  ) => {
    if (isEditing && selectedItem._id === adFuelVersion._id) {
      return type === 'number'
        ? (
          <TextField
            type={type}
            inputProps={{ min: '0', max: '100', step: '1' }}
            style={{ width: '100%' }}
            margin='dense'
            defaultValue={textFieldDefaultValue}
            variant='outlined'
            {...register(textFieldName)}
            onChange={e => handleTextFieldChange(e)}
            error={!!error}
            helperText={error ? errorMessage : ''}
          />
          )
        : (
          <TextField
            style={{ width: '100%' }}
            margin='dense'
            defaultValue={textFieldDefaultValue}
            variant='outlined'
            {...register(textFieldName)}
            onChange={e => handleTextFieldChange(e)}
            error={!!error}
            helperText={error ? errorMessage : ''}
          />
          )
    } else {
      return <div className={commonClasses.plainText}>{textFieldDefaultValue}</div>
    }
  }

  const chkbox = (isEditing, selectedItem, adFuelVersion, name='chkboxBeta', property='isBeta') => {
    if (isEditing && selectedItem._id === adFuelVersion._id) {
      return (
        <Checkbox
          checked={selectedItem[property] === true}
          color='primary'
          value='true'
          name={name}
          disabled={!isEditing}
          onChange={e => handleCheckBoxChange(e)}
        />
      )
    } else {
      return (
        <Checkbox
          checked={adFuelVersion[property] === true}
          color='primary'
          value='true'
          name={name}
          disabled={isEditing}
          onChange={e => handleCheckBoxChange(e)}
        />
      )
    }
  }

  useEffect(() => {
    if (adFuelVersions && adFuelVersions.length > 0) {
      setAdFuelversionsData(
        adFuelVersions.map(adFuelVersions => ({ ...adFuelVersions }))
      )
    } else {
      dispatch(fetchAdFuelVersions())
    }
  }, [register, adFuelVersions])

  return (
    <Container maxWidth='xl'>
      <Paper position='static'>
        <form autoComplete='off' noValidate>
          <CardHeader title='AdFuel Versions' />
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label='Find'
                      margin='dense'
                      name='itemFilter'
                      defaultValue={searchTerm}
                      variant='outlined'
                      onChange={onSearchChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={commonClasses.m_1}
                      startIcon={<Add />}
                      onClick={() => {
                        onAddAdFuelVersion()
                      }}
                    >
                      Add New AdFuel Version
                    </Button>
                  </Grid>
                  <Grid item md={2} />
                </Grid>
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardContent>
                <Grid>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '10%', fontWeight: 'bold' }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '5%', fontWeight: 'bold' }}
                        >
                          Beta
                        </TableCell>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '10%', fontWeight: 'bold' }}
                        >
                          Major
                        </TableCell>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '10%', fontWeight: 'bold' }}
                        >
                          Minor
                        </TableCell>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '10%', fontWeight: 'bold' }}
                        >
                          Revision
                        </TableCell>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '10%', fontWeight: 'bold' }}
                        >
                          Patch
                        </TableCell>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '5%', fontWeight: 'bold' }}
                        >
                          Has COPPA
                        </TableCell>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '30%', fontWeight: 'bold' }}
                        >
                          Release Notes
                        </TableCell>
                        <TableCell
                          className={commonClasses.fontWeightBold}
                          style={{ width: '10%', fontWeight: 'bold' }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow className={!addingNew ? commonClasses.hidden : ''}>
                        <StyledTableCell>
                          <TextField
                            margin='dense'
                            id={selectedItem.name}
                            value={selectedItem.name ? selectedItem.name : ''}
                            style={{ width: '100%' }}
                            variant='outlined'
                            {...register('name', {
                              validate: value => value === '' || 'Required'
                            })}
                            onChange={e => handleSlotLocationChange(e)}
                            error={!!errors.name}
                            helperText={errors.name ? errors.name.message : ''}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Checkbox
                            checked={selectedItem.isBeta === true}
                            color='primary'
                            value='true'
                            name='chkboxBeta'
                            onChange={e => handleCheckBoxChange(e)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            type='number'
                            inputProps={{ min: '0', max: '100', step: '1' }}
                            margin='dense'
                            value={selectedItem.major ? selectedItem.major : ''}
                            variant='outlined'
                            style={{ width: '100%' }}
                            {...register('major', {
                              validate: value => value === '' || 'Required'
                            })}
                            onChange={e => handleSlotLocationChange(e)}
                            error={!!errors.major}
                            helperText={errors.major ? errors.major.message : ''}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            type='number'
                            inputProps={{ min: '0', max: '100', step: '1' }}
                            margin='dense'
                            value={selectedItem.minor ? selectedItem.minor : ''}
                            variant='outlined'
                            style={{ width: '100%' }}
                            {...register('minor', {
                              validate: value => value === '' || 'Required'
                            })}
                            onChange={e => handleSlotLocationChange(e)}
                            error={!!errors.minor}
                            helperText={errors.minor ? errors.minor.message : ''}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            type='number'
                            inputProps={{ min: '0', max: '100', step: '1' }}
                            name='revision'
                            margin='dense'
                            value={
                              selectedItem.revision ? selectedItem.revision : ''
                            }
                            variant='outlined'
                            style={{ width: '100%' }}
                            onChange={e => handleSlotLocationChange(e)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            type='number'
                            inputProps={{ min: '0', max: '100', step: '1' }}
                            name='patch'
                            margin='dense'
                            value={selectedItem.patch ? selectedItem.patch : ''}
                            variant='outlined'
                            style={{ width: '100%' }}
                            onChange={e => handleSlotLocationChange(e)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Checkbox
                            checked={selectedItem.hasCOPPA === true}
                            color='primary'
                            value='true'
                            name='chkboxHasCOPPA'
                            onChange={e => handleCheckBoxChange(e)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            name='releaseNotes'
                            margin='dense'
                            value={
                              selectedItem.releaseNotes
                                ? selectedItem.releaseNotes
                                : ''
                            }
                            variant='outlined'
                            style={{ width: '100%' }}
                            onChange={e => handleSlotLocationChange(e)}
                          />
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: 'center' }}>
                          <Grid container>
                            <Grid className={commonClasses.displayFlex} item>
                              <IconButton
                                aria-label='Save'
                                title='Save'
                                onClick={handleSubmit(CreateSlotLocation)}
                              >
                                <Check />
                              </IconButton>
                              <IconButton
                                aria-label='Cancel'
                                variant='contained'
                                title='Cancel'
                                name='cancel'
                                onClick={e => {
                                  setAddingNew(false)
                                }}
                              >
                                <Clear />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                      </StyledTableRow>
                      {adFuelVersionsData.length > 0
                        ? (
                            adFuelVersionsData.map(adFuelVersion => (
                              <React.Fragment key={adFuelVersion._id}>
                                <StyledTableRow>
                                  <StyledTableCell>
                                    {textField(
                                      isEditing,
                                      adFuelVersion,
                                      'name',
                                      adFuelVersion.name,
                                      handleSlotLocationChange,
                                      errors.name,
                                      errors.name ? errors.name.message : ''
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {chkbox(isEditing, selectedItem, adFuelVersion, 'chkboxIsBeta', 'isBeta')}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {textField(
                                      isEditing,
                                      adFuelVersion,
                                      'major',
                                      adFuelVersion.major,
                                      handleSlotLocationChange,
                                      errors.major,
                                      errors.major ? errors.major.message : '',
                                      'number'
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {textField(
                                      isEditing,
                                      adFuelVersion,
                                      'minor',
                                      adFuelVersion.minor,
                                      handleSlotLocationChange,
                                      errors.minor,
                                      errors.minor ? errors.minor.message : '',
                                      'number'
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {textField(
                                      isEditing,
                                      adFuelVersion,
                                      'revision',
                                      adFuelVersion.revision,
                                      handleSlotLocationChange,
                                      errors.revision,
                                      errors.revision ? errors.revision.message : '',
                                      'number'
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {textField(
                                      isEditing,
                                      adFuelVersion,
                                      'patch',
                                      adFuelVersion.patch,
                                      handleSlotLocationChange,
                                      errors.patch,
                                      errors.patch ? errors.patch.message : '',
                                      'number'
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {chkbox(isEditing, selectedItem, adFuelVersion, 'chkboxHasCOPPA', 'hasCOPPA')}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {textField(
                                      isEditing,
                                      adFuelVersion,
                                      'releaseNotes',
                                      adFuelVersion.releaseNotes,
                                      handleSlotLocationChange,
                                      errors.releaseNotes,
                                      errors.releaseNotes
                                        ? errors.releaseNotes.message
                                        : ''
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Grid container>
                                      <Grid className={commonClasses.displayFlex} item>
                                        <IconButton
                                          aria-label='Save'
                                          variant='contained'
                                          className={
                                      isEditing &&
                                      selectedItem._id === adFuelVersion._id
                                        ? ''
                                        : commonClasses.hidden
                                    }
                                          name='Save'
                                          title='Save'
                                          onClick={handleSubmit(UpdateSlotLocation)}
                                        >
                                          <Check />
                                        </IconButton>
                                        <IconButton
                                          aria-label='Cancel'
                                          variant='contained'
                                          className={
                                      isEditing &&
                                      selectedItem._id === adFuelVersion._id
                                        ? ''
                                        : commonClasses.hidden
                                    }
                                          title='Cancel'
                                          onClick={e => {
                                            e.stopPropagation()
                                            setIsEditing(false)
                                          }}
                                        >
                                          <Clear />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                    <Grid container>
                                      <Grid className={commonClasses.displayFlex} item>
                                        <IconButton
                                          aria-label='Edit'
                                          variant='contained'
                                          className={
                                      isEditing &&
                                      selectedItem._id === adFuelVersion._id
                                        ? commonClasses.hidden
                                        : commonClasses.editIconColor
                                    }
                                          name='Edit'
                                          title='Edit'
                                          onClick={e => {
                                            onEditAdFuelVersion(adFuelVersion)
                                          }}
                                        >
                                          <Edit />
                                        </IconButton>
                                        <IconButton
                                          aria-label='Delete'
                                          variant='contained'
                                          className={
                                      isEditing &&
                                      selectedItem._id === adFuelVersion._id
                                        ? commonClasses.hidden
                                        : commonClasses.editIconColor
                                    }
                                          name='Delete'
                                          title='Delete'
                                          onClick={e => {
                                            onDeleteSlotLocation(adFuelVersion)
                                          }}
                                        >
                                          <DeleteIcon className={commonClasses.deleteIconColor} />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </StyledTableCell>
                                </StyledTableRow>
                              </React.Fragment>
                            ))
                          )
                        : (
                          <TableRow>
                            <TableCell colSpan={8}>
                              {adFuelVersionsData.length === 0 && loading && (
                                <LoadingProgress
                                  circular={false}
                                  label='Loading Registries'
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          )}
                    </TableBody>
                  </Table>
                </Grid>
              </CardContent>
            </Card>
          </CardContent>
        </form>
      </Paper>
    </Container>
  )
}

export default AdFuelVersions
