import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllCustomTargets, refreshReloadDfpAdUnits } from '../../modules/DFPAdUnitSlice'
import { login, logout } from '../../modules/authSlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { NavLink } from 'react-router-dom'
import { SimpleMenu, ItemContent } from './index'
import { makeStyles, Toolbar, Box, Typography, Hidden, IconButton, Button, Drawer, List, ListItem, Divider } from '@material-ui/core'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
import SyncIcon from '@material-ui/icons/Sync'
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import CheckCircle from '@material-ui/icons/CheckCircle'
import HighlightOff from '@material-ui/icons/HighlightOff'

import { clone } from '../../utilities/helperFunctions.js'

let env = document.location.hostname.startsWith('rocketeer-ui-dev.') || document.location.hostname.startsWith('dev.') || document.location.hostname.startsWith('localhost') || document.location.hostname.startsWith('ui.')
  ? 'DEVELOPMENT'
  : (
      document.location.hostname.startsWith('qa.')
        ? 'QA'
        : 'PRODUCTION'
    )
if (document.location.hostname.startsWith('localhost')) env = 'local'

const swal = withReactContent(Swal)

const useStyles = makeStyles(theme => ({
  root: {
    '& button': {
      color: theme.palette.primary.contrastText,
      fontSize: '1.2em'
    }
  },
  appTitle: {
    flexGrow: 1,
    fontFamily: '"Maverick", cursive !important',
    '& *': {
      fontFamily: '"Maverick", cursive !important'
    },
    fontSize: '2.5em',
    fontWeight: 'bold'
  }
}))

export const Navbar = ({ darkMode, setDarkMode, betaTest, setBetaTest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [drawerPosition, setDrawerPosition] = useState({ top: false, left: false, bottom: false, right: false })
  const { loading, user, canUseBulkLineItemCreation, hasDFPAccess, isAdmin, isBetaTester } = useSelector(state => state.authReducer)
  const [currentUser, setCurrentUser] = useState(clone(user))

  useEffect(() => {
    setCurrentUser(user)
  }, [user])

  const applyLightTheme = () => {
    setDarkMode(false)
    window.localStorage.setItem('theme', 'light')
  }
  const applyDarkTheme = () => {
    setDarkMode(true)
    window.localStorage.setItem('theme', 'dark')
  }

  const addToBetaTest = () => {
    setBetaTest(true)
    window.localStorage.setItem('beta', 'true')
    window.location.reload()
  }

  const removeFromBetaTest = () => {
    setBetaTest(false)
    window.localStorage.setItem('beta', 'false')
    window.location.reload()
  }

  const sitesTab = { text: 'Sites', url: '/sites' }
  const adLoaderTab = { text: 'Ad Loader', url: '/adLoader' }
  const resourcesTab = canUseBulkLineItemCreation
    ? {
        title: 'Resources',
        links: [
          { text: 'CDN Purge Tool', url: '/support/cdnPurge' },
          { text: 'GAM Custom Targets', url: '/support/customTargets' },
          { text: 'Bulk Line Item Creation', url: '/support/bulkLineItem' },
          { text: 'POS Targeting Report', url: '/support/POSTargetingReport' }// ,
          // { text: 'GAM API', url: '/admin/gamApi' }
        ]
      }
    : {
        title: 'Resources',
        links: [
          { text: 'CDN Purge Tool', url: '/support/cdnPurge' },
          { text: 'GAM Custom Targets', url: '/support/customTargets' }
        ]
      }
  if (hasDFPAccess) {
    resourcesTab.links.push({ text: 'GAM API', url: '/support/gamApi'})
  }
  const adminTab = {
    title: 'Admin',
    links: [
      { text: 'AdFuel Versions', url: '/admin/adFuelVersions' },
      { text: 'AdFuel Modules', url: '/admin/adFuelModules' },
      { text: 'AdFuel Options', url: '/admin/adFuelOptions' },
      { text: 'Ad Loader CDN Folders', url: '/admin/cdnFolders' },
      { text: 'Banner Message', url: '/admin/bannerMessage' },
      { text: 'Email Users', url: '/admin/emailUsers' },
      { text: 'GAM Cache', url: '/admin/dfpAdmin' },
      // { text: 'GAM API', url: '/admin/gamAPI' },
      { text: 'Reporting', url: '/admin/reports' },
      { text: 'Permissions', url: '/admin/permissions' },
      { text: 'PublishersRoot', url: '/admin/publisherRoot' },
      { text: 'Roles', url: '/admin/roles' },
      { text: 'Site Categories', url: '/admin/categories' },
      { text: 'Slot Types', url: '/admin/slotTypes' },
      { text: 'Slot Locations', url: '/admin/locations' },
      { text: 'Users', url: '/admin/users' }
    ]
  }
  const refreshTab = {
    title: 'Refresh',
    links: [
      {
        text: 'Ad Units',
        action: async () => {
          dispatch(refreshReloadDfpAdUnits(true, true))
          swal.fire({
            icon: 'success',
            title: 'Ad Units refresh request sent.',
            text: 'Please allow up to 15 minutes for this process to complete.'
            // timer: 1300
          })
        },
        render: () => (<><SyncIcon />&nbsp;Refresh Ad Units</>)
      },
      {
        text: 'Custom Targets',
        action: async () => {
          dispatch(getAllCustomTargets(true, true))
          swal.fire({
            icon: 'success',
            title: 'Custom Targets refresh request sent.',
            text: 'Please allow up to 15 minutes for this process to complete.'
            // timer: 1300
          })
        },
        render: () => (<><SyncIcon />&nbsp;Refresh Custom Targets</>)
      }
    ]
  }
  const accountTab = isBetaTester ? {
    title: currentUser ? (currentUser.displayName || `${currentUser.lastName}, ${currentUser.firstName}`) : 'ACCOUNT',
    links: [
      {
        text: 'Profile',
        url: '/profile',
        render: () => (<><AccountCircleIcon />&nbsp;Profile</>)
      },
      {
        text: 'Light Switch',
        action: () => { darkMode ? applyLightTheme() : applyDarkTheme() },
        render: () => (<>{darkMode ? <Brightness7Icon /> : <Brightness4Icon />}&nbsp;Dark Theme: {darkMode ? 'On' : 'Off'}</>)
      },
      {
        text: 'Beta Test',
        action: () => { betaTest ? removeFromBetaTest() : addToBetaTest() },
        render: () => (<>{betaTest ? <CheckCircle /> : <HighlightOff />}&nbsp;Beta Test: {betaTest ? 'On' : 'Off'}</>)
      },
      {
        text: 'Log Out',
        url: '/',
        action: () => logout(),
        render: () => (<><ExitToAppIcon />&nbsp;Log Out</>)
      }
    ]
  } : {
    title: currentUser ? currentUser.displayName : 'ACCOUNT',
    links: [
      {
        text: 'Profile',
        url: '/profile',
        render: () => (<><AccountCircleIcon />&nbsp;Profile</>)
      },
      {
        text: 'Light Switch',
        action: () => { darkMode ? applyLightTheme() : applyDarkTheme() },
        render: () => (<>{darkMode ? <Brightness7Icon /> : <Brightness4Icon />}&nbsp;Dark Theme: {darkMode ? 'On' : 'Off'}</>)
      },
      {
        text: 'Log Out',
        url: '/',
        action: () => logout(),
        render: () => (<><ExitToAppIcon />&nbsp;Log Out</>)
      }
    ]
  }
  const navTabs = []
  navTabs.push(sitesTab)
  navTabs.push(adLoaderTab)
  navTabs.push(resourcesTab)
  if (isAdmin) { navTabs.push(adminTab) }
  navTabs.push(refreshTab)

  if (currentUser) { navTabs.push(accountTab) }

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) { return }
    setDrawerPosition({ [side]: open })
  }

  const performAction = (action) => { if (action) { action() } }

  const displaySideList = side => (
    <Box role='presentation' onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)}>
      {navTabs.map((tab, tabIndex) => {
        return tab.title
          ? (
            <Box key={tabIndex}>
              <List>
                {tab.links.map((link, linkIndex) => {
                  if (!link.url) {
                    return (
                      <ListItem button onClick={() => performAction(link.action)} key={linkIndex}>
                        <ItemContent link={link} />
                      </ListItem>
                    )
                  } else {
                    return (
                      <NavLink to={link.url} key={linkIndex}>
                        <ListItem button onClick={() => performAction(link.action)}>
                          <ItemContent link={link} />
                        </ListItem>
                      </NavLink>
                    )
                  }
                })}
              </List>
              <Divider />
            </Box>
            )
          : (
            <Box key={tabIndex}>
              <List>
                <NavLink to={tab.url || '/'}>
                  <ListItem button>{tab.text}</ListItem>
                </NavLink>
              </List>
              <Divider />
            </Box>
            )
      })}
    </Box>
  )

  const displayTabs = () => {
    return currentUser
      ? (
        <>
          <Hidden smDown>
            {navTabs.map((tab, index) => {
              return tab.title
                ? (
                  <SimpleMenu tab={tab} key={index} />
                  )
                : (
                  <NavLink key={index} to={tab.url || '/'}><Button>{tab.text}</Button></NavLink>
                  )
            })}
          </Hidden>
          <Hidden mdUp>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={toggleDrawer('right', true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor='right'
              open={drawerPosition.right}
              onClose={toggleDrawer('right', false)}
            >
              {displaySideList('right')}
            </Drawer>
          </Hidden>
        </>
        )
      : (
        <Button onClick={login}>Sign In</Button>
        )
  }

  return (
    <Toolbar className={classes.root} variant='dense'>
      <Typography variant='h1' className={classes.appTitle}>
        <NavLink to='/'><img src='/images/rocketeer_title.png' height='50' style={{ position: 'relative', top: '5px' }} /></NavLink>
      </Typography>
      <Typography variant='h5' style={{ flexGrow: 1 }}>
        Environment: <span style={env === 'PRODUCTION' ? { fontWeight: 'bold', color: 'red' } : env === 'QA' ? { fontWeight: 'bold', color: 'yellow' } : { fontWeight: 'bold', color: 'green' }}>{env.toUpperCase()}</span>
        {betaTest ? <>&nbsp;&nbsp;-&nbsp;&nbsp;<span style={{fontWeight: 'bold', color: 'red' }}>{'BETA UI'}</span></> : ''}
      </Typography>
      {!loading && displayTabs()}
    </Toolbar>
  )
}
