import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'
import {
  FetchRegistryById,
  getAllAdUnits,
  addNewRegistrySlot,
  UpdateCurrentRegistry
} from './slotSlice'
import {
  filterByValue,
  registryNameFormatter,
  cacheBuster
} from '../utilities/helperFunctions'

const swal = withReactContent(Swal)

const initialState = {
  registries: [],
  orgRegistries: [],
  singletons: [],
  selected_registry: {},
  registry: {},
  selectedItemId: null,
  totalRegistriesToDeploy: 0,
  deployedRegistries: [],
  isDeleting: false,
  isDeploying: false,
  isUpdating: false,
  isNew: false,
  isChildDirectedTreatmentDialogOpen: false,
  isInViewRefreshDialogOpen: false,
  isCustomTargetingDialogOpen: false,
  isCopyRegistry: false,
  inViewRefresh: false,
  realTimeAdInsertion: false,
  hasSafeFrameConfig: false,
  newRegistryResource: {
    name: null,
    description: null,
    hasInViewRefresh: false,
    hasRealTimeAdInsertion: false,
    rtaiMaxNoOfAds: 6,
    rtaiBuffer: 4,
    rtaiOffset: 2,
    rtaiInitialPlacement: 4,
    rtaiParent: '.article_content',
    isActive: true,
    rootAdUnitId: '',
    isSingleton: true,
    isRegistryMinified: false,
    areLinksSecure: false,
    siteId: null,
    isChangedSinceDeployment: false,
    safeFrameAllowOverlayExpansion: false,
    safeFrameConfig: false,
    safeFrameSandboxMode: false,
    tagForChildDirectedTreatment: false,
    slots: [],
    targetings: []
  },
  loading: false,
  toast: {
    isOpen: false,
    message: '',
    variant: 'info'
  },
  posTargetings: []
}

const registrySlice = createSlice({
  name: 'registrySlice',
  initialState,
  reducers: {
    RegistryLoadingStatus: state => {
      state.loading = true
    },
    FetchSiteRegistries: (state, action) => {
      state.loading = false
      state.registries = action.payload
      state.orgRegistries = action.payload
    },

    FetchSiteRegistryById: (state, action) => {
      state.loading = false
      state.registry = action.payload
      state.realTimeAdInsertion = state.registry.hasRealTimeAdInsertion
      state.inViewRefresh = state.registry.hasInViewRefresh
      state.hasSafeFrameConfig = state.registry.safeFrameConfig
    },

    FetchPosTargets: (state, action) => {
      console.log('action.payload', action.payload)
      state.loading = false
      state.posTargetings = action.payload
    },

    AddSiteRegistry: (state, action) => {
      if (action.payload.validationErrors !== undefined) {
        swal.fire({
          title: 'Validation Error!',
          text: action.payload.validationErrors[0],
          icon: 'error'
        })
      } else {
        const orgRegistries = state.orgRegistries.slice()
        orgRegistries.unshift(action.payload)
        state.registries = orgRegistries

        state.isNew = false
        swal.fire({
          title: 'Registry Added!',
          text: 'Registry added successfully.',
          icon: 'success'
        })
      }
      state.loading = false
    },

    CloneRegistryResource: (state, action) => {
      state.loading = false
      state.isCopyRegistry = true
    },

    AddCommentToHistory: (state, action) => {
      state.registry.history = [
        action.payload.newHistoryItem,
        ...state.registry.history
      ]
      state.loading = false
      swal.fire({
        title: 'History Added!',
        text: `${action.payload.newHistoryItem.newValue} added successfully.`,
        icon: 'success'
      })
    },

    UpdateSiteRegistry: (state, action) => {
      if (action.payload.validationErrors !== undefined) {
        swal.fire({
          title: 'Validation Error!',
          text: action.payload.validationErrors[0],
          icon: 'error'
        })
      } else {
        state.registry = action.payload
        state.loading = false
        swal.fire({
          title: 'Registry Updated!',
          text: `${action.payload.name} updated successfully.`,
          icon: 'success'
        })
      }
    },

    UpdateRegistry: (state, action) => {
      state.registry = action.payload
      state.loading = false
    },

    UpdateSiteRegistrySettings: (state, action) => {
      const registries = state.registries.slice()
      const updatedItemIndex = registries.findIndex(
        registry => registry._id === action.payload._id
      )
      registries[updatedItemIndex] = action.payload
      state.registries = registries
      state.orgRegistries = registries
      state.toast = {
        isOpen: true, // registry updated by c-hclent-rkumarsingh: AS_bumps_build
        message: `registry updated by ${action.payload.modifiedBy}: ${action.payload.name}.`,
        variant: 'success'
      }
    },

    UpdateRegistryForSlot: (state, action) => {
      state.registry = action.payload
    },

    DeleteSiteRegistry: (state, action) => {
      const registries = state.registries
        .slice()
        .filter(registry => registry._id !== action.payload._id)
      state.registries = registries
      state.orgRegistries = registries
      state.isDeleting = false
      swal.fire({
        title: 'Registry Deleted!',
        text: `${action.payload.name} deleted successfully.`,
        icon: 'success'
      })
    },

    DeploySiteRegistry: (state, action) => {
      const registries = state.registries.slice()
      const updatedItemIndex = registries.findIndex(
        registry => registry._id === action.payload._id
      )
      registries[updatedItemIndex] = action.payload
      state.registry = action.payload
      state.registries = registries
      state.orgRegistries = registries
      state.isDeploying = false
      state.toast = {
        isOpen: true,
        message: `${action.payload.name} deployed successfully.  ${state
          .deployedRegistries.length + 1} out of ${
          state.totalRegistriesToDeploy
        } Registries  deployed successfully.`,
        variant: 'success'
      }
    },

    TotalRegistriesToDeploy: (state, action) => {
      state.deployedRegistries = []
      state.totalRegistriesToDeploy = action.payload
    },

    DeployedRegistries: (state, action) => {
      state.deployedRegistries.push(action.payload)
    },

    FilterSiteRegistries: (state, action) => {
      const results = filterByValue(state.orgRegistries, action.payload)
      state.loading = false
      state.registries = results
    },

    SelectedItemId: (state, action) => {
      state.selectedItemId = action.payload
    },

    DeployingStatus: state => {
      state.isDeploying = true
    },

    UpdatingStatus: (state, action) => {
      state.isUpdating = action.payload
    },

    ToggleDeletingingStatus: state => {
      state.isDeleting = !state.isDeleting
    },

    ToggleCopyRegistryStatus: state => {
      state.isCopyRegistry = !state.isCopyRegistry
    },

    ToggleNewRegistryStatus: (state, action) => {
      state.isNew = !state.isNew
      if (state.isNew) {
        state.registry = action.payload
      }
    },

    ToggleChildDirectedTreatmentDialog: state => {
      state.isChildDirectedTreatmentDialogOpen = !state.isChildDirectedTreatmentDialogOpen
    },

    ToggleInViewRefreshDialog: state => {
      state.isInViewRefreshDialogOpen = !state.isInViewRefreshDialogOpen
    },

    ToggleCustomTargetingDialog: state => {
      state.isCustomTargetingDialogOpen = !state.isCustomTargetingDialogOpen
    },

    ToggleInViewRefresh: (state, action) => {
      console.log('Setting IVR Payload: ', action.payload)
      state.inViewRefresh = action.payload
    },

    ToggleRealTimeAdInsertion: (state, action) => {
      state.realTimeAdInsertion = action.payload
    },

    ToggleRealTimeAdInsertionDialog: state => {
      state.isRealTimeAdInsertionDialogOpen = !state.isRealTimeAdInsertionDialogOpen
    },

    ToggleSafeframeConfig: (state, action) => {
      state.hasSafeFrameConfig = action.payload
    },

    GetSelectedRegistry: (state, action) => {
      state.loading = false
      state.selected_registry = action.payload
    },

    RegistryError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    },

    Reset: (state, action) => {
      state.loading = false
      state.selected_registry = initialState.selected_registry
      state.registries = initialState.registries
      state.singletons = initialState.singletons
    },

    SetSiteId: (state, action) => {
      state.registriesSiteId = action.payload
    },

    CloseToast: state => {
      state.toast = {
        isOpen: false,
        message: '',
        variant: 'info'
      }
    }
  }
})

export default registrySlice.reducer
export const {
  SetSiteId,
  RegistryLoadingStatus,
  GetSelectedRegistry,
  FetchSiteRegistries,
  FetchSiteRegistryById,
  FetchPosTargets,
  AddSiteRegistry,
  CloneRegistryResource,
  AddCommentToHistory,
  SelectedItemId,
  UpdateRegistry,
  UpdateSiteRegistry,
  UpdateSiteRegistrySettings,
  UpdateRegistryForSlot,
  DeleteSiteRegistry,
  DeploySiteRegistry,
  TotalRegistriesToDeploy,
  DeployingStatus,
  UpdatingStatus,
  ToggleDeletingingStatus,
  ToggleNewRegistryStatus,
  ToggleChildDirectedTreatmentDialog,
  ToggleInViewRefreshDialog,
  ToggleCustomTargetingDialog,
  ToggleCopyRegistryStatus,
  ToggleInViewRefresh,
  ToggleRealTimeAdInsertion,
  ToggleRealTimeAdInsertionDialog,
  ToggleSafeframeConfig,
  DeployedRegistries,
  FilterSiteRegistries,
  RegistryError,
  Reset,
  CloseToast
} = registrySlice.actions

export const registryImplementationProcedureUrl = (
  siteName,
  includeCacheBuster,
  isSecured,
  registry,
  publishers
) => {
  let result = ''
  // var rootSiteUrl = config[config.env].publisher.rootSiteUrl
  let rootSiteUrl = publishers?.rootSiteUrl
  if (isSecured) {
    rootSiteUrl = rootSiteUrl.replace('http://i.', 'https://i.')
  }
  if (registry.lastDeployedOn) {
    if (registry.isSingleton) {
      result =
        rootSiteUrl.replace('{siteName}', siteName) +
        '/singles/' +
        registryNameFormatter(registry.name) +
        '.txt'
    } else {
      result =
        rootSiteUrl.replace('{siteName}', siteName) +
        '/' +
        registryNameFormatter(registry.name) +
        '.txt'
    }
  }
  if (includeCacheBuster) {
    result += '?cacheBuster=' + cacheBuster()
  }
  return result
}

export const registryUrl = (
  siteName,
  includeCacheBuster,
  isMinified,
  isSecured,
  registry,
  publishers
) => {
  let result = ''
  // var rootSiteUrl = config[config.env].publisher.rootSiteUrl
  let rootSiteUrl = publishers?.rootSiteUrl
  if (isSecured) {
    rootSiteUrl = rootSiteUrl.replace('http://i.', 'https://i.')
  }
  if (registry.lastDeployedOn) {
    if (registry.isSingleton) {
      result =
        rootSiteUrl.replace('{siteName}', siteName) +
        '/singles/' +
        registryNameFormatter(registry.name) +
        '.json'
    } else {
      result =
        rootSiteUrl.replace('{siteName}', siteName) +
        '/' +
        registryNameFormatter(registry.name) +
        '.json'
    }
  }
  if (includeCacheBuster) {
    result += '?cacheBuster=' + cacheBuster()
  }
  return result
}

export const reset = () => {
  return async dispatch => {
    return dispatch(Reset())
  }
}

export const fetchRegistriesForSite = (siteId, includeSlots) => {
  return async dispatch => {
    dispatch(RegistryLoadingStatus())
    return request('/registries', 'GET', null, {
      siteId,
      includeSlots
    })
      .then(res => {
        dispatch(FetchSiteRegistries(res.data))
        dispatch(getAllAdUnits())
      })
      .catch(err => dispatch(RegistryError(err.message)))
  }
}

export const fetchSiteRegistryById = registryId => {
  return async dispatch => {
    dispatch(RegistryLoadingStatus())
    return request(`/registries/${registryId}`)
      .then(res => {
        dispatch(FetchSiteRegistryById(res.data))
        dispatch(FetchRegistryById(res.data))
      })
      .catch(err => dispatch(RegistryError(err)))
  }
}

export const addSiteRegistryComment = (registryId, comment) => {
  return async dispatch => {
    dispatch(RegistryLoadingStatus())
    return request(`/registries/comment/${registryId}`, 'POST', {
      comment
    })
      .then(res => {
        dispatch(AddCommentToHistory(res.data))
      })
      .catch(err => dispatch(RegistryError(err)))
  }
}

export const addNewRegistry = registry => {
  return async dispatch => {
    dispatch(ToggleNewRegistryStatus(registry))
    dispatch(addNewRegistrySlot(registry))
  }
}

export const addSiteRegistry = registry => {
  return async dispatch => {
    dispatch(RegistryLoadingStatus())
    return request('/registries', 'POST', registry)
      .then(res => dispatch(AddSiteRegistry(res.data)))
      .catch(err => dispatch(RegistryError(err)))
  }
}

export const cloneRegistryResource = (registry, inviewRefreshEditor) => {
  return async dispatch => {
    dispatch(RegistryLoadingStatus())
    return request(`/registries/${registry._id}`)
      .then(res => {
        const registryClone = res.data
        // set _temp_id, so multiple new registries can be managed
        let tempId = ''
        for (let i = 0; i < 9; i++) {
          tempId += Math.floor(Math.random() * 16).toString(16)
        }
        registryClone._temp_id = tempId
        registryClone.isClone = true
        // clear id, history, version, deployment, slot history
        delete registryClone._id
        delete registryClone.history
        delete registryClone.__v
        if (registryClone.deployedLocationUrl) {
          delete registryClone.deployedLocationUrl
        }
        delete registryClone.lastDeployedOn
        delete registryClone.isChangedSinceDeployment
        registryClone.slots.forEach(slot => {
          delete slot.history
        })
        // clear name
        registryClone.name = ''
        if (!inviewRefreshEditor) {
          registryClone.hasInViewRefresh = false
          registryClone.slots.map(slot => {
            slot.hasInViewRefresh = false
            return slot
          })
        }
        dispatch(CloneRegistryResource(registryClone))
        dispatch(ToggleNewRegistryStatus(registryClone))
        dispatch(addNewRegistrySlot(registryClone))
      })
      .catch(err => dispatch(RegistryError(err)))
  }
}

export const updateSiteRegistry = registry => {
  return async dispatch => {
    dispatch(RegistryLoadingStatus())
    console.log("[registrySlice] ========== modules::UPDATING SITE REGISTRY: ", registry)
    return request(`/registries/${registry._id}`, 'POST', registry)
      .then(res => {
        dispatch(UpdateSiteRegistry(res.data))
        dispatch(UpdateCurrentRegistry(res.data))
      })
      .catch(err => dispatch(RegistryError(err)))
  }
}

export const updateSiteRegistrySettings = (
  registries,
  objForChildDirectedTreatment,
  objForInViewRefresh,  
  objBulkCustomTargets,
  objForRealTimeAdInsertion
) => {
  return async dispatch => {
    dispatch(UpdatingStatus(true))
    for (const registry of registries) {
      let _registry = null
      await request(`/registries/${registry._id}`)
        .then(res => {
          _registry = res.data
          if (objForInViewRefresh) {
            // true
            _registry.hasInViewRefresh = objForInViewRefresh.inViewRefresh
            if (objForInViewRefresh.inViewRefresh) {
              _registry.inViewRefreshCount = parseInt(objForInViewRefresh.refreshCount, 10)
              _registry.inViewRefreshInterval = parseInt(objForInViewRefresh.refreshInterval, 10)
              _registry.inViewDisplayPercentage = parseInt(objForInViewRefresh.displayPercentage, 10)
              _registry.slots.forEach(element => {
                element.hasInViewRefresh = false
                element.inViewRefreshCount = parseInt(objForInViewRefresh.refreshCount, 10)
                element.inViewDisplayPercentage = parseInt(objForInViewRefresh.displayPercentage, 10)
                element.inViewRefreshInterval = parseInt(objForInViewRefresh.refreshInterval, 10)
              })
            }
          }
          if (objForChildDirectedTreatment) {
            _registry.objForChildDirectedTreatment = objForChildDirectedTreatment
          }
          if (objBulkCustomTargets) {
            const finalTargeting = []
            for (let y = 0; y < _registry.targetings.length; y++) {
              const target = _registry.targetings[y]
              if (objBulkCustomTargets.indexOf(target) === -1) {
                finalTargeting.push(target)
              }
            }
            objBulkCustomTargets.forEach(bulkTarget => {
              if (bulkTarget.key !== undefined) {
                bulkTarget.values.forEach(value => {
                  const targetingObject = {
                    key: bulkTarget.key,
                    value: value.name
                  }
                  finalTargeting.push(targetingObject)
                })
              }
            })
            _registry.targetings = finalTargeting
          }
          if (objForRealTimeAdInsertion) {
            // true
            _registry.hasRealTimeAdInsertion = parseInt(objForRealTimeAdInsertion.realTimeAdInsertion, 10)
            _registry.rtaiMaxNoOfAds = parseInt(objForRealTimeAdInsertion.rtaiMaxNoOfAds, 10)
            _registry.rtaiBuffer = parseInt(objForRealTimeAdInsertion.rtaiBuffer, 10)
            _registry.rtaiOffset = parseInt(objForRealTimeAdInsertion.rtaiOffset, 10)
            _registry.rtaiInitialPlacement = parseInt(objForRealTimeAdInsertion.rtaiInitialPlacement, 10)
            _registry.rtaiParentSelector = objForRealTimeAdInsertion.rtaiParentSelector
          } else {
            _registry.hasRealTimeAdInsertion = false
            _registry.rtaiMaxNoOfAds = 0
            _registry.rtaiBuffer = 0
            _registry.rtaiOffset = 0
            _registry.rtaiInitialPlacement = 0
            _registry.rtaiParentSelector = ''            
          }
        })
        .catch(err => dispatch(RegistryError(err)))
      await request(`/registries/${registry._id}`, 'POST', _registry)
        .then(res => {
          dispatch(UpdateSiteRegistrySettings(res.data))
        })
        .catch(err => dispatch(RegistryError(err)))
    }
    dispatch(UpdatingStatus(false))

    if (objForChildDirectedTreatment) {
      dispatch(ToggleChildDirectedTreatmentDialog())
    } else if (objForInViewRefresh) {
      dispatch(ToggleInViewRefreshDialog())
    } else if (objBulkCustomTargets) {
      dispatch(ToggleCustomTargetingDialog())
    } else if (objForRealTimeAdInsertion) {
      dispatch(ToggleRealTimeAdInsertionDialog())
    }
  }
}

export const deleteSiteRegistry = registryId => {
  return async dispatch => {
    dispatch(ToggleDeletingingStatus())
    return request(`/registries/${registryId}`, 'DELETE')
      .then(res => dispatch(DeleteSiteRegistry(res.data)))
      .catch(err => dispatch(RegistryError(err)))
  }
}

export const deploySiteRegistry = registryId => {
  return async dispatch => {
    dispatch(TotalRegistriesToDeploy(1))
    dispatch(DeployingStatus())
    dispatch(SelectedItemId(registryId))
    return request(`/registries/deploy/${registryId}`, 'POST')
      .then(res => dispatch(DeploySiteRegistry(res.data)))
      .catch(err => dispatch(RegistryError(err)))
  }
}

export const deploySiteRegistries = siteRegistries => {
  return async dispatch => {
    dispatch(TotalRegistriesToDeploy(siteRegistries.length))
    for (const siteRegistry of siteRegistries) {
      dispatch(DeployingStatus())
      dispatch(SelectedItemId(siteRegistry._id))
      await request(`/registries/deploy/${siteRegistry._id}`, 'POST', null)
        .then(res => {
          dispatch(DeploySiteRegistry(res.data))
          dispatch(DeployedRegistries(siteRegistry._id))
        })
        .catch(err => dispatch(RegistryError(err)))
    }
  }
}

export const filterSiteRegistries = searchTerm => {
  return async dispatch => {
    dispatch(RegistryLoadingStatus())
    dispatch(FilterSiteRegistries(searchTerm))
  }
}

export const deleteRegistrySlot = registry => {
  return async dispatch => {
    return request(`/registries/${registry._id}`, 'POST', registry)
      .then(res => dispatch(UpdateRegistryForSlot(res.data)))
      .catch(err => dispatch(RegistryError(err)))
  }
}

export const updateSlots = ({ type, data }) => {
  return request(`/registries/updateSlots?type=${type}`, 'POST', data)
    .then(res => res)
    .catch(err => err)
}

export const getSlotsDataFile = (siteId, type) => {
  return request(`/registries/slotsDataFile?type=${type}&siteId=${siteId}`)
    .then(res => res)
    .catch(err => err)
}

export const clearRegistries = () => {
  return async dispatch => {
    dispatch(FetchSiteRegistries([]))
  }
}

export const getPosTargets = (posValue, unitValue) => {
  return async dispatch => {
    console.log('In Slice')
    dispatch(RegistryLoadingStatus())
    const uri = unitValue === '' ? `/registries/pos/${posValue}` : `/registries/pos/${posValue}/${unitValue}`
    console.log('In URI', uri)
    return request(uri)
      .then(res => {
        console.log('res', res.data)
        dispatch(FetchPosTargets(res.data))
      })
      .catch(err => dispatch(RegistryError(err)))
  }
}

// #endregion
