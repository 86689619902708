import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { addClasses, commonStyle } from '../../styles'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Box, Button, Card, CardHeader, CardContent, Checkbox, Divider, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Typography } from '@material-ui/core'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Add from '@material-ui/icons/Add'
import SlotDetails from './SlotDetails'

const swal = withReactContent(Swal)

const useStyle = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 170
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  itemRight: { textAlign: 'right', float: 'right' },
  itemCenter: { textAlign: 'Center' },
  ConfigurationItemContainer: {
    paddingTop: '4px'
  },
  ConfigurationItems: {
    margin: '2px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '95%',
    border: 'none',
    boxShadow: 'none'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeaderCell: {
    background: '#777',
    color: 'white',
    fontWeight: 'bolder',
  }  
}))

const SlotList = ({ slots, selectedSlot, setSelectedSlot }) => {
  const classes = useStyle()
  const commonClasses = commonStyle()
  const [searchTerm, setSearchTerm] = useState()
  const [isRegistryChanged, setIsRegistryChagned] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentSlot, setCurrentSlot] = useState(selectedSlot)
  const [selected, setSelected] = useState([]);

  const isSelected = (name) => selected === name;

  const onSearchChange = (event) => {
    console.log('[SlotList] ---------- onSearchChange:event', event)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = slots.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };  

  
  const handleClick = (event, name) => {
    console.log('Name: ', name)
    console.log('Selected: ', selected)
    const selectedIndex = selected?.indexOf(name);
    console.log('Selected Index: ', selectedIndex)
    let newSelected = null;

    if (selectedIndex === -1 || selected === null) {
      newSelected = name;
    }
    console.log('New Selected: ', newSelected)
    setSelected(newSelected);
    setSelectedSlot(slots.filter(n => n.name === newSelected)[0])
  };
  
  console.log('[SlotList] ---------- render::slots', slots)
  return (
    // <>
    //   <Grid container style={{ marginBottom: '10pt' }}>
    //     <Grid item xl={10} lg={9} md={8} xs={6}>
    //       <TextField
    //         style={{ width: '99%' }}
    //         label='Find'
    //         margin='dense'
    //         size="small"
    //         name='itemFilter'
    //         defaultValue={searchTerm}
    //         variant='outlined'
    //         onChange={onSearchChange}
    //       />
    //     </Grid>
    //     <Grid item xl={2} lg={3} md={4} xs={6}>
    //       <Button
    //         disabled={isRegistryChanged || loading}
    //         variant='contained'
    //         color='primary'
    //         margin='dense'
    //         size="small"
    //         className={[
    //           commonClasses.btnSuccess,
    //           commonClasses.m_1
    //         ].join(' ')}
    //         startIcon={<Add />}
    //         onClick={() => onAddSlot()}
    //       >
    //         Add Slot
    //       </Button>
    //     </Grid>
    //   </Grid>
      
    //   {/* <Grid container>
    //     <Grid item xs={4}>Name</Grid>
    //     <Grid item xs={4}>Ad Unit</Grid>
    //     <Grid item xs={2}>Is Fluid?</Grid>
    //     <Grid item xs={2}></Grid>
    //   </Grid> */}

    //   {/* <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
    //     {slots?.map(item => {
    //       let nameBits = item.name.split('_')
    //       let type = nameBits[1]
    //       let location = nameBits[2]
    //       return (
    //         <>
    //           <Grid item xs={4}>
    //             <Button
    //               variant="contained"
    //               style={{display: 'block', textAlign: 'left', width: '100%', padding: '0px'}}                
    //             >
    //               <Grid container style={{padding: '5px'}}>
    //                 <Grid item xs={12}>
    //                   <Typography variant="h4"><b>Name:</b>&nbsp;&nbsp;{item.name}</Typography>
    //                 </Grid>
    //               </Grid>
    //               <Divider />
    //               <Grid container style={{backgroundColor: "#999", padding: '5px'}}>
    //                 <Grid item xs={6}>
    //                   <Typography variant="subtitle">
    //                     <span style={{display: 'block', marginBottom: '0px'}}><b>AdUnit:</b> {item.adUnitPath}</span>
    //                     <span style={{display: 'block', marginTop: '0px'}}><b>IsFluid:</b> {item.isFluid ? 'true' : 'false'}</span>
    //                   </Typography>
    //                 </Grid>
    //                 <Grid item xs={6}>
    //                   <Typography variant="subtitle">
    //                     <span style={{display: 'block', marginTop: '0px'}}><b>Type:</b> {type}</span>
    //                     <span style={{display: 'block', marginTop: '0px'}}><b>Location:</b> {location}</span>
    //                   </Typography>
    //                 </Grid>
    //               </Grid>
    //             </Button>
    //           </Grid>
    //         </>
    //        )
    //     })}
    //   </Grid> */}
    // </>
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"          
          size={'small'}
        >
          <TableHead>
            <TableRow >
              <TableCell padding="checkbox" className={classes.tableHeaderCell}>
                {/* <Checkbox
                  color="primary"

                  indeterminate={selected.length > 0 && selected.length < slots?.length}
                  checked={slots?.length > 0 && selected.length === slots?.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                /> */}
              </TableCell>              
              <TableCell
                className={classes.tableHeaderCell}
                key={'name'}
                align={'left'}
                padding={'normal'}
              >
                Name
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                key={'adUnitPath'}
                align={'left'}
                padding={'none'}
              >
                AdUnit
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                key={'type'}
                align={'left'}
                padding={'none'}
              >
                Type
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                key={'location'}
                align={'left'}
                padding={'none'}
              >
                Location
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                key={'isFluid'}
                align={'left'}
                padding={'none'}
              >
                IsFluid
              </TableCell>
            </TableRow>
          </TableHead>          
          <TableBody>
            {
              slots ? slots.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `custom-table-checkbox-${index}`;
                let nameBits = row.name.split('_')
                let type = nameBits[1]
                let location = nameBits[2]
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="normal"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell padding="none" align="left">{row.adUnitPath}</TableCell>
                    <TableCell padding="none" align="left">{type}</TableCell>
                    <TableCell padding="none" align="left">{location}</TableCell>
                    <TableCell padding="none" align="left">{row.isFluid ? 'true' : 'false'}</TableCell>
                  </TableRow>
                );
              }) :(
                <TableRow
                  style={{
                    height: 33 * 1,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SlotList