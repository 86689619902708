import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCategories } from '../../../modules/lookupsSlice'
import { updateCategories } from '../../../modules/sitesSlice'
import { FormControlLabel, Checkbox, Card, CardHeader, CardContent, CardActions, Divider, Button } from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { addClasses, commonStyle } from '../../../styles'
import { clone } from '../../../utilities/helperFunctions.js'

const Categories = ({ data, canEditSite }) => {
  const commonClasses = commonStyle()
  const { updatingSiteCategories } = useSelector(state => state.sitesSlice)
  const [site, setSite] = useState(clone(data))
  const { categoryCodes } = site
  const [isChanged, setIsChanged] = useState(false)
  const { categories } = useSelector(state => state.lookups)
  const dispatch = useDispatch()

  useEffect(() => {
    setSite(clone(data))
  }, [data])

  useEffect(() => {
    if (categories.length === 0) { dispatch(getCategories()) }
  }, [])

  useEffect(() => {
    if (site._id) {
      const oldData = {
        description: data.description,
        rootAdUnitId: data.rootAdUnitId,
        categoryCodes: data.categoryCodes,
        useWOPR: data.useWOPR,
        isRegistryMinified: data.isRegistryMinified,
        safeFrameConfig: data.safeFrameConfig,
        safeFrameSandboxMode: data.safeFrameSandboxMode,
        safeFrameAllowOverlayExpansion: data.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: data.safeFrameAllowPushExpansion,
        tagForChildDirectedTreatment: data.tagForChildDirectedTreatment
      }
      const newData = {
        description: site.description,
        rootAdUnitId: site.rootAdUnitId,
        categoryCodes: site.categoryCodes,
        useWOPR: site.useWOPR,
        isRegistryMinified: site.isRegistryMinified,
        safeFrameConfig: site.safeFrameConfig,
        safeFrameSandboxMode: site.safeFrameSandboxMode,
        safeFrameAllowOverlayExpansion: site.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: site.safeFrameAllowPushExpansion,
        tagForChildDirectedTreatment: site.tagForChildDirectedTreatment
      }
      setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
    }
  }, [site])

  return (
    <Card>
      <CardHeader className={commonClasses.pl_3} titleTypographyProps={{ variant: 'h6' }} title='Categories' />
      <CardContent className={addClasses(commonClasses.pl_3, commonClasses.pr_3)}>
        {!!categoryCodes && categories !== undefined && categories.map((category, index) => {
          const checked = !!categoryCodes.find(item => item === category.code)
          return (
            <FormControlLabel
              key={index}
              control={(
                <Checkbox
                  disabled={!canEditSite || updatingSiteCategories}
                  color='secondary'
                  className={commonClasses.checkboxControl}
                  checked={checked}
                  onChange={(event) => {
                    const checked = event.target.checked
                    if (checked) {
                      setSite({ ...site, categoryCodes: [...site.categoryCodes, category.code] })
                    } else {
                      setSite({ ...site, categoryCodes: site.categoryCodes.filter(item => item !== category.code) })
                    }
                  }}
                />
              )}
              label={category.name}
            />
          )
        })}
      </CardContent>
      <Divider />
      <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className={isChanged ? commonClasses.btnSuccess : ''}
          disabled={!isChanged || updatingSiteCategories}
          variant='contained'
          startIcon={<CheckCircleIcon />}
          onClick={() => {
            dispatch(updateCategories(site))
          }}
        >
          {updatingSiteCategories ? 'Saving...' : 'Save'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default Categories