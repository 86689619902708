import React, { useState, useEffect } from 'react'

import { Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const template = {
  CUSTOMER_ID: {
    DOM: "",
    INTL: ""
  },
  SLOT_ID: {
    DOM: {
      bnr: {
        atf: "",
        btf: ""
      },
      rect: {
        atf: "",
        btf: ""
      },
      qtm: ""
    },
    INTL: {
      bnr: {
        atf: "",
        btf: ""
      },
      rect: {
        atf: "",
        btf: ""
      },
      qtm: ""
    }
  },
}

export const MediaNet = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const MEDIANET = { ...template, ...VENDOR.MEDIANET }  
  PREBID.VENDOR.MEDIANET = MEDIANET
  const { CUSTOMER_ID, SLOT_ID } = MEDIANET
  const CUSTOMER_DOM = CUSTOMER_ID.DOM
  const CUSTOMER_INTL = CUSTOMER_ID.INTL
  const SLOT_DOM_BNR_ATF = SLOT_ID.DOM.bnr.atf
  const SLOT_DOM_BNR_BTF = SLOT_ID.DOM.bnr.btf
  const SLOT_DOM_RECT_ATF = SLOT_ID.DOM.rect.atf
  const SLOT_DOM_RECT_BTF = SLOT_ID.DOM.rect.btf
  const SLOT_DOM_QTM = SLOT_ID.DOM.qtm
  const SLOT_INTL_BNR_ATF = SLOT_ID.INTL.bnr.atf
  const SLOT_INTL_BNR_BTF = SLOT_ID.INTL.bnr.btf
  const SLOT_INTL_RECT_ATF = SLOT_ID.INTL.rect.atf
  const SLOT_INTL_RECT_BTF = SLOT_ID.INTL.rect.btf
  const SLOT_INTL_QTM = SLOT_ID.INTL.qtm
  const [expanded, setExpanded] = useState(expandAllVendors)
  const { STRING } = validation
  const { LENGTH } = STRING

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displayCustomerIdTable = () => {
    return (
    <Table size='small' aria-label='appnexus-account-id-table'>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '180px' }} />
          <TableCell style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
          <TableCell style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell><Typography variant='h6' style={{ fontWeight: 'bolder' }}>Customer IDs</Typography></TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            <TextField
              margin='none'
              value={CUSTOMER_DOM}
              error={errors.PREBID_MEDIANET_CIDD}
              helperText={errors.PREBID_MEDIANET_CIDD && LENGTH.message}
              onChange={(event) => {
                const value = event.target.value
                PREBID.VENDOR.MEDIANET.CUSTOMER_ID.DOM = value
                setErrors({ ...errors, PREBID_MEDIANET_CIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                setOptions({ ...options, PREBID })
                setAllOptions({ ...allOptions, PREBID })
              }}
            />
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            <TextField
              margin='none'
              value={CUSTOMER_INTL}
              error={errors.PREBID_MEDIANET_CIDI}
              helperText={errors.PREBID_MEDIANET_CIDI && LENGTH.message}
              onChange={(event) => {
                const value = event.target.value
                PREBID.VENDOR.MEDIANET.CUSTOMER_ID.INTL = value
                setErrors({ ...errors, PREBID_MEDIANET_CIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                setOptions({ ...options, PREBID })
                setAllOptions({ ...allOptions, PREBID })
              }}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    )
  }

  const displayMappingsTable = () => {
    return (
      <Table size='small' aria-label='medianet-unit-mapping-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }} />
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell colSpan={2} style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '180px' }}>
              <Typography variant='h6' style={{ fontWeight: 'bolder' }}>Slot IDs</Typography>
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>ABOVE THE FOLD</TableCell>
            <TableCell style={{ textAlign: 'center' }}>BELOW THE FOLD</TableCell>
            <TableCell style={{ textAlign: 'center' }}>ABOVE THE FOLD</TableCell>
            <TableCell style={{ textAlign: 'center' }}>BELOW THE FOLD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><Typography variant='h6'>Banner</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_DOM_BNR_ATF}
                error={errors.PREBID_MEDIANET_SIDDBA}
                helperText={errors.PREBID_MEDIANET_SIDDBA && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.DOM.bnr.atf = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDDBA: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_DOM_BNR_BTF}
                error={errors.PREBID_MEDIANET_SIDDBB}
                helperText={errors.PREBID_MEDIANET_SIDDBB && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.DOM.bnr.btf = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDDBB: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_INTL_BNR_ATF}
                error={errors.PREBID_MEDIANET_SIDIBA}
                helperText={errors.PREBID_MEDIANET_SIDIBA && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.INTL.bnr.atf = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDIBA: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_INTL_BNR_BTF}
                error={errors.PREBID_MEDIANET_SIDIBB}
                helperText={errors.PREBID_MEDIANET_SIDIBB && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.INTL.bnr.btf = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDIBB: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><Typography variant='h6'>Rectangle</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_DOM_RECT_ATF}
                error={errors.PREBID_MEDIANET_SIDDRA}
                helperText={errors.PREBID_MEDIANET_SIDDRA && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.DOM.rect.atf = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDDRA: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_DOM_RECT_BTF}
                error={errors.PREBID_MEDIANET_SIDDRB}
                helperText={errors.PREBID_MEDIANET_SIDDRB && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.DOM.rect.btf = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDDRB: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_INTL_RECT_ATF}
                error={errors.PREBID_MEDIANET_SIDIRA}
                helperText={errors.PREBID_MEDIANET_SIDIRA && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.INTL.rect.atf = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDIRA: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_INTL_RECT_BTF}
                error={errors.PREBID_MEDIANET_SIDIRB}
                helperText={errors.PREBID_MEDIANET_SIDIRB && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.INTL.rect.btf = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDIRB: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><Typography variant='h6'>Dynamic</Typography></TableCell>
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_DOM_QTM}
                error={errors.PREBID_MEDIANET_SIDDQ}
                helperText={errors.PREBID_MEDIANET_SIDDQ && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.DOM.qtm = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDDQ: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell />
            <TableCell style={{ verticalAlign: 'top', textAlign: 'center' }}>
              <TextField
                margin='none'
                value={SLOT_INTL_QTM}
                error={errors.PREBID_MEDIANET_SIDIQ}
                helperText={errors.PREBID_MEDIANET_SIDIQ && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.MEDIANET.SLOT_ID.INTL.qtm = value
                  setErrors({ ...errors, PREBID_MEDIANET_SIDIQ: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='medianet-panel-content'
        id='medianet-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>MediaNet</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displayCustomerIdTable()}</Grid>
          <Grid item xs={12}>{displayMappingsTable()}</Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
