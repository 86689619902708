import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { checkUser } from '../../modules/authSlice'
import { useHistory } from 'react-router-dom'
import { makeStyles, Box, CardMedia } from '@material-ui/core'
import { getPath } from '../../modules/configSlice'
import { fetchBannerMessage } from '../../modules/bannerMessageSlice'

const useStyles = makeStyles(theme => ({
  media: {
    minHeight: 'auto',
    width: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  figure: {
    maxWidth: '500px',
    margin: '0 auto'
  }
}))

export const HomeBeta = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const user = await checkUser()
      if (
        user &&
        window.localStorage.getItem('redirectUri') &&
        window.localStorage.getItem('adal.login.request') !==
          window.localStorage.getItem('redirectUri')
      ) {
        history.push(
          `/${window.localStorage
            .getItem('redirectUri')
            .replace(/http(s)?:\/\/.*?\//, '')}`
        )
      }
      if (user) {
        dispatch(getPath())
        dispatch(fetchBannerMessage())
      }
    })()
  }, [])

  return (
    <Box className={classes.figure}>
      <CardMedia
        component='img'
        className={classes.media}
        image={process.env.PUBLIC_URL + '/images/logo-new-wbd2.png'}
        title='logo'
        alt='logo'
        aria-label='logo'
      />
    </Box>
  )
}
