/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupstring, object as yupobject, array as yuparray } from 'yup'
import {
  TextField, Button, Card, CardHeader, CardContent, FormGroup,
  FormControlLabel, FormHelperText, Box, Checkbox, Divider, Grid, Table, TableBody,
  TableCell, TableHead, TableRow, Container, Paper, IconButton
} from '@material-ui/core'
import {
  updateSlotType, createSlotType, ToggleIsNewSlotType, filterSlotType, fetchSlotTypes
} from '../../modules/slotTypesSlice'
import { fetchSlotLocations } from '../../modules/slotLocationSlice'
import { Add, Edit, Clear, Check } from '@material-ui/icons'
import { commonStyle } from '../../styles/commonStyle'
import Typography from '@material-ui/core/Typography'
import { LoadingProgress } from '../ui'

const StyledTableRow = withStyles(theme => ({
  root: {
    verticalAlign: 'top',
    border: 'none'
  }
}))(TableRow)

const StyledTableCell = withStyles(theme => ({
  root: {
    border: 'none'
  }
}))(TableCell)

const SlotTypes = props => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { slotTypes, newSlotType } = useSelector(state => state.slotTypeSlice)
  const { locations } = useSelector(state => state.slotLocationSlice)
  const [searchTerm, setSearchTerm] = useState('')
  const [slotTypesData, setSlotTypesData] = useState({})
  const [slotLocations, setSlotLocations] = useState({})
  const [selectedItem, setSelectedItem] = useState({})
  const [selectedLocation, setselectedLocation] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  const validationSchema = yupobject().shape({
    name: yupstring().required('Required'),
    displayName: yupstring().required('Required'),
    description: yupstring().required('Required'),
    setLocations: yuparray().min(2, 'Select at least two locations.')
  })

  const {
    register,
    unregister,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const onSearchChange = e => {
    setSearchTerm(e.target.value)
    dispatch(filterSlotType(e.target.value))
  }

  const onEditSlotType = slotTypes => {
    setSelectedItem(slotTypes)
    setselectedLocation(slotTypes.validLocationIds)
    setIsEditing(true)
    if (newSlotType) {
      dispatch(ToggleIsNewSlotType(false))
    }
    setValue('name', slotTypes.name, { shouldValidate: true })
    setValue('displayName', slotTypes.displayName, { shouldValidate: true })
    setValue('description', slotTypes.description, { shouldValidate: true })
    setValue('setLocations', slotTypes.validLocationIds, {
      shouldValidate: true
    })
  }

  const onAddSlotType = () => {
    setSelectedItem({
      name: null,
      displayName: null,
      description: null,
      locationName: 'locationName'
    })
    setselectedLocation(null)
    dispatch(ToggleIsNewSlotType(true))
    setIsEditing(true)
    setValue('name', '')
    setValue('displayName', '')
    setValue('description', '')
    setValue('setLocations', [])
  }

  const handleSlotTypeChange = (e, slotType) => {
    if (e.target.name === 'name') {
      setSelectedItem({
        ...selectedItem,
        name: e.target.value
      })
      setValue('name', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'displayName') {
      setSelectedItem({
        ...selectedItem,
        displayName: e.target.value
      })
      setValue('displayName', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'description') {
      setSelectedItem({
        ...selectedItem,
        description: e.target.value
      })
      setValue('description', e.target.value, { shouldValidate: true })
    }
  }

  const handleCheckBoxChange = (e, location) => {
    const _selLocation = selectedLocation ? [...selectedLocation].slice() : []
    if (e.target.checked) {
      _selLocation.push(location._id)
      setselectedLocation(_selLocation)
      setSelectedItem({
        ...selectedItem,
        validLocationIds: _selLocation
      })
    } else {
      _selLocation.splice(_selLocation.indexOf(location._id), 1)
      setselectedLocation(_selLocation)
      setSelectedItem({
        ...selectedItem,
        validLocationIds: _selLocation
      })
    }
    setValue(e.target.name, _selLocation, { shouldValidate: true })
  }

  const UpdateSlotType = () => {
    dispatch(updateSlotType(selectedItem))
    setIsEditing(false)
  }

  const CreateSlotType = () => {
    dispatch(createSlotType(selectedItem))
    dispatch(ToggleIsNewSlotType(false))
  }

  const textField = (
    isEditing,
    slotType,
    textFieldName,
    textFieldDefaultValue,
    handleTextFieldChange,
    error,
    errorMessage
  ) => {
    if (isEditing && selectedItem._id === slotType._id) {
      return (
        <TextField
          style={{ width: '100%' }}
          margin='dense'
          {...register(textFieldName)}
          defaultValue={textFieldDefaultValue}
          variant='outlined'
          onChange={e => handleTextFieldChange(e, slotType)}
          error={!!error}
          helperText={error ? errorMessage : ''}
        />
      )
    } else {
      return (
        <div className={commonClasses.plainText}>{textFieldDefaultValue}</div>
      )
    }
  }

  useEffect(() => {
    if (slotTypes && slotTypes.length > 0) {
      setSlotTypesData(slotTypes.map(sType => ({ ...sType })))
    } else {
      dispatch(fetchSlotTypes())
    }
    if (locations && locations.length > 0) {
      setSlotLocations(locations.map(locations => ({ ...locations })))
    } else {
      dispatch(fetchSlotLocations())
    }
  }, [slotTypes, locations])

  return (
    <Container maxWidth="xl">
      <Paper position='static'>
        <form autoComplete='off' noValidate>
          <CardHeader title='Slot Types' />
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card><CardContent>
            <Grid container style={{ marginBottom: '10pt' }}>
              <Grid item md={4} xs={12}>
                <TextField
                  style={{ width: '99%' }}
                  label='Find'
                  margin='dense'
                  name='itemFilter'
                  defaultValue={searchTerm}
                  variant='outlined'
                  onChange={onSearchChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  className={commonClasses.m_1}
                  startIcon={<Add />}
                  onClick={() => {
                    onAddSlotType()
                  }}
                >
                  Add New Slot Type
                </Button>
              </Grid>
              <Grid item md={2} />
            </Grid>
            <Divider />
            <Grid>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                      Name
                    </TableCell>
                    <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                      Display Name
                    </TableCell>
                    <TableCell style={{ width: '60%', fontWeight: 'bold' }}>
                      Description
                    </TableCell>
                    <TableCell style={{ width: '10%', fontWeight: 'bold' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {newSlotType
                    ? (
                      <>
                        <StyledTableRow>
                          <StyledTableCell>
                            {textField(
                              isEditing,
                              selectedItem,
                              'name',
                              selectedItem.name ? selectedItem.name : '',
                              handleSlotTypeChange,
                              errors.name,
                              errors.name ? errors.name.message : ''
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {textField(
                              isEditing,
                              selectedItem,
                              'displayName',
                              selectedItem.displayName
                                ? selectedItem.displayName
                                : '',
                              handleSlotTypeChange,
                              errors.displayName,
                              errors.displayName ? errors.displayName.message : ''
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {textField(
                              isEditing,
                              selectedItem,
                              'description',
                              selectedItem.description
                                ? selectedItem.description
                                : '',
                              handleSlotTypeChange,
                              errors.description,
                              errors.description ? errors.description.message : ''
                            )}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: 'center' }}>
                            <Grid container>
                              <Grid className={commonClasses.displayFlex} item>
                                <IconButton
                                  aria-label='Save'
                                  variant='contained'
                                  name='Save'
                                  title='Save'
                                  onClick={handleSubmit(CreateSlotType)}
                                >
                                  <Check />
                                </IconButton>
                                <IconButton
                                  aria-label='Cancel'
                                  variant='contained'
                                  title='Cancel'
                                  name='cancel'
                                  onClick={e => {
                                    dispatch(ToggleIsNewSlotType(false))
                                    unregister([
                                      'name',
                                      'displayName',
                                      'description',
                                      'setLocations'
                                    ])
                                  }}
                                >
                                  <Clear />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </StyledTableCell>
                        </StyledTableRow>
                        <TableRow>
                          <TableCell colSpan={3}>
                            <FormGroup row>
                              <Grid container spacing={1} alignItems='flex-end'>
                                <Grid item>
                                  <Typography
                                    variant='body1'
                                    align='center'
                                    gutterBottom
                                  >
                                    Locations:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Box
                                    border={errors.setLocations ? 1 : 0}
                                    borderColor={
                                    errors.setLocations ? 'error.main' : ''
                                  }
                                  >
                                    {slotLocations.length > 0
                                      ? (
                                          slotLocations.map(location => (
                                            <FormControlLabel
                                              key={location._id}
                                              control={
                                                <Checkbox
                                                  {...register('setLocations')}
                                                  color='primary'
                                                  value={location._id}
                                                  onChange={e => {
                                                    handleCheckBoxChange(e, location)
                                                  }}
                                                />
                                        }
                                              label={location.displayName}
                                            />
                                          ))
                                        )
                                      : (
                                        <LoadingProgress />
                                        )}
                                    {!!errors.setLocations && (
                                      <FormHelperText
                                        error={!!errors.setLocations}
                                      >
                                        {errors.setLocations.message}
                                      </FormHelperText>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </FormGroup>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </>
                      )
                    : (
                      <></>
                      )}

                  {slotTypesData.length > 0
                    ? (
                        slotTypesData.map(slotType => (
                          <React.Fragment key={slotType._id}>
                            <StyledTableRow>
                              <StyledTableCell>
                                {textField(
                                  isEditing,
                                  slotType,
                                  'name',
                                  slotType.name,
                                  handleSlotTypeChange,
                                  errors.name,
                                  errors.name ? errors.name.message : ''
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {textField(
                                  isEditing,
                                  slotType,
                                  'displayName',
                                  slotType.displayName,
                                  handleSlotTypeChange,
                                  errors.displayName,
                                  errors.displayName
                                    ? errors.displayName.message
                                    : ''
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {textField(
                                  isEditing,
                                  slotType,
                                  'description',
                                  slotType.description,
                                  handleSlotTypeChange,
                                  errors.description,
                                  errors.description
                                    ? errors.description.message
                                    : ''
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                <Grid container>
                                  <Grid className={commonClasses.displayFlex} item>
                                    <IconButton
                                      aria-label='Save'
                                      variant='contained'
                                      className={
                                  isEditing && selectedItem._id === slotType._id
                                    ? ''
                                    : commonClasses.hidden
                                }
                                      name='Save'
                                      title='Save'
                                      onClick={handleSubmit(UpdateSlotType)}
                                    >
                                      <Check />
                                    </IconButton>
                                    <IconButton
                                      aria-label='Cancel'
                                      variant='contained'
                                      className={
                                  isEditing && selectedItem._id === slotType._id
                                    ? ''
                                    : commonClasses.hidden
                                }
                                      title='Cancel'
                                      onClick={e => {
                                        e.stopPropagation()
                                        setIsEditing(false)
                                        setSelectedItem({})
                                        unregister([
                                          'name',
                                          'displayName',
                                          'description',
                                          'setLocations'
                                        ])
                                      }}
                                    >
                                      <Clear />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid className={commonClasses.displayFlex} item>
                                    <IconButton
                                      aria-label='Edit'
                                      variant='contained'
                                      className={
                                  isEditing && selectedItem._id === slotType._id
                                    ? commonClasses.hidden
                                    : commonClasses.editIconColor
                                }
                                      name='Edit'
                                      title='Edit'
                                      onClick={e => {
                                        onEditSlotType(slotType)
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </StyledTableCell>
                            </StyledTableRow>
                            <TableRow>
                              <TableCell colSpan={3}>
                                <FormGroup row>
                                  <Grid container spacing={1} alignItems='flex-end'>
                                    <Grid item>
                                      <Typography
                                        variant='body1'
                                        align='center'
                                        gutterBottom
                                      >
                                        Locations:
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Box
                                        border={
                                      !!errors.setLocations &&
                                      slotType.name === selectedItem.name
                                        ? 1
                                        : 0
                                    }
                                        borderColor={
                                      !!errors.setLocations &&
                                      slotType.name === selectedItem.name
                                        ? 'error.main'
                                        : ''
                                    }
                                      >
                                        {slotLocations.length > 0
                                          ? (
                                              slotLocations.map(location => {
                                                if (
                                                  isEditing &&
                                          selectedItem._id === slotType._id
                                                ) {
                                                  return (
                                                    <FormControlLabel
                                                      key={location._id}
                                                      control={
                                                        <Checkbox
                                                          {...register('setLocations')}
                                                          checked={
                                                    selectedLocation.findIndex(
                                                      validLocationId =>
                                                        validLocationId ===
                                                        location._id
                                                    ) !== -1
                                                  }
                                                          color='primary'
                                                          value={location._id}
                                                          onChange={e => {
                                                            handleCheckBoxChange(
                                                              e,
                                                              location
                                                            )
                                                          }}
                                                        />
                                              }
                                                      label={location.displayName}
                                                    />
                                                  )
                                                } else {
                                                  return (
                                                    <FormControlLabel
                                                      key={location._id}
                                                      control={
                                                        <Checkbox
                                                          checked={
                                                    slotType.validLocationIds.findIndex(
                                                      validLocationId =>
                                                        validLocationId ===
                                                        location._id
                                                    ) !== -1
                                                  }
                                                          color='primary'
                                                          value={location._id}
                                                          name={slotType.name}
                                                          disabled
                                                        />
                                              }
                                                      label={location.displayName}
                                                      name={slotType.name}
                                                    />
                                                  )
                                                }
                                              })
                                            )
                                          : (
                                            <LoadingProgress />
                                            )}
                                        {!!errors.setLocations &&
                                      slotType.name === selectedItem.name && (<FormHelperText error={!!errors.setLocations && slotType.name === selectedItem.name}>&nbsp;&nbsp;{errors.setLocations.message}</FormHelperText>)}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </FormGroup>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </React.Fragment>
                        ))
                      )
                    : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <LoadingProgress />
                        </TableCell>
                      </TableRow>
                      )}
                </TableBody>
              </Table>
            </Grid>
            </CardContent></Card>
          </CardContent>
        </form>
      </Paper>
    </Container>
  )
}

export default SlotTypes
