import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  frequencies: ['hourly', 'daily', 'weekly'],
  rptNotifications: [],
  orgNotifications: [],
  rptNotification: {},
  loading: false
}

const reportNotificationsSlice = createSlice({
  name: 'reportNotifications',
  initialState,
  reducers: {
    FetchReportNotifications: (state, action) => {
      state.loading = false
      // XSSVulnerabilties report is no longer needed(Reffer: CSD-1643).
      const filteredRptNotifications = action.payload.filter(
        rptNotification => rptNotification.name !== 'dfpCreativesContainingXSSVulnerabilties'
      )
      state.rptNotifications = filteredRptNotifications
      state.orgNotifications = filteredRptNotifications
    },

    UpdateReportNotification: (state, action) => {
      const rptNotifications = state.rptNotifications.slice()
      const updatedItemIndex = rptNotifications.findIndex(
        rptNotification => rptNotification._id === action.payload._id
      )
      rptNotifications[updatedItemIndex] = action.payload

      state.loading = false
      state.rptNotifications = rptNotifications
      state.orgNotifications = rptNotifications
      swal.fire({
        title: 'Site notifications Updated!',
        text: 'Site notifications Updated.',
        icon: 'success'
      })
    },

    FilterReportNotification: (state, action) => {
      const results = filterByValue(
        state.orgNotifications.slice(),
        action.payload
      )
      state.loading = false
      state.rptNotifications = results
    },

    /** REPORT NOTIFICATION METHODS */
    SendReportNotification: (state, action) => {
      state.loading = false
      swal.fire({
        title: 'Notifications triggered!',
        text: action.payload,
        icon: 'success'
      })
    },

    ReportNotificationLoadingStatus: state => {
      state.loading = true
    },

    ReportNotificationError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    }
  }
})

export default reportNotificationsSlice.reducer
export const {
  FetchReportNotifications,
  UpdateReportNotification,
  FilterReportNotification,
  SendReportNotification,
  ReportNotificationLoadingStatus,
  ReportNotificationError
} = reportNotificationsSlice.actions

const filterByValue = (array, string) => {
  return array.filter(arrObject =>
    Object.keys(arrObject).some(element =>
      // Some() allows you to test if an array has at least one element that meets a condition.
      arrObject[element]
        .toString()
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  )
}

const dispatchNotifications = url => {
  return async dispatch => {
    dispatch(ReportNotificationLoadingStatus())
    return request(url)
      .then(res => dispatch(SendReportNotification(res.data)))
      .catch(err => dispatch(ReportNotificationError(err)))
  }
}

export const fetchNotifications = () => {
  return async dispatch => {
    dispatch(ReportNotificationLoadingStatus())
    return request('/lookups/notifications')
      .then(res => dispatch(FetchReportNotifications(res.data)))
      .catch(err => dispatch(ReportNotificationError(err)))
  }
}

export const updateNotifications = reportNotification => {
  return async dispatch => {
    dispatch(ReportNotificationLoadingStatus())
    return request(
      `/lookups/notifications/${reportNotification._id}`,
      'POST',
      reportNotification,
      true
    )
      .then(res => {
        dispatch(UpdateReportNotification(res.data))
      })
      .catch(err => dispatch(ReportNotificationError(err)))
  }
}

/** REPORT NOTIFICATION METHODS */
export const sendNonDeliveringReadyLineItems = () =>
  dispatchNotifications('/notifications/dfp/nondeliveringreadylineitems')

export const sendCNNLineItemsLackingGeoTargeting = () =>
  dispatchNotifications('/notifications/dfp/cnnlineitemslackinggeotargeting')

export const sendCreativesContainingTrackingImpjPixel = () =>
  dispatchNotifications('/notifications/dfp/creativescontainingtrackimpjpixel')

export const sendCreativesContainingProofPointClickThru = () =>
  dispatchNotifications('/notifications/dfp/creativescontainingproofpointclickthru')

export const getActiveCreativesOnInactiveLineItems = () =>
  dispatchNotifications('/notifications/creativesOnInactiveLineItems')

export const sendDeliveringTestLineItems = () =>
  dispatchNotifications('/notifications/dfp/deliveringtestlineitems')

export const sendHouseLineItemsLackingNgInventoryTarget = () =>
  dispatchNotifications('/notifications/dfp/houselineitemslackingnginventorytarget')

export const sendInactiveLineItems = () =>
  dispatchNotifications('/notifications/dfp/inactive')

export const sendInactiveLineItemsModified = () =>
  dispatchNotifications('/notifications/dfp/inactivemodified')

export const sendNonDeliveringLineItems = () =>
  dispatchNotifications('/notifications/dfp/nondeliveringlineitems')

export const sendUsingDeviceIdMacro = () =>
  dispatchNotifications('/notifications/dfp/usingdeviceidmacro')

export const sendAdUnitHierarchy = () =>
  dispatchNotifications('/notifications/dfp/adunithierarchy')

export const sendLineItemsWithCreativeTargeting = () =>
  dispatchNotifications('/notifications/dfp/withcreativetargeting')

export const sendInviewRefresh = () =>
  dispatchNotifications('/notifications/inviewrefresh')

export const sendActiveUsers = () =>
  dispatchNotifications('/notifications/activeusers')

export const sendAdFuelVersions = () =>
  dispatchNotifications('/notifications/adfuelversions')

export const sendAdFuelModulesBySite = () =>
  dispatchNotifications('/notifications/adFuelModulesBySite')

export const sendDfpActiveUsers = () =>
  dispatchNotifications('/notifications/dfpactiveusers')

export const sendGamAudienceData = () =>
  dispatchNotifications('/notifications/gamaudiencedata')

export const sendGamInactiveAudienceData = () =>
  dispatchNotifications('/notifications/gaminactiveaudiencedata')

export const sendAllPrebidVendorsList = () =>
  dispatchNotifications('/notifications/allprebidvendorslist')

export const sendCNNA9SlotNames = () =>
  dispatchNotifications('/notifications/a9slotnames/cnn')

export const filterReportNotification = searchTerm => {
  return async dispatch => {
    dispatch(ReportNotificationLoadingStatus())
    dispatch(FilterReportNotification(searchTerm))
  }
}
