/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupstring, object as yupobject } from 'yup'
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Paper,
  IconButton
} from '@material-ui/core'
import {
  updateSlotLocation,
  createSlotLocation,
  filterSlotLocation,
  fetchSlotLocations
} from '../../modules/slotLocationSlice'
import { Add, Edit, Clear, Check } from '@material-ui/icons'
import { commonStyle } from '../../styles'
import { LoadingProgress } from '../ui'

const StyledTableRow = withStyles(theme => ({
  root: {
    verticalAlign: 'top',
    border: 'none'
  }
}))(TableRow)

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  }
}))(TableCell)

const SlotLocations = () => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { locations } = useSelector(state => state.slotLocationSlice)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [slotLocations, setSlotLocations] = useState({})
  const [selectedItem, setSelectedItem] = useState({})
  const [addingNewSlotLocation, setAddingNewSlotLocation] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const validationSchema = yupobject().shape({
    name: yupstring().required('Required'),
    displayName: yupstring().required('Required'),
    description: yupstring().required('Required')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  useEffect(() => {
    if (locations && locations.length > 0) {
      setSlotLocations(locations.map(locations => ({ ...locations })))
    } else {
      dispatch(fetchSlotLocations())
    }
  }, [locations])

  const onSearchChange = e => {
    setSearchTerm(e.target.value)
    dispatch(filterSlotLocation(e.target.value))
  }

  const onEditSlotLocation = slotLocations => {
    setSelectedItem(slotLocations)
    setIsEditing(true)
    setAddingNewSlotLocation(false)
    setValue('name', slotLocations.name, { shouldValidate: true })
    setValue('displayName', slotLocations.displayName, { shouldValidate: true })
    setValue('description', slotLocations.description, { shouldValidate: true })
  }

  const onAddSlotLocation = slotLocations => {
    setSelectedItem({ name: '', displayName: '', description: '' })
    setAddingNewSlotLocation(true)
    setIsEditing(true)
    setValue('name', '')
    setValue('displayName', '')
    setValue('description', '')
  }

  const handleSlotLocationChange = e => {
    if (e.target.name === 'name') {
      setSelectedItem({
        ...selectedItem,
        name: e.target.value
      })
      setValue('name', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'displayName') {
      setSelectedItem({
        ...selectedItem,
        displayName: e.target.value
      })
      setValue('displayName', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'description') {
      setSelectedItem({
        ...selectedItem,
        description: e.target.value
      })
      setValue('description', e.target.value, { shouldValidate: true })
    }
  }

  const UpdateSlotLocation = () => {
    dispatch(updateSlotLocation(selectedItem))
    setIsEditing(false)
  }

  const CreateSlotLocation = () => {
    dispatch(createSlotLocation(selectedItem))
    setAddingNewSlotLocation(false)
  }

  const textField = (
    isEditing,
    slotLocation,
    textFieldName,
    textFieldDefaultValue,
    handleTextFieldChange,
    error,
    errorMessage
  ) => {
    if (isEditing && selectedItem._id === slotLocation._id) {
      return (
        <TextField
          {...register(textFieldName)}
          style={{ width: '100%' }}
          margin='dense'
          defaultValue={textFieldDefaultValue}
          variant='outlined'
          onChange={e => handleTextFieldChange(e)}
          error={!!error}
          helperText={error ? errorMessage : ''}
        />
      )
    } else {
      return <div className={commonClasses.plainText}>{textFieldDefaultValue}</div>
    }
  }

  return (
    <Container maxWidth='xl'>
      <Paper position='static'>
        <form autoComplete='off' noValidate>
          <CardHeader title='Slot Locations' />
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card><CardContent>
            <Grid container style={{ marginBottom: '10pt' }}>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label='Find'
                  margin='dense'
                  name='itemFilter'
                  defaultValue={searchTerm}
                  variant='outlined'
                  onChange={onSearchChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  className={commonClasses.m_1}
                  startIcon={<Add />}
                  onClick={() => {
                    onAddSlotLocation()
                  }}
                >
                  Add New Location
                </Button>
              </Grid>
              <Grid item md={2} />
            </Grid>
            <Divider />
            <Grid>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '15%', fontWeight: 'bold' }}>
                      Name
                    </TableCell>
                    <TableCell style={{ width: '20%', fontWeight: 'bold' }}>
                      Display Name
                    </TableCell>
                    <TableCell style={{ width: '55%', fontWeight: 'bold' }}>
                      Description
                    </TableCell>
                    <TableCell style={{ width: '10%', fontWeight: 'bold' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow
                    className={!addingNewSlotLocation ? commonClasses.hidden : ''}
                  >
                    <StyledTableCell>
                      <TextField
                        name='name'
                        margin='dense'
                        id={selectedItem.name}
                        value={selectedItem.name ? selectedItem.name : ''}
                        style={{ width: '100%' }}
                        variant='outlined'
                        onChange={e => handleSlotLocationChange(e)}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ''}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        name='displayName'
                        margin='dense'
                        value={
                          selectedItem.displayName
                            ? selectedItem.displayName
                            : ''
                        }
                        variant='outlined'
                        style={{ width: '100%' }}
                        onChange={e => handleSlotLocationChange(e)}
                        error={!!errors.displayName}
                        helperText={
                          errors.displayName ? errors.displayName.message : ''
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        name='description'
                        margin='dense'
                        value={
                          selectedItem.description
                            ? selectedItem.description
                            : ''
                        }
                        variant='outlined'
                        style={{ width: '100%' }}
                        onChange={e => handleSlotLocationChange(e)}
                        error={!!errors.description}
                        helperText={
                          errors.description ? errors.description.message : ''
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: 'center' }}>
                      <Grid container>
                        <Grid className={commonClasses.displayFlex} item>
                          <IconButton
                            aria-label='Save'
                            variant='contained'
                            name='Save'
                            title='Save'
                            onClick={handleSubmit(CreateSlotLocation)}
                          >
                            <Check />
                          </IconButton>
                          <IconButton
                            aria-label='Cancel'
                            title='Cancel'
                            name='cancel'
                            onClick={e => {
                              setAddingNewSlotLocation(false)
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                  {slotLocations.length > 0
                    ? (
                        slotLocations.map(slotLocation => (
                          <React.Fragment key={slotLocation._id}>
                            <StyledTableRow>
                              <StyledTableCell>
                                {textField(
                                  isEditing,
                                  slotLocation,
                                  'name',
                                  slotLocation.name,
                                  handleSlotLocationChange,
                                  errors.name,
                                  errors.name ? errors.name.message : ''
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {textField(
                                  isEditing,
                                  slotLocation,
                                  'displayName',
                                  slotLocation.displayName,
                                  handleSlotLocationChange,
                                  errors.displayName,
                                  errors.displayName
                                    ? errors.displayName.message
                                    : ''
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {textField(
                                  isEditing,
                                  slotLocation,
                                  'description',
                                  slotLocation.description,
                                  handleSlotLocationChange,
                                  errors.description,
                                  errors.description
                                    ? errors.description.message
                                    : ''
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                <Grid container>
                                  <Grid className={commonClasses.displayFlex} item>
                                    <IconButton
                                      aria-label='Save'
                                      variant='contained'
                                      className={
                                  isEditing &&
                                  selectedItem._id === slotLocation._id
                                    ? ''
                                    : commonClasses.hidden
                                }
                                      name='Save'
                                      title='Save'
                                      onClick={handleSubmit(UpdateSlotLocation)}
                                    >
                                      <Check />
                                    </IconButton>
                                    <IconButton
                                      aria-label='Cancel'
                                      variant='contained'
                                      className={
                                  isEditing &&
                                  selectedItem._id === slotLocation._id
                                    ? ''
                                    : commonClasses.hidden
                                }
                                      title='Cancel'
                                      onClick={e => {
                                        e.stopPropagation()
                                        setIsEditing(false)
                                      }}
                                    >
                                      <Clear />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                <IconButton
                                  aria-label='Edit'
                                  variant='contained'
                                  className={
                                isEditing &&
                                selectedItem._id === slotLocation._id
                                  ? commonClasses.hidden
                                  : commonClasses.editIconColor
                              }
                                  name='Edit'
                                  title='Edit'
                                  onClick={e => {
                                    onEditSlotLocation(slotLocation)
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          </React.Fragment>
                        ))
                      )
                    : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <LoadingProgress />
                        </TableCell>
                      </TableRow>
                      )}
                </TableBody>
              </Table>
            </Grid>
            </CardContent></Card>
          </CardContent>
        </form>
      </Paper>
    </Container>
  )
}

export default SlotLocations
