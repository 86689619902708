/* eslint-disable react-hooks/exhaustive-deps */
// adding the below line to prevent infinite loop
import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Card, CardHeader, CardContent } from '@material-ui/core'
import { getPath, updatePath } from '../../modules/configSlice'
import { TableIcons } from '../ui/TableIcons'
import * as yup from 'yup'
import { clone } from '../../utilities/helperFunctions'
import { LoadingProgress } from '../ui'
import { commonStyle } from '../../styles'

const PublisherRoot = () => {
  const dispatch = useDispatch()
  const commonClasses = commonStyle()
  const { pathConfig } = useSelector(state => state.configSlice)
  const [publishers, setPublishers] = useState()

  const viewportSchema = yup.object().shape({
    name: yup.string().required('Name is Required'),
    url: yup.string().required('URL is Required')
  })

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      const publishers = []
      Object.keys(pathConfig.publisher).forEach(key =>
        publishers.push({
          name: key,
          url: pathConfig.publisher[key]
        })
      )
      publishers.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      setPublishers(publishers.map(publishers => ({ ...publishers })))
    } else {
      dispatch(getPath())
    }
  }, [pathConfig])

  return (
    <Container maxWidth='xl'>
      <Card>
        <CardHeader title="Publisher Configuration" />
        <CardContent className={commonClasses.cardBkClr}>
          <Card>
            <CardContent>
            {publishers?.length > 0
              ? (
                <MaterialTable
                  title=''
                  icons={TableIcons}
                  columns={[
                    {
                      title: 'Name',
                      field: 'name',
                      editable: 'onAdd',
                      validate: rowData => {
                        if (typeof rowData.name !== 'undefined') {
                          try {
                            viewportSchema.validateSyncAt('name', rowData)
                            return true
                          } catch (err) {
                            return { isValid: false, helperText: err.errors[0] }
                          }
                        }
                      }
                    },
                    {
                      title: 'URL',
                      field: 'url',
                      validate: rowData => {
                        if (typeof rowData.url !== 'undefined') {
                          try {
                            viewportSchema.validateSyncAt('url', rowData)
                            return true
                          } catch (err) {
                            return { isValid: false, helperText: err.errors[0] }
                          }
                        }
                      }
                    }
                  ]}
                  data={publishers}
                  options={{
                    addRowPosition: 'first',
                    emptyRowsWhenPaging: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    headerStyle: {
                      fontWeight: 'bold'
                    },
                    actionsColumnIndex: -1
                  }}
                  editable={{
                    onRowAdd: publisher => {
                      return new Promise(resolve => {
                        setTimeout(() => {
                          const newPathConfig = clone(pathConfig)
                          newPathConfig.publisher[publisher.name] = publisher.url
                          dispatch(updatePath(newPathConfig, publisher.name, 'Add'))
                          setPublishers([...publishers, publisher])
                          resolve()
                        }, 600)
                      })
                    },
                    onRowUpdate: (newPublisher, prevPublisher) => {
                      return new Promise(resolve => {
                        setTimeout(() => {
                          const newPathConfig = clone(pathConfig)
                          newPathConfig.publisher[newPublisher.name] = newPublisher.url
                          dispatch(updatePath(newPathConfig, newPublisher.name, 'Edit'))
                          const _publishers = publishers.slice()
                          const updatedItemIndex = _publishers.findIndex(
                            publisher => publisher.name === newPublisher.name
                          )
                          _publishers[updatedItemIndex] = newPublisher
                          setPublishers(
                            _publishers.map(publisherRoot => ({ ...publisherRoot }))
                          )
                          resolve()
                        }, 600)
                      })
                    },
                    onRowDelete: publisher => {
                      return new Promise(resolve => {
                        setTimeout(() => {
                          const newPathConfig = clone(pathConfig)
                          delete newPathConfig.publisher[publisher.name]
                          dispatch(updatePath(newPathConfig, publisher.name, 'del'))
                          const _publishers = publishers.slice()
                          const updatedItemIndex = _publishers.findIndex(
                            publisher => publisher._id === publisher.name
                          )
                          _publishers[updatedItemIndex] = publisher
                          setPublishers(
                            _publishers.filter(item => item.name !== publisher.name)
                          )
                          resolve()
                        }, 600)
                      })
                    }
                  }}
                />
                )
              : (
                <LoadingProgress />
                )}
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </Container>
  )
}

export default PublisherRoot
