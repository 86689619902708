import {
  AuthenticationContext,
  adalFetch,
  adalGetToken,
  withAdalLogin
} from 'react-adal'

let userToken

const clientId = '4f0757bb-5170-41ae-bf51-452ef48f55b6'
// const tenantId = '0eb48825-e871-4459-bc72-d0ecd68f1f39'

export const adalConfig = {
  tenant: 'turner.onmicrosoft.com',
  clientId,
  endpoints: {
    api: clientId
  },
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  cacheLocation: 'localStorage'
}

export const authContext = new AuthenticationContext(adalConfig)

export const adalApiFetch = (fetch, url, options) => {
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options)
}

export const getToken = () => {
  return adalGetToken(authContext, clientId)
}
export const logOut = () => {
  return authContext.logOut
}
// gets the user
export const getCachedUser = () => {
  const user = authContext.getCachedUser()
  return user
}
// returns token
export const aquireToken = () => {
  return authContext.acquireToken(
    clientId,
    (error, token) => {
      if (error) return authContext.login()
      if (!token) {
        console.log('There is no token')
      }
      userToken = token
      return userToken
    }
  )
}
export const loginUser = () => {
  console.log('logging in')
  return authContext.login()
}

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
)
