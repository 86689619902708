import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  siteCategories: [],
  orgSiteCategories: [],
  loading: false
}

const siteCategorySlice = createSlice({
  name: 'siteCategory',
  initialState,
  reducers: {
    FetchSiteCategories: (state, action) => {
      state.loading = false
      state.siteCategories = action.payload
      state.orgSiteCategories = action.payload
    },
    UpdateSiteCategories: (state, action) => {
      const siteCategories = state.siteCategories.slice()
      const updatedItemIndex = siteCategories.findIndex(
        siteCategories => siteCategories._id === action.payload._id
      )
      siteCategories[updatedItemIndex] = action.payload

      state.loading = false
      state.siteCategories = siteCategories
      state.orgSiteCategories = siteCategories
      swal.fire({
        title: 'Site Categories Updated!',
        text: 'Site Categories Updated.',
        icon: 'success'
      })
    },
    CreateSiteCategories: (state, action) => {
      const siteCategories = state.siteCategories.slice()
      siteCategories.unshift(action.payload)
      state.loading = false
      state.siteCategories = siteCategories
      state.orgSiteCategories = siteCategories
      swal.fire({
        title: 'Site Categories Saved!',
        text: 'Site Categories saved successfully.',
        icon: 'success'
      })
    },
    FilterSiteCategories: (state, action) => {
      const results = filterByValue(state.orgSiteCategories, action.payload)
      state.loading = false
      state.siteCategories = results
    },
    SiteCategoriesLoadingStatus: state => {
      state.loading = true
    },
    SiteCategoriesError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    }
  }
})

export default siteCategorySlice.reducer
export const {
  FetchSiteCategories,
  UpdateSiteCategories,
  CreateSiteCategories,
  FilterSiteCategories,
  SiteCategoriesLoadingStatus,
  SiteCategoriesError
} = siteCategorySlice.actions

const filterByValue = (array, string) => {
  return array.filter(arrObject =>
    Object.keys(arrObject).some(element =>
      // Some() allows you to test if an array has at least one element that meets a condition.
      arrObject[element]
        .toString()
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  )
}

export const fetchSiteCategories = () => {
  return async dispatch => {
    dispatch(SiteCategoriesLoadingStatus())
    return request('/lookups/categories')
      .then(res => {
        dispatch(FetchSiteCategories(res.data))
      })
      .catch(err => dispatch(SiteCategoriesError(err)))
  }
}

export const updateSiteCategory = siteCategory => {
  return async dispatch => {
    dispatch(SiteCategoriesLoadingStatus())
    return request(
      `/lookups/categories/${siteCategory._id}`,
      'POST',
      siteCategory
    )
      .then(res => {
        dispatch(UpdateSiteCategories(res.data))
      })
      .catch(err => dispatch(SiteCategoriesError(err)))
  }
}

export const createSiteCategory = siteCategory => {
  return async dispatch => {
    dispatch(SiteCategoriesLoadingStatus())
    return request('/lookups/categories', 'POST', siteCategory)
      .then(res => dispatch(CreateSiteCategories(res.data)))
      .catch(err => dispatch(SiteCategoriesError(err)))
  }
}

export const filterSiteCategory = searchTerm => {
  return async dispatch => {
    dispatch(SiteCategoriesLoadingStatus())
    dispatch(FilterSiteCategories(searchTerm))
  }
}
