import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, FormControlLabel, Checkbox, Select, MenuItem, Tooltip, Chip, Card, CardHeader, CardContent, CardActions, Divider, Grid, TextField, Typography, Link, Button, makeStyles } from '@material-ui/core'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HistoryIcon from '@material-ui/icons/History'
import { addClasses, commonStyle } from '../../../styles'
import { ToggleSwitch } from '../../ui'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { clone, siteNameFormatter, cacheBuster } from '../../../utilities/helperFunctions.js'
import { NavLink } from 'react-router-dom'
import { getPath } from '../../../modules/configSlice'
import { updateGeneralInformation } from '../../../modules/sitesSlice'
import { deploySiteRegistries, fetchRegistriesForSite } from '../../../modules/registrySlice'
import { registryImplementationProcedureUrl } from '../../../modules/registrySlice'

const useStyle = makeStyles(theme => ({
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
  links: {
    color: "darkorange",
    fontSize: '.9em',
    "&:visited": {
      color: "green"
    }
  },
}))

const SiteOverview = ({ data, canEditSite }) => {
  const commonClasses = commonStyle()
  const classes = useStyle()
  const { updatingSiteGeneralInfo, isAdFuelVersionSelected } = useSelector(state => state.sitesSlice)
  const [site, setSite] = useState(clone(data))
  const [indexUrl, setIndexUrl] = useState('')
  const [indexUrlWithCacheBuster, setIndexUrlWithCacheBuster] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const { adRoots } = useSelector(state => state.dfp)
  const dispatch = useDispatch()
  const [clickCount, setClickCount] = useState(0)
  const { registries } = useSelector(state => state.registrySlice)
  const [siteRegistries, setSiteRegistries] = useState(registries.length)
  const [siteSingletons, setSiteSingletons] = useState(0)
  const [siteRegistriesToDeploy, setSiteRegistriesToDeploy] = useState(0)
  const [siteSingletonsToDeploy, setSiteSingletonsToDeploy] = useState(0)
  const [allSiteRegistries, setAllSiteRegistries] = useState(0)
  const [allSiteSingletons, setAllSiteSingletons] = useState(0)
  const [ publishers, setPublishers ] = useState({
    rootAdFuelDirectory: '/www/ads/dev/adfuel',
    rootAdFuelAisDirectory: '/www/ads/dev/adfuel/ais',
    rootSiteDirectory: '/www/ads/dev/{siteName}',
    rootAdSpacesDirectory: '/www/ads/dev/adspaces',
    rootAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
    rootAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
    rootSSLAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
    rootSSLAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
    rootSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
    rootSSLSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
    rootAdSpacesUrl: 'https://i.cdn.turner.com/ads/dev/adspaces',
    rootAnalyticsSiteUrl: 'https://i.cdn.turner.com/analytics/{siteName}'
  })
  const { isDeploying } = useSelector(state => state.registrySlice)
  const { pathConfig } = useSelector(state => state.configSlice)

  const deployRegistries = () => {
    if (registries.length > 0) {
      swal.fire({
        title: 'Are you sure?',
        text:
          'Once deployed, the current registries in the database will overwrite the existing registries on the CDN for the site. This cannot be reversed.',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(willDeploy => {
        if (willDeploy.isConfirmed) {
          dispatch(deploySiteRegistries(registries))
        }
      })
    } else {
      swal.fire({
        title: 'No registries!',
        text: 'No registries to Deploy.',
        icon: 'warning'
      })
    }
  }

  const toggleSiteActivation = () => {
    data.isActive = !data.isActive
    dispatch(updateGeneralInformation(data))
  }

  const handleChange = (event) => {
    console.log('[SiteOverview] ---------- handleChange::Event:', event)
  }

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      setPublishers(pathConfig.publisher)
      if (site.name) {
        setIndexUrl(`${pathConfig.publisher.rootSiteUrl.replace('{siteName}', siteNameFormatter(site.name))}/index.txt`)
        setIndexUrlWithCacheBuster(`${pathConfig.publisher.rootSiteUrl.replace('{siteName}', siteNameFormatter(site.name))}/index.txt?cacheBuster=${cacheBuster()}`)
      }
    } else {
      dispatch(getPath())
    }
  }, [pathConfig])  

  useEffect(() => {    
    setSite(clone(data))
    if (site.name) {
      dispatch(fetchRegistriesForSite(site._id, false))
      if (publishers.rootSiteUrl) {
        setIndexUrl(`${publishers.rootSiteUrl.replace('{siteName}', siteNameFormatter(site.name))}/index.txt`)
        setIndexUrlWithCacheBuster(`${publishers.rootSiteUrl.replace('{siteName}', siteNameFormatter(site.name))}/index.txt?cacheBuster=${cacheBuster()}`)
      } else {
        setIndexUrl(`https://i.cdn.turner.com/ads/${siteNameFormatter(site.name)}/index.txt`)
        setIndexUrlWithCacheBuster(`https://i.cdn.turner.com/ads/${siteNameFormatter(site.name)}/index.txt?cacheBuster=${cacheBuster()}`)
      }
    }
  }, [data])

  useEffect(() => {
    if (registries.length > 0) {
      let allSings = registries.filter(registry => registry.isSingleton).length
      let allRegs = registries.filter(registry => !registry.isSingleton).length
      let sings = registries.filter(registry => registry.isSingleton && !registry.isChangedSinceDeployment).length
      let regs = registries.filter(registry => !registry.isSingleton && !registry.isChangedSinceDeployment).length
      let singDep = registries.filter(registry => registry.isSingleton && registry.isChangedSinceDeployment).length
      let regDep = registries.filter(registry => !registry.isSingleton && registry.isChangedSinceDeployment).length

      setAllSiteRegistries(allRegs)
      setAllSiteSingletons(allSings)
      setSiteRegistries(regs)
      setSiteSingletons(sings)
      setSiteRegistriesToDeploy(regDep)
      setSiteSingletonsToDeploy(singDep)
    }
  }, [registries])

  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrSiteHeader)} titleTypographyProps={{ variant: 'h6' }} title={`Site Overview`}/>
      <CardContent className={addClasses(commonClasses.pl_3, commonClasses.pr_3)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={true}
              fullWidth
              margin='none'
              label='Name'
              placeholder='Name'
              value={site.name}
              onChange={(event) => {
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={!canEditSite}
              fullWidth
              margin='none'
              label='Description'
              placeholder='Description'
              value={site.description}
              onChange={(event) => {
                setIsChanged(event.target.value !== data.description)
                setSite({ ...site, description: event.target.value })
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={3}>
            {site.rootAdUnitId && (
              <>
                <label style={{display: 'block', color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Root Ad Unit:</label>
                {/* <FormControl>
                  <Select
                    disabled
                    labelId='adRoot-label'
                    style={{ minWidth: '120px' }}
                    value={site.rootAdUnitId}
                    onChange={(event) => {
                      setSite({ ...site, rootAdUnitId: event.target.value })
                    }}
                  > */}
                    {adRoots.map((item, index) => {
                      return item.dfpId === site.rootAdUnitId ? (<Typography style={{marginLeft: 0, paddingLeft: 0, paddingTop: '5px'}} key={index} value={item.dfpId}>{item.name}</Typography>) : ''
                    })}
                  {/* </Select>
                </FormControl> */}
              </>
            )}
            </Grid>
            <Grid item xs={3}>
              {site.useWOPR !== undefined && (
                <>
                  <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Registry Naming:</label><br />
                  <div style={{display: 'inline-block', marginLeft: 0, paddingLeft: 0}}>
                    <Checkbox
                      checked={!site.useWOPR}
                      onChange={handleChange}
                      name="normal"
                      size="small"
                      style={{marginLeft: 0, paddingLeft: 0}}
                      disabled
                    />
                    Normal
                  </div>
                  <div style={{display: 'inline-block'}}>
                    <Checkbox
                      checked={site.useWOPR}
                      onChange={handleChange}
                      name="wopr"
                      size="small"
                      disabled
                    />
                    WOPR
                  </div>
                  {/* <ToggleSwitch
                    offLabel='Normal'
                    onLabel='WOPR'
                    checked={site.useWOPR}
                    size="small"
                    // onChange={(event) => {
                    //   setSite({ ...site, useWOPR: event.target.checked })
                    // }}
                    style={{margin: 0, padding: 0}}
                    disabled
                  /> */}
                </>
              )}
            </Grid>
            <Grid item xs={3}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Created:</label><br />
              <Typography>
              {new Date(site.createdOn).toDateString()} {new Date(site.createdOn).toLocaleTimeString('en-US')}<div style={{fontSize: '.7em'}}>by: {site.createdBy}</div>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Last Modified:</label><br />
              <Typography>
              {new Date(site.modifiedOn).toDateString()} {new Date(site.modifiedOn).toLocaleTimeString('en-US')} <div style={{fontSize: '.7em'}}>by: {site.modifiedBy}</div>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Site Registry Index URL:</label>
              <Typography className={classes.link}>
                <Link
                  className={addClasses(commonClasses.linkColor, classes.links)}
                  href={indexUrlWithCacheBuster}
                  rel='noreferrer'
                  target='_blank'
                  onClick={() => {
                    setClickCount(clickCount + 1)
                  }}
                >
                  {indexUrl.length > 60 
                    ? (indexUrl.substring(0,30) + '[...]' + indexUrl.substring(indexUrl.length-25)) 
                    : (indexUrl)
                  }
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Registries:</label>
              <Tooltip title={`${allSiteRegistries} registries - ${siteRegistries} deployed / ${siteRegistriesToDeploy} changed since deployment`}>
                <Typography>
                  <Chip label={`${allSiteRegistries} / ${siteRegistries} / ${siteRegistriesToDeploy}`}></Chip>
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Singletons:</label>
              <Tooltip title={`${allSiteSingletons} singletons - ${siteSingletons} deployed / ${siteSingletonsToDeploy} changed since deployment`}>
                <Typography>
                  <Chip label={`${allSiteSingletons} / ${siteSingletons} / ${siteSingletonsToDeploy}`}></Chip>
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={addClasses(commonClasses.panelFooter)} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={!canEditSite}
          size="small"
          className={addClasses(
            data.isActive
              ? commonClasses.btnDanger
              : commonClasses.btnSuccess,
            commonClasses.mr_1
          )}
          variant='contained'
          title={data.isActive ? 'Deactivate Site' : 'Activate Site'}
          startIcon={
            data.isActive
              ? (
                <LockIcon />
                )
              : (<LockOpenIcon />)
          }
          onClick={toggleSiteActivation}
        >
          {data.isActive ? 'Deactivate Site' : 'Activate Site'}
        </Button>
        <NavLink
          to={`/sites/${siteNameFormatter(site.name)}/history`}
        >
          <Button
            className={addClasses(
              commonClasses.btnInfo,
              commonClasses.mr_1
            )}
            variant='contained'
            size="small"
            title='View Site History'
            startIcon={<HistoryIcon />}
          >
            View Site History
          </Button>
        </NavLink>
        <Button
          disabled={!(isAdFuelVersionSelected && canEditSite)}
          className={addClasses(
            commonClasses.btnWarning,
            commonClasses.mr_1
          )}
          size="small"
          variant='contained'
          startIcon={<CloudUploadIcon />}
          onClick={deployRegistries}
          title="Deploy Site Registries"
        >
          Deploy Site Registries
        </Button>
        { canEditSite 
          ? (
            <Button
              className={isChanged ? commonClasses.btnSuccess : ''}
              disabled={!isChanged || updatingSiteGeneralInfo}
              variant='contained'
              size="small"
              startIcon={<CheckCircleIcon />}
              title={updatingSiteGeneralInfo ? 'Saving Changes...' : 'Save Changes'}
              onClick={() => {
                dispatch(updateGeneralInformation(site))
              }}
            >
              {updatingSiteGeneralInfo ? 'Saving Changes...' : 'Save Changes'}
            </Button>
          )
        : ''
      }
      </CardActions>
    </Card>
  )
}

export default SiteOverview