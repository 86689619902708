/* eslint-disable react-hooks/exhaustive-deps */
// adding the below line to prevent infinite loop
import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  FormControlLabel,
  Container,
  CircularProgress,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import {
  updateUserRole,
  createUserRole,
  deleteUserRole,
  fetchUserRoles,
  toggleDialogStatus
} from '../../modules/roleSlice'
import { fetchRolePermissions } from '../../modules/permissionSlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { TableIcons } from '../ui/TableIcons'
import { LoadingProgress } from '../ui'
import { commonStyle } from '../../styles'
import * as yup from 'yup'

const swal = withReactContent(Swal)

const Roles = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const commonClasses = commonStyle()
  const { openDialog, roles, loading } = useSelector(state => state.roles)
  const { permissions } = useSelector(state => state.permissionSlice)
  const [role, setRole] = useState()
  const [selectedRole, setSelectedRole] = useState()
  const [isChanged, setIsChanged] = useState(false)
  const [roleList, setRoleList] = useState([])
  const [permissionList, setPermissionList] = useState([])
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const viewportSchema = yup.object().shape({
    name: yup.string().required('Name is Required'),
    description: yup.string().required('Description is Required'),
    level: yup.number().required('Level is Required').min(1)
  })

  const openEditPermissionsDialog = role => {
    setSelectedRole(role)
    setRole(role)
    dispatch(toggleDialogStatus(true))
  }

  const _toggleDialogStatus = status => {
    if (isChanged) {
      swal.fire({
        title: `${role.name} has changes.`,
        text: 'Are you sure you want to close and lose your changes?',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(isclose => {
        if (isclose.isConfirmed) {
          dispatch(toggleDialogStatus(status))
        }
      })
    } else {
      dispatch(toggleDialogStatus(status))
    }
  }

  useEffect(() => {
    if (roles.length === 0) {
      dispatch(fetchUserRoles())
    }
    if (permissions.length === 0) {
      dispatch(fetchRolePermissions())
    }
  }, [])

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      setPermissionList(permissions.map(permissions => ({ ...permissions })))
    }
  }, [permissions])

  useEffect(() => {
    setRoleList(roles.map(roles => ({ ...roles })))
  }, [roles])

  useEffect(() => {
    if (selectedRole) {
      setIsChanged(
        JSON.stringify(selectedRole.permissions) !==
          JSON.stringify(role.permissions)
      )
    }
  }, [role])

  const editPermissionsDialog = () => {
    return (
      <Dialog
        onClose={() => _toggleDialogStatus(false)}
        aria-labelledby='customized-dialog-title'
        open={openDialog}
        fullScreen={fullScreen}
      >
        <DialogTitle id='form-dialog-title'>Permissions</DialogTitle>
        <DialogContent dividers>
          <Card>
            <CardContent>
              <List>
                {permissionList.map((permission, index) => {
                  const checked = !!role?.permissions.find(
                    item => item.name === permission.name
                  )
                  return (
                    <ListItem key={index} dense>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            checked={checked}
                            onChange={event => {
                              if (event.target.checked) {
                                const newPermissions = [
                                  ...role.permissions,
                                  {
                                    name: permission.name,
                                    description: permission.description
                                  }
                                ]
                                newPermissions.sort()
                                setRole({
                                  ...role,
                                  permissions: newPermissions
                                })
                              } else {
                                setRole({
                                  ...role,
                                  permissions: role.permissions.filter(
                                    item => item.name !== permission.name
                                  )
                                })
                              }
                            }}
                          />
                        }
                        label={permission.name}
                        // label={permission.name + ': ' + permission.description}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions className={commonClasses.wrapper}>
          <Button
            disabled={!isChanged || loading}
            variant='contained'
            title={loading ? 'Saving...' : 'Save'}
            className={commonClasses.btnSuccess}
            startIcon={
              loading ? <CircularProgress size={24} /> : <CheckCircleIcon />
            }
            onClick={() => {
              dispatch(updateUserRole(role))
            }}
          >
            {loading ? 'Saving' : 'Save'}
          </Button>
          <Button
            className={commonClasses.ml_1}
            title='Cancel'
            variant='contained'
            onClick={() => _toggleDialogStatus(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container maxWidth='xl'>
      <Card>
        <CardHeader title="Roles" />
        <CardContent className={commonClasses.cardBkClr}>
          <Card>
            <CardContent>
            {roleList.length > 1
              ? (
                <MaterialTable
                  title=''
                  icons={TableIcons}
                  columns={[
                    {
                      title: 'Name',
                      field: 'name',
                      editable: 'onAdd',
                      validate: rowData => {
                        if (typeof rowData.name !== 'undefined') {
                          try {
                            viewportSchema.validateSyncAt('name', rowData)
                            return true
                          } catch (err) {
                            return { isValid: false, helperText: err.errors[0] }
                          }
                        }
                      }
                    },
                    {
                      title: 'Description',
                      field: 'description',
                      validate: rowData => {
                        if (typeof rowData.name !== 'undefined') {
                          try {
                            viewportSchema.validateSyncAt('description', rowData)
                            return true
                          } catch (err) {
                            return { isValid: false, helperText: err.errors[0] }
                          }
                        }
                      }
                    },
                    {
                      title: 'Level',
                      field: 'level',
                      type: 'numeric',
                      validate: rowData => {
                        if (typeof rowData.name !== 'undefined') {
                          try {
                            viewportSchema.validateSyncAt('level', rowData)
                            return true
                          } catch (err) {
                            return { isValid: false, helperText: err.errors[0] }
                          }
                        }
                      }
                    },
                    {
                      title: 'Permissions',
                      field: 'permissions',
                      editable: 'never',
                      render: rowData => {
                        return (
                          <Button
                            className={commonClasses.btnInfo}
                            variant='contained'
                            startIcon={<AssignmentIndIcon />}
                            onClick={() => openEditPermissionsDialog(rowData)}
                          >
                            Edit Permissions
                          </Button>
                        )
                      }
                    }
                  ]}
                  data={roleList}
                  options={{
                    addRowPosition: 'first',
                    emptyRowsWhenPaging: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    headerStyle: {
                      fontWeight: 'bold'
                    },
                    actionsColumnIndex: -1
                  }}
                  editable={{
                    onRowAdd: role => {
                      return new Promise(resolve => {
                        setTimeout(() => {
                          dispatch(createUserRole(role))
                          resolve()
                        }, 600)
                      })
                    },
                    onRowUpdate: (newRole, prevRole) => {
                      return new Promise((resolve, reject) => {
                        setTimeout(() => {
                          dispatch(updateUserRole(newRole))
                          const _roles = roleList.slice()
                          const updatedItemIndex = _roles.findIndex(
                            role => role._id === newRole._id
                          )
                          _roles[updatedItemIndex] = newRole
                          setRoleList(_roles.map(permissions => ({ ...permissions })))
                          resolve()
                        }, 0)
                      })
                    },
                    onRowDelete: role => {
                      return new Promise(resolve => {
                        setTimeout(() => {
                          dispatch(deleteUserRole(role._id))
                          resolve()
                        }, 600)
                      })
                    }
                  }}
                  detailPanel={[
                    {
                      tooltip: 'Permissions',
                      render: rowData => {
                        const permissions = rowData.permissions.map(item => ({
                          ...item
                        }))
                        permissions.sort((a, b) => {
                          const nameA = a.name.toUpperCase()
                          const nameB = b.name.toUpperCase()
                          if (nameA < nameB) {
                            return -1
                          }
                          if (nameA > nameB) {
                            return 1
                          }
                          return 0
                        })
                        return (
                          <Box padding={2}>
                            <List dense subheader='Permissions'>
                              {permissions.map((permission, index) => {
                                return (
                                  <ListItem dense key={index}>
                                    {`${permission.name} - ${permission.description}`}
                                  </ListItem>
                                )
                              })}
                            </List>
                          </Box>
                        )
                      }
                    }
                  ]}
                />
                )
              : (
                <LoadingProgress />
                )}
            {editPermissionsDialog()}
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </Container>
  )
}

export default Roles
