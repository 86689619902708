import React, { useState } from 'react'
import { FormControlLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails, Typography, Select, MenuItem, Card, FormGroup, TextField, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { addClasses, commonStyle } from '../../styles'

const useStyles = makeStyles((theme) => ({
  flexAlignItemsEnd: {
    alignContent: 'flex-end'
  },
  justifyFlexStart: {
    justifyContent: 'flex-start'
  },
  justifySpaceBetween: {
    justifyContent: 'space-between'
  },
  dropdown: {
    minWidth: '200px'
  },
  fullWidth: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular
  }
}))

export const LineItemTargeting = (props) => {
  const { data, setData, customTargets, deviceCapabilities, deviceCategories, operatingSystems, self } = props
  const commonClasses = commonStyle()
  const classes = useStyles()
  const [customTargetList, setCustomTargetList] = useState([])
  const [customTargetValuesList, setCustomTargetValuesList] = useState([])

  const displayMultiAutocomplete = ({ customTargetType, items, itemName, listNameToModify, label }) => {
    return (
      <Autocomplete
        className={addClasses(commonClasses.mr_2)}
        style={{ minWidth: 200 }}
        disableClearable
        multiple
        options={items}
        getOptionLabel={(option) => option[itemName]}
        onChange={(event, newValue) => {
          self.customTarget[customTargetType][listNameToModify] = newValue
          setData([...data])
        }}
        renderInput={(params) => (<TextField {...params} label={label} variant='standard' />)}
      />
    )
  }

  return (
    <Card elevation={3} className={addClasses(commonClasses.mb_1, commonClasses.p_2, classes.fullWidth)}>
      <FormGroup className={addClasses(classes.justifyFlexStart, commonClasses.mb_2)} row>
        <Autocomplete
          className={addClasses(commonClasses.mr_1)}
          style={{ minWidth: 200 }}
          autoSelect
          autoComplete
          noOptionsText='Type to search key'
          forcePopupIcon={false}
          options={customTargetList}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            self.customTarget.key = !newValue ? null : newValue
            if (!self.customTarget.key) {
              self.customTarget.value = null
              self.customTarget.exclude = false
            }
            setData([...data])
          }}
          onInputChange={(event, newValue, reason) => setCustomTargetList((reason === 'input' && newValue.length >= 1) ? customTargets.filter(item => item.name.match(RegExp(`^${newValue}`, 'i'))).sort() : [])}
          renderInput={(params) => (<TextField {...params} label='Custom Target' variant='standard' />)}
        />
        {self.customTarget.key
          ? (
            <Autocomplete
              className={addClasses(commonClasses.mr_1)}
              style={{ minWidth: 200 }}
              autoSelect
              autoComplete
              noOptionsText='Type to search value'
              onChange={(event, newValue) => {
                self.customTarget.value = newValue
                setData([...data])
              }}
              options={customTargetValuesList}
              getOptionLabel={(option) => option.name}
              onInputChange={(event, newValue, reason) => setCustomTargetValuesList((reason === 'input' && newValue.length >= 3) ? self.customTarget.key.values.filter(item => item.name.match(RegExp(`^${newValue}`, 'i'))).sort() : [])}
              renderInput={(params) => (<TextField {...params} label='Value' variant='standard' />)}
            />
            )
          : null}
        {self.customTarget.value
          ? (
            <FormControlLabel
              label='Exclude'
              control={(
                <Checkbox
                  color='primary'
                  checked={self.customTarget.exclude}
                  onChange={(event) => {
                    self.customTarget.exclude = event.target.checked
                    setData([...data])
                  }}
                />
            )}
            />
            )
          : null}
      </FormGroup>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography className={classes.heading}>Device Capability Targeting</Typography></AccordionSummary>
        <AccordionDetails className={commonClasses.p_2}>
          {displayMultiAutocomplete({
            customTargetType: 'deviceCapabilityTargeting',
            items: deviceCapabilities,
            itemName: 'devicecapabilityname',
            listNameToModify: 'includeList',
            label: 'Include'
          })}
          {displayMultiAutocomplete({
            customTargetType: 'deviceCapabilityTargeting',
            items: deviceCapabilities,
            itemName: 'devicecapabilityname',
            listNameToModify: 'excludeList',
            label: 'Exclude'
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography className={classes.heading}>Device Category Targeting</Typography></AccordionSummary>
        <AccordionDetails className={commonClasses.p_2}>
          {displayMultiAutocomplete({
            customTargetType: 'deviceCategoryTargeting',
            items: deviceCategories,
            itemName: 'devicecategoryname',
            listNameToModify: 'includeList',
            label: 'Include'
          })}
          {displayMultiAutocomplete({
            customTargetType: 'deviceCategoryTargeting',
            items: deviceCategories,
            itemName: 'devicecategoryname',
            listNameToModify: 'excludeList',
            label: 'Exclude'
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography className={classes.heading}>Operating System Targeting</Typography></AccordionSummary>
        <AccordionDetails>
          <FormGroup className={addClasses(classes.justifyFlexStart, commonClasses.mb_2)} row>
            <Select
              className={addClasses(commonClasses.mr_1)}
              value={self.customTarget.operatingSystemTargeting.isTargeted}
              onChange={(event) => {
                self.customTarget.operatingSystemTargeting.isTargeted = event.target.value
                setData([...data])
              }}
            >
              <MenuItem value>Include</MenuItem>
              <MenuItem value={false}>Exclude</MenuItem>
            </Select>
            <Autocomplete
              style={{ minWidth: 200 }}
              disableClearable
              multiple
              options={operatingSystems}
              getOptionLabel={(option) => option.operatingsystemname}
              onChange={(event, newValue) => {
                self.customTarget.operatingSystemTargeting.list = newValue
                setData(data)
              }}
              renderInput={(params) => (<TextField {...params} variant='standard' />)}
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Card>
  )
}
