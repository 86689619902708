import {
  string as yupstring,
  object as yupobject,
  number as yupnumber,
  array as yuparray,
  mixed as yupmixed
} from 'yup'

export const moduleVersionSchema = yupobject().shape({
  adFuelVersionId: yupmixed().required(), // MongoDB ObjectID value
  name: yupstring().required(),
  releaseNotes: yupstring(),
  url: yupstring().required()
})

export const moduleSchema = yupobject().shape({
  name: yupstring().required(),
  versions: yuparray().ensure(),
  priority: yupnumber()
    .required()
    .integer()
    .default(0),
  description: yupstring(),
  createdBy: yupstring(),
  createdOn: yupmixed(),
  modifiedBy: yupstring(),
  modifiedOn: yupmixed()
})
