import React, { useState, useEffect } from 'react'

import { Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { SlotList, DurationTable } from '../index'

const template = {
  IDENTITY: '',
  SITE_ID: {
    DOM: {
      DESKTOP: {
        atf: {},
        btf: {}
      },
      MOBILE: {
        atf: {},
        btf: {}
      },
      VIDEO: {
        DESKTOP: 0,
        MOBILE: 0
      },
      OUTSTREAM: {
        DESKTOP: 0,
        MOBILE: 0
      }
    },
    INTL: {
      DESKTOP: {
        atf: {},
        btf: {}
      },
      MOBILE: {
        atf: {},
        btf: {}
      },
      VIDEO: {
        DESKTOP: 0,
        MOBILE: 0
      },
      OUTSTREAM: {
        DESKTOP: 0,
        MOBILE: 0
      }
    }
  },
  OUTSTREAM: {
    SITE_ID: { 
      DOM: {
        DESKTOP: 0,
        MOBILE: 0
      },
      INTL: {
        DESKTOP: 0,
        MOBILE: 0
      }
    },
  },
  VIDEO: {
    WIDTH: 640,
    HEIGHT: 480,
    MIMES: ['video/mp4'],
    PROTOCOLS: [2, 3, 5, 6],
    SITE_ID: { 
      DOM: {
        DESKTOP: 0,
        MOBILE: 0
      },
      INTL: {
        DESKTOP: 0,
        MOBILE: 0
      }
    },
    DURATION: {
      DESKTOP: {
        MIN: {
          DOM: 5,
          INTL: 7
        },
        MAX: {
          DOM: 15,
          INTL: 20
        }
      },
      MOBILE: {
        MIN: {
          DOM: 6,
          INTL: 8
        },
        MAX: {
          DOM: 30,
          INTL: 30
        }

      }
    }
  }
}

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

const mergeDeep = (target, ...sources) => {
  if (!sources.length) {
    // console.log('No more sources. Returning target: ', target)
    return target
  }
  const source = sources.shift()
  // console.log('Merging in source: ', source)
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          })
        }
        // console.log('Merging key: ', key)
        mergeDeep(target[key], source[key])
      } else {
        // console.log('Setting key: ', key)
        Object.assign(target, {
          [key]: source[key]
        })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const IndexExchange = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const IX = mergeDeep(template, VENDOR.IX)
  VENDOR.IX = IX
  const { IDENTITY, SITE_ID, OUTSTREAM, VIDEO } = IX
  if (typeof VIDEO.SITE_ID.DOM === 'string') {
    VIDEO.SITE_ID.DOM = { DESKTOP: 0, MOBILE: 0 }
  }
  if (typeof VIDEO.SITE_ID.INTL === 'string') {
    VIDEO.SITE_ID.INTL = { DESKTOP: 0, MOBILE: 0 }
  }
  if (typeof OUTSTREAM.SITE_ID.DOM === 'string') {
    OUTSTREAM.SITE_ID.DOM = { DESKTOP: 0, MOBILE: 0 }
  }
  if (typeof OUTSTREAM.SITE_ID.INTL === 'string') {
    OUTSTREAM.SITE_ID.INTL = { DESKTOP: 0, MOBILE: 0 }
  }
    
  const { STRING } = validation
  const { LENGTH } = STRING
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displayPropertyTable = () => {
    return (
      <Table size='small' aria-label='IX-property-table'>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Identity</Typography></TableCell>
            <TableCell>
              <TextField
                fullWidth
                margin='none'
                placeholder='Identity'
                value={IDENTITY}
                error={errors.IX_ID}
                helperText={errors.IX_ID && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.IDENTITY = value
                  setErrors({ ...errors, IX_ID: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displaySiteIdTable = () => {
    return (
      <Table size='small' aria-label='IX-display-site-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Display Site ID</Typography></TableCell>
            <TableCell>ATF</TableCell>
            <TableCell>BTF</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Domestic Desktop</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='IX.SITE_ID.DOM.DESKTOP.atf'
                object={SITE_ID?.DOM?.DESKTOP?.atf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='IX.SITE_ID.DOM.DESKTOP.btf'
                object={SITE_ID?.DOM?.DESKTOP?.btf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Domestic Mobile</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='IX.SITE_ID.DOM.MOBILE.atf'
                object={SITE_ID?.DOM?.MOBILE?.atf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='IX.SITE_ID.DOM.MOBILE.btf'
                object={SITE_ID?.DOM?.MOBILE?.btf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>International Desktop</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='IX.SITE_ID.INTL.DESKTOP.atf'
                object={SITE_ID?.INTL?.DESKTOP?.atf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='IX.SITE_ID.INTL.DESKTOP.btf'
                object={SITE_ID?.INTL?.DESKTOP?.btf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>International Mobile</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='IX.SITE_ID.INTL.MOBILE.atf'
                object={SITE_ID?.INTL?.MOBILE?.atf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='IX.SITE_ID.INTL.MOBILE.btf'
                object={SITE_ID?.INTL?.MOBILE?.btf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayVideoTable = () => {
    return (
      <Table size='small' aria-label='IX-video-table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell colSpan="2" style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell colSpan="2" style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '180px' }} />
            <TableCell style={{ textAlign: 'center' }}>DESKTOP</TableCell>
            <TableCell style={{ textAlign: 'center' }}>MOBILE</TableCell>
            <TableCell style={{ textAlign: 'center' }}>DESKTOP</TableCell>
            <TableCell style={{ textAlign: 'center' }}>MOBILE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Video Site ID</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.IX.VIDEO.SITE_ID.DOM.DESKTOP}
                error={errors.PREBID_IX_VSDD}
                helperText={errors.PREBID_IX_VSDD && LENGTH.message}
                placeholder={'Desktop'}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.VIDEO.SITE_ID.DOM.DESKTOP = value
                  setErrors({ ...errors, PREBID_IX_VSDD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.IX.VIDEO.SITE_ID.DOM.MOBILE}
                error={errors.PREBID_IX_VSDM}
                helperText={errors.PREBID_IX_VSDM && LENGTH.message}
                placeholder={'Mobile'}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.VIDEO.SITE_ID.DOM.MOBILE = value
                  setErrors({ ...errors, PREBID_IX_VSDM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.IX.VIDEO.SITE_ID.INTL.DESKTOP}
                error={errors.PREBID_IX_VSID}
                helperText={errors.PREBID_IX_VSID && LENGTH.message}
                placeholder={'Desktop'}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.VIDEO.SITE_ID.INTL.DESKTOP = value
                  setErrors({ ...errors, PREBID_IX_VSID: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.IX.VIDEO.SITE_ID.INTL.MOBILE}
                error={errors.PREBID_IX_VSIM}
                helperText={errors.PREBID_IX_VSIM && LENGTH.message}
                placeholder={'Mobile'}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.VIDEO.SITE_ID.INTL.MOBILE = value
                  setErrors({ ...errors, PREBID_IX_VSIM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayOutstreamTable = () => {
    return (
      <Table size='small' aria-label='IX-outstream-table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell colSpan="2" style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell colSpan="2" style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '180px' }} />
            <TableCell style={{ textAlign: 'center' }}>DESKTOP</TableCell>
            <TableCell style={{ textAlign: 'center' }}>MOBILE</TableCell>
            <TableCell style={{ textAlign: 'center' }}>DESKTOP</TableCell>
            <TableCell style={{ textAlign: 'center' }}>MOBILE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Outstream Site ID</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.IX.OUTSTREAM.SITE_ID.DOM.DESKTOP}
                error={errors.PREBID_IX_OSDD}
                helperText={errors.PREBID_IX_OSDD && LENGTH.message}
                placeholder={'Desktop'}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.OUTSTREAM.SITE_ID.DOM.DESKTOP = value
                  setErrors({ ...errors, PREBID_IX_OSDD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.IX.OUTSTREAM.SITE_ID.DOM.MOBILE}
                error={errors.PREBID_IX_OSDM}
                helperText={errors.PREBID_IX_OSDM && LENGTH.message}
                placeholder={'Mobile'}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.OUTSTREAM.SITE_ID.DOM.MOBILE = value
                  setErrors({ ...errors, PREBID_IX_OSDM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.IX.OUTSTREAM.SITE_ID.INTL.DESKTOP}
                error={errors.PREBID_IX_OSID}
                helperText={errors.PREBID_IX_OSID && LENGTH.message}
                placeholder={'Desktop'}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.OUTSTREAM.SITE_ID.INTL.DESKTOP = value
                  setErrors({ ...errors, PREBID_IX_OSID: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.IX.OUTSTREAM.SITE_ID.INTL.MOBILE}
                error={errors.PREBID_IX_OSIM}
                helperText={errors.PREBID_IX_OSIM && LENGTH.message}
                placeholder={'Mobile'}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.IX.OUTSTREAM.SITE_ID.INTL.MOBILE = value
                  setErrors({ ...errors, PREBID_IX_OSIM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='IndexExchange-panel-content'
        id='IndexExchange-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>IndexExchange</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displayPropertyTable()}</Grid>
          <Grid item xs={12}>{displaySiteIdTable()}</Grid>
          <Grid item xs={12}>{displayOutstreamTable()}</Grid>
          <Grid item xs={12}>{displayVideoTable()}</Grid>
          <Grid item xs={12}>
            <DurationTable
                // path='IX.SITE_ID.DOM.DESKTOP.btf'
              path='IX.VIDEO.DURATION'
                // object={SITE_ID?.DOM?.DESKTOP?.btf}
              object={IX}
              options={options}
              allOptions={allOptions}
              setOptions={setOptions}
              setAllOptions={setAllOptions}
              errors={errors}
              setErrors={setErrors}
              validation={validation}
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
