import React, { useState, useEffect } from 'react'

import { TextField, Table, TableHead, TableBody, TableRow, TableCell, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'



const template = {
  MAPPINGS: {
    DOM: {
        DESKTOP: {
            DCN: '',
            POS: {
                atf: '',
                btf: ''
            }
        },
        MOBILE: {
            DCN: '',
            POS: {
                atf: '',
                btf: ''
            }
        }
    },
    INTL: {
        DESKTOP: {
            DCN: '',
            POS: {
                atf: '',
                btf: ''
            }
        },
        MOBILE: {
            DCN: '',
            POS: {
                atf: '',
                btf: ''
            }
        }
    }
  }
}

export const Yahoo = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const YAHOO = Object.assign(template, VENDOR.YAHOO)
  VENDOR.YAHOO = YAHOO
  const { MAPPINGS } = YAHOO
  const { DOM, INTL } = MAPPINGS
  const { DESKTOP: DOM_DESKTOP, MOBILE: DOM_MOBILE  } = DOM
  const { DESKTOP: INTL_DESKTOP, MOBILE: INTL_MOBILE  } = INTL
  const { DCN: DD_DCN, POS: DD_POS } = DOM_DESKTOP
  const { DCN: DM_DCN, POS: DM_POS } = DOM_MOBILE
  const { DCN: ID_DCN, POS: ID_POS } = INTL_DESKTOP
  const { DCN: IM_DCN, POS: IM_POS } = INTL_MOBILE

  const { STRING } = validation
  const { LENGTH } = STRING
  
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])


  const displayDCNTable = () => {
    return (
      <Table size='small' aria-label='rubicon-site-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>DCN</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Desktop
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DD_DCN}
                error={errors.PREBID_YAHOO_DD_DCN}
                helperText={errors.PREBID_YAHOO_DD_DCN && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.DOM.DESKTOP.DCN = value
                  setErrors({ ...errors, PREBID_YAHOO_DD_DCN: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={ID_DCN}
                error={errors.PREBID_YAHOO_ID_DCN}
                helperText={errors.PREBID_YAHOO_ID_DCN && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.INTL.DESKTOP.DCN = value
                  setErrors({ ...errors, PREBID_YAHOO_ID_DCN: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Mobile
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DM_DCN}
                error={errors.PREBID_YAHOO_DM_DCN}
                helperText={errors.PREBID_YAHOO_DM_DCN && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.DOM.MOBILE.DCN = value
                  setErrors({ ...errors, PREBID_YAHOO_DM_DCN: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={IM_DCN}
                error={errors.PREBID_YAHOO_IM_DCN}
                helperText={errors.PREBID_YAHOO_IM_DCN && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.INTL.MOBILE.DCN = value
                  setErrors({ ...errors, PREBID_YAHOO_IM_DCN: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayPOSTable = () => {
    return (
      <Table size='small' aria-label='rubicon-site-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6' style={{ fontWeight: 'bolder' }}>POS</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Desktop ATF
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DD_POS.atf}
                error={errors.PREBID_YAHOO_DD_POS_ATF}
                helperText={errors.PREBID_YAHOO_DD_POS_ATF && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.DOM.DESKTOP.POS.atf = value
                  setErrors({ ...errors, PREBID_YAHOO_DD_POS_ATF: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={ID_POS.atf}
                error={errors.PREBID_YAHOO_ID_POS_ATF}
                helperText={errors.PREBID_YAHOO_ID_POS_ATF && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.INTL.DESKTOP.POS.atf = value
                  setErrors({ ...errors, PREBID_YAHOO_ID_POS_ATF: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Desktop BTF
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DD_POS.btf}
                error={errors.PREBID_YAHOO_DD_POS_BTF}
                helperText={errors.PREBID_YAHOO_DD_POS_BTF && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.DOM.DESKTOP.POS.btf = value
                  setErrors({ ...errors, PREBID_YAHOO_DD_POS_BTF: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={ID_POS.btf}
                error={errors.PREBID_YAHOO_ID_POS_BTF}
                helperText={errors.PREBID_YAHOO_ID_POS_BTF && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.INTL.DESKTOP.POS.btf = value
                  setErrors({ ...errors, PREBID_YAHOO_ID_POS_BTF: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Mobile ATF
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DM_POS.atf}
                error={errors.PREBID_YAHOO_DM_POS_ATF}
                helperText={errors.PREBID_YAHOO_DM_POS_ATF && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.DOM.MOBILE.POS.atf = value
                  setErrors({ ...errors, PREBID_YAHOO_DM_POS_ATF: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={IM_POS.atf}
                error={errors.PREBID_YAHOO_IM_POS_ATF}
                helperText={errors.PREBID_YAHOO_IM_POS_ATF && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.INTL.MOBILE.POS.atf = value
                  setErrors({ ...errors, PREBID_YAHOO_IM_POS_ATF: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Mobile BTF
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DM_POS.btf}
                error={errors.PREBID_YAHOO_DM_POS_BTF}
                helperText={errors.PREBID_YAHOO_DM_POS_BTF && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.DOM.MOBILE.POS.btf = value
                  setErrors({ ...errors, PREBID_YAHOO_DM_POS_BTF: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={IM_POS.btf}
                error={errors.PREBID_YAHOO_IM_POS_BTF}
                helperText={errors.PREBID_YAHOO_IM_POS_BTF && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.YAHOO.MAPPINGS.INTL.MOBILE.POS.btf = value
                  setErrors({ ...errors, PREBID_YAHOO_IM_POS_BTF: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }


  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='yahoo-panel-content'
        id='yahoo-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Yahoo</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displayDCNTable()}</Grid>
          <Grid item xs={12}>{displayPOSTable()}</Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
