import React, { useState, useEffect } from 'react'

import { Table, TableHead, TableBody, TableRow, TableCell, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { SlotList } from '../index'

const template = {
  UID: {
    DOM: {
      DESKTOP: {
        atf: {},
        btf: {}
      },
      MOBILE: {
        atf: {},
        btf: {}
      }
    },
    INTL: {
      DESKTOP: {
        atf: {},
        btf: {}
      },
      MOBILE: {
        atf: {},
        btf: {}
      }
    }
  }
}
export const TrustX = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const TRUSTX = { ...template, ...VENDOR }
  const { UID } = TRUSTX
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displayUIDTable = () => {
    return (
      <Table size='small' aria-label='TRUSTX-UID-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>UID</Typography></TableCell>
            <TableCell>ATF</TableCell>
            <TableCell>BTF</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Domestic Desktop</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRUSTX.UID.DOM.DESKTOP.atf'
                object={UID.DOM.DESKTOP.atf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRUSTX.UID.DOM.DESKTOP.btf'
                object={UID.DOM.DESKTOP.btf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Domestic Mobile</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRUSTX.UID.DOM.MOBILE.atf'
                object={UID.DOM.MOBILE.atf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRUSTX.UID.DOM.MOBILE.btf'
                object={UID.DOM.MOBILE.btf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>International Desktop</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRUSTX.UID.INTL.DESKTOP.atf'
                object={UID.INTL.DESKTOP.atf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRUSTX.UID.INTL.DESKTOP.btf'
                object={UID.INTL.DESKTOP.btf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>International Mobile</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRUSTX.UID.INTL.MOBILE.atf'
                object={UID.INTL.MOBILE.atf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRUSTX.UID.INTL.MOBILE.btf'
                object={UID.INTL.MOBILE.btf}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='TrustX-panel-content'
        id='TrustX-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>TrustX</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displayUIDTable()}</Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
