import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllAdUnits } from '../../../modules/DFPAdUnitSlice'
import { updateCategories } from '../../../modules/sitesSlice'
import { makeStyles, Checkbox, FormControlLabel, Card, CardHeader, CardContent, CardActions, Divider, Grid, Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ToggleSwitch } from '../../ui'
import { addClasses, commonStyle } from '../../../styles'
import { clone } from '../../../utilities/helperFunctions.js'
import { getCategories } from '../../../modules/lookupsSlice'
import ResponsiveViewports from './ResponsiveViewports'

const useStyle = makeStyles(theme => ({
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
}))

const SiteConfiguration = ({ data, canEditSite }) => {
  const commonClasses = commonStyle()
  const classes = useStyle()
  const { updatingSiteCategories } = useSelector(state => state.sitesSlice)
  const [site, setSite] = useState(clone(data))
  const { categoryCodes } = site
  const { categories } = useSelector(state => state.lookups)
  const {
    isResponsive,  
    safeFrameConfig,
    safeFrameSandboxMode,
    safeFrameAllowOverlayExpansion,
    safeFrameAllowPushExpansion,
    tagForChildDirectedTreatment,
    useMCP = false
  } = site
  const [isChanged, setIsChanged] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (categories.length === 0) { dispatch(getCategories()) }
  }, [])

  useEffect(() => {
    console.log('[SiteConfiguration] ---------- useEffect[data]::data ', data)
    setSite(data)
  }, [data])

  useEffect(() => {
    if (site._id) {
      const oldData = {
        categoryCodes: [ ...data.categoryCodes ],
        isRegistryMinified: data.isRegistryMinified,
        safeFrameConfig: data.safeFrameConfig,
        safeFrameSandboxMode: data.safeFrameSandboxMode,
        safeFrameAllowOverlayExpansion: data.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: data.safeFrameAllowPushExpansion,
        tagForChildDirectedTreatment: data.tagForChildDirectedTreatment,
        isResponsive: data.isResponsive,
        useMCP: data.useMCP
      }
      const newData = {
        categoryCodes:[ ...site.categoryCodes ],
        isRegistryMinified: site.isRegistryMinified,
        safeFrameConfig: site.safeFrameConfig,
        safeFrameSandboxMode: site.safeFrameSandboxMode,
        safeFrameAllowOverlayExpansion: site.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: site.safeFrameAllowPushExpansion,
        tagForChildDirectedTreatment: site.tagForChildDirectedTreatment,
        isResponsive: site.isResponsive,
        useMCP: site.useMCP
      }
      console.log('[SiteConfiguration] ---------- useEffect[site]::comparison ', { oldData: oldData, newData: newData })
      setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
    }
  }, [site])

  console.log('[SiteConfiguration] ---------- render::site ', site)

  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrSiteHeader)} titleTypographyProps={{ variant: 'h6' }} title={`Site Configuration`}/>
      <CardContent className={addClasses(commonClasses.pl_3, commonClasses.pr_3)}>
        <Grid container spacing={2}>
          {/* {usePackage !== undefined && (
            <Grid item>
              <ToggleSwitch
                label='Implementation'
                offLabel='CDN'
                onLabel='NPM'
                checked={usePackage}
                onChange={(event) => {
                  setSite({ ...site, usePackage: event.target.checked })
                  setImplementationMethod(event.target.checked ? 'NPM' : 'CDN')
                }}
                className={commonClasses.toggleSwitch}
                color='success'
              />
            </Grid>
          )} */}
          {tagForChildDirectedTreatment !== undefined && (
            <Grid item xs={3}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Tag For Child-Directed Treatment:</label><br />
              <ToggleSwitch
                // disabled
                offLabel='No'
                onLabel='Yes'
                checked={tagForChildDirectedTreatment}
                onChange={(event) => {
                  setSite({ ...site, tagForChildDirectedTreatment: event.target.checked })
                }}
                className={commonClasses.toggleSwitch}
                color='success'
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Site Categories:</label><br />
            {!!categoryCodes && categories !== undefined && categories.map((category, index) => {
              const checked = !!categoryCodes.find(item => item === category.code)
              return (
                <FormControlLabel
                  key={index}
                  size="small"
                  style={{padding: 0, margin: 0, fontSize: '.7em'}}
                  control={(
                    <Checkbox
                      disabled={!canEditSite || updatingSiteCategories}
                      color='secondary'
                      className={commonClasses.checkboxControl}
                      checked={checked}
                      size="small"
                      style={{padding: 5, paddingTop: 2, paddingBottom: 2, margin: 5, fontSize: '.5em'}}
                      onChange={(event) => {
                        const checked = event.target.checked
                        if (checked) {
                          setSite({ ...site, categoryCodes: [...site.categoryCodes, category.code] })
                        } else {
                          setSite({ ...site, categoryCodes: site.categoryCodes.filter(item => item !== category.code) })
                        }
                      }}
                    />
                  )}
                  label={category.name}
                />
              )
            })}
          </Grid>
          <Grid item xs={3}>
            <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Ad Platform:</label><br />
            <FormControlLabel
              key={'platform_adfuel'}
              control={(
                <Checkbox
                  disabled={!canEditSite || updatingSiteCategories}
                  color='secondary'
                  className={commonClasses.checkboxControl}
                  checked={!useMCP}
                  onClick={(event) => {
                    setSite({ ...site, useMCP: false })
                  }}
                />
              )}
              label={'AdFuel'}
            />
            <FormControlLabel
              key={'platform_mcp'}
              control={(
                <Checkbox
                  disabled={!canEditSite || updatingSiteCategories}
                  color='secondary'
                  className={commonClasses.checkboxControl}
                  checked={useMCP}
                  onClick={(event) => {
                    setSite({ ...site, useMCP: true })
                  }}
                />
              )}
              label={'MCP'}
            />
          </Grid>
          {safeFrameConfig !== undefined && (
            <Grid item xs={12} container>
              <Grid item xs={3}>
                <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Safeframe Configuration:</label><br />
                <ToggleSwitch
                  disabled={!canEditSite || updatingSiteCategories}
                  offLabel='Disabled'
                  onLabel='Enabled'
                  checked={safeFrameConfig}
                  onChange={(event) => {
                    console.log('[SiteConfiguration] ---------- onChange::setting safeFrameConfig', event.target.checked, site.safeFrameConfig)                    
                    if (event.target.checked !== site.safeFrameConfig) setIsChanged(true)
                    setSite({ ...site, safeFrameConfig: event.target.checked })
                  }}
                  color='info'
                />
              </Grid>
              {safeFrameConfig && (
                <>
                  <Grid item>
                    <ToggleSwitch
                      disabled={!canEditSite || updatingSiteCategories}
                      label='Sandbox'
                      offLabel='No'
                      onLabel='Yes'
                      checked={safeFrameSandboxMode}
                      onChange={(event) => {
                        setSite({ ...site, safeFrameSandboxMode: event.target.checked })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ToggleSwitch
                      disabled={!canEditSite || updatingSiteCategories}
                      label='Allow Overlay Expansion'
                      offLabel='No'
                      onLabel='Yes'
                      checked={safeFrameAllowOverlayExpansion}
                      onChange={(event) => {
                        setSite({ ...site, safeFrameAllowOverlayExpansion: event.target.checked })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ToggleSwitch
                      disabled={!canEditSite || updatingSiteCategories}
                      label='Allow Push Expansion'
                      offLabel='No'
                      onLabel='Yes'
                      checked={safeFrameAllowPushExpansion}
                      onChange={(event) => {
                        setSite({ ...site, safeFrameAllowPushExpansion: event.target.checked })
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
          <Grid item xs={12} container>
            <Grid item xs={3}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Responsive Mapping:</label><br />
              <ToggleSwitch
                disabled={!canEditSite || updatingSiteCategories}
                offLabel='Disabled'
                onLabel='Enabled'
                checked={!!isResponsive}
                onChange={(event) => {
                  setSite({ ...site, isResponsive: event.target.checked })
                }}
                color='info'
              />
            </Grid>
            <Grid item xs>
              {!!isResponsive ? <ResponsiveViewports data={site} canEditSite={canEditSite} setIsChanged={setIsChanged} isChanged={isChanged} /> : ''}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={commonClasses.panelFooter} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className={isChanged ? commonClasses.btnSuccess : ''}
          disabled={!isChanged || updatingSiteCategories}
          size="small"
          variant='contained'
          startIcon={<CheckCircleIcon />}
          onClick={() => {
            dispatch(updateCategories(site))
          }}
        >
          {updatingSiteCategories ? 'Saving...' : 'Save'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default SiteConfiguration