import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from './authSlice'
import cdnServiceSlice from './cdnService'
import userSlice from './userSlice'
import permissionSlice from './permissionSlice'
import roleSlice from './roleSlice'
import dfpSlice from './DFPAdUnitSlice'
import lookups from './lookupsSlice'
import registrySlice from './registrySlice'
import siteCategorySlice from './siteCategorySlice'
import reportNotificationsSlice from './reportNotificationsSlice'
import slotTypeSlice from './slotTypesSlice'
import slotLocationSlice from './slotLocationSlice'
import sitesSlice from './sitesSlice'
import bannerMessageSlice from './bannerMessageSlice'
import emailUsersSlice from './emailUsersSlice'
import adLoaderCDNFoldersSlice from './AdLoaderCDNFoldersSlice'
import adFuelSlice from './adFuelSlice'
import adFuelModulesSlice from './adFuelModuleSlice'
import slotSlice from './slotSlice'
import configSlice from './configSlice'

// // console.log('lookups: ', lookups)
const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    cdnService: cdnServiceSlice,
    users: userSlice,
    roles: roleSlice,
    dfp: dfpSlice,
    lookups,
    registrySlice,
    sitesSlice,
    slotLocationSlice,
    permissionSlice,
    roleSlice,
    userSlice,
    siteCategorySlice,
    reportNotificationsSlice,
    slotTypeSlice,
    bannerMessageSlice,
    emailUsersSlice,
    adLoaderCDNFoldersSlice,
    adFuelSlice,
    adFuelModulesSlice,
    slotSlice,
    configSlice
  })

export default createRootReducer
