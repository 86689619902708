import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRocketeerSites, addSiteComment } from '../../modules/sitesSlice'
import {
  fetchRegistriesForSite,
  addSiteRegistryComment
} from '../../modules/registrySlice'

import { NavLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
  LinearProgress,
  Container,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControl,
  TextField,
  Button,
  DialogActions,
  Card,
  CardHeader
} from '@material-ui/core'
import MaterialTable from 'material-table'
import { TableIcons } from '../ui'

import {
  siteNameFormatter,
  registryNameFormatter,
  hasRole
} from '../../utilities/helperFunctions'
import { addClasses, commonStyle } from '../../styles'

const getHistoryType = path => {
  let result = ''
  switch (true) {
    case path === '/sites/:siteName/history':
      result = 'site'
      break
    case path === '/sites/:siteName/registries/:registryName/history':
      result = 'registry'
      break
    default:
      // console.log('type not found for path: ', path)
  }
  return result
}

const columns = [
  {
    title: 'Modified On',
    field: 'modifiedOn',
    headerStyle: { fontWeight: 'bold' }
  },
  {
    title: 'Modified By',
    field: 'modifiedBy',
    headerStyle: { fontWeight: 'bold' }
  },
  {
    title: 'Area',
    field: 'area',
    headerStyle: { fontWeight: 'bold' }
  },
  {
    title: 'Old Value',
    field: 'oldValue',
    headerStyle: { fontWeight: 'bold' }
  },
  {
    title: 'New Value',
    field: 'newValue',
    headerStyle: { fontWeight: 'bold' }
  }
]

const validationSchema = yup.object().shape({
  comment: yup.string().required()
})

export const ViewHistoryBeta = props => {
  const { match } = props
  const historyType = getHistoryType(match.path)
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { site } = useSelector(state => state.sitesSlice)
  const { registry } = useSelector(state => state.registrySlice)
  const [model, setModel] = useState([])
  const [comment, setComment] = useState([])
  const [historyItems, setHistoryItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openSiteDialog, setOpenSiteDialog] = useState(false)
  const [loading, setLoading] = useState(true)
  const { user } = useSelector(state => state.authReducer)

  const { register, formState: { errors }, setValue, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const tableActions = historyType === 'registry'
    ? [
        {
          icon: TableIcons.Add,
          tooltip: 'Add Comment',
          isFreeAction: true,
          onClick: () => setOpenDialog(true)
        }
      ]
    : [{
      icon: TableIcons.Add,
      tooltip: 'Add Comment',
      isFreeAction: true,
      onClick: () => setOpenSiteDialog(true)
    }]

  const AddNewComment = () => {
    dispatch(addSiteRegistryComment(model._id, comment))
    setOpenDialog(false)
  }

  const AddNewSiteComment = () => {
    dispatch(addSiteComment(model._id, comment))
    setOpenSiteDialog(false)
  }

  useEffect(() => {
    if (!site._id) {
      dispatch(fetchRocketeerSites())
    }
  }, [])

  useEffect(() => {
    dispatch(fetchRegistriesForSite(site._id, true))
  }, [site])

  useEffect(() => {
    console.log(`History Type Is ${historyType.toUpperCase()}`)
    switch (true) {
      case historyType === 'registry':
        if (registry) {
          console.log(`Setting 'model' to a REGISTRY`)
          setModel(registry)
          if (registry.history) {
            setHistoryItems(registry.history.filter(history => history.area !== 'registry - added comment').map(history => ({ ...history })))
            setLoading(false)
          }
        }
        break
      case historyType === 'site':
        if (site) {
          console.log(`Setting 'model' to a SITE`)
          setModel(site)
          if (site.history) {
            setHistoryItems(site.history.filter(history => history.area !== 'site - added comment').map(history => ({ ...history })))
            setLoading(false)
          }
        }
        break
      default:
    }
  }, [site, registry])

  const displayNavLink = (url, name) => {
    const isActive = name === 'History'
    return (
      <NavLink
        key={name}
        activeStyle={
          isActive ? { fontWeight: 'bold', textDecoration: 'underline' } : {}
        }
        color='inherit'
        to={url}
      >
        {name}
      </NavLink>
    )
  }

  const displayBreadcrumbs = () => {
    const breadcrumbs = [
      displayNavLink(
        `/sites/${siteNameFormatter(site.name)}`,
        site.name || 'Site'
      )
    ]
    if (historyType === 'registry') {
      breadcrumbs.push(
        displayNavLink(
          `/sites/${siteNameFormatter(site.name)}/registries`,
          'Registries'
        ),
        displayNavLink(
          `/sites/${siteNameFormatter(
            site.name
          )}/registries/${registryNameFormatter(registry.name)}`,
          registry.name || 'Registry'
        ),
        displayNavLink(
          `/sites/${siteNameFormatter(
            site.name
          )}/registries/${registryNameFormatter(registry.name)}/history`,
          'History'
        )
      )
    } else {
      breadcrumbs.push(
        displayNavLink(
          `/sites/${siteNameFormatter(site.name)}/history`,
          'History'
        )
      )
    }
    return (
      <Breadcrumbs
        className={addClasses(commonClasses.pl_3, commonClasses.pb_1)}
        aria-label='breadcrumb'
      >
        {breadcrumbs.map(breadcrumb => breadcrumb)}
      </Breadcrumbs>
    )
  }

  const displayDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth='md'
        onClose={() => setOpenDialog(false)}
        aria-labelledby={`${model.name} comment`}
        open={openDialog}
      >
        <DialogTitle id='form-dialog-title'>Add Registry Comment</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormControl
                className={commonClasses.m_1}
                style={{ width: '100%' }}
              >
                <TextField
                  label={`${model.name} comment`}
                  {...register('comment')}
                  margin='dense'
                  style={{ marginTop: 0 }}
                  defaultValue={comment}
                  variant='outlined'
                  onChange={e => {
                    setComment(e.target.value)
                    setValue('comment', e.target.value, { shouldValidate: true })
                  }}
                  multiline
                  rows={3}
                  error={!!errors.comment}
                  helperText={errors.comment ? errors.comment.message : ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className={commonClasses.btnSuccess}
            onClick={handleSubmit(AddNewComment)}
            variant='contained'
          >
            Save
          </Button>
          <Button
            className={commonClasses.ml_1}
            title='Cancel'
            variant='contained'
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const displaySiteDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth='md'
        onClose={() => setOpenSiteDialog(false)}
        aria-labelledby={`${model.name} comment`}
        open={openSiteDialog}
      >
        <DialogTitle id='form-dialog-title'>Add Site Comment</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormControl
                className={commonClasses.m_1}
                style={{ width: '100%' }}
              >
                <TextField
                  label={`${model.name} comment`}
                  {...register('comment')}
                  margin='dense'
                  style={{ marginTop: 0 }}
                  defaultValue={comment}
                  variant='outlined'
                  onChange={e => {
                    setComment(e.target.value)
                    setValue('comment', e.target.value, { shouldValidate: true })
                  }}
                  multiline
                  rows={3}
                  error={!!errors.comment}
                  helperText={errors.comment ? errors.comment.message : ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className={commonClasses.btnSuccess}
            onClick={handleSubmit(AddNewSiteComment)}
            variant='contained'
          >
            Save
          </Button>
          <Button
            className={commonClasses.ml_1}
            title='Cancel'
            variant='contained'
            onClick={() => setOpenSiteDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <>
      {displayBreadcrumbs()}
      <Container maxWidth='lg'>
        {historyType === 'registry' &&
        hasRole(
          ['admin', 'inview-refresh-editor', 'site-editor', 'registry-editor'],
          user
        )
          ? displayDialog()
          : null}
        {historyType === 'site' &&
        hasRole(
          ['admin', 'inview-refresh-editor', 'site-editor'],
          user
        )
          ? displaySiteDialog()
          : null}
        {!loading
          ? (
            <Card className={addClasses(commonClasses.roundedCard)}>
              <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, historyType === 'site' ? commonClasses.clrSiteHeader : commonClasses.clrRegistryHeader)} titleTypographyProps={{ variant: 'h6' }} title={`${model.name} History`}/>
              <MaterialTable
                title={`                `}
                icons={TableIcons}
                columns={columns}
                data={historyItems.sort(
                  (a, b) =>
                    parseInt(b.modifiedOn.replace(/-|T|:|\./g, '')) -
                  parseInt(a.modifiedOn.replace(/-|T|:|\./g, ''))
                )}
                options={{
                  addRowPosition: 'first',
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                  pageSizeOptions: [10, 20, 50, 100],
                  headerStyle: {
                    fontWeight: 'bold'
                  }
                }}
                actions={tableActions}
              />
            </Card>
          )
          : (
            <LinearProgress />
          )
        }
      </Container>
    </>
  )
}
