import React, { useState, useEffect } from 'react'

import { TextField, Table, TableHead, TableBody, TableRow, TableCell, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { DurationTable } from '../index'

const template = {
  CHANNEL_ID: {
    DOM: { 
      DESKTOP: '',
      MOBILE: ''
    },
    INTL: { 
      DESKTOP: '',
      MOBILE: ''
    }
  },
  VIDEO: {
    DURATION: {
      DESKTOP: {
        MIN: {
          DOM: 5,
          INTL: 5
        },
        MAX: {
          DOM: 15,
          INTL: 15
        }
      },
      MOBILE: {
        MIN: {
          DOM: 6,
          INTL: 6
        },
        MAX: {
          DOM: 30,
          INTL: 30
        }

      }
    }
  }
}

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

const mergeDeep = (target, ...sources) => {
  if (!sources.length) {
    // console.log('No more sources. Returning target: ', target)
    return target
  }
  const source = sources.shift()
  // console.log('Merging in source: ', source)
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          })
        }
        // console.log('Merging key: ', key)
        mergeDeep(target[key], source[key])
      } else {
        // console.log('Setting key: ', key)
        Object.assign(target, {
          [key]: source[key]
        })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const SpotX = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  let { PREBID } = options
  let { VENDOR } = PREBID
  
  let SPOTX = mergeDeep(template, VENDOR.SPOTX)
  VENDOR.SPOTX = SPOTX
  let { STRING } = validation
  let { LENGTH } = STRING

  let {
    CHANNEL_ID
  } = SPOTX

  let { DOM, INTL } = CHANNEL_ID
  if (typeof DOM === 'string') {
    DOM = { DESKTOP: '', MOBILE: '' }
  }
  if (typeof INTL === 'string') {
    INTL = { DESKTOP: '', MOBILE: '' }
  }
  CHANNEL_ID = { DOM, INTL }
  SPOTX.CHANNEL_ID = CHANNEL_ID
  VENDOR.SPOTX = SPOTX

  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displayChannelIdTable = () => {
    return (
      <Table size='small' aria-label='spotx-channel-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }} />
            <TableCell colSpan="2" style={{ textAlign: 'center' }}>DOMESTIC</TableCell>
            <TableCell colSpan="2" style={{ textAlign: 'center' }}>INTERNATIONAL</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '180px' }} />
            <TableCell style={{ textAlign: 'center' }}>DESKTOP</TableCell>
            <TableCell style={{ textAlign: 'center' }}>MOBILE</TableCell>
            <TableCell style={{ textAlign: 'center' }}>DESKTOP</TableCell>
            <TableCell style={{ textAlign: 'center' }}>MOBILE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Channel ID</Typography></TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={PREBID.VENDOR.SPOTX.CHANNEL_ID.DOM.DESKTOP}
                error={errors.PREBID_SPOTX_CIDDD}
                helperText={errors.PREBID_SPOTX_CIDDD && LENGTH.message}
                placeholder="Desktop"
                onChange={(event) => {
                  console.log('Setting SpotX DOM Desktop Channel ID...', event.target.value)
                  const value = event.target.value
                  PREBID.VENDOR.SPOTX.CHANNEL_ID.DOM.DESKTOP = value
                  console.log('New Value:', JSON.stringify(PREBID.VENDOR.SPOTX.CHANNEL_ID.DOM.DESKTOP))
                  setErrors({ ...errors, PREBID_SPOTX_CIDDD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={DOM.MOBILE}
                error={errors.PREBID_SPOTX_CIDDM}
                helperText={errors.PREBID_SPOTX_CIDDM && LENGTH.message}
                placeholder="Mobile"
                onChange={(event) => {
                  console.log('Setting SpotX DOM Mobile Channel ID...', event.target.value)
                  const value = event.target.value
                  PREBID.VENDOR.SPOTX.CHANNEL_ID.DOM.MOBILE = value
                  console.log('New Value:', JSON.stringify(PREBID.VENDOR.SPOTX.CHANNEL_ID.DOM.MOBILE))
                  setErrors({ ...errors, PREBID_SPOTX_CIDDM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={INTL.DESKTOP}
                error={errors.PREBID_SPOTX_CIDID}
                helperText={errors.PREBID_SPOTX_CIDID && LENGTH.message}
                placeholder="Desktop"
                onChange={(event) => {
                  console.log('Setting SpotX INTL Desktop Channel ID...', event.target.value)
                  const value = event.target.value
                  PREBID.VENDOR.SPOTX.CHANNEL_ID.INTL.DESKTOP = value
                  console.log('New Value:', JSON.stringify(PREBID.VENDOR.SPOTX.CHANNEL_ID.INTL.DESKTOP))
                  setErrors({ ...errors, PREBID_SPOTX_CIDID: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <TextField
                margin='none'
                value={INTL.MOBILE}
                error={errors.PREBID_SPOTX_CIDIM}
                helperText={errors.PREBID_SPOTX_CIDIM && LENGTH.message}
                placeholder="Mobile"
                onChange={(event) => {
                  console.log('Setting SpotX INTL Mobile Channel ID...', event.target.value)
                  const value = event.target.value
                  PREBID.VENDOR.SPOTX.CHANNEL_ID.INTL.MOBILE = value
                  console.log('New Value:', JSON.stringify(PREBID.VENDOR.SPOTX.CHANNEL_ID.INTL.MOBILE))
                  setErrors({ ...errors, PREBID_SPOTX_CIDIM: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='spotx-panel-content'
        id='spotx-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>SpotX</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displayChannelIdTable()}</Grid>
          <Grid item xs={12}>
            <DurationTable
                // path='IX.SITE_ID.DOM.DESKTOP.btf'
              path='APPNEXUS.VIDEO.DURATION'
                // object={SITE_ID?.DOM?.DESKTOP?.btf}
              object={SPOTX}
              options={options}
              allOptions={allOptions}
              setOptions={setOptions}
              setAllOptions={setAllOptions}
              errors={errors}
              setErrors={setErrors}
              validation={validation}
            />

          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
