import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, requestUpload, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

const initialState = {
  uploads: [],
  folders: [],
  upload: null,
  uploadComplete: false,
  loading: false,
  count: 0,
  error: null
}
const cdnServiceSlice = createSlice({
  name: 'cdnServiceSlice',
  initialState,
  reducers: {
    uploadSuccess: (state, action) => {
      state.upload = action.payload
      state.loading = false
      state.uploadComplete = true
      swal.fire({
        title: 'AdImage Uploaded Successfully',
        icon: 'success'
      })
    },
    getFolders: (state, action) => {
      state.loading = false
      state.folders = action.payload
    },
    getUploads: (state, action) => {
      state.loading = false
      state.uploads = action.payload
    },
    getUploadCount: (state, action) => {
      state.loading = false
      state.count = action.payload.count
    },
    purgeCdnUrls: (state, action) => {
      state.loading = false
      if (action.payload) {
        swal.fire({
          title: 'Urls Purged',
          message:
            'Urls have been purged successfully, please wait at least 5 mins for full purge to take effect',
          icon: 'success'
        })
      }
    },
    loadingStatus: (state) => {
      state.loading = true
    },
    errorStatus: (state, action) => {
      state.loading = false
      state.error = action.payload
      handleError(action.payload, showError)
    }
  }
})

export default cdnServiceSlice.reducer

export const {
  uploadSuccess,
  getFolders,
  loadingStatus,
  errorStatus,
  getUploads,
  getUploadCount,
  purgeCdnUrls
} = cdnServiceSlice.actions

export const fetchAdLoaderUploads = (params) => {
  return async (dispatch) => {
    dispatch(loadingStatus())
    return request('/cdnUploads', 'GET', null, params)
      .then((res) => dispatch(getUploads(res.data)))
      .catch((err) => dispatch(errorStatus(err)))
  }
}

export const fetchAdLoaderFolders = () => {
  return async (dispatch) => {
    dispatch(loadingStatus())
    return request('/lookups/cdnFolders')
      .then((res) => dispatch(getFolders(res.data)))
      .catch((err) => dispatch(errorStatus(err.response.data)))
  }
}

export const uploadAsset = formData => {
  return async dispatch => {
    dispatch(loadingStatus())
    return requestUpload('/cdnUploads', 'POST', formData)
      .then(upload => dispatch(uploadSuccess(upload.data)))
      .then(() => dispatch(fetchAdLoaderUploads()))
      .catch(err => dispatch(errorStatus(err.response.data)))
  }
}

export const getCdnUploadsCount = (params) => {
  return (dispatch) => {
    dispatch(loadingStatus())
    return request('/cdnUploadsCount', 'GET', null, params)
      .then((res) => dispatch(getUploadCount(res.data)))
      .catch((err) => dispatch(errorStatus(err.response.data)))
  }
}

export const purgeCdn = (cdnUris) => {
  return (dispatch) => {
    dispatch(loadingStatus())
    return request('/cdnPurge', 'POST', cdnUris)
      .then((res) => dispatch(purgeCdnUrls(res.data)))
      .catch((err) => dispatch(errorStatus(err.response.data)))
  }
}
