/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
// import { string as yupstring, object as yupobject } from 'yup'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import FormGroup from '@material-ui/core/FormGroup'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CancelIcon from '@material-ui/icons/Cancel'
import InfoIcon from '@material-ui/icons/Info'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'

import ReleaseNotesDialog from './ReleaseNotesDialog'
import ConfigurationDialog from './ConfigurationDialog'
import MainDialog from './MainDialog'
import { moduleSchema, moduleVersionSchema } from './schemas'
import { fetchAdFuelVersions } from '../../../modules/adFuelSlice'
import {
  createAdFuelModule,
  updateAdFuelModules,
  removeAdFuelModule,
  fetchAdFuelModules,
  isModuleInUse
} from '../../../modules/adFuelModuleSlice'
import { request } from '../../../utilities/request'
import { commonStyle } from '../../../styles'

import semver from 'semver'

// import { createAdFuelModule } from '../../../modules/lookupsSlice'

const swal = withReactContent(Swal)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  hidden: {
    display: 'none',
    visibility: 'collapse'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  spacer: { margin: theme.spacing(1) },
  plainText: {
    fontSize: theme.typography.pxToRem(18)
  },
  buttonGroup: {
    marginTop: theme.spacing(1),
    position: 'relative',
    top: 5
  }
}))

const StyledTableRow = withStyles(theme => ({
  root: {
    verticalAlign: 'top',
    border: 'none'
  }
}))(TableRow)

const StyledTableCell = withStyles(theme => ({
  root: {
    border: 'none'
  }
}))(TableCell)

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead (props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key='name'
          sortDirection={orderBy === 'name' ? order : false}
          style={{ width: '40%', fontWeight: 'bold' }}
        >
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={createSortHandler('name')}
          >
            Name
          </TableSortLabel>
        </TableCell>
        <TableCell
          key='version'
          sortDirection={orderBy === 'version' ? order : false}
          style={{ width: '25%', fontWeight: 'bold' }}
        >
          Version
        </TableCell>
        <TableCell
          key='priority'
          sortDirection={orderBy === 'priority' ? order : false}
          style={{ width: '20%', fontWeight: 'bold' }}
        >
          <TableSortLabel
            active={orderBy === 'priority'}
            direction={orderBy === 'priority' ? order : 'asc'}
            onClick={createSortHandler('priority')}
          >
            Priority
          </TableSortLabel>
        </TableCell>
        <TableCell
          align='right'
          style={{
            width: '15%',
            fontWeight: 'bold'
          }}
        >
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
}

const AdFuelModules = props => {
  const classes = useStyles()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { adFuelVersions } = useSelector(state => state.adFuelSlice)
  const { adFuelModules } = useSelector(state => state.adFuelModulesSlice)
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openReleaseNotes, setOpenReleaseNotes] = useState(false)
  const [expanded, setExpanded] = useState({ 0: false })
  const [filteredAdFuelModules, setFilteredAdFuelModules] = useState([])
  const [versions, setVersions] = useState({})
  const [selectedModule, setSelectedModule] = useState({})
  const [selectedVersion, setSelectedVersion] = useState({})
  const [addingNewVersion, setAddingNewVersion] = useState(false)
  const [isEditingVersion, setIsEditingVersion] = useState(null)
  const [newVersionName, setNewVersionName] = useState('')
  const [newVersionUrl, setNewVersionUrl] = useState(
    'https://i.cdn.turner.com/ads/'
  )
  const [newVersionSelectedVersion, setNewVersionSelectedVersion] = useState('')

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => {
    updateState({})
    handleSearchChange()
  }, [])
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')

  const initialState = {
    name: '',
    description: '',
    versions: [],
    priority: 9,
    url: '',
    adFuelVersionId: null,
    isConfigurable: false,
    configurationKey: '',
    logKey: '',
    createdBy: '',
    createdOn: Date.now(),
    modifiedBy: '',
    modifiedOn: Date.now(),
    clean: true
  }

  // const validationSchema = yupobject().shape({
  //   txtVersion: yupstring().required('Required'),
  //   txtUrl: yupstring().required('Required'),
  //   selectVersion: yupstring().required('Required')
  // })

  const {
    register,
    formState: { errors },
    setValue
  } = useForm({
    // resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const handleExpand = index => {
    if (expanded[index]) {
      setExpanded({ [index]: false })
    } else {
      setExpanded({ [index]: true })
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  // Release Notes Dialog Functions
  const handleReleaseNotesSave = (module, version, notes) => {
    let updatedAdFuelModules = adFuelModules.map(module => ({ ...module }))
    const updatedAdFuelModule = JSON.parse(JSON.stringify(module))
    let updatedAdFuelModuleVersions = updatedAdFuelModule.versions
    const updatedVersion = JSON.parse(JSON.stringify(version))
    updatedVersion.releaseNotes = notes
    moduleVersionSchema
      .validate(updatedVersion)
      .then(validVersion => {
        if (validVersion) {
          updatedAdFuelModuleVersions = updatedAdFuelModuleVersions.map(ver => {
            if (ver.name === validVersion.name) {
              return validVersion
            }
            return ver
          })
          updatedAdFuelModule.versions = updatedAdFuelModuleVersions
          moduleSchema
            .validate(updatedAdFuelModule)
            .then(validDoc => {
              if (validDoc) {
                // console.log('Ok to save Module...')
                updatedAdFuelModules = updatedAdFuelModules.map(mod => {
                  if (
                    mod._id === updatedAdFuelModule._id ||
                    mod.name === updatedAdFuelModule.name
                  ) {
                    return updatedAdFuelModule
                  }
                  return mod
                })
                // console.log('Updated Modules: ', updatedAdFuelModules)

                // setAdFuelModules(updatedAdFuelModules.map(module => ({ ...module })))
                setSelectedVersion(updatedVersion)
                dispatch(
                  updateAdFuelModules(updatedAdFuelModules, updatedAdFuelModule)
                )
              }
            })
            .catch(error => {
              console.error('Error: ', error)
              console.error('Error Name: ', error.name) // => 'ValidationError'
              console.error('Errors: ', error.errors) // => [{ key: 'field_too_short', values: { min: 18 } }]
            })
        } else {
          // console.log('Not Valid. /shrug')
        }
      })
      .catch(err => {
        console.error('Error: ', err)
        console.error('Error Name: ', err.name) // => 'ValidationError'
        console.error('Errors: ', err.errors) // => [{ key: 'field_too_short', values: { min: 18 } }]
      })
  }

  const handleClickReleaseNotesOpen = (afModule, version) => {
    if (afModule) setSelectedModule(afModule)
    if (version) setSelectedVersion(version)
    setOpenReleaseNotes(!openReleaseNotes)
  }

  // Configuration Dialog Functions
  const handleClickOpen = (afModule, version) => {
    // console.log('Handling Click Open: ', { afModule, version })
    if (afModule) setSelectedModule(afModule)
    if (version) setSelectedVersion(version)
    setOpen(!open)
  }

  const handleArgumentSave = (module, version, args) => {
    // console.log('Handling Argument Saving... ', { module, version, args })
    let updatedAdFuelModules = adFuelModules.map(module => ({ ...module }))
    const updatedAdFuelModule = JSON.parse(JSON.stringify(module))
    let updatedAdFuelModuleVersions = updatedAdFuelModule.versions
    const updatedVersion = JSON.parse(JSON.stringify(version))
    updatedVersion.arguments = args || updatedVersion.arguments
    // console.log('Updated Version: ', updatedVersion)
    moduleVersionSchema
      .validate(updatedVersion)
      .then(validVersion => {
        // console.log('valid version: ', validVersion)
        if (validVersion) {
          updatedAdFuelModuleVersions = updatedAdFuelModuleVersions.map(ver => {
            // console.log('ver: ', ver)
            if (ver.name === validVersion.name) {
              // console.log('found match: ', { ver, version })
              return validVersion
            }
            return ver
          })
          // console.log('Updated Module Versions: ', updatedAdFuelModuleVersions)
          updatedAdFuelModule.versions = updatedAdFuelModuleVersions
          // console.log('Updated Module: ', updatedAdFuelModule)
          moduleSchema
            .validate(updatedAdFuelModule)
            .then(validDoc => {
              if (validDoc) {
                // console.log('Ok to save Module...')
                updatedAdFuelModules = updatedAdFuelModules.map(mod => {
                  if (
                    mod._id === updatedAdFuelModule._id ||
                    mod.name === updatedAdFuelModule.name
                  ) {
                    return updatedAdFuelModule
                  }
                  return mod
                })
                // console.log('Updated Modules: ', updatedAdFuelModules)

                // setAdFuelModules(updatedAdFuelModules.map(module => ({ ...module })))
                setSelectedVersion(updatedVersion)
                dispatch(
                  updateAdFuelModules(updatedAdFuelModules, updatedAdFuelModule)
                )
              }
            })
            .catch(error => {
              console.error('Error: ', error)
              console.error('Error Name: ', error.name) // => 'ValidationError'
              console.error('Errors: ', error.errors) // => [{ key: 'field_too_short', values: { min: 18 } }]
            })
        } else {
          // console.log('Not Valid. /shrug')
        }
      })
      .catch(err => {
        console.error('Error: ', err)
        console.error('Error Name: ', err.name) // => 'ValidationError'
        console.error('Errors: ', err.errors) // => [{ key: 'field_too_short', values: { min: 18 } }]
      })
  }

  // Main Dialog Functions
  const handleClickOpenEdit = afModule => {
    // // console.log('Handling Click Open Edit: ', { afModule })
    if (afModule) setSelectedModule(afModule)
    setOpenEdit(!openEdit)
  }

  const handleClickOpenSave = (
    moduleName,
    moduleDescription,
    moduleURL,
    moduleIsConfigurable,
    moduleConfigurationKey,
    moduleLogKey,
    modulePriority,
    moduleVersions
  ) => {
    const updatedModule = {
      ...selectedModule,
      name: moduleName,
      url: moduleURL,
      priority: modulePriority,
      description: moduleDescription,
      isConfigurable: moduleIsConfigurable,
      configurationKey: moduleConfigurationKey,
      logKey: moduleLogKey,
      versions: moduleVersions
    }
    delete updatedModule.__v
    moduleSchema
      .validate(updatedModule)
      .then(validDoc => {
        if (validDoc) {
          // console.log('Ok to save Module...')
          const updatedAdFuelModules = adFuelModules.map(afModule => {
            if (afModule.name === selectedModule.name) {
              return updatedModule
            }
            return afModule
          })
          if (updatedModule._id) {
            // console.log('Updating AdFuel Module...')
            dispatch(updateAdFuelModules(updatedAdFuelModules, updatedModule))
          } else {
            updatedAdFuelModules.push(updatedModule)
            updatedAdFuelModules.sort((a, b) => {
              if (a.name > b.name) return 1
              if (b.name > a.name) return -1
              return 0
            })
            // console.log('Creating AdFuel Module...')
            dispatch(createAdFuelModule(updatedAdFuelModules, updatedModule))
          }
          // console.log('module id comparison: ', {
          //   selected: selectedModule._id,
          //   updated: updatedModule._id
          // })
          handleSearchChange()
          setSelectedModule(updatedModule)
          setOpenEdit(!openEdit)
        }
      })
      .catch(err => {
        console.error('Error: ', err)
        console.error('Error Name: ', err.name) // => 'ValidationError'
        console.error('Errors: ', err.errors) // => [{ key: 'field_too_short', values: { min: 18 } }]
      })
  }

  const handleDelete = (afModule, version) => {
    // // console.log('Deleing Version...')
    swal.fire({
      title: 'Are you sure?',
      text:
        'Once deleted you will not be able to recover this database record!',
      icon: 'warning',
      showCancelButton: true
      // buttons: true,
      // dangerMode: true
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        let updatedModule
        console.log('Checking for is in use: ', `/lookups/isInUseAdFuelModuleVersion/${version._id}`)
        request(`/lookups/isInUseAdFuelModuleVersion/${version._id}`, 'GET').then(response => {
          if (response.data && !response.data.isInUse) { 
            const updatedAdFuelModules = adFuelModules.map(mod => {
              if (mod.name === afModule.name) {
                updatedModule = {
                  ...mod,
                  versions: mod.versions
                    .map(ver => {
                      if (ver.name === version.name) {
                        return null
                      }
                      return ver
                    })
                    .filter(item => item !== null)
                }
                return updatedModule
              }
              return mod
            })
            dispatch(updateAdFuelModules(updatedAdFuelModules, updatedModule))
            swal.fire(
              {
                title: 'The selected version has been deleted.',
                text: 'I sure hope no one was using that.',
                icon: 'success'
              }
            ).then(() => {
              forceUpdate()
            })
          } else if ( response.data && response.data.isInUse ) {
            let html = `
            <h3 style="margin: 0; padding: 0; margin-bottom: 5px;">The module version is in use on the following ${response.data.sitesUsing.length} sites:</h3>
            <ul style="list-style-type: none; margin: 0; padding: 0;">
            `
            response.data.sitesUsing.forEach(site => {
              html = html + '<li style="margin: 0; padding: 0;">'+site.name+'</li>'
            })
            html = html + '</ul>'
            swal.fire(
              {
                title: `UNABLE TO DELETE VERSION`,
                icon: 'error',
                html
              }
            )
          } else {
            swal.fire({
              title: 'Ummm... I dunno.',
              icon: 'question',
              text: JSON.stringify(response)
            })
          }
      })
      }
    })
  }

  const handleVersionEdit = (module, version) => {
    // // console.log('Handling Version Edit: ', { module, version })
    setSelectedModule(module)
    setSelectedVersion(version)
    setIsEditingVersion(true)
  }

  const deleteModule = (module, index) => {
    // // console.log('Deleting Module: ', { module, index })
    swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this module!',
      icon: 'warning',
      showCancelButton: true
      // buttons: true,
      // dangerMode: true
    })
      .then(willDelete => {
        if (willDelete.isConfirmed) {
          console.log('Checking for is in use: ', `/lookups/isInUseAdFuelModule/${module._id}`)
          request(`/lookups/isInUseAdFuelModule/${module._id}`, 'GET').then(response => {
            if (response.data && !response.data.isInUse) { 
              const updatedModules = adFuelModules
                .map((mod, i) => {
                  if (i === index) return null
                  return mod
                })
                .filter(item => item !== null)
              // console.log('Removing Module: ', { updated: updatedModules, module })
              dispatch(removeAdFuelModule(updatedModules, module))
              swal.fire(
                {
                  icon: 'success',
                  title: 'The selected module has been deleted.',
                  text: 'I really hope someone wasn\'t using that.'
                }
              ).then(() => {
                forceUpdate()
              })
            } else if ( response.data && response.data.isInUse ) {
              let html = `
                <h3 style="margin: 0; padding: 0; margin-bottom: 5px";>The module is in use on the following ${response.data.sitesUsing.length} sites:</h3>
                <ul style="list-style-type: none; margin: 0; padding: 0;">
              `
              response.data.sitesUsing.forEach(site => {
                html = html + '<li style="margin: 0; padding: 0;">'+site.name+'</li>'
              })
              html = html + '</ul>'
                swal.fire(
                {
                  title: `UNABLE TO DELETE MODULE`,
                  icon: 'error',
                  html
                }
              )
            } else {
              swal.fire({
                title: 'Ummm... I dunno.',
                icon: 'question',
                text: JSON.stringify(response)
              })
            }
          })
        }
      })
  }

  const handleSearchChange = async e => {
    const filteredModules = adFuelModules
      .map(module => {
        const afModule = JSON.parse(JSON.stringify({ ...module }))
        if (
          !e ||
          e.target.value === '' ||
          e.target.value === null ||
          typeof e.target.value === 'undefined'
        ) {
          afModule.versions.sort((v1, v2) => {
            if (semver.gt(v1.name, v2.name)) {
              return -1
            }
            if (semver.lt(v1.name, v2.name)) {
              return 1
            }
            return 0
          })
          return afModule
        } else if (afModule.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 || afModule.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) {
          afModule.versions.sort((v1, v2) => {
            if (semver.gt(v1.name, v2.name)) {
              return -1
            }
            if (semver.lt(v1.name, v2.name)) {
              return 1
            }
            return 0
          })
          return afModule
        }
        return null
      })
      .filter(item => item !== null)
    setOpen(false)
    setFilteredAdFuelModules(filteredModules)
  }

  const handleVersionSave = async (adFuelModule, versionIndex) => {
    let versionData = {}
    if (typeof versionIndex === 'undefined') {
      versionData = {
        name: newVersionName,
        url: newVersionUrl.replace('https://i.cdn.turner.com', ''),
        adFuelVersionId: newVersionSelectedVersion
      }
    } else {
      versionData = {
        name: selectedVersion.name,
        url: selectedVersion.url,
        adFuelVersionId: selectedVersion.adFuelVersionId
      }
    }
    const rawVer = versionData.name
    const coercedVer = semver.coerce(rawVer, { loose: true })
    const validVer = semver.valid(coercedVer)
    versionData.name = validVer
    console.log(`Fetching Version URL: https://i.cdn.turner.com${versionData.url}`)
    const version = await fetch(`https://i.cdn.turner.com${versionData.url}`)
    if (version.status === 404) {
      swal.fire({ text: 'The URL does not exist.  Please try check it and try again.' })
    } else {
      moduleVersionSchema
        .validate(versionData)
        .then(validRecord => {
          if (validRecord) {
            let newAdFuelModules = adFuelModules.map(module => ({ ...module }))
            const newAdFuelModule = JSON.parse(
              JSON.stringify(
                newAdFuelModules.filter(
                  module => module._id === adFuelModule._id
                )[0]
              )
            )
            const newAdFuelModuleVersions = newAdFuelModule.versions
            delete newAdFuelModule.__v
            if (typeof versionIndex !== 'undefined') {
              newAdFuelModuleVersions[versionIndex] = validRecord
            } else {
              newAdFuelModuleVersions.push(validRecord)
            }
            newAdFuelModule.versions = newAdFuelModuleVersions
            moduleSchema
              .validate(newAdFuelModule)
              .then(validDoc => {
                if (validDoc) {
                  newAdFuelModules = newAdFuelModules.map(module =>
                    module._id === newAdFuelModule._id ? newAdFuelModule : module
                  )
                  // setAdFuelModules(newAdFuelModules.map(module => ({ ...module })))
                  dispatch(updateAdFuelModules(newAdFuelModules, newAdFuelModule))
                  setNewVersionName('')
                  setNewVersionUrl('https://i.cdn.turner.com/ads/')
                  forceUpdate()
                }
              })
              .catch(err => {
                console.error('Error: ', err)
                console.error('Error Name: ', err.name) // => 'ValidationError'
                console.error('Errors: ', err.errors) // => [{ key: 'field_too_short', values: { min: 18 } }]
              })
          }
        })
        .catch(err => {
          console.error('Error: ', err)
          console.error('Error Name: ', err.name) // => 'ValidationError'
          console.error('Errors: ', err.errors) // => [{ key: 'field_too_short', values: { min: 18 } }]
        })
    }
  }

  const handleSelectChange = e => {
    if (e.target.name === 'selectVersion') {
      setSelectedVersion({
        ...selectedVersion,
        adFuelVersionId: e.target.value
      })
    }
  }

  const handleVersionTextChange = e => {
    // console.log('Handling Version Text Change: ', e.target)
    if (e.target.name === 'txtVersion') {
      setSelectedVersion({
        ...selectedVersion,
        name: e.target.value
      })
      setValue('txtVersion', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'txtUrl') {
      // console.log('Setting URL for version: ', selectedVersion, e.target.value)
      setSelectedVersion({
        ...selectedVersion,
        url: e.target.value.replace('https://i.cdn.turner.com', '')
      })
      setValue('txtUrl', e.target.value, { shouldValidate: true })
      // console.log('Set txtUrl value for form: ', e.target.value)
    }
  }

  const textField = (
    isEditing,
    adFuelModule,
    version,
    textFieldName,
    textFieldDefaultValue,
    handleTextFieldChange,
    error,
    errorMessage,
    classes
  ) => {
    if (
      isEditing &&
      selectedModule.name === adFuelModule.name &&
      selectedVersion.name === version.name
    ) {
      return (
        <TextField
          {...register(textFieldName)}
          style={{ width: '100%' }}
          margin='dense'
          defaultValue={textFieldDefaultValue}
          variant='outlined'
          onChange={e => handleTextFieldChange(e)}
          error={!!error}
          helperText={error ? errorMessage : ''}
        />
      )
    } else {
      return <div className={classes.plainText}>{textFieldDefaultValue}</div>
    }
  }

  const select = (isEditing, version) => {
    if (isEditing === true && selectedVersion.name === version.name) {
      return (
        <Select
          id='moduleAdFuelVersion'
          value={selectedVersion.adFuelVersionId}
          className={classes.selectEmpty}
          name='selectVersion'
          variant='outlined'
          disabled={!isEditing}
          onChange={e => handleSelectChange(e)}
        >
          {versions.length > 0 &&
            typeof versions.map === 'function' &&
            versions?.map((afVersion, i) => (
              <MenuItem key={afVersion._id} value={afVersion._id}>
                {afVersion.name}
              </MenuItem>
            ))}
        </Select>
      )
    } else {
      return (
        <Select
          id='moduleAdFuelVersion'
          value={version.adFuelVersionId}
          className={classes.selectEmpty}
          name='selectVersion'
          variant='outlined'
          disabled={
            !isEditing || (isEditing && selectedVersion.name !== version.name)
          }
        >
          {versions &&
            versions.length > 0 &&
            versions.map((afVersion, i) => (
              <MenuItem key={afVersion._id} value={afVersion._id}>
                {afVersion.name}
              </MenuItem>
            ))}
        </Select>
      )
    }
  }

  const adFuelModuleVersion = (adFuelModule, version, versionIndex, i) => {
    return (
      <TableRow
        className={classes.tableRow}
        hover
        key={version.name}
        style={{
          verticalAlign: 'top'
        }}
      >
        <TableCell>
          {textField(
            isEditingVersion,
            adFuelModule,
            version,
            'txtVersion',
            version.name,
            handleVersionTextChange,
            errors.name,
            errors.name ? errors.name.message : '',
            classes
          )}
        </TableCell>
        <TableCell
          style={{
            textAlign: 'center'
          }}
        >
          {select(isEditingVersion, version)}
        </TableCell>
        <TableCell>
          {textField(
            isEditingVersion,
            adFuelModule,
            version,
            'txtUrl',
            `https://i.cdn.turner.com${version.url}`,
            handleVersionTextChange,
            errors.txtUrl,
            errors.txtUrl ? errors.txtUrl.message : '',
            classes
          )}
        </TableCell>
        <TableCell
          style={{
            textAlign: 'center'
          }}
        >
          <ButtonGroup
            elevation={0}
            className={isEditingVersion ? classes.buttonGroup : ''}
            variant='text'
            aria-label='outlined primary button group'
          >
            <IconButton
              aria-label='Save'
              variant='contained'
              className={
                isEditingVersion &&
                selectedModule.name === adFuelModule.name &&
                selectedVersion.name === version.name
                  ? commonClasses.saveIconColor
                  : classes.hidden
              }
              fontSize='small'
              size='small'
              name='Save'
              title='Save'
              onClick={e => {
                e.stopPropagation()
                swal.fire({
                  title: 'Are you sure?',
                  icon: 'warning',
                  showCancelButton: true
                  // buttons: true,
                  // dangerMode: true
                }).then(willDelete => {
                  if (willDelete.isConfirmed) {
                    handleVersionSave(adFuelModule, versionIndex)
                    swal.fire({
                      title: 'Version Saved!',
                      icon: 'success'
                    })
                    setIsEditingVersion(false)
                  }
                })
              }}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              aria-label='Cancel'
              variant='contained'
              className={
                isEditingVersion &&
                selectedModule.name === adFuelModule.name &&
                selectedVersion.name === version.name
                  ? commonClasses.deleteIconColor
                  : classes.hidden
              }
              fontSize='small'
              size='small'
              title='Cancel'
              onClick={e => {
                e.stopPropagation()
                setIsEditingVersion(false)
              }}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              aria-label='Edit'
              variant='contained'
              color='primary'
              fontSize='small'
              className={isEditingVersion ? classes.hidden : ''}
              size='small'
              name='Edit'
              title='Edit'
              onClick={e => {
                e.stopPropagation()
                handleVersionEdit(adFuelModule, version)
              }}
            >
              <EditIcon
                className={commonClasses.editIconColor}
              />
            </IconButton>
            <IconButton
              aria-label='Delete'
              variant='contained'
              className={
                isEditingVersion ? classes.hidden : commonClasses.deleteIconColor
              }
              fontSize='small'
              size='small'
              title='Delete'
              onClick={e => {
                e.stopPropagation()
                handleDelete(adFuelModule, version)
              }}
            >
              <DeleteIcon className={commonClasses.deleteIconColor} />
            </IconButton>
            <IconButton
              aria-label='Release Notes'
              variant='contained'
              color='default'
              className={isEditingVersion ? classes.hidden : ''}
              fontSize='small'
              size='small'
              title='Release Notes'
              onClick={e => {
                e.stopPropagation()
                handleClickReleaseNotesOpen(adFuelModule, version)
              }}
            >
              <InfoIcon />
            </IconButton>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    if (adFuelModules.length > 0) {
      handleSearchChange()
    } else {
      dispatch(fetchAdFuelModules())
    }
    if (adFuelVersions.length > 0) {
      setVersions(adFuelVersions.map(version => ({ ...version })))
    } else {
      dispatch(fetchAdFuelVersions())
    }
  }, [adFuelVersions, adFuelModules])

  return (
    <Container maxWidth={'xl'}>
      <Paper elevation={3} position='static' style={{ marginTop: '10px' }}>
        <MainDialog
          id='adfuel-modules-dialog'
          module={selectedModule}
          open={openEdit}
          handleClose={handleClickOpenEdit}
          handleSave={handleClickOpenSave}
        />
        <ConfigurationDialog
          module={selectedModule}
          version={selectedVersion}
          open={open}
          handleClose={handleClickOpen}
          handleSave={handleArgumentSave}
        />
        <ReleaseNotesDialog
          module={selectedModule}
          version={selectedVersion}
          open={openReleaseNotes}
          handleClose={handleClickReleaseNotesOpen}
          handleSave={handleReleaseNotesSave}
        />
        <form autoComplete='off' noValidate>
          <CardHeader title='AdFuel Modules' />
          <Divider />
          <CardContent className={commonClasses.cardBkClr}>
            <Card>
              <CardContent>
                <Grid container >
                  <Grid item md={6} xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      label='Find'
                      margin='dense'
                      name='itemFilter'
                      defaultValue=''
                      variant='outlined'
                      onChange={handleSearchChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.spacer}
                      startIcon={<AddIcon />}
                      onClick={e => {
                        e.stopPropagation()
                        handleClickOpenEdit(initialState)
                      }}
                    >
                      Add New AdFuel Module
                    </Button>
                  </Grid>
                  <Grid item md={2} />
                </Grid>
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardContent>
                <Grid>
                  <Table size='small'>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredAdFuelModules &&
                        filteredAdFuelModules.length > 0 &&
                        stableSort(
                          filteredAdFuelModules,
                          getComparator(order, orderBy)
                        ).map((adFuelModule, i) => (
                          <React.Fragment key={adFuelModule._id}>
                            <StyledTableRow>
                              <StyledTableCell>
                                <Typography className={classes.heading}>
                                  {adFuelModule.name}
                                </Typography>
                                <Typography className={classes.secondaryHeading}>
                                  {adFuelModule.description}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography className={classes.heading}>
                                  Versions:{' '} {adFuelModule.versions ? adFuelModule.versions.length ? adFuelModule.versions.length : 0 : 0}
                                </Typography>
                                <Typography className={classes.secondaryHeading}>
                                  Latest Version:{' '}
                                  {adFuelModule?.versions?.length > 0
                                    ? adFuelModule.versions[0].name
                                    : 'N/A'}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography className={classes.heading}>
                                  Priority: {adFuelModule.priority}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  textAlign: 'right'
                                }}
                              >
                                <IconButton
                                  aria-label='Edit'
                                  variant='contained'
                                  color='primary'
                                  className={classes.spacer}
                                  fontSize='small'
                                  size='small'
                                  name='Edit'
                                  title='Edit'
                                  onClick={e => {
                                    e.stopPropagation()
                                    handleClickOpenEdit(adFuelModule)
                                  }}
                                  aria-controls='adfuel-modules-dialog'
                                >
                                  <EditIcon className={classes.EditIcon} />
                                </IconButton>
                                <IconButton
                                  aria-label='Delete'
                                  variant='contained'
                                  className={
                                    adFuelModule.versions &&
                                    adFuelModule.versions.length === 0
                                      ? commonClasses.deleteIconColor
                                      : classes.hidden
                                  }
                                  fontSize='small'
                                  size='small'
                                  title='Delete'
                                  onClick={e => {
                                    e.stopPropagation()
                                    deleteModule(adFuelModule, i)
                                  }}
                                >
                                  <DeleteIcon className={commonClasses.deleteIconColor} />
                                </IconButton>
                                <IconButton
                                  aria-label='expand row'
                                  size='small'
                                  onClick={() => handleExpand(i)}
                                >
                                  {expanded[i]
                                    ? (<KeyboardArrowUpIcon />)
                                    : (<KeyboardArrowDownIcon />)}
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                            <TableRow>
                              <TableCell colSpan={4}>
                                <Collapse
                                  in={expanded[i]}
                                  timeout='auto'
                                  unmountOnExit
                                >
                                  <FormGroup row>
                                    <Grid container>
                                      <Grid item sm={12}>
                                        <Table size='small'>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell
                                                style={{
                                                  width: '50px',
                                                  fontWeight: 'bold'
                                                }}
                                              >
                                                Version
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: '50px',
                                                  fontWeight: 'bold'
                                                }}
                                              >
                                                AdFuel Version
                                              </TableCell>
                                              <TableCell
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                URL
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: '150px',
                                                  textAlign: 'center',
                                                  fontWeight: 'bold'
                                                }}
                                              >
                                                Actions
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {adFuelModule.versions
                                              ? adFuelModule.versions.map((version, versionIndex) => adFuelModuleVersion(adFuelModule, version, versionIndex, i))
                                              : ''}
                                            <TableRow
                                              className={
                                                !addingNewVersion
                                                  ? classes.hidden
                                                  : ''
                                              }
                                            >
                                              <TableCell>
                                                <TextField
                                                  label=''
                                                  name='txtVersion'
                                                  margin='dense'
                                                  value={newVersionName}
                                                  style={{ width: '100%' }}
                                                  variant='outlined'
                                                  onChange={e => {
                                                    setNewVersionName(
                                                      e.target.value
                                                    )
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell
                                                style={{ textAlign: 'center' }}
                                              >
                                                <Select
                                                  id='moduleAdFuelVersion'
                                                  value={newVersionSelectedVersion}
                                                  onChange={e => {
                                                    setNewVersionSelectedVersion(
                                                      e.target.value
                                                    )
                                                  }}
                                                >
                                                  {versions &&
                                                    versions.length > 0 &&
                                                    versions.map(afVersion => {
                                                      return (
                                                        <MenuItem
                                                          key={afVersion._id}
                                                          value={afVersion._id}
                                                        >
                                                          {afVersion.name}
                                                        </MenuItem>
                                                      )
                                                    })}
                                                </Select>
                                              </TableCell>
                                              <TableCell>
                                                <TextField
                                                  label=''
                                                  name='txtUrl'
                                                  margin='dense'
                                                  value={newVersionUrl}
                                                  variant='outlined'
                                                  style={{ width: '100%' }}
                                                  target='_blank'
                                                  onChange={e => {
                                                    setNewVersionUrl(e.target.value)
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell
                                                style={{ textAlign: 'center' }}
                                              >
                                                <ButtonGroup
                                                  elevation={0}
                                                  variant='text'
                                                  aria-label='outlined primary button group'
                                                >
                                                  <IconButton
                                                    aria-label='Save'
                                                    variant='contained'
                                                    className={commonClasses.saveIconColor}
                                                    fontSize='small'
                                                    size='small'
                                                    name='Save'
                                                    title='Save'
                                                    onClick={e => {
                                                      e.stopPropagation()
                                                      swal.fire({
                                                        title: 'Are you sure?',
                                                        icon: 'warning',
                                                        showCancelButton: true
                                                        // buttons: true,
                                                        // dangerMode: true
                                                      }).then(willSave => {
                                                        if (willSave.isConfirmed) {
                                                          handleVersionSave(
                                                            adFuelModule
                                                          )
                                                          // swal.fire({
                                                          //   title:
                                                          //     'New Version Saved!',
                                                          //   icon: 'success'
                                                          // })

                                                          setAddingNewVersion(false)
                                                        }
                                                      })
                                                    }}
                                                  >
                                                    <SaveIcon />
                                                  </IconButton>
                                                  <IconButton
                                                    aria-label='Cancel'
                                                    variant='contained'
                                                    className={commonClasses.deleteIconColor}
                                                    fontSize='small'
                                                    size='small'
                                                    title='Cancel'
                                                    onClick={e => {
                                                      e.stopPropagation()
                                                      setAddingNewVersion(false)
                                                    }}
                                                  >
                                                    <CancelIcon />
                                                  </IconButton>
                                                </ButtonGroup>
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Grid>
                                      <Grid item md={10} />
                                      <Grid item md={2}>
                                        <Button
                                          variant='contained'
                                          color='primary'
                                          className={
                                            addingNewVersion
                                              ? classes.hidden
                                              : classes.spacer
                                          }
                                          startIcon={<AddIcon />}
                                          onClick={() => {
                                            setAddingNewVersion(true)
                                          }}
                                        >
                                          Add New Version
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </FormGroup>
                                </Collapse>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </React.Fragment>
                        ))}
                    </TableBody>
                  </Table>
                </Grid>
              </CardContent>
            </Card>
          </CardContent>
        </form>
      </Paper>
    </Container>
  )
}

export default AdFuelModules

// AdFuelModules.propTypes = {
//   className: PropTypes.string,
//   modules: PropTypes.arrayOf(PropTypes.object),
//   versions: PropTypes.arrayOf(PropTypes.object)
// }

// const mapStateToProps = function mapStateToProps (state) {
//   return {
//     currentUser: state.auth.username,
//     loggedIn: state.auth.isLoginSuccess,
//     path: state.router.location.pathname,
//     modules: state.lookups.modules,
//     versions: state.lookups.versions
//   }
// }

// const mapDispatchToProps = dispatch => bindActionCreators({
//   loginRedirect: () => push('/'),
//   fetchAdFuelModules,
//   fetchAdFuelVersions
// }, dispatch)

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(AdFuelModules)
