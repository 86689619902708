import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'

const swal = withReactContent(Swal)

const initialState = {
  loading: false,
  roles: [],
  error: null,
  openDialog: false
}

const roleSlice = createSlice({
  name: 'roleSlice',
  initialState,
  reducers: {
    getRoles: (state, action) => {
      state.loading = false
      state.roles = action.payload
    },
    createRole: (state, action) => {
      const orgRoles = state.roles.slice()
      orgRoles.unshift(action.payload)
      state.roles = orgRoles
      state.loading = false
      swal.fire({
        title: `The role "${action.payload.name}" has been created!`,
        icon: 'success'
      })
    },
    updateRole: (state, action) => {
      // console.log(action.payload)
      const roles = state.roles.slice()
      const updatedItemIndex = roles.findIndex(
        permission => permission._id === action.payload._id
      )
      roles[updatedItemIndex] = action.payload
      state.roles = roles
      state.openDialog = false
      state.loading = false
      swal.fire({
        title: `The role "${action.payload.name}" has been updated!`,
        icon: 'success'
      })
    },
    deleteRole: (state, action) => {
      // console.log(action.payload)
      const orgRoles = state.roles
        .slice()
        .filter(role => role._id !== action.payload._id)
      state.roles = orgRoles
      state.loading = false
      swal.fire({
        title: `The role "${action.payload.name}" has been deleted!`,
        icon: 'success'
      })
    },
    loadingStatus: state => {
      state.loading = true
    },
    toggleDialogStatus: (state, action) => {
      if (action.payload === undefined) {
        state.openDialog = !state.openDialog
      } else {
        state.openDialog = action.payload
      }
    },
    errorStatus: (state, action) => {
      state.loading = false
      state.error = action.payload
      handleError(action.payload, showError)
    }
  }
})

export default roleSlice.reducer
export const {
  errorStatus,
  loadingStatus,
  getRoles,
  updateRole,
  createRole,
  deleteRole,
  toggleDialogStatus,
  toggleIsChange
} = roleSlice.actions

export const fetchUserRoles = () => {
  return dispatch => {
    dispatch(loadingStatus())
    return request('/lookups/roles')
      .then(res => dispatch(getRoles(res.data)))
      .catch(err => dispatch(errorStatus(err.response.data)))
  }
}

export const updateUserRole = role => {
  return dispatch => {
    return request(`/lookups/roles/${role._id}`, 'POST', role)
      .then(res => dispatch(updateRole(res.data)))
      .catch(err => dispatch(errorStatus(err.response.data)))
  }
}

export const createUserRole = role => {
  return dispatch => {
    return (
      request('/lookups/roles', 'POST', role)
        .then(res => dispatch(createRole(res.data)))
        // .then(dispatch(fetchUserRoles()))
        .catch(err => dispatch(errorStatus(err.response.data)))
    )
  }
}

export const deleteUserRole = roleId => {
  return dispatch => {
    return (
      request(`/lookups/roles/${roleId}`, 'DELETE')
        .then(res => dispatch(deleteRole(res.data)))
        // .then(dispatch(fetchUserRoles()))
        .catch(err => dispatch(errorStatus(err.response.data)))
    )
  }
}
