import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from '../../modules/userSlice'
import { fetchUserRoles } from '../../modules/roleSlice'
import { setUser } from '../../modules/authSlice'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup'

import { Checkbox, FormControlLabel, List, ListItem, Container, Card, CardHeader, CardContent, CardActions, Divider, Grid, TextField, Typography, Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EditIcon from '@material-ui/icons/Edit'

import { commonStyle } from '../../styles'
import { clone } from '../../utilities/helperFunctions.js'

const swal = withReactContent(Swal)

const canEditUser = false

const userSchema = yup.object().shape({
  firstName: yup.string().required('First Name is Required'),
  lastName: yup.string().required('Last Name is Required'),
  email: yup.string().required('Email is Required').email('Email is Invalid')
})

export const Profile = () => {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.authReducer)
  const { roles } = useSelector(state => state.roles)
  const [userData, setUserData] = useState({})
  const { firstName = '', lastName = '', email = '' } = userData
  const [roleList, setRoleList] = useState([])
  const [isChanged, setIsChanged] = useState(false)
  const [errors, setErrors] = useState({})
  const [enableEdit, setEnableEdit] = useState(false)

  useEffect(() => {
    if (user) {
      setUserData(clone(user))
    }
  }, [user])

  useEffect(() => {
    setRoleList(clone(roles))
  }, [roles])

  useEffect(() => {
    if (roles.length === 0) { dispatch(fetchUserRoles()) }
  }, [])

  useEffect(() => {
    if (userData.username !== '') {
      setIsChanged(JSON.stringify(user) !== JSON.stringify(userData))
    }
  }, [userData])

  return (
    <Container maxWidth='lg'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title='User Profile'
              action={canEditUser && (
                <Button
                  className={commonClasses.btnSuccess}
                  variant='contained'
                  startIcon={<EditIcon />}
                  onClick={() => setEnableEdit(true)}
                >
                  Edit
                </Button>
              )}
            />
            <Divider />
            <CardContent className={commonClasses.cardBkClr}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {enableEdit
                        ? (
                          <TextField
                            fullWidth
                            disabled={!canEditUser}
                            margin='none'
                            label='First Name'
                            value={firstName}
                            error={!!errors.firstName}
                            helperText={errors.firstName && errors.firstName.message}
                            onChange={(event) => {
                              setUserData({ ...userData, firstName: event.target.value })
                              if (errors.firstName) {
                                delete errors.firstName
                                setErrors({ ...errors })
                              }
                            }}
                          />
                          )
                        : (
                          <Typography>First Name: {firstName}</Typography>
                          )}
                    </Grid>
                    <Grid item xs={12}>
                      {enableEdit
                        ? (
                          <TextField
                            fullWidth
                            disabled={!canEditUser}
                            margin='none'
                            label='Last Name'
                            value={lastName}
                            error={!!errors.lastName}
                            helperText={errors.lastName && errors.lastName.message}
                            onChange={(event) => {
                              setUserData({ ...userData, lastName: event.target.value })
                              if (errors.lastName) {
                                delete errors.lastName
                                setErrors({ ...errors })
                              }
                            }}
                          />
                          )
                        : (
                          <Typography>Last Name: {lastName}</Typography>
                          )}
                    </Grid>
                    <Grid item xs={12}>
                      {enableEdit
                        ? (
                          <TextField
                            fullWidth
                            disabled={!canEditUser}
                            margin='none'
                            label='Email'
                            value={email}
                            error={!!errors.email}
                            helperText={errors.email && errors.email.message}
                            onChange={(event) => {
                              setUserData({ ...userData, email: event.target.value })
                              if (errors.email) {
                                delete errors.email
                                setErrors({ ...errors })
                              }
                            }}
                          />
                          )
                        : (
                          <Typography>Email: {email}</Typography>
                          )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title='User Roles'
              action={canEditUser && (
                <Button
                  className={commonClasses.btnSuccess}
                  variant='contained'
                  startIcon={<EditIcon />}
                  onClick={() => setEnableEdit(true)}
                >
                  Edit
                </Button>
              )}
            />
            <Divider />
            <CardContent className={commonClasses.cardBkClr}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {enableEdit
                        ? (
                          <List>
                            {roleList.map((role, index) => {
                              const checked = !!userData.role2.find(item => item === role.name)
                              return (
                                <ListItem key={index} dense>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        disabled={!canEditUser}
                                        color='primary'
                                        checked={checked}
                                        onChange={(event) => {
                                          const checked = event.target.checked
                                          if (checked) {
                                            const newRoles = [...userData.role2, role.name]
                                            newRoles.sort()
                                            setUserData({ ...userData, role2: newRoles })
                                          } else {
                                            setUserData({ ...userData, role2: userData.role2.filter(item => item !== role.name) })
                                          }
                                        }}
                                      />
                                  )}
                                    label={role.name}
                                  />
                                </ListItem>
                              )
                            })}
                          </List>
                          )
                        : (
                          <List>
                            {!userData.role2 || userData.role2.length === 0
                              ? (
                                <Typography>No Roles found for {userData.username}</Typography>
                                )
                              : userData.role2.map((role, index) => {
                                return (
                                  <ListItem key={index}><Typography>{role}</Typography></ListItem>
                                )
                              })}
                          </List>
                          )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </CardContent>
            {enableEdit && (
              <>
                <Divider />
                <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant='text'
                    onClick={() => setEnableEdit(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={isChanged ? commonClasses.btnSuccess : ''}
                    disabled={!isChanged}
                    variant='contained'
                    startIcon={<CheckCircleIcon />}
                    onClick={() => {
                      userSchema.validate(userData).then(async user => {
                        try {
                          const result = await updateUser(user)
                          if (result.reason) {
                            swal.fire({ title: result.reason, icon: 'error' })
                          } else {
                            swal.fire({ title: `User Roles for ${user.firstName} was updated successfully`, icon: 'success' })
                            dispatch(setUser())
                          }
                        } catch (err) {
                          swal.fire({ title: err, icon: 'error' })
                        }
                      }).catch(err => {
                        errors[err.path] = err
                        setErrors({ ...errors })
                      })
                      setEnableEdit(false)
                    }}
                  >
                    Save
                  </Button>
                </CardActions>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
