import { createSlice } from '@reduxjs/toolkit'
import { request, handleError, showError } from '../utilities/request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Moment from 'moment'

const swal = withReactContent(Swal)

const adServer =
  process.env.REACT_APP_AD_SERVER || process.env.AD_SERVER || 'dfp'

// console.log('Ad Server: ', adServer)

const initialState = {
  errorMessage: null,
  loading: false,
  adRoots: [],
  adUnits: [],
  adUnitsByLabel: [],
  customTargets: [],
  lineItems: [],
  orders: [],
  advertisers: [],
  creatives: [],
  placements: [],
  geoTargets: [],
  timeStamps: [],
  capTopics: [],
  deviceCapabilities: [],
  deviceCategories: [],
  operatingSystems: [],
  dfpDataTypes: [
    {
      name: 'Ad Units',
      id: 'adUnit',
      timestamp: null,
      loadingFlag: 'refreshingDfpAdUnits'
    }
  ],
  cacheTimestampResource: [],
  refreshingDfpAdUnits: false,
  currentVersion: '',
  schedule: []
}

const DFPAdUnitSlice = createSlice({
  name: 'DFPAdUnitSlice',
  initialState,
  reducers: {
    loadingStatus: state => {
      state.loading = true
    },
    setErrorMessage: (state, action) => {
      state.loading = false
      state.errorMessage = action.payload
      handleError(action.payload, showError)
    },
    refreshingDfpAdUnits: state => {
      state.refreshingDfpAdUnits = true
    },
    setCurrentVersion: (state, action) => {
      console.log('Setting Current Version: ', action)
      state.loading = false
      state.currentVersion = action.payload
    },
    setSchedule: (state, action) => {
      console.log('Setting Schedule: ', action)
      state.loading = false
      state.schedule = action.payload
    },
    setAdUnits: (state, action) => {
      state.loading = false
      state.adUnits = action.payload
      state.refreshingDfpAdUnits = false
    },
    setAdRoots: (state, action) => {
      // console.log('Got Ad Roots: ', { state, action })

      state.loading = false
      state.adRoots = action.payload
    },
    setCustomTargets: (state, action) => {
      state.loading = false
      state.customTargets = action.payload
    },
    setLineItems: (state, action) => {
      state.loading = false
      state.lineItems = action.payload
    },
    setAdvertisers: (state, action) => {
      state.loading = false
      state.advertisers = action.payload.results
    },
    setGeoTargets: (state, action) => {
      state.loading = false
      state.geoTargets = action.payload
    },
    setPlacements: (state, action) => {
      state.loading = false
      state.placements = action.payload.results
    },
    setCreatives: (state, action) => {
      state.loading = false
      state.creatives = action.payload
    },
    setOrders: (state, action) => {
      state.loading = false
      state.orders = action.payload
    },
    setTimeStamps: (state, action) => {
      state.timeStamps = action.payload
      state.cacheTimestampResource = action.payload.data
      action.payload.data.sort(
        (a, b) =>
          new Moment(a.lastUpdate).format('YYYYMMDD') -
          new Moment(b.lastUpdate).format('YYYYMMDD')
      )
      action.payload.data.forEach(TimestampResource => {
        const index = state.dfpDataTypes.findIndex(
          dfpDataType => dfpDataType.id === TimestampResource.name
        )
        state.dfpDataTypes[index].timestamp = TimestampResource.lastUpdate
      })
      state.loading = false
      state.refreshingDfpAdUnits = false
    },
    setCapTopics: (state, action) => {
      state.loading = false
      state.capTopics = action.payload
    },
    setDeviceCapabilities: (state, action) => {
      state.loading = false
      state.deviceCapabilities = action.payload.result
    },
    setDeviceCategories: (state, action) => {
      state.loading = false
      state.deviceCategories = action.payload.result
    },
    setOperatingSystems: (state, action) => {
      state.loading = false
      state.operatingSystems = action.payload.result
    },
    updateDfpAdUnits: (state, action) => {
      const adUnits = action.payload
      // console.log('adUnits::==>', adUnits)
      if (adUnits !== '' && state.adUnitsCachedOn !== adUnits.cachedOn) {
        state.adUnitsCachedOn = adUnits.cachedOn
        state.adUnits = adUnits.topLevelAdUnits
        // build dictionary by Id
        const adUnitDictionary = {}
        const processLevel = (adUnits, adUnitDictionary) => {
          adUnits.forEach(adUnit => {
            adUnitDictionary[adUnit._id] = adUnit
            if (adUnit.children) {
              processLevel(adUnit.children, adUnitDictionary)
            }
          })
        }
        processLevel(state.adUnits, adUnitDictionary)
        state.adUnitDictionary = adUnitDictionary
      }
      state.refreshingDfpAdUnits = false
      swal.fire({
        text: 'GAM Ad Units reloaded',
        icon: 'success'
      })
    }
  }
})

export default DFPAdUnitSlice.reducer

const {
  setTimeStamps,
  setOrders,
  loadingStatus,
  setErrorMessage,
  setCreatives,
  setAdUnits,
  setAdRoots,
  setAdvertisers,
  setGeoTargets,
  setPlacements,
  setCustomTargets,
  setLineItems,
  setCapTopics,
  setDeviceCapabilities,
  setDeviceCategories,
  setOperatingSystems,
  setCurrentVersion,
  setSchedule,
  updateDfpAdUnits,
  refreshingDfpAdUnits
} = DFPAdUnitSlice.actions

export const fetchAPIVersion = () => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/version`)
      .then(res => {
        console.log('Version Response: ', res)
        dispatch(setCurrentVersion(res.data.version))
      })
      .catch(err => dispatch(setErrorMessage(err.message)))
    }
}

export const fetchAPISchedule = () => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/schedule`)
      .then(res => {
        console.log('Schedule Response: ', res)
        dispatch(setSchedule(res.data.schedule))
      })    
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}

export const fetchAllAdUnits = () => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/adUnits`)
      .then(res => {
        dispatch(setAdUnits(res.data))
        dispatch(setAdRoots(res.data.topLevelAdUnits))
      })
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}

export const getAdUnitsByLabel = labelId => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/adUnitsByLabel/${labelId}`)
      .then(res => dispatch(setAdUnits(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getAdUnitById = adUnitId => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/adUnit/${adUnitId}`)
      .then(res => dispatch(setAdUnits(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getAllCustomTargets = completeList => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(
      `/${adServer}/customTargets${completeList ? '?completeList=true' : ''}`
    )
      .then(res => dispatch(setCustomTargets(res.data.result)))
      .catch(err => dispatch(err.message))
  }
}
export const fetchLineItem = lineItemId => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/lineItem/${lineItemId}`)
      .then(res => dispatch(setLineItems(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const fetchOrder = orderId => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/order/${orderId}`)
      .then(res => dispatch(setOrders(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getCustomTargetByKey = customTargetKeyId => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/customTarget/${customTargetKeyId}`)
      .then(res => dispatch(setCustomTargets(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getCustomTargetByName = customTargetKeyName => {
  return dispatch => {
    dispatch(loadingStatus())
    return request(`/${adServer}/customTargetByName/${customTargetKeyName}`)
      .then(res => dispatch(setCustomTargets(res.data.values)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getAdvertisersByName = name => {
  return dispatch => {
    return request(`/${adServer}/advertisersByName/${name}`)
      .then(res => dispatch(setAdvertisers(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getNonDeliveringLineItems = createdWithinDays => {
  return dispatch => {
    return request(`/${adServer}/deliveringTestLineItems/${createdWithinDays}`)
      .then(res => dispatch(setLineItems(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getDeliveringTestLineItems = createdWithinDays => {
  return dispatch => {
    return request(`/${adServer}/deliveringTestLineItems/${createdWithinDays}`)
      .then(res => dispatch(setLineItems(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getCreativesContainingText = (createdWithinDays, searchText) => {
  return dispatch => {
    return request(
      `/${adServer}/creativesContainingText/${createdWithinDays}/${searchText}`
    )
      .then(res => dispatch(setCreatives(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getCreativesContainingTrackImpjPixel = createdWithinDays => {
  return dispatch => {
    return request(
      `/${adServer}/creativesContainingTrackImpjPixel/${createdWithinDays}`
    )
      .then(res => dispatch(setCreatives(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getCreativesContainingProofPointClickThru = createdWithinDays => {
  return dispatch => {
    return request(
      `/${adServer}/creativesContainingProofPointClickThru/${createdWithinDays}`
    )
      .then(res => dispatch(setCreatives(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const fetchCreatives = creativeId => {
  return dispatch => {
    return request(`/${adServer}/creative/${creativeId}`)
      .then(res => dispatch(setCreatives(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getCreativeById = () => {
  return dispatch => {
    return request(`/${adServer}/creatives`)
      .then(res => dispatch(setCreatives(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getAllGeoTargets = () => {
  return dispatch => {
    return request(`/${adServer}/geoTargets`)
      .then(res => dispatch(setGeoTargets(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
// export const createBulkLineItems = payload => {
//   return request('/dfp/lineItems/bulkCreate', 'POST', { data: payload }, true)
// }
export const setKeyValue = () => {
  return dispatch => {
    return request(`/${adServer}/keyValues/set`)
      .then(res => console.log(res.data))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getAllPlacements = () => {
  return dispatch => {
    return request(`/${adServer}/placements`)
      .then(res => dispatch(setPlacements(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getAllAdvertisers = () => {
  // console.log('getting advertisers...')
  return dispatch => {
    return request(`/${adServer}/advertisers`)
      .then(res => dispatch(setAdvertisers(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getCacheTimeStamps = () => {
  return dispatch => {
    return request(`/${adServer}/cacheTimestamp`)
      .then(res => {
        dispatch(setTimeStamps(res.data))
        swal.fire({
          text: 'Workspace loaded',
          icon: 'success'
        })
      })
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getOrdersByTrafficker = () => {
  return dispatch => {
    return request(`/${adServer}/orders/getByTrafficker`)
      .then(res => dispatch(setOrders(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
// export const sendPQLQuery = (pqlQuery) => {
//   return dispatch => {
//     return request(`/dfp/pql?query=${pqlQuery}`)
//   }
// }
export const getDeviceCapabilities = () => {
  return dispatch => {
    return request(
      `/${adServer}/pql?query=select Id,DeviceCapabilityName from Device_Capability`
    )
      .then(res => dispatch(setDeviceCapabilities(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getDeviceCategories = () => {
  return dispatch => {
    return request(
      `/${adServer}/pql?query=select Id,DeviceCategoryName from Device_Category`
    )
      .then(res => dispatch(setDeviceCategories(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getOperatingSystems = () => {
  return dispatch => {
    return request(
      `/${adServer}/pql?query=select Id,OperatingSystemName from Operating_System`
    )
      .then(res => dispatch(setOperatingSystems(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const exportDFPUsersForAAA = () => {
  return dispatch => {
    return request(`/${adServer}/aaa/export/users`)
      .then(res => console.log(res.data))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const exportDFPRolesForAAA = () => {
  return dispatch => {
    return request(`/${adServer}/aaa/export/roles`)
      .then(res => console.log(res.data))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const getHouseLineItemsLackingNgInventoryTarget = () => {
  return dispatch => {
    return request(`/${adServer}/houseLineItemsLackingNgInventoryTarget`)
      .then(res => dispatch(setLineItems(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const loadCAPTopics = () => {
  return dispatch => {
    return request(`/${adServer}/loadCAPTopics`)
      .then(res => dispatch(setCapTopics(res.data)))
      .catch(err => dispatch(setErrorMessage(err.message)))
  }
}
export const refreshReloadDfpAdUnits = (updateCache, clearCache) => {
  return async dispatch => {
    dispatch(refreshingDfpAdUnits())
    return request(`/${adServer}/adUnits`, 'GET', null, {
      updateCache,
      clearCache
    })
      .then(res => {
        if (!updateCache) {
          dispatch(updateDfpAdUnits(res.data))
        } else {
          return request(`/${adServer}/adUnits`)
            .then(res => {
              dispatch(setAdUnits(res.data))
              dispatch(refreshingDfpAdUnits())
              return request(`/${adServer}/cacheTimestamp`)
                .then(res => {
                  dispatch(setTimeStamps(res.data))
                  swal.fire({
                    text: 'GAM Ad Units refreshed',
                    icon: 'success'
                  })
                })
                .catch(err => dispatch(setErrorMessage(err.message)))
            })
            .catch(err => dispatch(setErrorMessage(err)))
        }
      })
      .catch(err => {
        if (err.data && err.data.reason) {
          err = err.data.reason
        } else if (err.status && err.statusText) {
          err = err.status + ': ' + err.statusText
        }
        if (updateCache) {
          dispatch(
            setErrorMessage(`${adServer} Ad Units failed to refresh: ` + err)
          )
        } else {
          dispatch(setErrorMessage('GAM Ad Units failed to reload: ' + err))
        }
      })
  }
}
export const getAllLineItems = () => {
  return request(`/${adServer}/lineItems/getAllLineItems`)
    .then(res => res)
    .catch(err => err)
}
export const getByCustomTarget = customTargetKey => {
  return request(
    `/${adServer}/lineItems/getByCustomTarget?customTargetKey=${customTargetKey}`
  )
    .then(res => res)
    .catch(err => err)
}
export const getAllAudienceSegments = () => {
  return request(`/${adServer}/audienceSegments`)
    .then(res => res)
    .catch(err => err)
}

export const getEnhancedLineItems = () => {
  return request(`/${adServer}/enhancedLineItems`)
    .then(res => res)
    .catch(err => err)
}
export const getAudienceSegmentsForCsv = filter => {
  return request(`/${adServer}/audienceSegmentsCsv?filter=${filter}`)
    .then(res => res)
    .catch(err => err)
}

export const deactivateSegments = ids => {
  console.log('Deactivating Segments...', ids)
  return request(`/${adServer}/segments/bulkDeactivate`, 'POST', { data: ids }, true)
    .then(res => res)
    .catch(err => err)
}
