import React, { useState, useEffect } from 'react'

import { TextField, Table, TableHead, TableBody, TableRow, TableCell, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { SlotList } from '../index'

const template = {
  PUBLISHER_ID: {
    DOM: '',
    INTL: ''
  },
  MAPPINGS: {
    DOM: {
      DESKTOP: {},
      MOBILE: {}
    },
    INTL: {
      DESKTOP: {},
      MOBILE: {}
    }
  }
}

export const Pubmatic = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const PUBMATIC = Object.assign(template, VENDOR.PUBMATIC)
  VENDOR.PUBMATIC = PUBMATIC
  const { STRING } = validation
  const { LENGTH } = STRING

  let { MAPPINGS } = PUBMATIC
  MAPPINGS = { ...MAPPINGS, ...template.MAPPINGS }

  let { PUBLISHER_ID } = PUBMATIC
  PUBLISHER_ID = { ...PUBLISHER_ID, ...template.PUBLISHER_ID }

  const { DOM, INTL } = PUBLISHER_ID

  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displayPublisherIdTable = () => {
    return (
      <Table size='small' aria-label='pubmatic-publisher-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Publisher IDs</Typography></TableCell>
            <TableCell>Domestic</TableCell>
            <TableCell>International</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell>
              <TextField
                margin='none'
                value={DOM}
                error={errors.PREBID_PUBMATIC_PIDD}
                helperText={errors.PREBID_PUBMATIC_PIDD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.PUBMATIC.PUBLISHER_ID.DOM = value
                  setErrors({ ...errors, PREBID_PUBMATIC_PIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                margin='none'
                value={INTL}
                error={errors.PREBID_PUBMATIC_PIDI}
                helperText={errors.PREBID_PUBMATIC_PIDI && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.PUBMATIC.PUBLISHER_ID.INTL = value
                  setErrors({ ...errors, PREBID_PUBMATIC_PIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayMappingsTable = () => {
    return (
      <Table size='small' aria-label='kargo-unit-mapping-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Mappings</Typography></TableCell>
            <TableCell>Domestic</TableCell>
            <TableCell>International</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Desktop</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='PUBMATIC.MAPPINGS.DOM.DESKTOP'
                object={MAPPINGS.DOM.DESKTOP}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                keyName='Slot Name'
                valueName='Ad Tag Id'
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='PUBMATIC.MAPPINGS.INTL.DESKTOP'
                object={MAPPINGS.INTL.DESKTOP}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                keyName='Slot Name'
                valueName='Ad Tag Id'
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mobile</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='PUBMATIC.MAPPINGS.DOM.MOBILE'
                object={MAPPINGS.DOM.MOBILE}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                keyName='Slot Name'
                valueName='Ad Tag Id'
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='PUBMATIC.MAPPINGS.INTL.MOBILE'
                object={MAPPINGS.INTL.MOBILE}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                keyName='Slot Name'
                valueName='Ad Tag Id'
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='pubmatic-panel-content'
        id='pubmatic-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Pubmatic</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displayPublisherIdTable()}</Grid>
          <Grid item xs={12}>{displayMappingsTable()}</Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
