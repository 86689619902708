import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  adFuelModules: [],
  orgAdFuelModules: [],
  adFuelVersions: [],
  orgAdFuelVersions: [],
  loading: false
}

const adFuelSlice = createSlice({
  name: 'adFuel',
  initialState,
  reducers: {
    AdFuelLoadingStatus: state => {
      state.loading = true
    },
    FetchAdFuelVersion: (state, action) => {
      state.loading = false
      state.adFuelModules = action.payload
      state.orgAdFuelModules = action.payload
    },
    FetchAdFuelVersions: (state, action) => {
      state.loading = false
      state.adFuelVersions = action.payload
      state.orgAdFuelVersions = action.payload
    },
    UpdateAdFuelVersion: (state, action) => {
      const adFuelVersions = state.adFuelVersions.slice()
      const index = adFuelVersions.findIndex(
        adFuelVersion => adFuelVersion._id === action.payload._id
      )
      adFuelVersions[index] = action.payload
      state.loading = false
      state.adFuelVersions = adFuelVersions
      state.orgAdFuelVersions = adFuelVersions
      swal.fire({
        title: 'adFuelVersions Updated!',
        text: 'adFuelVersions Updated.',
        icon: 'success'
      })
    },
    CreateAdFuelVersion: (state, action) => {
      const adFuelVersions = state.adFuelVersions.slice()
      adFuelVersions.unshift(action.payload)
      state.loading = false
      state.adFuelVersions = adFuelVersions
      state.orgAdFuelVersions = adFuelVersions
      swal.fire({
        title: 'AdFuelVersions Saved!',
        text: 'AdFuelVersionssaved successfully.',
        icon: 'success'
      })
    },
    DeleteAdFuelVersion: (state, action) => {
      const adFuelVersions = state.adFuelVersions
        .slice()
        .filter(adFuelVersion => adFuelVersion._id !== action.payload._id)
      state.loading = false
      state.adFuelVersions = adFuelVersions
      state.orgAdFuelVersions = adFuelVersions
      swal.fire({
        title: `The adFuelVersion "${action.payload.name}" has been deleted!`,
        icon: 'success'
      })
    },
    FilterAdFuelVersions: (state, action) => {
      const results = filterByValue(state.orgAdFuelVersions, action.payload)
      state.loading = false
      state.adFuelVersions = results
    },
    AdFuelError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    }
  }
})

export default adFuelSlice.reducer
export const {
  AdFuelLoadingStatus,
  FetchAdFuelVersion,
  FetchAdFuelVersions,
  UpdateAdFuelVersion,
  CreateAdFuelVersion,
  DeleteAdFuelVersion,
  FilterAdFuelVersions,
  AdFuelError
} = adFuelSlice.actions

const filterByValue = (array, string) => {
  return array.filter(arrObject =>
    Object.keys(arrObject).some(element =>
      // Some() allows you to test if an array has at least one element that meets a condition.
      arrObject[element]
        .toString()
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  )
}

export const fetchAdFuelVersions = () => {
  return async dispatch => {
    dispatch(AdFuelLoadingStatus())
    return request('/lookups/adFuelVersions')
      .then(res => dispatch(FetchAdFuelVersions(res.data)))
      .catch(err => dispatch(AdFuelError(err)))
  }
}

export const updateAdFuelVersion = slotLocation => {
  return async dispatch => {
    dispatch(AdFuelLoadingStatus())
    return request(
      `/lookups/adFuelVersions/${slotLocation._id}`,
      'POST',
      slotLocation
    )
      .then(res => {
        dispatch(UpdateAdFuelVersion(res.data))
      })
      .catch(err => dispatch(AdFuelError(err)))
  }
}

export const createAdFuelVersion = slotLocation => {
  return async dispatch => {
    dispatch(AdFuelLoadingStatus())
    return request('/lookups/adFuelVersions', 'POST', slotLocation)
      .then(res => dispatch(CreateAdFuelVersion(res.data)))
      .catch(err => dispatch(AdFuelError(err)))
  }
}

export const deleteAdFuelVersion = adFuelVersion => {
  return async dispatch => {
    dispatch(AdFuelLoadingStatus())
    return request(
      `/lookups/adFuelVersions/${adFuelVersion._id}`,
      'DELETE',
      adFuelVersion
    )
      .then(res => dispatch(DeleteAdFuelVersion(res.data)))
      .catch(err => dispatch(AdFuelError(err)))
  }
}

export const filterAdFuelVersions = searchTerm => {
  return async dispatch => {
    dispatch(AdFuelLoadingStatus())
    dispatch(FilterAdFuelVersions(searchTerm))
  }
}
