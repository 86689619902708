import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, TextField, Paper, Button, makeStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { addClasses, commonStyle } from '../../styles'
import { ToggleSwitch } from '../ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  string as yupstring,
  number as yupNumber,
  object as yupobject
} from 'yup'
import {
  updateSiteRegistry,
  ToggleInViewRefresh,
  ToggleRealTimeAdInsertion,
  ToggleSafeframeConfig,
} from '../../modules/registrySlice'

const useStyle = makeStyles(theme => ({
  ConfigurationItemContainer: {
    paddingTop: '4px'
  },
  ConfigurationItems: {
    margin: '2px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '95%',
    border: 'none',
    boxShadow: 'none'
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
  links: {
    color: "darkorange",
    "&:visited": {
      color: "green"
    }
  }
}))

const RegistryProperties = ({ canEditRegistry, registry, site }) => {
  const commonClasses = commonStyle()
  const classes = useStyle()
  const dispatch = useDispatch()
  const [ isChanged, setIsChanged ] = useState(false)
  const [ publishers, setPublishers ] = useState({
    rootAdFuelDirectory: '/www/ads/dev/adfuel',
    rootAdFuelAisDirectory: '/www/ads/dev/adfuel/ais',
    rootSiteDirectory: '/www/ads/dev/{siteName}',
    rootAdSpacesDirectory: '/www/ads/dev/adspaces',
    rootAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
    rootAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
    rootSSLAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
    rootSSLAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
    rootSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
    rootSSLSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
    rootAdSpacesUrl: 'https://i.cdn.turner.com/ads/dev/adspaces',
    rootAnalyticsSiteUrl: 'https://i.cdn.turner.com/analytics/{siteName}'
  })
  const { pathConfig } = useSelector(state => state.configSlice)
  const {
    inViewRefresh,
    realTimeAdInsertion,
    hasSafeFrameConfig
  } = useSelector(state => state.registrySlice)
  const { siteIVR } = useSelector(state => state.sitesSlice)
  console.log('[RegistryProperties] ========== render::initial state: ', registry)
  const [ currentRegistry, setCurrentRegistry ] = useState(registry)

  const validationSchema = yupobject().shape({
    registryDescription: yupstring().required('Required'),
    registryInViewRefreshCount: yupNumber()
      .typeError('Count must be a number')
      .required('Required')
      .min(5, 'Min value 5'),
    registryInViewRefreshInterval: yupNumber()
      .typeError('Interval must be a number')
      .required('Required')
      .min(35, 'Min value 35'),
    registryInViewDisplayPercentage: yupNumber()
      .typeError('Percentage must be a number')
      .required('Required')
      .min(25, 'Min value 25')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  let updatingRegistryDetails = false
  console.log('[RegistryProperties] ========== render::Can Edit Registry: ', canEditRegistry)
  console.log('[RegistryProperties] ========== render::Updating Registry Details: ', updatingRegistryDetails)

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      setPublishers(pathConfig.publisher)
    } else {
      dispatch(getPath())
    }
  }, [pathConfig])  

  const siteHasModule = moduleName => {
    let hasModule = false
    if (site && site.adFuelModules) {
      site.adFuelModules.forEach(afModule => {
        if (afModule.name.indexOf(moduleName) >= 0) hasModule = true
      })
    }
    return hasModule
  }

  const handleRegistryChange = e => {
    console.log('[RegistryProperties] ========== handleRegistryChange::data ', { name: e.target.name, value: e.target.value, checked: e.target.checked })
    switch (e.target.name) {
      case 'registrySingleton':
        setCurrentRegistry({
          ...currentRegistry,
          isSingleton: e.target.checked
        })
        break
      case 'registryTagChildDirectedTreatment':
        setCurrentRegistry({
          ...currentRegistry,
          tagForChildDirectedTreatment: e.target.checked
        })
        break
      case 'registryInViewRefresh':
        setCurrentRegistry({
          ...currentRegistry,
          hasInViewRefresh: e.target.checked
        })
        toggleInViewRefresh(e.target.checked)
        break
      case 'registryInViewRefreshCount':
        setCurrentRegistry({
          ...currentRegistry,
          inViewRefreshCount: e.target.value
        })
        setValue('registryInViewRefreshCount', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'registryInViewRefreshInterval':
        setCurrentRegistry({
          ...currentRegistry,
          inViewRefreshInterval: e.target.value
        })
        setValue('registryInViewRefreshInterval', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'registryRealTimeAdInsertion':
      setCurrentRegistry({
        ...currentRegistry,
        hasRealTimeAdInsertion: e.target.checked
      })
      toggleRealTimeAdInsertion(e.target.checked)
      break
      case 'registryRTAIMaxNoOfAds':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiMaxNoOfAds: e.target.value
        })
        break
      case 'registryRTAIBuffer':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiBuffer: e.target.value
        })
        break
      case 'registryRTAIOffset':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiOffset: e.target.value
        })
        break  
      case 'registryRTAIInitialPlacement':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiInitialPlacement: e.target.value
        })
        break
      case 'registryRTAIParentSelector':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiParentSelector: e.target.value
        })
        break  
      case 'registrySafeframeConfig':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameConfig: e.target.checked
        })
        toggleSafeframeConfig(e.target.checked)
        break
      case 'registrySandbox':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameSandboxMode: e.target.checked
        })
        break
      case 'registryAllowOverlayExpansion':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameAllowOverlayExpansion: e.target.checked
        })
        break
      case 'registryAllowPushExpansion':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameAllowPushExpansion: e.target.checked
        })
        break
      default:
        break
    }
  }

  const toggleInViewRefresh = e => {
    dispatch(ToggleInViewRefresh(e))
  }

  const toggleRealTimeAdInsertion = e => {
    dispatch(ToggleRealTimeAdInsertion(e))
  }

  const toggleSafeframeConfig = e => {
    dispatch(ToggleSafeframeConfig(e))
  }

  const updateRegistryProperties = () => {
    console.log('[RegistryProperties] ========== updateRegistryProperties::currentRegistry: ', currentRegistry)
    dispatch(updateSiteRegistry(currentRegistry))
  }

  useEffect(() => {
    setCurrentRegistry(registry)
  }, [registry])

  useEffect(() => {
    if (currentRegistry._id) {
      const oldData = {
        tagForChildDirectedTreatment: registry.tagForChildDirectedTreatment,
        isSingleton: registry.isSingleton,
        safeFrameConfig: registry.safeFrameConfig,
        safeFrameAllowOverlayExpansion: registry.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: registry.safeFrameAllowPushExpansion,
        safeFrameSandboxMode: registry.safeFrameSandboxMode,
        hasInViewRefresh: registry.hasInViewRefresh,
        inViewRefreshCount: registry.inViewRefreshCount,
        inViewRefreshInterval: registry.inViewRefreshInterval,
        hasRealTimeAdInsertion: registry.hasRealTimeAdInsertion,
        rtaiMaxNoOfAds: registry.rtaiMaxNoOfAds,
        rtaiBuffer: registry.rtaiBuffer,
        rtaiOffset: registry.rtaiOffset,
        rtaiInitialPlacement: registry.rtaiInitialPlacement,
        rtaiParentSelector: registry.rtaiParentSelector
      }
      const newData = {
        tagForChildDirectedTreatment: currentRegistry.tagForChildDirectedTreatment,
        isSingleton: currentRegistry.isSingleton,
        safeFrameConfig: currentRegistry.safeFrameConfig,
        safeFrameAllowOverlayExpansion: currentRegistry.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: currentRegistry.safeFrameAllowPushExpansion,
        safeFrameSandboxMode: currentRegistry.safeFrameSandboxMode,
        hasInViewRefresh: currentRegistry.hasInViewRefresh,
        inViewRefreshCount: currentRegistry.inViewRefreshCount,
        inViewRefreshInterval: currentRegistry.inViewRefreshInterval,
        hasRealTimeAdInsertion: currentRegistry.hasRealTimeAdInsertion,
        rtaiMaxNoOfAds: currentRegistry.rtaiMaxNoOfAds,
        rtaiBuffer: currentRegistry.rtaiBuffer,
        rtaiOffset: currentRegistry.rtaiOffset,
        rtaiInitialPlacement: currentRegistry.rtaiInitialPlacement,
        rtaiParentSelector: currentRegistry.rtaiParentSelector
      }
      console.log('[RegistryProperties] ---------- useEffect[currentRegistry]::comparison ', { oldData: oldData, newData: newData })
      setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
    }
  }, [currentRegistry])
  
  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrRegistryHeader)} titleTypographyProps={{ variant: 'h6' }} title={`Registry Properties `}/>
      <CardContent className={addClasses(commonClasses.pl_3, commonClasses.pr_3)} style={{height: '100%'}}>
        <Grid container key={'tagForChildDirectedTreatment-singleton'}>
          <Grid item md={6} xs={12}>
            <ToggleSwitch
              inLine
              label='Child-Directed Treatment'
              name='registryTagChildDirectedTreatment'
              offLabel='No'
              onLabel='Yes'
              checked={!!currentRegistry.tagForChildDirectedTreatment}
              onChange={e => handleRegistryChange(e)}
              value={currentRegistry.tagForChildDirectedTreatment}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ToggleSwitch
              inLine
              disabled={
                currentRegistry.slots &&
                currentRegistry.slots.length > 0
              }
              label='Treat As Singleton'
              name='registrySingleton'
              offLabel='No'
              onLabel='Yes'
              checked={!!currentRegistry.isSingleton}
              onChange={e => {
                handleRegistryChange(e)
              }}
            />
          </Grid>                    
        </Grid>
        <Grid container key={'safeframeConfig'}>
          <Grid item md={3} xs={3}>
            <ToggleSwitch
              label='Safeframe Config'
              name='registrySafeframeConfig'
              offLabel='Disabled'
              onLabel='Enabled'
              checked={!!currentRegistry.safeFrameConfig}
              onChange={e => handleRegistryChange(e)}
              value={currentRegistry.safeFrameConfig}
            />
          </Grid>
          <Grid item md={9} xs={9}>
            {hasSafeFrameConfig 
              ? (
                <Grid container spacing={2}>
                  <Grid item md={4} xs={4} className={classes.ConfigurationItemContainer}>
                  <Paper className={classes.ConfigurationItems}>
                    <ToggleSwitch
                      label='Sandbox'
                      name='registrySandbox'
                      offLabel='No'
                      onLabel='Yes'
                      checked={!!currentRegistry.safeFrameSandboxMode}
                      onChange={e => handleRegistryChange(e)}
                      value={currentRegistry.safeFrameSandboxMode}
                      smallLabel
                    />
                  </Paper>
                  </Grid>
                  <Grid item md={4} xs={4} className={classes.ConfigurationItemContainer}>
                  <Paper className={classes.ConfigurationItems}>
                    <ToggleSwitch
                      label='Allow Overlay Expansion'
                      name='registryAllowOverlayExpansion'
                      offLabel='No'
                      onLabel='Yes'
                      checked={!!currentRegistry.safeFrameAllowOverlayExpansion}
                      onChange={e => handleRegistryChange(e)}
                      value={currentRegistry.safeFrameAllowOverlayExpansion}
                      smallLabel
                    />
                  </Paper>
                  </Grid>
                  <Grid item md={4} xs={4} className={classes.ConfigurationItemContainer}>
                  <Paper className={classes.ConfigurationItems}>
                    <ToggleSwitch
                      label='Allow Push Expansion'
                      name='registryAllowPushExpansion'
                      offLabel='No'
                      onLabel='Yes'
                      checked={
                        !!currentRegistry.safeFrameAllowPushExpansion
                      }
                      onChange={e => handleRegistryChange(e)}
                      value={currentRegistry.safeFrameAllowPushExpansion}
                      smallLabel
                    />
                  </Paper>
                  </Grid>
                </Grid>
              )
              : (
                <></>
              )
            }
          </Grid>
        </Grid>
        {siteHasModule('In-View Refresh') ? (
          <Grid container key={'inViewRefresh'}>
            <Grid item md={3} xs={3}>
              <ToggleSwitch
                label='In-View Refresh'
                name='registryInViewRefresh'
                offLabel='Disabled'
                onLabel='Enabled'
                checked={!!currentRegistry.hasInViewRefresh}
                onChange={e => {
                  handleRegistryChange(e)
                }}
                value={!!currentRegistry.hasInViewRefresh}
              />
            </Grid>
            <Grid item md={9} xs={9}>
              {inViewRefresh
                ? (
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={4} className={classes.ConfigurationItemContainer}>
                      {currentRegistry.hasInViewRefresh
                        ? (
                          <>
                            <Paper className={classes.ConfigurationItems}>
                              <TextField
                                {...register('registryInViewRefreshCount')}
                                className={classes.input}
                                label='Refresh Count'
                                margin='dense'
                                id='registryInViewRefreshCount'
                                variant='standard'
                                onChange={e => {
                                  handleRegistryChange(e)
                                }}
                                value={
                                  currentRegistry.inViewRefreshCount === 0
                                    ? (siteIVR?.DEFAULT_REFRESH_COUNT || 5)
                                    : currentRegistry.inViewRefreshCount
                                }
                                type='number'
                                InputProps={{
                                  inputProps: { min: '5', step: '1' }
                                }}
                                error={!!errors.registryInViewRefreshCount}
                                helperText={
                      errors.registryInViewRefreshCount
                        ? errors.registryInViewRefreshCount.message
                        : ''
                    }
                              />
                              <Divider
                                className={commonClasses.verticalDivider28}
                                orientation='vertical'
                              />
                              <span className={classes.smallFont}>times</span>
                            </Paper>
                          </>
                          )
                        : (
                          <></>
                          )}
                    </Grid>
                    <Grid item md={4} xs={4} className={classes.ConfigurationItemContainer}>
                      {currentRegistry.hasInViewRefresh
                        ? (
                          <>
                            <Paper className={classes.ConfigurationItems}>
                              <TextField
                                {...register('registryInViewRefreshInterval')}
                                className={classes.input}
                                label='Refresh Interval'
                                margin='dense'
                                id='registryInViewRefreshInterval'
                                variant='standard'
                                type='number'
                                InputProps={{
                                  inputProps: { min: '35', step: '1' }
                                }}
                                onChange={e => {
                                  handleRegistryChange(e)
                                }}
                                value={
                                  !currentRegistry.inViewRefreshInterval
                                    ? (siteIVR?.DEFAULT_DISPLAY_MILLISECONDS / 1000)
                                    : currentRegistry.inViewRefreshInterval
                                }
                                error={!!errors.registryInViewRefreshInterval}
                                helperText={
                                  errors.registryInViewRefreshInterval
                                    ? errors.registryInViewRefreshInterval.message
                                    : ''
                                }
                              />
                              <Divider
                                className={commonClasses.verticalDivider28}
                                orientation='vertical'
                              />
                              <span className={classes.smallFont}>seconds</span>
                            </Paper>
                          </>
                          )
                        : (
                          <></>
                          )}
                    </Grid>
                  </Grid>
                  )
                : (
                  <></>
                  )}
            </Grid>
          </Grid>
        ): (
        <Grid container key={'inViewRefresh'}>
          <Grid item sm={12}><br /></Grid>
        </Grid>
        )}
        {siteHasModule('Real-Time Ad Insertion') ? (
          <Grid container key={'realTimeAdInsertion'}>
            <Grid item md={3} xs={3}>
              <ToggleSwitch
                label='Real-Time Ad Insertion'
                name='registryRealTimeAdInsertion'
                offLabel='Disabled'
                onLabel='Enabled'
                checked={!!currentRegistry.hasRealTimeAdInsertion}
                onChange={e => {
                  handleRegistryChange(e)
                }}
                value={!!currentRegistry.hasRealTimeAdInsertion}
              />
            </Grid>
            <Grid item md={9} xs={9}>
              {realTimeAdInsertion
                ? (
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={4} key={'rtaiMaxNoOfAdsItem'} className={classes.ConfigurationItemContainer}>
                      {currentRegistry.hasRealTimeAdInsertion
                        ? (
                          <Paper className={classes.ConfigurationItems}>
                            <TextField
                              {...register('registryRTAIMaxNoOfAds')}
                              className={classes.input}
                              label='Max # of Ads'
                              margin='dense'
                              id='registryRTAIMaxNoOfAds'
                              variant='standard'
                              onChange={e => {
                                handleRegistryChange(e)
                              }}
                              value={
                                currentRegistry.rtaiMaxNoOfAds                                            
                                  ? currentRegistry.rtaiMaxNoOfAds
                                  : 6
                              }
                              type='number'
                              InputProps={{
                                inputProps: { min: '1', step: '1' }
                              }}
                              error={!!errors.registryRTAIMaxNoOfAds}
                              helperText={
                                errors.registryRTAIMaxNoOfAds
                                  ? errors.registryRTAIMaxNoOfAds.message
                                  : ''
                              }
                            />
                          </Paper>
                          )
                        : (
                          <></>
                          )
                      }
                    </Grid>
                    <Grid item md={4} xs={4} key={'rtaiBuffer'} className={classes.ConfigurationItemContainer}>
                      {currentRegistry.hasRealTimeAdInsertion
                        ? (
                          <Paper className={classes.ConfigurationItems}>
                            <TextField
                              {...register('registryRTAIBuffer')}
                              className={classes.input}
                              label='Buffer'
                              margin='dense'
                              id='registryRTAIBuffer'
                              variant='standard'
                              onChange={e => {
                                handleRegistryChange(e)
                              }}
                              value={
                                currentRegistry.rtaiBuffer === 0
                                  ? 4
                                  : currentRegistry.rtaiBuffer
                              }
                              type='number'
                              InputProps={{
                                inputProps: { min: '1', step: '1' }
                              }}
                              error={!!errors.registryRTAIBuffer}
                              helperText={
                                errors.registryRTAIBuffer
                                  ? errors.registryRTAIBuffer.message
                                  : ''
                              }
                            />
                          </Paper>
                        )
                        : (
                          <></>
                        )
                      }
                    </Grid>
                    <Grid item md={4} xs={4} key={'rtaiOffset'} className={classes.ConfigurationItemContainer}>
                    {currentRegistry.hasRealTimeAdInsertion
                        ? (
                          <Paper className={classes.ConfigurationItems}>
                            <TextField
                              {...register('registryRTAIOffset')}
                              className={classes.input}
                              label='Offset'
                              margin='dense'
                              id='registryRTAIOffset'
                              variant='standard'
                              onChange={e => {
                                handleRegistryChange(e)
                              }}
                              value={
                                currentRegistry.rtaiOffset === 0
                                  ? 2
                                  : currentRegistry.rtaiOffset
                              }
                              type='number'
                              InputProps={{
                                inputProps: { min: '1', step: '1' }
                              }}
                              error={!!errors.registryRTAIOffset}
                              helperText={
                                errors.registryRTAIOffset
                                  ? errors.registryRTAIOffset.message
                                  : ''
                              }
                            />
                          </Paper>
                        )
                        : (
                          <></>
                        )
                      }
                    </Grid>
                    <Grid item md={4} xs={4} key={'rtaiInitialPlacement'} className={classes.ConfigurationItemContainer}>
                      {currentRegistry.hasRealTimeAdInsertion
                        ? (
                          <Paper className={classes.ConfigurationItems}>
                            <TextField
                              {...register('registryRTAIInitialPlacement')}
                              className={classes.input}
                              label='Initial Placement'
                              margin='dense'
                              id='registryRTAIInitialPlacement'
                              variant='standard'
                              onChange={e => {
                                handleRegistryChange(e)
                              }}
                              value={
                                currentRegistry.rtaiInitialPlacement === 0
                                  ? 4
                                  : currentRegistry.rtaiInitialPlacement
                              }
                              type='number'
                              InputProps={{
                                inputProps: { min: '1', step: '1' }
                              }}
                              error={!!errors.registryRTAIInitialPlacement}
                              helperText={
                                errors.registryRTAIInitialPlacement
                                  ? errors.registryRTAIInitialPlacement.message
                                  : ''
                              }
                            />
                          </Paper>
                        )
                        : (
                          <></>
                          )
                      }
                    </Grid>
                    <Grid item md={8} xs={8} key={'rtaiParentSelector'} className={classes.ConfigurationItemContainer}>
                      {currentRegistry.hasRealTimeAdInsertion
                        ? (
                          <Paper className={classes.ConfigurationItems}>
                            <TextField
                              fullWidth
                              label='Parent Selector'
                              margin='dense'
                              id='registryRTAIParentSelector'
                              variant='standard'
                              value={currentRegistry.rtaiParentSelector}
                              {...register('registryRTAIParentSelector')}
                              onChange={e => handleRegistryChange(e)}
                              error={!!errors.registryRTAIParentSelector}
                              helperText={
                                errors.registryRTAIParentSelector
                                  ? errors.registryRTAIParentSelector.message
                                  : ''
                              }
                            />
                          </Paper>
                        )
                        : (
                          <></>
                        )
                      }
                    </Grid>
                  </Grid>
                  )
                : (
                  <></>
                  )}
            </Grid>
          </Grid>
        ): (
          <Grid container key={'realTimeAdInsertion'}>
            <Grid item sm={12}><br /></Grid>
          </Grid>
        )}
      </CardContent>
      <Divider />
      <CardActions className={commonClasses.panelFooter} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className={isChanged ? commonClasses.btnSuccess : ''}
          disabled={!isChanged || updatingRegistryDetails}
          variant='contained'
          startIcon={<CheckCircleIcon />}
          onClick={() => {
            dispatch(updateRegistryProperties(currentRegistry))
          }}
        >
          {updatingRegistryDetails ? 'Saving...' : 'Save'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default RegistryProperties