import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers, createUser, updateUser, deleteUser } from '../../modules/userSlice'
import { fetchUserRoles } from '../../modules/roleSlice'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup'

import MaterialTable, { MTableActions } from 'material-table'
import { TableIcons } from '../ui/TableIcons'
import { Select, MenuItem, FormControlLabel, Card, CardHeader, CardContent, CardActions, Container, List, ListItem, Checkbox, Divider, Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import TuneIcon from '@material-ui/icons/Tune'
import { commonStyle } from '../../styles'

import { CustomDialog, LoadingProgress } from '../ui'
import { clone } from '../../utilities/helperFunctions.js'

const swal = withReactContent(Swal)

const userSchema = yup.object().shape({
  username: yup.string().required('Username is Required'),
  firstName: yup.string().required('First Name is Required'),
  lastName: yup.string().required('Last Name is Required'),
  email: yup.string().required('Email is Required').email('Email is Invalid')
})

export const EditRoleDialog = ({ data, roleList, setOpenDialog }) => {
  const dispatch = useDispatch()
  const commonClasses = commonStyle()
  const [user, setUser] = useState(clone(data))
  const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    data.role2.sort()
    user.role2.sort()
    setIsChanged(JSON.stringify(data.role2) !== JSON.stringify(user.role2))
  }, [user])

  return (
    <Card>
      <CardHeader title='User Roles' />
      <Divider />
      <CardContent className={commonClasses.cardBkClr}>
        <Card><CardContent>
        <List>
          {roleList.map((role, index) => {
            const checked = !!user.role2.find(item => item === role.name)
            return (
              <ListItem key={index} dense>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color='primary'
                      checked={checked}
                      onChange={(event) => {
                        const checked = event.target.checked
                        if (checked) {
                          const newRoles = [...user.role2, role.name]
                          newRoles.sort()
                          setUser({ ...user, role2: newRoles })
                        } else {
                          setUser({ ...user, role2: user.role2.filter(item => item !== role.name) })
                        }
                      }}
                    />
                  )}
                  label={role.name}
                />
              </ListItem>
            )
          })}
        </List>
        </CardContent></Card>
      </CardContent>
      <Divider />
      <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='text'
          onClick={() => setOpenDialog(false)}
        >
          Cancel
        </Button>
        <Button
          className={commonClasses.btnSuccess}
          disabled={!isChanged}
          variant='contained'
          startIcon={<CheckCircleIcon />}
          onClick={async () => {
            try {
              const result = await updateUser(user)
              if (result.reason) {
                swal.fire({ title: result.reason, icon: 'error' })
              } else {
                swal.fire({ title: `User Roles for ${user.firstName} was updated successfully`, icon: 'success' })
                dispatch(fetchUsers())
              }
            } catch (err) {
              swal.fire({ title: err, icon: 'error' })
            }
            setOpenDialog(false)
          }}
        >
          Save
        </Button>
      </CardActions>
    </Card>
  )
}

export const Users = () => {
  const dispatch = useDispatch()
  const commonClasses = commonStyle()
  const { users } = useSelector((state) => state.users)
  const { roles } = useSelector((state) => state.roles)
  const [userList, setUserList] = useState([])
  const [roleList, setRoleList] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [roleFilter, setRoleFilter] = useState('none')
  const [openFilter, setOpenFilter] = useState(false)

  useEffect(() => {
    setRoleList(clone(roles))
  }, [roles])

  useEffect(() => {
    if (roleFilter !== 'none') {
      setUserList([...clone(users).filter(user => user.role2.find(role => role === roleFilter.name))])
    } else {
      setUserList([...clone(users)])
    }
  }, [users])

  useEffect(() => {
    if (users.length === 0) { dispatch(fetchUsers()) }
    if (roles.length === 0) { dispatch(fetchUserRoles()) }
  }, [])

  const openEditRolesDialog = (user) => {
    setDialog({
      size: 'sm',
      content: <EditRoleDialog data={user} roleList={roleList} setOpenDialog={setOpenDialog} />,
      displayDialogAction: false
    })
    setOpenDialog(true)
  }

  return (
    <Container maxWidth='xl'>
      <Card>
        <CardHeader title="Users" />
        <CardContent className={commonClasses.cardBkClr}>
          <Card><CardContent>
          {users.length > 0
            ? (
              <MaterialTable
                title='Users'
                icons={TableIcons}
                data={userList}
                options={{
                  addRowPosition: 'first',
                  emptyRowsWhenPaging: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100, userList.length],
                  headerStyle: {
                    fontWeight: 'bold'
                  },
                  actionsColumnIndex: -1,
                  actionsCellStyle: { width: '100px' }
                }}
                columns={[
                  {
                    title: 'First Name',
                    field: 'firstName',
                    validate: rowData => {
                      if (typeof rowData.firstName !== 'undefined') {
                        try {
                          userSchema.validateSyncAt('firstName', rowData)
                          return true
                        } catch (err) {
                          return { isValid: false, helperText: err.errors[0] }
                        }
                      }
                    }
                  },
                  {
                    title: 'Last Name',
                    field: 'lastName',
                    validate: rowData => {
                      if (typeof rowData.lastName !== 'undefined') {
                        try {
                          userSchema.validateSyncAt('lastName', rowData)
                          return true
                        } catch (err) {
                          return { isValid: false, helperText: err.errors[0] }
                        }
                      }
                    }
                  },
                  {
                    title: 'Email',
                    field: 'email',
                    validate: rowData => {
                      if (typeof rowData.email !== 'undefined') {
                        try {
                          userSchema.validateSyncAt('email', rowData)
                          return true
                        } catch (err) {
                          return { isValid: false, helperText: err.errors[0] }
                        }
                      }
                    }
                  },
                  {
                    title: 'Roles',
                    field: 'role2',
                    editable: 'never',
                    render: rowData => {
                      return (
                        <Button
                          className={commonClasses.btnInfo}
                          variant='contained'
                          startIcon={<AssignmentIndIcon />}
                          onClick={() => openEditRolesDialog(rowData)}
                        >
                          Roles
                        </Button>
                      )
                    }
                  }
                ]}
                editable={{
                  onRowAdd: (item) => new Promise(resolve => {
                    setTimeout(async () => {
                      const username = (item.firstName.substring(0, 1) + '-' + item.lastName).toLowerCase() + (Math.random() + 1).toString(36).substring(7)
                      item.username = username
                      try {
                        const result = await createUser(item)
                        if (result.reason) {
                          swal.fire({ title: result.reason, icon: 'error' })
                        } else {
                          swal.fire({ title: `User ${item.firstName} was created successfully`, icon: 'success' })
                          dispatch(fetchUsers())
                        }
                      } catch (err) {
                        swal.fire({ title: err, icon: 'error' })
                      }
                      resolve()
                    }, 1000)
                  }),
                  onBulkUpdate: (changes) => new Promise(resolve => {
                    setTimeout(async () => {
                      try {
                        let result = await Promise.all(userList.map((user, index) => changes[index] ? updateUser(changes[index].newData) : null))
                        if (result.reason) {
                          swal.fire({ title: result.reason, icon: 'error' })
                        } else {
                          result = result.filter(item => item)
                          swal.fire({ title: `${result.length} User(s) have been updated successfully`, icon: 'success' })
                          dispatch(fetchUsers())
                        }
                      } catch (err) {
                        swal.fire({ title: err, icon: 'error' })
                      }
                      resolve()
                    }, 1000)
                  }),
                  onRowDelete: (item) => new Promise(resolve => {
                    setTimeout(async () => {
                      try {
                        const result = await deleteUser(item)
                        if (result.reason) {
                          swal.fire({ title: result.reason, icon: 'error' })
                        } else {
                          swal.fire({ title: `User ${item.firstName} was deleted successfully`, icon: 'success' })
                          dispatch(fetchUsers())
                        }
                      } catch (err) {
                        swal.fire({ title: err, icon: 'error' })
                      }
                      resolve()
                    }, 1000)
                  })
                }}
                components={{
                  Actions: props => {
                    const { actions } = props
                    return (
                      <div>
                        {actions.find(item => item.tooltip === 'Filter Roles') && (
                          <Select
                            labelId='filter-roles-label'
                            style={{ width: '0', opacity: '0' }}
                            value={roleFilter}
                            open={openFilter}
                            onClose={() => {
                              setOpenFilter(false)
                            }}
                            onChange={(event) => {
                              const value = event.target.value
                              if (value !== 'none') {
                                setRoleFilter(value)
                                setUserList([...clone(users).filter(user => user.role2.find(role => role === value.name))])
                              } else {
                                setRoleFilter('none')
                                setUserList([...clone(users)])
                              }
                            }}
                          >
                            <MenuItem value='none'>none</MenuItem>
                            {roleList.map((item, index) => {
                              return (<MenuItem key={index} value={item}>{item.name}</MenuItem>)
                            })}
                          </Select>
                        )}
                        <MTableActions {...props} />
                      </div>
                    )
                  }
                }}
                actions={[
                  {
                    icon: TuneIcon,
                    tooltip: 'Filter Roles',
                    isFreeAction: true,
                    onClick: () => {
                      setOpenFilter(!openFilter)
                    }
                  }
                ]}
              />
              )
            : (
              <LoadingProgress />
              )}
          <CustomDialog
            open={openDialog}
            setOpenDialog={setOpenDialog}
            title={dialog.title}
            subtitle={dialog.subtitle}
            content={dialog.content}
            displayDialogAction={dialog.displayDialogAction}
            customAction={dialog.customAction}
            size={dialog.size}
          />
          </CardContent></Card>
        </CardContent>
      </Card>

    </Container>
  )
}
