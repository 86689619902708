import React, { useState, useEffect } from 'react'

import { Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const template = {
  SITE_ID: {
    DOM: 0,
    INTL: 0
  }
}

export const ThirtyThreeAcross = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  VENDOR.THIRTYTHREEACROSS = { ...template, ...VENDOR.THIRTYTHREEACROSS }
  const { THIRTYTHREEACROSS } = VENDOR
  const { SITE_ID } = THIRTYTHREEACROSS
  const { DOM, INTL } = SITE_ID
  const { STRING } = validation
  const { LENGTH } = STRING
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='33across-panel-content'
        id='33across-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>33Across</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Table size='small' aria-label='33across-account-id-table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '180px' }}><Typography variant='h6'>Site IDs</Typography></TableCell>
              <TableCell>Domestic</TableCell>
              <TableCell>International</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell>
                <TextField
                  margin='none'
                  value={DOM}
                  error={errors.PREBID_33ACROSS_SIDD}
                  helperText={errors.PREBID_33ACROSS_SIDD && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    PREBID.VENDOR.THIRTYTHREEACROSS.SITE_ID.DOM = value
                    setErrors({ ...errors, PREBID_33ACROSS_SIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, PREBID })
                    setAllOptions({ ...allOptions, PREBID })
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  margin='none'
                  value={INTL}
                  error={errors.PREBID_33ACROSS_SIDI}
                  helperText={errors.PREBID_33ACROSS_SIDI && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    PREBID.VENDOR.THIRTYTHREEACROSS.SITE_ID.INTL = value
                    setErrors({ ...errors, PREBID_33ACROSS_SIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, PREBID })
                    setAllOptions({ ...allOptions, PREBID })
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
