import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CircularProgress, Card, CardHeader, CardContent, CardActions, Divider, Grid, TextField, Typography, Link, Button, makeStyles } from '@material-ui/core'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HistoryIcon from '@material-ui/icons/History'
import { addClasses, commonStyle } from '../../styles'
import {
  siteNameFormatter,
  isObjectEquivalent,
  registryNameFormatter
} from '../../utilities/helperFunctions'
import {
  updateSiteRegistry,
  registryImplementationProcedureUrl,
  registryUrl,
  deploySiteRegistry
} from '../../modules/registrySlice'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  string as yupstring,
  number as yupNumber,
  object as yupobject
} from 'yup'
import { getPath } from '../../modules/configSlice'

const useStyle = makeStyles(theme => ({
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
  links: {
    color: "darkorange",
    fontSize: '.9em',
    "&:visited": {
      color: "green"
    }
  }
}))

const RegistryDetails = ({ canEditRegistry, registry, site }) => {
  const commonClasses = commonStyle()
  const classes = useStyle()
  const dispatch = useDispatch()
  const history = useHistory()
  const [ publishers, setPublishers ] = useState({
    rootAdFuelDirectory: '/www/ads/dev/adfuel',
    rootAdFuelAisDirectory: '/www/ads/dev/adfuel/ais',
    rootSiteDirectory: '/www/ads/dev/{siteName}',
    rootAdSpacesDirectory: '/www/ads/dev/adspaces',
    rootAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
    rootAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
    rootSSLAdFuelUrl: 'https://i.cdn.turner.com/ads/dev/adfuel',
    rootSSLAdFuelAisUrl: 'https://i.cdn.turner.com/ads/dev/adfuel/ais',
    rootSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
    rootSSLSiteUrl: 'https://i.cdn.turner.com/ads/dev/{siteName}',
    rootAdSpacesUrl: 'https://i.cdn.turner.com/ads/dev/adspaces',
    rootAnalyticsSiteUrl: 'https://i.cdn.turner.com/analytics/{siteName}'
  })
  const [clickCount, setClickCount] = useState(0)
  const { isDeploying } = useSelector(state => state.registrySlice)
  const { pathConfig } = useSelector(state => state.configSlice)
  const { isAdFuelVersionSelected, siteIVR, siteRTAI } = useSelector(state => state.sitesSlice)
  const validationSchema = yupobject().shape({
    registryDescription: yupstring().required('Required'),
    registryInViewRefreshCount: yupNumber()
      .typeError('Count must be a number')
      .required('Required')
      .min(5, 'Min value 5'),
    registryInViewRefreshInterval: yupNumber()
      .typeError('Interval must be a number')
      .required('Required')
      .min(35, 'Min value 35'),
    registryInViewDisplayPercentage: yupNumber()
      .typeError('Percentage must be a number')
      .required('Required')
      .min(25, 'Min value 25')
  })
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

    // Update Registry
  const updateRegistryDetails = () => {
    dispatch(updateSiteRegistry(registry))
  }
  
  let isChanged = false
  let updatingRegistryDetails = false
  console.log('Can Edit Registry: ', canEditRegistry)
  console.log('Updating Registry Details: ', updatingRegistryDetails)

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      setPublishers(pathConfig.publisher)
    } else {
      dispatch(getPath())
    }
  }, [pathConfig])  
  let ripURL = registryImplementationProcedureUrl(
    siteNameFormatter(site.name),
    false,
    site.areLinksSecure,
    registry,
    publishers
  )
  let registryURL = registryUrl(
    siteNameFormatter(site.name),
    false,
    site.isRegistryMinified,
    site.areLinksSecure,
    registry,
    publishers
  )

  const onViewHistory = () => {
    console.log('[RegistryDetails] ========== onViewHistory...')
    history.push(
      `/sites/${siteNameFormatter(
        site.name
      )}/registries/${registryNameFormatter(registry.name)}/history`
    )
  }

  const onDeploySiteRegistries = () => {
    dispatch(deploySiteRegistry(registry._id))
  }

  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrRegistryHeader)} titleTypographyProps={{ variant: 'h6' }} title={`Registry Details `}/>
      <CardContent className={addClasses(commonClasses.pl_3, commonClasses.pr_3)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={true}
              fullWidth
              margin='none'
              label='Name'
              placeholder='Name'
              value={registry.name}
              onChange={(event) => {
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={!canEditRegistry || updatingRegistryDetails}
              fullWidth
              margin='none'
              label='Description'
              placeholder='Description'
              value={registry.description}
              onChange={(event) => {
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item lg={4} xs={6}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Created:</label>
              <Typography>
              {new Date(registry.createdOn).toDateString()} {new Date(registry.createdOn).toLocaleTimeString('en-US')}<div style={{fontSize: '.7em'}}>by: {registry.createdBy}</div>
              </Typography>
            </Grid>
            <Grid item lg={4} xs={6}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Last Modified:</label>
              <Typography>
              {new Date(registry.modifiedOn).toDateString()} {new Date(registry.modifiedOn).toLocaleTimeString('en-US')} <div style={{fontSize: '.7em'}}>by: {registry.modifiedBy}</div>
              </Typography>
            </Grid>
            <Grid item lg={4} xs={6}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Last Deployment:</label>
              <Typography>
                {new Date(registry.lastDeployedOn).toDateString()} {new Date(registry.lastDeployedOn).toLocaleTimeString('en-US')}
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Registry Metadata URL:</label>
              <Typography className={classes.link}>
                <Link
                  className={addClasses(commonClasses.linkColor, classes.links)}
                  href={registryURL}
                  rel='noreferrer'
                  target='_blank'
                  onClick={() => {
                    setClickCount(clickCount + 1)
                  }}
                >
                  {registryURL.length > 60 
                    ? (registryURL.substring(0,30) + '[...]' + registryURL.substring(registryURL.length-25)) 
                    : (registryURL)
                  }
                </Link>
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Implementation Procedure:</label>
              <Typography className={classes.link}>
                <Link
                  className={addClasses(commonClasses.linkColor, classes.links)}
                  href={ripURL}
                  onClick={() => {
                    setClickCount(clickCount + 1)
                  }}
                  rel='noreferrer'
                  target='_blank'
                >
                  {ripURL.length > 60 
                    ? (ripURL.substring(0,30) + '[...]' + ripURL.substring(ripURL.length-25)) 
                    : (ripURL)
                  }
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={commonClasses.panelFooter} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <>
          <Button
            className={
              isChanged
                ? [commonClasses.btnSuccess, commonClasses.mr_1].join(' ')
                : commonClasses.mr_1
            }
            style={{color: 'white'}}
            disabled={!isChanged || loading}
            title='Save'
            variant='contained'
            size="small"
            onClick={handleSubmit(updateRegistryDetails)}
            startIcon={<CheckCircleIcon />}
          >
            Save
          </Button>
          {isDeploying
            ? (
              <Button
                disabled
                variant='contained'
                size="small"
                title='Deploying...'
                className={[
                  commonClasses.btnSuccess,
                  commonClasses.mr_1
                ].join(' ')}
                startIcon={<CircularProgress size={24} />}
              >
                Deploy
              </Button>
              )
            : registry.isChangedSinceDeployment
              ? (
                <Button
                  disabled={isChanged || !isAdFuelVersionSelected}
                  variant='contained'
                  size="small"
                  title='Deploy'
                  className={[
                    commonClasses.btnWarning,
                    commonClasses.mr_1
                  ].join(' ')}
                  startIcon={<OfflineBoltIcon />}
                  onClick={() => {
                    onDeploySiteRegistries()
                  }}
                >
                  Deploy
                </Button>
                )
              : (
                <Button
                  disabled={isChanged || !isAdFuelVersionSelected}
                  variant='contained'
                  size="small"
                  title='Deploy'
                  className={[
                    commonClasses.btnSuccess,
                    commonClasses.mr_1
                  ].join(' ')}
                  startIcon={<OfflineBoltIcon />}
                  onClick={() => {
                    onDeploySiteRegistries()
                  }}
                >
                  Deploy
                </Button>
                )
          }
          {
            <Button
              variant='contained'
              size="small"
              title='View History'
              className={[
                commonClasses.btnInfo,
                commonClasses.mr_1
              ].join(' ')}
              startIcon={<HistoryIcon size={24} />}
              onClick={() => {
                onViewHistory()
              }}
            >
              View History
            </Button>
          }
        </>
      </CardActions>
    </Card>
  )
}

export default RegistryDetails