/* eslint-disable react-hooks/exhaustive-deps */
// adding the below line to prevent infinite loop
import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Card, CardContent, CardHeader, Divider } from '@material-ui/core'
import {
  updateRolePermission,
  createRolePermission,
  fetchRolePermissions
} from '../../modules/permissionSlice'
import { TableIcons } from '../ui/TableIcons'
import { LoadingProgress } from '../ui'
import * as yup from 'yup'
import { commonStyle } from '../../styles'

const Permissions = () => {
  const dispatch = useDispatch()
  const { permissions } = useSelector(state => state.permissionSlice)
  const [permissionList, setPermissionList] = useState([])
  const commonClasses = commonStyle()
  const viewportSchema = yup.object().shape({
    name: yup.string().required('Name is Required'),
    description: yup.string().required('Description is Required'),
    defaultLevel: yup.number().required('Level is Required').min(1)
  })

  useEffect(() => {
    if (permissions.length === 0) {
      dispatch(fetchRolePermissions())
    } else {
      setPermissionList(permissions.map(permissions => ({ ...permissions })))
    }
  }, [permissions])

  return (
    <Container maxWidth='xl'>
       <Card>
        <CardHeader title='Permissions' />
        <Divider />
        <CardContent className={commonClasses.cardBkClr}>
          {permissionList.length > 1
            ? (
              <MaterialTable
                title=''
                icons={TableIcons}
                columns={[
                  {
                    title: 'Name',
                    field: 'name',
                    editable: 'onAdd',
                    validate: rowData => {
                      if (typeof rowData.name !== 'undefined') {
                        try {
                          viewportSchema.validateSyncAt('name', rowData)
                          return true
                        } catch (err) {
                          return { isValid: false, helperText: err.errors[0] }
                        }
                      }
                    }
                  },
                  {
                    title: 'Description',
                    field: 'description',
                    validate: rowData => {
                      if (typeof rowData.name !== 'undefined') {
                        try {
                          viewportSchema.validateSyncAt('description', rowData)
                          return true
                        } catch (err) {
                          return { isValid: false, helperText: err.errors[0] }
                        }
                      }
                    }
                  },
                  {
                    title: 'Level',
                    field: 'defaultLevel',
                    type: 'numeric',
                    validate: rowData => {
                      if (typeof rowData.name !== 'undefined') {
                        try {
                          viewportSchema.validateSyncAt('defaultLevel', rowData)
                          return true
                        } catch (err) {
                          return { isValid: false, helperText: err.errors[0] }
                        }
                      }
                    }
                  }
                ]}
                data={permissionList}
                options={{
                  addRowPosition: 'first',
                  emptyRowsWhenPaging: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  headerStyle: {
                    fontWeight: 'bold'
                  },
                  actionsColumnIndex: -1
                }}
                editable={{
                  onRowAdd: permission => {
                    return new Promise(resolve => {
                      setTimeout(() => {
                        dispatch(createRolePermission(permission))
                        setPermissionList([...permissionList, permission])
                        resolve()
                      }, 600)
                    })
                  },
                  onRowUpdate: (newPermission, prevPermission) => {
                    return new Promise(resolve => {
                      setTimeout(() => {
                        dispatch(updateRolePermission(newPermission))
                        const _permissions = permissionList.slice()
                        const updatedItemIndex = _permissions.findIndex(
                          permission => permission._id === newPermission._id
                        )
                        _permissions[updatedItemIndex] = newPermission
                        setPermissionList(
                          _permissions.map(permissions => ({ ...permissions }))
                        )
                        resolve()
                      }, 600)
                    })
                  }
                // onRowDelete: permission => {
                //   return new Promise(resolve => {
                //     setTimeout(() => {
                //       dispatch(deleteRolePermission(permission._id))
                //       setPermissionList(
                //         permissionList.filter(item => item._id !== permission._id)
                //       )
                //       resolve()
                //     }, 600)
                //   })
                // }
                }}
              />
            )
            : (
              <LoadingProgress label='Loading Registry Information' />
            )
          }
        </CardContent>
      </Card>
    </Container>
  )
}

export default Permissions
