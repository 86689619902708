import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllAdUnits } from '../../../modules/DFPAdUnitSlice'
import { updateGeneralInformation } from '../../../modules/sitesSlice'

import { FormControl, InputLabel, Select, MenuItem, Card, CardHeader, CardContent, CardActions, Divider, Link, Grid, TextField, Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ToggleSwitch } from '../../ui'

import { addClasses, commonStyle } from '../../../styles'
import { clone, siteNameFormatter, cacheBuster } from '../../../utilities/helperFunctions.js'

const GeneralInformation = ({ data, canEditSite, setImplementationMethod }) => {
  const commonClasses = commonStyle()
  const { updatingSiteGeneralInfo } = useSelector(state => state.sitesSlice)
  const [site, setSite] = useState(clone(data))
  const {
    description,
    rootAdUnitId,
    useWOPR,
    // usePackage = false,
    safeFrameConfig,
    safeFrameSandboxMode,
    safeFrameAllowOverlayExpansion,
    safeFrameAllowPushExpansion,
    tagForChildDirectedTreatment
  } = site
  const [indexUrl, setIndexUrl] = useState('')
  const [indexUrlWithCacheBuster, setIndexUrlWithCacheBuster] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const { adRoots } = useSelector(state => state.dfp)
  const dispatch = useDispatch()
  const [clickCount, setClickCount] = useState(0)

  useEffect(() => {
    setSite(clone(data))
    if (site.name) {
      setIndexUrl(`https://i.cdn.turner.com/ads/${siteNameFormatter(site.name)}/index.txt`)
      setIndexUrlWithCacheBuster(`https://i.cdn.turner.com/ads/${siteNameFormatter(site.name)}/index.txt?cacheBuster=${cacheBuster()}`)
    }
  }, [data])

  useEffect(() => {
    if (site.name) {
      setIndexUrlWithCacheBuster(`https://i.cdn.turner.com/ads/${siteNameFormatter(site.name)}/index.txt?cacheBuster=${cacheBuster()}`)
    }
  }, [clickCount])

  useEffect(() => {
    if (adRoots.length === 0) { dispatch(fetchAllAdUnits()) }
  }, [])

  useEffect(() => {
    if (site._id) {
      const oldData = {
        description: data.description,
        rootAdUnitId: data.rootAdUnitId,
        useWOPR: data.useWOPR,
        // usePackage: data.usePackage || false,
        isRegistryMinified: data.isRegistryMinified,
        safeFrameConfig: data.safeFrameConfig,
        safeFrameSandboxMode: data.safeFrameSandboxMode,
        safeFrameAllowOverlayExpansion: data.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: data.safeFrameAllowPushExpansion,
        tagForChildDirectedTreatment: data.tagForChildDirectedTreatment
      }
      const newData = {
        description: site.description,
        rootAdUnitId: site.rootAdUnitId,
        useWOPR: site.useWOPR,
        // usePacakge: site.usePackage || false,
        isRegistryMinified: site.isRegistryMinified,
        safeFrameConfig: site.safeFrameConfig,
        safeFrameSandboxMode: site.safeFrameSandboxMode,
        safeFrameAllowOverlayExpansion: site.safeFrameAllowOverlayExpansion,
        safeFrameAllowPushExpansion: site.safeFrameAllowPushExpansion,
        tagForChildDirectedTreatment: site.tagForChildDirectedTreatment
      }
      setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
    }
  }, [site])

  return (
    <Card>
      <CardHeader className={commonClasses.pl_3} titleTypographyProps={{ variant: 'h6' }} title='General Information' />
      <CardContent className={addClasses(commonClasses.pl_3, commonClasses.pr_3)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={!canEditSite || updatingSiteGeneralInfo}
              fullWidth
              margin='none'
              label='Description'
              placeholder='Description'
              value={description}
              onChange={(event) => {
                const value = event.target.value
                setSite({ ...site, description: value })
              }}
            />
          </Grid>
          <Grid item xs={12} container spacing={2} alignItems='center'>
            {rootAdUnitId && (
              <Grid item>
                <FormControl>
                  <InputLabel id='adRoot-label'>AdRoot</InputLabel>
                  <Select
                    disabled
                    labelId='adRoot-label'
                    style={{ minWidth: '120px' }}
                    value={rootAdUnitId}
                    onChange={(event) => {
                      setSite({ ...site, rootAdUnitId: event.target.value })
                    }}
                  >
                    {adRoots.map((item, index) => {
                      return (<MenuItem key={index} value={item.dfpId}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {indexUrl && (
              <Grid item>
                <Link
                  className={commonClasses.linkColor}
                  target='_blank' rel='noopener noreferrer'
                  href={indexUrlWithCacheBuster}
                  onClick={() => {
                    setClickCount(clickCount + 1)
                  }}
                >{indexUrl}
                </Link>
              </Grid>)}
          </Grid>
          {useWOPR !== undefined && (
            <Grid item>
              <ToggleSwitch
                label='Registry Naming'
                offLabel='Normal'
                onLabel='WOPR'
                checked={useWOPR}
                // onChange={(event) => {
                //   setSite({ ...site, useWOPR: event.target.checked })
                // }}
                disabled
              />
            </Grid>
          )}
          {/* {usePackage !== undefined && (
            <Grid item>
              <ToggleSwitch
                label='Implementation'
                offLabel='CDN'
                onLabel='NPM'
                checked={usePackage}
                onChange={(event) => {
                  setSite({ ...site, usePackage: event.target.checked })
                  setImplementationMethod(event.target.checked ? 'NPM' : 'CDN')
                }}
                className={commonClasses.toggleSwitch}
                color='success'
              />
            </Grid>
          )} */}
          {tagForChildDirectedTreatment !== undefined && (
            <Grid item>
              <ToggleSwitch
                // disabled
                label='Tag Site for Child-Directed Treatment'
                offLabel='No'
                onLabel='Yes'
                checked={tagForChildDirectedTreatment}
                onChange={(event) => {
                  setSite({ ...site, tagForChildDirectedTreatment: event.target.checked })
                }}
                className={commonClasses.toggleSwitch}
                color='success'
              />
            </Grid>
          )}
          {safeFrameConfig !== undefined && (
            <Grid item xs={12} container>
              <Grid item>
                <ToggleSwitch
                  disabled={!canEditSite || updatingSiteGeneralInfo}
                  label='Set Safeframe Config'
                  offLabel='Disabled'
                  onLabel='Enabled'
                  checked={safeFrameConfig}
                  onChange={(event) => {
                    setSite({ ...site, safeFrameConfig: event.target.checked })
                  }}
                  color='info'
                />
              </Grid>
              {safeFrameConfig && (
                <>
                  <Grid item>
                    <ToggleSwitch
                      disabled={!canEditSite || updatingSiteGeneralInfo}
                      label='Sandbox'
                      offLabel='No'
                      onLabel='Yes'
                      checked={safeFrameSandboxMode}
                      onChange={(event) => {
                        setSite({ ...site, safeFrameSandboxMode: event.target.checked })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ToggleSwitch
                      disabled={!canEditSite || updatingSiteGeneralInfo}
                      label='Allow Overlay Expansion'
                      offLabel='No'
                      onLabel='Yes'
                      checked={safeFrameAllowOverlayExpansion}
                      onChange={(event) => {
                        setSite({ ...site, safeFrameAllowOverlayExpansion: event.target.checked })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ToggleSwitch
                      disabled={!canEditSite || updatingSiteGeneralInfo}
                      label='Allow Push Expansion'
                      offLabel='No'
                      onLabel='Yes'
                      checked={safeFrameAllowPushExpansion}
                      onChange={(event) => {
                        setSite({ ...site, safeFrameAllowPushExpansion: event.target.checked })
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className={isChanged ? commonClasses.btnSuccess : ''}
          disabled={!isChanged || updatingSiteGeneralInfo}
          variant='contained'
          startIcon={<CheckCircleIcon />}
          onClick={() => {
            dispatch(updateGeneralInformation(site))
          }}
        >
          {updatingSiteGeneralInfo ? 'Saving...' : 'Save'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default GeneralInformation