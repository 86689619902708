import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { addClasses, commonStyle } from '../../styles'
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  ButtonGroup,
  Button,
  Grid,
  Chip
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useSelector, useDispatch } from 'react-redux'
import { getAllCustomTargets } from '../../modules/DFPAdUnitSlice'
import { Edit } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
}))

function getStyles (targeting, selectedTargetings, theme) {
  return {
    fontWeight:
      selectedTargetings.indexOf(targeting) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

export const CustomTargetingListBeta = ({
  isNew = false,
  targetings,
  isSlotTargeting = true,
  slotName,
  handleSave,
  isModifyingTargeting,
  setIsModifyingTargeting,
  isAddingTargeting,
  onAddTarget
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { customTargets } = useSelector(state => state.dfp)
  const [customTargetings, setCustomTargetings] = useState()
  const [targetingsWithKeys, setTargetingsWithKeys] = useState(true)
  const [expanded, setExpanded] = useState(true)
  const [nameChange, setNameChange] = useState(null)

  const handleChange = (event, customTargeting) => {
    // find the index of object from array that you want to update
    const selectedTargetingIndex = customTargetings.findIndex(
      _targeting => _targeting.id === customTargeting.id
    )

    // update Targeting status
    const updatedSelectedTargeting = customTargetings
      .filter(_targeting => _targeting.id === customTargeting.id)
      .map(targeting => ({
        ...targeting,
        selectedValues: event.target.value
      }))

    // make final new array of objects by combining updated object.
    const updatedTargetings = [
      ...customTargetings.slice(0, selectedTargetingIndex),
      updatedSelectedTargeting[0],
      ...customTargetings.slice(selectedTargetingIndex + 1)
    ]
    setCustomTargetings(updatedTargetings)
    handleTargetingSave(updatedTargetings)
  }

  const handleTargetingSave = updatedTargetings => {
    const bulkTargetings = updatedTargetings
      .filter(customTargeting => customTargeting.selectedValues.length > 0)
      .map(_customTargeting => {
        const container = {
          key: _customTargeting.name,
          values: _customTargeting.selectedValues
        }
        return container
      })
    handleSave(bulkTargetings)
  }

  const doEditTarget = (customTargeting) => {
    console.log('[CustomTargetingListBeta] ========== doEditTarget::customTargeting', customTargeting)
    console.log('[CustomTargetingListBeta] ========== doEditTarget::isModifyingTargeting', isModifyingTargeting)
    console.log('[CustomTargetingListBeta] ========== doEditTarget::calling setIsModifyingTargeting', !isModifyingTargeting)
    setIsModifyingTargeting(!isModifyingTargeting)
  }

  useEffect(() => {
    console.log('[CustomTaregtingBeta] ========== useEffect[customTargets, targetings, slotName]', { customTargets, targetings, slotName })
    if (customTargets.length > 0) {
      let _customTargets = customTargets
        .slice()
        .filter(_targeting => isSlotTargeting || _targeting.name !== 'pos') // for registryLevel, hide "pos"
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          return 0
        })
        .map(customTarget => ({
          ...customTarget,
          selectedValues: []
        }))
      if (isNew && targetings?.length === 1) {
        _customTargets = _customTargets.map(targetingItem => {
          if (targetingItem.name === 'pos') {
            return {
              ...targetingItem,
              selectedValues: [{ name: slotName?.split(/_(.+)/)[1] }]
            }
          } else {
            return targetingItem
          }
        })
        setCustomTargetings(_customTargets)
      } else {
        let updatedTargeting = _customTargets.slice()
        const currentTartetingGroup = targetings?.reduce(function (r,a) {
          r[a.key] = r[a.key] || []
          r[a.key].push(a.key === 'pos' ? slotName?.split(/_(.+)/)[1] : a.value)
          return r
        }, {})
        const currentTartetingKeyValue = []
        for (const targetingName in currentTartetingGroup) {
          currentTartetingKeyValue.push({
            key: targetingName,
            values:
              targetingName === 'pos'
                ? [slotName?.split(/_(.+)/)[1]]
                : currentTartetingGroup[targetingName]
          })
        }
        currentTartetingKeyValue.forEach((targeting, i) => {
          const selectedTargetingIndex = _customTargets?.findIndex(
            _targeting => _targeting.name === targeting.key
          )
          // update Targeting status
          const updatedSelectedTargeting = _customTargets
            ?.filter(_targeting => _targeting.name === targeting.key)
            .map(_targeting => ({
              ..._targeting,
              selectedValues:
                _targeting.name === 'pos'
                  ? targeting.values
                  : _targeting.values.filter(f => targeting.values.includes(f.name))
            }))
          updatedTargeting = [
            ...updatedTargeting.slice(0, selectedTargetingIndex),
            updatedSelectedTargeting[0],
            ...updatedTargeting.slice(selectedTargetingIndex + 1)
          ]
        })
        setCustomTargetings(updatedTargeting)
      }
    } else {
      dispatch(getAllCustomTargets(true))
    }
  }, [customTargets, targetings, slotName])

  useEffect(() => {
    setNameChange(slotName)
  }, [slotName])

  useEffect(() => {
    if (isNew && currentTargetings?.length === 1) {
      handleTargetingSave(customTargetings)
    }
  }, [nameChange])

  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, isSlotTargeting ? commonClasses.clrSlotHeader : commonClasses.clrRegistryHeader)} titleTypographyProps={{ variant: 'h6' }} title={`${slotName ? slotName : 'Page'} Targeting`}/>
      <CardContent>
        <Grid container className={commonClasses.m_0}>
          <Grid item xs={12} sm={12}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '30%', fontWeight: 'bold' }}>
                    Target Key
                  </TableCell>
                  <TableCell style={{ width: '55%', fontWeight: 'bold' }}>
                    Values
                  </TableCell>
                  <TableCell style={{ width: '15%'}}>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customTargetings && customTargetings.length > 0
                  ? (
                      customTargetings.map((customTargeting, i) => {
                        if (customTargeting?.selectedValues?.length > 0) {
                          return (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={customTargeting.id || i}
                              style={{
                                verticalAlign: 'top'
                              }}
                            >
                              <TableCell>
                                <div className={classes.plainText}>
                                  {customTargeting.displayName}
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className={classes.chips}>
                                  {customTargeting.selectedValues?.map((value, i) => (
                                    <Chip
                                      key={value.id || i}
                                      label={value.name}                                        
                                      className={addClasses(commonClasses.clrContrast, commonClasses.mr_2)}
                                    />
                                  ))}
                                </div>
                              </TableCell>
                              <TableCell>
                                <ButtonGroup>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    aria-label='Edit'
                                    title='Edit'
                                    className={addClasses(commonClasses.btnSuccess, commonClasses.mb_1)}
                                    onClick={() => { doEditTarget(customTargeting)}}
                                  >
                                    <EditIcon size={24} />
                                  </Button>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    aria-label='Remove'
                                    title='Remove'
                                    className={addClasses(commonClasses.btnDanger, commonClasses.mb_1)}
                                  >
                                    <DeleteIcon size={24} />
                                  </Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          )
                        } else {
                          return <></>
                        }
                      })
                    )
                  : (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                    )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={commonClasses.panelFooter} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='contained'
          size="small"
          title='Add New Target'
          className={addClasses(
            commonClasses.btnInfo,
            commonClasses.mr_1
          )}
          onClick={() => {
            onAddTarget(true)
          }}
          disabled={isAddingTargeting}
        >
          Add New Target
        </Button>
      </CardActions>
    </Card>
  )
}
