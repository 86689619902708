import React from 'react'

import { Card, CardContent, FormHelperText, Input, InputAdornment, Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core'

export const InViewRefresh = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { IVR } = options
  const { DEFAULT_DISPLAY_MILLISECONDS, DEFAULT_DISPLAY_PERCENT, DEFAULT_REFRESH_COUNT = 5 } = IVR
  const { NUMBER } = validation
  const { REFRESH, PERCENTAGE, REFRESH_COUNT } = NUMBER

  const displayPropertyTable = () => {
    return (
      <Table size='small' aria-label='IVR-property-table'>
        <TableBody>
        <TableRow>
            <TableCell style={{ width: '240px' }}><Typography variant='h5'>Ad Refresh Count</Typography></TableCell>
            <TableCell>
              <Input
                style={{ minWidth: '80px' }}
                margin='none'
                inputProps={{
                  type: 'number',
                  min: REFRESH_COUNT.min,
                  max: REFRESH_COUNT.max
                }}
                value={DEFAULT_REFRESH_COUNT === '' ? '' : DEFAULT_REFRESH_COUNT}
                error={errors.IVR_DRC}
                endAdornment={<InputAdornment position='end'>times</InputAdornment>}
                onChange={(event) => {
                  const value = event.target.value
                  IVR.DEFAULT_REFRESH_COUNT = value === '' ? '' : value
                  setErrors({ ...errors, IVR_DRC: (value < REFRESH_COUNT.min) || (value > REFRESH_COUNT.max) })
                  setOptions({ ...options, IVR })
                  setAllOptions({ ...allOptions, IVR })
                }}
              />
              <FormHelperText>{errors.IVR_DRC && REFRESH_COUNT.message}</FormHelperText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '240px' }}><Typography variant='h5'>Ad Refresh Interval</Typography></TableCell>
            <TableCell>
              <Input
                style={{ minWidth: '80px' }}
                margin='none'
                inputProps={{
                  type: 'number',
                  min: REFRESH.min,
                  max: REFRESH.max
                }}
                value={DEFAULT_DISPLAY_MILLISECONDS === '' ? '' : DEFAULT_DISPLAY_MILLISECONDS / 1000}
                error={errors.IVR_DDM}
                endAdornment={<InputAdornment position='end'>seconds</InputAdornment>}
                onChange={(event) => {
                  const value = event.target.value
                  IVR.DEFAULT_DISPLAY_MILLISECONDS = value === '' ? '' : value * 1000
                  setErrors({ ...errors, IVR_DDM: (value < REFRESH.min) || (value > REFRESH.max) })
                  setOptions({ ...options, IVR })
                  setAllOptions({ ...allOptions, IVR })
                }}
              />
              <FormHelperText>{errors.IVR_DDM && REFRESH.message}</FormHelperText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: '240px' }}><Typography variant='h5'>Ad Display Percentage</Typography></TableCell>
            <TableCell>
              <Input
                style={{ minWidth: '50px' }}
                margin='none'
                inputProps={{
                  type: 'number',
                  min: PERCENTAGE.min,
                  max: PERCENTAGE.max
                }}
                value={DEFAULT_DISPLAY_PERCENT}
                error={errors.IVR_DDP}
                endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                onChange={(event) => {
                  const value = event.target.value
                  IVR.DEFAULT_DISPLAY_PERCENT = value
                  setErrors({ ...errors, IVR_DDP: value < PERCENTAGE.min || value > PERCENTAGE.max })
                  setOptions({ ...options, IVR })
                  setAllOptions({ ...allOptions, IVR })
                }}
              />
              <FormHelperText>{errors.IVR_DDP && PERCENTAGE.message}</FormHelperText>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <Card>
      <CardContent>
        {displayPropertyTable()}
      </CardContent>
    </Card>
  )
}
