/* eslint-disable react/prop-types */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { set, useForm } from 'react-hook-form'
import { addClasses, commonStyle } from '../../styles'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  InputLabel,
  Box,
  FormGroup,
  Button,
  FormHelperText,
  CardActions,
  Typography,
  Card,
  CardContent,
  Chip,
  Avatar,
  Divider,
  CardHeader,
  Checkbox
} from '@material-ui/core'
import {
  ToggleIsChange
} from '../../modules/slotSlice'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { number as yupNumber, object as yupobject } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const swal = withReactContent(Swal)

const useStyle = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 170
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
  itemRight: { textAlign: 'right', float: 'right' },
  itemCenter: { textAlign: 'Center' },
  Targetings: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(2)
  },
  ResponsiveContaner: { width: '98%' },
  action: {
    position: 'absolute',
    bottom: theme.spacing(0),
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    background: theme.palette.background.paper,
    width: '98%'
  },
  box: { textAlign: 'right', width: '100%', padding: '8px 16px' }
}))

const SizeGroup = ({title, adUnitSizes, standardGroupSizes, selectedSizes, setSelectedSizes}) => {
  const commonClasses = commonStyle()
  const classes = useStyle()
  const [sel, setSel] = useState(new Set())

  const handleClick = (size, event, type) => {
    console.log('[SlotSizes] ========== SizeGroup::handleClick::size/event: ', { type, size, event, has: selectedSizes.has(size), selectedSizes })
    if (selectedSizes.has(size)) {
      selectedSizes.delete(size)
      console.log('[SlotSizes] ========== SizeGroup::handleClick::hasSize', { size, selectedSizes })
      setSelectedSizes(selectedSizes)
    } else {
      selectedSizes.add(size)
      console.log('[SlotSizes] ========== SizeGroup::handleClick::NOT hasSize', { size, selectedSizes })
      setSelectedSizes(selectedSizes)
    }    
  }

  useEffect(() => {
    setSel(selectedSizes)
  }, [selectedSizes])

  console.log('[SlotSizes] ========== SizeGroup::render::selected', { title, adUnitSizes, selectedSizes })

  return (
    <CardContent className={commonClasses.p_1}>
      <Typography className={classes.title} color="textSecondary" gutterButtom>
        {title}
      </Typography>          
      <Divider className={commonClasses.mb_1}/>
      <Grid container spacing={1}>
      {standardGroupSizes.map(size=>(
        <Grid item>
          <Checkbox size={'small'} defaultChecked={sel?.has(size)} style={{marginLeft: '10px', width: '16px', height: '16px', borderRadius: '25px'}} id={`${size}_checkbox`} onClick={(event) => { handleClick(size, event, 'checkbox') }}/>
          <Typography style={{display: 'inline-block', marginLeft: '5px', width: '60px'}} variant={'p'}>{size}</Typography>
        </Grid>
      ))}
      </Grid>          
    </CardContent>
  )
}

const SlotSizes = ({ data, registry }) => {
  console.log('[SlotSizes] ========== render:initial state', { slot: data, registry })
  const [slot, setSlot] = useState(data)
  const classes = useStyle()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { site } = useSelector(state => state.sitesSlice)
  const [adUnitSizesUI, setAdUnitSizesUI] = useState([])
  const [currentRegistry, setCurrentRegistry] = useState()
  const { adUnitDictionary, loading, isChanged } = useSelector(state => state.slotSlice)
  const [originalSlot, setOriginalSlot] = useState()
  const [selectedSizes, setSelectedSizes] = useState(new Set([]))

  const validateAdUnitSizes = () => {
    const values = getValues()
    const msg = 'Ad Sizes are required.'
    if (!slot.isFluid) {
      if (
        typeof values.adUnitSizes === 'undefined' ||
        values.adUnitSizes === undefined
      ) {
        return msg
      } else if (typeof values.adUnitSizes === 'object') {
        return values.adUnitSizes.filter(v => Boolean(v)).length >= 1 || msg
      }
    } else {
      return true
    }
  }

  const validationSchema = yupobject().shape({
    slotViewRefreshCount: yupNumber()
      .typeError('Count must be a number')
      .required('Required')
      .min(5, 'Min value 5'),
    slotViewRefreshInterval: yupNumber()
      .typeError('Interval must be a number')
      .required('Required')
      .min(35, 'Min value 35')
  })

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    unregister,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const handleAdSizesChange = event => {
    if (event.target.checked) {
      const selectedAdUnitSize = adUnitSizesUI.find(
        adUnitSize => adUnitSize.name === event.target.value
      )
      selectedAdUnitSize.checked = !event.target.checked
      let _adUnitSizesUISelected = [...adUnitSizesUI.filter(adUnitSize => adUnitSize.checked), selectedAdUnitSize]

      setSlot({
        ...slot,
        adSizes: _adUnitSizesUISelected,
      })
    } else {
      const adUnitSize = viewports[0].adSizes.filter(
        adUnitSize => adUnitSize.name !== event.target.value
      )
      setSlot({
        ...slot,
        adSizes: adUnitSize,
      })
    }

    // find the index of object from array that you want to update
    const selectedAdSizeIndex = adUnitSizesUI.findIndex(
      adUnitSize => adUnitSize.name === event.target.value
    )

    // make new object of updated object.
    const updatedViewport = {
      ...adUnitSizesUI[selectedAdSizeIndex],
      checked: event.target.checked
    }

    // make final new array of objects by combining updated object.
    const updatedAdUnitSizesUI = [
      ...adUnitSizesUI.slice(0, selectedAdSizeIndex),
      updatedViewport,
      ...adUnitSizesUI.slice(selectedAdSizeIndex + 1)
    ]
    console.log('[SlotSizes] ========== handleAdSizesChange::Setting AdUnitSizesUI: ', updatedAdUnitSizesUI)
    console.log('[SlotSizes] ========== handleAdSizesChange::Checked Sizes: ', updatedAdUnitSizesUI.filter(adUnitSize => adUnitSize.checked))
    setAdUnitSizesUI(updatedAdUnitSizesUI)
    const adName = event.target.name.slice()
    setValue(
      adName,
      updatedAdUnitSizesUI.filter(adUnitSize => adUnitSize.checked),
      { shouldValidate: true }
    )
  }

  const sizeGroups = (adUnitSizesUI) => {
    const standardBannerSizes = [
      '728x90','970x66','970x90','970x250','982x300','1100x90','1100x300'
    ]
    const standardRectangleSizes = [
      '300x250','300x359','300x600','300x850','300x1050'
    ]
    const mobileSizes = [
      '200x60','300x25','300x25','300x100','318x318','320x35','320x320','322x322'
    ]
    const otherSizes = [
      'fluid', '1x1', '1x2', '2x2', '300x251','300x252','300x253','300x601','300x602','300x603','728x91','970x91'
    ]
    return (
      <Grid container spacing={2} alignContent='stretch' justifyContent={'center'}>
        <Grid item md={6} xs={12}><SizeGroup title={'Banner'} adUnitSizesUI={[]} standardGroupSizes={standardBannerSizes} selectedSizes={selectedSizes} setSelectedSizes={setSelectedSizes} /></Grid>
        <Grid item md={6} xs={12}><SizeGroup title={'Rectangle'} adUnitSizesUI={[]} standardGroupSizes={standardRectangleSizes} selectedSizes={selectedSizes} setSelectedSizes={setSelectedSizes} /></Grid>
        <Grid item md={6} xs={12}><SizeGroup title={'Mobile'} adUnitSizesUI={[]} standardGroupSizes={mobileSizes} selectedSizes={selectedSizes} setSelectedSizes={setSelectedSizes} /></Grid>
        <Grid item md={6} xs={12}><SizeGroup title={'Other'} adUnitSizesUI={[]} standardGroupSizes={otherSizes} selectselectedSizesed={selectedSizes} setSelectedSizes={setSelectedSizes} /></Grid>
      </Grid>
    )
  }

  useEffect(() => {
    console.log('[SlotSizes] ========== useEffect[data]:Setting slot:', { slot: data })
    setSlot(data)
  }, [data])

  useEffect(() => {
    setCurrentRegistry(registry)
  }, [registry])

  useEffect(() => {
    if (site && slot) {
      const rootAdUnit = adUnitDictionary[site.rootAdUnitId]
      let adUnit = rootAdUnit
      const adUnitPathNames = slot.adUnitPath?.split('/') || []
      // loop to find selected last Child in Ad Unit
      for (let index = 1; index < adUnitPathNames.length; index++) {
        if (!adUnit.hasChildren) {
          break
        }
        adUnit = adUnit.children.find(
          child => child.name === adUnitPathNames[index]
        )
        if (!adUnit) {
          break
        }
      }
      console.log('[SlotSizes] ========== useEffect[]::slot: ', slot)
      let adSizes = []
      if ((slot.isFluid && (slot.adSizes && slot.adSizes.length === 0)) || !slot.adSizes) {
      // if (slot.isFluid || (!slot.adSizes || slot.adSizes === null || typeof slot.adSizes === 'undefined' || slot.adSizes.length === 0)) {
        adSizes = adUnit?.adUnitSizes?.slice().map(item => ({
          ...item,
          checked: false,
          name: item.width + 'x' + item.height
        }))
      } else {
      // } else if (slot.adSizes.length > 0) {
        adSizes = adUnit?.adUnitSizes?.slice().map(item => ({
          ...item,
          checked: slot.adSizes.some(
            obj => obj.width + 'x' + obj.height === item.name
          ),
          name: item.width + 'x' + item.height
        }))
      }
      console.log('[SlotSizes] ========== useEffect[]::adSizes : ', adSizes)
      setAdUnitSizesUI(adSizes)
    }
  }, [])

  useEffect(() => {
    dispatch(
      ToggleIsChange(JSON.stringify(slot) !== JSON.stringify(originalSlot))
    )
  }, [slot])

  return (
    <Grid container className={commonClasses.m_1}>
      <Grid item xs={12}>
        {/* <Typography variant={'h6'} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3)} style={{color: 'black'}}>Ad Sizes</Typography> */}
        <Box
          border={errors.adUnitSizes ? 1 : 0}
          borderColor={errors.adUnitSizes ? 'error.main' : ''}
        >
          <FormGroup aria-label='adUnitSizes' row>
            {adUnitSizesUI?.length > 0 ? sizeGroups() : ""}
            {/* {adUnitSizesUI?.length > 0 ? (
              adUnitSizesUI.map((adUnitSize, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      {...register('adUnitSizes', {
                        validate: validateAdUnitSizes
                      })}
                      checked={adUnitSize.checked}
                      value={
                        adUnitSize.width + 'x' + adUnitSize.height
                      }
                      onChange={e => {
                        handleAdSizesChange(e)
                      }}
                      color='primary'
                    />
                  }
                  label={
                    adUnitSize.width + 'x' + adUnitSize.height
                  }
                />
              ))
            ) : (
              <></>
            )} */}
          </FormGroup>
          {!!errors.adUnitSizes && (
            <FormHelperText error={!!errors.adUnitSizes}>
              Ad Sizes are required.
            </FormHelperText>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default SlotSizes