import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  siteNameFormatter,
  isObjectEquivalent,
  registryNameFormatter
} from '../../utilities/helperFunctions'
import { fetchRocketeerSites, fetchSiteIVRConfiguration, fetchSiteRTAIConfiguration, ToggleIsAdFuelVersionSelected } from '../../modules/sitesSlice'
import { fetchAdFuelVersions } from '../../modules/adFuelSlice'
import {
  fetchRegistriesForSite,
  updateSiteRegistry,
  deploySiteRegistry,
  ToggleInViewRefresh,
  ToggleRealTimeAdInsertion,
  ToggleSafeframeConfig,
  ToggleCustomTargetingDialog
} from '../../modules/registrySlice'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  string as yupstring,
  number as yupNumber,
  object as yupobject
} from 'yup'
import { useHistory, NavLink } from 'react-router-dom'
import { getPath } from '../../modules/configSlice'
import {
  Box,
  Card,
  Container,
  Breadcrumbs,
  makeStyles,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
} from '@material-ui/core'
import { CustomTargetingListBeta, CustomTargetingDetailsBeta } from '../targeting'
import { RegistrySiteDetails, RegistryDetails, RegistryProperties} from './index.js'
import { SlotsBeta, SlotList, SlotDetails, SlotPage, SlotDetails2 } from '../slots'
import { Notes } from '../sites'
import { LoadingProgress } from '../ui'
import { addClasses, commonStyle } from '../../styles'

const useStyle = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300
  },
  ConfigurationItemContainer: {
    paddingTop: '4px'
  },
  ConfigurationItems: {
    margin: '2px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '95%',
    border: 'none',
    boxShadow: 'none'  },
  Targetings: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    '& > * + *': {
      marginLeft: theme.spacing(2)      
    },
    fontSize: '.9em'
  },
  smallFont: {
    fontSize: '.8rem',
    opacity: 0.8
  },
  maxWidth: {
    width: '100%'
  },
  divider: {
    height: 28,
    margin: 4
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder'
  },
}))

const RegistryBeta = () => {
  const classes = useStyle()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { site, isAdFuelVersionSelected, siteIVR, siteRTAI } = useSelector(state => state.sitesSlice)
  const history = useHistory()
  const {
    registry,
    isDeploying,
    loading,
    inViewRefresh,
    realTimeAdInsertion,
    hasSafeFrameConfig
  } = useSelector(state => state.registrySlice)
  const { adFuelVersions } = useSelector(state => state.adFuelSlice)
  const [edit, setEdit] = useState(false)
  const [currentRegistry, setCurrentRegistry] = useState([])
  const [originalRegistry, setOriginalRegistry] = useState('')
  const registryName = currentRegistry.name ? currentRegistry.name : 'Registry'
  const [isChanged, setIsChanged] = useState(false)
  const { pathConfig } = useSelector(state => state.configSlice)
  const [publishers, setPublishers] = useState()
  const [clickCount, setClickCount] = useState(0)
  const [loadingPage, setLoadingPage] = useState(true)
  const [selectedSlot, setSelectedSlot] = useState()
  const [isModifyingTargeting, setIsModifyingTargeting] = useState(false)
  const [isAddingTargeting, setIsAddingTargeting] = useState(false)
  const [registryNotes, setRegistryNotes] = useState([])

  const validationSchema = yupobject().shape({
    registryDescription: yupstring().required('Required'),
    registryInViewRefreshCount: yupNumber()
      .typeError('Count must be a number')
      .required('Required')
      .min(5, 'Min value 5'),
    registryInViewRefreshInterval: yupNumber()
      .typeError('Interval must be a number')
      .required('Required')
      .min(35, 'Min value 35')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  // Update Registry
  const updateRegistry = () => {
    dispatch(updateSiteRegistry(currentRegistry))
  }

  const [betaTest] = useState(
    window.localStorage.getItem('beta') === 'true'
  )
  
  const handleCustomTargetingForRegistrySave = bulkTargetings => {
    if (bulkTargetings) {
      const finalTargeting = []
      for (let y = 0; y < currentRegistry.targetings.length; y++) {
        const target = currentRegistry.targetings[y]
        if (
          Array.isArray(bulkTargetings[target.key]) &&
          bulkTargetings[target.key].length > 0
        ) {
          if (Array.isArray(target.value)) {
            for (let z = 0; z < target.value.length; z++) {
              bulkTargetings[target.key].push(target.value[z])
            }
          } else {
            bulkTargetings[target.key].push(target.value)
          }
        } else {
          if (Array.isArray(target.value)) {
            bulkTargetings[target.key] = target.value
          } else {
            bulkTargetings[target.key] = [target.value]
          }
        }
      }
      bulkTargetings.forEach(bulkTarget => {
        if (bulkTarget.key !== undefined) {
          bulkTarget.values.forEach(value => {
            const targetingObject = {
              key: bulkTarget.key,
              value: value.name
            }
            finalTargeting.push(targetingObject)
          })
        }
      })
      setCurrentRegistry({ ...currentRegistry, targetings: finalTargeting })
    }
    dispatch(ToggleCustomTargetingDialog())
  }

  const onDeploySiteRegistries = () => {
    dispatch(deploySiteRegistry(currentRegistry._id))
  }

  const onViewHistory = () => {
    history.push(
      `/sites/${siteNameFormatter(
        site.name
      )}/registries/${registryNameFormatter(registryName)}/history`
    )
  }

  const handleRegistryChange = e => {
    switch (e.target.name) {
      case 'registryName':
        setCurrentRegistry({
          ...currentRegistry,
          name: e.target.value
        })
        setValue('registryName', e.target.value, {
          shouldValidate: true
        })
        break
      case 'registryDescription':
        setCurrentRegistry({
          ...currentRegistry,
          description: e.target.value
        })
        setValue('registryDescription', e.target.value, {
          shouldValidate: true
        })
        break
      case 'registrySingleton':
        setCurrentRegistry({
          ...currentRegistry,
          isSingleton: e.target.checked
        })
        break
      case 'registryTagChildDirectedTreatment':
        setCurrentRegistry({
          ...currentRegistry,
          tagForChildDirectedTreatment: e.target.checked
        })
        break
      case 'registryInViewRefresh':
        setCurrentRegistry({
          ...currentRegistry,
          hasInViewRefresh: e.target.checked
        })
        toggleInViewRefresh(e.target.checked)
        break
      case 'registryRealTimeAdInsertion':
        setCurrentRegistry({
          ...currentRegistry,
          hasRealTimeAdInsertion: e.target.checked
        })
        toggleRealTimeAdInsertion(e.target.checked)
        break
      case 'registryRTAIMaxNoOfAds':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiMaxNoOfAds: e.target.value
        })
        break
      case 'registryRTAIBuffer':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiBuffer: e.target.value
        })
        break
      case 'registryRTAIOffset':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiOffset: e.target.value
        })
        break  
      case 'registryRTAIInitialPlacement':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiInitialPlacement: e.target.value
        })
        break
      case 'registryRTAIParentSelector':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiParentSelector: e.target.value
        })
        break  
      case 'registryInViewRefreshCount':
        setCurrentRegistry({
          ...currentRegistry,
          inViewRefreshCount: e.target.value
        })
        setValue('registryInViewRefreshCount', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'registryInViewDisplayPercentage':
        setCurrentRegistry({
          ...currentRegistry,
          inViewDisplayPercentage: e.target.value          
        })
        setValue('registryInViewDisplayPercentage', parseInt(e.target.value), {
          shouldValidate: true
        })
        break        
      case 'registryInViewRefreshInterval':
        setCurrentRegistry({
          ...currentRegistry,
          inViewRefreshInterval: e.target.value
        })
        setValue('registryInViewRefreshInterval', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'registrySafeframeConfig':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameConfig: e.target.checked
        })
        toggleSafeframeConfig(e.target.checked)
        break
      case 'registrySandbox':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameSandboxMode: e.target.checked
        })
        break
      case 'registryAllowOverlayExpansion':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameAllowOverlayExpansion: e.target.checked
        })
        break
      case 'registryAllowPushExpansion':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameAllowPushExpansion: e.target.checked
        })
        break
      default:
        break
    }
    setRegistryNotes(currentRegistry.history
      .map(item => item.area === 'registry - added comment' ? item : null)
      .filter(item => item !== null) || [])    
  }

  const toggleInViewRefresh = e => {
    dispatch(ToggleInViewRefresh(e))
  }

  const toggleRealTimeAdInsertion = e => {
    dispatch(ToggleRealTimeAdInsertion(e))
  }

  const toggleSafeframeConfig = e => {
    dispatch(ToggleSafeframeConfig(e))
  }

  const siteHasModule = moduleName => {
    let hasModule = false
    if (site && site.adFuelModules) {
      site.adFuelModules.forEach(afModule => {
        if (afModule.name.indexOf(moduleName) >= 0) hasModule = true
      })
    }
    return hasModule
  }

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      setPublishers(pathConfig.publisher)
    } else {
      dispatch(getPath())
    }
  }, [pathConfig])

  useEffect(() => {
    if (!site._id) {
      dispatch(fetchRocketeerSites())
    }
  }, [dispatch, site])

  useEffect(() => {
    if (site._id) {
      dispatch(fetchRegistriesForSite(site._id, true))
      console.log('Fetching Site IVR Configuration...')
      dispatch(fetchSiteIVRConfiguration(site._id))
      dispatch(fetchSiteRTAIConfiguration(site._id))
    }
  }, [site, dispatch])

  useEffect(() => {
    console.log('Setting IVR Values...')
    setValue(
      'registryInViewRefreshCount',
      parseInt(currentRegistry.inViewRefreshCount) === 0
        ? (parseInt(siteIVR.DEFAULT_REFRESH_COUNT, 10) || 6)
        : parseInt(currentRegistry.inViewRefreshCount, 10),
      {
        shouldValidate: true
      }
    )
    setValue(
      'registryInViewRefreshInterval',
      parseInt(currentRegistry.inViewRefreshInterval) === 0
        ? (parseInt(siteIVR.DEFAULT_DISPLAY_MILLISECONDS/1000, 10) || 35)
        : parseInt(currentRegistry.inViewRefreshInterval/1000, 10),
      {
        shouldValidate: true
      }
    )
    setValue(
      'registryInViewDisplayPercentage',
      parseInt(currentRegistry.inViewDisplayPercentage) === 0
        ? (parseInt(siteIVR.DEFAULT_DISPLAY_PERCENT, 10) || 25)
        : parseInt(currentRegistry.inViewDisplayPercentage, 10),
      {
        shouldValidate: true
      }
    )
    setRegistryNotes(currentRegistry.history ? currentRegistry.history
      .map(item => item.area === 'registry - added comment' ? item : null)
      .filter(item => item !== null) : [])    
    console.log('=========== current registry: ', JSON.parse(JSON.stringify(currentRegistry), null, 2))
    setCurrentRegistry({
      ...currentRegistry
    })
  }, [siteIVR])

  useEffect(() => {
    if (isObjectEquivalent(currentRegistry, originalRegistry)) {
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
  }, [currentRegistry, originalRegistry])

  useEffect(() => {
    if (registry) {
      setCurrentRegistry(registry)
      setOriginalRegistry(registry)
      toggleSafeframeConfig(registry?.safeFrameConfig)
      toggleInViewRefresh(registry?.hasInViewRefresh)
      toggleRealTimeAdInsertion(registry?.hasRealTimeAdInsertion)
      setValue('registryDescription', registry.description, {
        shouldValidate: true
      })
      setRegistryNotes(currentRegistry.history ? currentRegistry.history
        .map(item => item.area === 'registry - added comment' ? item : null)
        .filter(item => item !== null) : [])    
    }
  }, [registry, clickCount])

  useEffect(() => {
    if (!loading) {
      setEdit(true)
    }
  }, [loading])

  useEffect(() => {
    if (adFuelVersions.length === 0) {
      dispatch(fetchAdFuelVersions())
    } else {
      const adFuelVersion = adFuelVersions.find(adfuel => adfuel._id === site.adFuelVersionId)
      dispatch(ToggleIsAdFuelVersionSelected(adFuelVersion !== undefined))
    }
  }, [adFuelVersions, site])

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false)
    }, 2000)
  }, [])
 
  console.log('Current Regsitry: ', currentRegistry)
  console.log('Site IVR: ', siteIVR)
  return (
    <div className='registry'>
      <Breadcrumbs
        className={addClasses(commonClasses.pl_3, commonClasses.pb_1, loadingPage ? (commonClasses.disabledlink, commonClasses.opacity50) : null)}
        aria-label='breadcrumb'
      >
        {/* <NavLink to={`/sites/${siteNameFormatter(site.name)}`}>
          {site.name ? site.name : 'Site'}
        </NavLink>
        <NavLink to={`/sites/${siteNameFormatter(site.name)}/registries`}>
          Registries
        </NavLink>
        <NavLink
          activeStyle={{
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
          to={`/sites/${siteNameFormatter(
            site.name
          )}/registries/${registryNameFormatter(registryName)}`}
        >
          {registryName}
        </NavLink> */}
        <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}`} disabled={loadingPage} onClick={loadingPage ? (event) => event.preventDefault() : null}>{site.name ? site.name : 'Site'} </NavLink>
        <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}/registries`} disabled={loadingPage} onClick={loadingPage ? (event) => event.preventDefault() : null}>Registries</NavLink>        
        <NavLink to={`/sites/${siteNameFormatter(site.name)}/registries/${registry.isSingleton ? 'singleton/' : 'registry/'}${registryNameFormatter(registryName)}`} disabled={loadingPage} onClick={loadingPage ? (event) => event.preventDefault() : null}>{registryName}</NavLink>
      </Breadcrumbs>
      <Container maxWidth='xl'>
        <form autoComplete='off' noValidate>
          {currentRegistry.name === undefined || loading
            ? (
              <LoadingProgress label='Loading Registry Information' />
              )
            : (
              <Grid container spacing={2}>                    
                <Grid item md={12} xl={2}>
                  <Box mb={2}>
                    <RegistrySiteDetails site={site} ivr={siteHasModule('In-View Refresh') ? siteIVR : false} rtai={siteHasModule('Real-Time Ad Insertion') ? siteRTAI : false} />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12} xl={5}>
                  <Box mb={2}>
                    <RegistryDetails canEditRegistry={true} registry={currentRegistry} site={site}/>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12} xl={5}>
                  <Box mb={2}>
                    <RegistryProperties canEditRegistry={true} registry={currentRegistry} site={site}/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Box mb={2}>
                    <Notes notes={registryNotes} data={currentRegistry} headerClass={commonClasses.clrRegistryHeader} />
                  </Box>
                </Grid>
                <Grid item sm={12} md={5}>
                  <Box mb={2}>
                    <CustomTargetingListBeta
                      isSlotTargeting={false}
                      targetings={currentRegistry.targetings}
                      setIsModifyingTargeting={setIsModifyingTargeting}
                      isModifyingTargeting={isModifyingTargeting}
                      isAddingTargeting={isAddingTargeting}
                      onAddTarget={setIsAddingTargeting}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} md={7}>
                  {
                    isModifyingTargeting || isAddingTargeting
                      ? (
                        <Box mb={1}>
                          <CustomTargetingDetailsBeta 
                            className={commonClasses.mb_1}  
                            isSlotTargeting={false} 
                            targeting={currentRegistry.targeting}
                            handleSave={() => {
                              setIsAddingTargeting(false)
                              setIsModifyingTargeting(false)
                            }} /> 
                        </Box>
                      )
                      : ''
                  }
                </Grid>
                <Grid item sm={12} md={5}>
                  <Box mb={2}>
                    <SlotsBeta selectedSlot={selectedSlot} setSelectedSlot={setSelectedSlot} />
                  </Box>
                </Grid>
                <Grid item sm={12} md={7} style={{height: '100%'}}>
                  { selectedSlot 
                    ? (
                      <Box mb={1}>
                        {/* <SlotPage data={selectedSlot} isSingleton={currentRegistry.isSingleton} /> */}
                        <SlotDetails className={commonClasses.mb_1} style={{height: '100%'}} data={selectedSlot} registry={registry} isSingleton={currentRegistry.isSingleton} />
                      </Box>
                    )
                    : ''
                  }
                </Grid>
              </Grid>
            )
          }
        </form>
      </Container>
    </div>
  )
}

export default RegistryBeta
