import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  lastLoginDates: [
    {
      option: 'Today',
      days: 1
    },
    {
      option: '1 week',
      days: 7
    },
    {
      option: '1 month',
      days: 30
    },
    {
      option: '2 months',
      days: 61
    },
    {
      option: '3 months',
      days: 92
    },
    {
      option: '1 year',
      days: 365
    },
    {
      option: 'All Time',
      days: 3000
    }
  ],
  item: {},
  error: null,
  loading: false
}

const emailUserSlice = createSlice({
  name: 'emailUser',
  initialState,
  reducers: {
    EmailUserLoadingStatus: state => {
      state.loading = true
    },

    SendMail: (state, action) => {
      state.loading = false
      state.item = action.payload
      if (action.payload === 'Successfully sent email(s).') {
        swal.fire({
          title: 'Email Send!',
          text: action.payload,
          icon: 'success'
        })
      } else {
        swal.fire({
          title: 'Email Send!',
          text: action.payload,
          icon: 'error'
        })
      }
    },

    EmailUserError: (state, action) => {
      state.loading = false
      state.error = action.payload.error
      handleError(action.payload, showError)
    }
  }
})

export default emailUserSlice.reducer
export const {
  EmailUserLoadingStatus,
  SendMail,
  EmailUserError
} = emailUserSlice.actions

export const sendMail = email => {
  return async dispatch => {
    dispatch(EmailUserLoadingStatus())
    return request('/email', 'POST', email, true)
      .then(res => dispatch(SendMail(res.data)))
      .catch(err => dispatch(EmailUserError(err)))
  }
}
