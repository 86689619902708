import React from 'react'
import { Typography, FormControlLabel, Grid, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const thumbSize = 20
const thumbBorder = 1
const thumbBorderTotal = 2 * thumbBorder
const containerWidth = 2 * (thumbSize + thumbBorderTotal)
const containerHeight = thumbSize + thumbBorderTotal
const thumbMoveBy = containerWidth - (thumbSize + thumbBorderTotal)

const IOSSwitch = makeStyles(theme => ({
  root: {
    width: containerWidth,
    height: containerHeight,
    padding: 0,
    margin: theme.spacing(1)
  },
  smallLabel: {
    fontSize: '.8rem',
    marginBottom: 0,
    paddingBottom: 0,
    opacity: 0.8
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: `translateX(${thumbMoveBy}px)`,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.light,
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.light,
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: thumbSize,
    height: thumbSize
  },
  track: {
    borderRadius: containerHeight / thumbBorderTotal,
    border: `${thumbBorder}px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {},
  inline: {
    display: 'inline-block',
    margin: '8px'
  },
  fullWidth: {
    width: '100%'
  }
}))

export const ToggleSwitch = ({
  label,
  onLabel,
  offLabel,
  checked,
  onChange,
  name,
  fullWidth = false,
  inLine = false,
  disabled = false,
  smallLabel = false
}) => {
  const classes = IOSSwitch()

  return (
    <div className={classes.inline}>
      {inLine
        ? (
          <Typography component='div' className={fullWidth ? classes.fullWidth : ''}>
            <Grid component='label' container alignItems='center'>
              <Grid item>{label}:&nbsp;</Grid>
              {/* <Grid item>{offLabel}</Grid> */}
              <Grid item>
                {disabled
                  ? (
                    <Switch
                      disabled
                      name={name}
                      focusVisibleClassName={classes.focusVisible}
                      disableRipple
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                      checked={checked}
                      onChange={onChange}
                    />
                    )
                  : (
                    <Switch
                      name={name}
                      focusVisibleClassName={classes.focusVisible}
                      disableRipple
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                      checked={checked}
                      onChange={onChange}
                    />
                    )}
              </Grid>
              {/* <Grid item>{onLabel}</Grid> */}
              <Grid item>
                {checked
                  ? (
                    <Typography>{onLabel}</Typography>
                    )
                  : (
                    <Typography>{offLabel}</Typography>
                    )}
              </Grid>
            </Grid>
          </Typography>
          )
        : (
          <div className={fullWidth ? classes.fullWidth : ''} >
            <Typography className={smallLabel ? classes.smallLabel : ''}>{label}</Typography>
            <FormControlLabel
              control={
                <Switch
                  name={name}
                  focusVisibleClassName={classes.focusVisible}
                  disableRipple
                  classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked
                  }}
                  checked={checked}
                  onChange={onChange}
                  disabled={disabled}
                />
            }
              label={checked ? onLabel : offLabel}
            />
          </div>
          )}
    </div>
  )
}
