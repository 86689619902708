import React from 'react'
import { Link as Anchor, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import packageJson from '../../../package.json'

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexShrink: 0
  }
}))

export default function Footer () {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Typography style={{ color: 'white' }}>
        v{packageJson.version} &copy; {new Date().getFullYear()}&emsp;
        <img width='150' src='/images/WBD_HorizontalLogo_OnDark_White.png' alt='WarnerMedia' style={{ paddingRight: '15px' }} />
      </Typography>
      <Typography>
        <Anchor style={{ color: 'white' }} target='_blank' rel='noreferrer' href='https://docsprod.turner.com/display/DIGADTECH/Rocketeer+v3+Users+Guide'>
          Rocketeer Users Guide
        </Anchor>
      </Typography>
    </footer>
  )
}
