import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, Paper, Card, CardHeader, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, TextField,  } from '@material-ui/core'
import { addClasses, commonStyle } from '../../../styles'
import { AddBox } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { addSiteComment } from '../../../modules/sitesSlice'
import { addSiteRegistryComment } from '../../../modules/registrySlice'

const Notes = ({ priorityOnly = false, notes, headerClass, data}) => {
  const commonClasses = commonStyle()
  const [comment, setComment] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [model, setModel] = useState(data)
  const [noteType, setNoteType] = useState('registry')
  const dispatch = useDispatch()
  useEffect(() => {
    setModel(data)
    if (data.categoryCodes) { setNoteType('site') }
  }, [data])

  let siteNotes = notes
  if (priorityOnly) {
    siteNotes = siteNotes.filter(item => item.severity > 0)
  }
  console.log('[Notes] ---------- render::siteNotes:', siteNotes)

  const validationSchema = yup.object().shape({
    comment: yup.string().required()
  })
  
  const { register, formState: { errors }, setValue, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: true
  })

  const AddNewSiteComment = () => {
    console.log('ADDING NEW SITE COMMENT !!!!!!!!!')
    console.log('Model: ', model)
    console.log('Comment: ', comment)
    dispatch(addSiteComment(model._id, comment))
    setOpenDialog(false)
  }

  const AddNewComment = () => {
    dispatch(addSiteRegistryComment(model._id, comment))
    setOpenDialog(false)
  }

  const displayDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth='md'
        onClose={() => setOpenDialog(false)}
        aria-labelledby={`comment`}
        open={openDialog}
      >
        <DialogTitle
          style={{borderRadius: '0', padding: 5}} 
          className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, headerClass || commonClasses.clrSiteHeader)}
          titleTypographyProps={{ variant: 'h6' }} 
          id='form-dialog-title'
        >
          Add Note
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormControl
                className={commonClasses.m_1}
                style={{ width: '100%' }}
              >
                <TextField
                  label={`comment`}
                  {...register('comment')}
                  margin='dense'
                  style={{ marginTop: 0 }}
                  defaultValue={comment}
                  variant='outlined'
                  onChange={e => {
                    setComment(e.target.value)
                    setValue('comment', e.target.value, { shouldValidate: true })
                  }}
                  multiline
                  rows={3}
                  error={!!errors.comment}
                  helperText={errors.comment ? errors.comment.message : ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className={commonClasses.btnSuccess}
            onClick={(e) => {
               if (noteType === 'site') { AddNewSiteComment() }
               else { AddNewComment() }
            }}
            variant='contained'
          >
            Save
          </Button>
          <Button
            className={commonClasses.ml_1}
            title='Cancel'
            variant='contained'
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const openNotesDialog = () => {
    setOpenDialog(true)
  }

  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader 
        style={{padding: 5}} 
        className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, headerClass || commonClasses.clrSiteHeader)}
        titleTypographyProps={{ variant: 'h6' }} 
        title={`${priorityOnly ? 'Important ' : ''}Comments`}
        action={
          <div className={addClasses(commonClasses.mt_1, commonClasses.mr_2)}>
            <IconButton
              aria-label="Add Note"
              variant="contained"
              size="small"
              style={{color: 'white'}}
              onClick={()=>{openNotesDialog()}}
            >
              <AddBox />
            </IconButton>
          </div>
        }        
      />
      <CardContent className={addClasses(commonClasses.pl_3, commonClasses.pr_3)}>
        {displayDialog()}
        <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="stretch" alignContent="stretch">
        {siteNotes?.map(note => {
          let newNote = { severity: 0, ...note}
          let itemBg = '#AAA'
          let itemBorder= '#333'
          let itemText = 'black'
          let itemFontWeight = 'normal'
          let itemFont = 'Times New Roman, Times, serif'
          let itemFontSize = '10pt'
          switch(newNote.severity) {
            case 0:
              itemBg = '#AAA'
              itemBorder = '#999'
              break
            case 1:
              itemBg = "goldenrod"
              itemBorder = 'darkgoldenrod'
              itemFontWeight = 'bold'
              break
            case 2:
              itemBg = 'darkred'
              itemBorder = 'darkRed'
              itemText = 'white'
              itemFontWeight = 'bolder'
              itemFont = 'Comic Sans'
              break
          }
          return (
            <Grid item>
              <Paper
                elevation={3}
                variant={'outlined'}
                square
                style={{border: `solid 1px ${itemBorder}`, padding: '10px', borderRadius: '10px', backgroundColor: itemBg}}
              >
                <p style={{fontSize: itemFontSize, fontFamily: itemFont, fontWeight: itemFontWeight, color: itemText}} dangerouslySetInnerHTML={{__html: newNote.newValue}}></p>
                <Grid container justifyContent='flex-end' alignItems='flex-end'>
                  <Grid item style={{fontFamily: itemFont, color: itemText}}>- {newNote.modifiedBy}</Grid>
                </Grid>
                <Grid container justifyContent='flex-end' alignItems='flex-end'>
                  <Grid item style={{fontFamily: itemFont, color: itemText}}> {new Date(newNote.modifiedOn).toLocaleDateString() + ' ' + new Date(newNote.modifiedOn).toLocaleTimeString()}</Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        })}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Notes