import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { commonStyle } from '../../../styles/commonStyle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { ToggleSwitch } from '../../ui/ToggleSwitch'
import { useSelector, useDispatch } from 'react-redux'
import { updateSiteRegistrySettings } from '../../../modules/registrySlice'
import { useForm } from 'react-hook-form'
import { number as yupNumber, object as yupobject } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 200
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  divider: {
    height: 28,
    margin: 4
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  }
}))

const InViewRefreshDialog = props => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const commonClasses = commonStyle()
  const { isUpdating } = useSelector(state => state.registrySlice)
  const [objForInViewRefresh, setObjForInViewRefresh] = useState({
    inViewRefresh: false,
    refreshCount: 0,
    refreshInterval: 0,
    displayPercentage: 0
  })

  const validationSchema = yupobject().shape({
    refreshCount: yupNumber()
      .typeError('Count must be a number')
      // .required('Required')
      .min(1, 'Min value 1'),
    refreshInterval: yupNumber()
      .typeError('Interval must be a number')
      // .required('Required')
      .min(35, 'Min value 35')
  })

  const {
    register,
    unregister,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  const updateRegistriesInViewRefresh = () => {
    // console.log(
    //   'updateRegistriesInViewRefresh',
    //   props.selectedRegistries,
    //   objForInViewRefresh
    // )
    dispatch(
      updateSiteRegistrySettings(
        props.selectedRegistries,
        null,
        objForInViewRefresh,
        null,
        null
      )
    )
  }

  const handleRadioGroupChange = e => {
    if (e.target.name === 'InViewRefresh') {
      // console.log('Setting IVR object: ', objForInViewRefresh, e.target.checked)
      setObjForInViewRefresh({
        ...objForInViewRefresh,
        inViewRefresh: e.target.checked
      })
    }
  }

  const handleTextFieldChange = e => {
    if (e.target.name === 'refreshCount') {
      setObjForInViewRefresh({
        ...objForInViewRefresh,
        refreshCount: parseInt(e.target.value)
      })
      setValue('refreshCount', parseInt(e.target.value), {
        shouldValidate: true
      })
    }
    if (e.target.name === 'refreshInterval') {
      setObjForInViewRefresh({
        ...objForInViewRefresh,
        refreshInterval: parseInt(e.target.value)
      })
      setValue('refreshInterval', parseInt(e.target.value), {
        shouldValidate: true
      })
    }
    if (e.target.name === 'displayPercentage') {
      setObjForInViewRefresh({
        ...objForInViewRefresh,
        displayPercentage: parseInt(e.target.value)
      })
      setValue('displayPercentage', parseInt(e.target.value), {
        shouldValidate: true
      })
    }
  }

  const handleDialogClose = () => {
    props.handleDialogClose()
  }

  useEffect(() => {
    if (!objForInViewRefresh.inViewRefresh) {
      unregister()
    } else {
      register('refreshCount')
      register('refreshInterval')
      register('displayPercentage')
      setValue('refreshCount', parseInt(objForInViewRefresh.refreshCount), {
        shouldValidate: true
      })
      setValue(
        'refreshInterval',
        parseInt(objForInViewRefresh.refreshInterval),
        {
          shouldValidate: true
        }
      )
      setValue('displayPercentage', parseInt(objForInViewRefresh.displayPercentage), {
        shouldValidate: true
      })
    }
  }, [objForInViewRefresh])

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby='customized-dialog-title'
      open={props.isDialogOpen}
      maxWidth='md'
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle id='form-dialog-title'>
        {props.siteName} - Bulk In-View Refresh
      </DialogTitle>
      <DialogContent dividers>
        <Grid container className={commonClasses.m_1}>
          <Grid item xs={4} sm={4}>
            <ToggleSwitch
              label='InView Refresh'
              name='InViewRefresh'
              offLabel='Disabled'
              onLabel='Enabled'
              checked={!!objForInViewRefresh.inViewRefresh}
              onChange={e => handleRadioGroupChange(e)}
              value={objForInViewRefresh.inViewRefresh}
            />
          </Grid>
          <Grid item md={4} xs={4}>
            {objForInViewRefresh.inViewRefresh
              ? (
                <>
                  <Paper className={classes.root}>
                    <TextField
                      {...register('refreshCount')}
                      className={classes.input}
                      label='Refresh Count'
                      margin='dense'
                      id='refreshCount'
                      variant='standard'
                      type='number'
                      InputProps={{
                        inputProps: { min: '5', step: '1' }
                      }}
                      defaultValue={objForInViewRefresh.refreshCount}
                      onChange={e => handleTextFieldChange(e)}
                      error={!!errors.refreshCount}
                      helperText={
                        errors.refreshCount ? errors.refreshCount.message : ''
                      }
                    />
                    <Divider className={classes.divider} orientation='vertical' />
                    times
                  </Paper>
                </>
                )
              : (
                <></>
                )}
          </Grid>
          <Grid item md={4} xs={4}>
            {objForInViewRefresh.inViewRefresh
              ? (
                <>
                  <Paper className={classes.root}>
                    <TextField
                      {...register('refreshInterval')}
                      className={classes.input}
                      label='Refresh Interval'
                      margin='dense'
                      id='refreshInterval'
                      variant='standard'
                      type='number'
                      InputProps={{
                        inputProps: { min: '35', step: '1' }
                      }}
                      defaultValue={objForInViewRefresh.refreshInterval}
                      onChange={e => handleTextFieldChange(e)}
                      error={!!errors.refreshInterval}
                      helperText={
                        errors.refreshInterval
                          ? errors.refreshInterval.message
                          : ''
                      }
                    />
                    <Divider className={classes.divider} orientation='vertical' />
                    seconds
                  </Paper>
                </>
                )
              : (
                <></>
                )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.wrapper}>
        {isUpdating
          ? (
            <Button
              disabled
              variant='contained'
              title='Saving...'
              className={commonClasses.btnSuccess}
              startIcon={<CircularProgress size={24} />}
            >
              Saving
            </Button>
            )
          : (
            <Button
              autoFocus
              className={commonClasses.btnSuccess}
              onClick={handleSubmit(updateRegistriesInViewRefresh)}
              variant='contained'
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
            )}
        <Button
          className={commonClasses.ml_1}
          title='Cancel'
          variant='contained'
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InViewRefreshDialog.propTypes = {
  selectedRegistries: PropTypes.array,
  handleDialogClose: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  siteName: PropTypes.string
}

export default InViewRefreshDialog
