import React from 'react'

import { Card, CardContent, Table, TableHead, TableBody, TableCell, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  hidden: {
    display: 'none',
    visibility: 'collapse'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  spacer: { margin: theme.spacing(1) },
  saveButton: { color: '#2D2' },
  deleteButton: { color: '#D22' },
  plainText: {
    fontSize: theme.typography.pxToRem(18)
  },
  buttonGroup: {
    marginTop: theme.spacing(1),
    position: 'relative',
    top: 5
  }
}))

export const DefaultConfig = ({ module, siteModule, setVersion }) => {
  // console.log('========== DEFAULT CONFIG AdFuel Module: ', module)
  // console.log('========== DEFAULT CONFIG Site Module: ', siteModule)
  // console.log('========== DEFAULT CONFIG SetVersion: ', setVersion)
  const classes = useStyles()

  const handleSelectChange = async e => {
    // console.log('siteModule: ', siteModule.adFuelModuleId)
    // console.log('e: ', e.target.value)
    // const siteRes = setVersion(siteModule.adFuelModuleId, e.target.value)
    // console.log('SiteRes: ', siteRes)
    return e
  }

  const select = (siteModule, versions) => {
    return (
      <Select
        id='moduleAdFuelVersion'
        value={siteModule?.adFuelModuleVersionId}
        className={classes.selectEmpty}
        name='selectVersion'
        variant='outlined'
        onChange={e => handleSelectChange(e)}
      >
        {versions.length > 0 &&
          typeof versions.map === 'function' &&
          versions?.map((afVersion, i) => (
            <MenuItem key={afVersion._id} value={afVersion._id}>
              {afVersion.name}
            </MenuItem>
          ))}
      </Select>
    )
  }

  const displayPropertyTable = (siteModule, versions) => {
    return (
      <Card>
        <CardContent style={{ width: '100vw' }}>
          <Table style={{ width: '100vw' }} aria-label='default-config-table'>
            <TableBody>
              <TableHead>
                <TableCell style={{ width: '175px' }}>
                  <Typography>Module Version:</Typography>
                </TableCell>
                <TableCell>
                  {select(siteModule.adFuelModuleVersionId, versions, setVersion)}
                </TableCell>
              </TableHead>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
  }
  return (
    displayPropertyTable(siteModule || { adFuelModuleVersionId: null }, module.versions || [])
  )
}
