import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { addClasses, commonStyle } from '../../styles'
import Grid from '@material-ui/core/Grid'
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  FormControl,
  Button,
  Avatar
} from '@material-ui/core'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
// import { Alert } from '@material-ui/lab'
import { useSelector, useDispatch } from 'react-redux'
import { getAllCustomTargets } from '../../modules/DFPAdUnitSlice'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { current } from '@reduxjs/toolkit'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
}))

function getStyles (targeting, selectedTargetings, theme) {
  return {
    fontWeight:
      selectedTargetings.indexOf(targeting) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

export const CustomTargetingDetailsBeta = ({
  isNew = false,
  targetings,
  isSlotTargeting = true,
  slotName,
  handleSave,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { customTargets } = useSelector(state => state.dfp)
  const [customTargetings, setCustomTargetings] = useState()
  const [targetingsWithKeys, setTargetingsWithKeys] = useState(true)
  const [expanded, setExpanded] = useState(true)
  const [nameChange, setNameChange] = useState(null)

  const handleChange = (event, customTargeting) => {
    // find the index of object from array that you want to update
    const selectedTargetingIndex = customTargetings.findIndex(
      _targeting => _targeting.id === customTargeting.id
    )

    // update Targeting status
    const updatedSelectedTargeting = customTargetings
      .filter(_targeting => _targeting.id === customTargeting.id)
      .map(targeting => ({
        ...targeting,
        selectedValues: event.target.value
      }))

    // make final new array of objects by combining updated object.
    const updatedTargetings = [
      ...customTargetings.slice(0, selectedTargetingIndex),
      updatedSelectedTargeting[0],
      ...customTargetings.slice(selectedTargetingIndex + 1)
    ]
    setCustomTargetings(updatedTargetings)
    handleTargetingSave(updatedTargetings)
  }

  const handleTargetingSave = updatedTargetings => {
    const bulkTargetings = updatedTargetings
      .filter(customTargeting => customTargeting.selectedValues.length > 0)
      .map(_customTargeting => {
        const container = {
          key: _customTargeting.name,
          values: _customTargeting.selectedValues
        }
        return container
      })
    handleSave(bulkTargetings)
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  useEffect(() => {
    console.log('[CustomTaregtingBeta] ========== useEffect[customTargets, targetings, slotName]', { customTargets, targetings, slotName })
    if (customTargets.length > 0) {
      let _customTargets = customTargets
        .slice()
        .filter(_targeting => isSlotTargeting || _targeting.name !== 'pos') // for registryLevel, hide "pos"
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          return 0
        })
        .map(customTarget => ({
          ...customTarget,
          selectedValues: []
        }))
      if (isNew && targetings?.length === 1) {
        _customTargets = _customTargets.map(targetingItem => {
          if (targetingItem.name === 'pos') {
            return {
              ...targetingItem,
              selectedValues: [{ name: slotName?.split(/_(.+)/)[1] }]
            }
          } else {
            return targetingItem
          }
        })
        setCustomTargetings(_customTargets)
      } else {
        let updatedTargeting = _customTargets.slice()
        const currentTartetingGroup = targetings?.reduce(function (r,a) {
          r[a.key] = r[a.key] || []
          r[a.key].push(a.key === 'pos' ? slotName?.split(/_(.+)/)[1] : a.value)
          return r
        }, {})
        const currentTartetingKeyValue = []
        for (const targetingName in currentTartetingGroup) {
          currentTartetingKeyValue.push({
            key: targetingName,
            values:
              targetingName === 'pos'
                ? [slotName?.split(/_(.+)/)[1]]
                : currentTartetingGroup[targetingName]
          })
        }
        currentTartetingKeyValue.forEach((targeting, i) => {
          const selectedTargetingIndex = _customTargets?.findIndex(
            _targeting => _targeting.name === targeting.key
          )
          // update Targeting status
          const updatedSelectedTargeting = _customTargets
            ?.filter(_targeting => _targeting.name === targeting.key)
            .map(_targeting => ({
              ..._targeting,
              selectedValues:
                _targeting.name === 'pos'
                  ? targeting.values
                  : _targeting.values.filter(f => targeting.values.includes(f.name))
            }))
          updatedTargeting = [
            ...updatedTargeting.slice(0, selectedTargetingIndex),
            updatedSelectedTargeting[0],
            ...updatedTargeting.slice(selectedTargetingIndex + 1)
          ]
        })
        setCustomTargetings(updatedTargeting)
      }
    } else {
      dispatch(getAllCustomTargets(true))
    }
  }, [customTargets, targetings, slotName])

  useEffect(() => {
    setNameChange(slotName)
  }, [slotName])

  useEffect(() => {
    if (isNew && currentTargetings?.length === 1) {
      handleTargetingSave(customTargetings)
    }
  }, [nameChange])

  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, isSlotTargeting ? commonClasses.clrSlotHeader : commonClasses.clrRegistryHeader)} titleTypographyProps={{ variant: 'h6' }} title={`${isSlotTargeting ? 'Targeting' : 'Targeting'} `}/>
      <CardContent>
        <Grid container className={commonClasses.m_1}>
          <Grid item xs={12} sm={12}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '50%', fontWeight: 'bold' }}>
                    Target Key
                  </TableCell>
                  <TableCell style={{ width: '50%', fontWeight: 'bold' }}>
                    Values
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customTargetings && customTargetings.length > 0
                  ? (
                      customTargetings.map((customTargeting, i) => {
                        if (targetingsWithKeys) {
                          if (customTargeting?.selectedValues?.length > 0) {
                            return (
                              <TableRow
                                className={classes.tableRow}
                                hover
                                key={customTargeting.id || i}
                                style={{
                                  verticalAlign: 'top'
                                }}
                              >
                                <TableCell>
                                  <div className={classes.plainText}>
                                    [{customTargeting.name}] -{' -'}
                                    {customTargeting.displayName}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <FormControl
                                    fullWidth
                                    style={{ minHeight: 50 }}
                                  >
                                    {customTargeting.name === 'pos'
                                      ? (
                                        <Typography>
                                          {slotName?.split(/_(.+)/)[1]}
                                        </Typography>
                                        )
                                      : (
                                        <Select
                                          labelId='demo-mutiple-chip-label'
                                          id='demo-mutiple-chip'
                                          multiple
                                          value={customTargeting.selectedValues}
                                          onChange={e => {
                                            handleChange(e, customTargeting)
                                          }}
                                          input={
                                            <Input id={customTargeting.id} />
                                    }
                                          renderValue={selected => {
                                            return (
                                              <div className={classes.chips}>
                                                {selected.map((value, i) => (
                                                  <Chip
                                                    key={value.id || i}
                                                    label={value.name}
                                                    className={classes.chip}
                                                  />
                                                ))}
                                              </div>
                                            )
                                          }}
                                          MenuProps={MenuProps}
                                        >
                                          {customTargeting.values.map((value, i) => (
                                            <MenuItem
                                              key={value.id || i}
                                              value={value}
                                              style={getStyles(
                                                value,
                                                customTargeting.selectedValues,
                                                theme
                                              )}
                                            >
                                              {value.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )
                          } else {
                            return <></>
                          }
                        } else {
                          return (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={customTargeting.id || i}
                              style={{
                                verticalAlign: 'top'
                              }}
                            >
                              <TableCell>
                                <div className={classes.plainText}>
                                  [{customTargeting.name}] -{' '}
                                  {customTargeting.displayName}
                                </div>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  fullWidth
                                  style={{ minHeight: 50 }}
                                >
                                  {customTargeting.name === 'pos'
                                    ? (
                                      <Typography>
                                        {customTargeting?.values[0]?.name}
                                      </Typography>
                                      )
                                    : (
                                      <Select
                                        labelId='demo-mutiple-chip-label'
                                        id='demo-mutiple-chip'
                                        multiple
                                        displayEmpty
                                        value={customTargeting.selectedValues}
                                        onChange={e => {
                                          handleChange(e, customTargeting)
                                        }}
                                        input={<Input id={customTargeting.id} />}
                                        renderValue={selected => {
                                          if (selected.length === 0) {
                                            return (
                                              <em>
                                                Select Key Value
                                                {customTargeting.name === 'pos'
                                                  ? ''
                                                  : 's'}
                                              </em>
                                            )
                                          } else {
                                            return (
                                              <div className={classes.chips}>
                                                {selected.map((value, i) => (
                                                  <Chip
                                                    key={value.id || i}
                                                    label={value.name}
                                                    className={classes.chip}
                                                  />
                                                ))}
                                              </div>
                                            )
                                          }
                                        }}
                                        MenuProps={MenuProps}
                                      >
                                        <MenuItem value='' disabled>
                                          Select key values
                                        </MenuItem>
                                        {customTargeting.values.map((value, i) => (
                                          <MenuItem
                                            key={value.id || i}
                                            value={value}
                                            style={getStyles(
                                              value,
                                              customTargeting.selectedValues,
                                              theme
                                            )}
                                          >
                                            {value.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      )}
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          )
                        }
                      })
                    )
                  : (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                    )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={commonClasses.panelFooter} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='contained'
          size="small"
          title='Add New Target'
          className={addClasses(
            commonClasses.btnInfo,
            commonClasses.mr_1
          )}
          onClick={() => {
            handleSave()
          }}
        >
          Save Targeting
        </Button>
      </CardActions>
    </Card>
  )
}
