/* eslint-disable no-sequences, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearSite,
  createSite,
  updateGeneralInformation,
  filterSite,
  fetchSiteById,
  ToggleIsNewStatus,
  fetchRocketeerSites
} from '../../modules/sitesSlice'

import { fetchAllAdUnits } from '../../modules/DFPAdUnitSlice'

import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string as yupstring, object as yupobject } from 'yup'

import {
  Dialog,
  makeStyles,
  withStyles,
  useTheme,
  useMediaQuery,
  DialogTitle,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  CircularProgress,
  TextField,
  Button,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Card,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

// import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Add from '@material-ui/icons/Add'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { siteNameFormatter, hasRole } from '../../utilities/helperFunctions'
import { addClasses, commonStyle } from '../../styles'
import { LoadingProgress } from '../ui'

const useStyles = makeStyles(theme => ({
  plainText: {
    fontSize: theme.typography.pxToRem(18)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  'swal2-container': {
    zIndex: 99999
  }
}))

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

const SitesBeta = () => {
  const theme = useTheme()
  const classes = useStyles()
  const commonClasses = commonStyle()
  const { user } = useSelector(state => state.authReducer)
  const { adRoots } = useSelector(state => state.dfp)
  const dispatch = useDispatch()
  const history = useHistory()
  const { isNew, sites, selectedItemId, loading } = useSelector(
    state => state.sitesSlice
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [newSite, setNewSite] = useState({})
  const [rocketeerSites, setRocketeerSites] = useState([])
  const [adUnitRoots, setAdUnitRoots] = useState([])
  const [sitesName, setSitesName] = useState([])
  const [isIncludeActive, setIsIncludeActive] = useState(false)
  const [isMinified, setIsMinified] = useState(false)
  const [areLinksSecure, setAreLinksSecure] = useState(true)
  const [useWOPR, setUseWOPR] = useState(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const validationSchema = yupobject().shape({
    description: yupstring().required('Required'),
    name: yupstring()
      .required('Required')
      .notOneOf(sitesName, 'Duplicate name already exists'),
    rootAdUnitId: yupstring().required('Required')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: false
  })

  // Add New Site
  const AddNewSite = () => {
    dispatch(createSite(newSite))
  }

  // Update Site
  const onActiveUpdate = (event, rocketeerSite) => {
    event.stopPropagation()
    rocketeerSite.isActive = !rocketeerSite.isActive
    dispatch(updateGeneralInformation(rocketeerSite))
  }

  // Filter
  const onSearchChange = e => {
    setSearchTerm(e.target.value)
  }

  // Handle Changes
  const onAddSite = () => {
    if (adRoots.length <= 0) {
      dispatch(fetchAllAdUnits())
    }
    setNewSite({
      name: null,
      description: null,
      isActive: true,
      rootAdUnitId: '',
      isRegistryMinified: isMinified,
      areLinksSecure,
      useWOPR
    })
    dispatch(ToggleIsNewStatus())
  }

  const onRowClick = rocketeerSite => {
    history.push(`/sites/${siteNameFormatter(rocketeerSite.name)}`)
    dispatch(fetchSiteById(rocketeerSite._id))
    setSearchTerm('')
    dispatch(filterSite(''))
  }

  const handleTextFieldChange = e => {
    if (e.target.name === 'name') {
      setNewSite({
        ...newSite,
        name: e.target.value
      })
      setValue('name', e.target.value, { shouldValidate: true })
    } else if (e.target.name === 'description') {
      setNewSite({
        ...newSite,
        description: e.target.value
      })
      setValue('description', e.target.value, { shouldValidate: true })
    }
  }

  const handleSelectChange = e => {
    if (e.target.name === 'rootAdUnitId') {
      setNewSite({
        ...newSite,
        rootAdUnitId: e.target.value
      })
      setValue('rootAdUnitId', e.target.value, { shouldValidate: true })
    }
  }

  const handleIsIncludeActive = e => {
    setIsIncludeActive(e.target.checked)
    if (!isIncludeActive) {
      setRocketeerSites(sites.map(site => ({ ...site })))
    } else {
      const filteredSites = sites.filter(site => site.isActive === true)
      setRocketeerSites(filteredSites.map(site => ({ ...site })))
    }
  }

  const handleRadioGroupChange = e => {
    if (e.target.name === 'areLinksSecure') {
      setNewSite({
        ...newSite,
        areLinksSecure: e.target.value
      })
      setAreLinksSecure(e.target.value)
    } else if (e.target.name === 'RegistryPackaging') {
      setNewSite({
        ...newSite,
        isRegistryMinified: e.target.value
      })
      setIsMinified(e.target.value)
    } else if (e.target.name === 'useWOPR') {
      setNewSite({
        ...newSite,
        useWOPR: e.target.value
      })
      setUseWOPR(e.target.value)
    }
  }

  const activeDeactiveButton = rocketeerSite => {
    // if (userPermissions.includes('deploy-registry')) {
    if (loading && rocketeerSite._id === selectedItemId) {
      return (
        <Button
          disabled
          variant='contained'
          title={rocketeerSite.isActive ? 'Deactivating...' : 'Activating...'}
          startIcon={<CircularProgress size={24} />}
        >
          {rocketeerSite.isActive ? 'Deactivate' : 'Activate'}
        </Button>
      )
    } else {
      return rocketeerSite.isActive
        ? (
          <Button
            name='isActive'
            variant='contained'
            className={commonClasses.btnDanger}
            onClick={e => onActiveUpdate(e, rocketeerSite)}
            startIcon={<LockIcon />}
          >
            Deactivate
          </Button>
          )
        : (
          <Button
            name='isActive'
            variant='contained'
            className={commonClasses.btnSuccess}
            onClick={e => onActiveUpdate(e, rocketeerSite)}
            startIcon={<LockOpenIcon />}
          >
            Activate
          </Button>
          )
    }
  }

  const userHasRole = hasRole(
    ['admin', 'inview-refresh-editor', 'site-editor'],
    user
  )

  // useEfect Section
  useEffect(() => {
    if (sites.length === 0) {
      dispatch(fetchRocketeerSites())
    } else {
      const _sites = sites.slice().sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
      if (isIncludeActive) {
        setRocketeerSites(_sites.map(site => ({ ...site })))
      } else {
        const filteredSites = _sites.filter(site => site.isActive === true)
        setRocketeerSites(filteredSites.map(site => ({ ...site })))
      }
      setSitesName(_sites.map(site => site.name))
    }
  }, [sites, isIncludeActive])

  useEffect(() => {
    setAdUnitRoots(adRoots.map(adRoot => ({ ...adRoot })))
  }, [adRoots])

  useEffect(() => {
    dispatch(filterSite(searchTerm))
  }, [searchTerm])

  useEffect(() => {
    dispatch(clearSite())
  }, [])

  const displayAddNewSiteDialog = () => {
    return (
      <Dialog
        onClose={() => dispatch(ToggleIsNewStatus())}
        aria-labelledby='customized-dialog-title'
        open={isNew}
        fullScreen={fullScreen}
      >
        <DialogTitle id='form-dialog-title'style={{padding: 5, borderRadius: '0'}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrSiteHeader)} titleTypographyProps={{ variant: 'h6' }} >
          Add New Site: General Information
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormControl
                className={commonClasses.m_1}
                style={{ width: '45%' }}
              >
                <TextField
                  label='Name'
                  margin='dense'
                  style={{ marginTop: 0 }}
                  id={newSite.name}
                  name='name'
                  defaultValue={newSite.name}
                  variant='standard'
                  {...register('name')}
                  onChange={e => handleTextFieldChange(e)}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                />
              </FormControl>
              <FormControl
                className={commonClasses.m_1}
                style={{ width: '45%' }}
              >
                <InputLabel id='adroot-select-label'>Ad Root</InputLabel>
                <Select
                  labelId='adroot-select-label'
                  id='rootAdUnitId'
                  name='rootAdUnitId'
                  value={newSite.rootAdUnitId}
                  {...register('rootAdUnitId', {
                    required: true,
                    validate: value => value === '' || 'Required'
                  })}
                  error={!!errors.rootAdUnitId}
                  onChange={e => handleSelectChange(e)}
                >
                  {adUnitRoots.length > 0
                    ? (
                      <MenuItem value=''>None</MenuItem>
                      )
                    : (
                      <MenuItem value=''>... Loading ...</MenuItem>
                      )}
                  {adUnitRoots.map(adUnitRoot => (
                    <MenuItem key={adUnitRoot.dfpId} value={adUnitRoot.dfpId}>
                      {adUnitRoot.name}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors.rootAdUnitId && (
                  <FormHelperText error={!!errors.rootAdUnitId}>
                    Required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                className={commonClasses.m_1}
                style={{ width: '95%' }}
              >
                <TextField
                  label='Description'
                  name='description'
                  margin='dense'
                  defaultValue={newSite.description}
                  variant='standard'
                  {...register('description')}
                  onChange={e => handleTextFieldChange(e)}
                  error={!!errors.description}
                  helperText={
                    errors.description ? errors.description.message : ''
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={commonClasses.m_1}>
              <FormLabel component='legend'>Registry Naming</FormLabel>
              <RadioGroup
                row
                aria-label='Registry Naming'
                name='useWOPR'
                value={useWOPR}
                onChange={handleRadioGroupChange}
              >
                <FormControlLabel
                  value={false.toString()}
                  control={<Radio />}
                  label='Normal'
                />
                <FormControlLabel
                  value={true.toString()}
                  control={<Radio />}
                  label='WOPR'
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.wrapper}>
          {loading
            ? (
              <Button
                disabled
                variant='contained'
                title='Saving...'
                className={commonClasses.btnSuccess}
                startIcon={<CircularProgress size={24} />}
              >
                Saving
              </Button>
              )
            : (
              <Button
                autoFocus
                className={commonClasses.btnSuccess}
                onClick={handleSubmit(AddNewSite)}
                variant='contained'
                startIcon={<CheckCircleIcon />}
              >
                Save
              </Button>
              )}
          <Button
            className={commonClasses.ml_1}
            title='Cancel'
            variant='contained'
            onClick={() => dispatch(ToggleIsNewStatus())}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className='sites'>
      <Container maxWidth='xl'>
        {displayAddNewSiteDialog()}
        <Card className={addClasses(commonClasses.roundedCard)}>
          <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrSiteHeader)} titleTypographyProps={{ variant: 'h6' }} title={'Filters'}/>
          {!loading
            ? (
              <CardContent>
                <Grid container >
                  <Grid item md={4} xs={12}>
                    <TextField
                      style={{ width: '99%' }}
                      label='Find'
                      margin='dense'
                      name='itemFilter'
                      defaultValue={searchTerm}
                      variant='outlined'
                      onChange={onSearchChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isIncludeActive === true}
                          color='primary'
                          value='true'
                          name='chkboxActive'
                          onChange={e => handleIsIncludeActive(e)}
                        />
                    }
                      label='Include Inactive Sites'
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {userHasRole
                      ? (
                        <>
                          <Button
                            variant='contained'
                            color='primary'
                            className={(classes.button, commonClasses.m_1)}
                            startIcon={<Add />}
                            onClick={() => {
                              onAddSite()
                            }}
                          >
                            Add New Site
                          </Button>
                        </>
                        )
                      : null}
                  </Grid>
                </Grid>
              </CardContent>
            ) : ( <></>)
          }
        </Card>
        <br />
        <Card className={addClasses(commonClasses.roundedCard)}>
          <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrSiteHeader)} titleTypographyProps={{ variant: 'h6' }} title={'Rocketeer Sites'}/>
          {!loading
            ? (
              <CardContent>
                <Grid>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            width: '25%',
                            fontSize: '1.25em',
                            fontWeight: 'bold'
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          style={{
                            width: '60%',
                            fontSize: '1.25em',
                            fontWeight: 'bold'
                          }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          style={{
                            width: '15%',
                            fontSize: '1.25em',
                            fontWeight: 'bold'
                          }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rocketeerSites.map(rocketeerSite => (
                        <TableRow name='siteRow' key={rocketeerSite._id} hover>
                          <TableCell
                            onClick={() => onRowClick(rocketeerSite)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className={classes.plainText}>
                              {rocketeerSite.name}
                            </div>
                          </TableCell>
                          <TableCell
                            onClick={() => onRowClick(rocketeerSite)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className={classes.plainText}>
                              {rocketeerSite.description}
                            </div>
                          </TableCell>
                          {userHasRole
                            ? (
                              <TableCell>
                                {activeDeactiveButton(rocketeerSite)}
                              </TableCell>
                              )
                            : null}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </CardContent>
              )
            : (
              <LoadingProgress label='Loading Sites' />
            )
          }
        </Card>
      </Container>
    </div>
  )
}

export default SitesBeta
