/* eslint-disable no-undef */
import React, { PureComponent } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'

const swal = withReactContent(Swal)

export default class JSONEditorReact extends PureComponent {
  constructor (props) {
    super(props)
    this.container = React.createRef()
  }

  state = {
    open: false,
    isDirty: false
  }

  componentDidMount () {
    this.setState({ open: this.props.open || false })
    setTimeout(() => {
      const options = {
        mode: 'code',
        onChange: this.handleChange
      }
      if (this.container.current) {
        this.jsoneditor = new JSONEditor(this.container.current, options)

        if (this.props?.version?.arguments) {
          try {
            // console.log('A', JSON.parse(JSON.stringify(this.props.version.arguments)))
            this.jsoneditor.set(JSON.parse(this.props.version.arguments))
          } catch (e) {
            // console.log('A OOPS!', e)
            this.jsoneditor.set({})
          }
        }
      } else {
        // console.log('A No Container')
      }
    }, 250)
  }

  componentDidUpdate () {
    setTimeout(() => {
      if (this.jsoneditor && this.props?.version?.arguments) {
        try {
          // console.log('C', JSON.parse(JSON.stringify(this.props.version.arguments)))
          this.jsoneditor.set(JSON.parse(JSON.stringify(this.props.version.arguments)))
        } catch (e) {
          // console.log('C OOPS!', e)
          this.jsoneditor.set({})
        }
      } else {
        if (this.container.current) {
          const options = Object.assign({}, {
            mode: 'code',
            onChange: this.handleChange
          })
          this.jsoneditor = new JSONEditor(this.container.current, options)

          if (this.props?.version?.arguments) {
            try {
              // console.log('B', JSON.parse(JSON.stringify(this.props.version.arguments)))
              this.jsoneditor.set(JSON.parse(JSON.stringify(this.props.version.arguments)))
            } catch (e) {
              // console.log('B OOPS!', e)
              this.jsoneditor.set({})
            }
          }
        } else {
          // console.log('B No Container')
        }
      }
    }, 250)
  }

  componentWillUnmount () {
    if (this.jsoneditor) {
      this.jsoneditor.destroy()
    }
  }

  handleClose = e => {
    if (this.state.isDirty) {
      swal.fire({
        title: 'Are you sure?',
        text: 'You have unsaved changes to the selected argument.',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.props.handleClose({}, {})
        }
      })
    } else {
      if (this.jsoneditor) {
        this.jsoneditor.destroy()
      }
      this.props.handleClose({}, {})
    }
  }

  handleSave = () => {
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true
      // buttons: true,
      // dangerMode: true
    }).then(willSave => {
      if (willSave.isConfirmed) {
        const args = this.jsoneditor.get()
        this.props.handleSave(
          this.props.module,
          this.props.version,
          args
        )
        // props.version.arguments[selectedArg] = moduleArgument
        this.setState({
          isDirty: false
        })
        swal.fire({
          title: 'Arguments Saved!',
          icon: 'success'
        })
      }
    })
  }

  handleChange = () => {
    // console.log('JSON: ', this.jsoneditor.get())
    this.setState({ isDirty: true })
  }

  render () {
    return (
      <Dialog
        maxWidth='xl'
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {this.props.module.name} v{this.props.version.name} - Configuration Options
        </DialogTitle>
        <DialogContent>
          <div
            className='jsoneditor-react-container'
            style={{
              width: '800px',
              height: '400px'
            }}
            ref={this.container}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={this.handleSave}
            color={this.state.isDirty ? 'primary' : 'secondary'}
            disabled={!this.state.isDirty}
          >
            Save Changes
          </Button>
          <Button onClick={this.handleClose} color={this.state.isDirty ? 'secondary' : 'primary'}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
