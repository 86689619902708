import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'

const swal = withReactContent(Swal)

const initialState = {
  error: null,
  users: [],
  user: {},
  loading: false,
  usersByRole: []
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    getUsers: (state, action) => {
      state.loading = false
      state.users = action.payload
    },
    fetchUsersByRole: (state, action) => {
      state.loading = false
      state.usersByRole = action.payload
    },
    onCreate: (state, action) => {
      state.loading = false
      if (action.payload.firstName) {
        swal.fire({
          title: `The user ${action.payload.firstName} was created successfully`,
          icon: 'success'
        })
      }
    },
    onUpdate: (state, action) => {
      state.loading = false
      if (action.payload.firstName) {
        swal.fire({
          title: `The user ${action.payload.firstName} was updated successfully`,
          icon: 'success'
        })
      }
    },
    onDelete: (state, action) => {
      state.loading = false
      if (action.payload.firstName) {
        swal.fire({
          title: `The user ${action.payload.firstName} was deleted successfully`,
          icon: 'success'
        })
      }
    },
    loadingStatus: (state) => {
      state.loading = true
    },
    errorStatus: (state, action) => {
      state.loading = false
      state.error = action.payload
      handleError(action.payload, showError)
    }
  }
})

export default userSlice.reducer

export const {
  getUsers,
  fetchUsersByRole,
  loadingStatus,
  errorStatus,
  onCreate,
  onDelete,
  onUpdate
} = userSlice.actions

export const fetchUsers = () => {
  return (dispatch) => {
    dispatch(loadingStatus())
    return request('/users')
      .then((res) => dispatch(getUsers(res.data)))
      .catch((err) => dispatch(errorStatus(err.response.data)))
  }
}

export const getUsersByRole = role => {
  return async dispatch => {
    dispatch(loadingStatus())
    return request(`/users/byRole/${role}`)
      .then(res => dispatch(fetchUsersByRole(res.data)))
      .catch(err => dispatch(errorStatus(err)))
  }
}

export const createUser = async user => {
  return request('/users', 'POST', user)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const updateUser = async user => {
  return request(`/users/${user._id}`, 'PUT', user)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const deleteUser = async user => {
  return request(`/users/${user._id}`, 'DELETE', user)
    .then(res => res.data)
    .catch(err => err.response.data)
}
