import React from 'react'
import { ItemContent } from './index'
import { NavLink } from 'react-router-dom'
import { Button, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.contrastText
  }
}))

export const SimpleMenu = props => {
  const { tab } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()

  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const performAction = (action) => {
    handleClose()
    setTimeout(() => {
      if (action) { action() }
    }, 300)
  }
  const randomTabId = Math.floor(Math.random() * 5000)
  return (
    <div>
      <Button
        className={classes.button}
        aria-controls={'simple-menu-' + (tab.id || randomTabId)}
        aria-haspopup='true'
        onClick={openMenu}
      >
        {tab.title}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id={'simple-menu-' + (tab.id || randomTabId)}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {tab.links.map((link, index) => {
          if (!link.url) {
            return (
              <MenuItem onClick={() => performAction(link.action)} key={index}>
                <ItemContent link={link} />
              </MenuItem>
            )
          } else {
            return (
              <NavLink to={link.url} key={index}>
                <MenuItem onClick={() => performAction(link.action)}>
                  <ItemContent link={link} />
                </MenuItem>
              </NavLink>
            )
          }
        })}
      </Menu>
    </div>
  )
}
