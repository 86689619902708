import React, { useState, useEffect } from 'react'

import { Table, TableHead, TableBody, TableRow, TableCell, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { SlotList } from '../index'

const template = {
  MAPPINGS: {
    DOM: {
      DESKTOP: {},
      MOBILE: {}
    },
    INTL: {
      DESKTOP: {},
      MOBILE: {}
    }
  }
}

export const TripleLift = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const { TRIPLELIFT = template } = VENDOR
  const { MAPPINGS } = TRIPLELIFT
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displayMappingsTable = () => {
    return (
      <Table size='small' aria-label='TRIPLELIFT-unit-mapping-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Mappings</Typography></TableCell>
            <TableCell>Domestic</TableCell>
            <TableCell>International</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Desktop HP</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRIPLELIFT.MAPPINGS.DOM.DESKTOP.HP'
                object={MAPPINGS.DOM.DESKTOP}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='Inventory Code'
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRIPLELIFT.MAPPINGS.INTL.DESKTOP.HP'
                object={MAPPINGS.INTL.DESKTOP}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='Inventory Code'
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Desktop ROS</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRIPLELIFT.MAPPINGS.DOM.DESKTOP.ROS'
                object={MAPPINGS.DOM.DESKTOP}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='Inventory Code'
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRIPLELIFT.MAPPINGS.INTL.DESKTOP.ROS'
                object={MAPPINGS.INTL.DESKTOP}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='Inventory Code'
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mobile HP</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRIPLELIFT.MAPPINGS.DOM.MOBILE.HP'
                object={MAPPINGS.DOM.MOBILE}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='Inventory Code'
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRIPLELIFT.MAPPINGS.INTL.MOBILE.HP'
                object={MAPPINGS.INTL.MOBILE}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='Inventory Code'
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mobile ROS</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRIPLELIFT.MAPPINGS.DOM.MOBILE.ROS'
                object={MAPPINGS.DOM.MOBILE}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='Inventory Code'
              />
            </TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <SlotList
                path='TRIPLELIFT.MAPPINGS.INTL.MOBILE.ROS'
                object={MAPPINGS.INTL.MOBILE}
                options={options}
                allOptions={allOptions}
                setOptions={setOptions}
                setAllOptions={setAllOptions}
                errors={errors}
                setErrors={setErrors}
                validation={validation}
                valueName='Inventory Code'
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='IndexExchange-panel-content'
        id='IndexExchange-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>TripleLift</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displayMappingsTable()}</Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
