import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  locations: [],
  orgLocations: [],
  loading: false
}

const slotLocationSlice = createSlice({
  name: 'slotLocation',
  initialState,
  reducers: {
    SlotLocationLoadingStatus: state => {
      state.loading = true
    },
    FetchSlotLocations: (state, action) => {
      state.loading = false
      state.orgLocations = action.payload
      state.locations = action.payload
    },
    UpdateSlotLocation: (state, action) => {
      const locations = state.locations.slice()
      const index = locations.findIndex(
        slotLocation => slotLocation._id === action.payload._id
      )
      locations[index] = action.payload

      state.loading = false
      state.orgLocations = locations
      state.locations = locations
      swal.fire({
        title: 'Slot Locations Updated!',
        text: 'Slot Locations Updated.',
        icon: 'success'
      })
    },
    CreateSlotLocation: (state, action) => {
      const orgLocations = state.orgLocations.slice()
      const locations = state.locations.slice()
      locations.unshift(action.payload)
      orgLocations.unshift(action.payload)
      state.loading = false
      state.locations = locations
      state.orgLocations = orgLocations
      swal.fire({
        title: 'Slot Locations Saved!',
        text: 'Slot Locations saved successfully.',
        icon: 'success'
      })
    },
    FilterSlotLocation: (state, action) => {
      const results = filterByValue(state.orgLocations, action.payload)
      state.loading = false
      state.locations = results
    },
    SlotLocationError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    }
  }
})

export default slotLocationSlice.reducer
export const {
  SlotLocationLoadingStatus,
  FetchSlotLocations,
  UpdateSlotLocation,
  CreateSlotLocation,
  FilterSlotLocation,
  SlotLocationError
} = slotLocationSlice.actions

const filterByValue = (array, string) => {
  return array.filter(arrObject =>
    Object.keys(arrObject).some(element =>
      // Some() allows you to test if an array has at least one element that meets a condition.
      arrObject[element]
        .toString()
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  )
}

export const fetchSlotLocations = () => {
  return async dispatch => {
    dispatch(SlotLocationLoadingStatus())
    return request('/lookups/locations')
      .then(res => dispatch(FetchSlotLocations(res.data)))
      .catch(err => dispatch(SlotLocationError(err)))
  }
}

export const updateSlotLocation = slotLocation => {
  return async dispatch => {
    dispatch(SlotLocationLoadingStatus())
    return request(
      `/lookups/locations/${slotLocation._id}`,
      'POST',
      slotLocation
    )
      .then(res => {
        dispatch(UpdateSlotLocation(res.data))
      })
      .catch(err => dispatch(SlotLocationError(err)))
  }
}

export const createSlotLocation = slotLocation => {
  return async dispatch => {
    dispatch(SlotLocationLoadingStatus())
    return request('/lookups/locations', 'POST', slotLocation)
      .then(res => dispatch(CreateSlotLocation(res.data)))
      .catch(err => dispatch(SlotLocationError(err)))
  }
}

export const filterSlotLocation = searchTerm => {
  return async dispatch => {
    dispatch(SlotLocationLoadingStatus())
    dispatch(FilterSlotLocation(searchTerm))
  }
}
