import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { request, handleError, showError } from '../utilities/request'
import { createSlice } from '@reduxjs/toolkit'

const swal = withReactContent(Swal)

/* * initial State */
const initialState = {
  banner: {},
  show: false,
  error: null,
  loading: false
}

const bannerMessageSlice = createSlice({
  name: 'bannerMessage',
  initialState,
  reducers: {
    BannerMessageLoadingStatus: state => {
      state.loading = true
    },

    FetchBannerMessage: (state, action) => {
      state.loading = false
      if (window.localStorage.getItem('showBanner') === 'false') {
        state.show = window.localStorage.getItem('bannerMessage') !== action.payload.message && !!action.payload.message.match(/\w+/)
        window.localStorage.setItem('showBanner', state.show)
        window.localStorage.setItem('bannerMessage', action.payload.message)
      }
      // state.show = !!action.payload.message.match(/\w+/)
      state.banner = action.payload
    },

    UpdateBannerMessage: (state, action) => {
      state.loading = false
      state.banner = action.payload
      state.show = !!action.payload.message.match(/\w+/)
      window.localStorage.setItem('showBanner', state.show)
      window.localStorage.setItem('bannerMessage', action.payload.message)
      swal.fire({
        title: 'Banner Message Updated!',
        text: 'Banner Message Updated.',
        icon: 'success'
      })
    },

    BannerMessageToggleDisplay: state => {
      state.show = !state.show
      if (window.localStorage.getItem('showBanner') === 'false') {
        window.localStorage.setItem('showBanner', true)
      } else if (window.localStorage.getItem('showBanner') === 'true') {
        window.localStorage.setItem('showBanner', false)
      }
    },

    BannerMessageError: (state, action) => {
      state.loading = false
      handleError(action.payload, showError)
    }
  }
})

export default bannerMessageSlice.reducer
export const {
  BannerMessageLoadingStatus,
  FetchBannerMessage,
  UpdateBannerMessage,
  BannerMessageToggleDisplay,
  BannerMessageError
} = bannerMessageSlice.actions

export const fetchBannerMessage = () => {
  return async dispatch => {
    dispatch(BannerMessageLoadingStatus())
    return request('/banner')
      .then(res => dispatch(FetchBannerMessage(res.data)))
      .catch(err => dispatch(BannerMessageError(err)))
  }
}

export const updateBannerMessage = bannerMsg => {
  return async dispatch => {
    dispatch(BannerMessageLoadingStatus())
    return request('/banner', 'POST', bannerMsg)
      .then(res => dispatch(UpdateBannerMessage(res.data)))
      .catch(err => dispatch(BannerMessageError(err)))
  }
}
