import { makeStyles } from '@material-ui/core/styles'

export const commonStyle = makeStyles(theme => {
  return ({
    clrDanger: {
      fontWeight: 'bold',
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.dark
      }
    },
    clrWarning: {
      fontWeight: 'bold',
      color: theme.palette.warning.main,
      '&:hover': {
        color: theme.palette.warning.dark
      }
    },
    clrInfo: {
      fontWeight: 'bold',
      color: theme.palette.info.main,
      '&:hover': {
        color: theme.palette.info.dark
      }
    },
    clrSuccess: {
      fontWeight: 'bold',
      color: theme.palette.success.main,
      '&:hover': {
        color: theme.palette.success.dark
      }
    },

    clrContrast: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.contrastColor,
      color: theme.palette.text.main
    },

    labelDanger: {
      fontWeight: 'bold',
      color: theme.palette.error.main
    },
    labelWarning: {
      fontWeight: 'bold',
      color: theme.palette.warning.main
    },
    labelInfo: {
      fontWeight: 'bold',
      color: theme.palette.info.main
    },
    labelSuccess: {
      fontWeight: 'bold',
      color: theme.palette.success.main
    },
    btnDanger: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '& *': {
        color: theme.palette.error.contrastText,
        cursor: 'pointer',
        fontWeight: 'bold'
      },
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    btnWarning: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      '& *': {
        color: theme.palette.warning.contrastText,
        cursor: 'pointer',
        fontWeight: 'bold'
      },
      '&:hover': {
        backgroundColor: theme.palette.warning.dark
      }
    },
    btnInfo: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      '& *': {
        color: theme.palette.info.contrastText,
        cursor: 'pointer',
        fontWeight: 'bold'
      },
      '&:hover': {
        backgroundColor: theme.palette.info.dark
      }
    },
    btnSuccess: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      '& *': {
        color: theme.palette.success.contrastText,
        cursor: 'pointer',
        fontWeight: 'bold'
      },
      '&:hover': {
        backgroundColor: theme.palette.success.dark
      }
    },
    m_0: { margin: '0px !important' },
    m_1: { margin: theme.spacing(1) + 'px !important' },
    m_2: { margin: theme.spacing(2) + 'px !important' },
    m_3: { margin: theme.spacing(3) + 'px !important' },
    mr_0: { marginRight: '0px !important' },
    mr_1: { marginRight: theme.spacing(1) + 'px !important' },
    mr_2: { marginRight: theme.spacing(2) + 'px !important' },
    mr_3: { marginRight: theme.spacing(3) + 'px !important' },
    ml_0: { marginLeft: '0px !important' },
    ml_1: { marginLeft: theme.spacing(1) + 'px !important' },
    ml_2: { marginLeft: theme.spacing(2) + 'px !important' },
    ml_3: { marginLeft: theme.spacing(3) + 'px !important' },
    mt_0: { marginTop: '0px !important' },
    mt_1: { marginTop: theme.spacing(1) + 'px !important' },
    mt_2: { marginTop: theme.spacing(2) + 'px !important' },
    mt_3: { marginTop: theme.spacing(3) + 'px !important' },
    mb_0: { marginBottom: '0px !important' },
    mb_1: { marginBottom: theme.spacing(1) + 'px !important' },
    mb_2: { marginBottom: theme.spacing(2) + 'px !important' },
    mb_3: { marginBottom: theme.spacing(3) + 'px !important' },
    p_0: { padding: '0px !important' },
    p_1: { padding: theme.spacing(1) + 'px !important' },
    p_2: { padding: theme.spacing(2) + 'px !important' },
    p_3: { padding: theme.spacing(3) + 'px !important' },
    pr_0: { paddingRight: '0px !important' },
    pr_1: { paddingRight: theme.spacing(1) + 'px !important' },
    pr_2: { paddingRight: theme.spacing(2) + 'px !important' },
    pr_3: { paddingRight: theme.spacing(3) + 'px !important' },
    pl_0: { paddingLeft: '0px !important' },
    pl_1: { paddingLeft: theme.spacing(1) + 'px !important' },
    pl_2: { paddingLeft: theme.spacing(2) + 'px !important' },
    pl_3: { paddingLeft: theme.spacing(3) + 'px !important' },
    pt_0: { paddingTop: '0px !important' },
    pt_1: { paddingTop: theme.spacing(1) + 'px !important' },
    pt_2: { paddingTop: theme.spacing(2) + 'px !important' },
    pt_3: { paddingTop: theme.spacing(3) + 'px !important' },
    pb_0: { paddingBottom: '0px !important' },
    pb_1: { paddingBottom: theme.spacing(1) + 'px !important' },
    pb_2: { paddingBottom: theme.spacing(2) + 'px !important' },
    pb_3: { paddingBottom: theme.spacing(3) + 'px !important' },
    xs_width: {
      width: '600px',
      margin: '0 auto'
    },
    sm_width: {
      width: '960px',
      margin: '0 auto'
    },
    md_width: {
      width: '1280px',
      margin: '0 auto'
    },
    lg_width: {
      width: '1920px',
      margin: '0 auto'
    },
    xl_width: {
      width: '100%',
      margin: '0 auto'
    },
    br_0: {
      borderRadius: 0
    },
    inline: { display: 'inline-block' },
    selectEmpty: { marginTop: theme.spacing(3) },
    hidden: { display: 'none', visibility: 'collapse' },
    fontWeightBold: { fontWeight: 'bold' },
    plainText: {
      fontSize: theme.typography.pxToRem(18)
    },
    plainTextWrap: {
      fontSize: theme.typography.pxToRem(18),
      overflowWrap: 'anywhere'
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3)
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7)
    },
    actionArea: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    cardBkClr: {
      backgroundColor: theme.palette.background.card
    },
    verticalDivider28: {
      height: 28,
      margin: 4
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    displayFlex: {
      display: 'flex'
    },
    saveIconColor: { color: '#2D2' },
    editIconColor: { color: '#e5bc73' },
    deleteIconColor: { color: '#D22' },
    infoIconColor: { color: '#0000008a' },
    linkColor: { color: theme.palette.linkColor },
    updateAvailable: { color: theme.palette.contrastColor },
    toggleSwitch: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light
    },
    checkboxControl: {
      color: theme.palette.common.white
    },
    'swal2-container': {
      zIndex: 99999
    },
    disabledlink: {
      pointerEvents: 'none'
    },
    opacity50: {
      opacity: '50%'
    },
    roundedCard: {
      borderRadius: '15px',
      borderRadius: '15px',
      padding: 0,
    },
    panelHeader: {
      margin: 0,    
      fontWeight: 'bolder',
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      color: theme.palette.primary.contrastText
    },
    panelFooter: {
      margin: 0,    
      backgroundColor: theme.palette.panelFooterBackground,
      // color: 'white',
      // fontWeight: 'bolder',
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px'
    },
    clrSiteHeader: {
      color: 'white',
      backgroundColor: '#AF0278' // Magenta
    },
    clrRegistryHeader: {
      color: 'white',
      backgroundColor: '#02AF39' // Lime
    },
    clrSlotHeader: {
      color: 'white',
      backgroundColor: '#AF8F02' // Goldenrod
    },
    clrToolHeader: {
      color: 'white',
      backgroundColor: '#415197' // Slate
    },
    clrAdminHeader: {
      color: 'white',
      backgroundColor: '#AF0222' // Brick 
    }
  })
})
// ***** DISCLAIMER ***** The color names used above are my own descriptions of them and not their official names. ***** DISCLAIMER ***** //
