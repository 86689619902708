import React, { useState, useEffect } from 'react'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Tooltip, IconButton, List, ListItem, TextField, Grid } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

import { commonStyle } from '../../../../styles'
import { convertObjectToArray, convertArrayToObject } from '../../../../utilities/helperFunctions.js'

const swal = withReactContent(Swal)

export const PriceBucketList = ({ object, path, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation, keyNames = ['Precision, Min, Max, Increment'], valueNames = ['precision', 'min', 'max', 'increment'] }) => {
  const commonClasses = commonStyle()
  const PREBID = options.PREBID

  const { STRING } = validation
  const { LENGTH } = STRING
  const [list, setList] = useState([])
  const [entry, setEntry] = useState({ precision: '', min: '', max: '', increment: '' })

  const assignProperty = (value) => {
    const keys = path.split(/\./)

    const lastIndex = keys.length - 1
    const lastKey = keys[lastIndex]
    object = value.map(item => convertArrayToObject(item))
    let property = options
    keys.forEach((key, index) => {
      if (!property[key]) { property[key] = {} }
      if (index < lastIndex) { property = property[key] }
    })
    property[lastKey] = object
  }

  useEffect(() => {
    setList(object.map(
      item => {
        return convertObjectToArray(item)
      }
    ))
  }, [object])

  return (
    <List dense disablePadding>
      {list.map((listItem, listItemIndex) => {
        return keyNames.map(
          (keyName, keyNameIndex) => {
            return (
              <ListItem dense disableGutters key={keyName}>
                <Grid container spacing={1}>
                  {listItem.map((item, index) => {
                    return (
                      <Grid key={`${item.key}_${index}`} item>
                        <TextField
                          style={{ width: '55px' }}
                          placeholder={item.key}
                          label={item.key.charAt(0).toUpperCase() + item.key.slice(1)}
                          value={item.value}
                          error={errors[path + '_PRICE_BUCKET_' + index]}
                          helperText={errors[path + '_PRICE_BUCKET_' + index] && LENGTH.message}
                          inputProps={{
                            type: 'number',
                            min: index === 3 ? 0.01 : 0,
                            max: 1000
                          }}
                          onChange={(event) => {
                            const value = index === 0 ? parseInt(event.target.value, 10) : parseFloat(event.target.value)
                            listItem[index] = { ...item, value }
                            list[listItemIndex] = listItem
                            // setList(list)
                            assignProperty(list)
                            setErrors({ ...errors, [path + '_PRICE_BUCKET_' + index]: value.length < LENGTH.min || value.length > LENGTH.max })
                            setOptions({ ...options, PREBID })
                            setAllOptions({ ...allOptions, PREBID })
                            return true
                          }}
                        />
                      </Grid>
                    )
                  })}
                  <Grid item>
                    <Tooltip title='Delete'>
                      <IconButton
                        className={commonClasses.btnDanger}
                        size='small'
                        onClick={() => {
                          swal.fire({
                            title: 'Are you sure?',
                            icon: 'warning',
                            showConfirmButton: true,
                            showCancelButton: true
                            // buttons: true,
                            // dangerMode: true
                          }).then(resp => {
                            console.log('resp: ', resp)
                            if (resp.isConfirmed) {
                              assignProperty(list.filter((entry, entryIndex) => {
                                return entryIndex !== listItemIndex
                              }))
                              setOptions({ ...options, PREBID })
                              setAllOptions({ ...allOptions, PREBID })
                            }
                          })
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </ListItem>
            )
          }
        )
      })}
      <ListItem dense disableGutters>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              style={{ width: '55px' }}
              label='Precision'
              value={entry.precision}
              error={errors[path + 'PRICE_BUCKET_0']}
              helperText={errors[path + 'PRICE_BUCKET_0'] && LENGTH.message}
              onChange={(event) => {
                const value = parseInt(event.target.value, 10)
                setErrors({ ...errors, [path + 'PRICE_BUCKET_0']: value.length < LENGTH.min || value.length > LENGTH.max })
                setEntry({ ...entry, precision: value })
              }}
              inputProps={{
                type: 'number',
                min: 0,
                max: 1000
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '55px' }}
              label='Min'
              value={entry.min}
              error={errors[path + 'PRICE_BUCKET_0']}
              helperText={errors[path + 'PRICE_BUCKET_0'] && LENGTH.message}
              onChange={(event) => {
                const value = parseFloat(event.target.value)
                setErrors({ ...errors, [path + 'PRICE_BUCKET_0']: value.length < LENGTH.min || value.length > LENGTH.max })
                setEntry({ ...entry, min: value })
              }}
              inputProps={{
                type: 'number',
                min: 0,
                max: 1000
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '55px' }}
              label='Max'
              value={entry.max}
              error={errors[path + 'PRICE_BUCKET_0']}
              helperText={errors[path + 'PRICE_BUCKET_0'] && LENGTH.message}
              onChange={(event) => {
                const value = parseFloat(event.target.value)
                setErrors({ ...errors, [path + 'PRICE_BUCKET_0']: value.length < LENGTH.min || value.length > LENGTH.max })
                setEntry({ ...entry, max: value })
              }}
              inputProps={{
                type: 'number',
                min: 0,
                max: 1000
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '55px' }}
              label='Increment'
              value={entry.increment}
              error={errors[path + 'PRICE_BUCKET_0']}
              helperText={errors[path + 'PRICE_BUCKET_0'] && LENGTH.message}
              onChange={(event) => {
                const value = parseFloat(event.target.value)
                setErrors({ ...errors, [path + 'PRICE_BUCKET_0']: value.length < LENGTH.min || value.length > LENGTH.max })
                setEntry({ ...entry, increment: value })
              }}
              inputProps={{
                type: 'number',
                min: 0.01,
                max: 10
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title='Add'>
              <IconButton
                className={commonClasses.btnSuccess}
                size='small'
                onClick={() => {
                  object.push(entry)
                  assignProperty([...list, convertObjectToArray(entry)])
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                  setEntry({ precision: '', min: '', max: '', increment: '' })
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  )
}
