import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateAdFuel, updateSiteAdFuelModules, updateAdFuelAndModules, ToggleIsAdFuelVersionSelected } from '../../../modules/sitesSlice'
import { fetchAdFuelVersions } from '../../../modules/adFuelSlice'
import { CustomDialog } from '../../ui'

import {
  Tooltip,
  IconButton,
  FormControl,
  Checkbox,
  Select,
  MenuItem,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Button,
  Link,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SettingsIcon from '@material-ui/icons/Settings'
import BuildIcon from '@material-ui/icons/Build'
import Launch from '@material-ui/icons/Launch'
import GetApp from '@material-ui/icons/GetApp'
import { AdFuelConfigurationModal } from '../Dialogs/AdFuelConfigurationModal'

import { addClasses, commonStyle } from '../../../styles'
import { clone } from '../../../utilities/helperFunctions'
// import config from '../../config'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  getAdFuelModuleConfiguration,
  getAdFuelModuleCoreConfiguration
} from '../../../modules/lookupsSlice'
import { getPath } from '../../../modules/configSlice'
import ModuleConfigurationLite from './ModuleConfigurationLite'

// const rootUrl = config[config.env].publisher.rootAdFuelUrl
const useStyle = makeStyles(theme => ({
  roundedCard: {
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: 0,
  },
  header: {
    margin: 0,    
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bolder',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
}))


const swal = withReactContent(Swal)

const byPriorityAndName = (a, b) => {
  console.log('a,b: ', { a, b })
  if (a.adFuelModuleId && b.adFuelModuleId) {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    const prioA = a.priority !== null ? parseInt(a.priority || 0, 10) : 0
    const prioB = b.priority !== null ? parseInt(b.priority || 0, 10) : 0
    try {
      if (prioA > prioB) {
        return -1
      }
      if (prioA < prioB) {
        return 1
      }
      if (prioA === prioB) {
        if (nameA > nameB) {
          return 1
        }
        if (nameA < nameB) {
          return -1
        }
      }
      return 0
    } catch (err) {
      return 0
    }    
  }
}

const AdFuelConfigurationBeta = ({ data, canEditSite }) => {
  const commonClasses = commonStyle()
  const classes = useStyle()
  const { adFuelVersions } = useSelector(state => state.adFuelSlice)
  const { updatingSiteAdFuel } = useSelector(state => state.sitesSlice)
  const { pathConfig } = useSelector(state => state.configSlice)
  const { updatingSiteAdFuelModules } = useSelector(state => state.sitesSlice)
  const [publishers, setPublishers] = useState()
  const [site, setSite] = useState(clone(data))
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [errors, setErrors] = useState({})
  const {
    isAdFuelMinified,
    isAdFuelLocationSiteSpecific,
    adFuelVersionId,
    tagForChildDirectedTreatment
  } = site || {
    isAdFuelMinified: true,
    isAdFuelLocationSpecific: false,
    adFuelVersionId: null,
    tagForChildDirectedTreatment: false
  }
  const [haveModulesChanged,setHaveModulesChanged] = useState(false)
  const [url, setUrl] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const [adFuelChanged, setAdFuelChanged] = useState(false)
  const [modulesChanged, setModulesChanged] = useState(false)
  const [versionId, setVersionId] = useState(adFuelVersionId)
  const dispatch = useDispatch()
  // console.log('Using COPPA AdFuel:', tagForChildDirectedTreatment)

  useEffect(() => {
    setSite(clone(data))
    const adFuelVersion = adFuelVersions.find(adfuel => adfuel._id === data.adFuelVersionId)
    dispatch(ToggleIsAdFuelVersionSelected(adFuelVersion !== undefined))
  }, [data])

  useEffect(() => {
    if (adFuelVersions.length === 0) {
      dispatch(fetchAdFuelVersions())
    }
  }, [adFuelVersions])

  useEffect(() => {
    buildUrl('topEffect-AdFuelVersions', adFuelVersions?.length)
    setVersionId(site.adFuelVersionId)
  }, [adFuelVersions])

  useEffect(() => {
    buildUrl('topEffect-Publishers', publishers)
  }, [publishers])

  useEffect(() => {
    if (site._id) {
      const oldData = {
        isAdFuelMinified: data.isAdFuelMinified,
        isAdFuelLocationSiteSpecific: data.isAdFuelLocationSiteSpecific,
        adFuelVersionId: data.adFuelVersionId,
      }
      const newData = {
        isAdFuelMinified: site.isAdFuelMinified,
        isAdFuelLocationSiteSpecific: site.isAdFuelLocationSiteSpecific,
        adFuelVersionId: site.adFuelVersionId,
      }
      console.log('[AdFuelConfigurationBeta] ========== useEffect[site]::comparison', { oldData, newData, haveModulesChanged})
      setVersionId(site.adFuelVersionId)
      if (JSON.stringify(oldData) !== JSON.stringify(newData)) setAdFuelChanged(true)
      if (haveModulesChanged) setModulesChanged(true)
      setIsChanged(JSON.stringify(oldData) !== JSON.stringify(newData))
      buildUrl()
    }
  }, [site, haveModulesChanged])

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      setPublishers(pathConfig.publisher)
    } else {
      dispatch(getPath())
    }
    buildUrl()
  }, [pathConfig])

  const buildUrl = (name, value) => {
    // console.log('Building URL: ', { name, value, publishers })
    if (!publishers) return
    const adFuelVersion =
      adFuelVersions.find(adfuel => adfuel._id === adFuelVersionId) || {}
    const { major, minor, revision, patch } = adFuelVersion
    const fileName =
      'adfuel-' +
      major +
      '.' +
      minor +
      (revision || revision === 0 ? '.' + revision : '') +
      (patch || patch === 0 ? '.' + patch : '') +
      (tagForChildDirectedTreatment ? '-cc' : '') +
      (isAdFuelMinified ? '.min' : '') +
      '.js'
    // const adFuelUrl = (isAdFuelLocationSiteSpecific ? rootUrl.replace('/adfuel', `/${site.name.toLowerCase()}`) : rootUrl) + '/' + fileName
    const adFuelUrl =
      (isAdFuelLocationSiteSpecific
        ? publishers?.rootAdFuelUrl?.replace('/adfuel', '/' + site.name.toLowerCase())
        : publishers?.rootAdFuelUrl) +
      '/' +
      fileName

    setUrl(adFuelUrl)
  }

  const openConfigurationDialog = async () => {
    const adFuelVersion =
      adFuelVersions.find(adfuel => adfuel._id === adFuelVersionId) || {}

    const onClose = () => {
      swal.fire({
        title: 'Are you sure?',
        text: 'Changes will not be saved.',
        icon: 'warning',
        showCancelButton: true
        // buttons: true,
        // dangerMode: true
      }).then(ok => {
        if (ok.isConfirmed) {
          setOpenDialog(false)
        }
      })
    }
    const response = await getAdFuelModuleConfiguration(
      site._id,
      adFuelVersionId
    )
    console.log('[AdFuelConfigurationBeta] ---------- openConfigurationDialog::response: ', response)
    const core = await getAdFuelModuleCoreConfiguration()
    const mergedData = { ...core, ...response.data }
    console.log('[AdFuelConfigurationBeta] ---------- openConfigurationDialog::mergedData: ', mergedData)

    try {
      console.log('[AdFuelConfigurationBeta] ---------- openConfigurationDialog::Setting Dialog to AdFuelConfiguationModal')
      setDialog({
        content: (
          <AdFuelConfigurationModal
            allOptions={mergedData}
            data={mergedData.ADFUEL}
            site={site}
            setOpenDialog={setOpenDialog}
            errors
            setErrors
          />
        ),
        displayDialogAction: false,
        onClose
      })
      console.log('[AdFuelConfigurationBeta] ---------- openConfigurationDialog::Setting Open Dialog to `true`')
      setOpenDialog(true)
    } catch (err) {
      console.log('[AdFuelConfigurationBeta] ---------- openConfigurationDialog::Error:', err)
      swal.fire({
        title: 'Error',
        text: err,
        icon: 'error'
      })
    }
  }

  const doUpdateSiteAdFuelModules = (modules) => {
    let newSiteData = Object.assign({}, site)
    newSiteData.adFuelModules = modules
    setSite(newSiteData)
    console.log('[AdFuelConfigurationBeta] ---------- doUpdateSiteAdFuelModules::Setting modulesChanged to true...')
    setModulesChanged(true)
  }

  console.log('[AdFuelConfigurationBeta] ---------- render::isChanged: ', isChanged)
  return (
    <Card className={commonClasses.roundedCard}>
      <CardHeader style={{padding: 5}} className={addClasses(commonClasses.panelHeader, commonClasses.pl_3, commonClasses.clrSiteHeader)} titleTypographyProps={{ variant: 'h6' }} title={`AdFuel Configuration`}/>

      <CardContent
        className={addClasses(commonClasses.pl_3, commonClasses.pr_3, commonClasses.pt_1)}
      >
        <CustomDialog
          open={openDialog}
          setOpenDialog={setOpenDialog}
          title={dialog.title}
          subtitle={dialog.subtitle}
          content={dialog.content}
          displayDialogAction={dialog.displayDialogAction}
          customAction={dialog.customAction}
          size={dialog.size}
          handleOnClose={dialog.onClose}
        />

        <Grid container spacing={2} alignItems='flex-start' justifyContent='flex-start' direction='row'>
          <Grid item xs={12} container spacing={2} alignItems='flex-start'>
            <Grid item xs={5}>
              <label style={{display: 'block', color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Version:</label>
              <FormControl>                
                <Select
                  disabled={!canEditSite || updatingSiteAdFuel}
                  labelId='adfuel-version-label'
                  style={{ minWidth: '120px' }}
                  value={versionId || ''}
                  onChange={event => {
                    const value = event.target.value
                    setVersionId(value)
                    setSite({ ...site, adFuelVersionId: value })
                    buildUrl('version', value)
                  }}
                >
                  {adFuelVersions.map((item, index) => {
                    // console.log('Filter: ', { tag: tagForChildDirectedTreatment, coppa: item.hasCOPPA })
                    if ((tagForChildDirectedTreatment && item.hasCOPPA) || !tagForChildDirectedTreatment) {
                      return (
                        <MenuItem key={index} value={item._id}>
                          {item.name}
                        </MenuItem>
                      )
                    } else {
                      return ''
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            {isAdFuelLocationSiteSpecific !== undefined && (
              <Grid item xs={4}>
                <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Location:</label><br />
                <div style={{display: 'inline-block', marginLeft: 0, paddingLeft: 0}}>
                  <Checkbox
                    checked={!site.isAdFuelLocationSiteSpecific}
                    onChange={event => {
                      setSite({ ...site, isAdFuelLocationSiteSpecific: false })
                      buildUrl('location', false)
                    }}
                    name="common"
                    size="small"
                    style={{marginLeft: 0, paddingLeft: 0}}
                  />
                  Common
                </div>
                <div style={{display: 'inline-block'}}>
                  <Checkbox
                    checked={site.isAdFuelLocationSiteSpecific}
                    onChange={event => {
                      setSite({ ...site, isAdFuelLocationSiteSpecific: true })
                      buildUrl('location', true)
                    }}
                    name="site-specific"
                    size="small"
                  />
                  Site-Specific
                </div>
              </Grid>
            )}
            {isAdFuelMinified !== undefined && (
              <Grid item xs={3}>
                <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>Packaging:</label><br />
                <div style={{display: 'inline-block', marginLeft: 0, paddingLeft: 0}}>
                  <Checkbox
                    checked={!site.isAdFuelMinified}
                    onChange={event => {
                      setSite({ ...site, isAdFuelMinified: false })
                      buildUrl('packaging', false)
                    }}
                    name="common"
                    size="small"
                    style={{marginLeft: 0, paddingLeft: 0}}
                  />
                  Normal
                </div>
                <div style={{display: 'inline-block'}}>
                  <Checkbox
                    checked={site.isAdFuelMinified}
                    onChange={event => {
                      setSite({ ...site, isAdFuelMinified: true })
                      buildUrl('packaging', true)
                    }}
                    name="site-specific"
                    size="small"
                  />
                  Minified
                </div>
              </Grid>
            )}
          </Grid>
          {url && adFuelVersionId && (
            <Grid item xs={12} container spacing={2} alignItems='flex-end'>              
              <Grid item xs={9}>
                <label style={{color: '#999', fontSize: '.9em', marginRight: '10px'}} className={addClasses(commonClasses.formControl, commonClasses.root)}>AdFuel File URL:</label>
                <Typography className={classes.link}>
                  <Link
                    className={addClasses(commonClasses.linkColor, classes.links)}
                    href={url}
                    rel='noreferrer'
                    target='_blank'
                    onClick={() => {
                      setClickCount(clickCount + 1)
                    }}
                  >
                    {url.length > 60 
                      ? (url.substring(0,30) + '[...]' + url.substring(url.length-25)) 
                      : (url)
                    }
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title='View'>
                  <IconButton
                    size='small'
                    color='primary'
                    style={{fontSize: '1em'}}
                    className={commonClasses.linkColor}
                    aria-label='view'
                    onClick={async () => {
                      try {
                        const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                        console.log('err', jsFile)
                        openViewDialog(jsFile)
                      } catch (err) {
                      // console.log('err', err)
                      }
                    }}
                  >
                    <Launch />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title='Download'>
                  <IconButton
                    size='small'
                    color='primary'
                    style={{fontSize: '1em'}}
                    className={commonClasses.linkColor}
                    aria-label='download'
                    onClick={async () => {
                      try {
                        const jsFile = await (await fetch(aisUrl.replace('http:', 'https:'))).text()
                        const file = new window.Blob([jsFile], { type: 'text/javascript' })
                        const link = document.createElement('a')
                        link.download = getAISFilename()
                        link.href = URL.createObjectURL(file)
                        link.click()
                      } catch (err) {
                      // console.log('err', err)
                      }
                    }}
                  >
                    <GetApp />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title='Configure'>
                  <IconButton
                    size='small'
                    color='primary'
                    style={{fontSize: '1em'}}
                    className={commonClasses.linkColor}
                    aria-label='configure'
                    onClick={async () => {
                      if (canEditSite) {
                        console.log('[AdFuelConfigurationBeta] ---------- configure::onClick - Opening Configuration Dialog...')
                        openConfigurationDialog()
                      } else {
                        swal.fire({
                          title: 'UNAUTHORIZED',
                          text: 'You do not have permissions to do this.',
                          icon: 'error',
                          dangerMode: false
                        })
                      }
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
        <ModuleConfigurationLite site={site} canEditSite={canEditSite} isAdFuelMinified={isAdFuelMinified} adFuelVersionId={adFuelVersionId} setHaveModulesChanged={setHaveModulesChanged} hasAdFuelChanged={isChanged} doUpdateSiteAdFuelModules={doUpdateSiteAdFuelModules} />
      </CardContent>
      <Divider />
      <CardActions className={commonClasses.panelFooter} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {(<Button
          className={commonClasses.btnWarning}
          disabled={updatingSiteAdFuelModules}
          variant='contained'
          size="small"
          startIcon={<BuildIcon />}
          onClick={() => {
            const saveAndBuild = () => {
              const adFuelModules = [...site.adFuelModules].sort(byPriorityAndName)
              site.adFuelModules.sort(byPriorityAndName)
              setSite({ ...site })
              let siteData = clone(site)
              dispatch(updateSiteAdFuelModules(siteData))
            }
            if (!haveModulesChanged) { 
              swal.fire({
                title: 'No changes were made to the list of modules included in AIS.',
                text: 'Do you still want to rebuild and publish AIS?',
                icon: 'info',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true
              }).then(ok => {
                if (ok.isConfirmed) { 
                  saveAndBuild()
                  setHaveModulesChanged(false)
                }
              })
            } else {
              saveAndBuild()
              setHaveModulesChanged(false)
            }
          }}
        >
          {
            haveModulesChanged ? (updatingSiteAdFuelModules ? 'Saving and Publishing...' : 'Save and Publish Module Changes')
            : (updatingSiteAdFuelModules ? 'Publishing...' : 'Republish Module Package')
          }
        </Button>)}
      {(
        <Button
          className={isChanged ? commonClasses.btnSuccess : ''}
          disabled={!isChanged || updatingSiteAdFuel}
          variant='contained'
          size="small"
          startIcon={<CheckCircleIcon />}
          onClick={() => {
            const saveAndBuild = () => {
              const adFuelModules = [...site.adFuelModules].sort(byPriorityAndName)
              site.adFuelModules.sort(byPriorityAndName)
              setSite({ ...site })
              let siteData = clone(site)
              console.log('Saving AdFuel Changes...')
              dispatch(updateAdFuel(siteData, adFuelChanged, modulesChanged))
            }
            swal.fire({
              title: 'Save AdFuel Changes?' ,
              text: 'Are you sure you want to save changes?',
              icon: 'info',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              showCancelButton: true
            }).then(ok => {
              if (ok.isConfirmed) { saveAndBuild() }
            })
          }}
        >
          {updatingSiteAdFuel ? 'Saving AdFuel Changes' : 'Save AdFuel Changes'}
        </Button>     
      )}
      </CardActions>
    </Card>
  )
}

export default AdFuelConfigurationBeta