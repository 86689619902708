import React, { useState, useEffect } from 'react'
import { Alert } from '@material-ui/lab'
import { makeStyles, Link } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { BannerMessageToggleDisplay } from '../../modules/bannerMessageSlice'
import { addClasses, commonStyle } from '../../styles'

const useStyle = makeStyles(theme => ({
  text: {
    fontSize: '1em',
    fontWeight: 'bold',
    color: 'white',
    lineHeight: 'normal',
    verticalAlign: 'middle'
  },
  link: {
    color: '#eaeaea',
    '&:hover': {
      color: 'white'
    }
  },
  banner: {
    position: 'fixed',
    top: '60px',
    width: '100%',
    zIndex: 9999,
    opacity: 0.8
  }
}))

export const Banner = () => {
  const classes = useStyle()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { banner } = useSelector(state => state.bannerMessageSlice)
  const [bannerMessage, setBannerMessage] = useState({})

  useEffect(() => {
    if (banner) {
      setBannerMessage(banner)
    }
  }, [banner])

  return window.localStorage.getItem('showBanner') === 'true'
    ? (
      <Alert
        severity={bannerMessage.severity}
        variant='filled'
        className={classes.banner}
      >
        <span
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: bannerMessage.message }}
        />
        <Link
          className={addClasses(commonClasses.ml_2, classes.text, classes.link)}
          component='button'
          style={{ position: 'fixed', right: 25 }}
          onClick={() => {
            dispatch(BannerMessageToggleDisplay())
          }}
        >
          [CLOSE]
        </Link>
      </Alert>
      )
    : null
}
