import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { commonStyle } from '../../../styles/commonStyle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { ToggleSwitch } from '../../ui/ToggleSwitch'
import { useSelector, useDispatch } from 'react-redux'

import { updateSiteRegistrySettings } from '../../../modules/registrySlice'
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}))

const ChildDirectedTreatmentDialog = props => {
  // // console.log('Registry Prop: ', props)
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const commonClasses = commonStyle()
  const { isUpdating } = useSelector(state => state.registrySlice)
  const [
    tagForChildDirectedTreatment,
    setTagForChildDirectedTreatment
  ] = useState(false)
  // const [registries, setRegistries] = useState(props.selectedRegistries)

  const handleSaveRegistry = e => {
    dispatch(
      updateSiteRegistrySettings(
        props.selectedRegistries,
        tagForChildDirectedTreatment,
        null,
        null,
        null
      )
    )
  }

  const handleRadioGroupChange = e => {
    if (e.target.name === 'ChildDirectedTreatment') {
      setTagForChildDirectedTreatment(e.target.checked)
    }
  }

  const handleDialogClose = () => {
    props.handleDialogClose()
  }

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby='customized-dialog-title'
      open={props.isDialogOpen}
      maxWidth='sm'
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle id='form-dialog-title'>
        {props.siteName} - Bulk Child-Directed Treatmet
      </DialogTitle>
      <DialogContent dividers>
        <Grid container className={commonClasses.m_1}>
          <Grid item xs={12} sm={12}>
            <ToggleSwitch
              label='Tag Selected Registries for Child-Directed Treatment'
              name='ChildDirectedTreatment'
              offLabel='Yes'
              onLabel='No'
              checked={!!tagForChildDirectedTreatment}
              onChange={e => handleRadioGroupChange(e)}
              value={tagForChildDirectedTreatment}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.wrapper}>
        {isUpdating
          ? (
            <Button
              disabled
              variant='contained'
              title='Saving...'
              className={commonClasses.btnSuccess}
              startIcon={<CircularProgress size={24} />}
            >
              Saving
            </Button>
            )
          : (
            <Button
              autoFocus
              className={commonClasses.btnSuccess}
              onClick={e => handleSaveRegistry(e)}
              variant='contained'
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
            )}

        <Button
          className={commonClasses.ml_1}
          title='Cancel'
          variant='contained'
          onClick={handleDialogClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ChildDirectedTreatmentDialog.propTypes = {
  selectedRegistries: PropTypes.array,
  handleDialogClose: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  siteName: PropTypes.string
}

export default ChildDirectedTreatmentDialog
